import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import LinkStyled from "../../../../../globalComponents/container/LinkStyled";
import { useForm } from "react-hook-form";
import CompaniesSelect from "../../../../../globalComponents/formFields/dynamic/CompaniesSelect";
import DepartmentSelect from "../../../../../globalComponents/formFields/dynamic/DepartmentSelect";
import { baseUrl } from "../../../../../config/globalVariables";
import LocationSelect from "../../../../../globalComponents/formFields/dynamic/LocationSelect";
import { CoreRequest } from "../../../../../config/backend/serviceInstances";
import { listEmployeeProfiles } from "../../../../../state/employees/employees/employee";
import {
  BottomContainer,
  FormFieldContainer,
  StyledForm,
  UpperContainer,
} from "../../../../../globalComponents/smartOperations/smartCrud/FormFieldReturn";
import TextFieldController from "../../../../../globalComponents/formFields/TextFieldController";
import { Button } from "@mui/material";
import { format } from "date-fns";
import {
  Errors,
  SimpleWorkContract,
} from "../../../../../state/employees/types";
import { AxiosResponse } from "axios";
import { getDateText } from "../../../../../utils/dates";
import SlideInFrame from "../../../../../globalComponents/tables/components/SlideInFrame";
import getErrorMessage, {
  getFirstError,
} from "../../../../../utils/backend-errors";
import { Alert } from "@material-ui/lab";

type CheckEmployeeAddResponse = {
  existingWorkContracts: SimpleWorkContract[];
  overlappingWorkContracts: SimpleWorkContract[];
  errors: Errors;
};

type FormValues = {
  companyId: null | number;
  departmentId: null | number;
  locationId: null | number;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
};

type Props = {
  close: () => void;
};

const EmployeeAdd = (props: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string | null>();
  const [newEmployeeId, setNewEmployeeId] = useState<number | null>(null);
  const { handleSubmit, control, watch, getValues } = useForm<FormValues>({
    defaultValues: {},
  });

  const watchCompanyId = watch("companyId");
  const watchEmail = watch("email");
  const watchDepartmentId = watch("departmentId");
  const watchLocationId = watch("locationId");

  const [
    checkResponse,
    setCheckResponse,
  ] = useState<CheckEmployeeAddResponse | null>();

  useEffect(() => {
    if (watchCompanyId && watchDepartmentId && watchLocationId && watchEmail) {
      const request = {
        companyId: watchCompanyId,
        email: watchEmail,
        departmentId: watchDepartmentId,
        locationId: watchLocationId,
        startDate: format(new Date(), "yyyy-MM-dd"),
      };

      CoreRequest.post("/employees/check", request)
        .then((response: AxiosResponse<CheckEmployeeAddResponse>) => {
          setCheckResponse(response.data);
        })
        .catch((error) => {
          console.debug(error);
        });
    }
  }, [watchCompanyId, watchEmail, watchDepartmentId, watchLocationId]);

  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    const requestParameters = formValues;

    const body = {
      firstName: requestParameters.firstName,
      lastName: requestParameters.lastName,
      email: requestParameters.email,
      phoneNumber: requestParameters.phoneNumber,
      companyId: requestParameters.companyId,
      departmentId: requestParameters.departmentId,
      locationId: requestParameters.locationId,
    };

    try {
      const response = await CoreRequest.post(`${baseUrl}/employees`, body);
      if (response.status > 299) {
        console.error("add employee error", response);
      } else {
        setNewEmployeeId(response.data.id);
        setLoading(false);
        dispatch(listEmployeeProfiles());
      }
    } catch (e) {
      setApiError(getFirstError(e));
    }
  };

  const page1 = (
    <>
      <UpperContainer>
        {apiError && (
          <Alert severity="error">{getErrorMessage(apiError)}</Alert>
        )}
        <FormFieldContainer>
          <TextFieldController
            key="firstName"
            control={control}
            label="Vorname"
            defaultValue=""
            fieldName="firstName"
            rules={{ required: true, minLength: 1 }}
          />
        </FormFieldContainer>
        <FormFieldContainer>
          <TextFieldController
            key="lastName"
            control={control}
            label="Nachname"
            defaultValue=""
            fieldName="lastName"
            rules={{ required: true, minLength: 1 }}
          />
        </FormFieldContainer>
        <FormFieldContainer>
          <TextFieldController
            key="email"
            control={control}
            label="Email"
            defaultValue=""
            fieldName="email"
            rules={{ required: true, minLength: 3 }}
          />
        </FormFieldContainer>
        <FormFieldContainer>
          <TextFieldController
            key="phoneNumber"
            control={control}
            label="Telefon"
            defaultValue=""
            fieldName="phoneNumber"
            rules={{ required: true, minLength: 5 }}
          />
        </FormFieldContainer>
        <FormFieldContainer>
          <CompaniesSelect
            control={control}
            fieldName={"companyId"}
            required={true}
          />
        </FormFieldContainer>
        <FormFieldContainer>
          <DepartmentSelect
            control={control}
            companyId={watchCompanyId}
            fieldName={"departmentId"}
            required={true}
          />
        </FormFieldContainer>
        <FormFieldContainer>
          <LocationSelect
            control={control}
            companyId={watchCompanyId}
            fieldName={"locationId"}
            required={true}
          />
        </FormFieldContainer>
        {checkResponse && checkResponse.overlappingWorkContracts.length === 1 && (
          <Typography variant={"subtitle1"}>
            This employee has an existing{" "}
            <a
              href={`/app/employees/${checkResponse.overlappingWorkContracts[0].employeeId}/basic`}
            >
              work contract (
              {checkResponse.overlappingWorkContracts[0].position
                ? checkResponse.overlappingWorkContracts[0].position
                : "Position fehlt"}
              )
            </a>{" "}
            that overlaps with a start date of{" "}
            {getDateText(checkResponse.overlappingWorkContracts[0].startDate)}.
          </Typography>
        )}
        {checkResponse && checkResponse.overlappingWorkContracts.length > 1 && (
          <Typography variant={"subtitle1"}>
            This employee has existing{" "}
            <a
              href={`/app/employees/${checkResponse.overlappingWorkContracts[0].employeeId}/basic`}
            >
              work contracts (
              {checkResponse.overlappingWorkContracts[0].position
                ? checkResponse.overlappingWorkContracts[0].position
                : "Position fehlt"}
              )
            </a>{" "}
            that overlap.
          </Typography>
        )}
      </UpperContainer>
      <BottomContainer>
        <Button
          type={"submit"}
          variant={"contained"}
          color={"primary"}
          disabled={loading}
        >
          Absenden
        </Button>
      </BottomContainer>
    </>
  );

  const page2 = (
    <>
      <p />
      <Typography variant={"subtitle2"}>
        {getValues().firstName} {getValues().lastName} was successfully added.
      </Typography>
      <p />
      <Typography variant={"subtitle2"}>
        What would you like to do next?{" "}
      </Typography>
      <p />
      <Typography variant={"subtitle2"}>
        <LinkStyled to={`/app/employees/${newEmployeeId}/basic`}>
          View profile for {getValues().firstName}.
        </LinkStyled>
      </Typography>
    </>
  );

  return (
    <SlideInFrame
      title={"Mitarbeiter Hinzufügen"}
      closeCallBack={props.close}
      confirmCallBack={props.close}
    >
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        {newEmployeeId ? page2 : page1}
      </StyledForm>
    </SlideInFrame>
  );
};

export default EmployeeAdd;
