import { CoreRequest } from "../../config/backend/serviceInstances";

export const setHeaders = (
  selectedCompanies: string[],
  allCompanies: string[]
): void => {
  const indicesOfSelectedCompanies: number[] = [];
  for (let i = 0; i < selectedCompanies.length; i++) {
    indicesOfSelectedCompanies.push(allCompanies.indexOf(selectedCompanies[i]));
  }

  //set Company and X Heaven Token Header
  CoreRequest.defaults.headers.common["companyId"] = selectedCompanies;
  CoreRequest.defaults.headers.common[
    "X-Heaven-Token-Role-Index"
  ] = indicesOfSelectedCompanies;
};
