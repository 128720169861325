import React from "react";
import { Grid, Typography } from "@mui/material";
import styled from "styled-components";
import { DetailHeaderWrapper } from "../../../organisation/companies/detail/CompanyDetailHeader";
import FileUploadButton from "../../../organisation/companies/detail/FileUploadButton";
import { useCurrentEmployee } from "../../../../../state/employees/employeeDetail/selectors";
import { useDispatch } from "react-redux";
import { getCurrentEmployee } from "../../../../../state/employees/employeeDetail/employeeDetail";
import { baseUrl } from "../../../../../config/globalVariables";
import EmployeeAvatarNew from "../../../../../globalComponents/avatars/EmployeeAvatarNew";
import { useEmployeeWorkContracts } from "../../../../../state/employees/employees/selectors";
import { faCameraAlt } from "@fortawesome/pro-solid-svg-icons";
import ExpandOptionsButton from "../../../../../globalComponents/buttons/ExpandOptionsButton";

const HeaderContainer = styled(DetailHeaderWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: visible;
  margin-top: 40px;
  margin-bottom: 40px;
  border: 0;
`;

const PictureContainer = styled.div`
  position: relative;
  flex: 0.13;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const GridContainer = styled.div`
  position: relative;
  flex: 0.87;
`;

const ExpandContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  id: number | null;
};

const EmployeeDetailHeader = (props: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const currentEmployee = useCurrentEmployee();
  const currentWorkContracts = useEmployeeWorkContracts();
  const currentWorkContract =
    currentWorkContracts.data.length > 0 ? currentWorkContracts.data[0] : null;

  const firstName = currentEmployee.Basic.data.firstName || "Nicht angegeben";
  const lastName = currentEmployee.Basic.data.lastName || "Nicht angegeben";
  const email = currentEmployee.Basic.data.email || "Nicht angegeben";
  const phoneNumber =
    currentEmployee.Basic.data.phoneNumber || "Nicht angegeben";
  const imgLink = currentEmployee.Header.data.imgLink || "";
  const city = currentEmployee.Basic.data.city || "Nicht angegeben";
  const position = currentWorkContract?.position || "Nicht angegeben";
  return (
    <HeaderContainer>
      <PictureContainer>
        <EmployeeAvatarNew
          imgUrl={baseUrl + "/employees/pic/" + imgLink}
          size={"LARGE"}
        />
      </PictureContainer>
      <GridContainer>
        <div style={{ position: "absolute", right: "0px", bottom: 0 }}>
          <FileUploadButton
            icon={faCameraAlt}
            successFunction={() =>
              dispatch(getCurrentEmployee(props.id as number))
            }
            endpoint={"/employees/pic?employeeId=" + props.id}
            errorMessage={"Foto konnte nicht hochgeladen werden"}
            id={props.id as number}
          />
        </div>
        <Grid container>
          <Grid item xs={11}>
            <Typography variant={"h2"} marginBottom={"15px"}>
              {firstName} {lastName}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <ExpandContainer>
              <ExpandOptionsButton
                items={[
                  {
                    label: "Änderungsvertrag erstellen",
                    onClick: () => console.log("change"),
                  },
                  {
                    label: "Neuen Vertrag erstellen",
                    onClick: () => console.log("change"),
                  },
                  {
                    label: "Mitarbeiter erneut einladen",
                    onClick: () => console.log("change"),
                  },
                  {
                    label: "Arbeitsverhältnis beenden",
                    onClick: () => console.log("change"),
                  },
                ]}
              />
            </ExpandContainer>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"body1"} marginBottom={"5px"}>
              {position}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"body1"}>{email || "Email fehlt"}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"body1"}>{city}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"body1"}>{phoneNumber}</Typography>
          </Grid>
        </Grid>
      </GridContainer>
    </HeaderContainer>
  );
};

export default EmployeeDetailHeader;
