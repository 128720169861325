import { DataState } from "./companyDetail";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CoreRequest } from "../../../../config/backend/serviceInstances";

export type CompanyDetailSocial2 = {
  id: number;
};

export interface CompanyDetailSocial2State extends DataState {
  data: CompanyDetailSocial2 | Record<string, never>;
}

export const CompanyDetailSocial2StateI: CompanyDetailSocial2State = {
  loading: false,
  error: false,
  data: {},
};

export const getSocialData2 = createAsyncThunk<CompanyDetailSocial2, number>(
  "/companies/detail/social2",
  async (companyId) => {
    const response = await CoreRequest.get(`/companies/berufsgenossenschaft`, {
      params: { companyId: companyId },
    });

    if (response.status > 299) {
      // Return the known error for future handling
      return response.data.error;
    }

    return response.data as CompanyDetailSocial2;
  }
);
