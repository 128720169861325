import React from "react";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import { FormHelperText, ListItemText, MenuItem, Select } from "@mui/material";
import {
  StyledLabel,
  TextFieldWrapper,
} from "../../../../../../../globalComponents/formFields/TextFieldController";
import { MenuProps } from "../../../../../../../globalComponents/formFields/dynamic/EmployeesSelect";

type Props = {
  control: Control<any>;
  fieldName: `${string}`;
  defaultValue?: number | null;
};

const timeIntervals = [
  {
    id: "HOURLY",
    description: "pro Stunde",
  },
  {
    id: "DAILY",
    description: "pro Tag",
  },
  {
    id: "MONTHLY",
    description: "pro Monat",
  },
];

const TimeIntervalSelect = (props: Props): React.ReactElement => {
  return (
    <Controller
      name={props.fieldName}
      control={props.control}
      defaultValue={props.defaultValue || ""}
      rules={{ required: "Häufigkeit ist ein pflichtfield" }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <TextFieldWrapper>
            <StyledLabel id={`${props.fieldName}-timeIntervalSelect`}>
              <label>Häufigkeit</label>
            </StyledLabel>
            <Select
              labelId={`${props.fieldName}-timeIntervalSelect`}
              id={`${props.fieldName}-timeIntervalSelect`}
              onChange={onChange}
              value={value}
              MenuProps={MenuProps}
              renderValue={(value: any) => {
                const vals = timeIntervals.filter((item) => item.id == value);
                if (vals && vals.length > 0) {
                  return `${vals[0].description}`;
                }
              }}
            >
              {timeIntervals.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  <ListItemText primary={item.description} />
                </MenuItem>
              ))}
            </Select>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </TextFieldWrapper>
        );
      }}
    />
  );
};

export default TimeIntervalSelect;
