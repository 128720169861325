import React, { ReactNode } from "react";
import useAnimate from "./useAnimate";
import { Container, CollapsableContainer, StyleProps } from "./styles";

export type AccordionProps = StyleProps & {
  value?: boolean;
  className?: string;
  ariaLabel?: string;
  customToggle?: ReactNode;
  customContent?: ReactNode;
};

const Accordion: React.FC<AccordionProps> = ({
  value = false,
  className,
  ariaLabel = "Accordion",
  contentHeight,
  scroll,
  customToggle,
  customContent,
  animationName,
}) => {
  const animate = useAnimate(value);

  return (
    <Container className={className} aria-label={ariaLabel}>
      {customToggle}
      <CollapsableContainer
        animationName={animationName}
        animate={animate}
        contentHeight={contentHeight}
        scroll={scroll}
        aria-label={`${value ? "" : "hidden "}accordion content`}
      >
        {customContent}
      </CollapsableContainer>
    </Container>
  );
};

export default Accordion;
