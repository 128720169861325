import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import Icon from "../../../../globalComponents/container/Icon";
import { faColumns, faSitemap } from "@fortawesome/free-solid-svg-icons";
import ViewContainer from "../../../../globalComponents/container/ViewContainer";
import { ElementType } from "../../../../globalComponents/orgChart/OrgChartGenerator";
import {
  useCompaniesOrgChart,
  useCompaniesTableView,
} from "../../../../state/organization/companies/selectors";
import { useDispatch } from "react-redux";
import SmartTable from "../../../../globalComponents/smartOperations/smartTable/SmartTable";
import OrganizationalChart from "../../../../globalComponents/orgChart/OrganizationalChart";
import { refreshToken } from "../../../../state/general/auth";
import {
  useGeneral2Auth,
  useUserSelection,
} from "../../../../state/general/selectors";
import {
  listCompaniesOrg,
  listCompaniesTableView,
} from "../../../../state/organization/companies/companies";
import TableLink from "../departments/components/TableLink";
import { PageTitle } from "../../../../globalComponents/PageHeader/PageTitle";
import { GridValueGetterParams } from "@mui/x-data-grid";
import { LabelValuePair } from "../../../../globalComponents/formFields/staticSelect/SelectMenuInput";
import EmployeeAvatars from "../../../../globalComponents/avatars/EmployeeAvatars";

type Props = {
  view: "chart" | "table";
};

const columns = [
  {
    field: "company",
    headerName: "Firmenname",
    width: 250,
    editable: true,
    renderCell: (params: GridValueGetterParams) => (
      <TableLink
        label={(params.getValue(params.id, "company") as LabelValuePair).label}
        startUrl={"/app/companies/" + params.id}
        customValue={params.id as string}
      />
    ),
  },
  {
    field: "entityType",
    headerName: "Form",
    width: 150,
    editable: true,
  },
  {
    field: "managingDirectors",
    headerName: "Geschäftsführer",
    width: 250,
    editable: true,
    renderCell: (params: GridValueGetterParams) =>
      params.row?.managingDirectors.length > 0 ? (
        <EmployeeAvatars employeeList={params.row?.managingDirectors} />
      ) : (
        <Typography>Keine Angegeben</Typography>
      ),
  },
  {
    field: "motherCompany",
    headerName: "Übergeordnete Firma",
    width: 250,
    editable: true,
    renderCell: (params: GridValueGetterParams) =>
      params.row.motherCompany ? (
        <TableLink
          label={params.row.motherCompany?.label}
          startUrl={"/app/companies/" + params.row.motherCompany?.value}
          customValue={params.row.motherCompany?.value as string}
        />
      ) : (
        <Typography>Keine</Typography>
      ),
  },
  {
    field: "city",
    headerName: "Firmensitz",
    width: 150,
    editable: true,
  },
];

const Companies = (props: Props): React.ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const rToken = useGeneral2Auth().refreshToken;
  const userSelection = useUserSelection().companies;
  useEffect(() => {
    dispatch(listCompaniesTableView());
    dispatch(listCompaniesOrg());
  }, [userSelection]);

  const companiesOrg = useCompaniesOrgChart();
  const companiesTable = useCompaniesTableView();

  return (
    <>
      <PageTitle>
        <Typography variant={"h1"}>Firmen</Typography>
        <ViewContainer>
          <Typography variant={"subtitle2"} marginRight={"10px"}>
            Ansicht:{" "}
          </Typography>
          <br />
          <Link to={"/app/companies"}>
            <Icon icon={faSitemap} />
          </Link>
          <br />
          <Link to={"/app/companies/table"}>
            <Icon icon={faColumns} />
          </Link>
        </ViewContainer>
      </PageTitle>
      {props.view === "chart" ? (
        <OrganizationalChart
          mother={companiesOrg?.data}
          reloadCallback={() => {
            dispatch(refreshToken(rToken));
          }}
          loading={companiesOrg?.loading}
          error={companiesOrg?.error}
          elementType={ElementType.COMPANY}
        />
      ) : (
        <SmartTable
          columns={columns}
          title={"Angelegte Firmen"}
          createButton={{
            label: "Firma hinzufügen",
            callbackFunc: () => history.push("/app/companies/add"),
          }}
          deleteEndpoint={"/companies?companyId="}
          deleteConfirmMessage={"Wollen Sie die Firma wirklich löschen?"}
          input={companiesTable}
          updateInputCallback={() => {
            dispatch(listCompaniesTableView);
            dispatch(refreshToken(rToken));
          }}
        />
      )}
    </>
  );
};

export default Companies;
