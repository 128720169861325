import { FormFieldType } from "../../../../../../globalComponents/dataCard/utils";
import entityTypes from "../../../../../../config/selectLabels/entityTypes";
import berufsgenossenschaft from "../../../../../../config/selectLabels/berufsgenossenschaft";
import branche from "../../../../../../config/selectLabels/branche";

export const BasicDataFormFields = [
  {
    fieldName: "name",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true, minLength: 4 },
    label: "Firmenname",
  },
  {
    fieldName: "street",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true },
    label: "Strasse",
  },
  {
    fieldName: "postalCode",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true },
    label: "PLZ",
  },
  {
    fieldName: "city",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true },
    label: "Stadt",
  },
  {
    fieldName: "managingDirectors",
    defaultValue: "",
    type: FormFieldType.SELECT_EMPLOYEES,
    validation: { required: true },
    label: "Geschäftsführer",
  },
  {
    fieldName: "entityType",
    defaultValue: "",
    type: FormFieldType.SELECT,
    validation: { required: true },
    label: "Gesellschaftsform",
    menuItems: entityTypes,
  },
  {
    fieldName: "motherCompany",
    requestFieldName: "motherCompanyId",
    defaultValue: "",
    type: FormFieldType.SELECT_MOTHER_COMPANY,
    validation: { required: true },
    label: "Übergeordnete Firma",
  },
];

export const TaxDataFormFields = [
  {
    fieldName: "financialAuthority",
    requestFieldName: "financialAuthorityNumber",
    defaultValue: "",
    type: FormFieldType.FINANCIAL_AUTHORITY,
    validation: { required: true },
    label: "Zuständiges Finanzamt",
  },
  {
    fieldName: "taxId",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true },
    label: "Steuernummer",
  },
  {
    fieldName: "iban",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true },
    label: "IBAN",
  },
  {
    fieldName: "bic",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true },
    label: "BIC",
  },
  {
    fieldName: "financialAuthoritySepa",
    defaultValue: "",
    type: FormFieldType.BOOLEAN,
    validation: { required: true },
    label: "Lastschriftmandat Finanzamt",
  },
];

export const SocialDataFormFields = [
  {
    fieldName: "betriebsNummer",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true },
    label: "Betriebsnummer",
  },
  {
    fieldName: "sofortmeldepflicht",
    defaultValue: "",
    type: FormFieldType.BOOLEAN,
    validation: { required: true },
    label: "Sofortmeldepflicht",
  },
  {
    fieldName: "umlage",
    defaultValue: "",
    type: FormFieldType.BOOLEAN,
    validation: { required: true },
    label: "Umlage",
  },
];

export const SocialDataFormFields2 = [
  {
    fieldName: "berufsGenossenschaft",
    defaultValue: "",
    type: FormFieldType.SELECT,
    validation: { required: true },
    menuItems: berufsgenossenschaft,
    label: "Berufsgenossenschaft",
  },
  {
    fieldName: "mitgliedsNummer",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true },
    label: "Mitgliedsnummer",
  },
  {
    fieldName: "pin",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true },
    label: "PIN",
  },
  {
    fieldName: "industry",
    defaultValue: "",
    type: FormFieldType.SELECT,
    validation: { required: true },
    label: "Branche",
    menuItems: branche,
  },
];
