import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import SearchIcon from "@material-ui/icons/Search";
import CustomFlapOut from "./CustomFlapOut";
import NotificationFlap, { ItemProps } from "./NotificationFlap";
import HelpFlap from "./HelpFlap";
import { useMobileVersion } from "../../state/general/selectors";
import { logOut } from "../../state/general/auth";
import { useDispatch } from "react-redux";
import { InputAdornment, MenuItem, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import EmployeeAvatarNew from "../../globalComponents/avatars/EmployeeAvatarNew";
import { ResponsiveContainer } from "../../globalComponents/ResponsiveContainer";
import { statusTypeValues } from "../../globalComponents/notification/StatusNotification";
import { ApprovalStatus, DocumentStatus } from "../../state/employees/types";
import type { Employee } from "../../state/employees/employees/employee";
import { CoreRequest } from "../../config/backend/serviceInstances";
import { CoreEndpoints } from "../../config/backend/endpointsCore";
import { baseUrl } from "../../config/globalVariables";
import MenuItemNew from "../../globalComponents/formFields/staticSelect/MenuItemNew";

// styles
const TopBarContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
  height: 80px;
  background-color: white;
  box-shadow: 0px 2px 4px #4c4c4c24;
`;

const TextFieldContainer = styled.div`
  max-width: 600px;
  min-width: 200px;
  margin-top: 20px;
`;

const RightContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 178px;
  height: 77px;
  padding: 10px;
`;

export const StyledMenuItem = styled(MenuItem)`
  border-bottom: 1px solid #c5c5c5;
  &:last-child {
    border-bottom: none;
  }
`;

const useStyles = makeStyles({
  root: {
    borderRadius: "24px",
  },
});

// components
const TopBar: React.FC = () => {
  const notificationItems = useMemo(() => sampleData, []);
  const mobileVersion = useMobileVersion();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [currentUser, setCurrentUser] = useState<Employee | null>();
  useEffect(() => {
    async function fetchUser() {
      try {
        const employee = await CoreRequest.get(CoreEndpoints.EMPLOYEE_SELF);
        setCurrentUser(employee.data as Employee);
      } catch (e) {
        console.error("error retrieving current user");
      }
    }

    fetchUser();
  }, []);

  return (
    <TopBarContainer>
      <ResponsiveContainer>
        <TextFieldContainer>
          <TextField
            classes={classes}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              sx: {
                borderRadius: "24px",
                fontStyle: "italic",
                color: "#999999",
                fontSize: "15px",
                width: "500px",
              },
            }}
            placeholder={"Search"}
            size={"small"}
          />
        </TextFieldContainer>
      </ResponsiveContainer>
      <RightContainer>
        {mobileVersion ? (
          <></>
        ) : (
          <>
            <HelpFlap />
            <NotificationFlap items={notificationItems} />
          </>
        )}
        <CustomFlapOut
          mainComponent={
            <ProfileContainer>
              <Typography variant={"subtitle2"} marginRight={"20px"} noWrap>
                {currentUser?.firstName || ""} {currentUser?.lastName || ""}
              </Typography>
              <EmployeeAvatarNew
                imgUrl={`${baseUrl}/employees/pic/${
                  currentUser?.imgLink || ""
                }`}
                size={"SMALL"}
              />
            </ProfileContainer>
          }
        >
          <MenuItemNew onClick={() => console.log("open profile")}>
            <>
              <Typography variant={"subtitle2"} color={"#131313"}>
                My Profile
              </Typography>
              <Typography variant={"body1"} color={"#999999"}>
                Your personal information.
              </Typography>
            </>
          </MenuItemNew>
          <MenuItemNew onClick={() => console.log("open settings")}>
            <>
              <Typography variant={"subtitle2"} color={"#131313"}>
                Settings
              </Typography>
              <Typography variant={"body1"} color={"#999999"}>
                Your personal account settings.
              </Typography>
            </>
          </MenuItemNew>
          <MenuItemNew onClick={() => dispatch(logOut())}>
            <Typography variant={"subtitle2"} color={"#131313"}>
              Logout
            </Typography>
          </MenuItemNew>
        </CustomFlapOut>
      </RightContainer>
    </TopBarContainer>
  );
};

// constants
// @todo remove when server data is available
const sampleData: ItemProps[] = [
  {
    id: 1,
    type: statusTypeValues.WORK_PERMIT_EXPIRATION,
    date: "2021-10-28",
    employee: {
      id: 1,
      firstName: "Alex",
      lastName: "Müller",
      imgLink: "",
      status: "",
      companyName: "",
      companyId: 1,
      department: "",
      startDate: "",
      location: "",
      position: "",
    },
  },
  {
    id: 2,
    type: statusTypeValues.CONTRACT_ENDING,
    date: "2021-10-25",
    employee: {
      id: 1,
      firstName: "Magdalena",
      lastName: "Müller",
      imgLink: "",
      status: "",
      companyName: "",
      companyId: 1,
      department: "",
      startDate: "",
      location: "",
      position: "",
    },
  },
  {
    id: 5,
    type: statusTypeValues.ABSENCE_REQUEST,
    category: "Absences",
    date: "2021-04-05",
    employee: {
      id: 5,
      firstName: "Magdalena",
      lastName: "Neuner",
      imgLink: "",
      status: "",
      companyName: "",
      companyId: 1,
      department: "",
      startDate: "",
      location: "",
      position: "",
    },
    absenceRequest: {
      id: 16,
      startDate: "2021-04-03",
      endDate: "2021-04-07",
      duration: 4,
      startWithHalf: false,
      endWithHalf: false,
      comment: "",
      documents: [],
      documentStatus: DocumentStatus.NOT_REQUIRED,
      workContract: {
        id: 1,
        startDate: "",
        endDate: "",
        position: "",
        status: "",
        personalNr: 0,
        locationName: "",
        lastName: "",
        firstName: "",
        imgLink: "",
        employeeId: 0,
        departmentName: "",
        departmentId: 0,
        creationDate: "",
        companyId: 0,
        company: {
          id: 0,
          imgLink: "",
          managingDirectors: [],
          motherCompany: null,
          city: "",
          children: [],
          activeEmployees: null,
          entityType: "",
          company: { label: "", value: "" },
        },
      },
      approvalStatus: ApprovalStatus.REQUESTED,
      absenceType: {
        id: 123,
        description: "Holiday",
        companyId: 1,
        halfDayPossible: true,
        budgetRequired: true,
        documentRequired: false,
        approvalRequired: true,
      },
    },
  },
];

export default TopBar;
