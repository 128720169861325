import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Icon from "../../../../globalComponents/container/Icon";
import { faColumns, faSitemap } from "@fortawesome/free-solid-svg-icons";
import ViewContainer from "../../../../globalComponents/container/ViewContainer";
import { useDepartments } from "../../../../state/organization/departments/selectors";
import {
  listDepartments,
  listDepartmentsOrg,
} from "../../../../state/organization/departments/departments";
import { ElementType } from "../../../../globalComponents/orgChart/OrgChartGenerator";
import OrganizationalChart from "../../../../globalComponents/orgChart/OrganizationalChart";
import SmartCrud, {
  RenderOnlyOn,
} from "../../../../globalComponents/smartOperations/smartCrud/SmartCrud";
import { PageTitle } from "../../../../globalComponents/PageHeader/PageTitle";
import { FormFieldType } from "../../../../globalComponents/dataCard/utils";
import { GridValueGetterParams } from "@mui/x-data-grid";
import TableLink from "./components/TableLink";
import EmployeeAvatars from "../../../../globalComponents/avatars/EmployeeAvatars";

type Props = {
  view: "chart" | "table";
};

const DepartmentsCells = [
  {
    field: "departmentName",
    headerName: "Bezeichnung",
    width: 240,
    defaultValue: "",
    editable: true,
    validation: { required: true },
    formType: FormFieldType.TEXT,
  },
  {
    field: "supervisorId",
    headerName: "Abteilungsleiter",
    requestFieldName: "supervisorList",
    width: 250,
    defaultValue: "",
    editable: true,
    validation: { required: true },
    formType: FormFieldType.SELECT_EMPLOYEES,
    renderCell: (params: GridValueGetterParams) =>
      params.row.supervisorList ? (
        <EmployeeAvatars employeeList={params.row.supervisorList} />
      ) : (
        <Typography>Keine</Typography>
      ),
  },
  {
    field: "watchField",
    headerName: "Gibt es eine übergeordnete Abteilung?",
    width: 200,
    defaultValue: "",
    editable: true,
    validation: { required: true },
    formType: FormFieldType.BOOLEAN_WATCH_FIELD,
    conditionalFields: ["motherDepartmentId"],
  },
  {
    field: "motherDepartmentId",
    headerName: "Übergeordnete Abteilung",
    width: 300,
    defaultValue: "",
    editable: true,
    validation: { required: true },
    formType: FormFieldType.SELECT_DEPARTMENT,
    conditionalRender: true,
    renderCell: (params: GridValueGetterParams) =>
      params.row.motherDepartmentLabel ? (
        <Typography>{params.row.motherDepartmentLabel.label}</Typography>
      ) : (
        <Typography>Keine</Typography>
      ),
  },
  {
    field: "companyId",
    headerName: "Zugehörige Firma",
    width: 200,
    defaultValue: "",
    editable: true,
    validation: { required: true },
    formType: FormFieldType.SELECT_MOTHER_COMPANY,
    renderOnlyOn: RenderOnlyOn.ADD,
    asRequestParameter: true,
    renderCell: (params: GridValueGetterParams) =>
      params.row.company ? (
        <TableLink
          label={params.row.company?.label}
          startUrl={"/app/companies/" + params.row.company?.value}
          customValue={params.row.motherCompany?.value as string}
        />
      ) : (
        <Typography>Keine</Typography>
      ),
  },
];

const Departments = (props: Props): React.ReactElement => {
  //get org chart data and table data
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listDepartments());
    dispatch(listDepartmentsOrg());
  }, []);

  const departments = useDepartments();

  return (
    <>
      <PageTitle>
        <Typography variant="h1">Abteilungen</Typography>
        <ViewContainer>
          <Typography variant={"subtitle2"} marginRight={"10px"}>
            Ansicht:{" "}
          </Typography>
          <br />
          <Link to={"/app/departments"}>
            <Icon icon={faSitemap} />
          </Link>
          <br />
          <Link to={"/app/departments/table"}>
            <Icon icon={faColumns} />
          </Link>
        </ViewContainer>
      </PageTitle>

      {props.view === "chart" ? (
        <OrganizationalChart
          mother={departments.departmentsOrg.data}
          elementType={ElementType.DEPARTMENT}
          loading={departments.departmentsTable.loading}
          error={departments.departmentsTable.error}
          reloadCallback={() => {
            console.log("s");
          }}
        />
      ) : (
        <SmartCrud
          title={"Angelegte Abteilungen"}
          input={departments.departmentsTable}
          updateInputCallback={() => {
            dispatch(listDepartments());
            dispatch(listDepartmentsOrg());
          }}
          createButtonLabel={"Abteilung hinzufügen"}
          endpoints={{
            getEndpointDetail: "/companies/department?id=",
            putPatchEndpoint: {
              url: "/companies/departments",
              type: "PUT",
              idAsPathParameter: true,
            },
            postEndpoint: { url: "/companies/departments", type: "POST" },
            deleteEndpoint: "/companies/departments?departmentId=",
          }}
          deleteConfirmMessage={"Wollen Sie die Abteilung wirklich löschen?"}
          parameter={DepartmentsCells}
        />
      )}
    </>
  );
};

export default Departments;
