import { Typography } from "@material-ui/core";
import React from "react";

const NoRowsOverlay = (): JSX.Element => {
  return (
    <div style={{ margin: "auto" }}>
      <Typography variant={"body2"}> Noch keine angelegt.</Typography>
    </div>
  );
};

export default NoRowsOverlay;
