import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import { StyledLabel, TextFieldWrapper } from "../TextFieldController";
import {
  CircularProgress,
  ClickAwayListener,
  FormHelperText,
  Typography,
} from "@mui/material";
import { LabelValuePair } from "../staticSelect/SelectMenuInput";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import TooltipNew from "../../tooltipNew/TooltipNew";
import {
  faCaretDown,
  faCaretUp,
  faExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CoreRequest } from "../../../config/backend/serviceInstances";
import { baseUrl } from "../../../config/globalVariables";

const MainContainer = styled.div`
  position: relative;
  height: 76px;
`;

const MenuContainer = styled.div<{
  backgroundColor: string;
  boxShadow: string;
  hoverBG: string;
  hoverBo: string;
}>`
  position: relative;
  width: 304px;
  min-height: 40px;
  max-height: 220px;
  overflow: scroll;
  border: 1px solid #cccccc;
  background-color: ${(props) => props.backgroundColor};
  box-shadow: ${(props) => props.boxShadow};
  border-radius: 4px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  &:hover {
    background-color: ${(props) => props.hoverBG};
    cursor: pointer;
    border: ${(props) => props.hoverBo};
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MenuItemsContainer = styled.div`
  position: relative;
  z-index: 2;
`;

const SelectedValueContainer = styled.div<{ color: string; open: boolean }>`
  position: sticky;
  top: 0;
  background-color: white;
  color: ${(props) => props.color};
  height: 40px;
  padding: 0px 10px 0px 10px;
  color: #131313;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  z-index: ${(props) => (props.open ? 3 : 1)};
`;

const MenuItem = styled.div<{ selected?: boolean }>`
  position: relative;
  height: 40px;
  padding: 0px 10px 0px 10px;
  color: ${(props) => (props.selected ? "#054d80" : "#131313")};
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.selected ? "#f5f8fa" : "white")};
  &:hover {
    background-color: #f5f8fa;
    color: #054d80;
    cursor: pointer;
  }
`;

type Props = {
  control: Control<any>;
  fieldName: `${string}`;
  defaultValue?: number | null;
  placeholder: string;
  label: string;
  menuItems?: LabelValuePair[];
  labelsEndpoint?: string;
  tooltip?: string;
};

const SelectNew = (props: Props): React.ReactElement => {
  const [state, setState] = useState<{
    open: boolean;
    filled: boolean;
    menuItems: LabelValuePair[];
    error: boolean;
    loading: boolean;
  }>({
    open: false,
    filled: false,
    menuItems: [],
    error: false,
    loading: false,
  });

  useEffect(() => {
    if (
      props.labelsEndpoint !== undefined &&
      state.open &&
      state.menuItems.length == 0
    ) {
      setState({ ...state, loading: true });
      CoreRequest(baseUrl + props.labelsEndpoint)
        .then((response) =>
          setState({ ...state, menuItems: response.data, loading: false })
        )
        .catch(() => setState({ ...state, error: true, loading: false }));
    } else if (props.menuItems !== undefined)
      setState({ ...state, menuItems: props.menuItems });
  }, [state.open]);

  let backgroundColor: string,
    color: string,
    boxShadow: string,
    hoverBG: string,
    hoverBo: string;
  if (state.open) {
    backgroundColor = "#ffffff";
    boxShadow = "0px 2px 4px #4C4C4C24";
    hoverBG = "#ffffff";
    hoverBo = "1px solid #cccccc";
  } else {
    backgroundColor = "#fbfbfb";
    boxShadow = "0px";
    hoverBG = "#f5f8fa";
    hoverBo = "1px solid #999999";
  }
  if (state.filled) {
    color = "#131313";
  } else {
    color = "#999999";
  }
  const menuItems = state.menuItems;
  return (
    <Controller
      name={props.fieldName}
      control={props.control}
      defaultValue={props.defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const filteredMenuItems = menuItems.filter(
          (item) => item.value == value
        );
        return (
          <MainContainer>
            <TextFieldWrapper>
              <StyledLabel id={`${props.fieldName}-employeeSelect`}>
                <LabelContainer>
                  <label> {props.label}</label>&nbsp;
                  {props.tooltip ? (
                    <TooltipNew message={props.tooltip} />
                  ) : (
                    <></>
                  )}
                </LabelContainer>
              </StyledLabel>
              <MenuContainer
                backgroundColor={backgroundColor}
                boxShadow={boxShadow}
                color={color}
                hoverBG={hoverBG}
                hoverBo={hoverBo}
                onClick={() => setState({ ...state, open: !state.open })}
              >
                <SelectedValueContainer color={color} open={state.open}>
                  {filteredMenuItems[0] !== undefined
                    ? filteredMenuItems[0].label
                    : props.placeholder}{" "}
                  <FontAwesomeIcon
                    icon={state.open ? faCaretUp : faCaretDown}
                    color={"#131313"}
                  />
                </SelectedValueContainer>
                {state.open ? (
                  <ClickAwayListener
                    onClickAway={() => setState({ ...state, open: false })}
                  >
                    <MenuItemsContainer>
                      {state.loading ? <CircularProgress /> : <></>}
                      {state.error ? (
                        <>
                          <Typography variant={"body2"} color={"error"}>
                            Auswahl konnte nicht geladen werden.
                          </Typography>
                          <FontAwesomeIcon icon={faExclamation} color={"red"} />
                        </>
                      ) : (
                        <></>
                      )}
                      {state.menuItems?.map((item) => {
                        return (
                          <MenuItem
                            onClick={() => onChange(item.value)}
                            key={uuidv4()}
                            selected={item.value === value}
                          >
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </MenuItemsContainer>
                  </ClickAwayListener>
                ) : (
                  <></>
                )}
              </MenuContainer>
              {error && <FormHelperText error>{error.message}</FormHelperText>}
            </TextFieldWrapper>
          </MainContainer>
        );
      }}
    />
  );
};

export default SelectNew;
