import { Button, Table, TableCell, TableHead, Typography } from "@mui/material";
import React from "react";
import TooltipNew from "../../../../../../globalComponents/tooltipNew/TooltipNew";
import styled from "styled-components";

const Headliner = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
`;

const TimetrackingDetailRounding = (): JSX.Element => {
  return (
    <>
      <Headliner>
        <div style={{ display: "inline-flex" }}>
          <Typography variant={"subtitle2"} marginRight={"5px"}>
            Rundungsregeln
          </Typography>
          <TooltipNew
            message={
              "Mit Hilfe von Rundungsregeln können Arbeitszeiten auf bzw. abgerundet werden."
            }
          />
        </div>
        <Button variant={"contained"} color={"primary"} size={"small"}>
          Regel hinzufügen
        </Button>
      </Headliner>
      <Table>
        <TableHead>
          <TableCell>
            <Typography variant={"body1"}>Intervallbeginn</Typography>
          </TableCell>
          <TableCell>
            <Typography variant={"body1"}>Intervallende</Typography>
          </TableCell>
          <TableCell>
            <Typography variant={"body1"}>Resultat</Typography>
          </TableCell>
          <TableCell />
        </TableHead>
      </Table>
    </>
  );
};
export default TimetrackingDetailRounding;
