import Wrapper from "../components/Wrapper";
import React, { FormEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import qs from "query-string";
import axios from "axios";
import { authUrl } from "../../config/globalVariables";
import {
  Validation,
  ValidationI,
  ValidationTypes,
} from "../../globalComponents/formFields/legacyFields/Validation/validation";
import PublicWrapper from "../components/PublicWrapper";
import FeedbackContainer from "../components/FeedbackContainer";
import { Button, CircularProgress, TextField, Typography } from "@mui/material";

const config = {
  withCredentials: true,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export interface EmailComponentProps {
  reset?: boolean;
}

const Email = (props: EmailComponentProps): React.ReactElement => {
  const history = useHistory();
  const validation: ValidationI = { error: false, message: null };
  const [formFields, setFormFields] = useState({
    email: { value: "", validation: validation },
  });

  const [formState, setFormState] = useState({
    loading: false,
    error: false,
    message: "",
  });

  const createAccount = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validateEmail = Validation(
      ValidationTypes.EMAIL,
      formFields.email.value
    );

    if (!validateEmail.error) {
      setFormState({ loading: true, error: false, message: "" });
      const endpoint = props.reset ? "/user/reset" : "/user/add";
      axios
        .post(
          authUrl + endpoint,
          qs.stringify({ email: formFields.email.value }),
          config
        )
        .then(() => {
          setFormState({ loading: false, error: false, message: "" });
          history.push({
            pathname: "/register/code",
            state: formFields.email.value,
          });
        })
        .catch((error) => {
          let errorMessage: string;
          if (!error.response) {
            errorMessage = "Ein Netzwerkfehler ist aufgetreten.";
          } else {
            errorMessage = "Der Account konnte nicht erstellt werden.";
          }
          setFormState({
            loading: false,
            error: true,
            message: errorMessage,
          });
        });
    } else {
      setFormFields({
        email: {
          ...formFields.email,
          validation: validateEmail,
        },
      });
    }
  };

  return (
    <PublicWrapper>
      <Wrapper
        onSubmit={(e) => {
          createAccount(e);
        }}
      >
        <Typography variant={"subtitle2"}>
          HeavenHR 2.0 ist bisher nur für bestimmte Kunden freigeschaltet. Falls
          Sie dazugehören, geben Sie Ihre Email Adresse ein.
        </Typography>
        <br />
        <TextField
          label={"Email"}
          variant={"standard"}
          sx={{ width: "100%" }}
          value={formFields.email.value}
          error={formFields.email.validation.error}
          helperText={formFields.email.validation.message}
          onChange={(e) =>
            setFormFields({
              email: {
                value: e.target.value,
                validation: { error: false, message: null },
              },
            })
          }
        />
        <br />
        {formState.loading ? (
          <CircularProgress />
        ) : (
          <Button type={"submit"} variant={"contained"} color={"primary"}>
            Weiter
          </Button>
        )}
        <br />
      </Wrapper>
      <FeedbackContainer>
        {formState.error ? (
          <>
            <Typography variant={"subtitle2"}>{formState.message}</Typography>
          </>
        ) : (
          <></>
        )}
      </FeedbackContainer>
    </PublicWrapper>
  );
};

export default Email;
