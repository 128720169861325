import styled from "styled-components";

export const ResponsiveContainer = styled.div`
  position: relative;
  height: auto;
  margin: auto;
  overflow: scroll;
  max-width: 1210px;
  padding: 0px 5px 0px 5px;
  background-color: white;
  &::-webkit-scrollbar {
    display: none;
  }
`;
