import React, { useState } from "react";
import TableNew from "../../../../../../globalComponents/tables/TableNew";
import { useCompanySalaryTypes } from "../../../../../../state/organization/companies/selectors";
import { useDispatch } from "react-redux";
import {
  listSalarytypes,
  Page,
  Salarytype,
  SalaryTypeVariant,
} from "../../../../../../state/organization/companies/detail/salarytype";
import SalarytypesSlideIn from "./SalarytypesSlideIn";
import { Typography } from "@mui/material";
import DeleteConfirmPopup from "../../../../../../globalComponents/tables/components/DeleteConfirm";
import { CoreRequest } from "../../../../../../config/backend/serviceInstances";
import { FadeContainer } from "../../../../../../globalComponents/tables/components/TableBase";
import IconButtonNew from "../../../../../../globalComponents/buttons/IconButtonNew";
import { faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const CompanySalarytypes = (props: { companyId: number }): JSX.Element => {
  const [slideIn, setSlideIn] = useState<{
    create: boolean;
    edit: { show: boolean; id: number | null };
    delete: { show: boolean; id: number | null };
  }>({
    create: false,
    edit: { show: false, id: null },
    delete: { show: false, id: null },
  });

  const deleteSalarytype = () => {
    CoreRequest.delete("/companies/salarytypes", {
      params: { salaryTypeId: slideIn.delete.id },
    }).then(() => {
      dispatch(
        listSalarytypes({
          companyId: props.companyId,
          page: salarytypes.page.page,
          pageSize: salarytypes.page.pageSize,
          sort: salarytypes.page.sorting,
          type: currentFilter(),
        })
      );
      setSlideIn({ ...slideIn, delete: { show: false, id: null } });
    });
  };
  const salarytypes = useCompanySalaryTypes();
  const dispatch = useDispatch();

  const currentFilter = (): SalaryTypeVariant => {
    if (salarytypes.All.selected) return SalaryTypeVariant.All;
    if (salarytypes.Predefined.selected) return SalaryTypeVariant.Predefined;
    else return SalaryTypeVariant.Custom;
  };

  const columns = [
    {
      id: 1,
      fieldName: "name",
      headerName: "Bezeichnung",
      ascCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "name",
            type: currentFilter(),
          })
        ),
      descCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "-name",
            type: currentFilter(),
          })
        ),
      initialCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "",
            type: currentFilter(),
          })
        ),
    },
    {
      id: 2,
      fieldName: "type",
      headerName: "Art",
      renderCell: (row: any) => {
        switch (row["type"]) {
          case "Predefined":
            return <Typography variant={"body1"}>Standard</Typography>;
          default:
            return <Typography variant={"body1"}>Individuell</Typography>;
        }
      },
      ascCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "type",
            type: currentFilter(),
          })
        ),
      descCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "-type",
            type: currentFilter(),
          })
        ),
      initialCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "",
            type: currentFilter(),
          })
        ),
    },
    {
      id: 3,
      fieldName: "st",
      headerName: "Versteuerung",
      ascCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "st",
            type: currentFilter(),
          })
        ),
      descCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "-st",
            type: currentFilter(),
          })
        ),
      initialCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "",
            type: currentFilter(),
          })
        ),
      renderCell: (row: any) => {
        switch (row["st"]) {
          case "LAUFEND":
            return <Typography variant={"body1"}>Laufend</Typography>;
          case "SONSTIG":
            return <Typography variant={"body1"}>Sonstig</Typography>;
          case "PAUSCHAL_ARBEITGEBER":
            return (
              <Typography variant={"body1"}>Pauschal Arbeitgeber</Typography>
            );
          case "PAUSCHAL_ARBEITNEHMER":
            return (
              <Typography variant={"body1"}>Pauschal Arbeitnehmer</Typography>
            );
          default:
            return <Typography variant={"body1"}>Frei</Typography>;
        }
      },
    },
    {
      id: 4,
      fieldName: "sv",
      headerName: "SV",
      ascCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "sv",
            type: currentFilter(),
          })
        ),
      descCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "-sv",
            type: currentFilter(),
          })
        ),
      initialCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "",
            type: currentFilter(),
          })
        ),
      renderCell: (row: any) => {
        switch (row["sv"]) {
          case "LAUFEND":
            return <Typography variant={"body1"}>Laufend</Typography>;
          case "SONSTIG":
            return <Typography variant={"body1"}>Sonstig</Typography>;
          case "PAUSCHAL_ARBEITGEBER":
            return (
              <Typography variant={"body1"}>Pauschal Arbeitgeber</Typography>
            );
          case "PAUSCHAL_ARBEITNEHMER":
            return (
              <Typography variant={"body1"}>Pauschal Arbeitnehmer</Typography>
            );
          default:
            return <Typography variant={"body1"}>Frei</Typography>;
        }
      },
    },
    {
      id: 5,
      fieldName: "gesamtBrutto",
      headerName: "Gesamtbrutto",
      ascCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "gesamtBrutto",
            type: currentFilter(),
          })
        ),
      descCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "-gesamtBrutto",
            type: currentFilter(),
          })
        ),
      initialCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "",
            type: currentFilter(),
          })
        ),
      renderCell: (row: any) => {
        if (row["gesamtBrutto"])
          return <Typography variant={"body1"}>Inkludiert</Typography>;
        else return <Typography variant={"body1"}>Ausgenommen</Typography>;
      },
    },
    {
      id: 6,
      fieldName: "pfaendbarkeit",
      headerName: "Pfändbarkeit",
      ascCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "pfaendbarkeit",
            type: currentFilter(),
          })
        ),
      descCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "-pfaendbarkeit",
            type: currentFilter(),
          })
        ),
      initialCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "",
            type: currentFilter(),
          })
        ),
      renderCell: (row: any) => {
        switch (row["pfaendbarkeit"]) {
          case "VOLL_PFAENDBAR":
            return <Typography variant={"body1"}>Voll Pfändbar</Typography>;
          case "NICHT_ZULAESSIG":
            return <Typography variant={"body1"}>Nicht Zulässig</Typography>;
          case "HALBER_UNTERHALT_3_4":
            return (
              <Typography variant={"body1"}>
                Halb Pfändbar Unterhalt 3/4
              </Typography>
            );
          case "NICHT_NICHT":
            return <Typography variant={"body1"}>Nicht Pfändbar</Typography>;
          default:
            return <Typography variant={"body1"}>Frei</Typography>;
        }
      },
    },
    {
      id: 7,
      fieldName: "factor",
      headerName: "Faktor",
      renderCell: (row: any) => {
        if (row["factor"]) return <Typography variant={"body1"}>Ja</Typography>;
        else return <Typography variant={"body1"}>Nein</Typography>;
      },
      ascCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "factor",
            type: currentFilter(),
          })
        ),
      descCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "-factor",
            type: currentFilter(),
          })
        ),
      initialCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "",
            type: currentFilter(),
          })
        ),
    },
    {
      id: 8,
      fieldName: "einheit",
      headerName: "Einheit",
      ascCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "einheit",
            type: currentFilter(),
          })
        ),
      descCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "-einheit",
            type: currentFilter(),
          })
        ),
      initialCallback: () =>
        dispatch(
          listSalarytypes({
            companyId: props.companyId,
            page: salarytypes.page.page,
            pageSize: salarytypes.page.pageSize,
            sort: "",
            type: currentFilter(),
          })
        ),
    },
    {
      id: 9,
      fieldName: "",
      initialCallback: () => console.log("cc"),
      descCallback: () => console.log("cc"),
      ascCallback: () => console.log("a"),
      headerName: "",
      actionColumn: true,
      renderCell: (row: any) => {
        return (
          <FadeContainer>
            <IconButtonNew
              icon={faPen}
              onClick={() =>
                setSlideIn({ ...slideIn, edit: { show: true, id: row.id } })
              }
            />
            &nbsp;
            <IconButtonNew
              icon={faTrashAlt}
              onClick={() =>
                setSlideIn({ ...slideIn, delete: { show: true, id: row.id } })
              }
            />
          </FadeContainer>
        );
      },
    },
  ];
  return (
    <>
      <TableNew
        title={"Lohnarten"}
        quickFilter={[
          {
            label: "Alle",
            onClick: () =>
              dispatch(
                listSalarytypes({
                  companyId: props.companyId,
                  page: 0,
                  pageSize: 25,
                  sort: "name",
                  type: SalaryTypeVariant.All,
                })
              ),
            selected: salarytypes.All.selected,
            count: salarytypes.All.count,
          },
          {
            label: "Standard",
            onClick: () =>
              dispatch(
                listSalarytypes({
                  companyId: props.companyId,
                  page: 0,
                  pageSize: 25,
                  sort: "name",
                  type: SalaryTypeVariant.Predefined,
                })
              ),
            selected: salarytypes.Predefined.selected,
            count: salarytypes.Predefined.count,
          },
          {
            label: "Individuelle",
            onClick: () =>
              dispatch(
                listSalarytypes({
                  companyId: props.companyId,
                  page: 0,
                  pageSize: 25,
                  sort: "name",
                  type: SalaryTypeVariant.Custom,
                })
              ),
            selected: salarytypes.Custom.selected,
            count: salarytypes.Custom.count,
          },
        ]}
        addResource={{
          label: "Lohnart hinzufügen",
          callBack: () => setSlideIn({ ...slideIn, create: true }),
        }}
        columns={columns}
        input={salarytypes.page as Page<Salarytype>}
        pageBack={() =>
          dispatch(
            listSalarytypes({
              companyId: props.companyId,
              page: salarytypes.page.page - 1,
              pageSize: 25,
              sort: salarytypes.page.sorting,
              type: currentFilter(),
            })
          )
        }
        pageForward={() =>
          dispatch(
            listSalarytypes({
              companyId: props.companyId,
              page: salarytypes.page.page + 1,
              pageSize: 25,
              sort: salarytypes.page.sorting,
              type: currentFilter(),
            })
          )
        }
      />
      {slideIn.create ? (
        <SalarytypesSlideIn
          companyId={props.companyId}
          title={"Lohnart hinzufügen"}
          confirmCallBack={() => {
            dispatch(
              listSalarytypes({
                companyId: props.companyId,
                page: salarytypes.page.page,
                pageSize: salarytypes.page.pageSize,
                sort: "-name",
                type: currentFilter(),
              })
            );
            setSlideIn({ ...slideIn, create: false });
          }}
          closeCallBack={() => setSlideIn({ ...slideIn, create: false })}
          variant={"CREATE"}
        />
      ) : (
        <></>
      )}
      {slideIn.edit.show ? (
        <SalarytypesSlideIn
          title={"Lohnart bearbeiten"}
          companyId={props.companyId}
          salaryType={
            salarytypes.page.data.filter(
              (item) => item.id == slideIn.edit.id
            )[0]
          }
          confirmCallBack={() => {
            dispatch(
              listSalarytypes({
                companyId: props.companyId,
                page: salarytypes.page.page,
                pageSize: salarytypes.page.pageSize,
                sort: "-name",
                type: currentFilter(),
              })
            );
            setSlideIn({ ...slideIn, edit: { show: false, id: null } });
          }}
          closeCallBack={() =>
            setSlideIn({ ...slideIn, edit: { show: false, id: null } })
          }
          variant={"EDIT"}
          id={slideIn.edit.id}
        />
      ) : (
        <></>
      )}
      {slideIn.delete.show ? (
        <DeleteConfirmPopup
          confirmCallBack={() => deleteSalarytype()}
          closeCallBack={() =>
            setSlideIn({ ...slideIn, delete: { show: false, id: null } })
          }
          text={"Wollen Sie die Lohnart wirklich löschen?"}
        />
      ) : (
        <></>
      )}
      <br />
      <br />
      <br />
    </>
  );
};

export default CompanySalarytypes;
