import React from "react";
import Flap from "./Flap";
import {
  faBook,
  faQuestionCircle,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Message from "../../globalComponents/notification/Message";
import { Typography } from "@mui/material";
import LinkStyled from "../../globalComponents/container/LinkStyled";
import EmployeeAvatarNew from "../../globalComponents/avatars/EmployeeAvatarNew";
import { NotificationContainer } from "../pages/overview/styles";

const HelpFlap: React.FC = () => {
  return (
    <Flap icon={faQuestionCircle}>
      <NotificationContainer>
        <Message
          icon={faBook}
          component1={
            <>
              <Typography variant={"subtitle2"}>Knowledge Base</Typography>
              <Typography
                variant={"body1"}
                noWrap
                marginTop={"10px"}
                color={"#4C4C4C"}
              >
                Learn to make the most out of HeavenHR
                <br /> with detailled instructions, tips and
                <br /> reference materials.{" "}
              </Typography>
            </>
          }
          component2={
            <Typography variant={"subtitle2"}>
              <LinkStyled to={"/app/help"}>Open Docs</LinkStyled>
            </Typography>
          }
        />
      </NotificationContainer>
      <NotificationContainer>
        <Message
          icon={faUser}
          component1={
            <>
              <Typography variant={"subtitle2"} marginBottom={"10px"}>
                Your support Agent
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <EmployeeAvatarNew
                  size={"SMALL"}
                  imgUrl={
                    "https://st.focusedcollection.com/3839757/i/1800/focused_178411440-stock-photo-male-office-worker-working-laptop.jpg"
                  }
                />
                <div>
                  <Typography variant={"body1"} marginLeft={"10px"}>
                    Paul Neumann
                  </Typography>
                  <Typography
                    variant={"body2"}
                    color={"#4C4C4C"}
                    marginLeft={"10px"}
                  >
                    Senior Customer Service Agent
                  </Typography>
                </div>
              </div>
              <Typography
                variant={"body1"}
                color={"#4C4C4C"}
                marginTop={"10px"}
              >
                Open a chat, write a mail or call under <br />
                030 / 555 743 991{" "}
              </Typography>
            </>
          }
          component2={
            <>
              <Typography variant={"subtitle2"}>
                <LinkStyled to={"/"}>Chat now</LinkStyled>
              </Typography>
              <Typography variant={"subtitle2"} marginTop={"10px"}>
                <LinkStyled to={"/"}>Mail</LinkStyled>
              </Typography>
              <Typography variant={"subtitle2"} marginTop={"10px"}>
                <LinkStyled to={"/"}>Call</LinkStyled>
              </Typography>
            </>
          }
        />
      </NotificationContainer>
    </Flap>
  );
};

export default HelpFlap;
