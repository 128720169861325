import React from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import MenuMobileFlapOutContainer from "./MenuMobileFlapOutContainer";
import FlapOutItem from "./FlapOutItem";
import { useHistory } from "react-router-dom";
import { menuAdmin } from "../../../config/menu";
import styled from "styled-components";

const MenuMobileContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default function MenuMobile(): React.ReactElement {
  //value just kept to keep material ui working
  const [value, setValue] = React.useState(0);
  const [flap, setFlap] = React.useState(false);
  const [key, setKey] = React.useState(0);
  const history = useHistory();

  const flapItems = menuAdmin.menuItems.map((item) => {
    return (
      <BottomNavigationAction
        label={item.title}
        icon={item.icon}
        key={item.key}
        onClick={() => {
          setKey(item.key);
          item.link !== false ? history.push(item.link) : <></>;
        }}
      />
    );
  });
  let children: JSX.Element[] = [<></>];
  let flapOut: JSX.Element = <></>;
  if (key !== 0) {
    const selectedItem = menuAdmin.menuItems.filter((selectedMenuItem) => {
      return selectedMenuItem.key === key;
    });
    if (selectedItem[0].children !== false) {
      children = selectedItem[0].children.map((child) => {
        return (
          <FlapOutItem
            key={child.key}
            icon={child.icon}
            title={child.title}
            link={child.link}
            closeFlap={(r) => setFlap(r)}
          />
        );
      });
      flapOut = (
        <MenuMobileFlapOutContainer
          closeFlapOut={(r) => setFlap(r)}
          title={selectedItem[0].title}
        >
          {children}
        </MenuMobileFlapOutContainer>
      );
    }
  }

  return (
    <MenuMobileContainer>
      {flap ? flapOut : <></>}
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setFlap(true);
          setValue(newValue);
        }}
        showLabels
      >
        {flapItems}
      </BottomNavigation>
    </MenuMobileContainer>
  );
}
