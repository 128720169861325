import { Roles } from "../state/general/auth";

export const decodeToken = (token: string): Roles => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  let roles: string[] = [];
  const JSONPay = JSON.parse(jsonPayload);
  if (JSONPay["https://heavenforeveryone.com/roles/v1"] !== undefined) {
    roles = JSONPay["https://heavenforeveryone.com/roles/v1"];
  }
  const HR_MANAGER: string[] = [];
  const EMPLOYEE: string[] = [];
  const ALL_ROLES: string[] = [];
  const Roles = { HR_MANAGER, EMPLOYEE, ALL_ROLES };

  if (roles.length > 0) {
    for (let i = 0; i < roles.length; i++) {
      const roleArray = roles[i].split(":");
      Roles.ALL_ROLES.push(roleArray[1]);
      switch (roleArray[0]) {
        case "hr-manager":
          Roles.HR_MANAGER.push(roleArray[1]);
          break;
        case "employee":
          Roles.EMPLOYEE.push(roleArray[1]);
          break;
      }
    }
  }

  return Roles;
};
