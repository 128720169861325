import { makeStyles } from "@mui/styles";
import styled from "styled-components";

export const useSmartTableStyles = makeStyles({
  root: {
    borderRadius: "20px!important",
    overflow: "hidden",
    "& .MuiDataGrid-cell": {
      borderBottom: "0px solid lightgrey!important",
      fontSize: "15px",
      fontWeight: 400,
      fontFamily: "Roboto",
      minHeight: "60px!important",
      lineHeight: "60px!important",
    },
    "& .MuiDataGrid-columnsContainer": {
      maxHeight: "40px!important",
      minHeight: "40px!important",
      lineHeight: "40px!important",
    },
    "& .MuiDataGrid-columnHeaderWrapper": {
      padding: "0px 40px 0px 50px",
      backgroundColor: "#f5f8fa",
      color: "#4c4c4c",
      textTransform: "uppercase",
      fontFamily: "Roboto",
      fontSize: "14px",
    },
    "& .MuiDataGrid-row": {
      padding: "0px 40px 0px 56px",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
      minHeight: "60px!important",
      maxHeight: "60px!important",
    },
    "& .MuiDataGrid-window": {
      top: "40px!important",
    },
    "& .MuiDataGrid-row.Mui-selected": {
      maxHeight: "60px!important",
    },
    "& .MuiDataGrid-columnSeparator": {
      visibility: "hidden",
    },

    "& .MuiDataGrid-viewport": {
      minHeight: "60px!important",
      maxHeight: "600px!important",
    },
    "& .MuiDataGrid-dataContainer": {
      minHeight: "0",
    },
    "& .MuiDataGrid-renderingZone": {
      minHeight: "60px!important",
      maxHeight: "600px!important",
    },
  },
});

export const FilterContainer = styled.div`
  width: 100%;
  margin: 0px 40px 40px 56px;
  display: inline;
`;
