import React from "react";
import { faFileAlt } from "@fortawesome/pro-solid-svg-icons";
import { AbsenceDocument } from "../../../../../../state/employees/types";
import ExpandOptionsButton from "../../../../../../globalComponents/buttons/ExpandOptionsButton";

interface FileButtonProps {
  documents: AbsenceDocument[];
  fileViewCallBack: (r: AbsenceDocument) => void;
}

const ShowFilesFlap = (props: FileButtonProps): JSX.Element => {
  return (
    <ExpandOptionsButton
      alternativeIcon={faFileAlt}
      items={props.documents.map((document) => {
        return {
          label: document.name,
          onClick: () => props.fileViewCallBack(document),
        };
      })}
    />
  );
};

export default ShowFilesFlap;
