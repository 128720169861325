import { Tooltip, withStyles } from "@material-ui/core";
import { colors } from "../../utils";

export const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: `${colors.white}`,
    border: `1px solid ${colors.gray11}`,
    color: `${colors.gray2}`,
  },
})(Tooltip);
