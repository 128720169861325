import React from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";

const ButtonContainer = styled.button<{ selected: boolean }>`
  width: auto;
  height: 40px;
  border: none;
  border-radius: 19px;
  color: #054d80;
  padding: 8px 20px 8px 20px;
  background-color: ${(props) => (props.selected ? "#e6edf2" : "white")};
  display: inline-flex;
  margin-right: 10px;
  &:hover {
    background-color: #e6edf2;
    cursor: pointer;
  }
`;

const ButtonFilter = (props: {
  label: string;
  onClick: () => void;
  count: number;
  selected: boolean;
}): JSX.Element => {
  if (props.selected)
    return (
      <ButtonContainer
        selected={props.selected}
        onClick={() => props.onClick()}
      >
        <Typography variant={"subtitle2"} marginRight={"5px"}>
          {props.label}
        </Typography>
        <Typography variant={"subtitle2"} color={"#999999"}>
          {props.count}
        </Typography>
      </ButtonContainer>
    );

  return (
    <ButtonContainer selected={props.selected} onClick={() => props.onClick()}>
      <Typography variant={"subtitle2"} marginRight={"5px"}>
        {props.label}
      </Typography>
      <Typography variant={"subtitle2"} color={"#999999"}>
        {props.count}
      </Typography>
    </ButtonContainer>
  );
};

export default ButtonFilter;
