import styled from "styled-components";
import { Animate } from "./useAnimate";

// types
export type StyleProps = {
  contentHeight?: number | string | null;
  scroll?: boolean;
  animationName?: string;
};

type Styles = StyleProps & {
  animate?: Animate;
};

// helper methods
const getOpenAnimationName = ({ animationName = "accordion" }: Styles) =>
  `${animationName}-open`;

const getHideAnimationName = ({ animationName = "accordion" }: Styles) =>
  `${animationName}-hide`;

const addAnimation = ({
  animate,
  contentHeight = "100px",
  ...props
}: Styles) => {
  if (animate === "animateOpen")
    return `
      height: ${contentHeight};
      animation-name: ${getOpenAnimationName(props)};
  `;
  if (animate === "animateClose")
    return `
      height: 0;
      animation-name: ${getHideAnimationName(props)};
  `;

  return "";
};

// styles
export const Container = styled.div``;

export const CollapsableContainer = styled.div`
  overflow: ${({ scroll = false }) => (scroll ? "scroll" : "hidden")};
  overflow: scroll;
  &: {
    ms-overflow-style: none;
    scrollbar-width: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  animation-duration: 0.2s;
  animation-direction: alternate;
  height: 0;

  ${addAnimation}

  @keyframes ${getOpenAnimationName} {
    from {
      height: 0;
    }
    to {
      height: ${({ contentHeight = "100px" }: Styles) => contentHeight};
    }
  }

  @keyframes ${getHideAnimationName} {
    from {
      height: ${({ contentHeight = "100px" }: Styles) => contentHeight};
    }
    to {
      height: 0;
    }
  }
`;
