import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material";
import {
  addMonths,
  endOfMonth,
  format,
  startOfMonth,
  subMonths,
} from "date-fns/fp";
import { compose } from "lodash/fp";
import { useWidth } from "./hooks";
import {
  CalendarContainer,
  Container,
  HeaderContainer,
  Legend,
  StyledCalendar,
  StyledLabel,
  StyledMonthPicker,
} from "./styles";
import { openOrgAbsenceForm } from "../utils";
import { listCalendarAbsences } from "../../../../../state/employees/absences/absence";
import { useAbsence } from "../../../../../state/employees/absences/selectors";

const CompanyCalendar: React.FC = () => {
  // hooks
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const absence = useAbsence();

  const { typography } = useTheme();
  const ref = useRef(null);
  const width = useWidth(ref);

  const [state] = useState({
    showId: 0,
    pagination: {
      page: 0,
      pageSize: 25,
      sort: "",
      startDate: format("yyyy-MM-dd")(startOfMonth(date)),
      endDate: format("yyyy-MM-dd")(endOfMonth(date)),
    },
  });

  useEffect(() => {
    dispatch(listCalendarAbsences(state.pagination));
  }, [state.pagination, date]);

  // methods
  const onIncrease = () => compose(setDate, addMonths(1))(date);

  const onDecrease = () => compose(setDate, subMonths(1))(date);

  return (
    <Container ref={ref} fontFamily={typography.fontFamily}>
      <HeaderContainer>
        <StyledMonthPicker
          width={width}
          date={date}
          onChange={setDate}
          onIncrease={onIncrease}
          onDecrease={onDecrease}
        />
        <Legend>
          <StyledLabel theme="APPROVED">Approved</StyledLabel>
          <StyledLabel theme="REQUESTED">Requested</StyledLabel>
          <StyledLabel theme="REJECTED">Rejected</StyledLabel>
          <StyledLabel theme="DRAFT">Draft</StyledLabel>
        </Legend>
      </HeaderContainer>
      <CalendarContainer>
        <StyledCalendar
          date={date}
          departments={absence.calendarData.departmentGrouping}
          absenceTypes={absence.calendarData.absenceTypes}
          onHighlightClick={openOrgAbsenceForm(dispatch)}
          width={width}
        />
      </CalendarContainer>
    </Container>
  );
};

export default CompanyCalendar;
