import React from "react";
import { Grid, Typography } from "@mui/material";
import styled from "styled-components";

const GridContainer = styled(Grid)`
  max-width: 35%;
  padding: 5px;
  border: 1px solid lightgrey;
  border-radius: 4px;
`;

export const GridContainerSv = styled(GridContainer)`
  max-width: 63%;
`;

const DeductionsTax = (): JSX.Element => {
  return (
    <GridContainerSv container columns={20}>
      <Grid item xs={1}>
        <Typography variant={"caption"}>SV</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"caption"}>KV Brutto</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"caption"}>RV Brutto</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"caption"}>AV Brutto</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"caption"}>PV Brutto</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"caption"}>KV Btrg.</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"caption"}>RV Btrg.</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"caption"}>AV Btrg.</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant={"caption"}>PV Btrg.</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"caption"}>Betrag</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant={"caption"}>L</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"caption"}>0.00</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"caption"}>5,666.67</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"caption"}>5,666.67</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"caption"}>0.00</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"caption"}>0.00</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"caption"}>527.00</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"caption"}>68.00</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant={"caption"}>0.00</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"caption"}>595.00</Typography>
      </Grid>
    </GridContainerSv>
  );
};

export default DeductionsTax;
