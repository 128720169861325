import React from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";

const StepTabContainer = styled.div<{ active: boolean }>`
  height: 60px;
  background-color: ${(props) => (props.active ? "#054d80" : "#FFFFFF")};
  border: ${(props) =>
    props.active ? "1px solid #054d80" : "1px solid #D9D9D9"};
  -webkit-clip-path: url(#scoop);
  clip-path: url(#scoop);
  color: ${(props) => (props.active ? "white" : "black")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  position: relative;
`;

const NumberHolder = styled.div<{ active: boolean }>`
  width: 40px;
  height: 40px;
  margin: 0px 10px 0px 10px;
  border-radius: 10px;
  background-color: ${(props) => (props.active ? "#377199" : "#F5F8FA")};
  color: ${(props) => (props.active ? "white" : "#054D80")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TextHolder = styled.div`
  min-width: 159px;
  max-width: 250px;
  margin: 0 10px 0 0;
`;

interface StepTabProps {
  title: string;
  active: boolean;
  step: number;
}

const StepTab = (props: StepTabProps): JSX.Element => {
  return (
    <StepTabContainer active={props.active}>
      <NumberHolder active={props.active}>
        <Typography variant={"subtitle2"}>{props.step}</Typography>
      </NumberHolder>
      <TextHolder>
        <Typography variant={"subtitle2"}>{props.title}</Typography>
      </TextHolder>
    </StepTabContainer>
  );
};

export default StepTab;
