import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ContentContainer } from "./components/styledComponents";
import SmartTable from "../../../../../globalComponents/smartOperations/smartTable/SmartTable";
import { GridValueGetterParams } from "@mui/x-data-grid";
import StatusChip from "../../../../../globalComponents/chips/StatusChip";
import { Typography } from "@mui/material";
import { getDateText } from "../../../../../utils/dates";
import EmployeeAvatar from "../../../../../globalComponents/avatars/EmployeeAvatar";
import { useDispatch } from "react-redux";
import { listPayrollAbsences } from "../../../../../state/payroll/absences";
import { usePayrollAbsences } from "../../../../../state/payroll/selectors";

const tableCells = [
  {
    field: "workContract",
    headerName: "Employee",
    width: 180,
    renderCell: (params: GridValueGetterParams) => (
      <EmployeeAvatar
        id={params.row.workContract.id}
        lastName={params.row.workContract.lastName}
        firstName={params.row.workContract.firstName}
        position={params.row.workContract.position}
        imgLink={params.row.employeeOverview?.imgLink}
      />
    ),
  },
  {
    field: "startDate",
    headerName: "Start Date",
    width: 150,
    renderCell: (params: GridValueGetterParams) => (
      <Typography variant={"body2"}>
        {getDateText(params.row.startDate)}
      </Typography>
    ),
  },
  {
    field: "endDate",
    headerName: "End Date",
    width: 200,
    renderCell: (params: GridValueGetterParams) => (
      <Typography variant={"body2"}>
        {getDateText(params.row.endDate)}
      </Typography>
    ),
  },
  {
    field: "absenceType",
    headerName: "Art",
    width: 150,
    renderCell: (params: GridValueGetterParams) => (
      <Typography variant={"body2"} marginLeft={"5px"}>
        {params.row.absenceType.description}
      </Typography>
    ),
  },
  {
    field: "approvalStatus",
    headerName: "status",
    width: 150,
    renderCell: (params: GridValueGetterParams) => (
      <StatusChip status={params.row.approvalStatus} />
    ),
  },
];

const Absences = (): React.ReactElement => {
  const {
    formState: {},
  } = useForm();
  const dispatch = useDispatch();
  const absences = usePayrollAbsences();
  useEffect(() => {
    dispatch(
      listPayrollAbsences({
        page: 0,
        pageSize: 20,
        sort: "-startDate",
        startDate: "2020-01-01",
        endDate: "2021-11-01",
      })
    );
  }, []);
  return (
    <>
      <ContentContainer>
        <SmartTable
          deleteEndpoint={"/employees?employeeId="}
          columns={tableCells}
          deleteConfirmMessage={"Wollen Sie den Antrag wirklich löschen?"}
          input={{
            error: absences.error,
            errorMessage: absences.errorMessage,
            data: absences.data.data,
            loading: absences.loading,
          }}
          height={"500px"}
          updateInputCallback={() => console.log("reload")}
        />
      </ContentContainer>
    </>
  );
};

export default Absences;
