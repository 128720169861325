import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { StyledParagraph } from "../../../Typogrophy";
import { colors } from "../../../../../../../utils";
import Accordion from "../Accordion";
import {
  getIconFontSize,
  Text,
  getBorderBottomStyle,
  getFontSize,
} from "../styles";

export const Container = styled.div``;

export const DepartmentButton = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
`;

export const DepartmentName = styled(Text)`
  flex: 8;
`;
export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  height: ${getIconFontSize}px;
  width: ${getIconFontSize}px;
  color: ${colors.gray3};
  flex: 1;
`;

export const DepartmentSize = styled(Text)`
  text-align: center;
  flex: 1;
`;

export const StyledArrowIcon = styled(FontAwesomeIcon)`
  height: ${getIconFontSize}px !important;
  color: ${colors.gray1};
  flex: 2;
`;

export const StyledAccordion = styled(Accordion)`
  ${getBorderBottomStyle}
`;

export const StyledLabel = styled(StyledParagraph)`
  background: ${colors.gray16};
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: default;
  padding: 5px 15px;
  font-size: 15px;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: ${getFontSize}px;
`;
