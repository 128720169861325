import React from "react";
import HeaderCell, { SortState } from "./HeaderCell";
import styled from "styled-components";
import { Page } from "../../../state/organization/companies/detail/salarytype";

const TableNewContainer = styled.table`
  width: 100%;
  background-color: white;
  border-spacing: 0px;
`;

export const FadeContainer = styled.div`
  opacity: 0;
  transition-duration: 0.5s;
  display: inline-flex;
`;

const TableNewRow = styled.tr`
  height: 60px;
  font-size: 15px;
  font-weight: 400;
  > * {
    &:first-child {
      padding-left: 40px;
    }
  }
  &:hover {
    &:hover ${FadeContainer} {
      opacity: 1;
    }
  }
`;

const TableNewHeader = styled.thead`
  text-transform: uppercase;
  color: #4c4c4c;
  font-size: 14px;
  font-weight: 500;
`;

const HeaderRow = styled.tr`
  height: 40px;
  > * {
    &:first-child {
      padding-left: 40px;
    }
  }
`;

export const TableNewCell = styled.td<{ alignment?: boolean }>`
  text-align: ${(props) => (props.alignment ? "center" : "left")};
  border-bottom: 1px solid #d9d9d9;
  padding: 0px 5px 0px 5px;
`;

const TableHeaderCell = styled.td<{ alignment?: boolean }>`
  position: sticky;
  background-color: #f5f8fa;
  border-bottom: 1px solid #d9d9d9;
  z-index: 2;
  top: 0;
  text-align: ${(props) => (props.alignment ? "center" : "left")};
`;

const TableNewBody = styled.tbody`
  overflow: scroll;
`;

export interface Column {
  id: number;
  fieldName: string;
  headerName: string;
  ascCallback: () => void;
  descCallback: () => void;
  initialCallback: () => void;
  actionColumn?: boolean;
  renderCell?: (row: any) => JSX.Element;
}

interface TableBaseProps {
  columns: Column[];
  input: Page<any>;
}

const TableBase = (props: TableBaseProps): JSX.Element => {
  const checkSortState = (FieldName: string, Sorting: string): SortState => {
    if (FieldName == Sorting) return SortState.Ascending;
    if (FieldName == Sorting.substring(1) && Sorting.charAt(0) == "-")
      return SortState.Descending;
    else return SortState.Unsorted;
  };
  return (
    <TableNewContainer>
      <TableNewHeader>
        <HeaderRow>
          {props.columns.map((headerCell) => {
            return (
              <TableHeaderCell key={headerCell.id}>
                <HeaderCell
                  title={headerCell.headerName}
                  ascCallBack={headerCell.ascCallback}
                  descCallBack={headerCell.descCallback}
                  initialCallBack={headerCell.initialCallback}
                  actionColumn={headerCell.actionColumn}
                  sortState={checkSortState(
                    headerCell.fieldName,
                    props.input.sorting
                  )}
                />
              </TableHeaderCell>
            );
          })}
        </HeaderRow>
      </TableNewHeader>
      <TableNewBody>
        {props.input.data.map((row) => {
          return (
            <TableNewRow key={row.id}>
              {props.columns.map((cell) => {
                return (
                  <TableNewCell
                    key={`${row.id}-${cell.id}`}
                    alignment={cell.actionColumn}
                  >
                    {cell.renderCell !== undefined
                      ? cell.renderCell!(row)
                      : row[cell.fieldName as keyof typeof row]}
                  </TableNewCell>
                );
              })}
            </TableNewRow>
          );
        })}
      </TableNewBody>
      <tfoot />
    </TableNewContainer>
  );
};

export default TableBase;
