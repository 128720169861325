import { FormFieldType } from "../../../../../globalComponents/dataCard/utils";

export const BasicDataFields = [
  {
    fieldName: "firstName",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true, minLength: 2 },
    label: "Vorname",
  },
  {
    fieldName: "lastName",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true },
    label: "Nachname",
  },
  {
    fieldName: "email",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true },
    label: "Email",
  },
  {
    fieldName: "phoneNumber",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true },
    label: "Telefonnummer",
  },
  {
    fieldName: "personalNr",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true },
    label: "Personalnummer",
  },
  {
    fieldName: "street",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true },
    label: "Strasse",
  },
  {
    fieldName: "postalCode",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true },
    label: "Postleitzahl",
  },
  {
    fieldName: "city",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true },
    label: "Stadt",
  },
  {
    fieldName: "sex",
    defaultValue: "",
    type: FormFieldType.SELECT,
    validation: { required: true },
    label: "Geschlecht",
    menuItems: [
      { label: "Männlich", value: 0 },
      { label: "Weiblich", value: 1 },
      { label: "Divers", value: 2 },
    ],
  },
];

export const TaxDataFields = [
  {
    fieldName: "birthDate",
    defaultValue: "",
    type: FormFieldType.DATE,
    validation: { required: true },
    label: "Geburtsdatum",
  },
  {
    fieldName: "taxId",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true, minLength: 2 },
    label: "Steuernummer",
  },
  {
    fieldName: "taxClass",
    defaultValue: "",
    type: FormFieldType.SELECT,
    validation: { required: true },
    label: "Steuerklasse",
    menuItems: [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
      { label: "6", value: 6 },
    ],
  },
  {
    fieldName: "taxFactor",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: false },
    label: "Faktor",
  },
  {
    fieldName: "jfreib",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: false },
    label: "Jahresfreibetrag",
  },
  {
    fieldName: "jhinzu",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: false },
    label: "Jahreshinzurechnungsbetrag",
  },
  {
    fieldName: "lzzfreib",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: false },
    label: "Freibetrag für Lohnzahlungszeitraum",
  },
  {
    fieldName: "lzzhinzu",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: false },
    label: "Hinzurechnugnsbetrag für Lohnzahlungszeitraum",
  },
  {
    fieldName: "religion",
    defaultValue: "",
    type: FormFieldType.SELECT,
    validation: { required: true },
    label: "Religionszugehörigkeit",
    menuItems: [
      { label: "Konfessionslos", value: 0 },
      { label: "Römisch-Katholisch", value: 1 },
    ],
  },
  {
    fieldName: "children",
    defaultValue: "",
    type: FormFieldType.BOOLEAN,
    validation: { required: true },
    label: "Kinder",
  },
  {
    fieldName: "childrenAllowance",
    defaultValue: "",
    type: FormFieldType.SELECT,
    validation: { required: false },
    label: "Kinder",
    menuItems: [
      { label: "0.5", value: 0.5 },
      { label: "1", value: 1 },
      { label: "1.5", value: 1.5 },
      { label: "2", value: 2 },
      { label: "2.5", value: 2.5 },
      { label: "3", value: 3 },
    ],
  },
];

export const SocialDataFields = [
  {
    fieldName: "privateHealth",
    defaultValue: "",
    type: FormFieldType.BOOLEAN,
    validation: { required: true },
    label: "Privatversichert?",
  },
  {
    fieldName: "employerContribution",
    defaultValue: "",
    type: FormFieldType.BOOLEAN,
    validation: { required: true },
    label: "Arbeitgeberzuschuss zur PKV",
  },
  {
    fieldName: "privateHealthRate",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true },
    label: "Beitrag zur PKV",
  },
  {
    fieldName: "privateNurseRate",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true },
    label: "Beitrag zur PPV",
  },
  {
    fieldName: "healthInsurer",
    defaultValue: "",
    type: FormFieldType.SELECT,
    validation: { required: true },
    label: "Krankenkasse",
    menuItems: [{ label: "Techniker Krankenkasse", value: "TK" }],
  },
  {
    fieldName: "svNumber",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true },
    label: "Sozialversicherungsnummer",
  },
  {
    fieldName: "privatePension",
    defaultValue: "",
    type: FormFieldType.BOOLEAN,
    validation: { required: true },
    label: "Private RV",
  },
];
