import React from "react";
import SlideInFrame, {
  ButtonContainer,
} from "../../../../../../globalComponents/tables/components/SlideInFrame";
import TextFieldController from "../../../../../../globalComponents/formFields/TextFieldController";
import SelectMenuInput from "../../../../../../globalComponents/formFields/staticSelect/SelectMenuInput";
import SelectBoolean from "../../../../../../globalComponents/formFields/SelectBoolean";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import { CoreRequest } from "../../../../../../config/backend/serviceInstances";
import styled from "styled-components";
import { Salarytype } from "../../../../../../state/organization/companies/detail/salarytype";

export const FormOverflow = styled.form`
  padding-bottom: 80px;
`;

const SalarytypesSlideIn = (props: {
  variant: "EDIT" | "CREATE";
  salaryType?: Salarytype;
  title: string;
  confirmCallBack: () => void;
  closeCallBack: () => void;
  companyId: number;
  id?: number | null;
}): JSX.Element => {
  const { control, handleSubmit, watch } = useForm();
  const { st, factor } = watch();
  const onSubmit = (d: any) => {
    if (props.variant == "CREATE") {
      d["companyId"] = props.companyId;
      CoreRequest.post("/companies/salarytypes", d).then(() =>
        props.confirmCallBack()
      );
    } else if (props.variant == "EDIT" && props.id !== null) {
      d["id"] = props.id;
      CoreRequest.patch("/companies/salarytypes", d).then(() =>
        props.confirmCallBack()
      );
    }
  };

  const DefaultValues: Salarytype =
    props.salaryType !== undefined
      ? props.salaryType
      : {
          id: 0,
          companyId: 0,
          einheit: null,
          taxRate: null,
          factor: false,
          gesamtBrutto: false,
          name: "",
          pfaendbarkeit: "",
          st: "",
          sv: "",
          type: "",
          factorValue: null,
        };

  return (
    <SlideInFrame
      title={"Lohnart hinzufügen"}
      confirmCallBack={props.confirmCallBack}
      closeCallBack={props.closeCallBack}
    >
      <FormOverflow onSubmit={handleSubmit(onSubmit)}>
        <TextFieldController
          control={control}
          defaultValue={DefaultValues.name}
          label={"Bezeichnung"}
          fieldName={"name"}
        />
        <br />
        <SelectMenuInput
          control={control}
          defaultValue={DefaultValues.st}
          label={"Versteuerung"}
          fieldName={"st"}
          errorMessage={""}
          menuItems={[
            { label: "Laufend", value: "LAUFEND" },
            { label: "Sonstig", value: "SONSTIG" },
            { label: "Paschaul (AG)", value: "PAUSCHAL_ARBEITGEBER" },
            { label: "Paschaul (AN)", value: "PAUSCHAL_ARBEITNEHMER" },
            { label: "Mehrjähriger Bezug", value: "MEHRJAEHRIG" },
            { label: "Frei", value: "FREI" },
          ]}
        />
        {st == "PAUSCHAL_ARBEITGEBER" || st == "PAUSCHAL_ARBEITNEHMER" ? (
          <>
            <br />
            <TextFieldController
              control={control}
              defaultValue={DefaultValues.taxRate}
              label={"Steuerrate"}
              fieldName={"taxRate"}
              errorMessage={""}
            />
          </>
        ) : (
          <></>
        )}
        <br />
        <SelectMenuInput
          control={control}
          defaultValue={DefaultValues.sv}
          label={"Sozialversicherung"}
          fieldName={"sv"}
          errorMessage={""}
          menuItems={[
            { label: "Laufend", value: "LAUFEND" },
            { label: "Sonstig", value: "SONSTIG" },
            { label: "Frei", value: "FREI" },
          ]}
        />
        <br />
        <SelectBoolean
          control={control}
          defaultValue={DefaultValues.gesamtBrutto.toString()}
          label={"Gesamtbrutto"}
          fieldName={"gesamtBrutto"}
          errorText={""}
        />
        <br />
        <SelectMenuInput
          control={control}
          defaultValue={DefaultValues.pfaendbarkeit}
          label={"Pfändbarkeit"}
          fieldName={"pfaendbarkeit"}
          errorMessage={""}
          menuItems={[
            { label: "Nicht zulässig", value: "NICHT_ZULAESSIG" },
            { label: "Voll pfändbar", value: "VOLL_PFAENDBAR" },
            {
              label: "Halb Pfändbar / Unterhalt 3/4",
              value: "HALBER_UNTERHALT_3_4",
            },
            {
              label: "Nicht pfändbar / Unterhalt: nicht pfändbar",
              value: "NICHT_NICHT",
            },
            {
              label: "Nicht pfändbar / halb pfändbar",
              value: "NICHT_UNTERHALT_HALB",
            },
          ]}
        />
        <br />
        <SelectBoolean
          control={control}
          defaultValue={DefaultValues.factor.toString()}
          label={"Faktorversteuerung"}
          fieldName={"factor"}
          errorText={""}
        />
        {factor == "true" ? (
          <>
            <br />
            <SelectMenuInput
              control={control}
              defaultValue={DefaultValues.factorValue}
              label={"Faktoreinheit"}
              fieldName={"factorValue"}
              errorMessage={""}
              menuItems={[
                { label: "Stunden", value: "STUNDEN" },
                { label: "Tage", value: "TAGE" },
              ]}
            />
            <br />
            <TextFieldController
              control={control}
              defaultValue={DefaultValues.einheit}
              label={"Bezeichnung auf Abrechnung"}
              fieldName={"einheit"}
              errorMessage={""}
            />
          </>
        ) : (
          <></>
        )}
        <br />
        <ButtonContainer>
          <Button variant={"contained"} color={"primary"} type={"submit"}>
            Bestätigen
          </Button>
        </ButtonContainer>
      </FormOverflow>
    </SlideInFrame>
  );
};
export default SalarytypesSlideIn;
