const entityTypes = [
  { label: "Einzelunternehmen", value: "Einzelunternehmen" },
  { label: "GmbH", value: "GmbH" },
  { label: "Unternehmergesellschaft (UG)", value: "UG" },
  { label: "Aktiengesellschaft (AG)", value: "AG" },
  { label: "Kommanditgesellschaft auf Aktien (KGaA)", value: "KGaA" },
  { label: "Offene Handelsgesellschaft (OHG)", value: "OHG" },
  { label: "Kommanditgesellschaft (KG)", value: "KG" },
  { label: "GmbH & Co. KG", value: "GmbHCoKg" },
  { label: "AG & Co. KG", value: "AgCoKg" },
  { label: "Gesellschaft bürgerlichen Rechts (GbR)", value: "GbR" },
  { label: "Partnergesellschaft", value: "Partnergesellschaft" },
];

export default entityTypes;
