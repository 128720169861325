import React from "react";
import styled from "styled-components";

export const TextButtonC = styled.div`
  display: inline-flex;
  align-items: center;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

interface TextButtonProps {
  onClick: () => void;
  status: "ONE" | "TWO";
  status1: JSX.Element;
  status2: JSX.Element;
}

const TextButton = (props: TextButtonProps): JSX.Element => {
  return (
    <TextButtonC onClick={() => props.onClick()}>
      {props.status == "ONE" ? props.status1 : props.status2}
    </TextButtonC>
  );
};

export default TextButton;
