import React, { useState } from "react";
import styled from "styled-components";
import { CoreRequest } from "../../../../../config/backend/serviceInstances";
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  Typography,
} from "@mui/material";

import IconButtonNew from "../../../../../globalComponents/buttons/IconButtonNew";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const LogoContainer = styled.div`
  position: relative;
`;

const FlapOutContainer = styled.form`
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 5px 0px lightgrey;
  border-radius: 5px;
  margin-top: 5px;
  padding: 5px;
  right: 0px;
  display: flex;
  flex-direction: row;
  height: 30px;
  align-items: center;
  z-index: 2;
`;

interface LogoUploadButtonProps {
  icon: IconProp;
  successFunction: (r: string) => void;
  endpoint: string;
  errorMessage: string;
  id: number;
}

const FileUploadButton = (props: LogoUploadButtonProps): React.ReactElement => {
  const [flap, setFlap] = useState(false);
  const [data, setData] = useState<{
    file: File | null;
    loading: boolean;
    error: boolean;
  }>({
    file: null,
    loading: false,
    error: false,
  });
  const uploadPicture = (
    file: File | null,
    e: React.FormEvent<HTMLFormElement>
  ) => {
    setData({ ...data, loading: true, error: false });
    e.preventDefault();
    const formData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    if (file !== null) {
      formData.append("file", file);
      CoreRequest.post(props.endpoint, formData, config)
        .then((response) => {
          setFlap(false);
          setData({ ...data, loading: false, error: false });
          props.successFunction(response.data.imgId);
        })
        .catch(() => setData({ ...data, error: true, loading: false }));
    }
  };

  return (
    <LogoContainer>
      <IconButtonNew
        focused={flap}
        onClick={() => {
          setFlap(!flap);
        }}
        icon={props.icon}
      />
      {flap ? (
        <ClickAwayListener onClickAway={() => setFlap(false)}>
          <FlapOutContainer onSubmit={(e) => uploadPicture(data.file, e)}>
            <input
              type={"file"}
              id={"myFile"}
              onChange={(e) => {
                if (e.target.files !== null) {
                  setData({ ...data, file: e.target.files[0] });
                }
              }}
            />
            {data.loading ? (
              <CircularProgress />
            ) : (
              <Button
                type={"submit"}
                size={"small"}
                variant={"contained"}
                color={"primary"}
              >
                Hochladen
              </Button>
            )}
            {data.error ? (
              <Typography
                variant={"subtitle2"}
                align={"center"}
                color={"secondary"}
              >
                &nbsp; {props.errorMessage}
              </Typography>
            ) : (
              <></>
            )}
          </FlapOutContainer>
        </ClickAwayListener>
      ) : (
        <></>
      )}
    </LogoContainer>
  );
};

export default FileUploadButton;
