import { Weekday } from "./WorkScheduleRow";

export function giveRow(weekday: Weekday): string {
  switch (weekday) {
    case Weekday.Mon:
      return "3/3";
    case Weekday.Tue:
      return "4/4";
    case Weekday.Wed:
      return "5/5";
    case Weekday.Thu:
      return "6/6";
    case Weekday.Fri:
      return "7/7";
    case Weekday.Sat:
      return "8/8";
    default:
      return "9/9";
  }
}
