import { LabelValuePair } from "../../../globalComponents/formFields/staticSelect/SelectMenuInput";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CoreRequest } from "../../../config/backend/serviceInstances";
import { DataState } from "./detail/companyDetail";

// action names
export enum Companies {
  CompaniesTable = "COMPANIES_TABLE",
  CompaniesOrg = "COMPANIES_ORG",
}

export type CompanyOrgTableElement = {
  id: number;
  motherCompany: number | null;
  name: string;
  city: string;
  entityType: string;
  imgLink: string;
  company: LabelValuePair;
  managingDirectors: [];
  children: CompanyOrgTableElement[];
  activeEmployees: number;
};

export interface CompanyTableOrgState extends DataState {
  data: CompanyOrgTableElement[];
}

export interface CompaniesState {
  tableView: CompanyTableOrgState;
  orgView: CompanyTableOrgState;
}

const CompaniesOrgTableInitial: CompanyTableOrgState = {
  loading: false,
  error: false,
  data: [],
};

export const CompaniesStateI: CompaniesState = {
  tableView: CompaniesOrgTableInitial,
  orgView: CompaniesOrgTableInitial,
};

export const listCompaniesOrg = createAsyncThunk(
  Companies.CompaniesOrg,
  async () => {
    const response = await CoreRequest.get(`/companies/org`);

    if (response.status > 299) {
      // Return the known error for future handling
      return response.data.error;
    }

    return response.data as CompanyOrgTableElement[];
  }
);

export const listCompaniesTableView = createAsyncThunk(
  Companies.CompaniesTable,
  async () => {
    const response = await CoreRequest.get(`/companies`);

    if (response.status > 299) {
      // Return the known error for future handling
      return response.data.error;
    }

    return response.data as CompanyOrgTableElement[];
  }
);

const companies = createSlice({
  name: "/companies",
  initialState: CompaniesStateI,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listCompaniesOrg.pending, (state) => {
      state.orgView.loading = true;
      state.orgView.data = [];
    });
    builder.addCase(listCompaniesOrg.fulfilled, (state, action) => {
      state.orgView.loading = false;
      state.orgView.data = action.payload;
      state.orgView.error = false;
    });
    builder.addCase(listCompaniesOrg.rejected, (state, action) => {
      state.orgView.loading = false;
      state.orgView.errorMessage = action.error.message as string;
      state.orgView.error = true;
    });
    builder.addCase(listCompaniesTableView.pending, (state) => {
      state.tableView.loading = true;
      state.tableView.data = [];
    });
    builder.addCase(listCompaniesTableView.fulfilled, (state, action) => {
      state.tableView.loading = false;
      state.tableView.data = action.payload;
      state.tableView.error = false;
    });
    builder.addCase(listCompaniesTableView.rejected, (state, action) => {
      state.tableView.loading = false;
      state.tableView.errorMessage = action.error.message as string;
      state.tableView.error = true;
    });
  },
});

export default companies;
