import React from "react";
import WorkScheduleRow, { Griddy, Weekday } from "./WorkScheduleRow";
import styled from "styled-components";
import { Button, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import SelectMenuInput from "../../../../../../globalComponents/formFields/staticSelect/SelectMenuInput";
import { useNewWorkSchedule } from "../../../../../../state/employees/employeeDetail/selectors";
import { v4 as uuidv4 } from "uuid";
import DateFieldRHF from "../../../../../../globalComponents/formFields/dateField/DateFieldRHF";
import TooltipNew from "../../../../../../globalComponents/tooltipNew/TooltipNew";
import TextButton from "../../../../../../globalComponents/buttons/TextButton";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEmployeeWorkContractBasic } from "../../../../../../state/employees/employees/selectors";
import { AddLeadingZero } from "./helper/workScheduleGridHelper";

const WorkScheduleContainer = styled.div`
  padding: 20px;
`;

const WorkScheduleGrid = styled.div`
  display: grid;
  grid-template-rows: repeat(9, 1fr);
  grid-template-columns: repeat(136, 1fr);
`;

const TimeWrapper = styled.div<{ left?: string; right?: string }>`
  position: absolute;
  bottom: 20px;
  left: ${(props) => (props.left ? props.left : "")};
  right: ${(props) => (props.right ? props.right : "")};
`;

const Tickline_6_Hours = styled.div<{ left?: boolean; big?: boolean }>`
  position: absolute;
  width: 0px;
  height: ${(props) => (props.big ? "395px" : "12px")};
  right: -1px;
  left: ${(props) => (props.left ? "0px" : "")};
  bottom: ${(props) => (props.big ? "-382px" : "0px")};
  border-right: 1px solid lightgrey;
`;

const Grid_Item_Quarter_Hour_Mark = styled.div`
  position: relative;
  grid-column-start: span 24;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid lightgrey;
  margin-top: auto;
  height: 20px;
`;

export interface WorkingTime {
  day: Weekday;
  startHour: number;
  startMin: number;
  endHour: number;
  endMin: number;
  breaks: { start: number; end: number }[];
}

const WorkScheduleAdd = (props: { callback: () => void }): JSX.Element => {
  const { control } = useForm();
  const newWorkSchedule = useNewWorkSchedule();
  const contractualWorkingHours = useEmployeeWorkContractBasic().data
    .workingHoursPerWeek;

  const calculateTotalHours = (): { hours: number; minutes: number } => {
    let minutes = 0;
    const weekdays = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    weekdays.forEach(
      (value) =>
        (minutes +=
          newWorkSchedule.data[value as keyof typeof newWorkSchedule.data]
            .hours *
            60 +
          newWorkSchedule.data[value as keyof typeof newWorkSchedule.data]
            .minutes)
    );
    return { hours: Math.floor(minutes / 60), minutes: minutes % 60 };
  };

  const WorkScheduleHours = calculateTotalHours();
  let hoursColor: string;
  if (
    contractualWorkingHours !== null &&
    contractualWorkingHours > WorkScheduleHours.hours
  )
    hoursColor = "#F05455";
  else if (
    contractualWorkingHours == WorkScheduleHours.hours &&
    WorkScheduleHours.minutes == 0
  )
    hoursColor = "#008C5B";
  else {
    hoursColor = "#F05455";
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <TextButton
          onClick={() => props.callback()}
          status={"ONE"}
          status1={
            <Typography
              variant={"subtitle2"}
              color={"#054d80"}
              marginRight={"5px"}
            >
              Arbeitszeitmodelle
            </Typography>
          }
          status2={
            <Typography variant={"subtitle2"}>Arbeitszeitmodelle</Typography>
          }
        />
        <FontAwesomeIcon icon={faChevronRight} size={"xs"} />
        <Typography variant={"body2"} marginLeft={"5px"}>
          Arbeitszeitmodell hinzufügen
        </Typography>
      </div>
      <Typography variant={"h3"} marginTop={"40px"} marginBottom={"40px"}>
        Neues Arbeitszeitmodell
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SelectMenuInput
          label={"Arbeitszeitmodell"}
          control={control}
          fieldName={"workScheduleType"}
          defaultValue={"CONTROL"}
          errorMessage={""}
          menuItems={[
            { label: "Gleitzeit/ Vertrauensarbeitszeit", value: "TRUST" },
            { label: "Kontrollierte Arbeitszeit", value: "CONTROL" },
          ]}
        />
        <DateFieldRHF
          label={"Gültig ab"}
          control={control}
          fieldName={"validFrom"}
          defaultValue={""}
          endYear={2024}
          startYear={1987}
          errorMessage={""}
        />
        <div>
          <div style={{ display: "inline-flex" }}>
            <Typography variant={"body2"} marginRight={"5px"}>
              Vertrag{" "}
            </Typography>
            <TooltipNew message={"Arbeitszeit pro Woche laut Arbeitsvertrag"} />
          </div>
          <Typography variant={"subtitle2"} marginTop={"5px"}>
            {contractualWorkingHours}h : 00m
          </Typography>
        </div>
        <div>
          <div style={{ display: "inline-flex" }}>
            <Typography variant={"body2"} marginRight={"5px"}>
              Modell{" "}
            </Typography>
            <TooltipNew
              message={
                "Arbeitszeit pro Woche laut Arbeitszeitmodell. Die vertraglich vereinbarte Arbeitszeit muss mit der Arbeitszeit im Modell übereinstimmen."
              }
            />
          </div>
          <Typography
            variant={"subtitle2"}
            marginTop={"5px"}
            color={hoursColor}
          >
            {AddLeadingZero(WorkScheduleHours.hours)}h :{" "}
            {AddLeadingZero(WorkScheduleHours.minutes)}m
          </Typography>
        </div>
        <Button
          variant={"contained"}
          color={"primary"}
          disabled={hoursColor !== "#008C5B"}
        >
          Speichern
        </Button>
      </div>
      <WorkScheduleContainer>
        <WorkScheduleGrid>
          <Griddy
            row={"1/1"}
            column={"1/20"}
            color={"transparent"}
            align={"flex-start"}
            hideBorder
          >
            <Typography variant={"subtitle2"}>Wochentag</Typography>
          </Griddy>
          <Griddy
            row={"1/1"}
            column={"21/115"}
            color={"transparent"}
            hideBorder
          >
            <Typography variant={"subtitle2"}>Verteilung</Typography>
          </Griddy>
          <Griddy
            row={"1/1"}
            column={"116/136"}
            color={"transparent"}
            align={"flex-end"}
            hideBorder
          >
            <Typography variant={"subtitle2"}>Stunden</Typography>
          </Griddy>
          <Griddy row={"2/2"} column={"1/20"} color={"transparent"} />
          <Grid_Item_Quarter_Hour_Mark>
            <Tickline_6_Hours left />
            <Tickline_6_Hours />
            <TimeWrapper left={"0px"}>
              <Typography variant={"caption"}>00:00 Uhr</Typography>
            </TimeWrapper>
          </Grid_Item_Quarter_Hour_Mark>
          <Grid_Item_Quarter_Hour_Mark>
            <TimeWrapper left={"-30px"}>
              <Typography variant={"caption"}>06:00 Uhr</Typography>
            </TimeWrapper>
            <Tickline_6_Hours />
          </Grid_Item_Quarter_Hour_Mark>
          <Grid_Item_Quarter_Hour_Mark>
            <Tickline_6_Hours />
            <TimeWrapper left={"-30px"}>
              <Typography variant={"caption"}>12:00 Uhr</Typography>
            </TimeWrapper>
          </Grid_Item_Quarter_Hour_Mark>
          <Grid_Item_Quarter_Hour_Mark>
            <Tickline_6_Hours big />
            <TimeWrapper left={"-30px"}>
              <Typography variant={"caption"}>18:00 Uhr</Typography>
            </TimeWrapper>
            <TimeWrapper right={"0px"}>
              <Typography variant={"caption"}>24:00 Uhr</Typography>
            </TimeWrapper>
          </Grid_Item_Quarter_Hour_Mark>
          <Griddy row={"2/2"} column={"116/136"} color={"transparent"}>
            <Typography variant={"subtitle2"} />
          </Griddy>
          <WorkScheduleRow
            weekday={Weekday.Mon}
            day={newWorkSchedule.data.Monday}
            key={uuidv4()}
          />
          <WorkScheduleRow
            weekday={Weekday.Tue}
            day={newWorkSchedule.data.Tuesday}
            key={uuidv4()}
          />
          <WorkScheduleRow
            weekday={Weekday.Wed}
            day={newWorkSchedule.data.Wednesday}
            key={uuidv4()}
          />
          <WorkScheduleRow
            weekday={Weekday.Thu}
            day={newWorkSchedule.data.Thursday}
            key={uuidv4()}
          />
          <WorkScheduleRow
            weekday={Weekday.Fri}
            day={newWorkSchedule.data.Friday}
            key={uuidv4()}
          />
          <WorkScheduleRow
            weekday={Weekday.Sat}
            day={newWorkSchedule.data.Saturday}
            key={uuidv4()}
          />
          <WorkScheduleRow
            weekday={Weekday.Sun}
            day={newWorkSchedule.data.Sunday}
            key={uuidv4()}
          />
        </WorkScheduleGrid>
      </WorkScheduleContainer>
    </>
  );
};

export default WorkScheduleAdd;
