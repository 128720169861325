import React from "react";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { ContentContainer } from "./components/styledComponents";
import SelectBoolean from "../../../../../../globalComponents/formFields/SelectBoolean";
import FinancialAuthoritySelect, {
  CompanyLabelFinance,
} from "../../../../../../globalComponents/formFields/dynamic/FinancialAuthoritySelect";
import TextFieldController from "../../../../../../globalComponents/formFields/TextFieldController";

const SteuerData = (): React.ReactElement => {
  const {
    control,
    formState: {},
    getValues,
  } = useFormContext();

  const taxData = getValues("taxData");

  return (
    <ContentContainer>
      <Grid container rowSpacing={"28px"}>
        <Grid item xs={6}>
          <FinancialAuthoritySelect
            control={control}
            fieldName={"taxData.financialAuthorityNumber"}
            defaultValue={taxData?.financialAuthority}
            label={"Zuständiges Finanzamt"}
            transform={{
              output: (data: CompanyLabelFinance) => {
                return data?.id;
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldController
            control={control}
            defaultValue={taxData?.taxId}
            label={"Steuernummer"}
            fieldName={"taxData.taxId"}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldController
            control={control}
            fieldName={"taxData.iban"}
            label={"IBAN"}
            defaultValue={taxData?.iban}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldController
            control={control}
            fieldName={"taxData.bic"}
            label={"BIC"}
            defaultValue={taxData?.bic}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectBoolean
            control={control}
            fieldName={"taxData.financialAuthoritySepa"}
            errorText={
              "Bitte wählen Sie aus ob ein Sepa Lastschriftmandat erteilt wurde."
            }
            label={"Sepa Lastschrift"}
            defaultValue={taxData?.financialAuthoritySepa}
          />
        </Grid>
      </Grid>
    </ContentContainer>
  );
};

export default SteuerData;
