import React from "react";
import { intervalToDuration } from "date-fns/fp";
import LinkStyled from "../container/LinkStyled";
import {
  faBirthdayCake,
  faCalendarCheck,
  faExclamationTriangle,
  faFileAlt,
  faFileImport,
  faLongArrowAltRight,
  faPlane,
  faUserPlus,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Typography } from "@mui/material";
import Message from "./Message";
import { AbsenceRequest, EmployeeOverview } from "../../state/employees/types";
import { getDateText } from "../../utils/dates";
import { NotificationContainer } from "../../product/pages/overview/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// types
export interface StatusNotificationProps {
  date: string;
  statusType: statusTypeValues;
  employee: EmployeeOverview;
  absenceRequest?: AbsenceRequest;
}

export enum statusTypeValues {
  BIRTHDAY = "BIRTHDAY",
  NEW_EMPLOYEE = "NEW_EMPLOYEE",
  WORK_PERMIT_EXPIRATION = "WORK_PERMIT_EXPIRATION",
  CONTRACT_ENDING = "CONTRACT_ENDING",
  ABSENCE_REQUEST = "ABSENCE_REQUEST",
}

const StatusNotification: React.FC<StatusNotificationProps> = ({
  date,
  statusType,
  employee,
  absenceRequest,
}) => {
  let icon: IconDefinition = faFileAlt;
  let description = "";
  let overdue: JSX.Element | null = null;

  if (statusType)
    switch (statusType) {
      case statusTypeValues.BIRTHDAY:
        icon = faBirthdayCake;
        description = "Birthday";
        break;
      case statusTypeValues.NEW_EMPLOYEE:
        icon = faUserPlus;
        description = "New Joiner";
        break;
      case statusTypeValues.WORK_PERMIT_EXPIRATION:
        icon = faFileAlt;
        description = "Expiring Work Permit";
        break;
      case statusTypeValues.CONTRACT_ENDING:
        icon = faFileImport;
        description = "Contract Expiry";
        break;
      case statusTypeValues.ABSENCE_REQUEST:
        icon = faCalendarCheck;
        description = "Absence Request";
        break;
    }

  const getNotificationTime = (date: string): string => {
    const today = new Date();
    const dateToCheck = new Date(date);
    if (today.getTime() >= dateToCheck.getTime()) {
      const { days } = intervalToDuration({
        start: new Date(date),
        end: new Date(),
      });
      overdue = <FontAwesomeIcon icon={faExclamationTriangle} />;
      if (days === 0) return "today";
      if (days === 1) return "yesterday";
      return `${days} Days ago`;
    } else {
      const { days } = intervalToDuration({
        start: new Date(),
        end: new Date(date),
      });
      if (days === 1) return "tomorrow";
      return `in ${days} Days`;
    }
  };

  return (
    <NotificationContainer>
      <Message
        icon={icon}
        component1={
          <>
            <Typography variant={"subtitle2"}>{description}</Typography>
            <LinkStyled to={"/app/employees/" + employee.id}>
              <Typography variant={"subtitle2"} marginTop={"10px"}>
                {employee.firstName} {employee.lastName}
              </Typography>
            </LinkStyled>
            {absenceRequest ? (
              <>
                <Typography variant={"subtitle2"} marginTop={"10px"}>
                  <FontAwesomeIcon icon={faPlane} />{" "}
                  {absenceRequest.absenceType?.description} -{" "}
                  {absenceRequest.duration} days
                </Typography>
                <Typography
                  variant={"body1"}
                  marginTop={"10px"}
                  marginBottom={"20px"}
                >
                  {getDateText(absenceRequest.startDate as string)}{" "}
                  <FontAwesomeIcon icon={faLongArrowAltRight} />{" "}
                  {getDateText(absenceRequest.endDate as string)}
                </Typography>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  style={{ marginRight: "10px" }}
                >
                  Reject
                </Button>
                <Button variant={"contained"} color={"primary"}>
                  Approve
                </Button>
              </>
            ) : (
              <></>
            )}
          </>
        }
        component2={
          <>
            <Typography variant={"body1"} color={"#999999"}>
              {getNotificationTime(date)}
            </Typography>
            <Typography
              variant={"body1"}
              color={"#F8B44C"}
              align={"right"}
              marginTop={"10px"}
              marginRight={"15px"}
            >
              {overdue}
            </Typography>
          </>
        }
      />
    </NotificationContainer>
  );
};

export default StatusNotification;
