import React, { useState } from "react";
import styled from "styled-components";
import { Tab } from "@material-ui/core";

// types
export type UseFilterList = () => { filter: string; onSetFilter: OnSetFilter };

export type OnSetFilter = (filter: string) => () => void;

export type FilterItem = {
  value: string;
  label: string;
};

export type FilterListProps = {
  list: FilterItem[];
  filter: string;
  onSetFilter: OnSetFilter;
  className?: string;
};

// styles
export const Container = styled.div`
  margin: 20px;
`;

export const Filter = styled(Tab)`
  color: #054d80;
`;

// hooks
export const useFilterList: UseFilterList = () => {
  const [filter, setFilter] = useState("");

  const onSetFilter = (newFilter: string) => () => {
    if (newFilter === filter) return setFilter("");

    setFilter(newFilter);
  };

  return { filter, onSetFilter };
};

// components
const FilterList: React.FC<FilterListProps> = ({
  list,
  filter,
  onSetFilter,
  className,
}) => {
  return (
    <Container className={className}>
      {list.map(({ value, label }) => (
        <Filter
          key={value}
          label={label}
          onClick={onSetFilter(value)}
          selected={filter === value}
        />
      ))}
    </Container>
  );
};

export default FilterList;
