import styled from "styled-components";
import { colors } from "../../../../../../../utils";
import {
  CalendarRow,
  Text,
  getFontSize,
  getBaseCellStyle,
  getCellHeaderHeight,
  getSmallFontSize,
} from "../styles";

// types
type Styles = {
  borderLeft?: boolean;
  borderRight?: boolean;
  basesize?: number;
  dashedBackground?: boolean;
};

// helper methods
export const getBackground = ({ dashedBackground = false }: Styles): string => {
  if (!dashedBackground) return `background: ${colors.gray12};`;

  return `background-image: repeating-linear-gradient(
      -45deg,
      #f5f8fa 3px 10px,
      #d1d5d6 0px 12px
    );`;
};

// styles
export const Container = styled(CalendarRow)``;

export const SearchEmplyeeContainer = styled.div`
  background: ${colors.gray12};
`;

export const StyledDate = styled(Text)`
  padding-bottom: 5px;
  font-size: ${getFontSize}px;
`;

export const Day = styled.span`
  text-transform: uppercase;
  font-size: ${getSmallFontSize}px;
`;

export const CellHeader = styled.div`
  ${getBaseCellStyle<Styles>()}
  height: ${getCellHeaderHeight<Styles>()}px;
  ${getBackground}
`;
