import { createAsyncThunk } from "@reduxjs/toolkit";
import { CoreRequest } from "../../config/backend/serviceInstances";
import { DataState } from "../organization/companies/detail/companyDetail";
import { TimeTrackingRequest } from "../employees/types";

export interface PayrollTimetrackingState extends DataState {
  data: TimetrackingRequestDataState;
}

export interface TimetrackingRequestDataState {
  data: TimeTrackingRequest[];
  page: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}

export const PayrollTimetrackingStateI = {
  error: false,
  errorMessage: "",
  loading: false,
  data: { data: [], page: 0, pageSize: 0, totalCount: 0, totalPages: 0 },
};

export const listPayrollTimetrackingRequests = createAsyncThunk<
  TimetrackingRequestDataState,
  {
    page: number;
    pageSize: number;
    sort: string;
    startDate: string;
    endDate: string;
  }
>(
  "/employees/timetracking",
  async (parameter: {
    page: number;
    pageSize: number;
    sort: string;
    startDate: string;
    endDate: string;
  }) => {
    const response = await CoreRequest.get(`/employees/timetracking/all`, {
      params: {
        page: parameter.page,
        pageSize: parameter.pageSize,
        sort: parameter.sort,
        startDate: parameter.startDate,
        endDate: parameter.endDate,
      },
    });

    if (response.status > 299) {
      // Return the known error for future handling
      return response.data.error;
    }

    return response.data as TimetrackingRequestDataState;
  }
);
