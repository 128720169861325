import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CoreRequest } from "../../../config/backend/serviceInstances";
import { DataState } from "../companies/detail/companyDetail";
import { Page } from "../companies/detail/salarytype";

// types
export interface LocationsState extends DataState {
  data: Location[];
  page: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}

export type Location = {
  id: number;
  locationName: string;
  city: string;
  postalCode?: string;
  latitude?: number;
  longitude?: number;
};

export type LocationsResponse = Page<Location>;

// actions
export const listLocations = createAsyncThunk<LocationsResponse>(
  "/locations",
  async () => {
    const response = await CoreRequest.get(`/companies/locations`);

    if (response.status > 299) {
      // Return the known error for future handling
      return response.data.error;
    }

    return response.data;
  }
);

const initialState: LocationsState = {
  loading: false,
  error: false,
  data: [],
  page: 0,
  pageSize: 25,
  totalCount: 0,
  totalPages: 0,
};

// reducer
const locations = createSlice({
  name: "organization/locations",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listLocations.pending, (state) => {
      state.loading = true;
      state.data = [];
    });
    builder.addCase(listLocations.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.page = action.payload.page;
      state.pageSize = action.payload.pageSize;
      state.totalPages = action.payload.totalPages;
      state.totalCount = action.payload.totalCount;
      state.error = false;
    });
    builder.addCase(listLocations.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default locations;
