import React from "react";
import OrgChartElementBody from "./styles/OrgChartElementBody";
import OrgChartElementContainer from "./styles/OrgChartElementContainer";
import OrgChartElementHeader from "./styles/OrgChartElementHeader";

interface OrgChartElementProps {
  headerLeft: JSX.Element;
  headerRight: JSX.Element;
  body: JSX.Element;
  zoomLevel: number;
  callBack: () => void;
}

const OrgChartElement = (props: OrgChartElementProps): JSX.Element => {
  return (
    <OrgChartElementContainer
      zoomLevel={props.zoomLevel}
      onClick={() => props.callBack()}
    >
      <OrgChartElementHeader>
        {props.headerLeft}
        {props.headerRight}
      </OrgChartElementHeader>
      <OrgChartElementBody hide={props.zoomLevel < 10}>
        {props.body}
      </OrgChartElementBody>
    </OrgChartElementContainer>
  );
};

export default OrgChartElement;
