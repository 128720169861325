import React from "react";
import { Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const GridStyled = styled(Grid)`
  max-width: 48%;
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding: 20px;
`;

const Verdienstbescheinigung = (): JSX.Element => {
  return (
    <GridStyled container rowSpacing={1}>
      <Grid item xs={6}>
        <Typography variant={"h4"} marginBottom={2}>
          Verdienstbescheinigung
        </Typography>
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={6}>
        <Grid container rowSpacing={1}>
          <Grid item xs={6}>
            <Typography variant={"subtitle2"}>Steuer</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"subtitle2"} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"caption"}>Gesamtbrutto</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"subtitle2"}>5,666.67</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"caption"}>Steuer-Brutto</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"subtitle2"}>5,666.67</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"caption"}>Lohnsteuer</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"subtitle2"}>1,240.00</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"caption"}>Kirchensteuer</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"subtitle2"}>0.00</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"caption"}>Solzg.</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"subtitle2"}>68.20</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"caption"}>Stfr. Bezüge</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"subtitle2"}>0.00</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"caption"}>Pausch. verst.</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"subtitle2"}>0.00</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"caption"}>Pfändung (rest)</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"subtitle2"}>0.00</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"caption"}>Darlehen (rest)</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"subtitle2"}>0.00</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container rowSpacing={1}>
          <Grid item xs={6}>
            <Typography variant={"subtitle2"}>SV</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"subtitle2"} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"caption"}>Sv-Brutto</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"subtitle2"}>5,666.67</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"caption"}>KV Beitrag</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"subtitle2"}>358.60</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"caption"}>RV Beitrag</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"subtitle2"}>527.00</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"caption"}>AV Beitrag</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"subtitle2"}>68.00</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"caption"}>PV Beitrag</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"subtitle2"}>83.21</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"caption"}>VWL Gesamt</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"subtitle2"}>0.00</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"caption"}>KUG</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"subtitle2"}>0.00</Typography>
          </Grid>
        </Grid>
      </Grid>
    </GridStyled>
  );
};

export default Verdienstbescheinigung;
