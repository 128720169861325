import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { a11yProps, TabPanel, useStyles } from "./EmployeeDetailBody";
import SubPage from "../../../../../globalComponents/subPage/SubPage";
import {
  getCurrentEmployeeWorkContractBasic,
  getCurrentEmployeeWorkContractJob,
  getCurrentEmployeeWorkContractStats,
  listWorkContractBudgets,
  listWorkContractCompensation,
  listWorkContractSchedules,
} from "../../../../../state/employees/employeeDetail/employeeDetail";
import DisplayCard from "../../../../../globalComponents/dataCard/DisplayCard";
import {
  useEmployeeWorkContractBasic,
  useEmployeeWorkContractBudgets,
  useEmployeeWorkContractCompensations,
  useEmployeeWorkContractJob,
  useEmployeeWorkContractStatistics,
  useEmployeeWorkContractWorkSchedule,
} from "../../../../../state/employees/employees/selectors";
import { FormFieldType } from "../../../../../globalComponents/dataCard/utils";
import SmartCrud from "../../../../../globalComponents/smartOperations/smartCrud/SmartCrud";
import { defaultDateTo } from "../../../../../utils";
import { GridValueGetterParams } from "@mui/x-data-grid";
import { AppBar, IconButton, Tab, Tabs } from "@mui/material";
import LinkStyled from "../../../../../globalComponents/container/LinkStyled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaste } from "@fortawesome/free-solid-svg-icons";
import CompensationDetailView from "./compensation/CompensationsDetailView";
import WorkSchedules from "./workSchedules/WorkSchedules";

const BasicData = [
  {
    fieldName: "position",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true, minLength: 2 },
    label: "Position",
  },
  {
    fieldName: "arbeitnehmerTyp",
    defaultValue: "",
    type: FormFieldType.SELECT,
    validation: { required: true },
    label: "Arbeitnehmertyp",
    menuItems: [
      { label: "Werkstudent", value: "Werkstudent" },
      { label: "Geringfügig Beschäftiger", value: "Geringfügig Beschäftigter" },
      { label: "Vollzeit", value: "Vollzeit" },
    ],
  },
  {
    fieldName: "workingHoursPerWeek",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true },
    label: "Arbeitszeit pro Woche",
  },
  {
    fieldName: "abrechnungsGruppe",
    defaultValue: "",
    type: FormFieldType.SELECT,
    validation: { required: true },
    label: "Abrechnungsgruppe",
    menuItems: [
      { label: "Erstabrechnung komplett", value: "Erstabrechnung komplett" },
      { label: "1. Folgegruppe", value: "1. Folgegruppe" },
    ],
  },
  {
    fieldName: "sicknessCertificateDays",
    requestFieldName: "daysSicknessCertificate",
    defaultValue: "",
    type: FormFieldType.SELECT,
    validation: { required: true },
    label: "Anzahl Tage AU",
    menuItems: [
      { label: "0 Tagen", value: "0" },
      { label: "1 Tag", value: 1 },
      { label: "2 Tagen", value: 2 },
      { label: "3 Tagen", value: 3 },
    ],
  },
  {
    fieldName: "vacationDaysPerYear",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true },
    label: "Urlaubstage",
  },
];

const Statistics = [
  {
    fieldName: "ausgeuebteTaetigkeit",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true, minLength: 2 },
    label: "Ausgeübte Tätigkeit",
  },
  {
    fieldName: "schulabschluss",
    defaultValue: "",
    type: FormFieldType.SELECT,
    validation: { required: true, minLength: 2 },
    label: "Höchster Schulabschluss",
    menuItems: [
      { label: "Hauptschulabschluss", value: "Haupt" },
      { label: "Mittlere Reife", value: "Mitel" },
      { label: "Abitur", value: "Abi" },
    ],
  },
  {
    fieldName: "ausbildungsabschluss",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: false },
    label: "Ausbildungsabschluss",
  },
  {
    fieldName: "arbeitnehmerUeberlassung",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: true },
    label: "Arbeitnehmerüberlassung",
  },
  {
    fieldName: "vertragsForm",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: false },
    label: "Vertragsform",
    editable: false,
  },
  {
    fieldName: "personenGruppenSchluessel",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: false },
    label: "Personengruppenschlüssel",
  },
  {
    fieldName: "taetigkeitsSchluessel",
    defaultValue: "",
    type: FormFieldType.TEXT,
    validation: { required: false },
    label: "Tätigkeitsschlüssel",
  },
];

const JobData = [
  {
    fieldName: "startDate",
    defaultValue: "",
    type: FormFieldType.DATE,
    validation: { required: true },
    label: "Startdatum",
  },
  {
    fieldName: "endDate",
    defaultValue: null,
    type: FormFieldType.DATE,
    validation: { required: false },
    label: "Enddatum",
  },
  {
    fieldName: "einstellungsArt",
    defaultValue: "",
    type: FormFieldType.SELECT,
    validation: { required: true },
    label: "Einstellungsart",
    menuItems: [
      { label: "Vollzeit", value: "voll" },
      { label: "Teilzeit", value: "teil" },
    ],
  },
  {
    fieldName: "contractType",
    defaultValue: "",
    type: FormFieldType.SELECT,
    validation: { required: true },
    label: "Vertragsart",
    menuItems: [
      { label: "Unebfristet", value: "permanent" },
      { label: "Befristet", value: "fixed" },
    ],
  },
  {
    fieldName: "probationPeriod",
    defaultValue: "",
    type: FormFieldType.SELECT,
    validation: { required: true },
    label: "Probezeit",
    menuItems: [
      { label: "2 Wochen", value: "2W" },
      { label: "4 Wochen", value: "4W" },
    ],
  },
  {
    fieldName: "probationTerminationPeriod",
    defaultValue: "",
    type: FormFieldType.SELECT,
    validation: { required: true },
    label: "Kündigungsfrist in Probezeit",
    menuItems: [
      { label: "1 Woche", value: "1W" },
      { label: "2 Wochen", value: "2W" },
    ],
  },
  {
    fieldName: "probationTerminationEffectiveness",
    defaultValue: "",
    type: FormFieldType.SELECT,
    validation: { required: true },
    label: "Effektivität",
    menuItems: [
      { label: "1 Woche", value: "1W" },
      { label: "2 Wochen", value: "2W" },
    ],
  },
  {
    fieldName: "terminationPeriod",
    defaultValue: "",
    type: FormFieldType.SELECT,
    validation: { required: true },
    label: "Effektivität ",
    menuItems: [
      { label: "1 Woche", value: "1W" },
      { label: "2 Wochen", value: "2W" },
    ],
  },
  {
    fieldName: "terminationEffectiveness",
    defaultValue: "",
    type: FormFieldType.SELECT,
    validation: { required: true },
    label: "Effektivität  ",
    menuItems: [
      { label: "1 Monat", value: "1M" },
      { label: "2 Monate", value: "2M" },
    ],
  },
];

const ChangeData = {
  loading: false,
  error: false,
  data: [
    {
      id: 1,
      fieldName: "Position",
      oldValue: "Sales Agent",
      newValue: "Senior Sales Agent",
      effectiveDate: "01. Dezember 2021",
      creator: "magdalena.neubauer@heavenhr.de",
      document: "",
    },
    {
      id: 2,
      fieldName: "Urlaubsbudget",
      oldValue: "28",
      newValue: "30",
      effectiveDate: "01. November 2021",
      creator: "magdalena.neubauer@heavenhr.de",
      document: "",
    },
    {
      id: 3,
      fieldName: "Urlaubsbudget",
      oldValue: "26",
      newValue: "28",
      effectiveDate: "01. August 2021",
      creator: "magdalena.neubauer@heavenhr.de",
      document: "",
    },
  ],
};

const EmployeeWorkContractsDetailView = (props: {
  employeeId: number;
}): JSX.Element => {
  const { workContractId } = useParams<{ workContractId: string }>();
  const wId = Number.parseInt(workContractId);
  const [tab, setTab] = React.useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCurrentEmployeeWorkContractBasic(wId));
    dispatch(getCurrentEmployeeWorkContractJob(wId));
    dispatch(getCurrentEmployeeWorkContractStats(wId));
    dispatch(listWorkContractSchedules(wId));
    dispatch(listWorkContractBudgets(wId));
    dispatch(listWorkContractCompensation(wId));
  }, [workContractId, tab]);
  const refreshCompensation = () => {
    dispatch(listWorkContractCompensation(wId));
  };
  const contractBasic = useEmployeeWorkContractBasic();
  const contractJob = useEmployeeWorkContractJob();
  const contractStatistics = useEmployeeWorkContractStatistics();
  const contractWorkSchedules = useEmployeeWorkContractWorkSchedule();
  const contractBudgets = useEmployeeWorkContractBudgets();
  const contractCompensation = useEmployeeWorkContractCompensations();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setTab(newValue);
  };
  return (
    <SubPage
      linkUrl1={"/app/employees/" + props.employeeId}
      linkTitle1={"Arbeitsverträge"}
      subtitle={`Arbeitsvertrag vom ${defaultDateTo(
        "-",
        contractJob.data.startDate
      )}`}
    >
      <br />
      <div>
        <AppBar position="static">
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="simple tabs example"
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
          >
            <Tab label="Allgemeine Angaben" {...a11yProps(0)} />
            <Tab label="Stundenverteilung" {...a11yProps(1)} />
            <Tab label="Budgets" {...a11yProps(2)} />
            <Tab label="Vergütung" {...a11yProps(3)} />
            <Tab label="Änderungen" {...a11yProps(4)} />
          </Tabs>
        </AppBar>
        <TabPanel value={tab} index={0}>
          <DisplayCard
            title={"Allgemeine Angaben"}
            getEndpoint={"/employees/contract/basic?workContractId="}
            updateEndpoint={"/employees/contract/" + workContractId + "/basic"}
            additionalBodyParams={["id"]}
            patch={false}
            id={wId}
            input={contractBasic}
            formFields={BasicData}
            callbackFunc={() =>
              dispatch(getCurrentEmployeeWorkContractBasic(wId))
            }
          />
          <DisplayCard
            title={"Beschäftigungsverhältnis"}
            getEndpoint={"/employees/contract/job?workContractId="}
            updateEndpoint={"/employees/contract/" + workContractId + "/job"}
            additionalBodyParams={["id"]}
            patch={false}
            id={wId}
            input={contractJob}
            formFields={JobData}
            callbackFunc={() =>
              dispatch(getCurrentEmployeeWorkContractJob(wId))
            }
          />
          <DisplayCard
            title={"Statistiken"}
            getEndpoint={"/employees/contract/statistics?workContractId="}
            updateEndpoint={"/employees/contract/statistics"}
            additionalBodyParams={["id"]}
            patch={false}
            id={wId}
            input={contractStatistics}
            formFields={Statistics}
            callbackFunc={() =>
              dispatch(getCurrentEmployeeWorkContractStats(wId))
            }
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <WorkSchedules />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <br />
          <SmartCrud
            title={"Angelegte Budgets"}
            input={contractBudgets}
            updateInputCallback={() => dispatch(listWorkContractBudgets(wId))}
            createButtonLabel={"Budget hinzufügen"}
            endpoints={{
              getEndpointDetail: "/employees/contract/budget?budgetId=",
              postEndpoint: {
                url: "/employees/contract/budget",
                type: "POST",
                bodyParameter: [{ label: "workContractId", value: wId }],
              },
              putPatchEndpoint: {
                url: "/employees/contract/budget",
                type: "PUT",
                idAsBodyParamLabel: "workContractId",
              },
              deleteEndpoint: "/employees/contract/budget?budgetId=",
            }}
            deleteConfirmMessage={"Wollen Sie das Budget wirklich löschen?"}
            parameter={[
              {
                formType: FormFieldType.SELECT,
                defaultValue: "",
                field: "absenceType",
                headerName: "Art",
                width: 190,
                renderCell: (params) => {
                  return (
                    <LinkStyled
                      to={"/app/companies/" + params.row.absenceType.companyId}
                    >
                      {params.row.absenceType.description}
                    </LinkStyled>
                  );
                },
              },
              {
                formType: FormFieldType.TEXT,
                defaultValue: "",
                field: "amount",
                headerName: "Betrag",
                width: 130,
              },
              {
                formType: FormFieldType.TEXT,
                defaultValue: "",
                field: "remainingAmount",
                headerName: "Verbleibend",
                width: 170,
              },
              {
                formType: FormFieldType.DATE,
                defaultValue: "",
                field: "startDate",
                headerName: "Startdatum",
                width: 170,
                renderCell: (params: GridValueGetterParams) => (
                  <span>{defaultDateTo("-", params.row.startDate)}</span>
                ),
              },
              {
                formType: FormFieldType.DATE,
                defaultValue: "",
                field: "endDate",
                headerName: "Enddatum",
                width: 160,
                renderCell: (params: GridValueGetterParams) => (
                  <span>{defaultDateTo("-", params.row.endDate)}</span>
                ),
              },
              {
                formType: FormFieldType.DATE,
                defaultValue: "",
                field: "expiryDate",
                headerName: "Verfallsdatum",
                width: 200,
                renderCell: (params: GridValueGetterParams) => (
                  <span>{defaultDateTo("-", params.row.expiryDate)}</span>
                ),
              },
            ]}
          />
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <CompensationDetailView
            employeeId={props.employeeId}
            workContractId={wId}
            refreshCompensation={refreshCompensation}
            workContractCompensation={contractCompensation}
          />
        </TabPanel>
        <TabPanel value={tab} index={4}>
          <br />
          <SmartCrud
            title={"Änderungen"}
            input={ChangeData}
            updateInputCallback={() => console.log("Änderungen")}
            createButtonLabel={"Änderung hinzufügen"}
            endpoints={{
              getEndpointDetail:
                "/employees/contract/compensation?compensationId=",
              postEndpoint: {
                url: "/employees/contract/compensation?salaryTypeId=1",
                type: "POST",
                bodyParameter: [{ label: "workContractId", value: wId }],
              },
              putPatchEndpoint: {
                url: "/employees/contract/compensation",
                type: "PUT",
                idAsBodyParamLabel: "workContractId",
              },
              deleteEndpoint: "/employees/contract/budget?compensationId=",
            }}
            deleteConfirmMessage={"Wollen Sie die Änderung wirklich löschen?"}
            parameter={[
              {
                formType: FormFieldType.TEXT,
                defaultValue: "",
                field: "fieldName",
                headerName: "Feld",
                width: 150,
              },
              {
                formType: FormFieldType.TEXT,
                defaultValue: "",
                field: "oldValue",
                headerName: "Alter Wert",
                width: 160,
              },
              {
                formType: FormFieldType.TEXT,
                defaultValue: "",
                field: "newValue",
                headerName: "Neuer Wert",
                width: 170,
              },
              {
                formType: FormFieldType.DATE,
                defaultValue: "",
                field: "effectiveDate",
                headerName: "Änderungsdatum",
                width: 210,
              },
              {
                formType: false,
                defaultValue: "",
                field: "creator",
                headerName: "Ersteller",
                width: 260,
              },
              {
                formType: false,
                defaultValue: "",
                field: "document",
                width: 50,
                renderCell: () => (
                  <IconButton size={"small"}>
                    <FontAwesomeIcon icon={faPaste} />
                  </IconButton>
                ),
              },
            ]}
          />
        </TabPanel>
      </div>
    </SubPage>
  );
};
export default EmployeeWorkContractsDetailView;
