import { combineReducers } from "redux";
import departments from "./organization/departments/departments";
import locations from "./organization/locations/locations";
import employees from "./employees/employees/employee";
import employeeDetail from "./employees/employeeDetail/employeeDetail";
import absence from "./employees/absences/absence";
import companyDetail from "./organization/companies/detail/companyDetail";
import general from "./general/general";
import companies from "./organization/companies/companies";
import payroll from "./payroll/payroll";

const combinedReducer = combineReducers({
  general: general.reducer,
  companies: companies.reducer,
  companyDetail: companyDetail.reducer,
  departments: departments.reducer,
  locations: locations.reducer,
  employees: employees.reducer,
  employeeDetail: employeeDetail.reducer,
  absence: absence.reducer,
  payroll: payroll.reducer,
});

export default combinedReducer;
