import React from "react";
import styled from "styled-components";

const MenuItemContainer = styled.div`
  width: 100%;
  min-height: 30px;
  padding: 5px 20px 5px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &: hover {
    background-color: #f6f8fa;
  }
`;

const MenuItemNew = (props: {
  children: JSX.Element;
  onClick: () => void;
}): JSX.Element => {
  return (
    <MenuItemContainer onClick={() => props.onClick()}>
      {props.children}
    </MenuItemContainer>
  );
};

export default MenuItemNew;
