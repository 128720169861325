import React from "react";
import styled from "styled-components";
import { Grid, Typography } from "@mui/material";
import EmployeeAvatar from "../../../../globalComponents/avatars/EmployeeAvatar";
import EmployeeTaxHeader from "./EmployeeTaxHeader";
import EmployeeSocialHeader from "./EmployeeSocialHeader";
import EmployeeTimeline from "./timeline/EmployeeTimeline";
import {
  useEmployeeDetail,
  useEmployeeDetailBasic,
  useEmployeeWorkContractBasic,
} from "../../../../state/employees/employees/selectors";
import { baseUrl } from "../../../../config/globalVariables";

const EmployeeHeaderContainer = styled.div`
  width: 100%;
`;

const exampleWorkingDays = [
  {
    calendarDay: "31",
    absent: true,
    contractHours: 8,
    regularHours: 8,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "01",
    absent: true,
    contractHours: 0,
    regularHours: 0,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "02",
    absent: true,
    contractHours: 8,
    regularHours: 8,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "03",
    absent: true,
    contractHours: 8,
    regularHours: 8,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "04",
    absent: true,
    contractHours: 0,
    regularHours: 0,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "05",
    absent: true,
    contractHours: 0,
    regularHours: 0,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "06",
    absent: true,
    contractHours: 8,
    regularHours: 8,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "07",
    absent: true,
    contractHours: 8,
    regularHours: 8,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "08",
    absent: true,
    contractHours: 8,
    regularHours: 8,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "09",
    absent: true,
    contractHours: 8,
    regularHours: 8,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "10",
    absent: true,
    contractHours: 8,
    regularHours: 8,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "11",
    absent: true,
    contractHours: 0,
    regularHours: 0,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "12",
    absent: true,
    contractHours: 0,
    regularHours: 0,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "13",
    absent: true,
    contractHours: 8,
    regularHours: 8,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "14",
    absent: true,
    contractHours: 8,
    regularHours: 8,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "15",
    absent: true,
    contractHours: 8,
    regularHours: 8,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "16",
    absent: true,
    contractHours: 8,
    regularHours: 8,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "17",
    absent: true,
    contractHours: 8,
    regularHours: 8,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "18",
    absent: true,
    contractHours: 0,
    regularHours: 0,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "19",
    absent: true,
    contractHours: 0,
    regularHours: 0,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "20",
    absent: true,
    contractHours: 8,
    regularHours: 8,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "21",
    absent: true,
    contractHours: 8,
    regularHours: 8,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "22",
    absent: true,
    contractHours: 8,
    regularHours: 8,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "23",
    absent: true,
    contractHours: 8,
    regularHours: 8,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "24",
    absent: true,
    contractHours: 8,
    regularHours: 8,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "25",
    absent: true,
    contractHours: 0,
    regularHours: 0,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "26",
    absent: true,
    contractHours: 0,
    regularHours: 0,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "27",
    absent: true,
    contractHours: 8,
    regularHours: 8,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "28",
    absent: true,
    contractHours: 8,
    regularHours: 8,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
  {
    calendarDay: "29",
    absent: true,
    contractHours: 8,
    regularHours: 8,
    nightHours: 0,
    sun: false,
    holiday: false,
  },
];

const EmployeeHeader = (): JSX.Element => {
  const personal = useEmployeeDetailBasic().data;
  const contract = useEmployeeWorkContractBasic().data;
  const base = useEmployeeDetail().Header.data;
  return (
    <EmployeeHeaderContainer>
      <Grid container rowSpacing={1} columnSpacing={1} columns={20}>
        <Grid item xs={1}>
          <Typography variant={"body1"}>für:</Typography>
        </Grid>
        <Grid item xs={4}>
          <EmployeeAvatar
            firstName={personal.firstName}
            lastName={personal.lastName}
            position={contract.position}
            imgLink={
              base.imgLink ? `${baseUrl}/employees/pic/${base.imgLink}` : null
            }
            id={1}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography variant={"body1"}>wohnhaft:</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant={"subtitle2"}>
            {personal.street} <br />
            {personal.postalCode} {personal.city}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant={"body1"}>beschäftigt bei:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={"subtitle2"}>
            Testfirma GmbH <br />
            Mittenwalderstrasse 6<br />
            10405 Berlin
          </Typography>
        </Grid>
      </Grid>
      <EmployeeTaxHeader />
      <EmployeeSocialHeader />
      <EmployeeTimeline workingDays={exampleWorkingDays} />
    </EmployeeHeaderContainer>
  );
};

export default EmployeeHeader;
