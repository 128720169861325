import React from "react";
import { ContentContainer } from "./components/styledComponents";
import CompensationTable from "./components/CompensationTable";

const Compensation = (): React.ReactElement => {
  //const compensations = useDPFtoDos();
  return (
    <ContentContainer>
      <CompensationTable />
    </ContentContainer>
  );
};

export default Compensation;
