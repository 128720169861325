import React, { MouseEventHandler, useRef, useState } from "react";
import { Animate } from "../Animation/useAnimate";
import { Container, StyledAnimation } from "./styles";

// types
export type RippleProps = Partial<{
  timeout: string;
  onEnter: () => void;
  onExited: () => void;
  className: string;
}>;

export type CircleProps =
  | { width: number; height: number; left: number; top: number }
  | "None";

type GetCircleProps = (
  domContainer: HTMLDivElement | null
) => (x?: number) => (y?: number) => CircleProps;

// utils
const getCircleProps: GetCircleProps = (domContainer) => (x) => (y) => {
  if (!domContainer || !x || !y) return "None";

  const rect = domContainer.getBoundingClientRect();
  const diameter = Math.max(rect.width, rect.height);
  const radius = diameter / 2;

  const width = diameter;
  const height = diameter;
  const left = x - rect.left - radius;
  const top = y - rect.top - radius;

  return { width, height, left, top };
};

// component
const Ripple: React.FC<RippleProps> = ({
  timeout = "0.5s",
  onEnter,
  onExited,
  className,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [clientX, setClientX] = useState<number>();
  const [clientY, setClientY] = useState<number>();
  const [rippleActive, setRippleActive] = useState(false);

  const createRipple: MouseEventHandler<HTMLDivElement> = (event) => {
    setClientX(event.clientX);
    setClientY(event.clientY);
    setRippleActive(true);
  };

  const onAnimationStartHandler = (e: React.AnimationEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onEnter) onEnter();
  };

  const onAnimationEndHandler = (e: React.AnimationEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setRippleActive(false);
    if (onExited) onExited();
  };

  return (
    <Container className={className} onClick={createRipple} ref={ref}>
      <StyledAnimation
        animate={rippleActive ? Animate.In : Animate.Empty}
        timeout={timeout}
        animationName="ripple"
        animateInStyle="transform: scale(4); opacity: 0"
        animateOutStyle="transform: scale(0); opacity: 1"
        onAnimationStart={onAnimationStartHandler}
        onAnimationEnd={onAnimationEndHandler}
        circleProps={getCircleProps(ref.current)(clientX)(clientY)}
      ></StyledAnimation>
      {children}
    </Container>
  );
};

export default Ripple;
