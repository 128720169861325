import React, { MouseEventHandler, useState, useEffect } from "react";
import styled from "styled-components";
import { IconButton, Paper, Popover } from "@material-ui/core";
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import {
  addAnimation,
  Animate,
  getBackgroundColor,
  getBorderColor,
  getColors,
  getDefaultColor,
  getGridTemplateColumns,
  getHeight,
  getHtmlColor,
  isValidColor,
  sizes,
} from "./utils";

// types
type OnPopover = MouseEventHandler<HTMLButtonElement>;

export type OnChange = (colorName: string) => void;

export type ColorPickerProps = {
  value?: string;
  onChange?: OnChange;
};

// styles
export const Container = styled.div``;

export const Content = styled.div`
  width: ${sizes.widht}px;
  padding: 20px;
  ${getHeight}
`;

export const ColorsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  cursor: pointer;
  ${getGridTemplateColumns}
`;

export const ColorContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const ColorItem = styled.div`
  width: 75%;
  height: 75%;
  border-radius: 100%;
  margin: 15%;
  animation-fill-mode: forwards;
  animation-duration: 0.2s;
  transform: scale(1);

  @keyframes zoomin {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(1.2);
    }
  }

  @keyframes zoomout {
    from {
      transform: scale(1.2);
    }

    to {
      transform: scale(1);
    }
  }

  ${getBackgroundColor}
  ${getBorderColor}
  ${addAnimation}
`;

// components
const ColorPicker: React.FC<ColorPickerProps> = ({ value, onChange }) => {
  const [selected, setSelected] = useState(getDefaultColor);
  const [hover, setHover] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (onChange) onChange(selected);
  }, [selected]);

  useEffect(() => {
    if (isValidColor(value)) setSelected(String(value));
  }, [value]);

  const openPopover: OnPopover = (e) => setAnchorEl(e.currentTarget);

  const closePopover = () => setAnchorEl(null);

  const onMouseEnterHandler = (colorName: string) => () => setHover(colorName);

  const onMouseLeaveHandler = () => setHover("");

  const onClickHandler = (colorName: string) => () => {
    setSelected(colorName);
    closePopover();
  };

  const open = Boolean(anchorEl);
  const id = open ? "color-picker" : undefined;
  const colors = getColors();

  return (
    <Container>
      <IconButton onClick={openPopover} color="default" aria-describedby={id}>
        <FormatColorFillIcon
          fontSize="small"
          htmlColor={getHtmlColor(selected)}
        />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Paper elevation={8}>
          <Content size={colors.length}>
            <ColorsContainer>
              {colors.map(({ colorName, bgColor, borderColor }) => (
                <ColorContainer
                  key={colorName}
                  onMouseEnter={onMouseEnterHandler(colorName)}
                  onMouseLeave={onMouseLeaveHandler}
                  onClick={onClickHandler(colorName)}
                >
                  <ColorItem
                    bgColor={bgColor}
                    borderColor={borderColor}
                    animate={colorName === hover ? Animate.In : Animate.Out}
                  />
                </ColorContainer>
              ))}
            </ColorsContainer>
          </Content>
        </Paper>
      </Popover>
    </Container>
  );
};

export default ColorPicker;
