import React from "react";
import styled from "styled-components";
import CompanyDetailHeader from "./CompanyDetailHeader";
import CompanyDetailBody from "./CompanyDetailBody";
import { useParams } from "react-router-dom";
import { GridToolbarContainer } from "@mui/x-data-grid";

export const GridToolbarContainerS = styled(GridToolbarContainer)`
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CompanyDetail = (): React.ReactElement => {
  type CompanyIdParams = {
    id: string;
  };
  const { id } = useParams<CompanyIdParams>();

  return (
    <>
      <CompanyDetailHeader id={Number.parseInt(id)} />
      <CompanyDetailBody id={Number.parseInt(id)} />
    </>
  );
};

export default CompanyDetail;
