import React, { useEffect } from "react";
import { ContentContainer } from "./components/styledComponents";
import SmartTable from "../../../../../globalComponents/smartOperations/smartTable/SmartTable";
import { GridValueGetterParams } from "@mui/x-data-grid";
import StatusChip from "../../../../../globalComponents/chips/StatusChip";
import { useDispatch } from "react-redux";
import { listPayrollTimetrackingRequests } from "../../../../../state/payroll/timetracking";
import { usePayrollTimetracking } from "../../../../../state/payroll/selectors";

const tableCells = [
  { field: "name", headerName: "Employee", width: 180 },
  {
    field: "startDate",
    headerName: "Start Date",
    width: 150,
  },
  { field: "endDate", headerName: "End Date", width: 200 },
  { field: "breaks", headerName: "Breaks", width: 150 },
  { field: "duration", headerName: "Duration", width: 150 },
  {
    field: "status",
    headerName: "status",
    width: 150,
    renderCell: (params: GridValueGetterParams) => (
      <StatusChip status={params.row.status} />
    ),
  },
];

const Timetracking = (): React.ReactElement => {
  const dispatch = useDispatch();
  const requests = usePayrollTimetracking();
  useEffect(() => {
    dispatch(
      listPayrollTimetrackingRequests({
        page: 0,
        pageSize: 20,
        sort: "-startDate",
        startDate: "2020-01-01",
        endDate: "2021-11-01",
      })
    );
  }, []);

  return (
    <>
      <ContentContainer>
        <SmartTable
          deleteEndpoint={"/employees?employeeId="}
          columns={tableCells}
          deleteConfirmMessage={"Do you really want to delete the request?"}
          input={{
            error: requests.error,
            errorMessage: requests.errorMessage,
            data: requests.data.data,
            loading: requests.loading,
          }}
          height={"500px"}
          updateInputCallback={() => console.log("reload")}
        />
      </ContentContainer>
    </>
  );
};

export default Timetracking;
