import React, { useState } from "react";
import { Typography } from "@mui/material";
import EmployeeAvatar from "../../../../../../globalComponents/avatars/EmployeeAvatar";
import { v4 as uuidv4 } from "uuid";
import TTRequest from "./TTRequest";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GridContainer, GridItem, GridItemCalendarDay } from "../styles/styles";
import { timetrackingLiveResponse } from "../helper/exampleResponse";
import { CalendarGridProps } from "./TimetrackingDays";

export type RequestDummy = {
  id: number;
  startDate: string;
  endDate?: string;
  breaks: { start: string; end: string }[];
};

const TimetrackingHours = (props: CalendarGridProps): JSX.Element => {
  const makeHourLine = () => {
    const hourline: JSX.Element[] = [];

    hourline.push(
      <GridItem
        size={props.gridItemDepartmentSize}
        backgroundColor={"#f5f8fa"}
        key={uuidv4()}
        style={{
          borderRight: "1px solid #E3E6E8",
          padding: "5px",
          paddingLeft: "40px",
          borderLeft: "1px solid #E3E6E8",
        }}
        height={"40px"}
      >
        <Typography variant={"subtitle2"}>Departments</Typography>
      </GridItem>
    );

    for (
      let d = props.startDate.getTime();
      d <= props.endDate.getTime();
      d = 3600000 + d
    ) {
      const date = new Date(d);
      hourline.push(
        <GridItemCalendarDay
          size={props.gridItemSize}
          height={"40px"}
          background={"#f5f8fa"}
          key={uuidv4()}
          variant={"REGULAR"}
        >
          <Typography variant={"subtitle2"}>{date.getHours()}.00</Typography>
          <Typography variant={"caption"} color={"#4C4C4C"}>
            Uhr
          </Typography>
        </GridItemCalendarDay>
      );
    }
    return hourline;
  };

  const makeDepartmentRows = () => {
    const renderRequests = (requests: RequestDummy[]): JSX.Element[] => {
      const MinuteRowPerEmployee: JSX.Element[] = [];

      const minutesOfThePeriod = Array<{
        request: RequestDummy | null;
        status: string;
        duration: number;
        ongoing: boolean;
      }>(720).fill({
        request: null,
        status: "NOTWORKING",
        duration: 1,
        ongoing: false,
      });

      requests.forEach((request) => {
        const startDate = new Date(request.startDate);
        const ongoingDummy = !request.endDate;

        const endDate =
          request.endDate == null ? new Date() : new Date(request.endDate);

        //calculate duration between period start and request start in minutes and fill minutesOfThePeriod up
        const duration1 = Math.round(
          (startDate.getTime() - props.startDate.getTime()) / 60000
        );
        //calculate duration in minutes between start and end of request in fill it up with minutes
        const duration2 = Math.round(
          (endDate.getTime() - startDate.getTime()) / 60000
        );
        minutesOfThePeriod[duration1] = {
          status: "WORKING",
          duration: duration2,
          request: request,
          ongoing: ongoingDummy,
        };
      });

      let notworking = 0;
      for (let i = 0; i < 720; i++) {
        const request = minutesOfThePeriod[i];
        if (request.status == "WORKING" && request.request !== null) {
          MinuteRowPerEmployee.push(
            <GridItem
              backgroundColor={"white"}
              height={"60px"}
              size={notworking}
              key={uuidv4()}
              style={{ paddingBottom: "5px", paddingTop: "5px" }}
            />
          );
          notworking = 0;
          MinuteRowPerEmployee.push(
            <TTRequest
              request={{
                originalRequest: request.request,
                duration: request.duration,
                ongoing: request.ongoing,
              }}
              key={uuidv4()}
            />
          );
          i = i + minutesOfThePeriod[i].duration - 1;
        } else if (minutesOfThePeriod[i].status == "NOTWORKING") {
          notworking += 1;
        }
      }
      if (notworking !== 0) {
        MinuteRowPerEmployee.push(
          <GridItem
            backgroundColor={"white"}
            height={"60px"}
            size={notworking}
            key={uuidv4()}
            style={{ paddingBottom: "5px", paddingTop: "5px" }}
          />
        );
      }
      return MinuteRowPerEmployee;
    };
    return timetrackingLiveResponse.departments.map((department) => {
      const [flap, setFlap] = useState(false);

      let departments: JSX.Element[] = [];
      departments.push(
        <GridItem
          backgroundColor={"#F5F8FA"}
          height={"40px"}
          size={180}
          key={department.id}
          style={{
            padding: "5px",
            paddingLeft: "40px",
            borderLeft: "1px solid #E3E6E8",
          }}
        >
          <Typography variant={"body1"}>{department.name}</Typography>
        </GridItem>
      );
      departments.push(
        <GridItem
          backgroundColor={"#F5F8FA"}
          height={"40px"}
          size={60}
          key={department.id}
          style={{
            borderRight: "1px solid #E3E6E8",
            padding: "5px",
            paddingLeft: "40px",
          }}
        >
          <FontAwesomeIcon
            icon={flap ? faAngleUp : faAngleDown}
            size={"1x"}
            onClick={() => setFlap(!flap)}
          />
        </GridItem>
      );

      department.numberPeopleWorking.forEach((hours) => {
        departments.push(
          <GridItem
            backgroundColor={"#F5F8FA"}
            height={"40px"}
            size={60}
            key={uuidv4()}
            style={{
              borderRight: "1px solid #E3E6E8",
              textAlign: "center",
              justifyContent: "center",
              padding: "5px",
            }}
          >
            <Typography variant={"body1"}>{hours}</Typography>
          </GridItem>
        );
      });
      {
        flap ? (
          department.employees.forEach((employee) => {
            departments.push(
              <GridItem
                size={240}
                key={employee.id}
                backgroundColor={"white"}
                height={"60px"}
                style={{
                  padding: "5px",
                  paddingLeft: "40px",
                  borderLeft: "1px solid #E3E6E8",
                }}
              >
                <EmployeeAvatar
                  id={employee.id}
                  firstName={employee.firstName}
                  lastName={employee.lastName}
                  imgLink={employee.imgLink}
                  position={employee.position}
                />
              </GridItem>
            );
            departments = departments.concat(renderRequests(employee.requests));
          })
        ) : (
          <></>
        );
      }

      return departments;
    });
  };

  return (
    <GridContainer gridSize={props.gridSize}>{makeHourLine()}</GridContainer>
  );
};

export default TimetrackingHours;
