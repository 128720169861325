import React from "react";
import styled from "styled-components";
import { Button, Typography } from "@mui/material";
import ButtonFilter from "../../product/pages/organisation/companies/detail/salarytypes/ButtonFilter";
import TableBase, { Column } from "./components/TableBase";
import IconButtonNew from "../buttons/IconButtonNew";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons/faAngleLeft";
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import { Page } from "../../state/organization/companies/detail/salarytype";

const ParentContainer = styled.div`
  font-family: Roboto;
  border: 1px solid lightgrey;
  overflow: hidden;
  border-radius: 12px;
`;

const HeaderContainer = styled.div`
  font-size: 20px;
  font-weight: 500;
  padding: 40px 40px 0px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FilterContainer = styled.div`
  padding: 20px 40px 40px 40px;
  display: flex;
  align-items: center;
`;

const ScrollContainer = styled.div`
  height: 400px;
  overflow: scroll;
`;

const PaginationContainer = styled.div`
  display: flex;
  padding: 8px 40px 8px 40px;
  justify-content: flex-end;
`;

const Pagination = styled.div`
  width: 213px;
  height: 40px;
  display: inline-flex;
  align-items: center;
`;

export interface QuickFilter {
  label: string;
  onClick: () => void;
  selected: boolean;
  count: number;
}

interface TableNewProps {
  addResource: { label: string; callBack: () => void };
  title: string;
  columns: Column[];
  input: Page<any>;
  quickFilter: QuickFilter[];
  pageBack: () => void;
  pageForward: () => void;
}

const TableNew = (props: TableNewProps): JSX.Element => {
  const pageLegend = (): string => {
    const pageNumber = props.input.page + 1;
    const { pageSize, totalCount, totalPages } = props.input;
    return `${pageNumber * pageSize - pageSize + 1}-${Math.min(
      totalCount,
      pageNumber * pageSize
    )}`;
  };

  return (
    <ParentContainer>
      <HeaderContainer>
        {props.title}
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={props.addResource.callBack}
        >
          {props.addResource.label}
        </Button>
      </HeaderContainer>
      <FilterContainer>
        {props.quickFilter.map((filter) => {
          return (
            <ButtonFilter
              key={uuidv4()}
              label={filter.label}
              onClick={filter.onClick}
              count={filter.count}
              selected={filter.selected}
            />
          );
        })}
      </FilterContainer>
      <ScrollContainer>
        <TableBase columns={props.columns} input={props.input} />
      </ScrollContainer>
      <PaginationContainer>
        <Pagination>
          <IconButtonNew icon={faAngleLeft} onClick={props.pageBack} />
          <Typography
            variant={"body1"}
            marginRight={"10px"}
            marginLeft={"10px"}
          >
            {pageLegend()}
          </Typography>
          <Typography variant={"body1"} color={"#999999"}>
            from
          </Typography>
          <Typography
            variant={"body1"}
            marginLeft={"10px"}
            marginRight={"10px"}
          >
            {props.input.totalCount}
          </Typography>
          <IconButtonNew icon={faAngleRight} onClick={props.pageForward} />
        </Pagination>
      </PaginationContainer>
    </ParentContainer>
  );
};

export default TableNew;
