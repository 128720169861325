import React from "react";
import { Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { GridContainer, GridItem, GridItemCalendarDay } from "../styles/styles";
import { timetrackingMonthResponse } from "../helper/exampleResponse";
import TimetrackingDayDepartment from "./TimetrackingDayDepartment";

export type RequestDummy = {
  id: number;
  startDate: string;
  endDate?: string;
  breaks: { start: string; end: string }[];
};

export interface CalendarGridProps {
  startDate: Date;
  endDate: Date;
  gridSize: number;
  gridItemDepartmentSize: number;
  gridItemSize: number;
}

const TimetrackingDays = (props: CalendarGridProps): JSX.Element => {
  const makeDayline = () => {
    const dayline: JSX.Element[] = [];

    dayline.push(
      <GridItem
        size={props.gridItemDepartmentSize}
        backgroundColor={"#f5f8fa"}
        key={uuidv4()}
        style={{
          borderRight: "1px solid #E3E6E8",
          padding: "5px",
          paddingLeft: "40px",
          borderLeft: "1px solid #E3E6E8",
        }}
        height={"40px"}
      >
        <Typography variant={"subtitle2"}>Departments</Typography>
      </GridItem>
    );

    for (
      let d = props.startDate.getTime();
      d <= props.endDate.getTime();
      d = 3600000 * 24 + d
    ) {
      const date = new Date(d);
      let weekday = "";
      switch (date.getDay()) {
        case 0:
          weekday = "Su";
          break;
        case 1:
          weekday = "Mo";
          break;
        case 2:
          weekday = "Tu";
          break;
        case 3:
          weekday = "We";
          break;
        case 4:
          weekday = "Th";
          break;
        case 5:
          weekday = "Fr";
          break;
        case 6:
          weekday = "Sa";
          break;
        default:
          weekday = "Su";
      }
      dayline.push(
        <GridItemCalendarDay
          size={props.gridItemSize}
          height={"40px"}
          background={"#f5f8fa"}
          key={uuidv4()}
          variant={"REGULAR"}
        >
          <Typography variant={"subtitle2"}>{date.getDate()}</Typography>
          <Typography variant={"caption"} color={"#4C4C4C"}>
            {weekday}
          </Typography>
        </GridItemCalendarDay>
      );
    }
    return dayline;
  };

  return (
    <GridContainer gridSize={props.gridSize}>
      {makeDayline()}
      {timetrackingMonthResponse.departments.map((department) => {
        return (
          <TimetrackingDayDepartment
            gridItemSize={props.gridItemSize}
            gridItemDepartmentSize={props.gridItemDepartmentSize}
            id={department.id}
            hoursPerDepartment={department.hourPerDepartment}
            name={department.name}
            key={department.id}
          />
        );
      })}
    </GridContainer>
  );
};

export default TimetrackingDays;
