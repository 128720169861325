import { createAsyncThunk } from "@reduxjs/toolkit";
import { PayrollRequest } from "../../config/backend/serviceInstances";
import { DataState } from "../organization/companies/detail/companyDetail";

export interface PeriodState extends DataState {
  data: PeriodResponse;
}

export const PeriodI = {
  loading: false,
  error: false,
  errorMessage: "",
  data: { companyId: 1, period: "November 21", startDate: "", endDate: "" },
};

export interface PeriodResponse {
  companyId: number;
  period: string;
  startDate: string;
  endDate: string;
}

export const getCurrentPeriod = createAsyncThunk<
  PeriodResponse,
  { companyId: number }
>("/payroll/period", async (parameters) => {
  const response = await PayrollRequest.get(
    `/period/current/${parameters.companyId}`
  );

  if (response.status > 299) {
    // Return the known error for future handling
    return response.data.error;
  }

  return response.data as PeriodResponse;
});
