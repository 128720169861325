import * as StompJs from "@stomp/stompjs";
import {connect} from "react-redux";
import React from "react";




const WebSocketClient = (props) => {

    const stompConfig = {
        // Typically login, passcode and vhost
        // Adjust these for your broker

        // Broker URL, should start with ws:// or wss:// - adjust for your broker setup
        brokerURL: window.server_url_websocket,

        // Keep it off for production, it can be quit verbose
        // Skip this key to disable
        debug: function (str) {
            console.log('STOMP: ' + str);
        },

        // If disconnected, it will retry after 200ms
        reconnectDelay: 2000,

        // Subscriptions should be done inside onConnect as those need to reinstated when the broker reconnects
        onConnect: function (frame) {
            // The return object has a method called `unsubscribe`
            const subscription = window.client.subscribe('/timetracking/live', function (message) {
                //props.updateLiveRequest({data: JSON.parse(message.body), loading: false});
            });
        }
    };

   //window.client = new StompJs.Client(stompConfig);

    // Attempt to connect
   //window.client.activate();

    return <></>
};



const mapDispatchToProps = dispatch => {
    return {
        updateLiveRequest: (value)=>dispatch({type: 'EMPLOYEE_TIMETRACKING_LIVE', payload: value}),
    }
};

export default connect(null, mapDispatchToProps) (WebSocketClient);
