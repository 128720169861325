import React, { useState } from "react";
import BasicData from "./wizardPages/BasicData";
import SteuerData from "./wizardPages/SteuerData";
import Lohn from "./wizardPages/Lohn";
import Sozial from "./wizardPages/Sozial";
import Sozial2 from "./wizardPages/Sozial2";
import { FormProvider, useForm } from "react-hook-form";
import { WizardNavigationContainer } from "./wizardPages/components/styledComponents";
import { useHistory } from "react-router-dom";
import { Employee } from "../../../../../store/actions";
import { CoreRequest } from "../../../../../config/backend/serviceInstances";
import {
  useGeneral2State,
  useMobileVersion,
} from "../../../../../state/general/selectors";
import { useDispatch } from "react-redux";
import { refreshToken } from "../../../../../state/general/auth";
import { createStyles, makeStyles } from "@mui/styles";
import {
  Button,
  CircularProgress,
  Stepper,
  Theme,
  Typography,
} from "@mui/material";
import LinkStyled from "../../../../../globalComponents/container/LinkStyled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import StepTab from "./wizardPages/components/StepTab";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "inline",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
  })
);

const useStylesWizard = makeStyles(() =>
  createStyles({
    root: {
      ".& Mui-active": {
        backgroundColor: "red",
      },
    },
  })
);

function getSteps() {
  return [
    "Allgemeine Angaben",
    "Steuer",
    "Sozialversicherung",
    "Berufsgenossenschaft",
    "Lohnabrechnung",
  ];
}

export default function AddCompanyWizard(): React.ReactElement {
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const [creationId, setCreationId] = useState(null);
  const methods = useForm({
    mode: "onChange",
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const rToken = useGeneral2State().auth.refreshToken;
  const [formStatus, setFormStatus] = useState({
    loading: false,
    error: false,
    errorMessage: "",
  });
  const steps = getSteps();
  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return <BasicData />;
      case 1:
        return <SteuerData />;
      case 2:
        return <Sozial />;
      case 3:
        return <Sozial2 />;
      case 4:
        return <Lohn />;
      default:
        return "Unknown stepIndex";
    }
  }
  const onSubmit = (data: Employee) => {
    setFormStatus({ ...formStatus, loading: true });
    switch (step) {
      case 0:
        {
          creationId === null
            ? CoreRequest.post("/companies", data.basicData)
                .then((response) => {
                  dispatch(refreshToken(rToken));
                  setCreationId(response.data.id);
                  setStep(1);
                  setFormStatus({ ...formStatus, loading: false });
                })
                .catch(() => {
                  setFormStatus({
                    loading: false,
                    error: true,
                    errorMessage: "Fehler bei der Firmenanlage",
                  });
                })
            : CoreRequest.patch("/companies/basic", data.basicData, {
                params: { companyId: creationId },
              })
                .then(() => {
                  setStep(1);
                  setFormStatus({ ...formStatus, loading: false });
                })
                .catch(() => {
                  setFormStatus({
                    loading: false,
                    error: true,
                    errorMessage: "Fehler bei der Aktualisierung der Firma",
                  });
                });
        }
        break;
      case 1:
        CoreRequest.put("/companies/tax", data.taxData, {
          params: { companyId: creationId },
        })
          .then(() => {
            setStep(2);
            setFormStatus({ ...formStatus, loading: false });
          })
          .catch(() => {
            setFormStatus({
              loading: false,
              error: true,
              errorMessage: "Fehler bei der Aktualisierung der Steuerdaten",
            });
          });
        break;
      case 2:
        CoreRequest.put("/companies/social", data.social, {
          params: { companyId: creationId },
        })
          .then(() => {
            setStep(3);
            setFormStatus({ ...formStatus, loading: false });
          })
          .catch(() => {
            setFormStatus({
              loading: false,
              error: true,
              errorMessage:
                "Fehler bei der Aktualisierung der Sozialversicherungsdaten",
            });
          });
        break;
      case 3:
        CoreRequest.put("/companies/berufsgenossenschaft", data.social2, {
          params: { companyId: creationId },
        })
          .then(() => {
            setStep(4);
            setFormStatus({ ...formStatus, loading: false });
          })
          .catch(() => {
            setFormStatus({
              loading: false,
              error: true,
              errorMessage:
                "Fehler bei der Aktualisierung der Berufsgenossenschaftsdaten",
            });
          });
        break;
      case 4:
        CoreRequest.put("/companies/payroll", data.lohn, {
          params: { companyId: creationId },
        })
          .then(() => {
            setFormStatus({ ...formStatus, loading: false });
            history.push("/app/companies/");
          })
          .catch(() => {
            setFormStatus({
              loading: false,
              error: true,
              errorMessage:
                "Fehler bei der Aktualisierung der Lohnabrechnungsdaten",
            });
          });
        break;
    }
  };

  const mobileVersion = useMobileVersion();
  const classesWizard = useStylesWizard();
  return (
    <>
      <Typography variant={"h1"} marginTop={"40px"} marginBottom={"20px"}>
        Neue Firma anlegen
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "40px",
        }}
      >
        <LinkStyled to={"/app/companies"}>
          <Typography variant={"subtitle2"} marginRight={"5px"}>
            Firmen
          </Typography>
        </LinkStyled>
        <FontAwesomeIcon icon={faChevronRight} color={"#999999"} size={"xs"} />
        <Typography variant={"body1"} marginLeft={"5px"}>
          Neue Firma
        </Typography>
      </div>
      <div className={classes.root}>
        <Stepper classes={classesWizard}>
          {!mobileVersion
            ? steps.map((label) => (
                <StepTab
                  active={step === steps.indexOf(label)}
                  step={steps.indexOf(label) + 1}
                  title={label}
                  key={label}
                />
              ))
            : steps.map((label) => (
                <StepTab
                  active={step === steps.indexOf(label)}
                  title={label}
                  step={steps.indexOf(label) + 1}
                  key={label}
                />
              ))}
        </Stepper>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {getStepContent(step)}
            <WizardNavigationContainer>
              <Button
                variant="contained"
                color={"secondary"}
                onClick={() => setStep(step - 1)}
                disabled={step == 0}
              >
                Zurück
              </Button>
              <div>
                {formStatus.error ? formStatus.errorMessage : null}
                {formStatus.loading ? (
                  <CircularProgress />
                ) : (
                  <>
                    {formStatus.error ? (
                      <Typography variant={"subtitle2"}>
                        formStatus.errorMessage
                      </Typography>
                    ) : null}
                    <Button
                      variant="contained"
                      color={"secondary"}
                      onClick={() => {
                        setStep(step + 1);
                      }}
                      disabled={step == 0}
                    >
                      Überspringen
                    </Button>
                    &nbsp;&nbsp;
                    {formStatus.loading ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        type={"submit"}
                      >
                        Weiter
                      </Button>
                    )}
                  </>
                )}
              </div>
            </WizardNavigationContainer>
          </form>
        </FormProvider>
      </div>
    </>
  );
}
