import React, { Context, createContext, useEffect, useState } from "react";
import { AbsenceType, getCompanyAbsenceRequestsImplicit } from "../utils";

// types
export type AbsenceTypesContextProps = {
  absenceTypes: AbsenceType[];
};

// context
export const AbsenceTypesContext: Context<AbsenceTypesContextProps> = createContext<AbsenceTypesContextProps>(
  {
    absenceTypes: [],
  }
);

// provider
export const AbsenceTypesProvider: React.FC = ({ children }) => {
  const [absenceTypes, setAbsenceTypes] = useState<AbsenceType[]>([]);

  useEffect(() => {
    getCompanyAbsenceRequestsImplicit().then(setAbsenceTypes);
  }, []);

  return (
    <AbsenceTypesContext.Provider value={{ absenceTypes }}>
      {children}
    </AbsenceTypesContext.Provider>
  );
};
