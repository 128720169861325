import React, { useRef, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";
import PdfDocumentFrame from "./PdfDocumentFrame";

const PageContainer = styled.div`
  padding: 20px;
`;

interface PdfDocumentRendererProps {
  file: any;
  fileName: string;
  close: () => void;
}

const PdfDocumentView = React.memo((props: PdfDocumentRendererProps) => {
  const [totalPages, setTotalPages] = useState(0);
  const containerRef = useRef<any>([]);
  return (
    <PdfDocumentFrame
      fileName={props.fileName}
      close={props.close}
      refs={containerRef}
    >
      <Document
        file={props.file}
        onLoadSuccess={(pages) => setTotalPages(pages.numPages)}
      >
        {Array.from(Array(totalPages).keys()).map((pageNumber) => (
          <PageContainer
            key={uuidv4()}
            ref={(element) => {
              containerRef.current[pageNumber] = element;
            }}
          >
            <Page pageNumber={pageNumber + 1} />
          </PageContainer>
        ))}
      </Document>
    </PdfDocumentFrame>
  );
});

export default PdfDocumentView;
