import styled from "styled-components";
import { Paper } from "@material-ui/core";

export type Styles = Partial<{ flex: string; width: string }>;

// utils
const getFlex = ({ flex = "1" }: Styles) => `flex: ${flex};`;

const getWidth = ({ width }: Styles) => {
  if (!width) return "";

  return `
  width: ${width};
  min-width: ${width};
  `;
};

// styles
export const Container = styled(Paper)`
  margin-top: 25px;
  display: flex;
  justify-content: flex-start;
  padding: 0 24px;
`;

export const ControlGroupContainer = styled.div`
  border-bottom: none;
  border-top: none;
  border-left: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${getFlex};
  ${getWidth};
`;

export const DividerContainer = styled.div`
  height: auto;
  padding: 12px;
`;
