import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

let staticUrl = "/public";
if (
  process &&
  process.env &&
  process.env.PUBLIC_URL !== null &&
  process.env.PUBLIC_URL !== undefined
) {
  staticUrl = process.env.PUBLIC_URL;
}

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "de",
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: `${staticUrl}/static/locales/{{lng}}/{{ns}}.json`,
    },
    supportedLngs: ["en", "de"],
    keySeparator: false,
  });

export default i18n;
