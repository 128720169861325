import React from "react";
import styled from "styled-components";
import { Button, Typography } from "@mui/material";
import IconButtonNew from "../../../../../../globalComponents/buttons/IconButtonNew";
import { faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import {
  newScheduleAddEditBreak,
  newWorkScheduleEmpty,
  newWorkSchedulePutToData,
} from "../../../../../../state/employees/employeeDetail/employeeDetail";
import TimeRow from "./TimeRow";
import { useNewWorkSchedule } from "../../../../../../state/employees/employeeDetail/selectors";
import { convertGridToTime } from "./helper/workScheduleGridHelper";
import TooltipNew from "../../../../../../globalComponents/tooltipNew/TooltipNew";

const ConfirmGrid = styled.div`
  display: grid;
  grid-template-columns: 120px 120px 80px;
  column-gap: 20px;
  align-items: center;
  justify-content: center;
`;

const Box = styled.div<{ left?: boolean }>`
  position: absolute;
  padding: 20px 20px 80px 20px;
  width: 360px;
  height: auto;
  border-radius: 5px;
  right: ${(props) => (props.left ? "" : "-410px")};
  left: ${(props) => (props.left ? "-410px" : "")};
  background-color: white;
  border: 1px solid lightgrey;
  flex-direction: column;
  box-shadow: 0px 0px 3px 0px lightgrey;
  z-index: 2;
`;

const ConfirmPopUp = (): JSX.Element => {
  const dispatch = useDispatch();
  const dayLine = useNewWorkSchedule().dayLine;
  const left = dayLine.columnEnd > 88;
  const startTime = convertGridToTime(dayLine.columnStart);
  const endTime = convertGridToTime(dayLine.columnEnd);
  return (
    <Box left={left}>
      <ConfirmGrid>
        <div style={{ gridColumn: "span 3" }}>
          <Typography variant={"subtitle2"} align={"center"}>
            {dayLine.weekday}
          </Typography>
        </div>
        <div style={{ position: "absolute", top: 0, right: 0 }}>
          <IconButtonNew
            icon={faTimes}
            onClick={() => dispatch(newWorkScheduleEmpty())}
          />
        </div>

        <Typography
          variant={"subtitle2"}
          marginTop={"10px"}
          marginBottom={"10px"}
        >
          Arbeitszeit
        </Typography>
        <div />
        <div />
        <Typography variant={"body1"}>Beginn</Typography>
        <Typography variant={"body1"}>Ende</Typography>
        <div />
        <TimeRow
          startHour={startTime.hour}
          startMin={startTime.minutes}
          endHour={endTime.hour}
          endMin={endTime.minutes}
          variant={"WORKINGTIME"}
        />
      </ConfirmGrid>
      <br />
      <ConfirmGrid>
        <Typography variant={"subtitle2"}>Pausenzeiten </Typography>
        <TooltipNew
          message={
            "Falls Mitarbeiter*innen zeitlich festgelegte Pausen haben können Sie diese hier festlegen. Mitarbeiter*innen werden dann an die Einhaltung der Pausenzeiten erinnert.  "
          }
        />
        <div />
        <Typography variant={"body1"}>Beginn</Typography>
        <Typography variant={"body1"}>Ende</Typography>
        <IconButtonNew
          icon={faPlusCircle}
          onClick={() =>
            dispatch(
              newScheduleAddEditBreak({
                colEnd: 72,
                colStart: 68,
              })
            )
          }
        />
        {dayLine.breaks?.map((b) => {
          const startTime = convertGridToTime(b.colStart);
          const endTime = convertGridToTime(b.colEnd);
          return (
            <TimeRow
              startHour={startTime.hour}
              startMin={startTime.minutes}
              endHour={endTime.hour}
              endMin={endTime.minutes}
              breakId={b.id}
              key={b.id}
              variant={"BREAK"}
            />
          );
        })}
        {dayLine.breaks.length == 0 ? (
          <Typography variant={"caption"}>Keine Pausen</Typography>
        ) : (
          <></>
        )}
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            display: "inline-flex",
            left: "100px",
          }}
        >
          <Button
            variant={"contained"}
            color={"secondary"}
            onClick={() => dispatch(newWorkScheduleEmpty())}
          >
            Abbrechen
          </Button>
          &nbsp;&nbsp;
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() =>
              dispatch(
                newWorkSchedulePutToData({
                  weekday: dayLine.weekday,
                })
              )
            }
          >
            Bestätigen
          </Button>
        </div>
      </ConfirmGrid>
    </Box>
  );
};

export default ConfirmPopUp;
