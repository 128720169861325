import { TableCell } from "@mui/material";
import { styled as styledMaterial } from "@mui/styles";
import styled from "styled-components";

export const ContentContainer = styled.div`
  height: 400px;
  overflow: scroll;
  background-color: white;

  padding: 40px 40px 40px 0px;
  align-items: center;
  justify-content: center;
`;

export const WizardNavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 5px 5px 5px;
  width: 99%;
  background-color: white;
  bottom: 0;
  border-top: 1px solid lightgrey;
`;

export const TableCellNoBorder = styledMaterial(TableCell)({
  border: 0,
});
