import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import { CircularProgress, TextField } from "@mui/material";
import { CoreRequest } from "../../../config/backend/serviceInstances";
import { StyledLabel, TextFieldWrapper } from "../TextFieldController";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { createStyles } from "@material-ui/core/styles";

export interface CompanyLabelFinance {
  id: number;
  label: string;
}

type Props = {
  control: Control<any>;
  fieldName: `${string}`;
  defaultValue?: CompanyLabelFinance;
  transform: any;
  label: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    option: {
      width: "100%",
      height: "40px",
      fontSize: "15px",
      fontFamily: "Roboto",
      fontWeight: 400,
      "&:hover": {
        backgroundColor: "#F5F8FA",
        color: "#054D80",
      },
    },
    inputRoot: {
      paddingTop: "0px!important",
    },
  })
);

const FinancialAuthoritySelect = (props: Props): React.ReactElement => {
  const [open, setOpen] = React.useState(false);
  const [labelResponse, setLabelResponse] = useState<{
    data: CompanyLabelFinance[];
    loading: boolean;
    error: boolean;
  }>({ data: [], loading: false, error: false });
  const classes = useStyles();
  useEffect(() => {
    if (open && labelResponse.data.length == 0) {
      setLabelResponse({ ...labelResponse, loading: true });
      CoreRequest.get("/financialAuthorities")
        .then((response) => {
          setLabelResponse({
            ...labelResponse,
            loading: false,
            data: response.data,
          });
        })
        .catch(() => {
          setLabelResponse({ ...labelResponse, loading: false, error: true });
        });
    }
  }, [open]);

  const transform = props.transform;
  const defaultValue = props.defaultValue;
  return (
    <Controller
      render={(props) => (
        <TextFieldWrapper {...props}>
          <StyledLabel>
            <label id={`financialAuth-Select`}>Aktuelle Finanzämter</label>
          </StyledLabel>
          <Autocomplete
            classes={classes}
            options={labelResponse.data}
            getOptionLabel={(option) => option.label}
            defaultValue={defaultValue}
            open={open}
            loading={labelResponse.loading}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            loadingText={"Lade aktuelle Finanzämter..."}
            renderOption={(option: any) => <span>{option.label}</span>}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {labelResponse.loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps?.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            onChange={(_, data) => props.field.onChange(transform.output(data))}
          />
        </TextFieldWrapper>
      )}
      name={props.fieldName}
      control={props.control}
    />
  );
};

export default FinancialAuthoritySelect;
