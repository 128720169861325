import styled from "styled-components";

export const StyledParagraph = styled.p`
  font-size: 15px;
  margin: 0;
`;

export const StyledTextArea = styled.textarea`
  font-size: 15px;
  margin: 0;
`;
