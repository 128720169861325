import React, { FormEvent, useState } from "react";
import Wrapper from "../components/Wrapper";
import { useHistory } from "react-router-dom";
import qs from "query-string";
import axios from "axios";
import { authUrl } from "../../config/globalVariables";
import PublicWrapper from "../components/PublicWrapper";
import FeedbackContainer from "../components/FeedbackContainer";
import { Button, CircularProgress, TextField, Typography } from "@mui/material";

const config = {
  withCredentials: true,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

const VerificationCode: React.FC = () => {
  const history = useHistory();
  const [formFields, setFormFields] = useState({
    code: "",
  });

  const [formState, setFormState] = useState({
    loading: false,
    error: false,
    message: "",
  });

  //in case of direct routing to /register/code
  if (history.location.state === undefined) {
    history.push("/register/email");
  }

  const verifyAccount = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const body = {
      email: history.location.state,
      code: formFields.code,
    };
    setFormState({ loading: true, error: false, message: "" });

    axios
      .post(authUrl + "/user/verify", qs.stringify(body), config)
      .then(() => {
        setFormState({ loading: true, error: false, message: "" });
        history.push({
          pathname: "/register/password",
          state: body,
        });
      })
      .catch(() => {
        setFormState({
          loading: false,
          error: true,
          message: "Ein Fehler ist aufgetreten.",
        });
      });
  };

  return (
    <PublicWrapper>
      <Wrapper
        onSubmit={(e) => {
          verifyAccount(e);
        }}
      >
        <Typography variant={"subtitle2"}>
          Bitte geben Sie den vierstelligen Code ein, den Sie vom Admin erhalten
          haben:
        </Typography>
        <br />
        <TextField
          label={"Code"}
          variant={"standard"}
          sx={{ width: "100%" }}
          id={"code"}
          autoFocus
          value={formFields.code}
          onChange={(e) => setFormFields({ code: e.target.value })}
        />
        <br />
        {formState.loading ? (
          <CircularProgress />
        ) : (
          <Button type={"submit"} variant={"contained"} color={"primary"}>
            Weiter
          </Button>
        )}
      </Wrapper>
      {formState.error ? (
        <FeedbackContainer>
          <Typography variant={"subtitle2"}>{formState.message}</Typography>
        </FeedbackContainer>
      ) : (
        <></>
      )}
    </PublicWrapper>
  );
};

export default VerificationCode;
