import { useEffect, useState } from "react";

export type Animate = "animateOpen" | "animateClose" | undefined;

export type UseAnimate = (value: boolean) => Animate;

const useAnimate: UseAnimate = (value) => {
  const [animate, setAnimate] = useState<Animate>();

  useEffect(() => {
    if (!animate && !value) return;

    setAnimate(value ? "animateOpen" : "animateClose");
  }, [value]);

  return animate;
};

export default useAnimate;
