import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CoreRequest } from "../../../config/backend/serviceInstances";
import { DataState } from "../../organization/companies/detail/companyDetail";
import { Page } from "../../organization/companies/detail/salarytype";

// action names
export const EMPLOYEES_PROFILES_LIST = "employees/profiles/list";

// types
export interface EmployeeProfilesState extends DataState {
  data: Employee[];
  page: number;
  pageSize: number;
  totalPages: number;
  totalCount: number;
}

export type Employee = {
  id: number;
  firstName: string;
  lastName: string;
  position: string;
  imgLink: string;
};

// actions
export const listEmployeeProfiles = createAsyncThunk(
  EMPLOYEES_PROFILES_LIST,
  async () => {
    const response = await CoreRequest.get(`/employees/all`);
    if (response.status > 299) {
      // Return the known error for future handling
      return response.data.error;
    }

    return response.data as Page<Employee>;
  }
);

const initialState: EmployeeProfilesState = {
  loading: false,
  error: false,
  errorMessage: undefined,
  data: [],
  page: 0,
  pageSize: 25,
  totalCount: 0,
  totalPages: 0,
};

// reducer
const employeeProfiles = createSlice({
  name: "employees/profiles",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listEmployeeProfiles.pending, (state) => {
      state.loading = true;
      state.data = [];
    });
    builder.addCase(listEmployeeProfiles.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.page = action.payload.page;
      state.pageSize = action.payload.pageSize;
      state.totalCount = action.payload.totalCount;
      state.totalPages = action.payload.totalPages;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(listEmployeeProfiles.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default employeeProfiles;
