import React from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";

const StatusContainer = styled.div<{ backgroundColor: string; color: string }>`
  min-width: 60px;
  height: 20px;
  display: flex;
  border-radius: 5px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
`;

export enum Status {
  WITHDRAWN = "WHITDRAWN",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  APPROVED = "APPROVED",
  DOCUMENT_PROVIDED = "DOCUMENT_PROVIDED",
}

interface StatusChipProps {
  status: Status;
}

const StatusChip = (props: StatusChipProps): JSX.Element => {
  let backgroundColor: string;
  let color: string;
  let text: string;
  switch (props.status) {
    case Status.APPROVED:
      {
        color = "#3B855C";
        backgroundColor = "#EAF5F0";
        text = "Approved";
      }
      break;
    case Status.DOCUMENT_PROVIDED:
      {
        color = "#3B855C";
        backgroundColor = "#EAF5F0";
        text = "Document Provided";
      }
      break;
    case Status.PENDING:
      {
        color = "orange";
        backgroundColor = "orange";
        text = "Pending";
      }
      break;
    case Status.WITHDRAWN:
      {
        color = "grey";
        backgroundColor = "darkgrey";
        text = "Withdrawn";
      }
      break;
    case Status.REJECTED:
      {
        color = "red";
        backgroundColor = "darkred";
        text = "Rejected";
      }
      break;
    default: {
      color = "white";
      backgroundColor = "white";
      text = "Missing";
    }
  }
  return (
    <StatusContainer color={color} backgroundColor={backgroundColor}>
      <Typography variant={"caption"}>{text}</Typography>
    </StatusContainer>
  );
};

export default StatusChip;
