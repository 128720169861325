import React, { useEffect, useState } from "react";
import { CoreRequest } from "../../config/backend/serviceInstances";
import { baseUrl } from "../../config/globalVariables";
import FileReaderContainer from "./FileReaderContainer";
import ImageReader from "./ImageReader";
import PdfDocumentView from "./PdfDocumentView";

interface FilePreviewProps {
  fileName: string;
  inputType: "pdf" | "image";
  endpoint: string;
  close: () => void;
}

const FilePreview = (props: FilePreviewProps): JSX.Element => {
  const [state, setState] = useState<{ file: any }>({
    file: null,
  });

  useEffect(() => {
    CoreRequest.get(baseUrl + props.endpoint, {
      responseType: "blob",
    }).then((response) => {
      const reader = new FileReader();
      reader.readAsDataURL(response.data);
      reader.onloadend = () => {
        const base64data = reader.result;
        setState({ file: base64data });
      };
    });
  }, []);

  if (props.inputType == "pdf")
    return (
      <PdfDocumentView
        fileName={props.fileName}
        close={props.close}
        file={state.file}
      />
    );
  else
    return (
      <FileReaderContainer
        currentPages={1}
        totalPages={1}
        closeCallBack={props.close}
        fileName={props.fileName}
      >
        <ImageReader file={state.file} />
      </FileReaderContainer>
    );
};

export default FilePreview;
