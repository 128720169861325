import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Ripple from "../Ripple";
import Sector from "./Sector";
import {
  getBorderColor,
  getIconColor,
  setState,
  getSegementColor,
  getSegementOffset,
  getSegementValue,
  getSmileyDimentions,
  colors,
} from "./utils";
import PeopleIcon from "@material-ui/icons/People";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import TimerIcon from "@material-ui/icons/Timer";
import MoneyIcon from "@mui/icons-material/Money";

// types
export type Status = "green" | "yellow" | "red";

export enum ModuleNames {
  DPF = "DPF",
  ABSENCE = "ABSENCE",
  TIMETRACKING = "TIMETRACKING",
  PAYROLL = "PAYROLL",
}

export type SectorData = {
  name: ModuleNames;
  status: Status;
};

export type StatusWheelProps = SmileyProps & {
  sectores: SectorData[];
  onChange?: (active: string[]) => void;
  className?: string;
};

export type SmileyProps = {
  status: Status;
};

// styles
export const Container = styled.div`
  position: relative;
  width: 275px;
  height: 275px;
`;

export const MouseEventsArea = styled.div`
  position: absolute;
  border-radius: 100%;
  height: inherit;
  width: inherit;
`;

export const SmileyContainer = styled.div`
  width: inherit;
  height: inherit;
  position: absolute;
  border-radius: 100%;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;

export const SmileyBackground = styled.div`
  border-radius: 100%;
  background-color: white;
  z-index: 100;
  pointer-events: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  ${getSmileyDimentions}
`;

export const SmileyFace = styled.div`
  width: 45%;
  height: 45%;
  border-radius: 100%;
  background-color: ${colors.sectorColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SmileyRightEye = styled.div`
  width: 3%;
  height: 3%;
  border-radius: 100%;
  background-color: ${colors.sectorActiveColor};
  transform: translate(125%, -60.25%);
  position: absolute;
`;

export const SmileyLeftEye = styled(SmileyRightEye)`
  transform: translate(-125%, -60.25%);
`;

export const SmileyMouth = styled.div`
  position: absolute;
  width: 8%;
  height: 1.5%;
  background-color: ${colors.sectorActiveColor};
  transform: translate(0, 250%);
  border-radius: 10px;
`;

export const SmileyHappyMouth = styled.div`
  position: absolute;
  width: 8%;
  height: 4%;
  background-color: ${colors.sectorActiveColor};
  transform: translate(0, 100%);
  border-radius: 0 0 100px 100px;
`;

export const SmileySadMouth = styled.div`
  position: absolute;
  width: 8%;
  height: 4%;
  background-color: ${colors.sectorActiveColor};
  transform: translate(0, 100%);
  border-radius: 100px 100px 0 0;
`;

export const StyledRipple = styled(Ripple)`
  height: inherit;
  width: inherit;
`;

// components
const StatusWheel: React.FC<StatusWheelProps> = ({
  className,
  sectores,
  status,
  onChange,
}) => {
  const [active, setActive] = useState<string[]>([]);
  const [hover, setHover] = useState<string[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (onChange) onChange(active);
  }, [active]);

  const onClickHandler = setState(ref)(sectores)(active)(setActive);
  const onMouseEnterHandler = setState(ref)(sectores)(hover)(setHover);
  let icon = <MoneyIcon />;
  return (
    <Container className={className}>
      {sectores.map(({ status, name }, index) => {
        switch (name) {
          case "DPF":
            icon = <PeopleIcon />;
            break;
          case "ABSENCE":
            icon = <BeachAccessIcon />;
            break;
          case "TIMETRACKING":
            icon = <TimerIcon />;
            break;
          case "PAYROLL":
            icon = <MoneyIcon />;
        }
        return (
          <Sector
            key={name}
            title={name}
            value={getSegementValue(sectores.length)}
            offset={getSegementOffset(sectores.length)(index)}
            color={getSegementColor(name)(active)(hover)}
            borderColor={getBorderColor(status)}
            icon={icon}
            iconColor={getIconColor(name)(active)(hover)}
            isShowTooltip={hover.includes(name)}
          />
        );
      })}
      <Smiley status={status} />
      <StyledRipple>
        <MouseEventsArea
          ref={ref}
          onClick={onClickHandler}
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={() => setHover([])}
        />
      </StyledRipple>
    </Container>
  );
};

const Smiley: React.FC<SmileyProps> = ({ status }) => {
  const renderSmileyMouth = () => {
    if (status === "green") return <SmileyHappyMouth />;

    if (status === "red") return <SmileySadMouth />;

    return <SmileyMouth />;
  };

  return (
    <SmileyContainer>
      <SmileyBackground>
        <SmileyFace>
          <SmileyRightEye />
          <SmileyLeftEye />
          {renderSmileyMouth()}
        </SmileyFace>
      </SmileyBackground>
    </SmileyContainer>
  );
};

export default StatusWheel;
