import React from "react";
import styled from "styled-components";
import { Chip } from "@material-ui/core";
import { getPlaceHolder, REGEX_DECORATOR } from "./utils";

// types
export type PlaceholderItemProps = {
  decoratedText: string;
};

// styles
export const Container = styled.span`
  .MuiChip-root {
    margin: 0;
  }
`;

export const PlaceholderText = styled.span`
  display: none;
`;

// components
const PlaceholderItem: React.FC<PlaceholderItemProps> = ({
  decoratedText,
  ...props
}) => {
  const regExMatch = decoratedText.matchAll(REGEX_DECORATOR);
  const [, id] = Array.from(regExMatch).pop() ?? [];
  const label = getPlaceHolder(id)?.label;

  return (
    <Container>
      <Chip label={label} />
      <PlaceholderText>{props.children}</PlaceholderText>
    </Container>
  );
};

export default PlaceholderItem;
