import React from "react";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import ExtensionIcon from "@material-ui/icons/Extension";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import GroupIcon from "@material-ui/icons/Group";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import TimerIcon from "@material-ui/icons/Timer";
import MoneyIcon from "@material-ui/icons/Money";
import TodayIcon from "@material-ui/icons/Today";
import SettingsIcon from "@material-ui/icons/Settings";
import BusinessIcon from "@material-ui/icons/Business";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssessmentIcon from "@mui/icons-material/Assessment";

export interface menuItem {
  key: number;
  title: string;
  link: string | false;
  icon: JSX.Element;
  children: Array<child> | false;
}

export interface child {
  key: number;
  title: string;
  link: string;
  icon: JSX.Element;
  children: Array<menuItem> | false;
}

export interface menu {
  menuItems: Array<menuItem>;
}

export const menuAdmin: menu = {
  menuItems: [
    {
      key: 21,
      title: "Dashboard",
      link: "/app",
      icon: <DashboardIcon />,
      children: false,
    },
    {
      key: 1,
      title: "Organisation",
      link: false,
      icon: <LocationCityIcon />,
      children: [
        {
          key: 2,
          title: "Companies",
          link: "/app/companies",
          icon: <BusinessIcon fontSize={"small"} />,
          children: false,
        },
        {
          key: 3,
          title: "Departments",
          link: "/app/departments",
          icon: <ExtensionIcon fontSize={"small"} />,
          children: false,
        },
        {
          key: 4,
          title: "Locations",
          link: "/app/locations",
          icon: <LocationOnIcon fontSize={"small"} />,
          children: false,
        },
      ],
    },
    {
      key: 5,
      title: "Mitarbeiter",
      link: false,
      icon: <GroupIcon />,
      children: [
        {
          key: 6,
          title: "Profile",
          link: "/app/employees",
          icon: <AssignmentIndIcon fontSize={"small"} />,
          children: false,
        },
        {
          key: 8,
          title: "Abwesenheiten",
          link: "/app/absences",
          icon: <BeachAccessIcon fontSize={"small"} />,
          children: false,
        },
        {
          key: 9,
          title: "Zeiterfassung",
          link: "/app/timetracking",
          icon: <TimerIcon fontSize={"small"} />,
          children: false,
        },
      ],
    },
    {
      key: 10,
      title: "Lohn",
      link: "/app/payroll",
      icon: <MoneyIcon />,
      children: false,
    },
    {
      key: 20,
      title: "Reporting",
      link: "/app/reporting",
      icon: <AssessmentIcon />,
      children: false,
    },
    {
      key: 13,
      title: "Einstellungen",
      link: false,
      icon: <SettingsIcon />,
      children: [
        {
          key: 14,
          title: "Digitale Personalakte",
          link: "/app/holiday",
          icon: <TodayIcon fontSize={"small"} />,
          children: false,
        },
        {
          key: 15,
          title: "Abwesenheiten",
          link: "/app/settings/documents",
          icon: <NoteAddIcon fontSize={"small"} />,
          children: false,
        },
      ],
    },
  ],
};

export const menuEmployee: menu = {
  menuItems: [
    {
      key: 16,
      title: "Persönliche Daten",
      link: false,
      icon: <NoteAddIcon />,
      children: false,
    },
    {
      key: 17,
      title: "Urlaub & Abwesenheiten",
      link: false,
      icon: <BeachAccessIcon />,
      children: false,
    },
    {
      key: 18,
      title: "Zeiterfassung",
      link: false,
      icon: <TodayIcon />,
      children: false,
    },
    {
      key: 19,
      title: "Lohnabrechnung",
      link: false,
      icon: <MoneyIcon />,
      children: false,
    },
    {
      key: 20,
      title: "Reporting",
      link: false,
      icon: <AssessmentIcon />,
      children: false,
    },
  ],
};
