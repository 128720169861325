import { Activity } from "./Overview";
import { ModuleNames, SectorData } from "./StatusWheel";
import { statusTypeValues } from "../../../globalComponents/notification/StatusNotification";
import { ApprovalStatus, DocumentStatus } from "../../../state/employees/types";

// @todo remove when server data is available
export const sampleData: { modules: SectorData[]; activities: Activity[] } = {
  modules: [
    {
      name: ModuleNames.DPF,
      status: "green",
    },
    {
      name: ModuleNames.ABSENCE,
      status: "yellow",
    },
    { name: ModuleNames.TIMETRACKING, status: "red" },
    { name: ModuleNames.PAYROLL, status: "green" },
  ],
  activities: [
    {
      id: 1,
      type: statusTypeValues.WORK_PERMIT_EXPIRATION,
      category: "DPF",
      employee: {
        id: 1,
        firstName: "Alex",
        lastName: "Müller",
        imgLink: "",
        status: "",
        companyName: "",
        companyId: 1,
        department: "",
        startDate: "",
        location: "",
        position: "",
      },
      date: "2021-11-12",
    },
    {
      id: 2,
      type: statusTypeValues.BIRTHDAY,
      category: "DPF",
      date: "2021-12-10",
      employee: {
        id: 2,
        firstName: "Paula",
        lastName: "Zimak",
        imgLink: "",
        status: "",
        companyName: "",
        companyId: 1,
        department: "",
        startDate: "",
        location: "",
        position: "",
      },
    },
    {
      id: 3,
      type: statusTypeValues.NEW_EMPLOYEE,
      category: "DPF",
      date: "2021-12-05",
      employee: {
        id: 3,
        firstName: "Julia",
        lastName: "Wiedemeyer",
        imgLink: "",
        status: "",
        companyName: "",
        companyId: 1,
        department: "",
        startDate: "",
        location: "",
        position: "",
      },
    },
    {
      id: 4,
      type: statusTypeValues.ABSENCE_REQUEST,
      category: "Absences",
      date: "2021-04-05",
      employee: {
        id: 4,
        firstName: "Magdalena",
        lastName: "Neuner",
        imgLink: "",
        status: "",
        companyName: "",
        companyId: 1,
        department: "",
        startDate: "",
        location: "",
        position: "",
      },
      absenceRequest: {
        id: 16,
        startDate: "2021-04-03",
        endDate: "2021-04-07",
        duration: 3,
        startWithHalf: false,
        endWithHalf: false,
        comment: "",
        documentStatus: DocumentStatus.NOT_REQUIRED,
        documents: [],
        workContract: {
          id: 1,
          startDate: "",
          endDate: "",
          position: "",
          status: "",
          personalNr: 0,
          locationName: "",
          lastName: "",
          firstName: "",
          imgLink: "",
          employeeId: 0,
          departmentName: "",
          departmentId: 0,
          creationDate: "",
          companyId: 0,
          company: {
            id: 0,
            imgLink: "",
            managingDirectors: [],
            motherCompany: null,
            city: "",
            children: [],
            activeEmployees: null,
            entityType: "",
            company: { label: "", value: "" },
          },
        },
        approvalStatus: ApprovalStatus.REQUESTED,
        absenceType: {
          id: 123,
          description: "Holiday",
          companyId: 1,
          halfDayPossible: true,
          budgetRequired: true,
          documentRequired: false,
          approvalRequired: false,
        },
      },
    },
  ],
};
