import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { giveRow } from "./giveRow";
import { useNewWorkSchedule } from "../../../../../../state/employees/employeeDetail/selectors";
import { useDispatch } from "react-redux";
import {
  changeWorkScheduleNewState,
  NewScheduleRowState,
  WorkingDay,
  WorkScheduleSlideRows,
} from "../../../../../../state/employees/employeeDetail/employeeDetail";
import ConfirmPopUp from "./ConfirmPopUp";
import {
  AddLeadingZero,
  convertTimeToGrid,
} from "./helper/workScheduleGridHelper";
import { v4 as uuidv4 } from "uuid";

export const Griddy = styled.div<{
  row: string;
  column: string;
  color: string;
  align?: "flex-start" | "flex-end";
  hideBorder?: boolean;
}>`
  background-color: ${(props) => props.color};
  grid-row: ${(props) => props.row};
  grid-column: ${(props) => props.column};
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.align ? props.align : "center")};
  border-bottom: ${(props) => (props.hideBorder ? "" : "1px solid lightgrey")};
`;

interface Griddy_Draft_Props {
  row: string;
  column: string;
}

const GriddyDraft = styled.div.attrs<Griddy_Draft_Props>(({ row, column }) => ({
  style: {
    gridRow: row,
    gridColumn: column,
  },
}))<Griddy_Draft_Props>`
  position: relative;
  background-color: #004c80;
  outline: 1px solid #e3e6e8;
  display: flex;
  align-items: center;
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
`;

export const GriddyTimeline = styled.div<{
  row: string;
  column: string;
}>`
  position: relative;
  background-color: #004c80;
  outline: 1px solid #e3e6e8;
  grid-row: ${(props) => props.row};
  grid-column: ${(props) => props.column};
  display: flex;
  align-items: center;
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 4px;
  z-index: 2;
  &:hover {
    outline: 2px solid lightgrey;
    cursor: pointer;
  }
`;

export const GriddyBreak = styled.div<{
  row: string;
  column: string;
}>`
  position: relative;
  background-color: #ffcc32;
  outline: 1px solid #e3e6e8;
  grid-row: ${(props) => props.row};
  grid-column: ${(props) => props.column};
  display: flex;
  align-items: center;
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 2;
`;

interface Grid_Item_Quarter_Hour_Props {
  row: string;
  column: string;
  hover?: boolean;
  index: number;
}

const Grid_Item_Quarter_Hour = styled.div.attrs<Grid_Item_Quarter_Hour_Props>(
  ({ row, column, index }) => ({
    style: {
      gridRow: row,
      gridColumn: column,
      borderLeft:
        index % 4 == 0 ? "1px solid lightgrey" : "1px dotted lightgrey",
    },
  })
)<Grid_Item_Quarter_Hour_Props>`
  border-bottom: 1px solid lightgrey;
  &:hover {
    background-color: ${(props) => (props.hover ? "#D9D9D9" : "")};
  }
  z-index: 2;
`;

export enum Weekday {
  Mon = "Monday",
  Tue = "Tuesday",
  Wed = "Wednesday",
  Thu = "Thursday",
  Fri = "Friday",
  Sat = "Saturday",
  Sun = "Sunday",
}

interface WorkScheduleRowProps {
  day: WorkingDay;
  weekday: Weekday;
}

const WorkScheduleRow = (props: WorkScheduleRowProps): JSX.Element => {
  const dispatch = useDispatch();
  const dayline = useNewWorkSchedule().dayLine;
  const day = props.day;
  const backgroundJSX: JSX.Element[] = [];
  const row = giveRow(props.weekday);

  for (let i = 0; i < 96; i++) {
    const r = i + 20;
    backgroundJSX.push(
      <Grid_Item_Quarter_Hour
        row={row}
        column={`${r}/${r}`}
        index={i}
        hover={dayline.state == NewScheduleRowState.INITIAL}
        key={uuidv4()}
        onClick={() =>
          dispatch(
            changeWorkScheduleNewState({
              column: r,
              row: row,
              weekday: props.weekday,
            })
          )
        }
        onMouseOver={() =>
          dayline.state == NewScheduleRowState.DRAFT
            ? dispatch(WorkScheduleSlideRows(r))
            : null
        }
      />
    );
  }
  return (
    <>
      <Griddy
        row={row}
        column={"1/20"}
        color={"transparent"}
        align={"flex-start"}
      >
        <Typography variant={"subtitle2"}>{props.weekday}</Typography>
      </Griddy>
      {backgroundJSX}
      <Griddy
        row={row}
        column={"116/136"}
        color={"transparent"}
        align={"flex-end"}
      >
        <Typography variant={"subtitle2"}>
          {AddLeadingZero(props.day.hours)} :{" "}
          {AddLeadingZero(props.day.minutes)}
        </Typography>
      </Griddy>
      {dayline.state !== NewScheduleRowState.INITIAL ? (
        <GriddyDraft
          row={dayline.row}
          column={`${dayline.columnStart}/${dayline.columnEnd}`}
          key={uuidv4()}
        >
          {dayline.state == NewScheduleRowState.CONFIRM ? (
            <ConfirmPopUp />
          ) : (
            <></>
          )}
        </GriddyDraft>
      ) : (
        <></>
      )}
      {dayline.state !== NewScheduleRowState.INITIAL ? (
        dayline.breaks.map((b) => {
          return (
            <GriddyDraft
              row={dayline.row}
              column={`${b.colStart}/${b.colEnd}`}
              key={b.id}
            />
          );
        })
      ) : (
        <></>
      )}
      <GriddyTimeline
        row={row}
        key={uuidv4()}
        column={`${convertTimeToGrid(
          day.workingTimes?.startHour,
          day.workingTimes?.startMin
        )}/${convertTimeToGrid(
          day.workingTimes?.endHour,
          day.workingTimes?.endMin
        )}`}
      />
      {day.breaks?.map((b) => {
        return (
          <GriddyBreak
            row={row}
            key={uuidv4()}
            column={`${convertTimeToGrid(
              b.startHour,
              b.startMin
            )}/${convertTimeToGrid(b.endHour, b.endMin)}`}
          />
        );
      })}
    </>
  );
};

export default WorkScheduleRow;
