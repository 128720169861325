import React, { useState } from "react";
import { DisplayCardField, FormFieldType } from "../../dataCard/utils";
import { Button } from "@mui/material";
import SelectMenuInput, {
  LabelValuePair,
} from "../../formFields/staticSelect/SelectMenuInput";
import SelectBoolean from "../../formFields/SelectBoolean";
import { FieldValues, useForm, UseFormHandleSubmit } from "react-hook-form";
import styled from "styled-components";
import TextFieldController from "../../formFields/TextFieldController";
import { CoreRequest } from "../../../config/backend/serviceInstances";
import EmployeesSelect from "../../formFields/dynamic/EmployeesSelect";
import CompaniesSelect from "../../formFields/dynamic/CompaniesSelect";
import DepartmentSelect from "../../formFields/dynamic/DepartmentSelect";
import SelectBooleanUncontrolled from "../smartTable/SelectBooleanUncontrolled";
import LocationSelect from "../../formFields/dynamic/LocationSelect";
import DateFieldRHF from "../../formFields/dateField/DateFieldRHF";
import NumberSelect from "../../formFields/NumberSelect";

export const StyledForm = styled.form`
  position: relative;
  height: 100%;
`;

export const UpperContainer = styled.div``;

export const FormFieldContainer = styled.div`
  position: relative;
  height: 100%;
  padding-bottom: 15px;
`;

export const BottomContainer = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

//delete the request parameter from body object d
function deleteFromObject(keyPart: string, obj: Record<string, any>) {
  for (const k in obj) {
    // Loop through the object
    if (~k.indexOf(keyPart)) {
      // If the current key contains the string we're looking for
      delete obj[k]; // Delete obj[key];
    }
  }
}

interface FormFieldReturnProps {
  endpoint: {
    url: string;
    requestParameter?: LabelValuePair[];
    bodyParameter?: LabelValuePair[];
    pathParameter?: string[];
    type: "POST" | "PUT" | "PATCH";
  };
  formFields: DisplayCardField[];
  confirm: () => void;
}

const FormFieldReturn = (props: FormFieldReturnProps): JSX.Element => {
  const { control, getValues, unregister, handleSubmit, watch } = useForm();
  const onSubmit = (d: UseFormHandleSubmit<FieldValues>) => {
    let finalUrl: string = props.endpoint.url;

    //add optional request Parameter
    const requestParameterFromProps = props.endpoint.requestParameter;
    if (requestParameterFromProps) {
      for (let i = 0; i < requestParameterFromProps.length; i++) {
        let r = "&";
        if (i == 0) r = "?";
        finalUrl = finalUrl.concat(
          r +
            requestParameterFromProps[i].label +
            "=" +
            requestParameterFromProps[i].value
        );
      }
    }

    //add optional request parameter from formfields and remove them from body
    const requestParameter = props.formFields.filter(
      (item) => item.asRequestParameter
    );

    //find their values in d and add them to url
    for (let i = 0; i < requestParameter.length; i++) {
      let r = "&";
      if (i == 0 && requestParameterFromProps == undefined) r = "?";
      const fieldName =
        requestParameter[i].requestFieldName !== undefined
          ? requestParameter[i].requestFieldName
          : requestParameter[i].fieldName;
      const editedValue = getValues(fieldName as string);
      if (editedValue !== undefined) {
        finalUrl = finalUrl.concat(r + fieldName + "=" + editedValue);
      }
      deleteFromObject(fieldName as string, d);
    }

    //add optional body parameters
    const obj: any = d;
    if (props.endpoint.bodyParameter !== undefined) {
      for (let i = 0; i < props.endpoint.bodyParameter.length; i++) {
        obj[props.endpoint.bodyParameter[i].label] =
          props.endpoint.bodyParameter[i].value;
      }
    }

    //add optional path parameter
    const pathParameterFromProps = props.endpoint.pathParameter;
    if (pathParameterFromProps) {
      for (let i = 0; i < pathParameterFromProps.length; i++) {
        finalUrl = finalUrl.concat("/" + pathParameterFromProps[i] + "/");
      }
    }

    if (props.endpoint.type == "POST") {
      CoreRequest.post(finalUrl, obj).then(() => props.confirm());
    }

    if (props.endpoint.type == "PUT") {
      CoreRequest.put(finalUrl, obj).then(() => props.confirm());
    }

    if (props.endpoint.type == "PATCH") {
      CoreRequest.patch(finalUrl, obj).then(() => props.confirm());
    }
  };
  const [fieldsToRender, setFieldsToRender] = useState<string[]>([]);
  const companyField = props.formFields.find(
    (item) => item.type === FormFieldType.SELECT_MOTHER_COMPANY
  );
  let companyIdWatch: number | null = null;
  if (companyField != null) {
    companyIdWatch = watch(companyField.fieldName);
  }

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <UpperContainer>
        {props.formFields.map((item) => {
          if (
            item.conditionalRender &&
            !fieldsToRender.includes(item.fieldName)
          ) {
            return <></>;
          } else {
            switch (item.type) {
              case FormFieldType.TEXT:
                if (item.defaultValue instanceof Date) {
                  throw TypeError(
                    "FormFieldType.TEXT does not accept defaultValue Date types"
                  );
                }
                return (
                  <FormFieldContainer>
                    <TextFieldController
                      fieldName={item.fieldName}
                      defaultValue={item.defaultValue}
                      rules={item.validation}
                      control={control}
                      label={item.label}
                    />
                  </FormFieldContainer>
                );
              case FormFieldType.SELECT:
                if (item.defaultValue instanceof Date) {
                  throw TypeError(
                    "FormFieldType.SELECT does not accept defaultValue Date types"
                  );
                }
                return (
                  <FormFieldContainer>
                    <SelectMenuInput
                      key={item.fieldName}
                      label={item.label}
                      control={control}
                      fieldName={item.fieldName}
                      defaultValue={item.defaultValue}
                      menuItems={item.menuItems ? item.menuItems : []}
                      errorMessage={"Bitte auswählen"}
                    />
                  </FormFieldContainer>
                );
              case FormFieldType.BOOLEAN:
                if (item.defaultValue instanceof Date) {
                  throw TypeError(
                    "FormFieldType.BOOLEAN does not accept defaultValue Date types"
                  );
                }
                return (
                  <FormFieldContainer>
                    <SelectBoolean
                      key={item.fieldName}
                      label={item.label}
                      control={control}
                      fieldName={item.fieldName}
                      defaultValue={item.defaultValue}
                      errorText={"Bitte ausfüllen"}
                    />
                  </FormFieldContainer>
                );
              case FormFieldType.SELECT_EMPLOYEES:
                return (
                  <FormFieldContainer>
                    <EmployeesSelect
                      key={item.fieldName}
                      label={item.label}
                      control={control}
                      fieldName={item.fieldName}
                      defaultValue={
                        item.defaultValue !== ""
                          ? item.defaultValue?.map((item: any) => {
                              return item.id;
                            })
                          : []
                      }
                    />
                  </FormFieldContainer>
                );
              case FormFieldType.SELECT_MOTHER_COMPANY:
                return (
                  <FormFieldContainer>
                    <CompaniesSelect
                      control={control}
                      fieldName={item.fieldName}
                    />
                  </FormFieldContainer>
                );
              case FormFieldType.BOOLEAN_WATCH_FIELD:
                const fields = item.conditionalFields
                  ? item.conditionalFields
                  : [];
                return (
                  <FormFieldContainer>
                    <SelectBooleanUncontrolled
                      question={item.label}
                      onClickYes={() =>
                        setFieldsToRender(fieldsToRender.concat(fields))
                      }
                      onClickNo={() => {
                        unregister(fields);
                        const fieldCopy = [...fieldsToRender];
                        fields.forEach((index) => {
                          fieldCopy.splice(Number(index));
                        });
                        setFieldsToRender(fieldCopy);
                      }}
                    />
                  </FormFieldContainer>
                );
              case FormFieldType.SELECT_DEPARTMENT:
                return (
                  <FormFieldContainer>
                    <DepartmentSelect
                      control={control}
                      companyId={companyIdWatch}
                      fieldName={item.fieldName}
                    />
                  </FormFieldContainer>
                );
              case FormFieldType.SELECT_LOCATION:
                return (
                  <FormFieldContainer>
                    <LocationSelect
                      control={control}
                      companyId={companyIdWatch}
                      fieldName={item.fieldName}
                    />
                  </FormFieldContainer>
                );
              case FormFieldType.DATE:
                return (
                  <FormFieldContainer>
                    <DateFieldRHF
                      control={control}
                      fieldName={item.fieldName}
                      label={item.label}
                      defaultValue={item.defaultValue || "2021-12-12"}
                    />
                  </FormFieldContainer>
                );
              case FormFieldType.DATE_LEFT:
                return (
                  <FormFieldContainer>
                    <DateFieldRHF
                      control={control}
                      fieldName={item.fieldName}
                      label={item.label}
                      openCalendar={"LEFT"}
                      defaultValue={item.defaultValue || "2021-12-12"}
                    />
                  </FormFieldContainer>
                );
              case FormFieldType.TIME_FIELD:
                return (
                  <FormFieldContainer>
                    <NumberSelect
                      control={control}
                      label={item.label}
                      fieldNameMin={item.minutesFieldName || ""}
                      fieldNameHours={item.hoursFieldName || ""}
                      defaultValueHours={
                        (item.defaultValueHours as number) || 0
                      }
                      defaultValueMin={(item.defaultValueMin as number) || 0}
                    />
                  </FormFieldContainer>
                );
            }
          }
        })}
      </UpperContainer>
      <BottomContainer>
        <Button type={"submit"} variant={"contained"} color={"primary"}>
          Bestätigen
        </Button>
      </BottomContainer>
    </StyledForm>
  );
};

export default FormFieldReturn;
