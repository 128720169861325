import {
  eachMonthOfInterval,
  endOfYear,
  format,
  getMonth,
  getYear,
  startOfYear,
} from "date-fns/fp";
import { compose } from "lodash/fp";

// types
type MonthLabel = {
  date: Date;
  monthLabel: string;
};

export type GetYearMonthsInterval = (date: Date) => Date[];

export type FormatDatesToMonthLabels = (dates: Date[]) => MonthLabel[];

export type GetYearMonths = (date: Date) => MonthLabel[];

export type FormatYearMonths = (months: MonthLabel[]) => [number, string][];

export type FindSelectedMonth = (
  date: Date
) => (months: MonthLabel[]) => MonthLabel | undefined;

export type GetMonthValue = (
  monthLabel: MonthLabel | undefined
) => number | undefined;

export type GetValue = (date: Date) => number | undefined;

// helper methods
const getYearMonthsInterval: GetYearMonthsInterval = (date: Date): Date[] =>
  eachMonthOfInterval({
    start: startOfYear(date),
    end: endOfYear(date),
  });

const formatDatesToMonthLabels: FormatDatesToMonthLabels = (
  dates: Date[]
): MonthLabel[] =>
  dates.map((date) => {
    return { date, monthLabel: `${format("MMMM")(date)} ${getYear(date)}` };
  });

const getYearMonths: GetYearMonths = (date) =>
  compose(formatDatesToMonthLabels, getYearMonthsInterval)(date);

const formatYearMonths: FormatYearMonths = (months) =>
  months.map(({ date, monthLabel }) => [date.getTime(), monthLabel]);

const findSelectedMonth: FindSelectedMonth = (date) => (months) =>
  months.find((month) => getMonth(month.date) === getMonth(date));

const getMonthValue: GetMonthValue = (monthLabel) => monthLabel?.date.getTime();

export const getOptions = compose(formatYearMonths, getYearMonths);

export const getValue: GetValue = (date) => {
  const yearMonths = getYearMonths(date);
  const selectedMonth = findSelectedMonth(date)(yearMonths);

  return getMonthValue(selectedMonth);
};
