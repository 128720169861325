import React, { useState } from "react";
import EmployeeAvatar from "../../../../../../../globalComponents/avatars/EmployeeAvatar";
import Cell, { OnHighlightClick } from "../Cell";
import { CalendarRow, getCellWidth } from "../styles";
import { getCellKey, isFirst, isLast } from "../utils";
import { Container, EmployeeContainer } from "./styles";
import { AbsenceEmployee } from "../../../../../../../state/employees/absences/absence";
import { AbsenceType } from "../../../../../../../state/employees/types";
import { baseUrl } from "../../../../../../../config/globalVariables";

export type EmployeeListProps = {
  date: Date;
  width: number;
  columns: number[];
  employees: AbsenceEmployee[];
  absenceTypes: AbsenceType[];
  onHighlightClick: OnHighlightClick;
};

const EmployeeList: React.FC<EmployeeListProps> = ({
  date,
  width,
  columns,
  employees,
  absenceTypes,
  onHighlightClick,
}) => {
  const [hoveredHighlightId, setHoveredHighlightId] = useState<
    number | undefined
  >();

  const getIsHighlightHovered = (highlightId?: number) =>
    Boolean(highlightId) && highlightId === hoveredHighlightId;

  return (
    <Container>
      {employees.map(
        (
          { id, firstName, lastName, position, absenceRequests, imgLink },
          index
        ) => (
          <CalendarRow
            key={id}
            daysInMonth={columns.length}
            columns={columns}
            borderBottom={!isLast(index)(employees)}
            basesize={width}
          >
            <EmployeeContainer>
              <EmployeeAvatar
                id={id}
                firstName={firstName}
                lastName={lastName}
                position={position}
                imgLink={baseUrl + "/employees/pic/" + imgLink}
              />
            </EmployeeContainer>
            {columns.map((colValue, colIndex) => (
              <Cell
                calendarDate={date}
                date={colValue}
                key={getCellKey(date)(colIndex)}
                borderLeft={isFirst(colIndex)}
                borderRight={!isLast(colIndex)(columns)}
                requests={absenceRequests}
                absenceTypes={absenceTypes}
                calendarWidth={width}
                onHighlightClick={onHighlightClick}
                onHighlightHover={setHoveredHighlightId}
                isFirstRow={isFirst(index)}
                width={getCellWidth(width)(columns.length)}
                getIsHighlightHovered={getIsHighlightHovered}
              />
            ))}
          </CalendarRow>
        )
      )}
    </Container>
  );
};

export default EmployeeList;
