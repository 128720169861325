import React, { useState } from "react";
import "date-fns";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import {
  StyledLabel,
  TextFieldWrapper,
  WarnLabel,
} from "../TextFieldController";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import {
  ClickAwayListener,
  Grid,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import { dateConverterForAbsence } from "../../../product/pages/employees/timetracking/calendar/helper/helper";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 40 * 4.5,
      width: 150,
    },
  },
  getContentAnchorEl: null,
};

export const InputContainer = styled.div`
  width: 295px;
  height: 38px;
  background: white;
  border: ${(props: { error: boolean }) =>
    props.error ? "1px solid #C80410" : "1px solid #cccccc"};
  border-radius: 4px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
  &:hover {
    cursor: pointer;
    background: #f5f8fa;
  }
`;

export const StyledInput = styled.input`
  width: 295px;
  border: none;
  padding-left: 10px;
  outline: none;
  display: flex;
  flex-direction: row;
  background-color: transparent;
  cursor: pointer;
  &:placeholder-shown {
    background: #fbfbfb;
    color: #999999;
    font-style: italic;
  }
`;

const Container = styled.div<{ marginTop: string; right: string }>`
  position: absolute;
  width: 350px;
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  margin-top: ${(props) => props.marginTop};
  margin-left: ${(props) => props.right};
  z-index: 5;
  border: 1px solid #cccccc;
  box-shadow: 0px 2px 4px #4c4c4c24;
`;

const GridDay = styled.div<{ selected?: boolean }>`
  height: 40px;
  width: 40px;
  border-radius: 8px;
  background-color: ${(props) => (props.selected ? "#D7E9F7" : "#ffffff")};
  border: ${(props) =>
    props.selected ? "1px solid #8CACC6" : "1px solid transparent"};
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #d7e9f7;
    cursor: pointer;
  }
`;

const Weekday = styled.div`
  height: 40px;
  width: 40px;
  font-family: Roboto;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 400;
  color: #999999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const months = [
  { label: "Januar", value: 1 },
  { label: "Februar", value: 2 },
  { label: "März", value: 3 },
  { label: "April", value: 4 },
  { label: "Mai", value: 5 },
  { label: "Juni", value: 6 },
  { label: "Juli", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "Oktober", value: 10 },
  { label: "November", value: 11 },
  { label: "Dezember", value: 12 },
];

interface DateFieldProps {
  control: Control<any>;
  fieldName: string;
  label: string;
  defaultValue?: Date | string | null | undefined;
  openCalendar?: "LEFT" | "RIGHT" | "TOP";
  startYear?: number;
  endYear?: number;
  validation?: { required?: boolean };
  errorMessage?: string;
}
const DateFieldRHF = (props: DateFieldProps): React.ReactElement => {
  const [calendar, setCalendar] = useState(false);
  const today = new Date();
  today.setMinutes(0);
  today.setHours(0);
  let marginTop: string, marginLeft: string;
  if (props.openCalendar == "LEFT") {
    marginTop = "-200px";
    marginLeft = "-400px";
  } else if (props.openCalendar == "RIGHT") {
    marginTop = "-200px";
    marginLeft = "400px";
  } else if (props.openCalendar == "TOP") {
    marginTop = "-450px";
    marginLeft = "-85px";
  } else {
    marginTop = "10px";
    marginLeft = "-85px";
  }

  return (
    <Controller
      name={props.fieldName}
      control={props.control}
      defaultValue={props.defaultValue || today}
      rules={
        props.validation?.required
          ? { required: "Bitte wählen Sie eine Option." }
          : {}
      }
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error },
      }) => {
        let date = new Date();
        if (value) {
          date = new Date(Date.parse(value));
        }
        const [calendarSelection, setCalendarSelection] = useState({
          year: date.getUTCFullYear(),
          month: date.getUTCMonth() + 1,
        });

        const monthSelectorJsx = (
          <Select
            value={calendarSelection.month}
            style={{ width: "150px" }}
            MenuProps={MenuProps}
          >
            {months.map((item) => {
              return (
                <MenuItem
                  key={item.label}
                  value={item.value}
                  onClick={() =>
                    setCalendarSelection({
                      ...calendarSelection,
                      month: item.value,
                    })
                  }
                >
                  {item.label}
                </MenuItem>
              );
            })}
            ;
          </Select>
        );

        const yearSelectItems = [];

        for (
          let i = props.startYear || 1900;
          i < (props.endYear || new Date().getFullYear() + 5);
          i++
        ) {
          yearSelectItems.push(
            <MenuItem
              key={i}
              value={i}
              onClick={() =>
                setCalendarSelection({
                  ...calendarSelection,
                  year: i,
                })
              }
            >
              {i}
            </MenuItem>
          );
        }

        const renderDays = () => {
          const returnJSX: JSX.Element[] = [
            <Grid xs={1} item key={"mo"}>
              <Weekday>Mo</Weekday>
            </Grid>,
            <Grid xs={1} item key={"di"}>
              <Weekday>Di</Weekday>
            </Grid>,
            <Grid xs={1} item key={"mi"}>
              <Weekday>Mi</Weekday>
            </Grid>,
            <Grid xs={1} item key={"do"}>
              <Weekday>Do</Weekday>
            </Grid>,
            <Grid xs={1} item key={"fr"}>
              <Weekday>Fr</Weekday>
            </Grid>,
            <Grid xs={1} item key={"sa"}>
              <Weekday>Sa</Weekday>
            </Grid>,
            <Grid xs={1} item key={"so"}>
              <Weekday>So</Weekday>
            </Grid>,
          ];
          const days = new Date(
            calendarSelection.year,
            calendarSelection.month,
            0
          ).getDate();
          //fill up the days until the right weekday
          let weekdayOfFirst = new Date(
            calendarSelection.year,
            calendarSelection.month - 1,
            1
          ).getUTCDay();
          switch (weekdayOfFirst) {
            case 1 | 2 | 3 | 4 | 5 | 6 | 7:
              weekdayOfFirst = weekdayOfFirst - 1;
              break;
          }
          for (let i = 0; i < weekdayOfFirst; i++) {
            returnJSX.push(<Grid xs={1} item key={i} />);
          }
          for (let i = 0; i < days; i++) {
            returnJSX.push(
              <Grid xs={1} item key={999 - i}>
                <GridDay
                  selected={
                    new Date(Date.parse(value)).getUTCDate() === i + 1 &&
                    new Date(Date.parse(value)).getUTCMonth() ===
                      calendarSelection.month &&
                    new Date(Date.parse(value)).getUTCFullYear() ===
                      calendarSelection.year
                  }
                  onClick={() => {
                    const month: string =
                      calendarSelection.month < 10
                        ? "0" + calendarSelection.month.toString()
                        : calendarSelection.month.toString();
                    const day: string =
                      i < 9 ? "0" + (i + 1).toString() : (i + 1).toString();
                    onChange(`${calendarSelection.year}-${month}-${day}`);
                    setCalendar(false);
                  }}
                >
                  {i + 1}
                </GridDay>
              </Grid>
            );
          }
          return returnJSX;
        };
        return (
          <TextFieldWrapper>
            <StyledLabel>
              <label id={`${props.fieldName}-Select`}>{props.label}</label>
            </StyledLabel>
            <InputContainer
              error={error !== undefined}
              onClick={() => setCalendar(true)}
            >
              <StyledInput
                key={name}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                checked={value}
                placeholder={props.label}
                type={"text"}
                readOnly
                value={dateConverterForAbsence(value)}
              />
              <IconButton size={"small"} onClick={() => setCalendar(true)}>
                <FontAwesomeIcon icon={faCalendarAlt} size={"xs"} />
              </IconButton>
            </InputContainer>
            {calendar ? (
              <ClickAwayListener onClickAway={() => setCalendar(false)}>
                <Container marginTop={marginTop} right={marginLeft}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {monthSelectorJsx}
                    <Select
                      value={calendarSelection.year || new Date().getFullYear()}
                      style={{ width: "150px" }}
                      MenuProps={MenuProps}
                    >
                      {yearSelectItems}
                    </Select>
                  </div>
                  <Grid
                    container
                    columns={7}
                    margin={"20px 0px 20px 0px"}
                    rowSpacing={"5px"}
                    columnSpacing={"5px"}
                  >
                    {renderDays()}
                  </Grid>
                </Container>
              </ClickAwayListener>
            ) : (
              <></>
            )}
            {error ? <WarnLabel>{props.errorMessage}</WarnLabel> : null}
          </TextFieldWrapper>
        );
      }}
    />
  );
};

export default DateFieldRHF;
