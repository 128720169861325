import React from "react";
import { DisplayCardField, DisplayCardModeProps, FormFieldType } from "./utils";
import { DataCardBody } from "./styles";
import { LabelValuePair } from "../formFields/staticSelect/SelectMenuInput";
import TableLink from "../../product/pages/organisation/departments/components/TableLink";
import { getDateText } from "../../utils/dates";
import { Grid, Typography } from "@mui/material";

const renderResponse = (
  response: React.ComponentState,
  item: DisplayCardField
) => {
  if (item.type === FormFieldType.BOOLEAN) {
    return (
      <Typography variant={"subtitle2"}>
        {response[item.fieldName] ? "Ja" : "Nein"}
      </Typography>
    );
  } else if (item.type === FormFieldType.DATE) {
    return (
      <Typography variant={"subtitle2"}>
        {response[item.fieldName]
          ? getDateText(response[item.fieldName])
          : "Nicht angegeben."}
      </Typography>
    );
  }

  if (typeof response[item.fieldName] == "object") {
    switch (item.fieldName) {
      case "managingDirectors":
        return response[item.fieldName].map((item: LabelValuePair) => {
          return (
            <>
              <TableLink
                key={item.value}
                label={item.label}
                startUrl={"app/employees/"}
                customValue={item.value as string}
                endUrl={"/basic"}
              />
              {item.value == response[response.length]?.value ? "" : ", "}{" "}
              &nbsp;
            </>
          );
        });
      case "financialAuthority":
        return (
          <Typography variant={"subtitle2"}>
            {response[item.fieldName]?.label || "Nicht angegeben"}
          </Typography>
        );
      case "motherCompany":
        return (
          <TableLink
            key={response[item.fieldName]?.value}
            label={response[item.fieldName]?.label}
            startUrl={"app/companies/"}
            customValue={response[item.fieldName]?.value as string}
            endUrl={"/basic"}
          />
        );
    }
  }

  if (response[item.fieldName] == null) {
    return <Typography variant={"subtitle2"}>Nicht angegeben.</Typography>;
  }

  return (
    <Typography variant={"subtitle2"}>{response[item.fieldName]}</Typography>
  );
};

const DisplayCardReadMode = (
  props: DisplayCardModeProps
): React.ReactElement => {
  return (
    <DataCardBody>
      <Grid container>
        {props.formFields.map((item) => {
          return (
            <>
              <Grid xs={6} key={item.label} marginBottom={"20px"}>
                <Typography variant={"body1"}>{item.label}</Typography>
              </Grid>
              <Grid xs={6}>{renderResponse(props.response, item)}</Grid>
            </>
          );
        })}
      </Grid>
    </DataCardBody>
  );
};
export default DisplayCardReadMode;
