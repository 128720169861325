import React, { useState } from "react";
import DisplayCardWriteMode from "./DisplayCardWriteMode";
import DisplayCardReadMode from "./DisplayCardReadMode";
import EditIcon from "@material-ui/icons/Edit";
import { DisplayCardProps, Mode } from "./utils";
import { IconContainer, TitleContainer, DataCardContainer } from "./styles";
import { Alert } from "@material-ui/lab";
import {
  CircularProgress,
  IconButton,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CenteredLoadingIndicator from "../loading/CenteredLoadingIndicator";

const DisplayCard = (props: DisplayCardProps): React.ReactElement => {
  const [state, setState] = useState<{
    mode: Mode;
    success: boolean;
  }>({
    mode: Mode.read,
    success: false,
  });

  const desktopVersion = useMediaQuery("(min-aspect-ratio: 8/5)");
  return (
    <DataCardContainer mobile={!desktopVersion} width={props.width}>
      <TitleContainer mobile={!desktopVersion}>
        <Typography variant={"h4"}>{props.title}</Typography>
        <IconContainer mobile={!desktopVersion}>
          {state.mode == Mode.read ? (
            <IconButton
              onClick={() => setState({ ...state, mode: Mode.Write })}
              size={!desktopVersion ? "small" : "medium"}
            >
              <EditIcon />
            </IconButton>
          ) : (
            <></>
          )}
        </IconContainer>
      </TitleContainer>
      {props.input.error ? (
        <Typography variant={"subtitle2"}>
          Die Karte konnte nicht geladen werden.
        </Typography>
      ) : props.input.loading ? (
        <CenteredLoadingIndicator />
      ) : state.mode == Mode.Write ? (
        <DisplayCardWriteMode
          response={props.input.data}
          formFields={props.formFields}
          updateEndpoint={props.updateEndpoint}
          patch={props.patch}
          additionalBodyParams={props.additionalBodyParams}
          additionalRequestParams={props.additionalRequestParams}
          id={props.id}
          callbackFunction={(r) => {
            setState({ ...state, mode: Mode.read, success: r });
            props.callbackFunc();
          }}
        />
      ) : (
        <>
          <DisplayCardReadMode
            id={props.id}
            response={props.input?.data}
            formFields={props.formFields}
          />
        </>
      )}
      <Snackbar
        open={state.success}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        autoHideDuration={3000}
        onClose={() => setState({ ...state, success: false })}
      >
        <Alert
          onClose={() => setState({ ...state, success: false })}
          severity="success"
        >
          Die Angaben wurden erfolgreich aktualisiert.
        </Alert>
      </Snackbar>
    </DataCardContainer>
  );
};

export default DisplayCard;
