import React, { useEffect } from "react";
import { AppBar, Tabs, Tab, Typography } from "@mui/material";
import CompanyDocuments from "./documents/CompanyDocuments";
import DisplayCard from "../../../../../globalComponents/dataCard/DisplayCard";
import {
  BasicDataFormFields,
  SocialDataFormFields,
  SocialDataFormFields2,
  TaxDataFormFields,
} from "./basic/DisplayCardInputs";
import SmartCrud from "../../../../../globalComponents/smartOperations/smartCrud/SmartCrud";
import { useDispatch } from "react-redux";
import { listAbsencetypesByCompanyId } from "../../../../../state/organization/companies/detail/absencetypes";
import {
  useCompanyAbsenceTypes,
  useCompanyDetailBasic,
  useCompanyDetailSocial,
  useCompanyDetailSocial2,
  useCompanyDetailTax,
} from "../../../../../state/organization/companies/selectors";
import { getBasicData } from "../../../../../state/organization/companies/detail/companyDetailBasic";
import { getTaxData } from "../../../../../state/organization/companies/detail/companyDetailTax";
import { getSocialData } from "../../../../../state/organization/companies/detail/companyDetailSocial";
import { getSocialData2 } from "../../../../../state/organization/companies/detail/companyDetailSocial2";
import { FormFieldType } from "../../../../../globalComponents/dataCard/utils";
import { GridValueGetterParams } from "@mui/x-data-grid";
import TimetrackingCompany from "./timetracking/TimetrackingCompany";
import CompanySalarytypes from "./salarytypes/CompanySalarytypes";
import {
  listSalarytypes,
  SalaryTypeVariant,
} from "../../../../../state/organization/companies/detail/salarytype";

export const AbsenceTypeCells = [
  {
    field: "description",
    headerName: "Bezeichnung",
    width: 250,
    defaultValue: "",
    editable: true,
    validation: { required: true },
    formType: FormFieldType.TEXT,
  },
  {
    field: "halfDayPossible",
    headerName: "Halbtags möglich?",
    width: 220,
    editable: true,
    formType: FormFieldType.BOOLEAN,
    renderCell: (params: GridValueGetterParams): JSX.Element =>
      params.row.halfDayPossible ? (
        <Typography>Ja</Typography>
      ) : (
        <Typography>Nein</Typography>
      ),
  },
  {
    field: "budgetRequired",
    headerName: "Budget benötigt?",
    width: 200,
    editable: true,
    formType: FormFieldType.BOOLEAN,
    renderCell: (params: GridValueGetterParams): JSX.Element =>
      params.row.budgetRequired ? (
        <Typography>Ja</Typography>
      ) : (
        <Typography>Nein</Typography>
      ),
  },
  {
    field: "documentRequired",
    headerName: "Nachweis benötigt?",
    width: 220,
    editable: true,
    formType: FormFieldType.BOOLEAN,
    renderCell: (params: GridValueGetterParams): JSX.Element =>
      params.row.documentRequired ? (
        <Typography>Ja</Typography>
      ) : (
        <Typography>Nein</Typography>
      ),
  },
];

export const SalaryTypeCells = [
  {
    field: "name",
    headerName: "Bezeichnung",
    width: 190,
    defaultValue: "",
    editable: true,
    validation: { required: true },
    formType: FormFieldType.TEXT,
  },
  {
    field: "type",
    headerName: "Art",
    width: 90,
    editable: true,
    formType: FormFieldType.SELECT,
    menuItems: [{ label: "Lohnart", value: "Lohnart" }],
  },

  {
    field: "st",
    headerName: "Versteuerung",
    width: 120,
    editable: true,
    formType: FormFieldType.SELECT,
    menuItems: [
      { label: "Laufend", value: "LAUFEND" },
      { label: "Sonstig", value: "SONSTIG" },
      { label: "Pauschal (AG)", value: "PAG" },
      { label: "Pauschal (AN)", value: "PAN" },
      { label: "Mehrjähriger Bezug", value: "M" },
      { label: "Frei", value: "F" },
    ],
    renderCell: (params: GridValueGetterParams): JSX.Element =>
      params.row.st === "LAUFEND" ? (
        <Typography>Laufend</Typography>
      ) : (
        <Typography>Sonstig</Typography>
      ),
  },
  {
    field: "sv",
    headerName: "Sozialversicherung",
    width: 120,
    editable: true,
    formType: FormFieldType.SELECT,
    menuItems: [
      { label: "Laufend", value: "LAUFEND" },
      { label: "Sonstig", value: "SONSTIG" },
    ],
    renderCell: (params: GridValueGetterParams): JSX.Element =>
      params.row.sv === "LAUFEND" ? (
        <Typography>Laufend</Typography>
      ) : (
        <Typography>Sonstig</Typography>
      ),
  },
  {
    field: "gesamtBrutto",
    headerName: "Gesamt-Brutto?",
    width: 120,
    editable: true,
    formType: FormFieldType.BOOLEAN,
    renderCell: (params: GridValueGetterParams): JSX.Element =>
      params.row.gesamtBrutto ? (
        <Typography>Ja</Typography>
      ) : (
        <Typography>Nein</Typography>
      ),
  },
  {
    field: "pfaendbarkeit",
    headerName: "Pfaendbarkeit",
    width: 120,
    editable: true,
    formType: FormFieldType.SELECT,
    menuItems: [{ label: "Voll Pfändbar", value: "VOLL_PFAENDBAR" }],
    renderCell: (params: GridValueGetterParams): JSX.Element =>
      params.row.pfaendbarkeit === "VOLL_PFAENDBAR" ? (
        <Typography>Voll</Typography>
      ) : (
        <Typography>Teilweise</Typography>
      ),
  },
  {
    field: "factor",
    headerName: "Faktor",
    width: 120,
    editable: true,
    formType: FormFieldType.BOOLEAN,
    renderCell: (params: GridValueGetterParams): JSX.Element =>
      params.row.factor ? (
        <Typography>Ja</Typography>
      ) : (
        <Typography>Nein</Typography>
      ),
  },
  {
    field: "einheit",
    headerName: "Einheit",
    width: 120,
    formType: false,
  },
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          <>{children}</>
        </>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface CompanyDetailBodyProps {
  id: number;
}

export default function CompanyDetailBody(
  props: CompanyDetailBodyProps
): React.ReactElement {
  const [value, setValue] = React.useState(0);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();
  const absenceTypes = useCompanyAbsenceTypes();
  const basicData = useCompanyDetailBasic();
  const taxData = useCompanyDetailTax();
  const socialData = useCompanyDetailSocial();
  const socialData2 = useCompanyDetailSocial2();

  useEffect(() => {
    dispatch(listAbsencetypesByCompanyId(props.id));
    dispatch(
      listSalarytypes({
        companyId: props.id,
        page: 0,
        pageSize: 25,
        sort: "name",
        type: SalaryTypeVariant.All,
      })
    );
    dispatch(getBasicData(props.id));
    dispatch(getTaxData(props.id));
    dispatch(getSocialData(props.id));
    dispatch(getSocialData2(props.id));
  }, [props.id]);

  return (
    <div>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
        >
          <Tab label="Basisdaten" {...a11yProps(0)} />
          <Tab label="Abwesenheitsarten" {...a11yProps(1)} />
          <Tab label="Zeiterfassung" {...a11yProps(2)} />
          <Tab label="Lohnarten" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <br />
        <DisplayCard
          title={"Allgemeine Angaben"}
          formFields={BasicDataFormFields}
          getEndpoint={"/companies/basic"}
          updateEndpoint={"/companies/basic"}
          additionalRequestParams={[{ label: "companyId", value: props.id }]}
          id={props.id}
          input={basicData}
          patch
          callbackFunc={() => dispatch(getBasicData(props.id))}
        />
        <DisplayCard
          title={"Steuerangaben"}
          formFields={TaxDataFormFields}
          getEndpoint={"/companies/tax"}
          updateEndpoint={"/companies/tax"}
          additionalRequestParams={[{ label: "companyId", value: props.id }]}
          id={props.id}
          patch={false}
          input={taxData}
          callbackFunc={() => dispatch(getTaxData(props.id))}
        />
        <DisplayCard
          title={"Sozialversicherung"}
          formFields={SocialDataFormFields}
          getEndpoint={"/companies/social"}
          updateEndpoint={"/companies/social"}
          additionalRequestParams={[{ label: "companyId", value: props.id }]}
          id={props.id}
          patch={false}
          input={socialData}
          callbackFunc={() => dispatch(getSocialData(props.id))}
        />
        <DisplayCard
          title={"Berufsgenossenschaft"}
          formFields={SocialDataFormFields2}
          getEndpoint={"/companies/berufsgenossenschaft"}
          updateEndpoint={"/companies/berufsgenossenschaft"}
          additionalRequestParams={[{ label: "companyId", value: props.id }]}
          id={props.id}
          patch={false}
          input={socialData2}
          callbackFunc={() => dispatch(getSocialData2(props.id))}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <br />
        <SmartCrud
          title={"Abwesenheitsarten"}
          endpoints={{
            getEndpointDetail: "/companies/absencetype?absenceTypeId=",
            postEndpoint: {
              url: "/companies/absencetype",
              requestParameter: [{ label: "companyId", value: props.id }],
              type: "POST",
            },
            putPatchEndpoint: {
              url: "/companies/absencetype",
              idAsRequestParamLabel: "absenceTypeId",
              type: "PATCH",
            },
            deleteEndpoint: "/companies/absencetype?absenceTypeId=",
          }}
          parameter={AbsenceTypeCells}
          createButtonLabel={"Abwesenheitsart hinzufügen"}
          deleteConfirmMessage={
            "Wollen Sie die Abwesenheitsart wirklich löschen?"
          }
          input={absenceTypes}
          updateInputCallback={() =>
            dispatch(listAbsencetypesByCompanyId(props.id))
          }
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TimetrackingCompany />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <br />
        <CompanySalarytypes companyId={props.id} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <br />
        <CompanyDocuments id={props.id} />
      </TabPanel>
    </div>
  );
}
