import {
  addDays,
  format,
  getDate,
  getDaysInMonth,
  getMonth,
  getYear,
  intervalToDuration,
  isWeekend,
  isWithinInterval,
  startOfDay,
  startOfMonth,
} from "date-fns/fp";
import { compose, defaultTo } from "lodash/fp";
import { getAccordionHeight, getCellHeight } from "./styles";
import { getHighlightTheme, HighlightTheme } from "../styles";
import { AbsenceEmployee } from "../../../../../../state/employees/absences/absence";
import {
  AbsenceRequest,
  AbsenceType,
} from "../../../../../../state/employees/types";
import { parse } from "date-fns";

// types
export type HighlightShape = "start" | "middle" | "end" | "single";

export type GetDay = (day: number) => (date: Date) => string;

export type GetColumns = (date: Date) => number[];

export type GetHighlight = (
  calendarDate: Date,
  days: number,
  requests: AbsenceRequest[] | undefined,
  absenceTypes: AbsenceType[]
) => {
  highlightTheme?: HighlightTheme;
  highlightShape?: HighlightShape;
  highlightLabel?: string | undefined;
  highlightDuration?: number | null;
  highlightId?: number;
};

export type GetCellKey = (date: Date) => (index: number) => string;

export type GetContentHeight = (
  employees: AbsenceEmployee[]
) => (basesize: number) => string;

export type IsFirst = (index: number) => boolean;

export type IsLast = (index: number) => (array: unknown[]) => boolean;

export type GetIsWeekend = (days: number) => (date: Date) => boolean;

// helpers
export const getArrayOfSize = (size: number): number[] =>
  Array.from(Array(size).keys());

export const getColumns: GetColumns = compose(getArrayOfSize, getDaysInMonth);

export const getDay: GetDay = (day) =>
  compose(format("iii"), addDays(day), startOfDay, startOfMonth);

const getRequestDates = (request: AbsenceRequest): Interval => {
  if (!request.startDate || !request.endDate) {
    return {
      start: startOfDay(new Date()),
      end: startOfDay(new Date()),
    };
  }
  const { startDate, endDate } = request;

  return {
    start: startOfDay(parse(startDate, "yyyy-MM-dd", startOfDay(new Date()))),
    end: startOfDay(parse(endDate, "yyyy-MM-dd", startOfDay(new Date()))),
  };
};

const getMatchingRequest = (requests: AbsenceRequest[] | undefined) => (
  date: Date
): AbsenceRequest | undefined => {
  if (!requests?.length) return;

  return requests.find((request) => {
    const { start, end } = getRequestDates(request);

    return isDateWithinInterval(date)(new Date(start), new Date(end));
  });
};

const getDurationInDays = ({ days }: Duration) => defaultTo(0)(days) + 1;

const getRequestDuration = compose(
  getDurationInDays,
  intervalToDuration,
  getRequestDates
);

export const calculateDate = (days: number): ((date: number | Date) => Date) =>
  compose(addDays(days), startOfDay, startOfMonth);

const getHighlightShape = (
  date: Date,
  request: AbsenceRequest
): HighlightShape | undefined => {
  if (!request || !request.startDate || !request.endDate) return;

  const { startDate, endDate } = request;
  const parsedStart = parse(startDate, "yyyy-MM-dd", startOfDay(new Date()));
  const parsedEnd = parse(endDate, "yyyy-MM-dd", startOfDay(new Date()));

  if (isSameDate(parsedStart, date) && isSameDate(parsedEnd, date))
    return "single";

  if (isSameDate(parsedStart, date) && !isSameDate(parsedEnd, date))
    return "start";

  if (!isSameDate(parsedStart, date) && isSameDate(parsedEnd, date))
    return "end";

  return "middle";
};

const getRequestLabel = (
  date: Date,
  request: AbsenceRequest,
  absenceTypes: AbsenceType[]
): string | undefined => {
  if (
    !isSameDate(
      parse(
        request.startDate || "0000-01-01",
        "yyyy-MM-dd",
        startOfDay(new Date())
      ),
      date
    )
  )
    return "";

  const type = absenceTypes.find((t) => t.id === request.id);
  if (type) {
    return type.description;
  }

  return "";
};

// @ts-ignore
export const getHighlight: GetHighlight = (
  calendarDate,
  days,
  requests,
  absenceTypes
) => {
  const date = calculateDate(days)(calendarDate);
  const request = getMatchingRequest(requests)(date);

  if (!request) return {};

  const highlightDuration = getRequestDuration(request);

  return {
    highlightTheme: getHighlightTheme(request.approvalStatus),
    highlightShape: getHighlightShape(date, request),
    highlightLabel: getRequestLabel(date, request, absenceTypes),
    highlightDuration: highlightDuration,
    highlightId: request.id,
  };
};

export const getCellKey: GetCellKey = (date) => (index) =>
  `${date.getTime()}_${index}`;

export const getContentHeight: GetContentHeight = (employees) => (basesize) => {
  const cellHeight = getCellHeight({ basesize });
  const accordionHeight = getAccordionHeight({ basesize });
  const height = cellHeight * employees.length;
  const contentHeight = height < accordionHeight ? height : accordionHeight;

  return `${contentHeight}px`;
};

export const isFirst: IsFirst = (index) => index === 0;

export const isLast: IsLast = (index) => (arr) => index === arr.length - 1;

export const getIsWeekend: GetIsWeekend = (days: number) =>
  compose(isWeekend, addDays(days), startOfDay, startOfMonth);

export const isDateWithinInterval = (date: Date) => (
  start: Date,
  end: Date
): boolean =>
  start && end && start <= end && isWithinInterval({ start, end })(date);

export const isSameDate = (date1: Date, date2: Date): boolean =>
  getDate(date1) === getDate(date2) &&
  getMonth(date1) === getMonth(date2) &&
  getYear(date1) === getYear(date2);

export const getIstHoliday = (): boolean => {
  const randomNum = Math.random() * 10;
  const roundedNum = Math.round(randomNum);

  return roundedNum > 9;
};
