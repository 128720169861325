import React from "react";
import { Button, Typography } from "@mui/material";
import styled from "styled-components";
import { CoreRequest } from "../../../config/backend/serviceInstances";

const ConfirmDialog = styled.div`
  background-color: #e3e6e8;
  z-index: 2;
  padding: 10px;
  min-height: 120px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 3px 0px lightgrey;
`;

const UpperDialog = styled.div`
  flex: 0.7;
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const LowerDialog = styled.div`
  display: flex;
  flex: 0.3;
  justify-content: center;
  align-items: center;
`;

interface SmartTableDeleteProps {
  id: number;
  deleteEndpoint: string;
  close: () => void;
  deleteConfirmMessage: string;
  deleteAndClose: () => void;
}

const SmartTableDelete = (props: SmartTableDeleteProps): JSX.Element => {
  const confirmDelete = () => {
    CoreRequest.delete(props.deleteEndpoint + props.id).then(() =>
      props.deleteAndClose()
    );
  };
  return (
    <ConfirmDialog>
      <UpperDialog>
        <Typography variant={"subtitle2"}>
          {props.deleteConfirmMessage}
        </Typography>
      </UpperDialog>
      <LowerDialog>
        <Button
          variant={"contained"}
          color={"primary"}
          size={"small"}
          onClick={() => confirmDelete()}
        >
          Löschen
        </Button>
        &nbsp;
        <Button
          variant={"contained"}
          color={"secondary"}
          size={"small"}
          onClick={() => props.close()}
        >
          Abbrechen
        </Button>
      </LowerDialog>
    </ConfirmDialog>
  );
};

export default SmartTableDelete;
