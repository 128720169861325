import { Typography } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";

const Container = styled.div`
  display: inline-flex;
  align-items: center;
`;

const TextFieldStyled = styled.input`
  width: 40px;
  height: 20px;
  padding: 2px;
  &:focus {
    outline: 2px solid #004c80;
  }
`;

const TextFieldNumber = (props: {
  fieldNameHours: string;
  fieldNameMin: string;
  control: any;
}): JSX.Element => {
  const checkValue = (r: number): string => {
    if (r > 59) return "59";
    if (r < 0) return "0";
    else return r.toString();
  };

  const checkHourValue = (r: number): string => {
    if (r > 23) return "23";
    if (r < 0) return "0";
    else return r.toString();
  };

  return (
    <Container>
      <Controller
        control={props.control}
        render={({ field: { onChange, value }, fieldState: {} }) => {
          return (
            <TextFieldStyled
              type={"number"}
              min={0}
              max={23}
              defaultValue={0}
              placeholder={"h"}
              value={value}
              onChange={(event) =>
                onChange(checkHourValue(Number.parseFloat(event.target.value)))
              }
            />
          );
        }}
        name={props.fieldNameHours}
      />
      <Typography variant={"subtitle2"} marginLeft={"5px"} marginRight={"5px"}>
        :
      </Typography>
      <Controller
        control={props.control}
        render={({ field: { onChange, value }, fieldState: {} }) => {
          return (
            <TextFieldStyled
              type={"number"}
              min={0}
              max={59}
              placeholder={"m"}
              value={value}
              defaultValue={0}
              onChange={(event) =>
                onChange(checkValue(Number.parseFloat(event.target.value)))
              }
              step={15}
            />
          );
        }}
        name={props.fieldNameMin}
      />
    </Container>
  );
};

export default TextFieldNumber;
