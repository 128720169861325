import React from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";
import EmployeeCardCollapsed from "../../product/pages/employees/digitalPersonalFile/components/overview/EmployeeCardCollapsed";
import EmployeeAvatarNew from "./EmployeeAvatarNew";
import LinkStyled from "../container/LinkStyled";

const EmployeeCardContainer = styled.div`
  width: 100%;
  min-width: 150px;
  max-width: 250px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;
`;

const LeftC = styled.div`
  height: 40px;
  display: inline;
`;

const RightC = styled.div`
  padding-left: 10px;
  height: 40px;
  display: inline-block;
  line-height: 2px;
`;

export interface EmployeeAvatarInput {
  id: number;
  firstName: string;
  lastName: string;
  position: string;
  imgLink: string;
}

export interface EmployeeAvatarProps {
  id: number;
  firstName: string;
  lastName: string;
  position?: string | null;
  imgLink?: string | null;
  collapsed?: boolean;
}

const EmployeeAvatar = (props: EmployeeAvatarProps): React.ReactElement => {
  const returnObject = props.collapsed ? (
    <EmployeeCardCollapsed
      imgLink={props.imgLink}
      firstName={props.firstName}
      lastName={props.lastName}
      position={props.position}
      id={props.id}
    />
  ) : (
    <EmployeeCardContainer>
      <LeftC>
        <EmployeeAvatarNew imgUrl={props.imgLink} size={"SMALL"} />
      </LeftC>
      <RightC>
        <LinkStyled to={`/app/employees/${props.id}/basic`}>
          <Typography variant={"subtitle2"} noWrap>
            {props.firstName} {props.lastName}
          </Typography>
        </LinkStyled>
        {props.position !== null ? (
          <Typography
            variant={"caption"}
            noWrap
            color={"#999999"}
            style={{ lineHeight: "10px" }}
          >
            {props.position}
          </Typography>
        ) : (
          <Typography
            variant={"caption"}
            color={"error"}
            style={{ lineHeight: "10px" }}
            noWrap
          >
            Position fehlt
          </Typography>
        )}
      </RightC>
    </EmployeeCardContainer>
  );
  return <>{returnObject}</>;
};

export default EmployeeAvatar;
