import React from "react";
import { ListItemText, MenuItem, Select } from "@mui/material";
import {
  StyledLabel,
  TextFieldWrapper,
} from "../../../../../../../globalComponents/formFields/TextFieldController";
import { MenuProps } from "../../../../../../../globalComponents/formFields/dynamic/EmployeesSelect";

type Props = {
  fieldName: `${string}`;
  onChange: (value: number) => void;
  value: number;
};

const compensationTypes = [
  {
    id: 0,
    description: "Compensation",
  },
  {
    id: 1,
    description: "Zuschlag",
  },
];

const CompensationTypeSelect = (props: Props): React.ReactElement => {
  return (
    <TextFieldWrapper>
      <StyledLabel id={`${props.fieldName}-compensationTypeSelect`}>
        <label>Typ</label>
      </StyledLabel>
      <Select
        labelId={`${props.fieldName}-compensationTypeSelect`}
        id={`${props.fieldName}-compensationTypeSelect`}
        onChange={(event) => props.onChange(Number(event.target.value))}
        value={props.value}
        MenuProps={MenuProps}
        renderValue={(value: any) => {
          const vals = compensationTypes.filter((item) => item.id == value);
          if (vals && vals.length > 0) {
            return `${vals[0].description}`;
          }
        }}
      >
        {compensationTypes.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            <ListItemText primary={item.description} />
          </MenuItem>
        ))}
      </Select>
    </TextFieldWrapper>
  );
};

export default CompensationTypeSelect;
