import qs from "querystring";
import { refreshConfig } from "../../homepage/signIn/SignIn";
import {
  AuthRequest,
  CoreRequest,
} from "../../config/backend/serviceInstances";
import { AuthEndpoints } from "../../config/backend/endpointsAuth";

export const setUpResponseInterceptor = (
  accessToken: string,
  refreshToken: string,
  existingInterceptor?: number
): number => {
  //eject existing interceptor
  if (existingInterceptor) {
    CoreRequest.interceptors.request.eject(existingInterceptor);
  }
  //initial set up
  CoreRequest.defaults.headers.common["Authorization"] =
    "Bearer " + accessToken;
  return CoreRequest.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const newAccessToken = await setAuthInterceptor(refreshToken);
        CoreRequest.defaults.headers.common["Authorization"] =
          "Bearer " + newAccessToken;
        error.config.headers["Authorization"] = "Bearer " + newAccessToken;

        return CoreRequest.request(error.config);
      }
      return Promise.reject(error);
    }
  );
};

export async function setAuthInterceptor(
  refreshToken: string
): Promise<string> {
  return await AuthRequest.post(
    AuthEndpoints.GET_TOKEN,
    qs.stringify({
      refresh_token: refreshToken,
    }),
    refreshConfig
  )
    .then((r) => {
      return r.data.access_token;
    })
    .catch(() => {
      console.log("error");
    });
}
