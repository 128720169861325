import React from "react";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import EmployeeList from "../EmployeeList";
import { CalendarRow, Cell } from "../styles";
import { Container, StyledAccordion, StyledLabel } from "./styles";
import {
  getCellKey,
  getContentHeight,
  getIsWeekend,
  isFirst,
  isLast,
} from "../utils";
import { OnHighlightClick } from "../Cell";
import { AbsenceEmployee } from "../../../../../../../state/employees/absences/absence";
import { AbsenceType } from "../../../../../../../state/employees/types";
import { Grid, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type DepartmentProps = {
  date: Date;
  name: string;
  size: number;
  absences: number[];
  employees: AbsenceEmployee[];
  absenceTypes: AbsenceType[];
  isActive: boolean;
  width: number;
  columns: number[];
  isLastDepartment?: boolean;
  onClick?: () => void;
  onHighlightClick: OnHighlightClick;
};

const Department: React.FC<DepartmentProps> = ({
  date,
  name,
  size,
  absences,
  employees,
  absenceTypes,
  isActive,
  width,
  columns,
  isLastDepartment,
  onClick,
  onHighlightClick,
}) => {
  return (
    <Container>
      <CalendarRow
        columns={columns}
        borderBottom={!isLastDepartment || isActive}
        basesize={width}
      >
        <Grid container style={{ padding: "10px", alignItems: "center" }}>
          <Grid item xs={8}>
            <Typography variant={"body1"}>{name}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant={"body1"}>
              <FontAwesomeIcon icon={faUsers} color={"rgb(194, 194, 194)"} />
            </Typography>
          </Grid>
          <Grid item xs={1} marginLeft={"5px"}>
            <Typography variant={"body1"}>{size}</Typography>
          </Grid>
          <Grid item xs={2} marginLeft={"-5px"}>
            <IconButton size={"small"} onClick={onClick}>
              {isActive ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </Grid>
        </Grid>
        {columns.map((day, index) => (
          <Cell
            key={getCellKey(date)(index)}
            borderLeft={isFirst(index)}
            borderRight={!isLast(index)(columns)}
            basesize={width}
            background={getIsWeekend(day)(date)}
            borderRadius={isLastDepartment && isLast(index)(columns)}
          >
            {!!absences[day] && (
              <StyledLabel basesize={width}>
                {absences[day].toString()}
              </StyledLabel>
            )}
          </Cell>
        ))}
      </CalendarRow>
      <StyledAccordion
        animationName={name}
        value={isActive}
        customContent={
          <EmployeeList
            employees={employees}
            absenceTypes={absenceTypes}
            width={width}
            columns={columns}
            date={date}
            onHighlightClick={onHighlightClick}
          />
        }
        contentHeight={getContentHeight(employees)(width)}
        scroll
        borderBottom={isActive && !isLastDepartment}
      />
    </Container>
  );
};

export default Department;
