import React from "react";
import styled from "styled-components";
import MenuDesktop from "./MenuDesktop";
import CompanySwitch from "../companySwitch/CompanySwitch";

const NavigationContainer = styled.div`
  background-color: #f5f8fa;
  min-width: 292px;

  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const NavInner = styled.div``;

const HeavenLogo = styled.img`
  width: 123px;
  height: 24px;
  margin: 0 auto 20px auto;
`;

const VerticalSpacer = styled.div`
  flex-grow: 1;
`;

const Navigation = (): React.ReactElement => {
  return (
    <NavigationContainer>
      <CompanySwitch />
      <NavInner>
        <MenuDesktop />
      </NavInner>
      <VerticalSpacer />
      <HeavenLogo
        src={"https://www.firstbird.com/content/uploads/2021/05/logo.png"}
        alt={"heavenLogo"}
      />
    </NavigationContainer>
  );
};

export default Navigation;
