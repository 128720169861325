import React from "react";
import styled from "styled-components";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";
import LocationCard from "./LocationCardSvg";
import geoUrl from "./geo";
import { Location } from "../../../../state/organization/locations/locations";

const LocationWrapper = styled.div``;
const GeoWrapper = styled(Geography)`
  fill: #054d80;
`;

type Props = {
  data: Location[];
};

const LocationWorldMap = (props: Props): React.ReactElement => {
  return (
    <LocationWrapper>
      <ComposableMap projection={"geoStereographic"}>
        <ZoomableGroup
          zoom={16}
          maxZoom={80}
          minZoom={16}
          center={[10.5, 48.5]}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <GeoWrapper
                  key={geo.rsmKey}
                  geography={geo}
                  style={{
                    default: { outline: "none" },
                    hover: { outline: "none" },
                    pressed: { outline: "none" },
                  }}
                />
              ))
            }
          </Geographies>
          {props.data.map((item) => {
            if (!item.latitude || !item.longitude) {
              return null;
            }
            return (
              <Marker
                coordinates={[item.longitude, item.latitude]}
                key={item.longitude + item.latitude}
              >
                <LocationCard
                  city={item.city}
                  locationName={item.locationName}
                  id={item.id}
                />
              </Marker>
            );
          })}
        </ZoomableGroup>
      </ComposableMap>
    </LocationWrapper>
  );
};

export default LocationWorldMap;
