import styled from "styled-components";

const OrgChartElementBody = styled.div<{ hide: boolean }>`
  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: row;
  flex: 0.8;
  padding: 0.5vw;
`;

export default OrgChartElementBody;
