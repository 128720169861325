import React, { useEffect } from "react";
import { Typography } from "@mui/material";

import ViewContainer from "../../../../globalComponents/container/ViewContainer";
import { Link } from "react-router-dom";
import Icon from "../../../../globalComponents/container/Icon";
import { faColumns, faGlobeEurope } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import LocationWorldMap from "./LocationWorldMap";
import { useLocations } from "../../../../state/organization/locations/selectors";
import { listLocations } from "../../../../state/organization/locations/locations";
import SmartCrud, {
  RenderOnlyOn,
} from "../../../../globalComponents/smartOperations/smartCrud/SmartCrud";
import { FormFieldType } from "../../../../globalComponents/dataCard/utils";
import { PageTitle } from "../../../../globalComponents/PageHeader/PageTitle";
import { Page } from "../../../../state/organization/companies/detail/salarytype";

type Props = {
  view: "map" | "table";
};

const Locations = (props: Props): React.ReactElement => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listLocations());
  }, []);

  const locations = useLocations();

  return (
    <>
      <PageTitle>
        <Typography variant="h1">Standorte</Typography>
        <ViewContainer>
          <Typography variant={"subtitle2"}>View: </Typography>
          <br />
          <Link to={"/app/locations"}>
            <Icon icon={faGlobeEurope} />
          </Link>
          <br />
          <Link to={"/app/locations/table"}>
            <Icon icon={faColumns} />
          </Link>
        </ViewContainer>
      </PageTitle>

      {props.view === "map" ? (
        <LocationWorldMap data={locations.data} />
      ) : (
        <SmartCrud
          input={locations}
          createButtonLabel={"Standort hinzufügen"}
          deleteConfirmMessage={"Wollen Sie den Standort wirklich löschen?"}
          endpoints={{
            putPatchEndpoint: {
              url: "/companies/locations",
              idAsPathParameter: true,
              type: "PUT",
            },
            getEndpointDetail: "/companies/locations/",
            postEndpoint: { url: "/companies/locations", type: "POST" },
            deleteEndpoint: "/companies/locations?locationId=",
          }}
          title={"Angelegte Standorte"}
          updateInputCallback={() => dispatch(listLocations())}
          parameter={[
            {
              field: "locationName",
              headerName: "Bezeichnung",
              width: 250,
              defaultValue: "",
              editable: true,
              validation: { required: true },
              formType: FormFieldType.TEXT,
            },
            {
              field: "street",
              headerName: "Strasse",
              width: 250,
              defaultValue: "",
              editable: true,
              validation: { required: true },
              formType: FormFieldType.TEXT,
            },
            {
              field: "postalCode",
              headerName: "Postleitzahl",
              width: 200,
              defaultValue: "",
              editable: true,
              validation: { required: true },
              formType: FormFieldType.TEXT,
            },
            {
              field: "city",
              headerName: "Stadt",
              width: 200,
              defaultValue: "",
              editable: true,
              validation: { required: true },
              formType: FormFieldType.TEXT,
            },
            {
              field: "companyId",
              headerName: "",
              width: 0,
              defaultValue: "",
              editable: true,
              validation: { required: true },
              formType: FormFieldType.SELECT_MOTHER_COMPANY,
              renderOnlyOn: RenderOnlyOn.ADD,
              asRequestParameter: true,
              renderCell: () => <></>,
            },
          ]}
        />
      )}
    </>
  );
};

export default Locations;
