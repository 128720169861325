import styled from "styled-components";

export const SlideInWrapper = styled.div`
  position: fixed;
  width: 384px;
  bottom: 0px;
  top: 0px;
  background-color: #ffffff;
  box-shadow: 0px 16px 48px #4c4c4c24;
  right: 0;
  z-index: 7;
`;

export const SlideInHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 40px 40px 0px 40px;
`;

export const SlideInBody = styled.div`
  height: 80%;
  overflow: scroll;
  padding: 40px;
  &: {
    ms-overflow-style: none;
    scrollbar-width: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;
