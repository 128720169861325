const berufsgenossenschaft = [
  {
    label: "Rohstoffe und chemische Industrie",
    value: "Rohstoffe und chemische Industrie",
  },
  { label: "Holz und Metall", value: "Holz und Metall" },
  {
    label: "Energie Textil Elektro Medienerzeugnisse",
    value: "Energie Textil Elektro Medienerzeugnisse",
  },
  {
    label: "Nahrungsmittel und Gastgewerbe",
    value: "Nahrungsmittel und Gastgewerbe",
  },
  { label: "Bauwirtschaft - BG BAU", value: "Bauwirtschaft - BG BAU" },
  { label: "Handel und Warenlogistik", value: "Handel und Warenlogistik" },
  {
    label: "Verwaltungs-Berufsgenossenschaft (VBG)",
    value: "Verwaltungs-Berufsgenossenschaft (VBG)",
  },
  {
    label: "Verkehrswirtschaft Post-Logistik Telekommunikation (BG Verkehr)",
    value: "Verkehrswirtschaft Post-Logistik Telekommunikation (BG Verkehr)",
  },
  {
    label:
      "Berufsgenossenschaft für Gesundheitsdienst und Wohlfahrtspflege (BGW)",
    value:
      "Berufsgenossenschaft für Gesundheitsdienst und Wohlfahrtspflege (BGW)",
  },
];

export default berufsgenossenschaft;
