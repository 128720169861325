import { Typography } from "@mui/material";
import TooltipNew from "../../../../../../globalComponents/tooltipNew/TooltipNew";
import TextButton from "../../../../../../globalComponents/buttons/TextButton";
import React from "react";
import styled from "styled-components";
import { GrContainer, GridItemTTC } from "./TimetrackingDetail";

const Dot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 10px;
  border-width: 1px solid lightgrey;
  background-color: #00a76d;
`;

const TimetrackingDetailBasicSettings = (): JSX.Element => {
  return (
    <>
      <Typography variant={"subtitle2"}>Grundeinstellungen </Typography>
      <GrContainer size={2}>
        <GridItemTTC>
          <Typography variant={"body1"} marginRight={"5px"}>
            Arbeitszeiterfassung via WebApp
          </Typography>
          <TooltipNew
            message={
              "Mitarbeiter*innen können Arbeitszeiten auf der Website via Start/ Stop Funktion selbst erfassen."
            }
          />
        </GridItemTTC>
        <GridItemTTC>
          <Dot />
          <Typography variant={"subtitle2"} marginLeft={1} marginRight={2}>
            Aktiviert{" "}
          </Typography>
          <TextButton
            onClick={() => console.log("call")}
            status={"ONE"}
            status1={
              <Typography variant={"subtitle2"} color={"#004C80"}>
                Deaktivieren
              </Typography>
            }
            status2={
              <Typography variant={"subtitle2"} color={"#004C80"}>
                Aktivieren
              </Typography>
            }
          />
        </GridItemTTC>
        <GridItemTTC>
          <Typography variant={"body1"} marginRight={"5px"}>
            Arbeitszeiterfassung via Terminals
          </Typography>
          <TooltipNew
            message={
              "Mitarbeiter*innen können Arbeitszeiten über das Terminal erfassen."
            }
          />
        </GridItemTTC>
        <GridItemTTC>
          {" "}
          <Dot />
          <Typography variant={"subtitle2"} marginLeft={1} marginRight={2}>
            Aktiviert{" "}
          </Typography>
          <TextButton
            onClick={() => console.log("call")}
            status={"ONE"}
            status1={
              <Typography variant={"subtitle2"} color={"#004C80"}>
                Deaktivieren
              </Typography>
            }
            status2={
              <Typography variant={"subtitle2"} color={"#004C80"}>
                Aktivieren
              </Typography>
            }
          />
        </GridItemTTC>
        <GridItemTTC>
          <Typography variant={"body1"} marginRight={"5px"}>
            Antragsrechte
          </Typography>
          <TooltipNew
            message={
              "Mitarbeiter*innen können eigenständig Anträge erstellen, löschen bzw. Änderungen vornehmen. Ist diese Funktion deaktiviert werden " +
              "erfasste Arbeitszeiten automatisch zur Freigabe versandt. "
            }
          />
        </GridItemTTC>
        <GridItemTTC>
          {" "}
          <Dot />
          <Typography variant={"subtitle2"} marginLeft={1} marginRight={2}>
            Aktiviert{" "}
          </Typography>
          <TextButton
            onClick={() => console.log("call")}
            status={"ONE"}
            status1={
              <Typography variant={"subtitle2"} color={"#004C80"}>
                Deaktivieren
              </Typography>
            }
            status2={
              <Typography variant={"subtitle2"} color={"#004C80"}>
                Aktivieren
              </Typography>
            }
          />
        </GridItemTTC>
        <GridItemTTC>
          <Typography variant={"body1"} marginRight={"5px"}>
            Automatische Genehmigung
          </Typography>
          <TooltipNew
            message={
              "Die erfassten Arbeitszeiten werden automatisch genehmigt."
            }
          />
        </GridItemTTC>
        <GridItemTTC>
          {" "}
          <Dot />
          <Typography variant={"subtitle2"} marginLeft={1} marginRight={2}>
            Aktiviert{" "}
          </Typography>
          <TextButton
            onClick={() => console.log("call")}
            status={"ONE"}
            status1={
              <Typography variant={"subtitle2"} color={"#004C80"}>
                Deaktivieren
              </Typography>
            }
            status2={
              <Typography variant={"subtitle2"} color={"#004C80"}>
                Aktivieren
              </Typography>
            }
          />
        </GridItemTTC>
      </GrContainer>
    </>
  );
};

export default TimetrackingDetailBasicSettings;
