import React, { useEffect } from "react";
import styled from "styled-components";
import Paycheck from "../../paycheck/Paycheck";
import { useDispatch } from "react-redux";
import { useEmployments } from "../../../../../state/payroll/selectors";
import { useCompanyBasicPayroll } from "../../../../../state/organization/companies/selectors";
import { CreatePeriod2, getPayslips } from "../../../../../state/payroll/trial";

const PaycheckContainer = styled.div`
  height: 100%;
  background-color: #f8f9fa;
  height: 500px;
  overflow: scroll;
  border-top: 1px solid lightgrey;
  margin-top: 5px;
  padding: 40px 40px 40px 40px;
`;

const Trial = (): React.ReactElement => {
  const dispatch = useDispatch();

  const employments = useEmployments();
  const companyBasic = useCompanyBasicPayroll();

  useEffect(() => {
    const createPeriod: CreatePeriod2 = {
      company: companyBasic,
      employments: employments,
      year: 2020,
      month: 1,
    };
    dispatch(getPayslips({ CreatePeriod2: createPeriod }));
  }, []);
  return (
    <PaycheckContainer>
      <Paycheck />
    </PaycheckContainer>
  );
};

export default Trial;
