export const dateConverter = (date: Date, time: boolean): string => {
  let day: string;
  switch (date.getDay()) {
    case 1:
      day = "Mon";
      break;
    case 2:
      day = "Tue";
      break;
    case 3:
      day = "Wed";
      break;
    case 4:
      day = "Thu";
      break;
    case 5:
      day = "Fri";
      break;
    case 6:
      day = "Sat";
      break;
    default:
      day = "Sun";
  }
  let timeVar = "";
  if (time) {
    timeVar = ` ${date.getHours()}:00 Uhr`;
  }

  return (
    `${day} ${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}` +
    timeVar
  );
};

export const dateConverterForAbsence = (date: string): string => {
  const inputDate = new Date(date);
  return `${inputDate.getDate()}.${
    inputDate.getMonth() + 1
  }.${inputDate.getFullYear()}`;
};
