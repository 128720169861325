import { Dispatch, RefObject, SetStateAction } from "react";
import { SectorData, Status } from ".";
import { compose } from "redux";

// types
export type Point = { x: number; y: number };

// constants
export const colors = {
  sectorColor: "#ECF1F7",
  sectorActiveColor: "#004C80",
  sectorHoverColor: "#DCE1E7",
  sectorStatusOk: "#00A76D",
  sectorStatusWarning: "#F8B44C",
  sectorStatusAlert: "#C80400",
  sectorIconColor: "#004C80",
  sectorIconActiveColor: "#fff",
};

export const sizes = {
  borderWidth: 3.8,
  iconRayWidth: 42,
  iconFontWidth: 17.8,
  iconFontHeight: 77.5,
  smilelyWidth: 57.5,
};

export type SetState = (
  ref: RefObject<HTMLDivElement>
) => (
  sectores: SectorData[]
) => (
  currentValue: string[]
) => (
  setNewValue: Dispatch<SetStateAction<string[]>>
) => (event: React.MouseEvent<HTMLDivElement>) => void;

// utils
export const getSegementValue = (count: number): number => 100 / count;

export const getSegementOffset = (count: number) => (index: number): number =>
  getSegementValue(count) * index;

export const getSegementColor = (name: string) => (active: string[]) => (
  hover: string[]
): string => {
  if (active.includes(name)) return colors.sectorActiveColor;

  if (hover.includes(name)) return colors.sectorHoverColor;

  return colors.sectorColor;
};

export const getBorderColor = (status: Status): string => {
  if (status === "red") return colors.sectorStatusAlert;
  if (status === "yellow") return colors.sectorStatusWarning;

  return colors.sectorStatusOk;
};

export const getIconColor = (name: string) => (active: string[]) => (
  hover: string[]
): string => {
  if (active.includes(name)) return colors.sectorIconActiveColor;

  if (hover.includes(name)) return colors.sectorIconColor;

  return colors.sectorIconColor;
};

export const getMousePoint = (ref: RefObject<HTMLDivElement>) => (
  e: React.MouseEvent<HTMLDivElement>
): Point => {
  const rectX = ref?.current?.getBoundingClientRect().x ?? 0;
  const rectY = ref?.current?.getBoundingClientRect().y ?? 0;
  const x = e.clientX - rectX;
  const y = e.clientY - rectY;

  return { x, y };
};

export const getAngleDegrees = (ref: RefObject<HTMLDivElement>) => ({
  x,
  y,
}: Point): number => {
  const width = ref?.current?.getBoundingClientRect().width ?? 0;
  const radius = width / 2;
  const offset = 450;

  return (Math.atan2(y - radius, x - radius) * 180) / Math.PI + offset;
};

export const getAnglePrecentage = (degrees: number): number =>
  ((degrees % 360) / 360) * 100;

export const getSectorIndex = (sectorsSize: number) => (
  precentage: number
): number => {
  if (sectorsSize === 0) return 0;

  return Math.trunc(precentage / (100 / sectorsSize));
};

export const getSectoreName = (sectores: SectorData[]) => (
  index: number
): string => {
  const sector = sectores[index];

  return sector?.name ?? "";
};

export const toggleNewValue = (currentValue: string[]) => (
  newValue: string
): string[] => {
  if (currentValue.includes(newValue)) {
    return currentValue.filter((value) => value !== newValue);
  }

  return [...currentValue, newValue];
};

export const getSmileyDimentions = (): string => `
 height: ${sizes.smilelyWidth}%;
 width: ${sizes.smilelyWidth}%;
`;

export const setState: SetState = (ref) => (sectores) => (currentValue) => (
  setNewValue
) =>
  compose(
    setNewValue,
    toggleNewValue(currentValue),
    getSectoreName(sectores),
    getSectorIndex(sectores.length),
    getAnglePrecentage,
    getAngleDegrees(ref),
    getMousePoint(ref)
  );
