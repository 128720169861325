import { compose, round } from "lodash/fp";
import styled, {
  css,
  FlattenInterpolation,
  ThemedStyledProps,
} from "styled-components";
import { StyledParagraph } from "../../Typogrophy";
import { calculateSize, colors } from "../../../../../../utils";

// types
export type StyleProps = {
  borderLeft?: boolean;
  borderRight?: boolean;
  borderBottom?: boolean;
  borderTop?: boolean;
};

type Styles = StyleProps & {
  basesize?: number;
  daysInMonth?: number;
  columns?: number[];
  background?: boolean;
  borderRadius?: boolean;
};

type Css<S extends Styles> = FlattenInterpolation<
  ThemedStyledProps<S, unknown>
>;

export type GetBorderBottomStyle = (styles: Styles) => string;

// constants
export const sizes = {
  cellHeaderHeightRatio: 0.046,
  cellHeightRatio: 0.042,
  searchColWidthRatio: 0.22,
  fontSizeRatio: 0.013,
  smallFontSizeRatio: 0.009,
  iconFontSizeRatio: 0.016,
  avatarSizeRatio: 0.025,
  accordionHeightRatio: 0.189,
  monthPickerRatio: 0.26,
};

// helper methods
const getBorder = (border: boolean) =>
  border ? `1px solid ${colors.gray14}` : "none";

export const getBorderLeftStyle = ({
  borderLeft = true,
}: StyleProps): string => `
  border-left:  ${getBorder(borderLeft)};
`;

export const getBorderRightStyle = ({
  borderRight = true,
}: StyleProps): string => `
  border-right: ${getBorder(borderRight)};
`;

export const getBorderBottomStyle: GetBorderBottomStyle = ({
  borderBottom = true,
}: StyleProps) => `border-bottom: ${getBorder(borderBottom)};`;

export const getBorderTopStyle = ({ borderTop = true }: StyleProps): string =>
  `border-top: ${getBorder(borderTop)};`;

const getTemplateColumns = ({ columns, basesize }: Styles) =>
  columns?.reduce(
    (templateColumns) => `${templateColumns} 1fr `,
    `${getSearchColWidth({ basesize })}px`
  );

const buildGridTemplateColumns = (templateColumns: string) =>
  `grid-template-columns: ${templateColumns};`;

const getGridTemplateColumns = compose(
  buildGridTemplateColumns,
  getTemplateColumns
);

export const getBaseCellStyle = <S extends Styles>(): Css<S> => css`
  height: ${getCellHeight}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${getBorderRightStyle}
  ${getBorderLeftStyle}
`;

export const getCellBackground = ({ background }: Styles): string => {
  if (!background) return "";

  return `background: ${colors.gray8};`;
};

const getBorderRadius = ({ borderRadius = false }: Styles) =>
  borderRadius ? "border-radius: 12px;" : "";

export const getCellHeight = calculateSize<Styles>(sizes.cellHeightRatio);

export const getCellHeaderHeight = <S extends Styles>(): ((
  styles: S
) => number) => calculateSize<S>(sizes.cellHeaderHeightRatio);

export const getMonthPickerWidth = calculateSize(sizes.monthPickerRatio);

export const getSearchColWidth = calculateSize(sizes.searchColWidthRatio);

export const getFontSize = calculateSize(sizes.fontSizeRatio);

export const getSmallFontSize = calculateSize(sizes.smallFontSizeRatio);

export const getIconFontSize = calculateSize(sizes.iconFontSizeRatio);

export const getAccordionHeight = calculateSize(sizes.accordionHeightRatio);

export const getCellWidth = (calendarWidth: number) => (
  columns: number
): number => {
  const searchColWidth = getSearchColWidth({ basesize: calendarWidth });

  return round((calendarWidth - searchColWidth) / columns);
};

//styles
export const Container = styled.div`
  ${getBorderRightStyle}
  ${getBorderLeftStyle}
  ${getBorderTopStyle}
  ${getBorderBottomStyle}
`;

export const LoadingContainer = styled(Container)`
  height: 150px;
`;

export const CalendarRow = styled.div`
  display: grid;
  ${getGridTemplateColumns}
  ${getBorderBottomStyle}
`;

export const Cell = styled.div`
  ${getCellBackground};
  ${getBaseCellStyle()}
  ${getBorderRadius}
  padding: 10px 5px;
`;

export const Text = styled(StyledParagraph)`
  cursor: default;
  font-size: ${getFontSize}px;
`;

export const NoData = styled.div`
  padding: 1em;
  color: ${colors.gray14};
  text-align: center;
`;
