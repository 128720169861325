import { EmployeeOverview } from "../employees/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CoreRequest } from "../../config/backend/serviceInstances";
import { DataState } from "../organization/companies/detail/companyDetail";

export interface DPFState extends DataState {
  data: ToDoDPF[];
}

export interface ToDoDPF {
  id: number;
  employee: EmployeeOverview;
  message: { text: string; linkUrl: string };
}

export const DPFStateI = {
  loading: false,
  error: false,
  errorMessage: "",
  data: [],
};

export const listDPFToDos = createAsyncThunk<
  ToDoDPF[],
  { companyId: number; startDate: string; endDate: string }
>(
  "/payroll/prepare",
  async (parameter: {
    companyId: number;
    startDate: string;
    endDate: string;
  }) => {
    const response = await CoreRequest.post(
      `/employees/${parameter.companyId}/prepare/`,
      { startDate: parameter.startDate, endDate: parameter.endDate }
    );

    if (response.status > 299) {
      // Return the known error for future handling
      return response.data.error;
    }

    return response.data as ToDoDPF[];
  }
);
