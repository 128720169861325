import styled from "styled-components";
import React, { useState } from "react";

interface HeadContainerProps {
  margin: number;
}

const HeadContainer = styled.div<HeadContainerProps>`
  margin: 20px;
  margin-left: ${(props) => props.margin}px;
  margin-top: ${(props) => props.margin}px;
  transition: 2s ease-out;
`;

const Pupil = styled.div`
  width: 12px;
  height: 12px;
  background-color: #054d80;
  border-radius: 20px;
  margin-left: 15px;
  transition-duration: 1s;
`;

const EmptyCircle = styled.div`
  position: relative;
  width: 160px;
  height: 140px;
  border-radius: 80px;
  background-color: #f5da8f;
  border: 1px solid lightgrey;
  box-shadow: 4px 4px 20px 4px lightgrey;
  transition-duration: 1s;
  &:hover ${Pupil} {
    margin: auto;
  }
  &:hover {
    background-color: #efb393;
  }
`;

interface EyeProps {
  left: boolean;
}

const Eye = styled.div<EyeProps>`
  position: absolute;
  width: 30px;
  height: 35px;
  border-radius: 15px;
  background-color: white;
  left: ${(props) => (props.left ? "30px" : "100px")};
  top: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

interface MouthProps {
  mouth: "smile" | "sad" | "normal";
}

const Mouth = styled.div<MouthProps>`
  position: absolute;
  width: 60px;
  background-color: darkred;
  bottom: 25px;
  left: 50px;
  border-radius: 0px 0px 120px 120px / 0px 0px 90px 90px;
  height: 20px;
`;

const Puppet = (): React.ReactElement => {
  const [margin, setMargin] = useState(20);

  return (
    <HeadContainer
      margin={margin}
      onClick={() => {
        setMargin(Math.random() * 500);
      }}
    >
      <EmptyCircle>
        <Eye left>
          <Pupil />
        </Eye>
        <Eye left={false}>
          <Pupil />
        </Eye>
        <Mouth mouth={"smile"} />
      </EmptyCircle>
    </HeadContainer>
  );
};
export default Puppet;
