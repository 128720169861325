import React, { useState } from "react";
import { ClickAwayListener, Typography } from "@mui/material";
import IconButtonNew from "./IconButtonNew";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

export const ExpandContainer = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: flex-end;
`;

const FlapContainer = styled.div`
  position: absolute;
  background-color: white;
  z-index: 3;
  right: 2px;
  top: 100%;
  margin-top: 5px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  overflow: hidden;
  padding: 10px 0px 10px 0px;
`;

const MenuItemContainer = styled.div`
  padding: 10px 40px 10px 40px;
  text-align: left;
  &:hover {
    background-color: #f6f8fa;
    cursor: pointer;
  }
`;

const ExpandOptionsButton = (props: {
  alternativeIcon?: IconDefinition;
  items: { label: string; onClick: () => void }[];
}): JSX.Element => {
  const [state, setState] = useState(false);

  return (
    <ExpandContainer>
      <IconButtonNew
        icon={props.alternativeIcon ? props.alternativeIcon : faEllipsisV}
        onClick={() => setState(true)}
        focused={state}
      />
      {state ? (
        <ClickAwayListener onClickAway={() => setState(false)}>
          <FlapContainer>
            {props.items.map((item) => {
              return (
                <MenuItemContainer onClick={item.onClick} key={uuidv4()}>
                  <Typography
                    variant={"body1"}
                    color={"#131313"}
                    width={item.label.length * 8}
                  >
                    {item.label}
                  </Typography>
                </MenuItemContainer>
              );
            })}
          </FlapContainer>
        </ClickAwayListener>
      ) : (
        <></>
      )}
    </ExpandContainer>
  );
};

export default ExpandOptionsButton;
