import React, { useRef, useState } from "react";
import { faCloudUpload } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { CoreRequest } from "../../config/backend/serviceInstances";

const StyledLabel = styled.label`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4c4c4c;
  background-color: white;
  border-radius: 4px;
  font-size: 16px;
  border: none;
  &:hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
`;

interface QuickUploadButtonProps {
  id: number;
  endpoint: string;
  successCallBack: () => void;
  errorCallBack?: () => void;
  params: any;
}

const QuickUploadButton = (props: QuickUploadButtonProps): JSX.Element => {
  const [call, setCall] = useState<{
    loading: boolean;
    error: boolean;
  }>({
    loading: false,
    error: false,
  });

  const ref: any = useRef();

  const upload = (file: File) => {
    const formData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      params: props.params,
    };
    if (file !== null) {
      formData.append("file", file);
      CoreRequest.post(props.endpoint, formData, config)
        .then(() => {
          setCall({ loading: false, error: false });
          props.successCallBack();
        })
        .catch(() => setCall({ error: true, loading: false }));
    }
  };

  return (
    <div>
      <StyledLabel htmlFor={props.id.toString()}>
        <FontAwesomeIcon icon={faCloudUpload} />
      </StyledLabel>
      <form ref={ref}>
        <input
          id={props.id.toString()}
          type="file"
          style={{
            display: "none",
          }}
          onChange={(e) => upload(e.target.files![0])}
        />
      </form>
    </div>
  );
};

export default QuickUploadButton;
