import React, { useEffect, useState } from "react";
import { WorkContractCompensationsState } from "../../../../../../state/employees/employeeDetail/employeeDetail";
import { Tab, Tabs } from "@mui/material";
import { a11yProps, TabPanel } from "../EmployeeDetailBody";
import CompensationCreateSlideIn from "./slidein/CompensationCreateSlideIn";
import SmartTable from "../../../../../../globalComponents/smartOperations/smartTable/SmartTable";
import { compensationColumns, zuschlagColumns } from "./tables";

interface Props {
  employeeId: number;
  workContractId: number;
  refreshCompensation: () => void;
  workContractCompensation: WorkContractCompensationsState;
}

enum TabNames {
  COMPENSATION = "Compensation",
  ZUSCHLAGE = "Zuschläge",
}

enum SlideIn {
  NONE,
  CREATE,
  UPDATE,
  DELETE,
}

const CompensationDetailView = (props: Props): JSX.Element => {
  const [currentTab, setCurrentTab] = React.useState(0);
  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setCurrentTab(newValue);
  };

  const [slideIn, setSlideIn] = useState(SlideIn.NONE);

  const [visibleTabs, setVisibleTabs] = useState<string[]>([]);
  useEffect(() => {
    const visibleTabs = [];
    if (props.workContractCompensation.data) {
      if (props.workContractCompensation.data.compensations.length > 0) {
        visibleTabs.push(TabNames.COMPENSATION.valueOf());
      }
      if (props.workContractCompensation.data.zuschlage.length > 0) {
        visibleTabs.push(TabNames.ZUSCHLAGE.valueOf());
      }
    }
    setVisibleTabs(visibleTabs);
  }, [props.workContractCompensation]);

  const slideInViews = () => {
    if (slideIn === SlideIn.NONE) {
      return null;
    } else if (slideIn == SlideIn.CREATE) {
      return (
        <CompensationCreateSlideIn
          employeeId={props.employeeId}
          workContractId={props.workContractId}
          confirmAndClose={() => {
            setSlideIn(SlideIn.NONE);
            props.refreshCompensation();
          }}
          close={() => setSlideIn(SlideIn.NONE)}
        />
      );
    }
  };

  if (visibleTabs.length === 0) {
    return (
      <>
        <br />
        <SmartTable
          title="Vergütungen"
          columns={compensationColumns}
          input={{
            loading: props.workContractCompensation.loading,
            data: [],
            error: props.workContractCompensation.error,
          }}
          deleteEndpoint={``}
          deleteConfirmMessage={"Compensation entfernen?"}
          updateInputCallback={props.refreshCompensation}
          createButton={{
            label: "Angelegte Vergütungen",
            callbackFunc: () => {
              setSlideIn(SlideIn.CREATE);
            },
          }}
        />
        {slideInViews()}
      </>
    );
  }

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        aria-label="compensation tabs"
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
      >
        {visibleTabs.map((tab, index) => {
          return <Tab key={tab} label={tab} {...a11yProps(index)} />;
        })}
      </Tabs>
      {visibleTabs.map((tab, index) => {
        return (
          <TabPanel value={currentTab} index={index} key={tab}>
            <br />
            {tab === TabNames.COMPENSATION && (
              <SmartTable
                title={tab.valueOf()}
                columns={compensationColumns}
                input={{
                  loading: props.workContractCompensation.loading,
                  data: props.workContractCompensation.data.compensations,
                  error: props.workContractCompensation.error,
                }}
                deleteEndpoint={`/employees/contract/${props.workContractId}/compensation/`}
                deleteConfirmMessage={"Compensation entfernen?"}
                updateInputCallback={props.refreshCompensation}
                createButton={{
                  label: "Angelegte Vergütungen",
                  callbackFunc: () => {
                    setSlideIn(SlideIn.CREATE);
                  },
                }}
              />
            )}
            {tab === TabNames.ZUSCHLAGE && (
              <SmartTable
                title={tab.valueOf()}
                columns={zuschlagColumns}
                input={{
                  loading: props.workContractCompensation.loading,
                  data: props.workContractCompensation.data.zuschlage,
                  error: props.workContractCompensation.error,
                }}
                deleteEndpoint={`/employees/contract/${props.workContractId}/zuschlag/`}
                deleteConfirmMessage={"Zuschlage entfernen?"}
                updateInputCallback={props.refreshCompensation}
                createButton={{
                  label: "Angelegte Vergütungen",
                  callbackFunc: () => {
                    setSlideIn(SlideIn.CREATE);
                  },
                }}
              />
            )}
          </TabPanel>
        );
      })}
      {slideInViews()}
    </>
  );
};

export default CompensationDetailView;
