import styled from "styled-components";
import React, { useState } from "react";
import NewCustomer from "./NewCustomer";
import AlreadyCustomer from "./AlreadyCustomer";
import { useGeneral2State } from "../../state/general/selectors";
import { enterDemoAccount, logOut } from "../../state/general/auth";
import { useDispatch } from "react-redux";
import { Button, Typography } from "@mui/material";

interface PopUpWrapperProps {
  visible: boolean;
}

const PopUpBackground = styled.div<PopUpWrapperProps>`
  width: 100%;
  height: 100%;
  z-index: 28;
  background-color: black;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: ${(props) => (props.visible ? "flex" : "none")};
  justify-content: center;
`;

const PopUp = styled.div`
  position: relative;
  width: 25%;
  background-color: #f5f8fa;
  margin: auto;
  border: 1px solid lightgrey;
  border-radius: 10px;
  min-width: 300px;
  max-width: 400px;
  color: #054d80;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const AuthorizationPopUp = (): React.ReactElement => {
  const generalState = useGeneral2State();
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  let returnJsx = <></>;
  switch (page) {
    case 1:
      returnJsx = (
        <>
          <Typography variant={"h4"} align={"center"} color={"primary"}>
            Herzlich Willkommen bei HeavenHR 2.0! &#128075;
          </Typography>
          <br />
          <Typography variant={"subtitle2"} align={"center"}>
            Schön, dass Sie sich für unser Produkt interessieren. Wie können wir
            Ihnen weiterhelfen?
          </Typography>
          <br />
          <Button
            variant={"contained"}
            onClick={() => {
              dispatch(enterDemoAccount());
            }}
            disabled
          >
            Ich möchte HeavenHR besser kennenlernen.
          </Button>
          <br />
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => {
              setPage(2);
            }}
          >
            Ich möchte HeavenHR in meiner Firma einführen.
          </Button>
          <br />
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => {
              setPage(3);
            }}
            disabled
          >
            Meine Firma benutzt bereits HeavenHR.
          </Button>
          <br />
          <br />
          <Button variant={"contained"} onClick={() => dispatch(logOut())}>
            Zurück zur Homepage
          </Button>
        </>
      );
      break;
    case 2:
      returnJsx = <NewCustomer localState={setPage} />;
      break;
    case 3:
      returnJsx = <AlreadyCustomer localState={setPage} />;
      break;
    case 4:
      returnJsx = (
        <>
          <Typography variant={"h6"} align={"center"}>
            Vielen Dank. &#128075;
          </Typography>
          <br />
          <Typography variant={"subtitle2"} align={"center"}>
            Die Anfrage wurde verschickt. Sobald sie angenommen wird, erhalten
            Sie eine E-Mail.
          </Typography>
          <br />
          <Button variant={"contained"} onClick={() => dispatch(logOut())}>
            Zurück zur Homepage
          </Button>
        </>
      );
  }

  return (
    <PopUpBackground
      visible={
        generalState.auth.roles?.HR_MANAGER.length === 0 &&
        generalState.auth.roles?.EMPLOYEE.length === 0 &&
        generalState.auth.authentication
      }
    >
      <PopUp>{returnJsx}</PopUp>
    </PopUpBackground>
  );
};

export default AuthorizationPopUp;
