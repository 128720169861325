import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { menu, menuAdmin, menuEmployee, menuItem } from "../../../config/menu";
import { useUserSelection } from "../../../state/general/selectors";
import { AccountType } from "../../../state/general/userSelection";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Select,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const MenuContainer = styled.div`
  background-color: "#F5F8FA";
`;

const MenuDesktop = (): React.ReactElement => {
  //linking hook used in handleClick
  const history = useHistory();
  const accountType = useUserSelection().type;
  const [open, setOpen] = React.useState(0);

  const location = useLocation();
  let menuItems: menu;

  switch (accountType) {
    case AccountType.HR_MANAGER:
      menuItems = menuAdmin;
      break;
    case AccountType.EMPLOYEE:
      menuItems = menuEmployee;
      break;
    default:
      menuItems = menuAdmin;
  }
  useEffect(() => {
    menuItems.menuItems.forEach((item: menuItem) => {
      item.children !== false
        ? item.children.forEach((r) => {
            if (location.pathname == r.link) setOpen(r.key);
          })
        : null;
    });
  }, []);

  const handleClick = (openedGroup: number, link: string | false) => {
    setOpen(openedGroup);
    if (link) {
      history.push(link);
    }
  };

  const menuItemsJSX = menuItems.menuItems.map((item) => {
    return (
      <div
        key={item.key}
        style={
          item.key === open ||
          (item.children !== false
            ? item.children.filter((item: { key: number }) => item.key == open)
                ?.length > 0
            : null)
            ? { backgroundColor: "#E8EFF4" }
            : { backgroundColor: "transparent" }
        }
      >
        <ListItemButton
          onClick={() =>
            handleClick(
              open === item.key ||
                (item.children !== false
                  ? item.children.filter((r) => {
                      return r.key == open;
                    }).length > 0
                  : null)
                ? 0
                : item.key,
              item.link
            )
          }
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.title} />
          {item.children ? (
            open === item.key ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )
          ) : (
            <></>
          )}
        </ListItemButton>
        {item.children ? (
          <Collapse
            in={
              open === item.key ||
              item.children.filter((item) => item.key == open)?.length > 0
            }
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {item.children.map((child) => {
                return (
                  <ListItemButton
                    key={child.key}
                    onClick={() => {
                      handleClick(child.key, child.link);
                    }}
                    style={
                      child.key == open
                        ? {
                            backgroundColor: "#D6E4EF",
                            borderRadius: "4px",
                            margin: "0px 10px 0px 10px",
                          }
                        : { margin: "0px 10px 0px 10px" }
                    }
                  >
                    <ListItemIcon />
                    <ListItemText primary={child.title} />
                  </ListItemButton>
                );
              })}
            </List>
          </Collapse>
        ) : (
          <></>
        )}
      </div>
    );
  });

  return (
    <MenuContainer>
      <List component="nav" aria-labelledby="nested-list-subheader">
        {menuItemsJSX}
      </List>
    </MenuContainer>
  );
};

export default MenuDesktop;
