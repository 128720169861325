import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Icon from "../../../../globalComponents/container/Icon";
import { faColumns, faSitemap } from "@fortawesome/free-solid-svg-icons";
import ViewContainer from "../../../../globalComponents/container/ViewContainer";
import { listEmployeeProfiles } from "../../../../state/employees/employees/employee";
import { useEmployees } from "../../../../state/employees/employees/selectors";
import SmartTable from "../../../../globalComponents/smartOperations/smartTable/SmartTable";
import EmployeeAvatar from "../../../../globalComponents/avatars/EmployeeAvatar";
import { FormFieldType } from "../../../../globalComponents/dataCard/utils";
import { Typography } from "@mui/material";
import { getDateText } from "../../../../utils/dates";
import { baseUrl } from "../../../../config/globalVariables";
import { PageTitle } from "../../../../globalComponents/PageHeader/PageTitle";
import EmployeeAdd from "./slidein/EmployeeAdd";

type Props = {
  view: "chart" | "table";
};

const tableCells = [
  {
    field: "lastName",
    headerName: "Name",
    width: 250,
    renderCell: (params: any) => (
      <EmployeeAvatar
        firstName={params.row.firstName}
        lastName={params.value}
        position={params.row.position}
        id={params.row.id}
        imgLink={
          params.row.imgLink
            ? baseUrl + "/employees/pic/" + params.row.imgLink
            : null
        }
      />
    ),
  },
  { field: "companyName", headerName: "Company", width: 150 },
  { field: "department", headerName: "Department", width: 150 },
  { field: "location", headerName: "Location", width: 150 },
  {
    field: "startDate",
    headerName: "Joined",
    width: 120,
    renderCell: (params: any) => (
      <Typography variant={"body1"}>
        {getDateText(params.row.startDate)}
      </Typography>
    ),
  },
  {
    field: "endDate",
    headerName: "End Date",
    width: 125,
    renderCell: (params: any) => (
      <Typography variant={"body1"}>
        {getDateText(params.row.endDate)}
      </Typography>
    ),
  },

  { field: "status", headerName: "Status", width: 110 },
];

const SlideInFields = [
  {
    type: FormFieldType.TEXT,
    label: "Vorname",
    fieldName: "firstName",
    defaultValue: "",
    validation: { required: true },
  },
  {
    type: FormFieldType.TEXT,
    label: "Nachname",
    fieldName: "lastName",
    defaultValue: "",
    validation: { required: true },
  },
  {
    type: FormFieldType.TEXT,
    label: "Email",
    fieldName: "email",
    defaultValue: "",
  },
  {
    type: FormFieldType.DATE_LEFT,
    label: "Startdatum",
    fieldName: "startDate",
    defaultValue: "2021-11-01",
  },
  {
    type: FormFieldType.TEXT,
    label: "Telefonnummer",
    fieldName: "phoneNumber",
    defaultValue: "",
  },
  {
    type: FormFieldType.SELECT_MOTHER_COMPANY,
    label: "Zugehörige Firma",
    fieldName: "companyId",
    defaultValue: "",
    validation: { required: true },
  },
  {
    type: FormFieldType.SELECT_DEPARTMENT,
    label: "Zugehörige Abteilung",
    fieldName: "departmentId",
    defaultValue: "",
  },
  {
    type: FormFieldType.SELECT_LOCATION,
    label: "Zugehörige Abteilung",
    fieldName: "locationId",
    defaultValue: "",
  },
];

const Employees = (props: Props): React.ReactElement => {
  const [slideIn, setSlideIn] = useState(false);
  //get org chart data and table data
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listEmployeeProfiles());
  }, []);
  const employees = useEmployees();

  return (
    <>
      <PageTitle>
        <Typography variant={"h1"}>Mitarbeiter</Typography>
        <ViewContainer>
          <Typography variant={"subtitle2"} marginRight={"10px"}>
            Ansicht:{" "}
          </Typography>
          <br />
          <Link to={"/app/employees"}>
            <Icon icon={faSitemap} />
          </Link>
          <br />
          <Link to={"/app/employees/table"}>
            <Icon icon={faColumns} />
          </Link>
        </ViewContainer>
      </PageTitle>
      {props.view === "chart" ? (
        <SmartTable
          deleteEndpoint={"/employees?employeeId="}
          columns={tableCells}
          createButton={{
            label: "Add Employee",
            callbackFunc: () => setSlideIn(true),
          }}
          title={"Added Employees"}
          deleteConfirmMessage={"Wollen Sie den Mitarbeiter wirklich löschen?"}
          input={employees}
          updateInputCallback={() => dispatch(listEmployeeProfiles)}
          quickFilter={[
            {
              label: "All",
              updateAction: {
                columnField: "firstName",
                value: "Christian",
                operatorValue: "contains",
              },
            },
            {
              label: "Active",
              updateAction: {
                columnField: "status",
                value: "active",
                operatorValue: "contains",
              },
            },
            {
              label: "Future",
              updateAction: {
                columnField: "status",
                value: "active",
                operatorValue: "contains",
              },
            },
            {
              label: "Candidates",
              updateAction: {
                columnField: "status",
                value: "active",
                operatorValue: "contains",
              },
            },
            {
              label: "Past",
              updateAction: {
                columnField: "status",
                value: "active",
                operatorValue: "equals",
              },
            },
            {
              label: "Others",
              updateAction: {
                columnField: "status",
                value: "active",
                operatorValue: "contains",
              },
            },
          ]}
        />
      ) : (
        <></>
      )}
      {slideIn ? <EmployeeAdd close={() => setSlideIn(false)} /> : <></>}
    </>
  );
};

export default Employees;
