import styled from "styled-components";
import Button from "@material-ui/core/Button";

interface Selected {
  selected: boolean;
}

export const SelectButton = styled(Button)<Selected>`
  background-color: ${(props) => (props.selected ? "#043D66" : "#C3C3C3")};
  color: ${(props) => (props.selected ? "white" : "#043D66")};
  &:hover {
    background-color: ${(props) => (props.selected ? "#043D66" : "lightgrey")};
  }
  flex: 1;
  border-radius: 0;
`;
