import React from "react";
import {
  CellHeader,
  Container,
  Day,
  SearchEmplyeeContainer,
  StyledDate,
} from "./styles";
import {
  calculateDate,
  isFirst,
  isLast,
  getCellKey,
  getIstHoliday,
} from "../utils";
import { format } from "date-fns/fp";
import { StyledTooltip } from "../../../../../../../globalComponents/tooltip";

export type HeaderProps = {
  date: Date;
  columns: number[];
  width: number;
};

const Header: React.FC<HeaderProps> = ({ date, columns, width }) => {
  const getCellProps = (day: number) => {
    const calculatedDate = calculateDate(day)(date);
    const dayLabel = format("iii")(calculatedDate);

    return { dayLabel };
  };

  return (
    <Container columns={columns} basesize={width}>
      <SearchEmplyeeContainer></SearchEmplyeeContainer>
      {columns.map((day, index) => {
        const { dayLabel } = getCellProps(day);
        const isHoliday = getIstHoliday();

        return (
          <StyledTooltip
            key={getCellKey(date)(index)}
            title="Public Holiday"
            disableHoverListener={!isHoliday}
            placement="top"
          >
            <CellHeader
              borderLeft={isFirst(index)}
              borderRight={!isLast(index)(columns)}
              basesize={width}
              dashedBackground={isHoliday}
            >
              <StyledDate basesize={width}>{day + 1}</StyledDate>
              <Day basesize={width}>{dayLabel}</Day>
            </CellHeader>
          </StyledTooltip>
        );
      })}
    </Container>
  );
};

export default Header;
