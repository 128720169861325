import { DataState } from "./companyDetail";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CoreRequest } from "../../../../config/backend/serviceInstances";

export type Absencetypes = {
  id: number;
};

export interface AbsencetypeState extends DataState {
  data: Absencetypes[];
}

export const AbsencetypeStateI: AbsencetypeState = {
  loading: false,
  error: false,
  data: [],
};

export const listAbsencetypesByCompanyId = createAsyncThunk<
  Absencetypes[],
  number
>("/companies/detail/absencetypes", async (companyId) => {
  const response = await CoreRequest.get(`/companies/absencetypes`, {
    params: { companyId: companyId },
  });

  if (response.status > 299) {
    // Return the known error for future handling
    return response.data.error;
  }

  return response.data as Absencetypes[];
});
