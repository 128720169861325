import styled from "styled-components";
import { Animate } from "./useAnimate";

// types
export type Styles = Partial<{
  animate: Animate;
  timeout: string;
  activeOnMount: boolean;
  animationName: string;
  animateInStyle: string;
  animateOutStyle: string;
}>;

// utils
export const getKeyFramesAnimatein = ({
  animationName = "animate",
  animateInStyle,
  animateOutStyle,
}: Styles): string => `
@keyframes ${getAnimationName(animationName)(Animate.In)} {
  from {
    ${animateOutStyle};
  }

  to {
    ${animateInStyle};
  }
}
`;

export const getKeyFramesAnimateout = ({
  animationName = "animate",
  animateInStyle,
  animateOutStyle,
}: Styles): string => `
@keyframes ${getAnimationName(animationName)(Animate.Out)} {
  from {
    ${animateInStyle};
  }

  to {
    ${animateOutStyle};
  }
}
`;

const getAnimationName = (animationName?: string) => (animate?: string) =>
  `${animationName}${animate}`;

export const addAnimation = ({
  animationName = "animate",
  animate,
}: Styles): string => {
  if (animate === Animate.Empty) return "";

  return `animation-name: ${getAnimationName(animationName)(animate)};`;
};

export const getAnimationDuration = ({ timeout = "0.2s" }: Styles): string =>
  `animation-duration: ${timeout};`;

export const getDefaultValue = ({
  activeOnMount,
  animateInStyle,
  animateOutStyle,
}: Styles): string => `${activeOnMount ? animateInStyle : animateOutStyle};`;

// styles
export const Container = styled.div`
  animation-fill-mode: forwards;
  ${getAnimationDuration}
  ${getDefaultValue}
  ${getKeyFramesAnimatein}
  ${getKeyFramesAnimateout}
  ${addAnimation}
`;
