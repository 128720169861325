import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import { CoreRequest } from "../../../config/backend/serviceInstances";
import { useUserSelection } from "../../../state/general/selectors";
import { StyledLabel, TextFieldWrapper } from "../TextFieldController";
import {
  CircularProgress,
  FormHelperText,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { MenuProps } from "./EmployeesSelect";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export interface LocationLabel {
  label: string;
  value: number;
}

type Props = {
  control: Control<any>;
  fieldName: `${string}`;
  companyId?: number | null;
  defaultValue?: number | null;
  required?: boolean;
};

const LocationSelect = (props: Props): React.ReactElement => {
  const { t } = useTranslation("common");
  const userSelection = useUserSelection();

  const [locationLabels, setLocationLabels] = useState<LocationLabel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const params: any = {};
    const url = props.companyId
      ? `/companies/${props.companyId}/locations/labels`
      : "/companies/locations/labels";
    CoreRequest.get(url, { params })
      .then((response) => {
        setLoading(false);
        setLocationLabels(response.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [userSelection, setLocationLabels, setLoading, props.companyId]);

  if (!locationLabels || loading) {
    return (
      <TextFieldWrapper>
        <StyledLabel>
          <label>{t("location")}</label>
        </StyledLabel>
        <CircularProgress />
      </TextFieldWrapper>
    );
  }

  return (
    <Controller
      name={props.fieldName}
      control={props.control}
      defaultValue={props.defaultValue || ""}
      rules={props.required ? { required: "Location is required" } : {}}
      render={({
        field: { onChange, value },
        fieldState: { error, invalid, isDirty },
      }) => {
        return (
          <TextFieldWrapper>
            <StyledLabel>
              <label>{t("location")}</label>
              {!invalid && isDirty ? (
                <>
                  &nbsp;
                  <FontAwesomeIcon icon={faCheckCircle} color={"#00A76D"} />
                </>
              ) : (
                <></>
              )}
              {(invalid && isDirty) || error ? (
                <>
                  &nbsp;
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    color={"#C80410"}
                  />
                </>
              ) : (
                <></>
              )}
            </StyledLabel>
            <Select
              disabled={loading}
              labelId={`${props.fieldName}-locationSelect`}
              id={`${props.fieldName}-locationSelect`}
              onChange={onChange}
              value={value}
              MenuProps={MenuProps}
              error={error !== undefined}
              renderValue={(value: any) => {
                const vals = locationLabels.filter(
                  (item) => item.value == value
                );
                if (vals && vals.length > 0) {
                  return vals[0].label;
                }
              }}
            >
              {locationLabels.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </Select>
            {error && (
              <FormHelperText error>
                Bitte geben Sie die übergeordnete Standort an.
              </FormHelperText>
            )}
          </TextFieldWrapper>
        );
      }}
    />
  );
};

export default LocationSelect;
