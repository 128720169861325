import React from "react";
import styled from "styled-components";
import { MenuItem, Select, Typography } from "@mui/material";

// types
type MenuItem = {
  value: string;
  label: string;
};

type SelectInputProps = {
  label: string;
  value: string;
  menueItems: MenuItem[];
  selectHeight?: string;
};

type Styles = Partial<{
  height: string;
}>;

// styles
const getHeight = ({ height }: Styles) => {
  if (!height) return "";

  return `height: ${height};`;
};

const SelectInputContainer = styled.div``;

const StyledSelect = styled(Select)`
  margin-top: 10px;
  width: 100%;
  ${getHeight}
`;

// components
const SelectInput: React.FC<SelectInputProps> = ({
  label,
  value,
  menueItems,
  selectHeight,
}) => {
  return (
    <SelectInputContainer>
      <Typography>{label}</Typography>
      <StyledSelect variant="outlined" value={value} height={selectHeight}>
        {menueItems.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </StyledSelect>
    </SelectInputContainer>
  );
};

export default SelectInput;
