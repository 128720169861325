import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import { AbsenceType } from "../../../state/employees/types";
import { CoreRequest } from "../../../config/backend/serviceInstances";
import { useUserSelection } from "../../../state/general/selectors";
import { StyledLabel, TextFieldWrapper } from "../TextFieldController";
import {
  CircularProgress,
  FormHelperText,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { MenuProps } from "./EmployeesSelect";

type Props = {
  control: Control<any>;
  fieldName: `${string}`;
  defaultValue?: number | null;
};

const AbsenceTypeSelect = (props: Props): React.ReactElement => {
  const userSelection = useUserSelection();

  const [absenceTypes, setAbsenceTypes] = useState<AbsenceType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    CoreRequest.get(`/employees/absence/types`)
      .then((response) => {
        setLoading(false);
        setAbsenceTypes(response.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [userSelection, setAbsenceTypes, setLoading]);

  if (!absenceTypes || loading) {
    return (
      <TextFieldWrapper>
        <StyledLabel id={`${props.fieldName}-absenceTypeSelect`}>
          <label>Abwesenheitsart</label>
        </StyledLabel>
        <CircularProgress />
      </TextFieldWrapper>
    );
  }

  return (
    <Controller
      name={props.fieldName}
      control={props.control}
      defaultValue={props.defaultValue || ""}
      rules={{ required: "Absence type is required" }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <TextFieldWrapper>
            <StyledLabel id={`${props.fieldName}-absenceTypeSelect`}>
              <label>Abwesenheitsart</label>
            </StyledLabel>
            <Select
              disabled={loading}
              labelId={`${props.fieldName}-absenceTypeSelect`}
              id={`${props.fieldName}-absenceTypeSelect`}
              onChange={onChange}
              value={value}
              MenuProps={MenuProps}
              renderValue={(value: any) => {
                const vals = absenceTypes.filter((item) => item.id == value);
                if (vals && vals.length > 0) {
                  return `${vals[0].description || "Urlaub"}`;
                }
              }}
            >
              {absenceTypes.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  <ListItemText primary={item.description || "Urlaub"} />
                </MenuItem>
              ))}
            </Select>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </TextFieldWrapper>
        );
      }}
    />
  );
};

export default AbsenceTypeSelect;
