import { useEffect, useRef, useState } from "react";

// types
export type UseAnimate = (
  active: boolean
) => {
  animate: Animate;
  activeOnMount: boolean;
};

export enum Animate {
  In = "in",
  Out = "out",
  Empty = "",
}

const useAnimate: UseAnimate = (active) => {
  const activeOnMount = useRef(active);
  const [animate, setAnimte] = useState<Animate>(Animate.Empty);

  useEffect(() => {
    if (animate === Animate.Empty && active === activeOnMount.current) return;

    if (active) return setAnimte(Animate.In);

    if (!active) return setAnimte(Animate.Out);
  }, [active, activeOnMount]);

  return { animate, activeOnMount: activeOnMount.current };
};

export default useAnimate;
