import React from "react";

interface EmployeeAvatarNewProps {
  size: "SMALL" | "MEDIUM" | "LARGE";
  imgUrl?: string | null;
}

let staticUrl = "/public";
if (
  process &&
  process.env &&
  process.env.PUBLIC_URL !== null &&
  process.env.PUBLIC_URL !== undefined
) {
  staticUrl = process.env.PUBLIC_URL;
}

const EmployeeAvatarNew = (props: EmployeeAvatarNewProps): JSX.Element => {
  let imgLink = `${staticUrl}/img/missing-avatar.png`;
  if (props.imgUrl) {
    imgLink = props.imgUrl;
  }

  switch (props.size) {
    case "LARGE":
      return imgLink ? (
        <svg
          id={"SquircleLarge" + imgLink}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="120"
          height="120"
          viewBox="0 0 120 120"
          key={"LARGE"}
        >
          <defs>
            <clipPath id="clip-pathLarge">
              <path
                id="Path_117_Large"
                data-name="Path 117_Large"
                d="M9.479,9.479C3.186,15.772,1,29.591,1,61s2.186,45.228,8.479,51.521S29.591,121,61,121s45.228-2.186,51.521-8.479S121,92.409,121,61s-2.186-45.228-8.479-51.521S92.409,1,61,1,15.772,3.186,9.479,9.479Z"
                transform="translate(-0.758 4.375)"
                fill="#c2c2c2"
              />
            </clipPath>
            <pattern
              id="patternLarge"
              width="1"
              height="1"
              patternTransform="matrix(-1, 0, 0, 1, 266.181, 0)"
              viewBox="0 0 133.091 134.794"
            >
              <image
                preserveAspectRatio="none"
                width="133.091"
                height="134.794"
                xlinkHref={imgLink}
              />
            </pattern>
          </defs>
          <g
            id="Mask_Group_31_Large"
            data-name="Mask Group 31_Large"
            transform="translate(-0.242 -5.375)"
            clipPath="url(#clip-pathLarge)"
          >
            <rect
              id="Image_9_Large"
              data-name="Image 9_Large"
              width="133.091"
              height="134.794"
              transform="translate(0 -2)"
              fill="url(#patternLarge)"
            />
          </g>
        </svg>
      ) : (
        <svg
          id="Squircle"
          xmlns="http://www.w3.org/2000/svg"
          width="120"
          height="120"
          viewBox="0 0 120 120"
        >
          <path
            id="Path_117"
            data-name="Path 117"
            d="M9.479,9.479C3.186,15.772,1,29.591,1,61s2.186,45.228,8.479,51.521S29.591,121,61,121s45.228-2.186,51.521-8.479S121,92.409,121,61s-2.186-45.228-8.479-51.521S92.409,1,61,1,15.772,3.186,9.479,9.479Z"
            transform="translate(-1 -1)"
            fill="#c2c2c2"
          />
        </svg>
      );
    case "MEDIUM":
      return imgLink ? (
        <svg
          id={"SquircleMiddle" + imgLink}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="80"
          height="80"
          viewBox="0 0 80 80"
        >
          <defs>
            <clipPath id="clip-path_MEDIUM">
              <path
                id="Path_117_MEDIUM"
                data-name="Path 117_MEDIUM"
                d="M6.653,6.653C2.457,10.848,1,20.06,1,41S2.457,71.152,6.653,75.347,20.06,81,41,81s30.152-1.457,34.347-5.653S81,61.94,81,41,79.543,10.848,75.347,6.653,61.94,1,41,1,10.848,2.457,6.653,6.653Z"
                transform="translate(-1 -1)"
                fill="#c2c2c2"
              />
            </clipPath>
            <pattern
              id="pattern_MEDIUM"
              width="1"
              height="1"
              patternTransform="matrix(-1, 0, 0, 1, 157.978, 0)"
              viewBox="0 0 78.989 80"
            >
              <image
                preserveAspectRatio="none"
                width="78.989"
                height="80"
                xlinkHref={imgLink}
              />
            </pattern>
          </defs>
          <g
            id="Mask_Group_31_MEDIUM"
            data-name="Mask Group 31_;EDIUM"
            clipPath="url(#clip-path_MEDIUM)"
          >
            <rect
              id="Image_9_MEDIUM"
              data-name="Image 9_MEDIUM"
              width="78.989"
              height="80"
              fill="url(#pattern_MEDIUM)"
            />
          </g>
        </svg>
      ) : (
        <svg
          id={"SquircleMiddle" + imgLink}
          xmlns="http://www.w3.org/2000/svg"
          width="80"
          height="80"
          viewBox="0 0 80 80"
        >
          <path
            id={"Path_117" + imgLink}
            data-name="Path 117"
            d="M6.653,6.653C2.457,10.848,1,20.06,1,41S2.457,71.152,6.653,75.347,20.06,81,41,81s30.152-1.457,34.347-5.653S81,61.94,81,41,79.543,10.848,75.347,6.653,61.94,1,41,1,10.848,2.457,6.653,6.653Z"
            transform="translate(-1 -1)"
            fill="#c2c2c2"
          />
        </svg>
      );
    case "SMALL":
      return imgLink ? (
        <svg
          id={"SquircleSmall" + imgLink}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="#c2c2c2"
        >
          <defs>
            <clipPath id={"clip-path" + imgLink}>
              <path
                id="Path_117"
                data-name="Path 117"
                d="M3.826,3.826C1.729,5.924,1,10.53,1,21s.729,15.076,2.826,17.174S10.53,41,21,41s15.076-.729,17.174-2.826S41,31.47,41,21,40.271,5.924,38.174,3.826,31.47,1,21,1,5.924,1.729,3.826,3.826Z"
                transform="translate(-1 -1)"
                fill="#c2c2c2"
              />
            </clipPath>
            <pattern
              id={"pattern" + imgLink}
              width="1"
              height="1"
              patternTransform="matrix(-1, 0, 0, 1, 88.727, 0)"
              viewBox="0 0 44.364 44.931"
            >
              <image
                preserveAspectRatio="none"
                width="44.364"
                height="44.931"
                xlinkHref={imgLink}
              />
            </pattern>
          </defs>
          <g
            id={"Mask_Group_31" + imgLink}
            data-name="Mask Group 31"
            clipPath={"url(#clip-path" + imgLink + ")"}
          >
            <rect
              id="Image_9"
              data-name="Image 9"
              width="44.364"
              height="44.931"
              transform="translate(-0.081 -2.458)"
              fill={"url(#pattern" + imgLink + ")"}
            />
          </g>
        </svg>
      ) : (
        <svg
          id="SquircleEmpty"
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
        >
          <path
            id="Path_117"
            data-name="Path 117"
            d="M3.826,3.826C1.729,5.924,1,10.53,1,21s.729,15.076,2.826,17.174S10.53,41,21,41s15.076-.729,17.174-2.826S41,31.47,41,21,40.271,5.924,38.174,3.826,31.47,1,21,1,5.924,1.729,3.826,3.826Z"
            transform="translate(-1 -1)"
            fill="#c2c2c2"
          />
        </svg>
      );
  }
  return <></>;
};

export default EmployeeAvatarNew;
