import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Icon = styled(FontAwesomeIcon)`
padding: 10px; 
border-radius: 5px;
color: #064475;
&:hover {
            background-color: #E3E6E8;
            cursor: 'pointer',
        },
`;

export default Icon;
