import React, { useEffect, useState } from "react";
import { GridItem } from "../styles/styles";
import { IconButton, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { ArrowDropDown } from "@mui/icons-material";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import TimetrackingDay from "./TimetrackingDay";

export type WorkingDay = {
  contractHours: number;
  regularHours: number;
  nightHours: number;
  holiday: boolean;
  absent: boolean;
};

interface TimetrackingDayDepartmentProps {
  id: number;
  name: string;
  gridItemDepartmentSize: number;
  gridItemSize: number;
  hoursPerDepartment: { workedHours: number; contractHours: number }[];
}

const TimetrackingDayDepartment = (
  props: TimetrackingDayDepartmentProps
): JSX.Element => {
  const [department, setDepartment] = useState<{
    open: boolean;
    loading: boolean;
    error: boolean;
    errorMessage: string;
    data: { id: number; days: WorkingDay[] }[];
  }>({
    open: false,
    loading: false,
    error: false,
    errorMessage: "",
    data: [
      {
        id: 1,
        days: [
          {
            contractHours: 8,
            regularHours: 7,
            nightHours: 0,
            holiday: false,
            absent: false,
          },
          {
            contractHours: 7,
            regularHours: 8,
            nightHours: 0,
            holiday: false,
            absent: false,
          },
          {
            contractHours: 8,
            regularHours: 8,
            nightHours: 0,
            holiday: false,
            absent: false,
          },
          {
            contractHours: 8,
            regularHours: 0,
            nightHours: 0,
            holiday: false,
            absent: true,
          },
          {
            contractHours: 0,
            regularHours: 0,
            nightHours: 0,
            holiday: true,
            absent: false,
          },
          {
            contractHours: 8,
            regularHours: 0,
            nightHours: 7,
            holiday: false,
            absent: false,
          },
          {
            contractHours: 8,
            regularHours: 0,
            nightHours: 7,
            holiday: false,
            absent: false,
          },
          {
            contractHours: 8,
            regularHours: 0,
            nightHours: 7,
            holiday: false,
            absent: false,
          },
          {
            contractHours: 8,
            regularHours: 0,
            nightHours: 7,
            holiday: false,
            absent: false,
          },
          {
            contractHours: 8,
            regularHours: 0,
            nightHours: 7,
            holiday: false,
            absent: false,
          },
          {
            contractHours: 8,
            regularHours: 0,
            nightHours: 7,
            holiday: false,
            absent: false,
          },
          {
            contractHours: 8,
            regularHours: 8,
            nightHours: 0,
            holiday: false,
            absent: false,
          },
          {
            contractHours: 8,
            regularHours: 8,
            nightHours: 0,
            holiday: false,
            absent: false,
          },
          {
            contractHours: 8,
            regularHours: 8,
            nightHours: 0,
            holiday: false,
            absent: false,
          },
          {
            contractHours: 8,
            regularHours: 8,
            nightHours: 0,
            holiday: false,
            absent: false,
          },
          {
            contractHours: 8,
            regularHours: 8,
            nightHours: 0,
            holiday: false,
            absent: false,
          },
          {
            contractHours: 8,
            regularHours: 8,
            nightHours: 0,
            holiday: false,
            absent: false,
          },
          {
            contractHours: 8,
            regularHours: 8,
            nightHours: 0,
            holiday: false,
            absent: false,
          },
          {
            contractHours: 8,
            regularHours: 9,
            nightHours: 0,
            holiday: false,
            absent: false,
          },
          {
            contractHours: 8,
            regularHours: 9,
            nightHours: 0,
            holiday: false,
            absent: false,
          },
          {
            contractHours: 8,
            regularHours: 9,
            nightHours: 0,
            holiday: false,
            absent: false,
          },
          {
            contractHours: 8,
            regularHours: 9,
            nightHours: 0,
            holiday: false,
            absent: false,
          },
          {
            contractHours: 8,
            regularHours: 0,
            nightHours: 0,
            holiday: false,
            absent: true,
          },
          {
            contractHours: 8,
            regularHours: 0,
            nightHours: 0,
            holiday: false,
            absent: true,
          },
          {
            contractHours: 8,
            regularHours: 0,
            nightHours: 0,
            holiday: false,
            absent: true,
          },
          {
            contractHours: 8,
            regularHours: 0,
            nightHours: 0,
            holiday: false,
            absent: true,
          },
          {
            contractHours: 8,
            regularHours: 0,
            nightHours: 0,
            holiday: false,
            absent: true,
          },
          {
            contractHours: 8,
            regularHours: 0,
            nightHours: 0,
            holiday: false,
            absent: true,
          },
          {
            contractHours: 8,
            regularHours: 7,
            nightHours: 0,
            holiday: false,
            absent: false,
          },
          {
            contractHours: 8,
            regularHours: 9,
            nightHours: 0,
            holiday: false,
            absent: false,
          },
        ],
      },
    ],
  });

  useEffect(() => {
    if (department.open) {
      setDepartment({ ...department, loading: true });
      //make call to backend and update departmentState
      setDepartment({ ...department, loading: false });
    }
  }, []);

  const departments: JSX.Element[] = [
    <GridItem
      backgroundColor={"white"}
      height={"40px"}
      size={props.gridItemDepartmentSize}
      key={props.id}
      style={{
        padding: "5px",
        paddingLeft: "40px",
        borderLeft: "1px solid #E3E6E8",
        borderRight: "1px solid #E3E6E8",
      }}
    >
      <IconButton
        onClick={() => setDepartment({ ...department, open: !department.open })}
      >
        {department.open ? <ArrowDropUpIcon /> : <ArrowDropDown />}
      </IconButton>
      <Typography variant={"subtitle2"} marginRight={1}>
        {props.name}
      </Typography>
    </GridItem>,
  ];
  props.hoursPerDepartment.forEach(() => {
    departments.push(
      <GridItem
        backgroundColor={"white"}
        height={"40px"}
        size={props.gridItemSize}
        key={uuidv4()}
        style={{
          borderRight: "1px solid #E3E6E8",
          textAlign: "center",
          justifyContent: "center",
          padding: "5px",
        }}
      >
        <Typography variant={"body1"} />
      </GridItem>
    );
  });

  if (department.open) {
    department.data.forEach((employeeRow) => {
      departments.push(
        <GridItem
          backgroundColor={"white"}
          height={"40px"}
          size={props.gridItemDepartmentSize}
          key={uuidv4()}
          style={{
            padding: "5px",
            paddingLeft: "40px",
            borderLeft: "1px solid #E3E6E8",
            borderRight: "1px solid #E3E6E8",
          }}
        >
          John Watson
        </GridItem>
      );
      employeeRow.days.forEach((day) => {
        departments.push(<TimetrackingDay day={day} key={uuidv4()} />);
      });
    });
  }

  return <>{departments}</>;
};

export default TimetrackingDayDepartment;
