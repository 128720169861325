import React, { useEffect, useState } from "react";
import { DisplayCardField } from "../../dataCard/utils";
import { AbsenceRequest, WorkSchedule } from "../../../state/employees/types";
import FormFieldReturn from "./FormFieldReturn";
import { SlideInBody, SlideInHeader, SlideInWrapper } from "./styles";
import { LabelValuePair } from "../../formFields/staticSelect/SelectMenuInput";
import { slideInType } from "./SmartCrud";
import { CoreRequest } from "../../../config/backend/serviceInstances";
import { CircularProgress, IconButton, Slide, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Backdrop } from "@material-ui/core";

export enum endpointTypes {
  PUT = "PUT",
  PATCH = "PATCH",
  GET = "GET",
  POST = "POST",
}

interface SmartSlideInProps {
  title: string;
  updateId: number;
  getEndpointDetail: string;
  putPatchEndpoint: {
    url: string;
    type: "PUT" | "PATCH";
    requestParameter?: LabelValuePair[];
    bodyParameter?: LabelValuePair[];
    patchParameter?: string[];
  };
  formFields: DisplayCardField[];
  close: React.Dispatch<
    React.SetStateAction<{
      type: slideInType | null;
      id: number;
      reload: boolean;
    }>
  >;
  type: "UPDATE" | "CREATE" | "DELETE";
  backdropCallback: () => void;
}

interface MinResponse {
  id: number;
}
// to be expanded with new types when implemented
export type ResponseTypes = MinResponse | AbsenceRequest | WorkSchedule;

const SmartSlideInUpdate = (props: SmartSlideInProps): JSX.Element => {
  const [dataResponse, setDataResponse] = useState<ResponseTypes>({ id: 0 });
  const [state, setState] = useState({ loading: false, error: false });

  useEffect(() => {
    setState({ loading: true, error: false });
    CoreRequest.get(props.getEndpointDetail + "" + props.updateId)
      .then((response) => {
        setDataResponse(response.data);
        setState({ loading: false, error: false });
      })
      .catch(() => setState({ loading: false, error: true }));
  }, [props.updateId]);

  type responseKeys = keyof ResponseTypes;

  return (
    <>
      {" "}
      <Backdrop
        open={
          props.type == "UPDATE" ||
          props.type == "CREATE" ||
          props.type == "DELETE"
        }
        style={{ zIndex: 6 }}
        onClick={() => props.backdropCallback()}
      />
      <Slide in direction={"left"} timeout={1000}>
        <SlideInWrapper>
          <SlideInHeader>
            <Typography variant={"h3"}>{props.title}</Typography>
            <IconButton
              onClick={() => props.close({ type: null, id: 0, reload: false })}
              sx={{ width: 40, height: 40, borderRadius: 0 }}
            >
              <Typography variant={"subtitle2"}>
                <FontAwesomeIcon icon={faTimes} color={"#4C4C4C"} />
              </Typography>
            </IconButton>
          </SlideInHeader>
          <SlideInBody>
            {state.loading ? (
              <CircularProgress />
            ) : (
              <FormFieldReturn
                endpoint={{
                  url: props.putPatchEndpoint.url,
                  type: props.putPatchEndpoint.type,
                  requestParameter: props.putPatchEndpoint.requestParameter,
                  bodyParameter: props.putPatchEndpoint.bodyParameter,
                  pathParameter: props.putPatchEndpoint.patchParameter,
                }}
                formFields={props.formFields.map((item) => {
                  return {
                    type: item.type,
                    label: item.label,
                    fieldName: item.fieldName,
                    validation: item.validation,
                    defaultValue:
                      dataResponse[
                        (item.requestFieldName
                          ? item.requestFieldName
                          : item.fieldName) as responseKeys
                      ],
                    menuItems: item.menuItems,
                    minutesFieldName: item.minutesFieldName,
                    hoursFieldName: item.hoursFieldName,
                    defaultValueHours:
                      dataResponse[item.hoursFieldName as responseKeys],
                    defaultValueMin:
                      dataResponse[item.minutesFieldName as responseKeys],
                    asRequestParameter: item.asRequestParameter,
                    conditionalFields: item.conditionalFields,
                    conditionalRender: item.conditionalRender,
                    requestFieldName: item.requestFieldName,
                  };
                })}
                confirm={() => props.close({ type: null, id: 0, reload: true })}
              />
            )}
          </SlideInBody>
        </SlideInWrapper>
      </Slide>
    </>
  );
};

export default SmartSlideInUpdate;
