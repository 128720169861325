import React from "react";
import EmployeeAvatar from "../../../../../../globalComponents/avatars/EmployeeAvatar";
import styled from "styled-components";
import { TextField } from "@mui/material";
import TimetrackingDetail from "./TimetrackingDetail";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin: 10px 0px 40px 0px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
`;

const ListContainer = styled.div`
  min-height: 500px;
  overflow: hidden;
  border-right: 1px solid #d9d9d9;
  background-color: #f2f2f2;
`;

const EmployeeContainer = styled.div`
  padding: 10px 20px 10px 20px;
  border-bottom: 1px solid #d9d9d9;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: #e3e6e8;
    cursor: pointer;
  }
`;

const ViewContainer = styled.div`
  flex: 1;
`;

const TimetrackingCompany = (): JSX.Element => {
  return (
    <Container>
      <ListContainer>
        <TextField
          sx={{
            padding: "20px",
            background: "white",
            borderBottom: "1px solid #d9d9d9",
          }}
          placeholder={"Mitarbeiter suchen"}
        />
        <EmployeeContainer>
          <EmployeeAvatar
            id={1}
            firstName={"Alex"}
            lastName={"Watson"}
            position={"CEO"}
          />
          <FontAwesomeIcon icon={faAngleRight} color={"#999999"} />
        </EmployeeContainer>
        <EmployeeContainer>
          <EmployeeAvatar
            id={2}
            firstName={"Gunnar"}
            lastName={"Watson"}
            position={"CTO"}
          />
          <FontAwesomeIcon icon={faAngleRight} color={"#999999"} />
        </EmployeeContainer>
      </ListContainer>
      <ViewContainer>
        <TimetrackingDetail />
      </ViewContainer>
    </Container>
  );
};

export default TimetrackingCompany;
