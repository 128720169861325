import { useSelector } from "react-redux";
import { DPFState } from "./DPF";
import { Store } from "../store";
import { PeriodState } from "./periodState";
import { Employment } from "./employments";
import { PayrollAbsenceState } from "./absences";
import { PayrollTimetrackingState } from "./timetracking";

export const useCurrentPayrollPeriod = (): PeriodState => {
  return useSelector<Store, PeriodState>((state) => state.payroll.period);
};

export const useDPFtoDos = (): DPFState => {
  return useSelector<Store, DPFState>((state) => state.payroll.dpf);
};

export const usePayrollAbsences = (): PayrollAbsenceState => {
  return useSelector<Store, PayrollAbsenceState>(
    (state) => state.payroll.absenceRequests
  );
};

export const usePayrollTimetracking = (): PayrollTimetrackingState => {
  return useSelector<Store, PayrollTimetrackingState>(
    (state) => state.payroll.timetrackingRequests
  );
};

export const useEmployments = (): Employment[] => {
  return useSelector<Store, Employment[]>(
    (state) => state.payroll.employments.data
  );
};
