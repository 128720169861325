import React from "react";

const PluralComponent = (props: {
  singleVersion: string;
  pluralVersion: string;
  plural: boolean;
}): JSX.Element => {
  return <>{props.plural ? props.pluralVersion : props.singleVersion}</>;
};

export default PluralComponent;
