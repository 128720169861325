import styled from "styled-components";
import React from "react";

const ViewContainerWrapper = styled.div`
display: flex;
flex-direction: row; 
justify-content: flex-end;
align-items: center;
padding: 10px;
padding-right: 0px; 
`;

const ViewContainer = (props)=>{

    return <ViewContainerWrapper>
        {props.children}
    </ViewContainerWrapper>
}

export default ViewContainer;
