import React from "react";
import { Grid, Typography } from "@mui/material";

const EmployeeSocialHeader = (): JSX.Element => {
  return (
    <Grid
      container
      columns={22}
      marginTop={1}
      marginBottom={5}
      sx={{ boxShadow: "0px 0px 2px 0px lightgrey", padding: "5px" }}
    >
      <Grid item xs={2}>
        <Typography variant={"body1"}>SV-Daten</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant={"body1"}>SV-Nummer</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant={"body1"}>Krankenkasse</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"body1"}>KK %</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"body1"}>PGRS</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"body1"}>BGRS</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant={"body1"}>Umlage</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"body1"}>SV-Tage</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"subtitle2"} />
      </Grid>
      <Grid item xs={3}>
        <Typography variant={"subtitle2"}>53190188Z502</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant={"subtitle2"}>Techniker Krankenkasse</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"subtitle2"}>16.3%</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"subtitle2"}>101-</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"subtitle2"}>1</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant={"subtitle2"}>1</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"subtitle2"}>30</Typography>
      </Grid>
    </Grid>
  );
};

export default EmployeeSocialHeader;
