import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import { useUserSelection } from "../../../state/general/selectors";
import { CoreRequest } from "../../../config/backend/serviceInstances";
import { StyledLabel, TextFieldWrapper } from "../TextFieldController";
import { MenuProps } from "./EmployeesSelect";
import {
  CircularProgress,
  FormHelperText,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";

export interface EmployeeLabel {
  label: string;
  value: number;
}

type Employee = {
  id: number;
  firstName: string;
  lastName: string;
};

type Props = {
  control: Control<any>;
  fieldName: `${string}`;
  defaultValue?: number | null;
  label: string;
};

const EmployeeSelect = (props: Props): React.ReactElement => {
  const userSelection = useUserSelection();

  const [employeeLabels, setEmployeeLabels] = useState<EmployeeLabel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    CoreRequest.get("/employees/all")
      .then((response) => {
        setLoading(false);
        setEmployeeLabels(
          response.data.map((employee: Employee) => {
            return {
              label: `${employee.firstName} ${employee.lastName}`,
              value: employee.id,
            };
          })
        );
      })
      .catch(() => {
        setLoading(false);
      });
  }, [userSelection]);

  if (!employeeLabels || loading) {
    return (
      <TextFieldWrapper>
        <StyledLabel>
          <label id={`${props.fieldName}-employeeSelect`}>{props.label}</label>
        </StyledLabel>
        <CircularProgress />
      </TextFieldWrapper>
    );
  }

  return (
    <Controller
      name={props.fieldName}
      control={props.control}
      defaultValue={props.defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <TextFieldWrapper>
            <StyledLabel id={`${props.fieldName}-employeeSelect`}>
              <label> {props.label}</label>
            </StyledLabel>
            <Select
              disabled={loading}
              labelId={`${props.fieldName}-employeeSelect`}
              id={`${props.fieldName}-employeeSelect`}
              onChange={onChange}
              value={value}
              MenuProps={MenuProps}
              renderValue={(value: unknown) => {
                if (value) {
                  const labels = [];
                  for (let i = 0; i < employeeLabels.length; i++) {
                    if (employeeLabels[i].value === value) {
                      labels.push(`${employeeLabels[i].label}`);
                    }
                  }
                  return labels.join(", ");
                }
              }}
            >
              {employeeLabels.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </Select>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </TextFieldWrapper>
        );
      }}
    />
  );
};

export default EmployeeSelect;
