import React, { useEffect, useState } from "react";
import FileReaderContainer from "./FileReaderContainer";

interface PdfDocumentReaderProps {
  fileName: string;
  close: () => void;
  refs: any;
  children: JSX.Element;
}

const PdfDocumentFrame = (props: PdfDocumentReaderProps): JSX.Element => {
  const [page, setPage] = useState(0);
  const references = props.refs;
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.6,
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callBackFunction, options);
    if (references.current.length > 0) {
      references.current.forEach((ref: any) => {
        observer.observe(ref);
      });
    }
  }, [options, references]);

  const callBackFunction = (entries: any) => {
    const [entry] = entries;
    references.current.forEach((ref: any, index: number) => {
      if (ref == entry.target && entry.isIntersecting) {
        setPage(index + 1);
      }
    });
  };

  return (
    <FileReaderContainer
      closeCallBack={props.close}
      fileName={props.fileName}
      totalPages={props.refs.current.length}
      currentPages={page}
    >
      {props.children}
    </FileReaderContainer>
  );
};

export default PdfDocumentFrame;
