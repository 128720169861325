import React from "react";
import styled from "styled-components";
import Navigation from "./navigation/desktop/Navigation";
import { Route, Switch } from "react-router-dom";
import TopBar from "./topbar";
import Feedback from "./navigation/desktop/Feedback";
import Overview from "./pages/overview/Overview";
import WebSocketClient from "../webSockets/WebSocketClient";
import MenuMobile from "./navigation/mobile/MenuMobile";
import SignIn from "../homepage/signIn/SignIn";
import ConstructionWork from "./pages/underConstruction/ConstructionWork";
import AuthorizationPopup from "./authorization/AuthorizationPopup";
import Companies from "./pages/organisation/companies/Companies";
import Departments from "./pages/organisation/departments/Departments";
import Locations from "./pages/organisation/locations/Locations";
import Employees from "./pages/employees/digitalPersonalFile/Employees";
import EmployeeDetail from "./pages/employees/digitalPersonalFile/detail/EmployeeDetail";
import ErrorBoundary from "../globalComponents/loading/ErrorBoundary";
import AddCompanyWizard from "./pages/organisation/companies/wizard/AddCompanyWizard";
import Absences from "./pages/employees/absences/Absences";
import CompanyDetail from "./pages/organisation/companies/detail/CompanyDetail";
import { ResponsiveContainer } from "../globalComponents/ResponsiveContainer";
import PayrollNew from "./pages/payroll/PayrollNew";
import Documents from "./pages/employees/documents/Documents";
import AddPayrollWizard from "./pages/payroll/wizard/AddPayrollWizard";
import Timetracking from "./pages/employees/timetracking/Timetracking";

const ApplicationContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  left: 0;
  top: 0;
  overflow: hidden;
`;

const Main = styled.div`
  flex: auto;
  display: flex;
  flex-direction: row;
  overflow: scroll;
`;

const Content = styled.div`
  flex: auto;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
`;

export interface StartProps {
  desktopVersion: boolean;
}

const Start = (props: StartProps): React.ReactElement => {
  return (
    <Switch>
      <Route path="/app/login" exact>
        <SignIn />
      </Route>
      <ApplicationContainer>
        <AuthorizationPopup />
        {props.desktopVersion ? <Navigation /> : <MenuMobile />}
        <Main>
          <Content>
            <TopBar />
            <ResponsiveContainer>
              <ErrorBoundary>
                <Switch>
                  <Route path="/app" exact>
                    <Overview />
                  </Route>
                  <Route path="/app/companies" exact>
                    <Companies view="chart" />
                  </Route>
                  <Route path="/app/companies/table" exact>
                    <Companies view="table" />
                  </Route>
                  <Route path="/app/companies/add" exact>
                    <AddCompanyWizard />
                  </Route>
                  <Route path="/app/companies/:id">
                    <CompanyDetail />
                  </Route>
                  <Route path="/app/locations/" exact>
                    <Locations view="map" />
                  </Route>
                  <Route path="/app/locations/table" exact>
                    <Locations view="table" />
                  </Route>
                  <Route path="/app/departments" exact>
                    <Departments view="chart" />
                  </Route>
                  <Route path="/app/departments/table" exact>
                    <Departments view="table" />
                  </Route>
                  <Route path="/app/employees" exact>
                    <Employees view="chart" />
                  </Route>
                  <Route path="/app/employees/table" exact>
                    <Employees view="table" />
                  </Route>
                  <Route path="/app/employees/org" exact>
                    <ConstructionWork />
                  </Route>
                  <Route path="/app/employees/:id">
                    <EmployeeDetail />
                  </Route>
                  <Route path="/app/employees/:id/add" exact>
                    <ConstructionWork />
                  </Route>
                  <Route path="/app/documents" exact>
                    <Documents />
                  </Route>
                  <Route path="/app/documents/table" exact>
                    <Documents />
                  </Route>
                  <Route path="/app/absences" exact>
                    <Absences view={"calendar"} />
                  </Route>
                  <Route path="/app/absences/table" exact>
                    <Absences view={"table"} />
                  </Route>
                  <Route path="/app/timetracking" exact>
                    <Timetracking view={"CALENDAR"} />
                  </Route>
                  <Route path="/app/timetracking/table" exact>
                    <Timetracking view={"TABLE"} />
                  </Route>
                  <Route path="/app/payroll" exact>
                    <PayrollNew />
                  </Route>
                  <Route path="/app/payroll/add" exact>
                    <AddPayrollWizard />
                  </Route>
                  <Route path="/app/reporting" exact>
                    <ConstructionWork />
                  </Route>
                  <Route path="/app/holiday" exact>
                    <ConstructionWork />
                  </Route>
                </Switch>
              </ErrorBoundary>
              <Feedback />
            </ResponsiveContainer>
          </Content>
        </Main>
        <WebSocketClient />
      </ApplicationContainer>
    </Switch>
  );
};

export default Start;
