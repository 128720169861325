import React from "react";
import { FormHelperText, MenuItem, Select } from "@mui/material";
import { Control } from "react-hook-form/dist/types/form";
import { Controller } from "react-hook-form";
import { StyledLabel, TextFieldWrapper } from "../TextFieldController";
import { MenuProps } from "../dynamic/EmployeesSelect";

export interface LabelValuePair {
  label: string;
  value: string | number;
}

interface SelectMenuProps {
  control: Control<any>;
  fieldName: string;
  defaultValue?: number | null | string;
  errorMessage: string;
  menuItems: LabelValuePair[];
  label: string;
}

const SelectMenuInput = (props: SelectMenuProps): React.ReactElement => {
  return (
    <Controller
      name={props.fieldName}
      control={props.control}
      defaultValue={props.defaultValue || ""}
      rules={{ required: "Bitte wählen Sie eine Option." }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <TextFieldWrapper>
            <StyledLabel>
              <label id={`${props.fieldName}-Select`}>{props.label}</label>
            </StyledLabel>
            <Select
              labelId={`${props.fieldName}-Select`}
              id={`${props.fieldName}-Select`}
              onChange={onChange}
              value={value}
              MenuProps={MenuProps}
              error={error !== undefined}
              renderValue={(value: unknown) => {
                const vals = props.menuItems.filter(
                  (item) => item.value == value
                );
                if (vals && vals.length > 0) {
                  return vals[0].label;
                }
              }}
            >
              {props.menuItems.map((item) => {
                return (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
            <>
              {error !== undefined ? (
                <FormHelperText error>{props.errorMessage}</FormHelperText>
              ) : null}
            </>
          </TextFieldWrapper>
        );
      }}
    />
  );
};

export default SelectMenuInput;
