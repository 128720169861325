import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { FormControl, FormHelperText } from "@material-ui/core";
import { Control } from "react-hook-form/dist/types/form";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Typography } from "@mui/material";
import styled from "styled-components";
import { CoreRequest } from "../../../../../config/backend/serviceInstances";

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: spae-between;
  padding: 5px;
`;

interface DocumentProps {
  control: Control<any>;
  fieldName: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      width: "100%",
    },
    selectMenu: {
      maxHeight: 200,
    },
  })
);

interface ParseState {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  data: {
    documentStatus: "CORRECT" | "INCORRECT" | "MANUAL_CHECK" | "NOT_STARTED";
  };
}

const DocumentUploadParser = (props: DocumentProps): JSX.Element => {
  const classes = useStyles();
  const [parseResult] = useState<ParseState>({
    loading: false,
    error: false,
    errorMessage: "",
    data: { documentStatus: "NOT_STARTED" },
  });

  let status = "Es wurde noch keine Datei zur Überprüfung hochgeladen";
  switch (parseResult.data.documentStatus) {
    case "CORRECT":
      status = "Das Zertifikat entspricht den Anforderungen.";
      break;
    case "INCORRECT":
      status = "Das Zertifikat entspricht nicht den Anforderungen.";
      break;
    case "MANUAL_CHECK":
      status =
        "Das Zertifikat wurde überprüft aber nicht alle\n" +
        "                    Felder erkannt. Sie können den Nachweis nochmals in einer\n" +
        "                    besseren Auflösung hochladen oder den Antrag ohne\n" +
        "                    Überprüfung abschicken.";
      break;
    case "NOT_STARTED":
      "Es wurde noch keine Datei zur Überprüfung hochgeladen";
  }
  if (parseResult.loading) status = "Überprüfung läuft...";

  const runParser = (
    file: File | null,
    e: React.FormEvent<HTMLInputElement>
  ) => {
    setData({ ...data, loading: true, error: false });
    e.preventDefault();
    const formData = new FormData();
    const config = {
      params: { employeeId: 1, startDate: "2020-11-14", endDate: "2020-11-15" },
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    if (file !== null) {
      formData.append("file", file);
      CoreRequest.post("/employees/absence/document-check", formData, config)
        .then((response) => {
          console.log(response.data);
          setData({ ...data, loading: false, error: false });
        })
        .catch(() => setData({ ...data, error: true, loading: false }));
    }
  };
  const [data, setData] = useState<{
    file: File | null;
    loading: boolean;
    error: boolean;
  }>({
    file: null,
    loading: false,
    error: false,
  });
  return (
    <Controller
      name={props.fieldName}
      control={props.control}
      render={({ fieldState: {} }) => {
        return (
          <FormControl className={classes.formControl}>
            <input
              type={"file"}
              id={"myFile"}
              onChange={(e) => {
                if (e.target.files !== null) {
                  setData({ ...data, file: e.target.files[0] });
                }
              }}
              onBlur={(e) => runParser(data.file, e)}
            />
            <StatusContainer>
              <Typography variant={"body2"}>Status: </Typography>&nbsp;
              <Typography variant={"caption"}>{status}</Typography>
            </StatusContainer>
            <>
              {parseResult.loading ? (
                <>
                  <CircularProgress />
                </>
              ) : null}
              {parseResult.error !== undefined ? (
                <FormHelperText error>
                  {parseResult.errorMessage}
                </FormHelperText>
              ) : null}
            </>
          </FormControl>
        );
      }}
    />
  );
};

export default DocumentUploadParser;
