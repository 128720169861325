import React from "react";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import SelectMenuInput from "../../../../../../globalComponents/formFields/staticSelect/SelectMenuInput";
import DateFieldRHF from "../../../../../../globalComponents/formFields/dateField/DateFieldRHF";
import { ContentContainer } from "./components/styledComponents";
import SelectBoolean from "../../../../../../globalComponents/formFields/SelectBoolean";

const Lohn = (): React.ReactElement => {
  const {
    control,
    formState: {},
    getValues,
  } = useFormContext();

  const lohn = getValues("lohn");

  return (
    <ContentContainer>
      <Grid container rowSpacing={"28px"}>
        <Grid item xs={6}>
          <DateFieldRHF
            label={"Startdatum"}
            control={control}
            fieldName={"lohn.firstPayroll"}
            defaultValue={""}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectMenuInput
            control={control}
            fieldName={"lohn.cuttingRule"}
            label={"Kürzungsmethode"}
            errorMessage={"Bitte Kürzungsmethode angeben"}
            menuItems={[{ label: "Kalendertäglich", value: "calendar" }]}
            defaultValue={lohn?.cuttingRule}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectMenuInput
            control={control}
            fieldName={"lohn.taxInterval"}
            label={"Anmeldezeitraum"}
            errorMessage={"Bitte Anmeldezeitraum angeben"}
            menuItems={[{ label: "Monatlich", value: "monthly" }]}
            defaultValue={lohn?.taxInterval}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectBoolean
            control={control}
            label={"Neuanlage"}
            fieldName={"lohn.newFounded"}
            errorText={"Bitte angeben."}
            defaultValue={lohn?.newFounded}
          />
        </Grid>
      </Grid>
    </ContentContainer>
  );
};

export default Lohn;
