import React from "react";
import LinkStyled from "../../../../../globalComponents/container/LinkStyled";
import { Typography } from "@mui/material";

interface TableLinkProps {
  startUrl: string;
  customValue?: string;
  endUrl?: string;
  label: string;
}

const TableLink = (props: TableLinkProps): JSX.Element => {
  return (
    <LinkStyled
      to={
        props.startUrl +
        (props.customValue ? props.customValue : "") +
        (props.endUrl ? props.endUrl : "")
      }
    >
      <Typography variant={"subtitle2"}>{props.label}</Typography>
    </LinkStyled>
  );
};

export default TableLink;
