import React from "react";
import { Typography } from "@mui/material";
import ViewContainer from "../../../../globalComponents/container/ViewContainer";
import { Link } from "react-router-dom";
import Icon from "../../../../globalComponents/container/Icon";
import { faColumns, faSitemap } from "@fortawesome/free-solid-svg-icons";
import { PageTitle } from "../../../../globalComponents/PageHeader/PageTitle";

const Documents = (): JSX.Element => {
  return (
    <PageTitle>
      <Typography variant={"h1"}>Dokumente</Typography>
      <ViewContainer>
        <Typography variant={"subtitle2"} marginRight={"10px"}>
          Ansicht:{" "}
        </Typography>
        <br />
        <Link to={"/app/documents"}>
          <Icon icon={faSitemap} />
        </Link>
        <br />
        <Link to={"/app/documents/table"}>
          <Icon icon={faColumns} />
        </Link>
      </ViewContainer>
    </PageTitle>
  );
};

export default Documents;
