import React, { useState } from "react";
import styled from "styled-components";
import IconButtonNew from "../../globalComponents/buttons/IconButtonNew";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ClickAwayListener from "@mui/material/ClickAwayListener";

export const Container = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  z-index: 1;
`;

export const FlapOutContainer = styled.div`
  position: absolute;
  background-color: white;
  width: 460px;
  z-index: 2;
  right: -20px;
  margin-top: 5px;
  border: 1px solid #cccccc;
  border-radius: 4px;
`;

const Flap = (props: {
  icon: IconProp;
  children: JSX.Element[];
}): JSX.Element => {
  const [open, setOpen] = useState(false);

  const children = open ? (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <FlapOutContainer>{props.children}</FlapOutContainer>
    </ClickAwayListener>
  ) : (
    <></>
  );

  return (
    <Container>
      <IconButtonNew
        focused={open}
        onClick={() => setOpen(!open)}
        icon={props.icon}
      />
      {children}
    </Container>
  );
};

export default Flap;
