import React from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LegendChipC = styled.div`
  display: inline-flex;
  align-items: center;
  padding-bottom: 10px;
`;

const LegendSquare = styled.div<{ color: string; riffled: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  background: ${(props) =>
    props.riffled
      ? "repeating-linear-gradient( -45deg, #f5f8fa 3px 10px, #d1d5d6 0px 12px)"
      : props.color};
`;

export interface LegendChipProps {
  label: string;
  color: string;
  icon?: any;
  riffled?: boolean;
}
const LegendChip = (props: LegendChipProps): JSX.Element => {
  return (
    <LegendChipC>
      {props.icon ? (
        <FontAwesomeIcon icon={props.icon} />
      ) : (
        <LegendSquare color={props.color} riffled={!!props.riffled} />
      )}
      <Typography variant={"body1"} marginLeft={"10px"}>
        {props.label}
      </Typography>
    </LegendChipC>
  );
};
export default LegendChip;
