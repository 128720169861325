import React, { useEffect, useState } from "react";
import {
  BottomContainer,
  FormFieldContainer,
  StyledForm,
  UpperContainer,
} from "../../../../../globalComponents/smartOperations/smartCrud/FormFieldReturn";
import AbsenceTypeSelect from "../../../../../globalComponents/formFields/dynamic/AbsenceTypeSelect";
import DateFieldRHF from "../../../../../globalComponents/formFields/dateField/DateFieldRHF";
import { Button } from "@mui/material";
import SmartSlideInCreate from "../../../../../globalComponents/smartOperations/smartCrud/SmartSlideInCreate";
import { useForm } from "react-hook-form";
import { CoreRequest } from "../../../../../config/backend/serviceInstances";
import AbsenceCreationDetails from "./AbsenceCreationDetails";
import EmployeeSelect from "../../../../../globalComponents/formFields/dynamic/EmployeeSelect";
import getErrorMessage, {
  getFirstError,
} from "../../../../../utils/backend-errors";
import { Alert } from "@material-ui/lab";

const AbsenceCreationSlideIn = (props: {
  id: number | boolean;
  confirmAndClose: () => void;
  close: () => void;
}): JSX.Element => {
  const { control, handleSubmit, getValues, watch, unregister } = useForm();
  const absenceTypeId = watch("absenceTypeId");
  const startDate = watch("startDate");
  const endDate = watch("endDate");
  const isStartWithHalf = watch("isStartWithHalf");
  const isEndWithHalf = watch("isEndWithHalf");
  const [apiError, setApiError] = useState<string | null>(null);
  useEffect(() => {
    const d = getValues();
    setabsenceTypesInputs({ ...absenceTypeInputs, loading: true });
    CoreRequest.post("/employees/absence/check", {
      employeeId: props.id !== false ? props.id : d.employeeId,
      startDate: d.startDate,
      endDate: d.endDate,
      absenceTypeId: d.absenceTypeId,
      isStartWithHalf: d.isStartWithHalf,
      isEndWithHalf: d.isEndWithHalf,
    })
      .then((response) =>
        setabsenceTypesInputs({
          data: response.data,
          loading: false,
          error: false,
          errorMessage: "",
        })
      )
      .catch(() =>
        setabsenceTypesInputs({
          ...absenceTypeInputs,
          loading: false,
          error: true,
          errorMessage: "",
        })
      );
  }, [absenceTypeId, startDate, endDate, isEndWithHalf, isStartWithHalf]);

  const [absenceTypeInputs, setabsenceTypesInputs] = useState<{
    loading: boolean;
    error: boolean;
    errorMessage: string;
    data: {
      workContractSelectionRequired: boolean;
      halfDayPossible: boolean;
      approvalRequired: boolean;
      documentRequired: boolean;
      budgetUsed: number;
    };
  }>({
    loading: false,
    error: false,
    errorMessage: "",
    data: {
      workContractSelectionRequired: false,
      halfDayPossible: false,
      approvalRequired: false,
      documentRequired: false,
      budgetUsed: 0,
    },
  });

  const onSubmit = () => {
    const options = absenceTypeInputs.data;
    if (!options.documentRequired) {
      unregister("document");
    }
    if (!options.halfDayPossible) {
      unregister("isStartWithHalf");
      unregister("isEndWithHalf");
    }
    const d = getValues();
    d.employeeId = props.id !== false ? props.id : d.employeeId;

    CoreRequest.post("/employees/absence", d)
      .then(() => {
        console.log("post absence request");
        props.confirmAndClose();
      })
      .catch((error) => setApiError(getFirstError(error)));
  };

  return (
    <SmartSlideInCreate
      type={"CREATE"}
      backdropCallback={() => props.close()}
      postEndpoint={{ url: "", type: "POST" }}
      title={"Abwesenheitsantrag"}
      formFields={[]}
      close={props.close}
      confirmAndClose={props.confirmAndClose}
    >
      <>
        {apiError && (
          <Alert
            severity="error"
            style={{ marginTop: 0, marginBottom: "0.5em" }}
            onClose={() => setApiError(null)}
          >
            {getErrorMessage(apiError)}
          </Alert>
        )}
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <UpperContainer>
            {!props.id ? (
              <FormFieldContainer>
                <EmployeeSelect
                  control={control}
                  fieldName={"employeeId"}
                  label={"Mitarbeiter"}
                />
              </FormFieldContainer>
            ) : (
              <></>
            )}
            <FormFieldContainer>
              <DateFieldRHF
                key={"startDate"}
                label={"Startdatum"}
                control={control}
                fieldName={"startDate"}
                defaultValue={"2021-11-01"}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <DateFieldRHF
                key={"endDate"}
                label={"Enddatum"}
                control={control}
                fieldName={"endDate"}
                defaultValue={"2021-11-01"}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <AbsenceTypeSelect
                control={control}
                fieldName={"absenceTypeId"}
                defaultValue={1}
              />
            </FormFieldContainer>
            <AbsenceCreationDetails
              absenceTypeInputs={absenceTypeInputs}
              control={control}
            />
          </UpperContainer>
          <BottomContainer>
            <Button
              type={"submit"}
              variant={"contained"}
              color={"primary"}
              disabled={absenceTypeInputs.error}
            >
              Absenden
            </Button>
          </BottomContainer>
        </StyledForm>
      </>
    </SmartSlideInCreate>
  );
};

export default AbsenceCreationSlideIn;
