import { format } from "date-fns/fp";

const getDateText = (date: string | null): string => {
  try {
    if (!date) return "";

    return format("dd MMM yyyy")(new Date(date));
  } catch {
    return "";
  }
};

export const convertToDateWithoutTime = (date: Date): string => {
  const selectedDate = new Date(date);
  return `${selectedDate.getUTCFullYear()}-${
    selectedDate.getUTCMonth() + 1 > 9
      ? selectedDate.getUTCMonth() + 1
      : "0" + selectedDate.getUTCMonth() + 1
  }-${
    selectedDate.getUTCDate() > 9
      ? selectedDate.getUTCDate()
      : "0" + selectedDate.getUTCDate()
  }`;
};

export { getDateText };
