import {
  EmployeePersonalData,
  EmployeeSocialData,
  EmployeeTaxData,
} from "../employees/types";
import { CompanyBasicData } from "../../globalComponents/dataCard/utils";
import { EmployeeSalarytypePay, Employment } from "./employments";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { PayrollRequest } from "../../config/backend/serviceInstances";
import { CompanyBasicD } from "./company";

export interface TrialState {
  period: string;
  payslips: Payslip[];
}

export interface Payslip {
  id: number;
  company: CompanyBasicData;
  personal: EmployeePersonalData;
  tax: EmployeeTaxData;
  social: EmployeeSocialData;
  salarytypes: EmployeeSalarytypePay[];
  taxesExpenses: TaxesExpense[];
  socialExpenses: SocialExpense[];
}

export interface TaxesExpense {
  id: string;
  st: string;
  brutto: number;
  church: number;
  solz: number;
  amount: number;
}

export interface SocialExpense {
  id: string;
  sv: string;
  kvBrutto: number;
  rvBrutto: number;
  avBrutto: number;
  pvBrutto: number;
  kvBeitrag: number;
  rvBeitrag: number;
  avBeitrag: number;
  pvBeitrag: number;
  amount: number;
}

export const TrialStateI = {
  period: "",
  payslips: [],
};

export interface CreatePeriod2 {
  company: CompanyBasicD;
  employments: Employment[];
  month: number;
  year: number;
}

export const getPayslips = createAsyncThunk<
  Payslip[],
  { CreatePeriod2: CreatePeriod2 }
>("/period", async (parameter: { CreatePeriod2: CreatePeriod2 }) => {
  const response = await PayrollRequest.post(`/period`, {
    createPeriod2: parameter.CreatePeriod2,
  });

  if (response.status > 299) {
    // Return the known error for future handling
    return response.data.error;
  }

  return response.data as Payslip[];
});
