import styled from "styled-components";
import React, { useEffect } from "react";
import { CoreRequest } from "../../../config/backend/serviceInstances";
import CompanyLogo, { CompanyDescriptionContainer } from "./CompanyLogo";
import { CompanyLabel } from "./CompanySwitch";
import { useUserSelection } from "../../../state/general/selectors";
import { Typography } from "@mui/material";

const LogoContainerWrapper = styled.div<{ oneLogo: boolean }>`
  flex: 0.8;
  display: ${(props) => (props.oneLogo ? "flex" : "grid")};
  grid-template-columns: 80px;
  grid-template-rows: 80px 80px;
  border-radius: 5px;
  margin: 10px 10px 0px 10px;
  overflow: hidden;
`;

const MoreToCome = styled.div`
  width: 100%;
  height: 20px;
  background-color: #f5f8fa;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoContainer = (props: { labels: CompanyLabel[] }): JSX.Element => {
  const userSelection = useUserSelection().companies;
  useEffect(() => {
    CoreRequest.get("/companies/imgIds")
      .then((response) => {
        setLogoUrls(response.data);
      })
      .catch((error) => console.log(error.data));
  }, [props.labels, userSelection]);

  const [logoUrls, setLogoUrls] = React.useState<
    { id: number; companyName: string; imgLink: string }[]
  >([]);

  const Logos = logoUrls.slice(0, 2).map((item) => {
    return (
      <CompanyLogo
        big={logoUrls.length == 1}
        companyId={item.id}
        companyName={item.companyName}
        imgUrl={item.imgLink}
        key={item.id}
      />
    );
  });

  return (
    <LogoContainerWrapper oneLogo={logoUrls.length < 2}>
      {Logos}
      {logoUrls.length > 2 ? (
        <CompanyDescriptionContainer>
          <MoreToCome>
            <Typography variant={"subtitle2"} noWrap>
              +{logoUrls.length - 2}&nbsp;
            </Typography>
            <Typography variant={"body2"} noWrap marginTop={"2px"}>
              weitere
            </Typography>
          </MoreToCome>
        </CompanyDescriptionContainer>
      ) : (
        <></>
      )}
    </LogoContainerWrapper>
  );
};

export default LogoContainer;
