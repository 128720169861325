import styled from "styled-components";
import { colors } from "../../../utils";
import Toolbar from "./Toolbar";

// styles

export const DashboardHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const LogoContainer = styled.div`
  flex: 0.15;
`;

export const TextContainer = styled.div`
  flex: 0.38;
`;

export const NewFeatureContainer = styled.div`
  flex: 0.47;
`;

export const StatusDescription = styled.div`
  height: 100%;
  flex: 0.3;
  padding: 30px 20px 20px 30px;
`;

export const StatusCard = styled.div`
  border: 1px solid #dadce0;
  border-radius: 12px;
  display: flex;
  height: 385px;
  margin-top: 40px;
`;

export const StatusWheelContainer = styled.div`
  flex: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #dadce0;
  padding: 30px 20px 20px 30px;
`;

export const ActivitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.4;
  padding: 30px 10px 20px 30px;
`;

export const Activities = styled.div`
  overflow-y: scroll;
`;

export const EmployeeSubtitle = styled.div`
  h6 {
    font-weight: bold;
  }
`;

export const DateText = styled.span`
  font-weight: bold;
`;

export const NotificationContainer = styled.div`
  border-bottom: 1px solid ${colors.gray14};
  &:first-child {
    margin-top: 10px;
  }
  &:last-child {
    border-bottom: none;
  }
`;

export const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: center;
`;
