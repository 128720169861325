import thunk from "redux-thunk";
import combinedReducer from "./combinedReducer";
import { configureStore } from "@reduxjs/toolkit";
import { DepartmentsState } from "./organization/departments/departments";
import { LocationsState } from "./organization/locations/locations";
import { EmployeeProfilesState } from "./employees/employees/employee";
import { AbsenceState } from "./employees/absences/absence";
import { CompanyDetailState } from "./organization/companies/detail/companyDetail";
import { GeneralState2 } from "./general/general";
import { CompaniesState } from "./organization/companies/companies";
import { EmployeeDetailState } from "./employees/employeeDetail/employeeDetail";
import { PayrollState } from "./payroll/payroll";

export const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(thunk),
});

export interface Store {
  general: GeneralState2;
  companies: CompaniesState;
  companyDetail: CompanyDetailState;
  departments: DepartmentsState;
  locations: LocationsState;
  employees: EmployeeProfilesState;
  employeeDetail: EmployeeDetailState;
  absence: AbsenceState;
  payroll: PayrollState;
}
