import { Button, CircularProgress, TextField, Typography } from "@mui/material";

import React, { FormEvent, useState } from "react";
import Wrapper from "../components/Wrapper";
import FeedbackContainer from "../components/FeedbackContainer";
import { useHistory } from "react-router-dom";
import axios from "axios";
import qs from "query-string";
import { authUrl } from "../../config/globalVariables";
import {
  Validation,
  ValidationI,
  ValidationTypes,
} from "../../globalComponents/formFields/legacyFields/Validation/validation";
import PublicWrapper from "../components/PublicWrapper";

const config = {
  withCredentials: true,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

const config2 = {
  withCredentials: true,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  params: {
    grant_type: "password",
    scope: "any",
  },
  auth: {
    username: "alex",
    password: "alex",
  },
};

const Password: React.FC = (): React.ReactElement => {
  const history = useHistory();
  const validation: ValidationI = { error: false, message: null };
  const passedParameters: any = history.location.state;
  const [formFields, setFormFields] = useState({
    password: { value: "", validation: validation },
  });

  const [formState, setFormState] = useState({
    loading: false,
    error: false,
    message: "",
  });

  //in case of direct routing to /register/password
  if (history.location.state === undefined) {
    history.push("/register/email");
  }

  const setPassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validatePassword = Validation(
      ValidationTypes.PASSWORD_SET,
      formFields.password.value
    );
    if (!validatePassword.error) {
      const body = {
        email: passedParameters.email,
        code: passedParameters.code,
        password: formFields.password.value,
      };

      axios
        .post(authUrl + "/user/set", qs.stringify(body), config)
        .then(() => {
          getToken();
        })
        .catch(() => {
          setFormState({
            error: true,
            message: "Password konnte nicht gesetzt werden.",
            loading: false,
          });
        });
    } else {
      setFormFields({
        password: { ...formFields.password, validation: validatePassword },
      });
    }
  };

  const getToken = () => {
    const formData = {
      username: passedParameters.email,
      password: formFields.password.value,
    };
    axios
      .post(authUrl + "/oauth/token", qs.stringify(formData), config2)
      .then((result) => {
        // Force full refresh of page to get new token and decode roles
        window.location.href = "/app";
      })
      .catch((e) => {
        console.debug(e);
        setFormState({
          error: true,
          message:
            "Das Password wurde gesetzt, doch der Login ist fehlgeschlagen.",
          loading: false,
        });
      });
  };

  return (
    <>
      <PublicWrapper>
        <Wrapper
          onSubmit={(e) => {
            setPassword(e);
          }}
        >
          <Typography variant={"subtitle2"}>
            Bitte setzen Sie Ihr Passwort:
          </Typography>
          <br />
          <TextField
            label={"Passwort"}
            variant={"standard"}
            sx={{ width: "100%" }}
            id={"password"}
            type={"password"}
            autoFocus
            value={formFields.password.value}
            helperText={formFields.password.validation.message}
            error={formFields.password.validation.error}
            onChange={(event) =>
              setFormFields({
                password: {
                  validation: { error: false, message: "" },
                  value: event.target.value,
                },
              })
            }
          />
          <br />
          {formState.loading ? (
            <CircularProgress />
          ) : (
            <Button type={"submit"} variant={"contained"} color={"primary"}>
              Weiter
            </Button>
          )}
        </Wrapper>
        <FeedbackContainer>
          {formState.error ? (
            <>
              <Typography variant={"subtitle2"}>{formState.message}</Typography>
            </>
          ) : (
            <></>
          )}
        </FeedbackContainer>
      </PublicWrapper>
    </>
  );
};

export default Password;
