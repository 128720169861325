import React from "react";
import { FormFieldContainer } from "../../../../../globalComponents/smartOperations/smartCrud/FormFieldReturn";
import { CircularProgress, Typography } from "@mui/material";
import SelectBoolean from "../../../../../globalComponents/formFields/SelectBoolean";
import DocumentUploadParser from "./DocumentUploadParser";
import PluralComponent from "./PluralComponent";

interface AbsenceCreationDetailsProps {
  absenceTypeInputs: {
    loading: boolean;
    error: boolean;
    errorMessage: string;
    data: {
      workContractSelectionRequired: boolean;
      halfDayPossible: boolean;
      approvalRequired: boolean;
      documentRequired: boolean;
      budgetUsed: number;
    };
  };
  control: any;
}

const AbsenceCreationDetails = (
  props: AbsenceCreationDetailsProps
): JSX.Element => {
  if (props.absenceTypeInputs.loading)
    return (
      <FormFieldContainer>
        <CircularProgress />
      </FormFieldContainer>
    );
  if (props.absenceTypeInputs.error)
    return (
      <FormFieldContainer>
        <Typography variant={"subtitle2"}>
          Ein Fehler ist aufgetreten
        </Typography>
      </FormFieldContainer>
    );

  let halfDayPossible = <></>;
  let documentRequired = <></>;

  if (props.absenceTypeInputs.data.documentRequired)
    documentRequired = (
      <>
        <FormFieldContainer>
          <Typography variant={"subtitle2"}>
            Ein Nachweis wird benötigt. Dieser kann der Anfrage jetzt oder auch
            nachträglich hinzugefügt werden.
          </Typography>
        </FormFieldContainer>
        <FormFieldContainer>
          <DocumentUploadParser
            control={props.control}
            fieldName={"document"}
          />
        </FormFieldContainer>
      </>
    );

  if (props.absenceTypeInputs.data.halfDayPossible)
    halfDayPossible = (
      <>
        <FormFieldContainer>
          <SelectBoolean
            control={props.control}
            fieldName={"isStartWithHalf"}
            label={"Starte mit halbem Tag"}
            errorText={"Fehler"}
          />
        </FormFieldContainer>
        <FormFieldContainer>
          <SelectBoolean
            control={props.control}
            fieldName={"isEndWithHalf"}
            label={"Ende mit halbem Tag"}
            errorText={"Fehler"}
          />
        </FormFieldContainer>
      </>
    );

  return (
    <>
      {halfDayPossible}
      <FormFieldContainer>
        <Typography variant={"subtitle2"}>
          Die Dauer beträgt {props.absenceTypeInputs.data.budgetUsed}{" "}
          <PluralComponent
            pluralVersion={"Arbeitstage."}
            singleVersion={"Arbeitstag."}
            plural={(props.absenceTypeInputs.data.budgetUsed as number) !== 1}
          />
        </Typography>
      </FormFieldContainer>
      {documentRequired}
    </>
  );
};

export default AbsenceCreationDetails;
