import styled, { css } from "styled-components";
import { Button, Chip, Paper } from "@material-ui/core";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 7fr 3fr;
  grid-template-rows: auto auto auto;
`;

export const DocumentDetails = styled(Paper)`
  padding: 20px;
`;

export const Details = styled.div`
  padding-top: 20px;
  display: grid;
  grid-template-columns: 3fr 3fr 1fr 1fr;
  grid-gap: 20px;
`;

export const StyledButton = styled(Button)`
  align-self: flex-end;
  height: 38px;
  font-size: 15px;
`;

export const displayInlineBlock = css`
  & > div {
    display: inline-block;
  }
`;

export const EditorContainer = styled(Paper)`
  margin-top: 25px;
  padding: 30px;
  margin-bottom: 60px;
  min-height: 500px;

  .rightAlignedBlock {
    text-align: right;
    ${displayInlineBlock}
  }
  .leftAlignedBlock {
    text-align: left;
    ${displayInlineBlock}
  }
  .centerAlignedBlock {
    text-align: center;
    ${displayInlineBlock}
  }
  .justifyAlignedBlock {
    text-align: justify;
    ${displayInlineBlock}
  }
`;

export const PlaceholderContainer = styled.div`
  width: 100%;
  height: 100%;
  grid-row-start: 1;
  grid-row-end: 4;
  grid-column-start: 2;
  grid-column-end: 3;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const Placeholder = styled(Paper)`
  width: 100%;
  margin: 0 25px;
`;

export const PlaceholderContent = styled.div`
  padding: 20px;
`;

export const ChipList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;

export const StyledChip = styled(Chip)`
  margin-top: 10px;
`;
