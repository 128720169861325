import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import SmartTableDelete from "../smartCrud/SmartTableDelete";
import { HeaderContainer } from "../smartCrud/SmartCrud";
import { FilterContainer, useSmartTableStyles } from "./styles";
import NoRowsOverlay from "./NoRowsOverlay";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import EditIcon from "@material-ui/icons/Edit";
import { Backdrop, Button, Slide, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridFilterItem,
  GridValueGetterParams,
} from "@mui/x-data-grid";

export type ResponseType = {
  loading: boolean;
  data: any[];
  error: boolean;
  errorMessage?: string;
};

export type FilterAction = {
  columnField: string;
  operatorValue: string;
  value: string;
};

type QuickFilter = { label: string; updateAction: FilterAction };

interface SmartTableProps {
  columns: GridColDef[];
  input: ResponseType;
  title?: string;
  deleteEndpoint: string;
  deleteConfirmMessage: string;
  updateInputCallback: () => void;
  createButton?: { label: string; callbackFunc: () => void };
  editIconAction?: { callbackFunc: (r: number) => void };
  quickFilter?: QuickFilter[];
  height?: string;
  hideFooter?: boolean;
  hideActions?: boolean;
}

const SmartTable = (props: SmartTableProps): JSX.Element => {
  const [hoveredRow, setHoweredRow] = useState(0);
  const [deletePopup, setdeletePopup] = useState(false);
  const [filter, setFilter] = useState<GridFilterItem | null>(
    props.quickFilter ? props.quickFilter[0].updateAction : null
  );

  const TableHeader = () => {
    const HeaderRow2 = props.quickFilter ? (
      <FilterContainer>
        {props.quickFilter?.map((item) => {
          return (
            <Button
              key={item.label}
              style={{
                color: "#054D80",
                borderRadius: "20px",
                boxShadow: "none",
                padding: "8px 20px 10px 20px",
                marginRight: "10px",
                marginBottom: "24px",
              }}
              onClick={() => setFilter(item.updateAction)}
            >
              <Typography variant={"subtitle2"}>{item.label}</Typography>
            </Button>
          );
        })}
      </FilterContainer>
    ) : (
      <></>
    );

    if (props.createButton) {
      return (
        <>
          <HeaderContainer>
            <Typography variant={"h3"}>{props.title}</Typography>
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={() => props.createButton?.callbackFunc()}
            >
              {props.createButton?.label}
            </Button>
          </HeaderContainer>
          {HeaderRow2}
        </>
      );
    } else if (props.title) {
      return (
        <>
          <HeaderContainer>
            <Typography variant={"h3"}>{props.title}</Typography>
          </HeaderContainer>
          {HeaderRow2}
        </>
      );
    } else return <>{HeaderRow2}</>;
  };

  const ActionColumn: GridColDef = {
    field: " ",
    headerName: "",
    description: "",
    sortable: false,
    width: 240,
    renderCell: (params: GridValueGetterParams) => (
      <Slide
        in={hoveredRow == (params.getValue(params.id, "id") as number)}
        timeout={{ enter: 500, exit: 0 }}
        direction={"up"}
      >
        <div>
          {props.editIconAction ? (
            <IconButton
              onClick={() =>
                props?.editIconAction?.callbackFunc(
                  params.getValue(params.id, "id") as number
                )
              }
            >
              <EditIcon />
            </IconButton>
          ) : (
            <></>
          )}
          <IconButton onClick={() => setdeletePopup(true)}>
            <DeleteIcon />
          </IconButton>
        </div>
      </Slide>
    ),
  };
  let combinedColumns = props.columns;

  props.hideActions == undefined
    ? (combinedColumns = props.columns.concat(ActionColumn))
    : null;

  const classes = useSmartTableStyles();
  return (
    <div style={{ height: props.height ? props.height : "600px" }}>
      <DataGrid
        classes={classes}
        rows={props.input?.data || []}
        loading={props.input?.loading}
        disableColumnMenu
        columns={combinedColumns}
        pageSize={10}
        onRowOver={(params) => {
          setHoweredRow(params.getValue(params.id, "id") as number);
        }}
        filterModel={{
          items: filter ? [filter] : [],
        }}
        hideFooter={props.hideFooter}
        components={{
          Toolbar: TableHeader,
          NoRowsOverlay: NoRowsOverlay,
          ColumnSortedDescendingIcon: ArrowDropDownIcon,
          ColumnSortedAscendingIcon: ArrowDropUpIcon,
        }}
      />
      {deletePopup ? (
        <Backdrop
          open={deletePopup}
          style={{ zIndex: 6 }}
          onClick={() => setdeletePopup(false)}
        >
          <SmartTableDelete
            id={hoveredRow}
            deleteEndpoint={props.deleteEndpoint}
            close={() => {
              setdeletePopup(false);
            }}
            deleteConfirmMessage={props.deleteConfirmMessage}
            deleteAndClose={() => {
              setdeletePopup(false);
              props.updateInputCallback();
            }}
          />
        </Backdrop>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SmartTable;
