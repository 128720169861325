import React, { useState } from "react";
import { ExpandContainer } from "../../../../../../globalComponents/buttons/ExpandOptionsButton";
import IconButtonNew from "../../../../../../globalComponents/buttons/IconButtonNew";
import { faCommentAlt } from "@fortawesome/pro-solid-svg-icons";
import { ClickAwayListener } from "@mui/material";
import styled from "styled-components";

const MessageContainer = styled.div`
  position: absolute;
  background-color: white;
  z-index: 2;
  right: 2px;
  top: 100%;
  margin-top: 5px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  width: 200px;
  font-size: 15px;
  font-style: italic;
  color: #4c4c4c;
  padding: 20px;
  overflow: hidden;
`;

const CommentView = (props: { message: string }): JSX.Element => {
  const [state, setState] = useState(false);

  return (
    <ExpandContainer>
      <IconButtonNew
        icon={faCommentAlt}
        onClick={() => setState(true)}
        focused={state}
      />
      {state ? (
        <ClickAwayListener onClickAway={() => setState(false)}>
          <MessageContainer>{props.message}</MessageContainer>
        </ClickAwayListener>
      ) : (
        <></>
      )}
    </ExpandContainer>
  );
};

export default CommentView;
