import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { getHighlight, getIsWeekend } from "../utils";
import {
  Container,
  HighlightContainer,
  HighlightText,
  StyledCell,
  StyledHighlightedCell,
  Text,
  TextContainer,
} from "./styles";
import { StyleProps as BorderStyleProps } from "../styles";
import { useWidth } from "../../hooks";
import { defaultTo } from "lodash/fp";
import { StyledTooltip } from "../../../../../../../globalComponents/tooltip";
import {
  AbsenceRequest,
  AbsenceType,
} from "../../../../../../../state/employees/types";

export type OnHighlightClick = (requestId: number) => void;

export type CellProps = BorderStyleProps & {
  calendarDate: Date;
  date: number;
  width: number;
  calendarWidth: number;
  isFirstRow: boolean;
  className?: string;
  requests?: AbsenceRequest[];
  absenceTypes: AbsenceType[];
  getIsHighlightHovered: (id?: number) => boolean;
  onHighlightClick: OnHighlightClick;
  onHighlightHover: Dispatch<SetStateAction<number | undefined>>;
};

const Cell: React.FC<CellProps> = ({
  calendarDate,
  date,
  width,
  calendarWidth,
  className,
  borderLeft,
  requests,
  absenceTypes,
  borderRight,
  getIsHighlightHovered,
  onHighlightClick,
  onHighlightHover,
}) => {
  const [isTextOverflow, setIsTextOverflow] = useState<boolean | undefined>();
  const {
    highlightLabel,
    highlightDuration,
    highlightId,
    ...highlight
  } = getHighlight(calendarDate, date, requests, absenceTypes);

  const textRef = useRef(null);
  const textLength = useWidth(textRef);
  const highlightLength = defaultTo(0)(highlightDuration) * width - width;

  useEffect(() => {
    if (textLength === 0 || isTextOverflow) return;

    setIsTextOverflow(textLength >= highlightLength);
  }, [textLength, isTextOverflow]);

  const onHoverHandler = (id?: number) => () => onHighlightHover(id);

  const onHighlightClickHandler = () =>
    onHighlightClick(defaultTo(0)(highlightId));

  return (
    <Container className={className}>
      <StyledCell
        borderLeft={borderLeft}
        borderRight={borderRight}
        basesize={calendarWidth}
        background={getIsWeekend(date)(calendarDate)}
      />
      <HighlightContainer
        onClick={onHighlightClickHandler}
        onMouseEnter={onHoverHandler(highlightId)}
        onMouseLeave={onHoverHandler()}
      >
        <StyledHighlightedCell
          {...highlight}
          isHovered={getIsHighlightHovered(highlightId)}
        />
        {highlightLabel && (
          <StyledTooltip
            title={highlightLabel}
            disableHoverListener={!isTextOverflow}
            placement="top"
          >
            <TextContainer>
              <HighlightText
                width={highlightLength}
                isTextOverflow={isTextOverflow}
              >
                <Text
                  {...highlight}
                  basesize={calendarWidth}
                  isTextOverflow={isTextOverflow}
                  ref={textRef}
                >
                  {highlightLabel}
                </Text>
              </HighlightText>
            </TextContainer>
          </StyledTooltip>
        )}
      </HighlightContainer>
    </Container>
  );
};

export default Cell;
