import React from "react";
import { Container } from "./styles";
import { Button, MenuItem, Select } from "@mui/material";
import { getOptions, getValue } from "./utils";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

export type MonthPickerProps = {
  width: number;
  date: Date;
  className?: string;
  onChange: (date: Date) => void;
  onIncrease?: () => void;
  onDecrease?: () => void;
};

type OnChangeHandler = SelectInputProps["onChange"];

const MonthPicker: React.FC<MonthPickerProps> = ({
  width,
  date,
  className,
  onChange,
  onIncrease,
  onDecrease,
}) => {
  const onChangeHandler: OnChangeHandler = (e) => {
    const value = e.target.value as number;
    e.preventDefault();
    onChange(new Date(value));
  };

  return (
    <Container className={className} basesize={width}>
      <Button
        onClick={onDecrease}
        sx={{
          height: "40px",
          width: "40px",
          padding: "0px",
          minWidth: "0px",
          marginRight: "10px",
          color: "#4C4C4C",
          borderRadius: "4px",
          fontSize: "16px",
        }}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
      </Button>
      <Select
        variant="outlined"
        key={getValue(date)}
        value={getValue(date)}
        onChange={onChangeHandler}
      >
        {getOptions(date).map(([value, label]) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      <Button
        onClick={onIncrease}
        sx={{
          height: "40px",
          width: "40px",
          padding: "0px",
          minWidth: "0px",
          marginLeft: "10px",
          color: "#4C4C4C",
          borderRadius: "4px",
          fontSize: "16px",
        }}
      >
        <FontAwesomeIcon icon={faAngleRight} />
      </Button>
    </Container>
  );
};

export default MonthPicker;
