import React from "react";
import { Grid, Typography } from "@mui/material";

const EmployeeTaxHeader = (): JSX.Element => {
  return (
    <Grid
      container
      columns={22}
      marginTop={2}
      sx={{ boxShadow: "0px 0px 2px 0px lightgrey", padding: "5px" }}
    >
      <Grid item xs={2}>
        <Typography variant={"body1"}>Steuerdaten</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant={"body1"}>Geburtsdatum</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant={"body1"}>Steuernummer</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant={"body1"}>Stkl.</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"body1"}>Faktor</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"body1"}>Konfession</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"body1"}>Freib. (jährl)</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"body1"}>Freib. (mtl)</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant={"body1"}>DBA</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"body1"}>Midijob</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"body1"}>Steuer-Tage</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"subtitle2"} />
      </Grid>
      <Grid item xs={3}>
        <Typography variant={"subtitle2"}>21.08.1987</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant={"subtitle2"}>75748026397</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant={"subtitle2"}>1</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"subtitle2"}>-</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"subtitle2"}>n.a.</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"subtitle2"}>-</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"subtitle2"}>-</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"subtitle2"}>-</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant={"subtitle2"}>-</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant={"subtitle2"}>30</Typography>
      </Grid>
    </Grid>
  );
};

export default EmployeeTaxHeader;
