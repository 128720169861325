import React, { MouseEventHandler } from "react";
import { EditorState, RichUtils } from "draft-js";
import { Map } from "immutable";
import { PropTypes } from "@material-ui/core";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import {
  getColorKeys,
  getFontKeys,
  getFontSizeKeys,
  removeAllStyles,
  setBlockData,
  TextAlignment as Alignment,
  TEXT_ALIGN,
  toggleStyleFromStyleList,
} from "../utils";

// types
export type ToggleStyle = (
  editorState: EditorState
) => (style: string) => EditorState;

export type OnEditorStateUpdate = (editorState: EditorState) => void;

// button style utils
export const getButtonColor = (isActive: boolean): PropTypes.Color => {
  if (isActive) return "primary";

  return "default";
};

export const getAlignmentButtonColor = (currentTextAlignment: string) => (
  textAlignment: Alignment
): PropTypes.Color => {
  const isAligned = textAlignment === currentTextAlignment;
  const alignmentButtonColor = getButtonColor(isAligned);

  return alignmentButtonColor;
};

export const getInlineStyleButtonColor = (editorState: EditorState) => (
  style: string
): PropTypes.Color => {
  const currentInlineStyle = editorState.getCurrentInlineStyle();
  const inlineStyleActive = currentInlineStyle.has(style);
  const inlineStyleButtonColor = getButtonColor(inlineStyleActive);

  return inlineStyleButtonColor;
};

export const getBlockStyleButtonColor = (editorState: EditorState) => (
  style: string
): PropTypes.Color => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  const blockTypeActive = blockType === style;
  const inlineStyleButtonColor = getButtonColor(blockTypeActive);

  return inlineStyleButtonColor;
};

export const renderButtonIcon = (icon: string): React.ReactElement => {
  if (icon === "BOLD") return <FormatBoldIcon fontSize="small" />;

  if (icon === "UNDERLINE") return <FormatUnderlinedIcon fontSize="small" />;

  if (icon === "unordered-list-item")
    return <FormatListBulletedIcon fontSize="small" />;

  return <FormatItalicIcon fontSize="small" />;
};

// mouse event utils
export const toggleInlineStyle = (editorState: EditorState) => (
  inlineStyle: string
): MouseEventHandler<HTMLButtonElement> => (e): EditorState => {
  e.preventDefault();
  const nextEditorState = RichUtils.toggleInlineStyle(editorState, inlineStyle);

  return nextEditorState;
};

export const toggleBlockStyle = (editorState: EditorState) => (
  blockType: string
) => (): EditorState => {
  const nextEditorState = RichUtils.toggleBlockType(editorState, blockType);

  return nextEditorState;
};

export const onTextAlignmentChange = (editorState: EditorState) => (
  value: string
) => (): EditorState => {
  const nextEditorState = setBlockData(editorState)(
    Map({ [TEXT_ALIGN]: value })
  );

  return nextEditorState;
};

export const toggleFontStyle: ToggleStyle = (editorState) => (font) => {
  const fontStyles = getFontKeys();
  const nextEditorState = toggleStyleFromStyleList(editorState)(fontStyles)(
    font
  );

  return nextEditorState;
};

export const toggleFontSizeStyle: ToggleStyle = (editorState) => (fontSize) => {
  const fontSizeStyles = getFontSizeKeys();
  const nextEditorState = toggleStyleFromStyleList(editorState)(fontSizeStyles)(
    fontSize
  );

  return nextEditorState;
};

export const toggleColorStyle: ToggleStyle = (editorState) => (color) => {
  const colorStyles = getColorKeys();
  const nextEditorState = toggleStyleFromStyleList(editorState)(colorStyles)(
    color
  );

  return nextEditorState;
};

export const onClearHandler = (
  editorState: EditorState
): MouseEventHandler<HTMLButtonElement> => (e) => {
  e.preventDefault();
  const nextEditorState = removeAllStyles(editorState);

  return nextEditorState;
};
