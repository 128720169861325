import styled from "styled-components";
import { ChildLine, OrgChartElementInterface } from "./OrgChartGenerator";
import React from "react";

interface ConnectLinesProps {
  right?: boolean;
  left?: boolean;
  center?: boolean;
}

const ConnectLineC = styled.div<ConnectLinesProps>`
  width: ${(props) => (props.center ? "100%" : "50%")};
  height: 0;
  border-bottom: 1px solid lightgrey;
  margin-right: ${(props) => (props.left ? "auto" : "")};
  margin-left: ${(props) => (props.right ? "auto" : "")};
`;

const ConnectLine = (props: {
  elements: OrgChartElementInterface[];
  currentElement: OrgChartElementInterface;
}): JSX.Element => {
  const indexOfCurrentElement =
    props.elements.indexOf(props.currentElement) + 1;
  const startEnd = [1, props.elements.length];
  return props.elements.length === 1 ? (
    <></>
  ) : (
    <>
      <ConnectLineC
        right={indexOfCurrentElement === 1}
        left={indexOfCurrentElement === props.elements.length}
        center={!startEnd.includes(indexOfCurrentElement)}
      />
      <ChildLine />
    </>
  );
};
export default ConnectLine;
