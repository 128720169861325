import React, { useState } from "react";
import SmartTable from "../../../../../../globalComponents/smartOperations/smartTable/SmartTable";
import { useEmployeeWorkContractWorkSchedule } from "../../../../../../state/employees/employees/selectors";
import WorkScheduleAdd from "./WorkScheduleAdd";
import { Typography } from "@mui/material";
import { GridValueGetterParams } from "@mui/x-data-grid";
import ChipC2 from "../../../../../../globalComponents/chips/ChipC2";

const tableCells = [
  {
    field: "creationDate",
    headerName: "Arbeitszeitmodell",
    width: 250,
    renderCell: (params: GridValueGetterParams) => (
      <Typography variant={"body1"}>
        Arbeitszeitmodell vom {params.getValue(params.id, "creationDate")}
      </Typography>
    ),
  },
  { field: "validFrom", headerName: "Gültig seit", width: 150 },
  {
    field: "type",
    headerName: "Art",
    width: 150,
    renderCell: () => <Typography variant={"body1"}>Kontrolliert</Typography>,
  },
  {
    field: "totalHours",
    headerName: "Location",
    width: 150,
    renderCell: () => <Typography variant={"body1"}>Berlin</Typography>,
  },
  {
    field: "status",
    headerName: "Status",
    width: 110,
    renderCell: () => <ChipC2 color={"#00A76D"} label={"Gültig"} />,
  },
];

const WorkSchedules = (): JSX.Element => {
  const [state, setState] = useState(false);
  const WorkSchedule = useEmployeeWorkContractWorkSchedule();

  if (state) return <WorkScheduleAdd callback={() => setState(false)} />;

  return (
    <>
      <br />
      <SmartTable
        columns={tableCells}
        input={WorkSchedule}
        deleteEndpoint={""}
        createButton={{
          label: "Modell hinzufügen",
          callbackFunc: () => setState(true),
        }}
        title={"Arbeitszeitmodelle"}
        deleteConfirmMessage={
          "Wollen Sie das Arbeitszeitmodell wirklich löschen?"
        }
        updateInputCallback={() => console.log("")}
      />
    </>
  );
};

export default WorkSchedules;
