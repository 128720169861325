import { useSelector } from "react-redux";
import { Store } from "../../store";
import { CompanyDetailTaxState } from "./detail/companyDetailTax";
import { CompanyDetailSocialState } from "./detail/companyDetailSocial";
import { CompanyDetailSocial2State } from "./detail/companyDetailSocial2";
import { AbsencetypeState } from "./detail/absencetypes";
import { SalarytypeState } from "./detail/salarytype";
import { CompanyDetailBasicState } from "./detail/companyDetailBasic";
import { CompanyTableOrgState } from "./companies";
import { CompanyBasicD } from "../../payroll/company";

export const useCompaniesOrgChart = (): CompanyTableOrgState => {
  return useSelector<Store, CompanyTableOrgState>(
    (state) => state.companies.orgView
  );
};

export const useCompaniesTableView = (): CompanyTableOrgState => {
  return useSelector<Store, CompanyTableOrgState>(
    (state) => state.companies.tableView
  );
};

export const useCompanyAbsenceTypes = (): AbsencetypeState => {
  return useSelector<Store, AbsencetypeState>(
    (state) => state.companyDetail.Absencetypes
  );
};

export const useCompanySalaryTypes = (): SalarytypeState => {
  return useSelector<Store, SalarytypeState>(
    (state) => state.companyDetail.Salarytypes
  );
};

export const useCompanyDetailBasic = (): CompanyDetailBasicState => {
  return useSelector<Store, CompanyDetailBasicState>(
    (state) => state.companyDetail.CompanyBasic
  );
};

export const useCompanyBasicPayroll = (): CompanyBasicD => {
  return useSelector<Store, CompanyBasicD>(() => {
    return {
      name: "Testfirma",
      street: "Mittenwalder 5",
      city: "",
      postalCode: 10405,
      entityType: "",
    };
  });
};

export const useCompanyDetailTax = (): CompanyDetailTaxState => {
  return useSelector<Store, CompanyDetailTaxState>(
    (state) => state.companyDetail.CompanyTax
  );
};

export const useCompanyDetailSocial = (): CompanyDetailSocialState => {
  return useSelector<Store, CompanyDetailSocialState>(
    (state) => state.companyDetail.CompanySocial
  );
};

export const useCompanyDetailSocial2 = (): CompanyDetailSocial2State => {
  return useSelector<Store, CompanyDetailSocial2State>(
    (state) => state.companyDetail.CompanySocial2
  );
};
