import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import styled from "styled-components";

const FlapContainer = styled.div<{ selected: boolean }>`
  position: relative;
  color: ${(props) => (props.selected ? "#054D80" : "#131313")};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.selected ? "#E8EFF4" : "white")};
  &:hover {
    background-color: ${(props) => (props.selected ? "#E8EFF4" : "#e8eff4")};
    cursor: pointer;
  }
`;

export const FlapContainerContent = styled.div`
  position: absolute;
  background-color: white;
  width: 250px;
  z-index: 2;
  right: 2px;
  top: 100%;
  margin-top: 5px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 10px 0px 10px 0px;
  overflow: hidden;
`;

interface FlapOutProps {
  mainComponent: JSX.Element;
  children: JSX.Element[];
}

const CustomFlapOut = (props: FlapOutProps): React.ReactElement => {
  const [open, setOpen] = React.useState(false);
  const childrenJSX = open ? (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <FlapContainerContent>{props.children}</FlapContainerContent>
    </ClickAwayListener>
  ) : (
    <></>
  );
  return (
    <FlapContainer selected={open} onClick={() => setOpen(!open)}>
      {props.mainComponent}
      {childrenJSX}
    </FlapContainer>
  );
};

export default CustomFlapOut;
