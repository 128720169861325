import React from "react";
import styled from "styled-components";

const SignInContainer = styled.div`
  position: absolute;
  background-image: url(${"https://www.heavenforeveryone.com/berlin-background.jpg"});
  background-size: cover;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
`;

const LoginBox = styled.div`
width: 20%;
height: 45%;
min-width: 300px;
max-height: 450px;
min-height: 300px;
padding: 20px;
margin: auto;
display: flex;
flex-direction: column;
border-radius: 10px;
background-color: #F5F8FA;
opacity: 0.9;
transition-duration: 1s;
&:hover {
    opacity: 1; 
  }\`;
`;

const LogoContainer = styled.div`
  flex: 0.2;
  justify-content: center;
  margin: auto;
`;

const FormContainer = styled.div`
  flex: 0.8;
`;

interface PublicWrapperProps {
  children: React.ReactNode;
}

const PublicWrapper = (props: PublicWrapperProps): React.ReactElement => {
  return (
    <SignInContainer>
      <LoginBox>
        <LogoContainer>
          <img
            src={"https://docs.heavenhr.com/logo.png"}
            alt={"Logo"}
            style={{
              margin: "auto",
              height: "35px",
              width: "170px",
              marginTop: "5px",
            }}
          />
        </LogoContainer>
        <FormContainer>{props.children}</FormContainer>
      </LoginBox>
    </SignInContainer>
  );
};

export default PublicWrapper;
