import React from "react";
import { Typography } from "@mui/material";
import TimetrackingCalendar from "./calendar/TimetrackingCalendar";
import { faCalendar, faColumns } from "@fortawesome/free-solid-svg-icons";
import ViewContainer from "../../../../globalComponents/container/ViewContainer";
import { Link } from "react-router-dom";
import Icon from "../../../../globalComponents/container/Icon";
import { PageTitle } from "../../../../globalComponents/PageHeader/PageTitle";
import TimetrackingTable from "./TimetrackingTable";

const Timetracking = (props: { view: "CALENDAR" | "TABLE" }): JSX.Element => {
  return (
    <>
      <PageTitle>
        <Typography variant={"h1"}>Zeiterfassung</Typography>
        <ViewContainer>
          <Typography variant={"subtitle2"} marginRight={"10px"}>
            Ansicht:{" "}
          </Typography>
          <br />
          <Link to={"/app/timetracking"}>
            <Icon icon={faCalendar} />
          </Link>
          <br />
          <Link to={"/app/timetracking/table"}>
            <Icon icon={faColumns} />
          </Link>
        </ViewContainer>
      </PageTitle>
      {props.view == "CALENDAR" ? (
        <TimetrackingCalendar />
      ) : (
        <TimetrackingTable />
      )}
    </>
  );
};

export default Timetracking;
