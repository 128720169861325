import React, { useEffect, useState } from "react";
import Absences from "./wizardPages/Absences";
import Timetracking from "./wizardPages/Timetracking";
import { WizardNavigationContainer } from "./wizardPages/components/styledComponents";
import { useMobileVersion } from "../../../../state/general/selectors";
import { createStyles, makeStyles } from "@mui/styles";
import { Button, Stepper, Theme, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import StepTab from "./wizardPages/components/StepTab";
import Trial from "./wizardPages/Trial";
import CompaniesSelect from "../../../../globalComponents/formFields/dynamic/CompaniesSelect";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useCurrentPayrollPeriod } from "../../../../state/payroll/selectors";
import { getCurrentPeriod } from "../../../../state/payroll/periodState";
import { listEmployments } from "../../../../state/payroll/employments";
import { getBasicData } from "../../../../state/organization/companies/detail/companyDetailBasic";
import DigitalPersonalFile from "./wizardPages/DigitalPersonalFile";
import Compensation from "./wizardPages/Compensation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "inline",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
  })
);

const useStylesWizard = makeStyles(() =>
  createStyles({
    root: {
      ".& Mui-active": {
        backgroundColor: "red",
      },
    },
  })
);

function getSteps() {
  return [
    "To-Dos",
    "Absences",
    "Timetracking",
    "Compensations",
    "Trial Period",
  ];
}

export default function AddPayrollWizard(): React.ReactElement {
  const currentPeriod = useCurrentPayrollPeriod();
  const dispatch = useDispatch();
  const { control, watch } = useForm();
  const companyId = watch("companyId");
  useEffect(() => {
    dispatch(getCurrentPeriod({ companyId: companyId }));
    dispatch(
      listEmployments({
        companyUid: "0dba91d8-a421-4119-b9ac-87359016fe07",
        periodStart: "2020-01-01",
        periodEnd: "2020-01-31",
      })
    );
    dispatch(getBasicData(1));
  }, [companyId]);

  const classes = useStyles();
  const [step, setStep] = useState(0);

  const steps = getSteps();
  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return <DigitalPersonalFile />;
      case 1:
        return <Absences />;
      case 2:
        return <Timetracking />;
      case 3:
        return <Compensation />;
      case 4:
        return <Trial />;
      default:
        return "Unknown stepIndex";
    }
  }
  const mobileVersion = useMobileVersion();
  const classesWizard = useStylesWizard();
  return (
    <>
      <Typography variant={"h1"} marginTop={"40px"} marginBottom={"40px"}>
        Lohnabrechnung starten
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "40px",
        }}
      >
        <CompaniesSelect
          control={control}
          fieldName={"companyId"}
          defaultValue={1}
          showLabel={false}
        />
        <FontAwesomeIcon
          icon={faChevronRight}
          color={"#999999"}
          style={{ marginLeft: "20px" }}
        />
        <Typography variant={"h4"} marginLeft={"20px"}>
          {currentPeriod.data.period}
        </Typography>
      </div>
      <div className={classes.root}>
        <Stepper classes={classesWizard}>
          {!mobileVersion
            ? steps.map((label) => (
                <StepTab
                  active={step === steps.indexOf(label)}
                  step={steps.indexOf(label) + 1}
                  title={label}
                  key={label}
                />
              ))
            : steps.map((label) => (
                <StepTab
                  active={step === steps.indexOf(label)}
                  title={label}
                  step={steps.indexOf(label) + 1}
                  key={label}
                />
              ))}
        </Stepper>
        {getStepContent(step)}
        <WizardNavigationContainer>
          <Button
            variant="contained"
            color={"secondary"}
            onClick={() => setStep(step - 1)}
            disabled={step == 0}
          >
            Zurück
          </Button>
          <div>
            <Button
              variant="contained"
              color={"secondary"}
              onClick={() => {
                setStep(step + 1);
              }}
              disabled={step == 0}
            >
              Überspringen
            </Button>
            &nbsp;&nbsp;
            <Button
              variant="contained"
              color="primary"
              type={"submit"}
              onClick={() => {
                setStep(step + 1);
              }}
            >
              Weiter
            </Button>
          </div>
        </WizardNavigationContainer>
      </div>
    </>
  );
}
