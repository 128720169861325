import React, { useState } from "react";
import { Grid, Slide } from "@mui/material";
import CompaniesSelect from "../../../../../../globalComponents/formFields/dynamic/CompaniesSelect";
import { useFormContext } from "react-hook-form";
import SelectMenuInput, {
  LabelValuePair,
} from "../../../../../../globalComponents/formFields/staticSelect/SelectMenuInput";
import entityTypes from "../../../../../../config/selectLabels/entityTypes";
import { ContentContainer } from "./components/styledComponents";
import { SelectButtonWrapper } from "../../../../../../globalComponents/formFields/SelectButton/SelectButtonWrapper";
import SelectButtonProduct from "../../../../../../globalComponents/formFields/SelectButton/SelectButtonProduct";
import EmployeesSelect from "../../../../../../globalComponents/formFields/dynamic/EmployeesSelect";
import TextFieldController, {
  StyledLabel,
  TextFieldWrapper,
} from "../../../../../../globalComponents/formFields/TextFieldController";

const BasicData = (): React.ReactElement => {
  const {
    control,
    formState: {},
    getValues,
  } = useFormContext();
  const [motherCompany, setMotherCompany] = useState(false);
  const basicData = getValues("basicData");
  return (
    <>
      <ContentContainer>
        <Grid container rowSpacing={"28px"}>
          <Grid item xs={6}>
            <TextFieldController
              control={control}
              defaultValue={basicData?.name}
              label={"Firmenname"}
              fieldName={"basicData.name"}
              errorMessage={"Der Firmenname muss mindestens 3 Zeichen haben."}
              rules={{ minLength: 3, required: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldController
              control={control}
              defaultValue={basicData?.name}
              label={"Strasse & Hausnummer"}
              fieldName={"basicData.street"}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldController
              control={control}
              fieldName={"basicData.street"}
              label={"Postleitzahl"}
              defaultValue={basicData?.postalCode}
              small
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldController
              control={control}
              fieldName={"basicData.city"}
              label={"Stadt"}
              defaultValue={basicData?.city}
              small
            />
          </Grid>
          <Grid item xs={6}>
            <EmployeesSelect
              control={control}
              fieldName={"basicData.managingDirectors"}
              label={"Geschäftsführer"}
              defaultValue={
                basicData?.managingDirectors?.length > 0
                  ? [
                      basicData?.managingDirectors?.map(
                        (item: LabelValuePair) => {
                          return item?.value;
                        }
                      ),
                    ]
                  : []
              }
            />
          </Grid>
          <Grid item xs={6}>
            <SelectMenuInput
              control={control}
              fieldName={"basicData.entityType"}
              menuItems={entityTypes}
              errorMessage={"Bitte wählen Sie die Gesellschaftsform aus"}
              label={"Gesellschaftsform"}
              defaultValue={basicData?.entityType}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldWrapper>
              <StyledLabel>
                <label>Übergeordnete Firma</label>
              </StyledLabel>
            </TextFieldWrapper>
            <SelectButtonWrapper>
              <SelectButtonProduct
                selected={motherCompany}
                onClick={() => setMotherCompany(true)}
                label={"Ja"}
              />
              <SelectButtonProduct
                selected={!motherCompany}
                onClick={() => setMotherCompany(false)}
                label={"Nein"}
              />
            </SelectButtonWrapper>
          </Grid>
          {motherCompany ? (
            <Slide direction={"left"} in={motherCompany} timeout={1000}>
              <Grid item xs={6}>
                <CompaniesSelect
                  control={control}
                  fieldName={"basicData.motherCompanyId"}
                  defaultValue={basicData?.motherCompanyId?.value || 0}
                  required={false}
                />
              </Grid>
            </Slide>
          ) : (
            <></>
          )}
        </Grid>
      </ContentContainer>
    </>
  );
};

export default BasicData;
