import { createAction } from "@reduxjs/toolkit";

export enum AccountType {
  EMPLOYEE = "EMPLOYEE",
  HR_MANAGER = "HR_MANAGER",
}

export interface UserSelectionState {
  type: AccountType;
  companies: string[];
}

export const UserSelectionStateI = {
  type: AccountType.HR_MANAGER,
  companies: [],
};

export const updateUserSelection = createAction<UserSelectionState>(
  "todos/add"
);
