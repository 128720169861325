import { createAsyncThunk } from "@reduxjs/toolkit";
import { CoreRequest } from "../../config/backend/serviceInstances";
import { DataState } from "../organization/companies/detail/companyDetail";

export interface EmploymentState extends DataState {
  data: Employment[];
}

export const EmploymentStateI = {
  error: false,
  errorMessage: "",
  loading: false,
  data: [],
};

export interface Employment {
  id: string;
  startDate: string;
  endDate?: string;
  personal: EmployeePersonalPay;
  tax: EmployeeTaxPay;
  social: EmployeeSocialPay;
  absenceRequest: AbsenceRequestPay[];
  isTimetracking: boolean;
  isNightWorker: boolean;
  timetrackingRequests: TimetrackingRequestPay[];
  holidayCalendar: string[];
  workSchedule: WorkSchedulePay | Record<string, never>;
  compensations: CompensationsPay[];
  zuschlaege: ZuschlagPay[];
  salarytypesAdd: EmployeeSalarytypePay[];
  lzz: number;
  sterbeGeld: number;
  vbb: boolean;
}

interface EmployeePersonalPay {
  personal_nr: number;
  first_name: string;
  last_name: string;
  address: string;
  postalCode: string;
  city: string;
}

interface EmployeeTaxPay {
  taxId: number;
  taxClass: number;
  taxFactor: number;
  af: boolean;
  entsch: number;
  jfreib: number;
  jhinzu: number;
  lzzfreib: number;
  lzzhinzu: number;
  religion: number;
  children: boolean;
  childrenAllowance: number;
  birthDate: string;
}

interface EmployeeSocialPay {
  healthInsurer: string;
  svNumber: string;
  employerContribution: boolean;
  privateHealthRate: number;
  privateNurseRate: number;
  stateHealth: boolean;
  statePension: boolean;
}

interface AbsenceRequestPay {
  id: number;
  startDate: string;
  endDate: string;
  startWithHalf: boolean;
  endWithHalf: boolean;
  absenceType: string;
}

interface TimetrackingRequestPay {
  id: number;
  start: string;
  end: string;
}

interface WorkSchedulePay {
  id: number;
  monH: ScheduleDay;
  tueH: ScheduleDay;
  wedH: ScheduleDay;
  thuH: ScheduleDay;
  friH: ScheduleDay;
  satH: ScheduleDay;
  sunH: ScheduleDay;
}

interface ScheduleDay {
  hours: number;
  nightShiftStart?: string;
  nightShiftEnd?: string;
  shiftBreaksSumH?: number;
}

interface CompensationsPay {
  id: number;
  name: string;
  timeInterval: TimeInterval;
  type: CompensationType;
  overtime: boolean;
  amount: number;
  salarytype?: EmployeeSalarytypePay;
}

interface ZuschlagPay {
  id: number;
  name: string;
  percentage: number;
  type: ZuschlagType;
  salarytype: EmployeeSalarytypePay;
}

export interface EmployeeSalarytypePay {
  id: number;
  name: string;
  taxRate?: number;
  st: Versteuerung;
  sv: Sozialversicherung;
  gesamtBrutto: boolean;
  pfaendbarkeit: Pfaendbarkeit;
  factor: Faktor;
  factorValue?: string;
  units?: number;
  amount?: number;
}

enum Versteuerung {
  LAUFEND = "LAUFEND",
  SONSTIG = "SONSTIG",
  PAUSCHAL_AG = "PAUSCHAL_AG",
  PAUSCHAL_AN = "PAUSCHAL_AN",
  MEHRJAEHRIG = "MEHRJAEHRIG",
  FREI = "FREI",
}

enum Sozialversicherung {
  LAUFEND = "LAUFEND",
  SONSTIG = "SONSTIG",
  FREI = "FREI",
}

enum Pfaendbarkeit {
  NICHT_ZULAESSIG = "NICHT_ZULAESSIG",
  VOLL_PFAENDBAR = "VOLL_PFAENDBAR",
  HALBER_UNTERHALT_3_4 = "HALBER_UNTERHALT_3_4",
  NICHT_PFAENDBAR = "NICHT_PFAENDBAR",
  NICHT_UNTERHALT_HALB = "NICHT_UNTERHALT_HALB",
}

enum Faktor {
  STUNDEN = "STUNDEN",
  TAGE = "TAGE",
}

enum ZuschlagType {
  SFN = "SFN",
  OVERTIME = "OVERTIME",
}

enum CompensationType {
  LAUFEND = "LAUFEND",
  SONSTIG = "SONSTIG",
}

enum TimeInterval {
  HOURLY = "HOURLY",
  MONTHLY = "MONTHLY",
  DAILY = "DAILY",
}

export const listEmployments = createAsyncThunk<
  Employment[],
  { companyUid: string; periodStart: string; periodEnd: string }
>(
  "/company/uuid/payroll/employments/2020-01-01/2020-01-31",
  async (parameter: {
    companyUid: string;
    periodStart: string;
    periodEnd: string;
  }) => {
    const response = await CoreRequest.get(
      `/company/${parameter.companyUid}/payroll/employments/${parameter.periodStart}/${parameter.periodEnd}`
    );

    if (response.status > 299) {
      // Return the known error for future handling
      return response.data.error;
    }

    return response.data as Employment[];
  }
);
