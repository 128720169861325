import React, { useEffect } from "react";
import styled from "styled-components";
import LogoContainer from "./LogoContainer";
import { CoreRequest } from "../../../config/backend/serviceInstances";
import { useGeneral2State } from "../../../state/general/selectors";
import { useDispatch } from "react-redux";
import {
  AccountType,
  updateUserSelection,
} from "../../../state/general/userSelection";
import {
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  StyledLabel,
  TextFieldWrapper,
} from "../../../globalComponents/formFields/TextFieldController";

export interface CompanyLabel {
  label: string;
  value: string;
}

const CompanySwitchContainer = styled.div`
  width: auto;
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const SelectorContainer = styled.div<{ displayRoles: boolean }>`
  display: ${(props) => (props.displayRoles ? "inline" : "none")};
  flex: 0.2;
  margin: 5px;
`;

interface SelectorTabProps {
  selected: boolean;
}

const SelectorTab = styled.div<SelectorTabProps>`
  background-color: ${(props) => (props.selected ? "#054D80" : "#EDEDED")};
  color: ${(props) => (props.selected ? "white" : "#043D66")};
  padding: 5px;
  cursor: ${(props) => (props.selected ? "default" : "pointer")};
  margin-top: 10px;
  margin-bottom: 10px;
`;

const TabContainer = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: row;
  align-items: center;
`;

const CompanySwitch: React.FC = () => {
  const { userSelection, auth } = useGeneral2State();
  const dispatch = useDispatch();
  const [labels, setLabels] = React.useState<CompanyLabel[]>([]);
  useEffect(() => {
    if (userSelection.companies.length !== 0) {
      CoreRequest.get("/companies/labels/uu")
        .then((response) => {
          setLabels(response.data);
        })
        .catch(() => {
          setLabels([]);
        });
    }
  }, [auth.roles]);

  const labelsJSX = labels?.map((item) => {
    return (
      <MenuItem key={item.value} value={item.value}>
        <Checkbox
          checked={userSelection.companies.includes(item.value)}
          size={"small"}
        />
        {item.label}
      </MenuItem>
    );
  });
  return (
    <CompanySwitchContainer>
      <LogoContainer labels={labels} />
      <SelectorContainer
        displayRoles={
          auth.roles.HR_MANAGER.length > 1 || auth.roles.EMPLOYEE.length > 1
        }
      >
        <TabContainer
          show={
            auth.roles.HR_MANAGER.length > 1 && auth.roles.EMPLOYEE.length > 1
          }
        >
          <InputLabel id="accesR" shrink>
            Zugänge
          </InputLabel>
          <SelectorTab
            selected={userSelection.type === AccountType.HR_MANAGER}
            onClick={() =>
              dispatch(
                updateUserSelection({
                  type: AccountType.HR_MANAGER,
                  companies: auth.roles.HR_MANAGER,
                })
              )
            }
          >
            <Typography variant={"subtitle2"}>Hr Manager</Typography>
          </SelectorTab>
          {auth.roles.EMPLOYEE.length > 0 ? (
            <SelectorTab
              selected={userSelection.type === AccountType.EMPLOYEE}
              onClick={() =>
                dispatch(
                  updateUserSelection({
                    type: AccountType.EMPLOYEE,
                    companies: auth.roles.EMPLOYEE,
                  })
                )
              }
            >
              <Typography variant={"subtitle2"}>Employee</Typography>
            </SelectorTab>
          ) : null}
        </TabContainer>
        <TextFieldWrapper>
          <StyledLabel>
            <label> Firmenauswahl</label>
          </StyledLabel>
          {userSelection.type === AccountType.HR_MANAGER ? (
            <Select
              value={userSelection.companies}
              style={{ width: 300 }}
              onChange={(e) =>
                (e.target.value as Array<string>).length > 0
                  ? dispatch(
                      updateUserSelection({
                        type: AccountType.HR_MANAGER,
                        companies: e.target.value as Array<string>,
                      })
                    )
                  : null
              }
              multiple
              renderValue={(value: any) => {
                const renderedInput = [];
                for (let i = 0; i < value.length; i++) {
                  renderedInput.push(
                    labels?.filter((item) => item.value == value[i])[0]?.label
                  );
                }
                return renderedInput.join(", ");
              }}
            >
              {labelsJSX}
            </Select>
          ) : null}
          {userSelection.type === AccountType.EMPLOYEE ? (
            <Select
              value={userSelection.companies}
              onChange={(e) =>
                (e.target.value as Array<string>).length > 0
                  ? dispatch(
                      updateUserSelection({
                        type: AccountType.EMPLOYEE,
                        companies: e.target.value as Array<string>,
                      })
                    )
                  : null
              }
              renderValue={(value: unknown) => {
                let renderedInput = "";
                // @ts-ignore
                for (let i = 0; i < value.length; i++) {
                  renderedInput = renderedInput.concat(
                    // @ts-ignore
                    labels?.filter((item) => item.value == value[i])[0]?.label,
                    ", "
                  );
                }
                return renderedInput;
              }}
              multiple
            >
              {labelsJSX}
            </Select>
          ) : null}
        </TextFieldWrapper>
      </SelectorContainer>
    </CompanySwitchContainer>
  );
};

export default CompanySwitch;
