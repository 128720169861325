import styled from "styled-components";

const FeedbackContainer = styled.div`
  flex: 0.05;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: red;
`;

export default FeedbackContainer;
