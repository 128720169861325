import React from "react";
import styled from "styled-components";
import Flap from "./Flap";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import StatusNotification, {
  statusTypeValues,
} from "../../globalComponents/notification/StatusNotification";
import { AbsenceRequest, EmployeeOverview } from "../../state/employees/types";

// types
export type ItemProps = {
  id: number;
  type: statusTypeValues;
  date: string;
  category?: string;
  startDate?: string;
  endDate?: string;
  duration?: number;
  absenceType?: string;
  requestId?: number;
  employee: EmployeeOverview;
  absenceRequest?: AbsenceRequest;
};

export type NotificationFlapProps = {
  items: ItemProps[];
};

// styles
export const Container = styled.div`
  display: flex;
  z-index: 1;
`;

const NotificationFlap: React.FC<NotificationFlapProps> = ({ items }) => {
  const content = items.map((item) => {
    return (
      <StatusNotification
        key={item.id}
        statusType={item.type}
        date={item.date}
        employee={item.employee}
        absenceRequest={item.absenceRequest}
      />
    );
  });

  return <Flap icon={faBell}>{content}</Flap>;
};

export default NotificationFlap;
