import React from "react";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUmbrellaBeach } from "@fortawesome/free-solid-svg-icons";
import { WorkingDay } from "./TimetrackingDayDepartment";
import styled from "styled-components";

const GridItemDayTT = styled.div<{
  size: number;
  height: string;
  backgroundColor: string;
}>`
  height: ${(props) => props.height};
  grid-column-start: span ${(props) => props.size};
  background-color: ${(props) => props.backgroundColor};
  align-items: center;
  border-right: 1px solid #e3e6e8;
  border-bottom: 1px solid #e3e6e8;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #e6e6e6;
    cursor: pointer;
  }
`;

interface TimetrackingDayProps {
  day: WorkingDay;
}

const TimetrackingDay = (props: TimetrackingDayProps): JSX.Element => {
  const day = props.day;
  let color: string;
  if (day.absent) color = "#EEF9F6";
  else color = "white";
  let fontColor = "";
  if (day.nightHours + day.regularHours < day.contractHours)
    fontColor = "#00A76D";
  if (day.nightHours + day.regularHours > day.contractHours)
    fontColor = "#EB8D25";
  return (
    <GridItemDayTT size={1} backgroundColor={color} height={"40px"}>
      {day.absent ? (
        <FontAwesomeIcon icon={faUmbrellaBeach} />
      ) : (
        <>
          <Typography variant={"body2"} color={fontColor}>
            {day.regularHours + day.nightHours}
          </Typography>
          <Typography variant={"caption"} color={fontColor}>
            19&#039;
          </Typography>
        </>
      )}
    </GridItemDayTT>
  );
};

export default TimetrackingDay;
