import React, { useCallback, useEffect, useState } from "react";
import "./LocationCardCSS.css";

type Props = {
  id: number;
  city: string;
  locationName: string;
};

const LocationCard = (props: Props): React.ReactElement => {
  const [state, setState] = useState(false);

  const handleKeyUp = useCallback(() => {
    setState(false);
  }, [setState]);

  useEffect(() => {
    if (state) {
      window.addEventListener("click", handleKeyUp);
    } else {
      window.removeEventListener("click", handleKeyUp);
    }

    return () => window.removeEventListener("click", handleKeyUp);
  }, [state]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="7"
      viewBox="0 0 246 89"
      x={-6}
      y={-5.6}
    >
      <rect id="MapMarker2" width="246" height="89" fill="transparent" />
      <g
        id="LocationCard"
        className={state ? "card" : "cardInvisible"}
        transform="translate(-1609 -289)"
      >
        <g
          id="Card"
          transform="translate(1610 290)"
          fill="#fff"
          stroke="#dadce0"
          strokeWidth="1"
        >
          <rect width="244" height="60" rx="6" stroke="none" />
          <rect
            x="-0.5"
            y="-0.5"
            width="245"
            height="61"
            rx="6.5"
            fill="none"
          />
        </g>
        <text
          id="LocationName"
          transform="translate(1620 321)"
          fill="#131313"
          fontSize="20"
          fontFamily="Roboto-Regular, Roboto"
        >
          {props.city}
        </text>
        <text
          id="CompanyName"
          transform="translate(1620 336)"
          fill="#4c4c4c"
          fontSize="11"
          fontFamily="Roboto-Regular, Roboto"
        >
          {props.locationName}
        </text>
        <g
          id="EditButton"
          className="btn"
          transform="translate(1758 302)"
          onClick={() => console.log("open")}
        >
          <g className="btn-inner" fill="#fff" stroke="#d9d9d9" strokeWidth="1">
            <rect width="38" height="38" rx="4" stroke="none" />
            <rect x="0.5" y="0.5" width="37" height="37" rx="3.5" fill="none" />
          </g>
          <path
            className="btn-icon"
            d="M371.873,170.747l-8.144,8.144-3.344.369a.7.7,0,0,1-.777-.777l.372-3.346L368.122,167Zm2.319-2.32-1.657,1.657-3.75-3.751,1.656-1.656a1.407,1.407,0,0,1,1.991,0l1.76,1.761A1.407,1.407,0,0,1,374.192,168.427Z"
            transform="translate(-347.604 -152.265)"
            fill="#4c4c4c"
          />
        </g>
        <g
          id="DeleteButton"
          className="btn"
          transform="translate(1806 302)"
          onClick={() => console.log("open")}
        >
          <g className="btn-inner" fill="#fff" stroke="#d9d9d9" strokeWidth="1">
            <rect width="38" height="38" rx="4" stroke="none" />
            <rect x="0.5" y="0.5" width="37" height="37" rx="3.5" fill="none" />
          </g>
          <path
            className="btn-icon"
            d="M336.572,119.417v.937a.469.469,0,0,1-.468.469H323.916a.469.469,0,0,1-.469-.469v-.937a.469.469,0,0,1,.469-.469h3.516l.275-.548a.76.76,0,0,1,.625-.39h3.352a.767.767,0,0,1,.628.39l.276.548H336.1A.469.469,0,0,1,336.572,119.417Zm-12.187,2.343h11.25l-.621,9.932a1.425,1.425,0,0,1-1.4,1.318h-7.2a1.426,1.426,0,0,1-1.4-1.318Z"
            transform="translate(-311.447 -106.01)"
            fill="#4c4c4c"
          />
        </g>
      </g>
      <path
        id="MapMarker"
        stroke="#054D80"
        strokeWidth="1"
        onClick={() => setState(!state)}
        d="M7.4,21.556C1.159,12.505,0,11.576,0,8.25a8.25,8.25,0,0,1,16.5,0c0,3.326-1.159,4.255-7.4,13.306a1.032,1.032,0,0,1-1.7,0Zm.848-9.869A3.437,3.437,0,1,0,4.812,8.25,3.437,3.437,0,0,0,8.25,11.687Z"
        transform="translate(114.75 67)"
        fill="#fff"
      />
    </svg>
  );
};

export default LocationCard;
