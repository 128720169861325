import React, { ReactElement } from "react";
import styled from "styled-components";
import { StyledTooltip } from "../../../../globalComponents/tooltip";
import { sizes } from "./utils";

// types
export type BaseStyles = {
  value: number;
  offset: number;
  color: string;
  iconColor: string;
};

export type Styles = Partial<BaseStyles>;

export type SectorProps = BaseStyles & {
  title: string;
  borderColor: string;
  icon: ReactElement;
  isShowTooltip?: boolean;
};

// utils
const getDegrees = (value: number): number => (value / 100) * 360;

const getSectorTrasform = ({ offset = 0 }: Styles) =>
  `transform: translate(0, -50%) rotate(90deg) rotate(${getDegrees(
    offset
  )}deg);`;

const getBackground = ({ color = "transparent" }: Styles) =>
  `background-color: ${color};`;

const getBeforeTrasform = ({ value = 0 }: Styles) =>
  `transform: translate(0, 100%) rotate(${getDegrees(value)}deg);`;

const getDividerHeight = () => {
  const height = sizes.borderWidth / 2;

  return `height: ${height}%;`;
};

const getDividerTransform = ({ offset = 0 }: Styles) => {
  return `transform: translate(50%, 0%) rotate(-90deg) rotate(${getDegrees(
    offset
  )}deg);
  `;
};

const getIconRayTransform = ({ offset = 0, value = 0 }: Styles) => {
  const offsetDeg = getDegrees(offset);
  const valueDeg = getDegrees(value / 2);

  return `transform: translate(50%, 0%) rotate(-90deg) rotate(${offsetDeg}deg) rotate(${valueDeg}deg);`;
};

const getIconTransform = ({ offset = 0, value = 0 }: Styles) => {
  const offsetDeg = getDegrees(offset);
  const valueDeg = getDegrees(value / 2);

  return `transform: rotate(${-offsetDeg}deg) rotate(${valueDeg}deg);`;
};

const getIconColor = ({ iconColor = "initial" }: Styles) =>
  `color: ${iconColor};`;

const getTooltipPlacement = (offset: number) => (value: number) => {
  const offsetDeg = getDegrees(offset);
  const valueDeg = getDegrees(value / 2);
  const iconPlacmentDeg = offsetDeg + valueDeg;

  if (iconPlacmentDeg < 180) return "right";

  return "left";
};

// styles
const Container = styled.div`
  height: inherit;
  width: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`;

const ContentContainer = styled.div`
  width: inherit;
  height: inherit;
  background: transparent;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
`;

const SmallContentContainer = styled(ContentContainer)`
  width: ${100 - sizes.borderWidth}%;
  height: ${100 - sizes.borderWidth}%;
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  transform-origin: 50% 100%;
  overflow: hidden;
  ${getSectorTrasform}

  &:before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    transform-origin: 50% 0%;
    ${getBackground}
    ${getBeforeTrasform}
  }
`;

const Divider = styled.div`
  width: 50%;
  background: white;
  position: absolute;
  z-index: 10;
  transform-origin: 0% 50%;
  ${getDividerHeight}
  ${getDividerTransform}
`;

const IconRay = styled.div`
  pointer-events: none;
  position: absolute;
  width: ${sizes.iconRayWidth}%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 11;
  transform-origin: 0% 50%;
  ${getIconRayTransform}
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: ${sizes.iconFontWidth}%;
  height: ${sizes.iconFontHeight}%;
  transform-origin: 50% 50%;
  ${getIconColor}
  ${getIconTransform} 

  svg {
    width: 100%;
    height: 100%;
  }
`;

// components
const Sector: React.FC<SectorProps> = ({
  title,
  value,
  offset,
  color,
  borderColor,
  icon,
  iconColor,
  isShowTooltip,
}) => {
  return (
    <>
      <Container>
        <ContentContainer>
          <Content value={value} offset={offset} color={borderColor} />
        </ContentContainer>
        <Divider offset={offset} />
        <IconRay offset={offset} value={value}>
          <IconContainer offset={offset} value={value} iconColor={iconColor}>
            <StyledTooltip
              title={title}
              placement={getTooltipPlacement(offset)(value)}
              open={isShowTooltip}
            >
              {icon}
            </StyledTooltip>
          </IconContainer>
        </IconRay>
      </Container>
      <Container>
        <SmallContentContainer>
          <Content value={value} offset={offset} color={color} />
        </SmallContentContainer>
      </Container>
    </>
  );
};

export default Sector;
