export interface ValidationI {
  error: boolean;
  message: null | string;
}

export enum ValidationTypes {
  EMAIL = "EMAIL",
  PHONE_NUMBER = "PHONE",
  LENGTH = "LENGTH",
  PASSWORD_SET = "PASSWORD_SET",
  NOT_EMPTY = "NOT_EMPTY",
}

export const Validation = (
  type: ValidationTypes,
  input: string
): ValidationI => {
  switch (type) {
    case ValidationTypes.EMAIL:
      const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailPattern.test(input.toLowerCase())) {
        return { error: false, message: null };
      } else {
        return {
          error: true,
          message: "Bitte geben Sie eine gültige E-Mail Adresse ein.",
        };
      }
    case ValidationTypes.PHONE_NUMBER:
      if (input.length > 7) {
        return { error: false, message: null };
      } else {
        return {
          error: true,
          message: "Bitte geben Sie eine gültige Telefonnummer ein.",
        };
      }
    case ValidationTypes.PASSWORD_SET:
      const passwordPattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/;
      if (passwordPattern.test(input)) {
        return { error: false, message: null };
      } else {
        return {
          error: true,
          message:
            "Das Passwort muss zwischen 8 und 32 Zeichen haben, einen Klein- und Großbuchstaben sowie eine Ziffer enthalten.",
        };
      }
    case ValidationTypes.LENGTH:
      if (input.length > 7) {
        return { error: false, message: null };
      } else {
        return {
          error: true,
          message: "Das Passwort muss mindestens 8 Zeichen haben.",
        };
      }
    case ValidationTypes.NOT_EMPTY:
      if (input.length > 0) {
        return { error: false, message: null };
      } else {
        return {
          error: true,
          message: "Dieses Feld darf nicht leer bleiben.",
        };
      }
    default:
      return { error: false, message: null };
  }
};
