import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import EmployeeDetailHeader from "./EmployeeDetailHeader";
import { useDispatch } from "react-redux";
import {
  getCurrentEmployee,
  getCurrentEmployeeWorkContracts,
} from "../../../../../state/employees/employeeDetail/employeeDetail";
import { useCurrentEmployee } from "../../../../../state/employees/employeeDetail/selectors";
import EmployeeDetailBody from "./EmployeeDetailBody";
import { Typography } from "@mui/material";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LinkStyled from "../../../../../globalComponents/container/LinkStyled";

const EmployeeContainer = styled.div`
  margin-top: 10px;
`;

const EmployeeDetail = (): React.ReactElement => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  //retrieve employeeDetail
  useEffect(() => {
    dispatch(getCurrentEmployee(Number(id)));
    dispatch(getCurrentEmployeeWorkContracts(Number(id)));
  }, [id]);

  const currentEmployee = useCurrentEmployee();

  return (
    <>
      <Typography variant={"h1"} marginTop={"40px"}>
        Mitarbeiter
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <LinkStyled to={"/app/employees"}>
          <Typography variant={"subtitle2"} marginRight={"5px"}>
            Mitarbeiter
          </Typography>
        </LinkStyled>
        <FontAwesomeIcon icon={faChevronRight} color={"#999999"} size={"xs"} />
        <Typography variant={"body1"} marginLeft={"5px"}>
          {currentEmployee.Basic.data.firstName}{" "}
          {currentEmployee.Basic.data.lastName}
        </Typography>
      </div>
      <EmployeeContainer>
        <EmployeeDetailHeader id={Number(id)} />
        <EmployeeDetailBody id={Number(id)} />
      </EmployeeContainer>
    </>
  );
};

export default EmployeeDetail;
