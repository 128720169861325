import styled from "styled-components";

export const AvatarContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 350px;
  flex-direction: row;
  display: flex;
  align-items: center;
  z-index: 4;
`;
