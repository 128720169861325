import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import { baseUrl } from "../../config/globalVariables";
import {
  Validation,
  ValidationI,
  ValidationTypes,
} from "../../globalComponents/formFields/legacyFields/Validation/validation";
import { SelectButton } from "../../globalComponents/formFields/SelectButton/SelectButton";
import { SelectButtonWrapper } from "../../globalComponents/formFields/SelectButton/SelectButtonWrapper";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 96%;
  min-height: 100%;
  color: white;
  background-image: url(${"https://www.heavenforeveryone.com/background.png"});
  background-size: cover;
  padding-top: 40px;
  padding-left: 2%;
  padding-right: 2%;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const animation = keyframes`
 from {
    opacity: 0.9;
  }

  to {
    opacity: 1;
  }
`;

const RegisterBox = styled.div`
  max-width: 350px;
  min-height: 250px;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid lightgrey;
  padding: 40px;
  padding-bottom: 10px;
  background-color: white;
  opacity: 0.9;
  color: #054d80;
  &:hover {
    animation: ${animation} 1s linear;
    opacity: 1;
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(3),
      },
    },
    root2: {
      root: {
        margin: 0,
        width: "100%",
      },
      input: {
        width: 42,
      },
    },
  })
);

const menuItems = [
  { label: "Recruiting", value: "Recruiting" },
  { label: "Digitale Personalakte", value: "DPF" },
  { label: "Abwesenheiten", value: "Absences" },
  { label: "Zeiterfassung", value: "Timetracking" },
  { label: "Lohn", value: "Payroll" },
  { label: "Sonstiges", value: "Payroll" },
];

const Teaser: React.FC = () => {
  const classes = useStyles();

  const validation: ValidationI = { error: false, message: null };
  const [formFields, setFormFields] = useState({
    email: { value: "", validation: validation },
    companyName: { value: "", validation: validation },
    employees: { value: "keineAuswahl", validation: validation },
    usingHeaven: false,
    happyness: 5,
    checked: false,
  });

  const [formState, setFormState] = useState({
    loading: false,
    error: false,
    message: "",
  });

  const [page, setPage] = useState(1);

  const [value, setValue] = React.useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [inputValue, setInputValue] = React.useState("");

  const submitMe = (
    email: string,
    companyName: string,
    employees: string,
    usingHeaven: boolean,
    happiness: number | number[]
  ) => {
    const validateEmail = Validation(ValidationTypes.EMAIL, email);
    const validateCompanyName = Validation(
      ValidationTypes.NOT_EMPTY,
      companyName
    );

    if (!validateEmail.error && !validateCompanyName.error) {
      setFormState({ ...formState, loading: true });
      axios
        .post(baseUrl + "/community", {
          email: email,
          companyName: companyName,
          employees: employees,
          usingHeaven: usingHeaven,
          focus: value,
          happiness: happiness,
        })
        .then(() => {
          setFormState({ ...formState, loading: false });
          setPage(2);
        })
        .catch(() => {
          setFormState({
            ...formState,
            loading: false,
            error: true,
            message: "Ein Fehler ist beim Abschicken aufgetreten.",
          });
        });
    } else {
      setFormFields({
        ...formFields,
        email: { ...formFields.email, validation: validateEmail },
        companyName: { ...formFields.email, validation: validateCompanyName },
      });
    }
  };
  let returnEmo = <></>;

  switch (formFields.happyness) {
    case 1:
      returnEmo = <>&#129324;</>;
      break;
    case 2:
      returnEmo = <>&#128545;</>;
      break;
    case 3:
      returnEmo = <>&#129327;</>;
      break;
    case 4:
      returnEmo = <>&#128533;</>;
      break;
    case 5:
      returnEmo = <>&#128580;</>;
      break;
    case 6:
      returnEmo = <>&#128528;</>;
      break;
    case 7:
      returnEmo = <>&#128578;</>;
      break;
    case 8:
      returnEmo = <>&#128512;</>;
      break;
    case 9:
      returnEmo = <>&#128515;</>;
      break;
    case 10:
      returnEmo = <>&#128525;</>;
      break;
  }
  return (
    <>
      <Wrapper>
        <Typography variant={"h4"} align={"center"} color={"white"}>
          Unsere Mission ist es, die beste HR-Software im Markt zu entwickeln.
        </Typography>
        <Typography
          variant={"h4"}
          align={"center"}
          marginTop={"20px"}
          color={"white"}
        >
          Zusammen mit Ihnen!
        </Typography>
        <br />
        <Typography variant={"subtitle1"} align={"center"}>
          Registrieren Sie sich jetzt um Teil der Product Community von HeavenHR
          2.0 zu werden.
        </Typography>

        <RegisterBox>
          <img
            src={"https://www.heavenforeveryone.com/heaven_logo.png"}
            alt={"heavenLogo"}
            width={"50%"}
            style={{ marginLeft: "25%" }}
          />
          <p />
          {page == 1 ? (
            <>
              <TextField
                label={"Firmenname"}
                id={"companyName"}
                variant={"standard"}
                sx={{ width: "100%" }}
                error={formFields.companyName.validation.error}
                helperText={formFields.companyName.validation.message}
                onChange={(e) =>
                  setFormFields({
                    ...formFields,
                    companyName: {
                      value: e.target.value as string,
                      validation: { error: false, message: "" },
                    },
                  })
                }
              />
              <p />
              <TextField
                label={"Email"}
                id={"email"}
                variant={"standard"}
                sx={{ width: "100%" }}
                error={formFields.email.validation.error}
                helperText={formFields.email.validation.message}
                onChange={(e) =>
                  setFormFields({
                    ...formFields,
                    email: {
                      value: e.target.value as string,
                      validation: { error: false, message: "" },
                    },
                  })
                }
              />
              <p />
              <InputLabel style={{ fontSize: 12 }}>
                Mitarbeiteranzahl
              </InputLabel>
              <Select
                variant={"standard"}
                sx={{ width: "100%" }}
                value={formFields.employees.value}
                onChange={(e: { target: { value: string } }) =>
                  setFormFields({
                    ...formFields,
                    employees: {
                      value: e.target.value as string,
                      validation: { error: false, message: "" },
                    },
                  })
                }
              >
                <MenuItem value={"keineAuswahl"}>Bitte auswählen</MenuItem>
                <MenuItem value={"0-15"}>0-15 Mitarbeiter</MenuItem>
                <MenuItem value={"16-50"}>16-50 Mitarbeiter</MenuItem>
                <MenuItem value={"51-100"}>51-100 Mitarbeiter</MenuItem>
                <MenuItem value={"101-500"}>101-500 Mitarbeiter</MenuItem>
                <MenuItem value={"501-1000"}>501-1000 Mitarbeiter</MenuItem>
                <MenuItem value={"1000+"}>1000+ Mitarbeiter</MenuItem>
              </Select>
              <p />
              <div className={classes.root}>
                <Autocomplete
                  value={value}
                  onChange={(
                    event: any,
                    newValue: { label: string; value: string }[]
                  ) => {
                    setValue(newValue);
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  multiple
                  id={"focus"}
                  options={menuItems}
                  getOptionLabel={(option) => option.label}
                  defaultValue={[]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant={"standard"}
                      sx={{ width: "100%" }}
                      label="Fokus"
                      placeholder="Fokus"
                    />
                  )}
                />
              </div>
              <p />
              <Typography variant={"subtitle2"}>
                Nutzen Sie bereits HeavenHR?
              </Typography>
              <p />
              <SelectButtonWrapper style={{ maxWidth: 350 }}>
                <SelectButton
                  onClick={() => {
                    setFormFields({ ...formFields, usingHeaven: true });
                  }}
                  selected={formFields.usingHeaven}
                >
                  Ja
                </SelectButton>
                <SelectButton
                  onClick={() => {
                    setFormFields({ ...formFields, usingHeaven: false });
                  }}
                  selected={!formFields.usingHeaven}
                >
                  Nein
                </SelectButton>
              </SelectButtonWrapper>
              {formFields.usingHeaven ? (
                <>
                  <p />
                  <Typography variant={"subtitle2"}>
                    Wie zufrieden sind Sie bisher?
                  </Typography>
                  <p />
                  <div className={classes.root2}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs>
                        <Slider
                          value={formFields.happyness}
                          onChange={(e, newValue: any) =>
                            setFormFields({
                              ...formFields,
                              happyness: newValue,
                            })
                          }
                          aria-labelledby="input-slider"
                          step={1}
                          marks
                          min={1}
                          max={10}
                        />
                      </Grid>
                      <Grid item>{returnEmo}</Grid>
                    </Grid>
                  </div>
                  <br />
                </>
              ) : (
                <></>
              )}
              <p />
              <CheckboxContainer>
                <Checkbox
                  checked={formFields.checked}
                  name="checkedB"
                  color="primary"
                  onClick={() =>
                    setFormFields({
                      ...formFields,
                      checked: !formFields.checked,
                    })
                  }
                />
                <Typography variant={"caption"}>
                  Ich bin damit einverstanden, dass meine Daten von der HeavenHR
                  GmbH verarbeitet werden.{" "}
                </Typography>
              </CheckboxContainer>
              <p />
              {formState.loading ? (
                <CircularProgress />
              ) : (
                <Button
                  variant={"contained"}
                  color={"primary"}
                  style={{ marginLeft: "30%" }}
                  onClick={() => {
                    submitMe(
                      formFields.email.value,
                      formFields.companyName.value,
                      formFields.employees.value,
                      formFields.usingHeaven,
                      formFields.happyness
                    );
                  }}
                  disabled={!formFields.checked}
                >
                  Jetzt beitreten!
                </Button>
              )}

              <p />
            </>
          ) : (
            <>
              <br />
              <Typography variant={"h5"} align={"center"}>
                <ThumbUpIcon />
              </Typography>
              <Typography variant={"h5"} align={"center"}>
                Vielen Dank!
              </Typography>
              <p />
              <Typography variant={"subtitle2"} align={"center"}>
                Wir werden Sie von nun an über die Produktentwicklung sowie
                Events bezüglich HeavenHR-2.0 auf dem Laufenden halten!
              </Typography>
            </>
          )}
        </RegisterBox>
        <Link
          to={"/app/login"}
          color={"primary"}
          style={{ position: "absolute", right: 20, color: "#043D66" }}
        >
          Zum Login für Betatester
        </Link>
      </Wrapper>
    </>
  );
};

export default Teaser;
