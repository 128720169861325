import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import {
  AbsenceFormData,
  showEditOrgRequestForm,
  showEditTableRequestForm,
} from "../../../../store/actions";
import { SelectedCompany } from "./selectors";
import { isNull, isUndefined } from "lodash/fp";
import { getServerUrl } from "../../../../utils";
import { baseUrl } from "../../../../config/globalVariables";
import { CoreRequest } from "../../../../config/backend/serviceInstances";

// types
export type Absence = Record<string, unknown>;

export type Employee = {
  id: number;
};

export type OpenAbsenceForm = (
  dispatch: Dispatch
) => (requestId: number) => void;

export type AbsenceType = {
  value: number;
  label: string;
};

export type ShowThemedFeedback = (
  message: string
) => (dispatch: Dispatch) => () => void;

export type ShowFeedback = (type: string) => ShowThemedFeedback;

// methods
const getAxiosResponseData = ({ data }: AxiosResponse) => data;

const refreshAbsenceRequestsTable = (dispatch: Dispatch) => () =>
  getAbsenceRequests(dispatch);

const closeSlideIn = (dispatch: Dispatch) => () =>
  dispatch({ type: "SLIDE_IN", payload: false });

const showFeedback: ShowFeedback = (type) => (message) => (dispatch) => () =>
  dispatch({
    type: "FEEDBACK",
    payload: {
      type,
      message,
    },
  });

export const showSuccessFeedback: ShowThemedFeedback = showFeedback("success");

export const showErrorFeedback: ShowThemedFeedback = showFeedback("error");

const getEmployee = async (): Promise<Employee> => {
  const employees = await CoreRequest.get(`${baseUrl}/employees/all`);

  return employees.data.shift();
};

export const openOrgAbsenceForm: OpenAbsenceForm = (dispatch) => (requestId) =>
  getEmployee().then(
    showEditOrgRequestForm(
      dispatch,
      "AbsenceForm",
      "Abwesenheitsantrag bearbeiten",
      requestId
    )
  );

export const openTableAbsenceForm: OpenAbsenceForm = (dispatch) => (
  requestId
) =>
  getEmployee().then(
    showEditTableRequestForm(
      dispatch,
      "AbsenceForm",
      "Abwesenheitsantrag bearbeiten",
      updateAbsenceRequest(dispatch)(requestId),
      requestId
    )
  );

export const getSafeSelectedCompany = (
  selectedCompany: SelectedCompany
): number | undefined => {
  if (Array.isArray(selectedCompany))
    return Array.from(selectedCompany).shift();

  return selectedCompany;
};

const isParamMissing = (param: unknown) => isUndefined(param) || isNull(param);

export const areParamsDefined = ({
  startDate,
  endDate,
  absenceTypeId,
  startWithHalf,
  endWithHalf,
}: Partial<AbsenceFormData>): boolean =>
  !isParamMissing(startDate) &&
  !isParamMissing(endDate) &&
  !isParamMissing(absenceTypeId) &&
  !isParamMissing(startWithHalf) &&
  !isParamMissing(endWithHalf);

// network requests
export const getAbsenceRequests = async (dispatch: Dispatch): Promise<void> => {
  const { data: employees } = await CoreRequest.get(`${baseUrl}/employees/all`);
  const { data: absences } = await CoreRequest.get(
    `${baseUrl}/employees/absence`
  );

  const absencesTableData = absences.map((absence: Absence) => {
    const { firstName, lastName, position, imgLink } =
      employees.find(
        (employee: Employee) => employee.id === absence.employeeId
      ) ?? {};

    return { ...absence, firstName, lastName, position, imgLink };
  });

  dispatch({
    type: "SET_ABSENCES_TABLE",
    payload: { data: absencesTableData },
  });
};

export const deleteAbsenceRequest = (dispatch: Dispatch) => (
  absenceRequestId: number
): Promise<void> =>
  CoreRequest.delete(`${baseUrl}/employees/absence`, {
    params: {
      absenceRequestId,
    },
  })
    .then(showSuccessFeedback("The absence request has been deleted")(dispatch))
    .then(refreshAbsenceRequestsTable(dispatch));

export const createAbsenceRequest = (dispatch: Dispatch) => (
  data: AbsenceFormData
): Promise<void> =>
  CoreRequest.post(`${getServerUrl()}/employees/absence`, data)
    .then(showSuccessFeedback("The absence request has been created")(dispatch))
    .then(closeSlideIn(dispatch))
    .then(refreshAbsenceRequestsTable(dispatch));

export const updateAbsenceRequest = (dispatch: Dispatch) => (id: number) => (
  data: AbsenceFormData
): Promise<void> =>
  CoreRequest.put(`${baseUrl}/employees/absence`, { ...data, id })
    .then(showSuccessFeedback("The absence request has been updated")(dispatch))
    .then(closeSlideIn(dispatch))
    .then(refreshAbsenceRequestsTable(dispatch));

export const getCompanyAbsenceRequests = (
  selectedCompany: SelectedCompany
): Promise<AbsenceType[]> =>
  CoreRequest.get(`${baseUrl}/companies/absencetypes/labels`, {
    params: { companyId: getSafeSelectedCompany(selectedCompany) },
  }).then(getAxiosResponseData);

export const getCompanyAbsenceRequestsImplicit = (): Promise<AbsenceType[]> =>
  CoreRequest.get(`${baseUrl}/companies/absencetypes/labels`).then(
    getAxiosResponseData
  );
