import styled from "styled-components";

const BottomContainer = styled.div`
  flex: 0.15;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export default BottomContainer;
