import { useEffect, useState } from "react";
import { OrderedSet } from "immutable";

// types
type OnChange = (value: string) => void;

export type UseSelectedStyleProps = {
  value: string;
  defaultValue: string;
  currentStyle: OrderedSet<string>;
  editorHasFocus: boolean;
  onSelect: () => void;
  onChange: (selected: string) => void;
};

export type UseSelectedStyle = (
  props: UseSelectedStyleProps
) => [string, OnChange];

// hooks
const useSelectedStyle: UseSelectedStyle = ({
  value,
  defaultValue,
  currentStyle,
  editorHasFocus,
  onSelect,
  onChange,
}) => {
  const [selectedStyle, setSelectedStyle] = useState(defaultValue);

  useEffect(() => {
    if (currentStyle.has(selectedStyle)) return;

    onChange(selectedStyle);
  }, [editorHasFocus, selectedStyle]);

  useEffect(() => {
    setSelectedStyle(value);
  }, [value]);

  const onChangeHandler: OnChange = (newValue) => {
    setSelectedStyle(newValue);
    onSelect();
  };

  return [selectedStyle, onChangeHandler];
};

export default useSelectedStyle;
