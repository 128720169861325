import styled from "styled-components";

export const SelectButtonWrapper = styled.div`
  width: 100%;
  max-width: 304px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  overflow: hidden;
`;
