import React from "react";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import styled from "styled-components";

const IconB = styled.button<{
  backgroundColor?: string;
  backgroundColorHover?: string;
}>`
  position: relative;
  width: 40px;
  height: 40px;
  color: #4c4c4c;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 4px;
  font-size: 16px;
  border: none;
  &:hover {
    background-color: ${(props) => props.backgroundColorHover};
    cursor: pointer;
  }
`;

interface CloseButtonProps {
  onClick: () => void;
  icon: IconProp;
  focused?: boolean;
  primary?: boolean;
}

const IconButtonNew = (props: CloseButtonProps): JSX.Element => {
  let backgroundColor, backgroundColorHover: string;
  if (props.primary) {
    backgroundColor = props.focused ? "#033354" : "#054D80";
    backgroundColorHover = props.focused ? "#033354" : "#033354";
  } else {
    backgroundColor = props.focused ? "#cccccc" : "white";
    backgroundColorHover = props.focused ? "#cccccc" : "#f2f2f2";
  }

  return (
    <IconB
      backgroundColorHover={backgroundColorHover}
      backgroundColor={backgroundColor}
      onClick={props.onClick}
    >
      <Typography variant={"subtitle2"}>
        <FontAwesomeIcon
          icon={props.icon}
          color={props.primary ? "white" : "#4C4C4C"}
        />
      </Typography>
    </IconB>
  );
};

export default IconButtonNew;
