import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { defaultDateTo } from "../../../../../../utils";

const timeframeMap: Record<string, string> = {
  HOURLY: "pro Stunde",
  DAILY: "pro Tag",
  MONTHLY: "pro Monat",
};

const zuschlagTypeMap: Record<string, string> = {
  SFN: "SNF-Zuschläge",
  OVERTIME: "Überstunden",
};

export const compensationColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    width: 450,
    editable: true,
  },
  {
    field: "amount",
    headerName: "Wert",
    width: 140,
    editable: true,
    renderCell: (params: GridRenderCellParams) =>
      new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(params.row.amount),
  },
  {
    field: "payoutDate",
    headerName: "Datum",
    width: 200,
    editable: true,
    renderCell: (params: GridRenderCellParams) =>
      defaultDateTo("-", params.row.payoutDate),
  },
  {
    field: "timeInterval",
    headerName: "Häufigkeit",
    width: 150,
    editable: true,
    renderCell: (params: GridRenderCellParams): string => {
      if (!params.row.timeInterval) {
        return "";
      }
      return timeframeMap[params.row.timeInterval];
    },
  },
];

export const zuschlagColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    width: 450,
    editable: true,
  },
  {
    field: "percentage",
    headerName: "Prozent",
    width: 140,
    editable: true,
    renderCell: (params: GridRenderCellParams) => `${params.row.percentage} %`,
  },
  {
    field: "type",
    headerName: "Typ",
    width: 200,
    editable: true,
    renderCell: (params: GridRenderCellParams): string => {
      if (!params.row.type) {
        return "";
      }
      return zuschlagTypeMap[params.row.type];
    },
  },
];
