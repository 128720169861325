import {
  CharacterMetadata,
  CompositeDecorator,
  ContentBlock,
  ContentState,
  convertFromRaw,
  DraftEditorCommand,
  DraftHandleValue,
  DraftStyleMap,
  EditorState,
  Modifier,
  RichUtils,
} from "draft-js";
import { List, Map, Set, Iterable } from "immutable";
import { compose } from "lodash/fp";
import {
  getDefaultColor as getDefaultColorName,
  getColorValues as getColorPickerValues,
  getColorName,
} from "../../../../../../../globalComponents/ColorPicker/utils";
import { OnEditorStateUpdate } from "./EditorControls/utils";
import PlaceholderItem from "./PlaceholderItem";

// types
type Font = { key: string; value: string };

type FontSize = { key: string; value: number };

type Color = { key: string; value: string };

export type Metadata = Map<string, List<string>>;

export type PlaceHolderItem = { id: string; label: string };

export type ChangeInlineStyle = (editorState: EditorState) => EditorState;

export type DecoratorCallback = (start: number, end: number) => void;

export enum TextAlignment {
  left = "left",
  center = "center",
  right = "right",
  justify = "justify",
}

export type PlaceHolderData = {
  blockKey: string;
  start: number;
  end: number;
};

// constants
export const TEXT_ALIGN = "text-align";
export const INLINE_STYLES = ["BOLD", "ITALIC", "UNDERLINE"];
export const BLOCK_TYPES = ["unordered-list-item"];
export const FONT_SIZE_PREFIX = "fontSize";
export const COLOR_KEY_PREFIX = "color";
export const TEXT_ALIGN_POSTFIX = "AlignedBlock";
export const REGEX_DECORATOR = /{id: (\d+), label: [^}]+}/g;
export const PLACEHOLDER_ID = "editorPlaceholderId";
export const ROW_DATA_KEY = "editorRowData";

// font utils
const clearStylesFromEditorState = (
  inlineStyles: List<string>
): ChangeInlineStyle => (editorState) => {
  const currentInlineStyles = editorState
    .getCurrentInlineStyle()
    .filter((s = "") => inlineStyles.includes(s));

  return currentInlineStyles.reduce(
    (state = new EditorState(), s = "") =>
      RichUtils.toggleInlineStyle(state, s),
    editorState
  );
};

const clearStylesFromEditorSelection = (
  inlineStyles: List<string>
): ChangeInlineStyle => (editorState) => {
  const selection = editorState.getSelection();
  const currentContent = editorState.getCurrentContent();
  const newContentState = inlineStyles.reduce(
    (contentState = currentContent, s = "") =>
      Modifier.removeInlineStyle(contentState, selection, s),
    currentContent
  );

  return EditorState.push(editorState, newContentState, "change-inline-style");
};

export const clearInlineStyles = (
  inlineStyles: List<string>
): ChangeInlineStyle => (editorState) => {
  const selection = editorState.getSelection();
  if (selection.isCollapsed())
    return clearStylesFromEditorState(inlineStyles)(editorState);

  return clearStylesFromEditorSelection(inlineStyles)(editorState);
};

const getCurrentInlineStyle = (inlineStyles: List<string>) => (
  editorState: EditorState
) =>
  editorState
    .getCurrentInlineStyle()
    .filter((s = "") => inlineStyles.includes(s))
    .first();

export const getFontsValues = (): List<string> =>
  List(["Arial", "Georgia", "Impact", "Tahoma", "Times New Roman", "Verdana"]);

const getFontKey = (f = "") => f.toUpperCase();

export const getFontKeys = (): List<string> =>
  getFontsValues().map(getFontKey).toList();

export const getFonts = (): List<Font> =>
  getFontsValues()
    .map((f = "") => ({ key: getFontKey(f), value: f }))
    .toList();

export const getCurrentFont = (editorState: EditorState): string => {
  const fontKeys = getFontKeys();
  const currentInlineStyle = getCurrentInlineStyle(fontKeys)(editorState);
  const safeCurrentInlineStyle = currentInlineStyle ?? getDefaultFont();

  return safeCurrentInlineStyle;
};

export const getDefaultFont = (): string => getFontKey("Arial");

export const getFontsSizeValues = (): List<number> =>
  List([8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96]);

const getFontSizeKey = (size = 0) => `${FONT_SIZE_PREFIX}${String(size)}`;

export const getFontSizeKeys = (): List<string> =>
  getFontsSizeValues().map(getFontSizeKey).toList();

export const getFontSizes = (): List<FontSize> =>
  getFontsSizeValues()
    .map((value = getFontsSizeValues().first()) => ({
      key: getFontSizeKey(value),
      value,
    }))
    .toList();

export const getCurrentFontSize = (editorState: EditorState): string => {
  const fontSizeKeys = getFontSizeKeys();
  const currentInlineStyle = getCurrentInlineStyle(fontSizeKeys)(editorState);
  const safeCurrentInlineStyle = currentInlineStyle ?? getDefaultFontSize();

  return safeCurrentInlineStyle;
};

export const getDefaultFontSize = (): string => getFontSizeKey(16);

export const toggleStyleFromStyleList = (editorState: EditorState) => (
  inlineStyles: List<string>
) => (style: string): EditorState => {
  const currentInlineStyle = editorState.getCurrentInlineStyle();

  if (currentInlineStyle.has(style)) {
    return editorState;
  }

  const clearedEditorState = clearInlineStyles(inlineStyles)(editorState);
  const nextEditorState = RichUtils.toggleInlineStyle(
    clearedEditorState,
    style
  );

  return nextEditorState;
};

// color utils
export const getColorValues = (): List<string> => List(getColorPickerValues());

export const getColorKey = (colorValue = ""): string =>
  `${COLOR_KEY_PREFIX}${getColorName(colorValue)}`;

export const getColorKeys = (): List<string> =>
  getColorValues().map(getColorKey).toList();

export const getColors = (): List<Color> =>
  getColorValues()
    .map((value = getColorValues().first()) => ({
      key: getColorKey(value),
      value,
    }))
    .toList();

export const getCurrentColor = (editorState: EditorState): string => {
  const colorKeys = getColorKeys();
  const currentInlineStyle = getCurrentInlineStyle(colorKeys)(editorState);
  const safeCurrentInlineStyle = currentInlineStyle ?? getDefaultColor();

  return safeCurrentInlineStyle;
};

export const getDefaultColor = compose(getColorKey, getDefaultColorName);

export const getColorPickerName = (colorKey: string): string =>
  colorKey.replace(COLOR_KEY_PREFIX, "");

// block level utils
export const blockStyleFn = (block: ContentBlock): string => {
  const textAlign = block.getData().get("text-align");

  if (!textAlign) return "";

  return `${textAlign}${TEXT_ALIGN_POSTFIX}`;
};

export const getSelectedBlocks = (
  editorState: EditorState
): List<ContentBlock> => {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const blockMap = contentState.getBlockMap();

  return blockMap
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat([[endKey, blockMap.get(endKey)]])
    .toList();
};

export const getBlocksMetadata = (blocks: List<ContentBlock>): Metadata => {
  return blocks
    .map((b) => b?.getData())
    .map((d) => d?.map((v) => List([v])))
    .reduce(
      (acc = Map(), v = Map()) =>
        acc.mergeWith(
          (oldVal, newVal) => Set([oldVal, newVal]).flatten().toList(),
          v
        ),
      Map()
    );
};

export const setBlockData = (editorState: EditorState) => (
  blockData: Map<string, string>
): EditorState => {
  const newContentState = Modifier.setBlockData(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    blockData
  );
  return EditorState.push(editorState, newContentState, "change-block-data");
};

export const getCurrentTextAlignment = (editorState: EditorState): string => {
  const selectedBlocks = getSelectedBlocks(editorState);
  const blocksMetadata = getBlocksMetadata(selectedBlocks);
  const textAlignmentList =
    blocksMetadata.get(TEXT_ALIGN) ?? List([TextAlignment.left]);
  const currentTextAlignment = textAlignmentList.first();

  return currentTextAlignment;
};

// chatacter level utils
const getFontsStyleMap = (): Iterable<number, Map<string, unknown>> =>
  getFontsValues().map((style = "") =>
    Map({ [style.toUpperCase()]: Map({ fontFamily: style }) })
  );

const getFontSizeStyleMap = (): Iterable<number, Map<string, unknown>> =>
  getFontsSizeValues().map((value = getFontsSizeValues().first()) =>
    Map({ [getFontSizeKey(value)]: Map({ fontSize: value }) })
  );

const getColorStyleMap = (): Iterable<number, Map<string, unknown>> =>
  getColorValues().map((value = getColorValues().first()) =>
    Map({ [getColorKey(value)]: Map({ color: value }) })
  );

export const getStyleMap = (): DraftStyleMap =>
  getFontsStyleMap()
    .concat(getFontSizeStyleMap())
    .concat(getColorStyleMap())
    .reduce((styleMap = Map(), v = Map()) => styleMap.merge(v), Map())
    .toJS();

export const getCharacters = (
  blocks: List<ContentBlock>
): List<CharacterMetadata> =>
  blocks
    .flatMap<string, CharacterMetadata>((b) => b?.getCharacterList())
    .toList();

export const getCharacterStyles = (
  characters: List<CharacterMetadata>
): List<string> =>
  characters
    .flatMap((c) => c?.getStyle())
    .toSet()
    .map((s) => String(s))
    .toList();

export const getCharacterFontStyles = (fontsKeys: List<string>) => (
  characterStyles: List<string>
): List<string> =>
  characterStyles.filter((style) => fontsKeys.contains(String(style))).toList();

export const getSelectedFontStyle = (characterStyles: List<string>): string =>
  characterStyles.size === 1 ? characterStyles.first() : "";

// PlaceHolder utils
// @todo remove when server endpoint available
export const getPlaceHolderItems = (): PlaceHolderItem[] => [
  { id: "1", label: "First name" },
  { id: "2", label: "Surname" },
  { id: "3", label: "Street" },
  { id: "4", label: "Number" },
  { id: "5", label: "Address addition" },
  { id: "6", label: "ZIP Code" },
  { id: "7", label: "City" },
  { id: "8", label: "State" },
  { id: "9", label: "Country" },
];

export const getPlaceHolderDataList = (
  contentBlock: ContentBlock
): PlaceHolderData[] => {
  const text = contentBlock.getText();
  const regExpMatchArray = Array.from(text.matchAll(REGEX_DECORATOR));

  return regExpMatchArray.map((regExpMatch) => {
    const start = regExpMatch.index ?? 0;
    const end = start + regExpMatch[0].length;

    return { start, end, blockKey: contentBlock.getKey() };
  });
};

export const getPlaceHolder = (
  placeHolderId?: string
): PlaceHolderItem | undefined =>
  getPlaceHolderItems().find(({ id }) => placeHolderId === id);

export const getPlaceHolderDataWithinRange = (
  list: PlaceHolderData[],
  anchorKey: string,
  anchorOffset: number,
  focusOffset: number,
  endOffset = 0
): PlaceHolderData | undefined =>
  list.find(
    ({ blockKey, start, end }) =>
      blockKey === anchorKey &&
      ((anchorOffset <= end + endOffset && anchorOffset >= start) ||
        (focusOffset <= end + endOffset && focusOffset >= start))
  );

export const isPlaceHolderChange = (
  list: PlaceHolderData[],
  editorState: EditorState,
  endOffset = 0
): boolean => {
  const selection = editorState.getSelection();
  const anchorKey = selection.getAnchorKey();
  const anchorOffset = selection.getAnchorOffset();
  const focusOffset = selection.getFocusOffset();
  const placeHolderData = getPlaceHolderDataWithinRange(
    list,
    anchorKey,
    anchorOffset,
    focusOffset,
    endOffset
  );

  return Boolean(placeHolderData);
};

// general utils
export const removeAllStyles = (editorState: EditorState): EditorState => {
  const allInlineStyles = List(INLINE_STYLES)
    .concat(getFontKeys())
    .concat(getFontSizeKeys())
    .concat(getColorKeys())
    .toList();
  const intermediateState = clearInlineStyles(allInlineStyles)(editorState);
  const nextEditorState = setBlockData(intermediateState)(Map());

  return nextEditorState;
};

export const getArrowKeys = (): string[] => [
  "ArrowRight",
  "ArrowLeft",
  "ArrowDown",
  "ArrowUp",
];

export const isArrowKey = (code: string): boolean =>
  getArrowKeys().includes(code);

export const handleKeyCommand = (onUpdate: OnEditorStateUpdate) => (
  command: DraftEditorCommand | string,
  editorState: EditorState
): DraftHandleValue => {
  if (command === "placeholder-edit") {
    // prevent placeholder edit
    return "handled";
  }

  if (command === "placeholder-delete") {
    const selection = editorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const anchorOffset = selection.getAnchorOffset();
    const focusOffset = selection.getFocusOffset();
    const content = editorState.getCurrentContent();
    const currentBlock = content.getBlockForKey(anchorKey);
    const placeHolderDataList = getPlaceHolderDataList(currentBlock);
    const placeHolderData = getPlaceHolderDataWithinRange(
      placeHolderDataList,
      anchorKey,
      anchorOffset,
      focusOffset,
      1
    );
    const newAnchorOffset = Math.min(
      placeHolderData?.start ?? anchorOffset,
      selection.getIsBackward() ? focusOffset : anchorOffset
    );
    const newFocusOffset = Math.max(
      placeHolderData?.end ?? focusOffset,
      selection.getIsBackward() ? anchorOffset : focusOffset
    );
    const newSelection = selection.merge({
      focusOffset: newFocusOffset,
      anchorOffset: newAnchorOffset,
      isBackward: false,
    });
    const newContent = Modifier.removeRange(content, newSelection, "forward");
    const nextEditorState = EditorState.push(
      editorState,
      newContent,
      "remove-range"
    );
    onUpdate(nextEditorState);

    return "handled";
  }

  return "not-handled";
};

// @todo remove when server endpoint available
export const getContentStateFromLocalStorage = (): ContentState | null => {
  const rawDataStr = localStorage.getItem(ROW_DATA_KEY);

  if (rawDataStr === null) return null;

  const rawData = JSON.parse(rawDataStr);

  return convertFromRaw(rawData);
};

const decoratorStratedy = (
  contentBlock: ContentBlock,
  callback: DecoratorCallback
): void =>
  getPlaceHolderDataList(contentBlock).forEach(({ start, end }) =>
    callback(start, end)
  );

export const compositeDecorator = new CompositeDecorator([
  {
    strategy: decoratorStratedy,
    component: PlaceholderItem,
  },
]);

export const createEditorState = (): EditorState => {
  const contentState = getContentStateFromLocalStorage();

  if (!contentState) return EditorState.createEmpty(compositeDecorator);

  return EditorState.createWithContent(contentState, compositeDecorator);
};
