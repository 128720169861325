import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import Homepage from "./homepage/Homepage";
import Start from "./product/Start";
import CenteredLoadingIndicator from "./globalComponents/loading/CenteredLoadingIndicator";
import { globalTheme, useStyles } from "./config/themes/themes";
import { ThemeProvider, useMediaQuery } from "@mui/material";
import { AuthRequest } from "./config/backend/serviceInstances";
import { AuthEndpoints } from "./config/backend/endpointsAuth";
import { config } from "./state/general/auth";
import { setUpResponseInterceptor } from "./product/authorization/setAuthInterceptor";
import { decodeToken } from "./homepage/decodeToken";
import { setHeaders } from "./state/general/setHeaders";
import { useDispatch } from "react-redux";
import general from "./state/general/general";
import { useTranslation } from "react-i18next";

const App: React.FC = () => {
  //classes has to stay there, otherwise theme breaks
  const { t } = useTranslation("common");
  const classes = useStyles();
  const desktopVersion = useMediaQuery("(min-aspect-ratio: 8/5)");
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    AuthRequest.get(AuthEndpoints.CHECK_SESSION, config)
      .then((result) => {
        if (result.data.session) {
          setUpResponseInterceptor(
            result.data.accessToken,
            result.data.refreshToken
          );
          const roles = decodeToken(result.data.accessToken);
          if (roles.EMPLOYEE.length > 0) {
            setHeaders(roles.EMPLOYEE, roles.ALL_ROLES);
          } else if (roles.HR_MANAGER.length > 0) {
            setHeaders(roles.HR_MANAGER, roles.ALL_ROLES);
          }
          dispatch(
            general.actions.adjustRoles({
              roles: roles,
              refreshToken: result.data.refreshToken,
              session: result.data.session,
            })
          );
          history.push(
            history.location.pathname === "/app/login"
              ? "/app"
              : history.location.pathname
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  });

  if (loading) {
    return <CenteredLoadingIndicator minHeight={"500px"} />;
  }

  return (
    <ThemeProvider theme={globalTheme}>
      <BrowserRouter>
        <Suspense fallback={<CenteredLoadingIndicator minWidth={"500px"} />}>
          <Switch>
            <Route path={"/app"}>
              <Start desktopVersion={desktopVersion} />
            </Route>
            <Route path={"/"}>
              <Homepage />
            </Route>
          </Switch>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
