import React from "react";
import BusinessIcon from "@material-ui/icons/Business";
import styled from "styled-components";
import { baseUrl } from "../../../config/globalVariables";
import LinkStyled from "../../../globalComponents/container/LinkStyled";
import { makeStyles } from "@mui/styles";
import { Fade, Typography } from "@mui/material";

interface NLFProps {
  big: boolean;
  companyId: number;
  companyName: string;
  imgUrl: string | null;
}

const LogoContainer = styled.div<{ big: boolean }>`
  display: flex;
  flex-direction: row;
  margin: auto;
`;

export const CompanyDescriptionContainer = styled.div`
  width: 200px;
  padding: 10px;
`;

const ImageContainer = styled.div<{ big: boolean }>`
  width: 69px;
  height: 69px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageS = styled.img`
  width: 100%;
  height: auto;
  border-radius: 5px;
  overflow: hidden;
`;

const useStyles = makeStyles({
  // style rule
  big: { fontSize: 60, color: "#043D66" },
});

const CompanyLogo = (props: NLFProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Fade in timeout={1200}>
      <LogoContainer big={props.big}>
        <ImageContainer big={props.big}>
          {props.imgUrl ? (
            <ImageS
              src={`${baseUrl}/companies/pic/${props.imgUrl}`}
              alt={"Das Logo konnte nicht geladen werden."}
              width={80}
              height={80}
              key={props.companyId}
            />
          ) : (
            <BusinessIcon className={classes.big} />
          )}
        </ImageContainer>
        <CompanyDescriptionContainer>
          <Typography
            variant={"subtitle2"}
            style={{ textTransform: "uppercase" }}
          >
            {props.companyName}
          </Typography>
          <LinkStyled to={"/app/companies/" + props.companyId}>
            <Typography variant={"subtitle2"} noWrap>
              zum Firmenprofil
            </Typography>
          </LinkStyled>
        </CompanyDescriptionContainer>
      </LogoContainer>
    </Fade>
  );
};

export default CompanyLogo;
