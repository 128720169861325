import React, { MouseEventHandler } from "react";
import styled from "styled-components";

// types
export type DropZone = {
  onDragEnter?: MouseEventHandler<HTMLDivElement>;
  onDragLeave?: MouseEventHandler<HTMLDivElement>;
  onDragOver?: MouseEventHandler<HTMLDivElement>;
  onDrop?: MouseEventHandler<HTMLDivElement>;
};

// styles
export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

// components
const DropZone: React.FC<DropZone> = ({
  children,
  onDragEnter,
  onDragLeave,
  onDragOver,
  onDrop,
}) => {
  const preventDefault: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter: MouseEventHandler<HTMLDivElement> = (e) => {
    preventDefault(e);
    if (onDragEnter) onDragEnter(e);
  };
  const handleDragLeave: MouseEventHandler<HTMLDivElement> = (e) => {
    preventDefault(e);
    if (onDragLeave) onDragLeave(e);
  };
  const handleDragOver: MouseEventHandler<HTMLDivElement> = (e) => {
    preventDefault(e);
    if (onDragOver) onDragOver(e);
  };
  const handleDrop: MouseEventHandler<HTMLDivElement> = (e) => {
    preventDefault(e);
    if (onDrop) onDrop(e);
  };

  return (
    <Container
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      {children}
    </Container>
  );
};

export default DropZone;
