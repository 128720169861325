import React, { useEffect, useState } from "react";
import SlideInFrame, {
  ButtonContainer,
} from "../../../../../globalComponents/tables/components/SlideInFrame";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { CoreRequest } from "../../../../../config/backend/serviceInstances";
import { FormOverflow } from "../../../organisation/companies/detail/salarytypes/SalarytypesSlideIn";
import {
  AbsenceRequest,
  ApprovalStatus,
  DocumentStatus,
} from "../../../../../state/employees/types";
import SelectNew from "../../../../../globalComponents/formFields/dynamic/SelectNew";
import DateFieldRHF from "../../../../../globalComponents/formFields/dateField/DateFieldRHF";
import SelectBoolean from "../../../../../globalComponents/formFields/SelectBoolean";
import FileInput from "../../../../../globalComponents/formFields/FileInput/FIleInput";

interface AbsenceCheckResponse {
  employeeId: number;
  absenceTypeId: number;
  startDate: string;
  endDate: string;
  workContractSelectionRequired: boolean;
  halfDayPossible: boolean;
  approvalRequired: boolean;
  documentRequired: boolean;
  budgetUsed: number;
  overlappingAbsenceRequests: AbsenceRequest[];
}

const AbsenceCreationSlideIn = (props: {
  variant: "EDIT" | "CREATE";
  absenceRequest?: AbsenceRequest;
  title: string;
  confirmCallBack: () => void;
  closeCallBack: () => void;
  companyId: number;
  id?: number | null;
}): JSX.Element => {
  const { control, handleSubmit, watch, getValues } = useForm();
  const [checkResponse, setCheckResponse] = useState<{
    loading: boolean;
    error: boolean;
    errorMessage: string;
    data: AbsenceCheckResponse;
  }>({
    loading: false,
    error: false,
    errorMessage: "",
    data: {
      employeeId: 0,
      absenceTypeId: 0,
      startDate: "0",
      endDate: "0",
      workContractSelectionRequired: false,
      halfDayPossible: true,
      approvalRequired: true,
      documentRequired: true,
      budgetUsed: 0,
      overlappingAbsenceRequests: [],
    },
  });
  const { absenceTypeId, employeeId, startDate, endDate } = watch();

  useEffect(() => {
    if (absenceTypeId != 0 && employeeId != 0) {
      setCheckResponse({ ...checkResponse, loading: true });
      CoreRequest.post("/employees/absence/check", {
        employeeId: employeeId,
        absenceTypeId: absenceTypeId,
        startDate: startDate,
        endDate: endDate,
      }).then((response) =>
        setCheckResponse({
          data: response.data,
          error: false,
          loading: false,
          errorMessage: "",
        })
      );
    }
  }, [absenceTypeId, employeeId, startDate, endDate]);

  const onSubmit = (d: any) => {
    if (props.variant == "CREATE") {
      const formData: FormData = new FormData();
      formData.append("file", d["documents"][0]);
      delete d["documents"];

      CoreRequest.post("/employees/absence", d).then((response) => {
        if (getValues("documents") !== "") {
          CoreRequest.post("/absence/documents/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            params: { absenceRequestId: response.data.id },
          }).then(() => props.confirmCallBack());
        } else props.confirmCallBack();
      });
    } else if (props.variant == "EDIT" && props.id !== null) {
      d["id"] = props.id;
      CoreRequest.patch("/companies/salarytypes", d, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(() => props.confirmCallBack());
    }
  };

  const DefaultValues: AbsenceRequest =
    props.absenceRequest !== undefined
      ? props.absenceRequest
      : {
          id: 0,
          startDate: new Date().toDateString(),
          endDate: new Date().toDateString(),
          startWithHalf: false,
          endWithHalf: false,
          duration: 1,
          comment: "",
          documentStatus: DocumentStatus.NOT_REQUIRED,
          documents: [],
          absenceType: {
            id: 0,
            description: "Holiday",
            companyId: 0,
            approvalRequired: false,
            halfDayPossible: false,
            budgetRequired: true,
            documentRequired: false,
          },
          approvalStatus: ApprovalStatus.PLANNED,
          workContract: {
            id: 1,
            startDate: "",
            endDate: "",
            position: "",
            status: "",
            personalNr: 0,
            locationName: "",
            lastName: "",
            firstName: "",
            imgLink: "",
            employeeId: 0,
            departmentName: "",
            departmentId: 0,
            creationDate: "",
            companyId: 0,
            company: {
              id: 0,
              imgLink: "",
              managingDirectors: [],
              motherCompany: null,
              city: "",
              children: [],
              activeEmployees: null,
              entityType: "",
              company: { label: "", value: "" },
            },
          },
        };

  return (
    <SlideInFrame
      title={props.title}
      confirmCallBack={props.confirmCallBack}
      closeCallBack={props.closeCallBack}
    >
      <FormOverflow onSubmit={handleSubmit(onSubmit)}>
        <br />
        <SelectNew
          control={control}
          fieldName={"employeeId"}
          label={"Mitarbeiter"}
          placeholder={"Bitte auswählen"}
          labelsEndpoint={`/employees/labels`}
        />
        <br />
        <SelectNew
          control={control}
          fieldName={"absenceTypeId"}
          label={"Abwesenheitsart"}
          placeholder={"Bitte auswählen"}
          labelsEndpoint={`/companies/absencetypes/labels?companyId=${props.companyId}`}
        />
        <br />
        <DateFieldRHF
          fieldName={"startDate"}
          label={"Start"}
          control={control}
          openCalendar={"LEFT"}
        />
        <br />
        <DateFieldRHF
          fieldName={"endDate"}
          label={"Ende (optional)"}
          control={control}
          openCalendar={"LEFT"}
        />
        <br />
        <SelectBoolean
          control={control}
          label={"Beginn mit halbem Tag"}
          fieldName={"isStartWithHalf"}
          errorText={"Bitte auswählen"}
        />
        <br />
        <SelectBoolean
          control={control}
          label={"Ende mit halbem Tag"}
          fieldName={"isEndWithHalf"}
          errorText={"Bitte auswählen"}
        />
        <br />
        <FileInput
          control={control}
          label={"Nachweis"}
          fieldName={"documents"}
          defaultValue={""}
        />
        <br />
        <ButtonContainer>
          <Button variant={"contained"} color={"primary"} type={"submit"}>
            Bestätigen
          </Button>
        </ButtonContainer>
      </FormOverflow>
    </SlideInFrame>
  );
};

export default AbsenceCreationSlideIn;
