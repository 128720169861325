import React, { ChangeEventHandler, DragEventHandler, useRef } from "react";
import styled from "styled-components";
import mammoth from "mammoth";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { Snackbar, Typography } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import { colors } from "../../../../../../../utils";
import { OnEditorStateUpdate } from "./EditorControls/utils";
import { compositeDecorator } from "./utils";
import DropZone from "../../../../../../../globalComponents/DropZone";

// types
export type HandleOpen = (msg: string) => (severity: Color) => () => void;

export type HandleClose = () => void;

export type UseAlert = () => {
  message: string;
  open: boolean;
  severity: Color;
  handleOpen: HandleOpen;
  handleClose: HandleClose;
};

export type DocumentLoadProps = {
  onEditorStateUpdate: OnEditorStateUpdate;
};

// utils
const convertBufferToHtml = (arrayBuffer: any) => {
  if (!arrayBuffer) return Promise.reject();

  return mammoth.convertToHtml({ arrayBuffer });
};

const convertHtmlToEditorState = (result: any) => {
  const html = result.value;
  if (!html) Promise.reject();

  const blocksFromHTML = convertFromHTML(html);
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  const newState = EditorState.createWithContent(
    contentState,
    compositeDecorator
  );
  return Promise.resolve(newState);
};

export const convertDocumentToEditorState = (
  updateEditor: OnEditorStateUpdate
) => (handleOpen: HandleOpen) => (loadEvent: ProgressEvent<FileReader>): void =>
  convertBufferToHtml(loadEvent?.target?.result)
    .then(convertHtmlToEditorState)
    .then(updateEditor)
    .then(handleOpen("Successfully loaded file")("success"))
    .catch(handleOpen("Faild to load file")("error"));

const getFileFromFileList = (files: FileList | null) => {
  if (!files) return null;

  return files.item(0);
};

const loadFile = (updateEditor: OnEditorStateUpdate) => (
  handleOpen: HandleOpen
) => (file: File | null) => {
  if (!file) return;

  const reader = new FileReader();
  reader.onload = convertDocumentToEditorState(updateEditor)(handleOpen);
  reader.readAsArrayBuffer(file);
};

const resetInputValue = (ref: React.RefObject<HTMLInputElement>) => {
  if (!ref?.current?.value) return;

  ref.current.value = "";
};

const useAlert: UseAlert = () => {
  const [message, setMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState<Color>("success");

  const handleOpen: HandleOpen = (msg: string) => (severity: Color) => () => {
    setMessage(msg);
    setSeverity(severity);
    setOpen(true);
  };

  const handleClose: HandleClose = () => {
    setMessage("");
    setSeverity("success");
    setOpen(false);
  };

  return { message, open, severity, handleOpen, handleClose };
};

// styles
export const Container = styled.div`
  position: relative;
  border: 2px dashed ${colors.blue5};
  border-radius: 4px;
  padding: 20px 0;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Search = styled.span`
  color: ${colors.blue1};
  cursor: pointer;
`;

export const StyledFileInput = styled.input`
  display: none;
`;

export const SnackbarContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  > .MuiSnackbar-root {
    width: 30%;
  }
`;

// components
const DocumentLoad: React.FC<DocumentLoadProps> = ({ onEditorStateUpdate }) => {
  const { message, open, severity, handleOpen, handleClose } = useAlert();
  const ref = useRef<HTMLInputElement>(null);

  const handleSearch = () => {
    if (!ref?.current) return;

    ref.current.click();
  };

  const handleDrop: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    const fileList = e?.dataTransfer?.files;
    const file = getFileFromFileList(fileList);
    loadFile(onEditorStateUpdate)(handleOpen)(file);
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    const fileList = e?.target?.files;
    const file = getFileFromFileList(fileList);
    loadFile(onEditorStateUpdate)(handleOpen)(file);
    resetInputValue(ref);
  };

  return (
    <Container>
      <DropZone onDrop={handleDrop}>
        <Content>
          <Typography>
            Drag and drop your documents here or
            <Search color="primary" onClick={handleSearch}>
              {" "}
              Search
            </Search>
          </Typography>
          <StyledFileInput ref={ref} type="file" onChange={handleInputChange} />
        </Content>
      </DropZone>
      <SnackbarContainer>
        <Snackbar
          open={open}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={severity}>
            {message}
          </Alert>
        </Snackbar>
      </SnackbarContainer>
    </Container>
  );
};

export default DocumentLoad;
