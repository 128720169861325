import { IconButton, Select } from "@material-ui/core";
import styled from "styled-components";
import { getMonthPickerWidth } from "../Calendar/styles";

// styles
export const Container = styled.div`
  width: ${getMonthPickerWidth}px;
  display: grid;
  grid-template-columns: 0.2fr 1fr 0.2fr;
  margin-bottom: 10px;
`;

export const IconButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

export const StyledIconButton = styled(IconButton)`
  width: 44px;
  height: 44px;
`;

export const StyledSelect = styled(Select)`
  height: 44px;
`;
