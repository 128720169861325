import React, { useState } from "react";
import {
  Validation,
  ValidationI,
  ValidationTypes,
} from "../../globalComponents/formFields/legacyFields/Validation/validation";
import MuiPhoneNumber from "material-ui-phone-number";
import Wrapper from "../../homepage/components/Wrapper";
import { CoreRequest } from "../../config/backend/serviceInstances";
import { refreshToken } from "../../state/general/auth";
import { useDispatch } from "react-redux";
import { useGeneral2Auth } from "../../state/general/selectors";
import { Button, Link, TextField, Typography } from "@mui/material";

interface NewCustomerProps {
  localState: (event: React.SetStateAction<number>) => void;
}

const NewCustomer = (props: NewCustomerProps): React.ReactElement => {
  const validation: ValidationI = { error: false, message: null };
  const [formFields, setFormFields] = useState({
    name: { value: "", validation: validation },
    firstName: { value: "", validation: validation },
    lastName: { value: "", validation: validation },
    phoneNumber: { value: "", validation: validation },
  });
  const dispatch = useDispatch();
  const rToken = useGeneral2Auth().refreshToken;
  const [formState, setFormState] = useState({
    loading: false,
    error: false,
    message: "",
  });
  const sendForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormState({ ...formState, loading: true });
    //validation
    const validateName = Validation(
      ValidationTypes.NOT_EMPTY,
      formFields.name.value
    );
    const validateFirstName = Validation(
      ValidationTypes.NOT_EMPTY,
      formFields.firstName.value
    );
    const validateLastName = Validation(
      ValidationTypes.NOT_EMPTY,
      formFields.lastName.value
    );
    const validatePhoneNumber = Validation(
      ValidationTypes.PHONE_NUMBER,
      formFields.phoneNumber.value
    );
    if (
      !validateName.error &&
      !validateFirstName.error &&
      !validateLastName.error &&
      !validatePhoneNumber.error
    ) {
      //make call for company creation and edit it.
      // this can be removed with one endpoint, creating a company, editing it, and giving back the refreshed token.
      //the postal code is needed for company creation, falco will adjust that later so we can remove it
      CoreRequest.post("/signup/funnel", {
        funnel: "HR_MANAGER",
        companyName: formFields.name.value,
        firstName: formFields.firstName.value,
        lastName: formFields.lastName.value,
        phoneNumber: formFields.phoneNumber.value,
      })
        .then(() => {
          //update thoken
          dispatch(refreshToken(rToken));
        })
        .catch(() => {
          console.log("authentication failed");
        });
    } else {
      setFormFields({
        name: { ...formFields.name, validation: validateName },
        firstName: { ...formFields.firstName, validation: validateFirstName },
        lastName: { ...formFields.lastName, validation: validateLastName },
        phoneNumber: {
          ...formFields.phoneNumber,
          validation: validatePhoneNumber,
        },
      });
    }
  };

  return (
    <>
      <Typography variant={"h4"} align={"center"} color={"primary"}>
        Perfekt, lassen Sie uns gleich starten! &#128077;
      </Typography>
      <br />
      <Typography variant={"subtitle2"} align={"center"}>
        Bitte geben Sie Firmennamen, Ihren Vor- und Nachnamen sowie eine
        Telefonnummer an.
      </Typography>
      <br />
      <Wrapper onSubmit={(e) => sendForm(e)}>
        <TextField
          label={"Firmenname"}
          variant={"standard"}
          sx={{ width: "100%" }}
          value={formFields.name.value}
          error={formFields.name.validation.error}
          helperText={formFields.name.validation.message}
          onChange={(e) => {
            setFormFields({
              ...formFields,
              name: { value: e.target.value, validation: validation },
            });
          }}
        />
        <br />
        <TextField
          label={"Vorname"}
          variant={"standard"}
          sx={{ width: "100%" }}
          value={formFields.firstName.value}
          error={formFields.firstName.validation.error}
          helperText={formFields.firstName.validation.message}
          onChange={(e) => {
            setFormFields({
              ...formFields,
              firstName: { value: e.target.value, validation: validation },
            });
          }}
        />
        <br />
        <TextField
          label={"Nachname"}
          variant={"standard"}
          sx={{ width: "100%" }}
          value={formFields.lastName.value}
          error={formFields.lastName.validation.error}
          helperText={formFields.lastName.validation.message}
          onChange={(e) => {
            setFormFields({
              ...formFields,
              lastName: { value: e.target.value, validation: validation },
            });
          }}
        />
        <br />
        <MuiPhoneNumber
          style={{ width: "100%" }}
          defaultCountry={"de"}
          onlyCountries={["ch", "fr", "at", "de"]}
          label={"Telefonnummer"}
          value={formFields.phoneNumber.value}
          error={formFields.phoneNumber.validation.error}
          helperText={formFields.phoneNumber.validation.message}
          type={"tel"}
          autoFormat={true}
          onChange={(value) => {
            setFormFields({
              ...formFields,
              phoneNumber: { value: value, validation: validation },
            });
          }}
        />
        <br />
        <br />
        <Button variant={"contained"} color={"primary"} type={"submit"}>
          Jetzt starten!
        </Button>
      </Wrapper>
      <br />
      <Link onClick={() => props.localState(1)} align={"center"}>
        Zurück
      </Link>
    </>
  );
};

export default NewCustomer;
