import { useSelector } from "react-redux";
import { Store } from "../store";
import { DataState } from "../organization/companies/detail/companyDetail";
import { UserSelectionState } from "./userSelection";
import { GeneralState2 } from "./general";
import { authorizationState } from "./auth";
import { Employee } from "../employees/employees/employee";

export const useGeneral2State = (): GeneralState2 => {
  return useSelector<Store, GeneralState2>((state) => state.general);
};

export const useGeneral2Auth = (): authorizationState => {
  return useSelector<Store, authorizationState>((state) => state.general.auth);
};

export const useUserSelection = (): UserSelectionState => {
  return useSelector<Store, UserSelectionState>(
    (state) => state.general.userSelection
  );
};

export const useMobileVersion = (): boolean => {
  return useSelector<Store, boolean>((state) => state.general.mobileVersion);
};
export const useAuthenticationByCredentialsRequest = (): DataState => {
  return useSelector<Store, DataState>(
    (state) => state.general.auth.loginByCredentials
  );
};

export const useAuthenticationBySessionRequest = (): DataState => {
  return useSelector<Store, DataState>(
    (state) => state.general.auth.loginBySession
  );
};

export const useAuthentication = (): boolean => {
  return useSelector<Store, boolean>(
    (state) => state.general.auth.authentication
  );
};

export const useSelf = (): { loading: boolean; data: Employee | null } => {
  return useSelector<Store, { loading: boolean; data: Employee | null }>(
    (state) => state.general.self
  );
};
