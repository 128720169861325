import React from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

const NewFeatureBannerContainer = styled.div`
  background-color: #eaf7f2b3;
  border-radius: 4px;
  padding: 10px;
  color: #008c5b;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid lightgrey;
    cursor: pointer;
  }
`;

const NewFeatureBanner = (): JSX.Element => {
  const history = useHistory();
  return (
    <NewFeatureBannerContainer onClick={() => history.push("/app/documents")}>
      <Typography variant={"subtitle2"}>
        Feature Update - Amendment Contracts
      </Typography>
      <Typography variant={"body2"} marginTop={0.5}>
        We are happy to announce that HeavenHR allows from now on to generate
        Amendment Contracts.
      </Typography>
      <Typography variant={"body1"} marginTop={0.5}>
        Try it out now. We are looking forward to your feedback!
      </Typography>
    </NewFeatureBannerContainer>
  );
};

export default NewFeatureBanner;
