import { DataState } from "./companyDetail";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CoreRequest } from "../../../../config/backend/serviceInstances";

export type Salarytype = {
  id: number;
  companyId: number;
  name: string;
  type: string;
  st: string;
  taxRate: null | number;
  factor: boolean;
  factorValue: number | null;
  sv: string;
  gesamtBrutto: boolean;
  pfaendbarkeit: string;
  einheit: string | null;
};

export interface Page<T> {
  data: T[];
  page: number;
  pageSize: number;
  totalPages: number;
  totalCount: number;
  sorting: string;
}

export interface SalarytypeState extends DataState {
  All: { count: number; selected: boolean };
  Predefined: { count: number; selected: boolean };
  Custom: { count: number; selected: boolean };
  page: Page<Salarytype> | Record<string, never>;
}

export const SalarytypeStateI: SalarytypeState = {
  All: { count: 30, selected: true },
  Predefined: { count: 20, selected: false },
  Custom: { count: 10, selected: false },
  loading: false,
  error: false,
  page: {},
};

export enum SalaryTypeVariant {
  Custom = "Custom",
  Predefined = "Predefined",
  All = "All",
}

export const listSalarytypes = createAsyncThunk<
  Page<Salarytype>,
  {
    companyId: number;
    page: number;
    pageSize: number;
    sort: string;
    type: SalaryTypeVariant;
  }
>("/companies/detail/salarytypes/all", async (params) => {
  const response = await CoreRequest.get(
    `/companies/salarytypes/${params.companyId}/${params.type}`,
    {
      params: {
        page: params.page,
        pageSize: params.pageSize,
        sort: params.sort,
      },
    }
  );

  if (response.status > 299) {
    // Return the known error for future handling
    return response.data.error;
  }

  return response.data as Page<Salarytype>;
});
