import React from "react";
import { StyledCell } from "../../product/pages/employees/absences/CompanyCalendar/styles";

type Props = {
  status: string | null;
};

const AbsenceRequestStatusChip = (props: Props): React.ReactElement => {
  if (props.status === "PLANNED" || props.status === "planned") {
    return <StyledCell theme="DRAFT">Entwurf</StyledCell>;
  }
  if (props.status === "REJECTED" || props.status === "rejected") {
    return <StyledCell theme="REJECTED">Abgelehnt</StyledCell>;
  }
  if (props.status === "REQUESTED" || props.status === "requested") {
    return <StyledCell theme="REQUESTED">Beantragt</StyledCell>;
  }
  if (props.status === "APPROVED" || props.status === "approved") {
    return <StyledCell theme="APPROVED">Bewilligt</StyledCell>;
  }

  return <></>;
};

/*
NOT_REQUIRED,
    MISSING,
    PROCESSING,
    CORRECT,
    INCORRECT,
    MANUAL_CHECK,
    MANUALLY_APPROVED,*/

export const AbsenceRequestDocumentStatusChip = (
  props: Props
): React.ReactElement => {
  if (props.status === "NOT_REQUIRED") {
    return <StyledCell theme="DRAFT">-</StyledCell>;
  }
  if (props.status === "PROCESSING") {
    return <StyledCell theme="DRAFT">-</StyledCell>;
  }
  if (props.status === "MANUAL_CHECK") {
    return <StyledCell theme="REQUESTED">Überprüfung erforderlich</StyledCell>;
  }
  if (props.status === "MISSING") {
    return <StyledCell theme="REJECTED">Fehlt</StyledCell>;
  }
  if (props.status === "INCORRECT") {
    return <StyledCell theme="REJECTED">Falsch</StyledCell>;
  }
  if (props.status === "MANUALLY_APPROVED") {
    return <StyledCell theme="APPROVED">Bewilligt</StyledCell>;
  }
  if (props.status === "CORRECT") {
    return <StyledCell theme="APPROVED">Bewilligt</StyledCell>;
  }

  return <></>;
};

export default AbsenceRequestStatusChip;
