import { defaultTo } from "lodash/fp";
import { MutableRefObject, useLayoutEffect, useState } from "react";

export type UseWidth = (ref: MutableRefObject<HTMLElement | null>) => number;

const useWidth: UseWidth = (ref) => {
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    const newWidth = ref?.current?.getBoundingClientRect().width;
    if (newWidth !== width) setWidth(defaultTo(0)(newWidth));
  });

  return width;
};

export default useWidth;
