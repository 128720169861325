import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import styled from "styled-components";
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

interface TextFieldControllerProps {
  control: Control<any>;
  rules?: { required?: boolean; minLength?: number };
  defaultValue: string | number | null | undefined;
  label: string;
  fieldName: string;
  errorMessage?: string;
  small?: boolean;
}

export const TextFieldWrapper = styled.div`
  width: 305px;
  height: auto;
`;

export const StyledLabel = styled.div`
  width: 305px;
  height: 28px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 15px;
`;

export const WarnLabel = styled.label`
  font-size: 11px;
  color: #c80410;
  font-family: Roboto;
  margin-top: 10px;
  display: inline-block;
`;

export const StyledInput = styled.input<{ error: boolean }>`
  width: 295px;
  height: 38px;
  background: white;
  border: ${(props: { error: boolean }) =>
    props.error ? "1px solid #C80410" : "1px solid #cccccc"};
  border-radius: 4px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 15px;
  padding-left: 10px;
  outline-color: #054d80;
  &:placeholder-shown {
    background: #fbfbfb;
    color: #999999;
    font-style: italic;
  }
  &:hover {
    background: #f5f8fa;
  }
  &:focus {
    background: #ffffff;
    color: #131313;
  }
  &:active {
    border: 1px solid #054d80;
    background: #ffffff;
    color: #131313;
  }
`;

const TextFieldController = (props: TextFieldControllerProps): JSX.Element => {
  return (
    <Controller
      name={props.fieldName}
      control={props.control}
      defaultValue={props.defaultValue}
      rules={props.rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isDirty, error },
      }) => {
        return (
          <TextFieldWrapper>
            <StyledLabel>
              <label>
                {props.label}
                {!invalid && isDirty ? (
                  <>
                    &nbsp;
                    <FontAwesomeIcon icon={faCheckCircle} color={"#00A76D"} />
                  </>
                ) : (
                  <></>
                )}
                {(invalid && isDirty) || error ? (
                  <>
                    &nbsp;
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      color={"#C80410"}
                    />
                  </>
                ) : (
                  <></>
                )}
              </label>
            </StyledLabel>
            <StyledInput
              error={error !== undefined}
              key={name}
              onBlur={onBlur}
              onChange={onChange}
              ref={ref}
              value={value}
              checked={value}
              placeholder={props.label}
              type={"text"}
            />
            {error && props.errorMessage ? (
              <WarnLabel>{props.errorMessage}</WarnLabel>
            ) : null}
          </TextFieldWrapper>
        );
      }}
    />
  );
};

export default TextFieldController;
