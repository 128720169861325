import React, { useState } from "react";
import SmartTable from "../../../../../globalComponents/smartOperations/smartTable/SmartTable";
import { listEmployeeAbsences } from "../../../../../state/employees/employeeDetail/employeeDetail";
import { useEmployeeDetailAbsences } from "../../../../../state/employees/employees/selectors";
import { useDispatch } from "react-redux";
import AbsenceCreationSlideIn from "./AbsenceCreationSlideIn";
import { GridColDef } from "@mui/x-data-grid";
import AbsenceRequestStatusChip, {
  AbsenceRequestDocumentStatusChip,
} from "../../../../../globalComponents/chips/AbsenceRequestStatusChip";

const columns: GridColDef[] = [
  {
    field: "startDate",
    headerName: "Startdatum",
    width: 150,
    editable: true,
  },
  {
    field: "endDate",
    headerName: "Enddatum",
    width: 140,
    editable: true,
  },
  {
    field: "absenceTypeId",
    headerName: "Abwesenheitsart",
    width: 200,
    editable: true,
  },
  {
    field: "currentStatus",
    headerName: "Status",
    width: 110,
    editable: true,
    renderCell: (params) => (
      <AbsenceRequestStatusChip status={params.row.currentStatus} />
    ),
  },
  {
    field: "approvalStatus",
    headerName: "Genehmigungsstatus",
    width: 220,
    editable: true,
    renderCell: (params) => (
      <AbsenceRequestStatusChip status={params.row.approvalStatus} />
    ),
  },
  {
    field: "documentStatus",
    headerName: "Dokumentenstatus",
    width: 210,
    editable: true,
    renderCell: (params) => (
      <AbsenceRequestDocumentStatusChip status={params.row.documentStatus} />
    ),
  },
];

const EmployeeDetailAbsences = (props: { id: number }): JSX.Element => {
  const [slideIn, setSlideIn] = useState(false);
  const dispatch = useDispatch();
  const absenceRequests = useEmployeeDetailAbsences();
  console.log(absenceRequests);

  return (
    <>
      <SmartTable
        columns={columns}
        input={absenceRequests}
        deleteEndpoint={"/employees/absence/"}
        deleteConfirmMessage={
          "Wollen Sie den Abwesenheitsantrag wirklich löschen?"
        }
        updateInputCallback={() => dispatch(listEmployeeAbsences(props.id))}
        title={"Abwesenheitsanträge"}
        createButton={{
          label: "Antrag erstellen",
          callbackFunc: () => {
            console.log("open slidein");
            setSlideIn(true);
          },
        }}
      />
      {slideIn ? (
        <AbsenceCreationSlideIn
          id={props.id}
          close={() => setSlideIn(false)}
          confirmAndClose={() => {
            console.log("posted confirm");
            dispatch(listEmployeeAbsences(props.id));
            setSlideIn(false);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default EmployeeDetailAbsences;
