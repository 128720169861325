import React, { useState } from "react";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { Alert } from "@material-ui/lab";
import {
  BottomContainer,
  FormFieldContainer,
  StyledForm,
  UpperContainer,
} from "../../../../../../../globalComponents/smartOperations/smartCrud/FormFieldReturn";
import DateFieldRHF from "../../../../../../../globalComponents/formFields/dateField/DateFieldRHF";
import getErrorMessage, {
  getFirstError,
} from "../../../../../../../utils/backend-errors";
import SmartSlideInCreate from "../../../../../../../globalComponents/smartOperations/smartCrud/SmartSlideInCreate";
import { CoreRequest } from "../../../../../../../config/backend/serviceInstances";
import CompensationTypeSelect from "../form/CompensationTypeSelect";
import TextFieldController from "../../../../../../../globalComponents/formFields/TextFieldController";
import TimeIntervalSelect from "../form/TimeIntervalSelect";
import CompensationDetailTypeSelect from "../form/CompensationDetailTypeSelect";
import ZuschlagDetailTypeSelect from "../form/ZuschlagDetailTypeSelect";

enum CompensationTypes {
  COMPENSATION = 0,
  ZUSCHLAG = 1,
}

const CompensationCreateSlideIn = (props: {
  employeeId: number;
  workContractId: number;
  confirmAndClose: () => void;
  close: () => void;
}): JSX.Element => {
  const { control, handleSubmit, getValues, reset } = useForm();
  const [apiError, setApiError] = useState<string | null>(null);
  const [type, setType] = useState<number>(0);

  const onSubmit = () => {
    const d = getValues();
    d.workContractId = props.workContractId;
    if (type === CompensationTypes.COMPENSATION) {
      CoreRequest.post(
        `/employees/contract/${props.workContractId}/compensation`,
        d
      )
        .then(() => {
          props.confirmAndClose();
        })
        .catch((error) => setApiError(getFirstError(error)));
    } else if (type === CompensationTypes.ZUSCHLAG) {
      CoreRequest.post(
        `/employees/contract/${props.workContractId}/zuschlag`,
        d
      )
        .then(() => {
          props.confirmAndClose();
        })
        .catch((error) => setApiError(getFirstError(error)));
    }
  };

  return (
    <SmartSlideInCreate
      type={"CREATE"}
      backdropCallback={() => props.close()}
      postEndpoint={{ url: "", type: "POST" }}
      title={"Vergütung"}
      formFields={[]}
      close={props.close}
      confirmAndClose={props.confirmAndClose}
    >
      <>
        {apiError && (
          <Alert
            severity="error"
            style={{ marginTop: 0, marginBottom: "0.5em" }}
            onClose={() => setApiError(null)}
          >
            {getErrorMessage(apiError)}
          </Alert>
        )}
        <UpperContainer>
          <FormFieldContainer>
            <CompensationTypeSelect
              value={type}
              fieldName={"compensationType"}
              onChange={(type: number) => {
                setType(type);
                reset();
              }}
            />
          </FormFieldContainer>

          {type === CompensationTypes.COMPENSATION && (
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
              <FormFieldContainer>
                <TextFieldController
                  control={control}
                  defaultValue={""}
                  label={"Name"}
                  fieldName={"name"}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <DateFieldRHF
                  control={control}
                  fieldName={"payoutDate"}
                  label={"Datum"}
                  defaultValue={""}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <TextFieldController
                  control={control}
                  fieldName={"amount"}
                  label={"Wert"}
                  defaultValue={""}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <TimeIntervalSelect
                  control={control}
                  fieldName={"timeInterval"}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <CompensationDetailTypeSelect
                  control={control}
                  fieldName={"type"}
                />
              </FormFieldContainer>
              <BottomContainer>
                <Button type={"submit"} variant={"contained"} color={"primary"}>
                  Absenden
                </Button>
              </BottomContainer>
            </StyledForm>
          )}
          {type === CompensationTypes.ZUSCHLAG && (
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
              <FormFieldContainer>
                <TextFieldController
                  control={control}
                  defaultValue={""}
                  label={"Name"}
                  fieldName={"name"}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <TextFieldController
                  control={control}
                  fieldName={"percentage"}
                  label={"Prozent"}
                  defaultValue={""}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <ZuschlagDetailTypeSelect
                  control={control}
                  fieldName={"type"}
                />
              </FormFieldContainer>
              <BottomContainer>
                <Button type={"submit"} variant={"contained"} color={"primary"}>
                  Absenden
                </Button>
              </BottomContainer>
            </StyledForm>
          )}
        </UpperContainer>
      </>
    </SmartSlideInCreate>
  );
};

export default CompensationCreateSlideIn;
