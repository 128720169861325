import React, { FormEvent, useEffect, useState } from "react";
import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import LinkStyled from "../../globalComponents/container/LinkStyled";
import Wrapper from "../components/Wrapper";
import {
  Validation,
  ValidationI,
  ValidationTypes,
} from "../../globalComponents/formFields/legacyFields/Validation/validation";
import PublicWrapper from "../components/PublicWrapper";
import FeedbackContainer from "../components/FeedbackContainer";
import BottomContainer from "../components/BottomContainer";
import { authenticateByCredentials } from "../../state/general/auth";
import { useDispatch } from "react-redux";
import {
  useAuthenticationByCredentialsRequest,
  useGeneral2State,
} from "../../state/general/selectors";
import { useHistory } from "react-router-dom";

const authConfig = {
  withCredentials: true,

  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  params: {
    grant_type: "password",
    scope: "any",
  },
  auth: {
    username: "alex",
    password: "alex",
  },
};

export const refreshConfig = {
  ...authConfig,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  params: { grant_type: "refresh_token", scope: "any" },
};

const SignIn: React.FC = () => {
  const dispatch = useDispatch();
  const { auth } = useGeneral2State();
  const authenticationRequest = useAuthenticationByCredentialsRequest();
  const validation: ValidationI = { error: false, message: null };
  const [formFields, setFormFields] = useState({
    username: { value: "", validation: validation },
    password: { value: "", validation: validation },
  });

  const history = useHistory();
  useEffect(() => {
    if (auth.session) {
      history.push(
        history.location.pathname === "/app/login"
          ? "/app"
          : history.location.pathname
      );
    }
  }, [auth]);

  const getToken = (e: FormEvent, username: string, password: string) => {
    e.preventDefault();

    //validate username and password before sending request
    const validateUsername = Validation(ValidationTypes.EMAIL, username);
    const validatePassword = Validation(ValidationTypes.LENGTH, password);

    if (!validateUsername.error && !validatePassword.error) {
      dispatch(
        authenticateByCredentials({ username: username, password: password })
      );
    } else {
      const formFieldCopy = { ...formFields };
      formFieldCopy.username.validation = validateUsername;
      formFieldCopy.password.validation = validatePassword;
      setFormFields(formFieldCopy);
    }
  };

  return (
    <PublicWrapper>
      <Wrapper
        onSubmit={(e) =>
          getToken(e, formFields.username.value, formFields.password.value)
        }
      >
        <TextField
          variant={"standard"}
          sx={{ width: "100%" }}
          label={"Email"}
          value={formFields.username.value}
          error={formFields.username.validation.error}
          helperText={formFields.username.validation.message}
          autoComplete={"username"}
          onChange={(e) =>
            setFormFields({
              ...formFields,
              username: {
                value: e.target.value,
                validation: { error: false, message: null },
              },
            })
          }
        />
        <br />
        <TextField
          variant={"standard"}
          sx={{ width: "100%" }}
          label={"Passwort"}
          value={formFields.password.value}
          error={formFields.password.validation.error}
          helperText={formFields.password.validation.message}
          autoComplete={"current-password"}
          type={"password"}
          onChange={(e) =>
            setFormFields({
              ...formFields,
              password: {
                value: e.target.value,
                validation: { error: false, message: null },
              },
            })
          }
        />
        <br />
        {authenticationRequest.loading ? (
          <CircularProgress />
        ) : (
          <>
            <br />
            <Button type={"submit"} variant={"contained"} color={"primary"}>
              Login
            </Button>
            <br />
          </>
        )}
      </Wrapper>
      {authenticationRequest.error ? (
        <FeedbackContainer>
          <Typography variant={"subtitle2"}>
            {authenticationRequest.errorMessage}
          </Typography>
          <LinkStyled to={"/reset/email"}>
            <Typography variant={"subtitle2"}>Passwort vergessen?</Typography>
          </LinkStyled>
        </FeedbackContainer>
      ) : (
        <></>
      )}
      <BottomContainer>
        <Typography variant={"subtitle2"}>
          Noch keinen Account?&nbsp;
        </Typography>
        <LinkStyled to={"/register/email"}>
          <Typography variant={"subtitle2"}>Jetzt registrieren!</Typography>
        </LinkStyled>
      </BottomContainer>
    </PublicWrapper>
  );
};

export default SignIn;
