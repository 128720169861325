import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import {
  faCaretDown as caretDR,
  faCaretUp as caretUR,
} from "@fortawesome/pro-regular-svg-icons";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &: hover {
    cursor: pointer;
  }
`;

const IconContainer = styled.div`
  position: relative;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
`;

const ReduceMarginContainer = styled.div<{ top: string }>`
  position: absolute;
  top: ${(props) => props.top};
`;

export enum SortState {
  Ascending = "ASCENDING",
  Descending = "DESCENDING",
  Unsorted = "UNSORTED",
}
const HeaderCell = (props: {
  title: string;
  ascCallBack: () => void;
  descCallBack: () => void;
  initialCallBack: () => void;
  actionColumn?: boolean;
  sortState: SortState;
}): JSX.Element => {
  let iconUp, iconDown: IconDefinition;

  switch (props.sortState) {
    case SortState.Unsorted:
      iconUp = caretUR;
      iconDown = caretDR;
      break;
    case SortState.Ascending:
      iconUp = faCaretUp;
      iconDown = caretDR;
      break;
    case SortState.Descending:
      iconUp = caretUR;
      iconDown = faCaretDown;
      break;
  }

  const clickHeader = () => {
    switch (props.sortState) {
      case SortState.Unsorted:
        props.ascCallBack();
        break;
      case SortState.Ascending:
        props.descCallBack();
        break;
      case SortState.Descending:
        props.initialCallBack();
        break;
    }
  };

  if (props.actionColumn) return <HeaderContainer />;

  return (
    <HeaderContainer onClick={() => clickHeader()}>
      {props.title}
      <IconContainer>
        <ReduceMarginContainer top={"-10px"}>
          <FontAwesomeIcon icon={iconUp} />
        </ReduceMarginContainer>
        <ReduceMarginContainer top={"-4px"}>
          <FontAwesomeIcon icon={iconDown} />
        </ReduceMarginContainer>
      </IconContainer>
    </HeaderContainer>
  );
};

export default HeaderCell;
