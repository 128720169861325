import React, { useState } from "react";
import TimetrackingHours from "./components/TimetrackingHours";
import TimetrackingDays from "./components/TimetrackingDays";
import {
  StyledLabel,
  TextFieldWrapper,
} from "../../../../../globalComponents/formFields/TextFieldController";
import { Collapse, MenuItem, Select, Typography } from "@mui/material";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import TimetrackingLegend_30Days from "./components/TimetrackingLegend_30Days";
import TimetrackingLegend_Now from "./components/TimetrackingLegend_Now";
import TimeIntervalSwitch from "./components/TimeIntervalSwitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dateConverter } from "./helper/helper";
import TextButton from "../../../../../globalComponents/buttons/TextButton";

const TimetrackingMainC = styled.div`
  border-radius: 6px;
  border: 1px solid lightgrey;
  overflow: hidden;
`;

const TimetrackingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const HeaderContainer = styled.div`
  padding: 40px 40px 20px 40px;
`;

const TopHeader = styled.form`
  display: flex;
  justify-content: space-between;
`;
const BottomHeader = styled.div`
  padding: 20px 0px 0px 0px;
`;

export enum ViewOption {
  DAY = "DAY",
  MONTHLY = "MONTHLY",
  LAST_30 = "LAST_30",
}

export enum RequestOption {
  APPROVED = "APPROVED",
  REQUESTED = "REQUESTED",
  REJECTED = "REJECTED",
  DRAFT = "DRAFT",
}

const TimetrackingCalendar = (): JSX.Element => {
  const now = new Date();
  const start_m = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0);
  const end_m = new Date(
    now.getFullYear(),
    now.getMonth() + 1,
    0,
    21,
    59,
    0,
    0
  );
  const start_l = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 29,
    21,
    59,
    0,
    0
  );
  const end_l = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    21,
    59,
    0,
    0
  );
  const start_d = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    now.getHours() - 10,
    now.getMinutes(),
    0,
    0
  );
  const end_d = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    now.getHours() + 1,
    now.getMinutes(),
    0,
    0
  );
  const [timeFrame, setTimeFrame] = useState<{
    start: Date;
    end: Date;
    view: ViewOption;
    requests: RequestOption[];
  }>({
    start: start_l,
    end: end_l,
    view: ViewOption.LAST_30,
    requests: [RequestOption.APPROVED, RequestOption.REQUESTED],
  });
  const [showLegend, setShowLegend] = useState(true);
  const setTimeInterval = (inc: boolean) => {
    const currentStartDate = timeFrame.start;
    const currentEndDate = timeFrame.end;
    if (timeFrame.view == ViewOption.MONTHLY) {
      if (inc) {
        currentStartDate.setMonth(currentStartDate.getMonth() + 1);
        currentEndDate.setMonth(currentEndDate.getMonth() + 2, 0);
      } else {
        currentStartDate.setMonth(currentStartDate.getMonth() - 1);
        currentEndDate.setMonth(currentEndDate.getMonth(), 0);
      }
    } else if (timeFrame.view == ViewOption.LAST_30) {
      if (inc) {
        currentStartDate.setDate(currentStartDate.getDate() + 30);
        currentEndDate.setDate(currentEndDate.getDate() + 30);
      } else {
        currentStartDate.setDate(currentStartDate.getDate() - 30);
        currentEndDate.setDate(currentEndDate.getDate() - 30);
      }
    } else if (timeFrame.view == ViewOption.DAY) {
      if (inc) {
        currentStartDate.setTime(currentStartDate.getHours() + 4);
        currentEndDate.setTime(currentEndDate.getHours() + 4);
      } else {
        currentStartDate.setTime(currentStartDate.getHours() - 4);
        currentEndDate.setTime(currentEndDate.getHours() - 4);
      }
    }
    setTimeFrame({
      ...timeFrame,
      start: currentStartDate,
      end: currentEndDate,
    });
  };
  let gridSize = 0;
  let gridItemDepartmentSize = 0;
  let gridItemSize = 0;
  switch (timeFrame.view) {
    case ViewOption.MONTHLY:
      {
        gridItemDepartmentSize = 10;
        gridSize =
          timeFrame.end.getDate() -
          timeFrame.start.getDate() +
          1 +
          gridItemDepartmentSize;
        gridItemSize = 1;
      }
      break;
    case ViewOption.LAST_30:
      {
        gridItemDepartmentSize = 10;
        gridSize = 30 + gridItemDepartmentSize;
        gridItemSize = 1;
      }
      break;
    case ViewOption.DAY: {
      gridItemDepartmentSize = 5;
      gridSize = 12 + gridItemDepartmentSize;
      gridItemSize = 1;
    }
  }
  return (
    <TimetrackingMainC>
      <HeaderContainer>
        <TopHeader>
          <TextFieldWrapper>
            <StyledLabel>Request Status</StyledLabel>
            <Select defaultValue={"APPROVED"}>
              <MenuItem value={"APPROVED"}>Approved</MenuItem>
              <MenuItem value={"REQUESTED"}>Requested</MenuItem>
              <MenuItem value={"DRAFT"}>Draft</MenuItem>
              <MenuItem value={"REJECTED"}>Rejected</MenuItem>
            </Select>
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledLabel>View</StyledLabel>
            <Select defaultValue={ViewOption.LAST_30}>
              <MenuItem
                onClick={() =>
                  setTimeFrame({
                    ...timeFrame,
                    view: ViewOption.DAY,
                    start: start_d,
                    end: end_d,
                  })
                }
                value={ViewOption.DAY}
              >
                Day
              </MenuItem>
              <MenuItem
                onClick={() =>
                  setTimeFrame({
                    ...timeFrame,
                    view: ViewOption.MONTHLY,
                    start: start_m,
                    end: end_m,
                  })
                }
                value={ViewOption.MONTHLY}
              >
                Month
              </MenuItem>
              <MenuItem
                onClick={() =>
                  setTimeFrame({
                    ...timeFrame,
                    view: ViewOption.LAST_30,
                    start: start_l,
                    end: end_l,
                  })
                }
                value={ViewOption.LAST_30}
              >
                Last 30 days
              </MenuItem>
            </Select>
          </TextFieldWrapper>
          <TimeIntervalSwitch
            forward={() => setTimeInterval(true)}
            backward={() => {
              setTimeInterval(false);
            }}
          >
            <Typography variant={"body1"} margin={"auto"}>
              {dateConverter(timeFrame.start, false)} bis{" "}
              {dateConverter(timeFrame.end, false)}
            </Typography>
          </TimeIntervalSwitch>
          <TextButton
            onClick={() => setShowLegend(!showLegend)}
            status1={
              <>
                <Typography variant={"caption"} marginRight={1}>
                  Legend
                </Typography>
                <FontAwesomeIcon icon={faAngleDown} color={"#4C4C4C"} />
              </>
            }
            status2={
              <>
                <Typography variant={"caption"} marginRight={1}>
                  Legend
                </Typography>
                <FontAwesomeIcon icon={faAngleUp} color={"#4C4C4C"} />
              </>
            }
            status={showLegend ? "TWO" : "ONE"}
          />
        </TopHeader>
        <BottomHeader>
          <Collapse in={showLegend}>
            {timeFrame.view == ViewOption.DAY ? (
              <TimetrackingLegend_Now />
            ) : (
              <TimetrackingLegend_30Days />
            )}
          </Collapse>
        </BottomHeader>
      </HeaderContainer>
      <TimetrackingContainer>
        {timeFrame.view == ViewOption.DAY ? (
          <TimetrackingHours
            startDate={timeFrame.start}
            endDate={timeFrame.end}
            gridSize={gridSize}
            gridItemDepartmentSize={gridItemDepartmentSize}
            gridItemSize={gridItemSize}
          />
        ) : (
          <TimetrackingDays
            startDate={timeFrame.start}
            endDate={timeFrame.end}
            gridSize={gridSize}
            gridItemDepartmentSize={gridItemDepartmentSize}
            gridItemSize={gridItemSize}
          />
        )}
      </TimetrackingContainer>
    </TimetrackingMainC>
  );
};

export default TimetrackingCalendar;
