import { createAsyncThunk } from "@reduxjs/toolkit";
import { CoreRequest } from "../../config/backend/serviceInstances";
import { DataState } from "../organization/companies/detail/companyDetail";
import { AbsenceRequest } from "../employees/types";

export interface PayrollAbsenceState extends DataState {
  data: AbsenceDataPayrollState;
}

export interface AbsenceDataPayrollState {
  data: AbsenceRequest[];
  page: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}

export const PayrollAbsenceStateI = {
  error: false,
  errorMessage: "",
  loading: false,
  data: { data: [], page: 0, pageSize: 0, totalCount: 0, totalPages: 0 },
};

export const listPayrollAbsences = createAsyncThunk<
  AbsenceDataPayrollState,
  {
    page: number;
    pageSize: number;
    sort: string;
    startDate: string;
    endDate: string;
  }
>(
  "/employees/absence",
  async (parameter: {
    page: number;
    pageSize: number;
    sort: string;
    startDate: string;
    endDate: string;
  }) => {
    const response = await CoreRequest.get(`/employees/absence`, {
      params: {
        page: parameter.page,
        pageSize: parameter.pageSize,
        sort: parameter.sort,
        startDate: parameter.startDate,
        endDate: parameter.endDate,
      },
    });

    if (response.status > 299) {
      // Return the known error for future handling
      return response.data.error;
    }

    return response.data as AbsenceDataPayrollState;
  }
);
