import React, { MouseEventHandler } from "react";
import { EditorState } from "draft-js";
import { compose as c } from "lodash/fp";
import { Divider, IconButton } from "@material-ui/core";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import FormatClearIcon from "@material-ui/icons/FormatClear";
import {
  BLOCK_TYPES,
  getCurrentFont,
  getCurrentFontSize,
  getFonts,
  getCurrentTextAlignment,
  INLINE_STYLES,
  TextAlignment as Alignment,
  getDefaultFont,
  getDefaultFontSize,
  removeAllStyles,
  getFontSizes,
  getCurrentColor,
  getDefaultColor,
} from "../utils";
import { Container, ControlGroupContainer, DividerContainer } from "./styles";
import {
  getAlignmentButtonColor,
  getBlockStyleButtonColor,
  getInlineStyleButtonColor,
  onTextAlignmentChange,
  renderButtonIcon,
  toggleBlockStyle,
  toggleInlineStyle,
  toggleFontStyle,
  toggleFontSizeStyle,
  OnEditorStateUpdate,
  toggleColorStyle,
} from "./utils";
import SelectControl from "./SelectControl";
import ColorPickerControl from "./ColorPickerControl";

// types
type EditorControlsProps = {
  editorState: EditorState;
  onEditorStateUpdate: OnEditorStateUpdate;
  onEditorFocus: () => void;
};

type ControlGroupProps = Partial<{
  divider: boolean;
  flex: string;
  width: string;
}>;

// components
const EditorControls: React.FC<EditorControlsProps> = ({
  editorState: state,
  onEditorStateUpdate: onUpdate,
  onEditorFocus,
}) => {
  const currentFont = getCurrentFont(state);
  const currentFontSize = getCurrentFontSize(state);
  const currentColor = getCurrentColor(state);
  const currentAlignment = getCurrentTextAlignment(state);
  const defaultFont = getDefaultFont();
  const defaultFontSize = getDefaultFontSize();
  const defaultColor = getDefaultColor();
  const editorHasFocus = state.getSelection().getHasFocus();
  const currentInlineStyle = state.getCurrentInlineStyle();

  const onFontChange = (style: string) => {
    const nextEditorState = toggleFontStyle(state)(style);
    onUpdate(nextEditorState);
  };

  const onFontSizeChange = (style: string) => {
    const nextEditorState = toggleFontSizeStyle(state)(style);
    onUpdate(nextEditorState);
  };

  const onColorChange = (style: string) => {
    const nextEditorState = toggleColorStyle(state)(style);
    onUpdate(nextEditorState);
  };

  const onClearHandler: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    const nextEditorState = removeAllStyles(state);
    onUpdate(nextEditorState);
  };

  return (
    <Container variant="outlined">
      <ControlGroup width="100px">
        <SelectControl
          value={currentFont}
          defaultValue={defaultFont}
          options={getFonts()}
          currentStyle={currentInlineStyle}
          editorHasFocus={editorHasFocus}
          onChange={onFontChange}
          onSelect={onEditorFocus}
        />
      </ControlGroup>
      <ControlGroup>
        <SelectControl
          value={currentFontSize}
          defaultValue={defaultFontSize}
          options={getFontSizes()}
          currentStyle={currentInlineStyle}
          editorHasFocus={editorHasFocus}
          onChange={onFontSizeChange}
          onSelect={onEditorFocus}
        />
      </ControlGroup>
      <ControlGroup>
        <ColorPickerControl
          value={currentColor}
          defaultValue={defaultColor}
          currentStyle={currentInlineStyle}
          editorHasFocus={editorHasFocus}
          onChange={onColorChange}
          onSelect={onEditorFocus}
        />
      </ControlGroup>
      <ControlGroup flex="3">
        {INLINE_STYLES.map((style) => (
          <IconButton
            key={style}
            onMouseDown={c(onUpdate, toggleInlineStyle(state)(style))}
            color={getInlineStyleButtonColor(state)(style)}
          >
            {renderButtonIcon(style)}
          </IconButton>
        ))}
      </ControlGroup>
      <ControlGroup flex="4">
        <IconButton
          onClick={c(onUpdate, onTextAlignmentChange(state)(Alignment.left))}
          color={getAlignmentButtonColor(currentAlignment)(Alignment.left)}
        >
          <FormatAlignLeftIcon fontSize="small" />
        </IconButton>
        <IconButton
          onClick={c(onUpdate, onTextAlignmentChange(state)(Alignment.center))}
          color={getAlignmentButtonColor(currentAlignment)(Alignment.center)}
        >
          <FormatAlignCenterIcon fontSize="small" />
        </IconButton>
        <IconButton
          onClick={c(onUpdate, onTextAlignmentChange(state)(Alignment.right))}
          color={getAlignmentButtonColor(currentAlignment)(Alignment.right)}
        >
          <FormatAlignRightIcon fontSize="small" />
        </IconButton>
        <IconButton
          onClick={c(onUpdate, onTextAlignmentChange(state)(Alignment.justify))}
          color={getAlignmentButtonColor(currentAlignment)(Alignment.justify)}
        >
          <FormatAlignJustifyIcon fontSize="small" />
        </IconButton>
      </ControlGroup>
      <ControlGroup flex={String(BLOCK_TYPES.length)}>
        {BLOCK_TYPES.map((type) => (
          <IconButton
            key={type}
            onClick={c(onUpdate, toggleBlockStyle(state)(type))}
            color={getBlockStyleButtonColor(state)(type)}
          >
            {renderButtonIcon(type)}
          </IconButton>
        ))}
      </ControlGroup>
      <ControlGroup divider={false}>
        <IconButton onMouseDown={onClearHandler}>
          <FormatClearIcon fontSize="small" />
        </IconButton>
      </ControlGroup>
    </Container>
  );
};

const ControlGroup: React.FC<ControlGroupProps> = ({
  children,
  divider = true,
  flex,
  width,
}) => {
  return (
    <>
      <ControlGroupContainer flex={flex} width={width}>
        {children}
      </ControlGroupContainer>
      {divider && (
        <DividerContainer>
          <Divider orientation="vertical" />
        </DividerContainer>
      )}
    </>
  );
};

export default EditorControls;
