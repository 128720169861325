import styled from "styled-components";

const OrgChartElementHeader = styled.div`
  background: #f5f8fa 0% 0% no-repeat padding-box;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  flex: 0.3;
  flex-direction: row;
  padding-left: 0.5vw;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  font-family: Roboto;
  min-height: 42px;
`;

export default OrgChartElementHeader;
