import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAbsence } from "../../../../../state/employees/absences/selectors";
import { listAbsences } from "../../../../../state/employees/absences/absence";
import TableNew from "../../../../../globalComponents/tables/TableNew";
import { Typography } from "@mui/material";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmployeeAvatar from "../../../../../globalComponents/avatars/EmployeeAvatar";
import { dateConverterForAbsence } from "../../timetracking/calendar/helper/helper";
import { baseUrl } from "../../../../../config/globalVariables";
import { FadeContainer } from "../../../../../globalComponents/tables/components/TableBase";
import CommentView from "./utils/CommentView";
import AbsenceCreationSlideIn from "./AbsenceCreationSlideIn";
import DeleteConfirm from "../../../../../globalComponents/tables/components/DeleteConfirm";
import { CoreRequest } from "../../../../../config/backend/serviceInstances";
import RowActions from "./utils/RowActions";
import AbsenceRequestStatus from "./utils/AbsenceRequestStatus";
import AbsenceRequestDocumentStatus from "./utils/AbsenceRequestDocumentStatus";
import {
  AbsenceDocument,
  AbsenceRequest,
  DocumentStatus,
} from "../../../../../state/employees/types";
import FilePreview from "../../../../../globalComponents/filePreview/FilePreview";

const checkFileEnd = (fileName: string): "image" | "pdf" => {
  const fileExtension = fileName.split(".").pop();
  switch (fileExtension) {
    case "jpg":
      return "image";
    case "jpeg":
      return "image";
    case "png":
      return "image";
    default:
      return "pdf";
  }
};

export enum Operations {
  CREATE = "CREATE",
  EDIT = "EDIT",
  DELETE = "DELETE",
}

const CompanyAbsencesTable = (): React.ReactElement => {
  const [slideIn, setSlideIn] = useState<{
    operation: Operations | null;
    requestId: number | null;
  }>({ operation: null, requestId: null });
  const [FileReader, setFileReader] = useState<AbsenceDocument>({
    id: 0,
    fileId: "",
    creationDate: "",
    status: DocumentStatus.NOT_REQUIRED,
    name: "",
  });
  const dispatch = useDispatch();
  const absences = useAbsence();
  useEffect(() => {
    dispatch(listAbsences());
  }, []);

  const deleteRequest = () => {
    CoreRequest.delete(
      `/employees/absence?absenceRequestId=${slideIn.requestId}`
    ).then(() => {
      setSlideIn({ operation: null, requestId: null });
      dispatch(listAbsences());
    });
  };

  const columnDefinition = [
    {
      id: 1,
      fieldName: "employee",
      initialCallback: () => console.log(""),
      descCallback: () => console.log("cc"),
      ascCallback: () => console.log(""),
      headerName: "Mitarbeiter",
      renderCell: (row: any) => {
        return (
          <EmployeeAvatar
            id={row.workContract.employeeId}
            firstName={row.workContract.firstName}
            lastName={row.workContract.lastName}
            imgLink={baseUrl + "/employees/pic/" + row.workContract.imgLink}
            position={row.workContract.position}
          />
        );
      },
    },
    {
      id: 2,
      fieldName: "absenceType",
      initialCallback: () => console.log(""),
      descCallback: () => console.log("cc"),
      ascCallback: () => console.log(""),
      headerName: "Art",
      renderCell: (row: any) => {
        return (
          <Typography variant={"body1"}>
            {row.absenceType.description == "Sickness"
              ? "Krankheit"
              : row.absenceType.description}
          </Typography>
        );
      },
    },
    {
      id: 3,
      fieldName: "startDate",
      initialCallback: () => console.log("a"),
      descCallback: () => console.log("cc"),
      ascCallback: () => console.log("a"),
      headerName: "Zeitraum",
      renderCell: (row: any) => {
        return (
          <div style={{ display: "inline-flex", alignItems: "center" }}>
            <Typography variant={"body1"} marginRight={"10px"}>
              {dateConverterForAbsence(row.startDate)}
            </Typography>
            <FontAwesomeIcon icon={faArrowRight} color={"#4C4C4C"} />
            <Typography variant={"body1"} marginLeft={"10px"}>
              {dateConverterForAbsence(row.endDate)}
            </Typography>
          </div>
        );
      },
    },
    {
      id: 4,
      fieldName: "duration",
      initialCallback: () => console.log("a"),
      descCallback: () => console.log("cc"),
      ascCallback: () => console.log("a"),
      headerName: "Dauer",
      renderCell: (row: any) => {
        return <Typography variant={"body1"}>{row.duration}</Typography>;
      },
    },
    {
      id: 5,
      fieldName: "creationDate",
      initialCallback: () => console.log("a"),
      descCallback: () => console.log("cc"),
      ascCallback: () => console.log("a"),
      headerName: "Beantragt",
      renderCell: (row: any) => {
        return (
          <Typography variant={"body1"}>
            {dateConverterForAbsence(row.creationDate.substring(0, 10))}
          </Typography>
        );
      },
    },
    {
      id: 6,
      fieldName: "approvalStatus",
      initialCallback: () => console.log("a"),
      descCallback: () => console.log("cc"),
      ascCallback: () => console.log("a"),
      headerName: "Genehmigung",
      renderCell: (row: any) => {
        return (
          <AbsenceRequestStatus
            approvalStatus={row.approvalStatus}
            absenceTypeId={row.absenceType.id}
          />
        );
      },
    },
    {
      id: 8,
      fieldName: "documentStatus",
      initialCallback: () => console.log("a"),
      descCallback: () => console.log("cc"),
      ascCallback: () => console.log("a"),
      headerName: "Nachweis",
      renderCell: (row: any) => {
        return (
          <AbsenceRequestDocumentStatus documentStatus={row.documentStatus} />
        );
      },
    },
    {
      id: 9,
      fieldName: "note",
      initialCallback: () => console.log("a"),
      descCallback: () => console.log("cc"),
      ascCallback: () => console.log("a"),
      headerName: "Kommentar",
      renderCell: (row: any) => {
        return (
          <CommentView
            message={
              "Hello World. need to go to the docotr to check whatever i have"
            }
          />
        );
      },
    },
    {
      id: 7,
      fieldName: "",
      initialCallback: () => console.log("a"),
      descCallback: () => console.log("cc"),
      ascCallback: () => console.log("a"),
      headerName: "",
      actionColumn: true,
      renderCell: (row: AbsenceRequest) => {
        return (
          <FadeContainer>
            <RowActions
              id={row.id}
              approvalStatus={row.approvalStatus}
              documents={row.documents}
              documentStatus={row.documentStatus}
              absenceType={row.absenceType}
              fileViewCallBack={(r: AbsenceDocument) => setFileReader(r)}
              editDeleteCallBack={(r: {
                operation: Operations;
                requestId: number;
              }) => setSlideIn(r)}
            />
          </FadeContainer>
        );
      },
    },
  ];
  return (
    <>
      <TableNew
        addResource={{
          label: "Neuer Antrag",
          callBack: () =>
            setSlideIn({ operation: Operations.CREATE, requestId: null }),
        }}
        title={"Abwesenheitsanträge"}
        columns={columnDefinition}
        input={absences.data}
        quickFilter={[
          {
            label: "Alle",
            count: 220,
            onClick: () => console.log("a"),
            selected: true,
          },
          {
            label: "Ausstehend",
            count: 10,
            onClick: () => console.log("a"),
            selected: false,
          },
          {
            label: "Genehmigt",
            count: 135,
            onClick: () => console.log("a"),
            selected: false,
          },
          {
            label: "Abgelehnt",
            count: 65,
            onClick: () => console.log("a"),
            selected: false,
          },
          {
            label: "Nachweis Fehlt",
            count: 7,
            onClick: () => console.log("a"),
            selected: false,
          },
          {
            label: "Nachweis überprüfen",
            count: 3,
            onClick: () => console.log("a"),
            selected: false,
          },
        ]}
        pageBack={() => console.log("page back")}
        pageForward={() => console.log("page forward")}
      />
      {slideIn.operation == Operations.CREATE ? (
        <AbsenceCreationSlideIn
          companyId={1}
          title={"Abwesenheitsantrag"}
          confirmCallBack={() => {
            setSlideIn({ requestId: null, operation: null });
            dispatch(listAbsences());
          }}
          closeCallBack={() => setSlideIn({ requestId: null, operation: null })}
          variant={"CREATE"}
        />
      ) : (
        <></>
      )}
      {slideIn.operation == Operations.DELETE ? (
        <DeleteConfirm
          confirmCallBack={() => deleteRequest()}
          closeCallBack={() => setSlideIn({ operation: null, requestId: null })}
          text={"Wollen Sie den Antrag wirklich löschen? "}
        />
      ) : (
        <></>
      )}
      {FileReader.id !== 0 ? (
        <FilePreview
          fileName={FileReader.name}
          inputType={checkFileEnd(FileReader.name)}
          endpoint={`/absence/documents/${FileReader.id}`}
          close={() => setFileReader({ ...FileReader, id: 0 })}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default CompanyAbsencesTable;
