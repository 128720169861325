import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getCurrentEmployeeBasicData,
  getCurrentEmployeeSocialData,
  getCurrentEmployeeTaxData,
  getCurrentEmployeeWorkContracts,
  listEmployeeAbsences,
} from "../../../../../state/employees/employeeDetail/employeeDetail";
import DisplayCard from "../../../../../globalComponents/dataCard/DisplayCard";
import {
  useEmployeeDetailBasic,
  useEmployeeDetailSocial,
  useEmployeeDetailTax,
  useEmployeeWorkContracts,
} from "../../../../../state/employees/employees/selectors";
import { BasicDataFields, SocialDataFields, TaxDataFields } from "./config";
import SmartTable from "../../../../../globalComponents/smartOperations/smartTable/SmartTable";
import TableLink from "../../../organisation/departments/components/TableLink";
import { Route, Switch } from "react-router-dom";
import EmployeeWorkContractsDetailView from "./EmployeeWorkContractsDetailView";
import { defaultDateTo } from "../../../../../utils";
import EmployeeDetailAbsences from "./EmployeeDetailAbsences";
import { makeStyles } from "@mui/styles";
import { AppBar, IconButton, Tab, Tabs, Theme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaste } from "@fortawesome/free-solid-svg-icons";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          <>{children}</>
        </>
      )}
    </div>
  );
}

export function a11yProps(index: unknown): unknown {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

interface EmployeeDetailBody {
  id: number;
}

export default function EmployeeDetailBody(
  props: EmployeeDetailBody
): React.ReactElement {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setValue(newValue);
  };

  const BasicData = useEmployeeDetailBasic();
  const Tax = useEmployeeDetailTax();
  const Social = useEmployeeDetailSocial();
  const WorkContracts = useEmployeeWorkContracts();
  useEffect(() => {
    dispatch(getCurrentEmployeeBasicData(props.id));
    dispatch(getCurrentEmployeeTaxData(props.id));
    dispatch(getCurrentEmployeeSocialData(props.id));
    dispatch(listEmployeeAbsences(props.id));
  }, [props.id]);

  const WorkContractColumns = [
    {
      field: "creationDate",
      headerName: "Bezeichnung",
      width: 300,
      editable: true,
      renderCell: (params: any) => (
        <TableLink
          label={`Arbeitsvertrag vom ${defaultDateTo(
            "-",
            params.row.creationDate
          )}`}
          startUrl={"/app/employees/" + props.id + "/contract/"}
          customValue={params.id as string}
        />
      ),
    },
    {
      field: "position",
      headerName: "Position",
      width: 200,
      editable: true,
    },
    {
      field: "startDate",
      headerName: "Startdatum",
      width: 180,
      editable: true,
      renderCell: (params: any) => defaultDateTo("-", params.row.startDate),
    },
    {
      field: "endDate",
      headerName: "Enddatum",
      width: 180,
      editable: true,
      renderCell: (params: any) => defaultDateTo("-", params.row.endDate),
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      editable: true,
      renderCell: () => <>Gültig</>,
    },
  ];

  const DocumentsColumns = [
    {
      field: "description",
      headerName: "Name",
      width: 250,
      editable: true,
    },
    {
      field: "creationDate",
      headerName: "Erstellungsdatum",
      width: 300,
      editable: true,
    },
    {
      field: "signature",
      headerName: "Unterschrift",
      width: 180,
      editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      editable: true,
    },
    {
      field: "pdfLink",
      headerName: "PDF",
      width: 100,
      editable: true,
      renderCell: () => (
        <IconButton size={"small"}>
          <FontAwesomeIcon icon={faPaste} />
        </IconButton>
      ),
    },
  ];

  const DocumentData = {
    loading: false,
    error: false,
    data: [
      {
        id: 1,
        pdfLink: "",
        status: "Gelesen",
        signature: "Unterschrieben",
        creationDate: "08.10.21",
        description: "Arbeitsvertrag",
      },
      {
        id: 2,
        pdfLink: "",
        status: "Gelesen",
        signature: "Nicht benötigt",
        creationDate: "29.08.21",
        description: "Lohnabrechnung August 21",
      },
      {
        id: 3,
        pdfLink: "",
        status: "Gelesen",
        signature: "Nicht benötigt",
        creationDate: "28.09.21",
        description: "Lohnabrechnung September 21",
      },
      {
        id: 4,
        pdfLink: "",
        status: "Ungelesen",
        signature: "Nicht benötigt",
        creationDate: "18.10.21",
        description: "Lohnabrechnung Oktober 21",
      },
    ],
  };

  const TimetrackingColumns = [
    {
      field: "startDate",
      headerName: "Startzeit",
      width: 150,
      editable: true,
    },
    {
      field: "endDate",
      headerName: "Endzeit",
      width: 150,
      editable: true,
    },
    {
      field: "breaks",
      headerName: "Pausenzeit",
      width: 350,
      editable: true,
    },
    {
      field: "hours",
      headerName: "Stunden",
      width: 150,
      editable: true,
    },
    {
      field: "status",
      headerName: "Genehmigungsstatus",
      width: 250,
      editable: true,
    },
  ];

  const TimetrackingData = {
    loading: false,
    error: false,
    data: [
      {
        id: 1,
        startDate: "08.10.21 9:30 Uhr",
        endDate: "08.10.21 18:30 Uhr",
        breaks: "Pause von 10.30-10.45, Pause von 14.30-16.00",
        hours: "7:15 Stunden",
        status: "Genehmigt",
      },
      {
        id: 2,
        startDate: "10.10.21 9:30 Uhr",
        endDate: "10.10.21 18:30 Uhr",
        breaks: "Pause von 15.30-16.00",
        hours: "8:30 Stunden",
        status: "Ausstehend",
      },
      {
        id: 3,
        startDate: "14.10.21 10:30 Uhr",
        endDate: "14.10.21 12:30 Uhr",
        breaks: "Keine",
        hours: "2:00 Stunden",
        status: "Abgelehnt",
      },
      {
        id: 4,
        startDate: "17.10.21 9:30 Uhr",
        endDate: "17.10.21 10:30 Uhr",
        breaks: "Keine",
        hours: "1:00 Stunden",
        status: "Ausstehend",
      },
    ],
  };

  return (
    <div>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
        >
          <Tab label="Basisdaten" {...a11yProps(0)} />
          <Tab label="Arbeitsverträge" {...a11yProps(1)} />
          <Tab label="Abwesenheiten" {...a11yProps(2)} />
          <Tab label="Zeiterfassung" {...a11yProps(3)} />
          <Tab label="Dokumente" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <DisplayCard
          title={"Allgemeine Angaben"}
          getEndpoint={"/employees/basic?employeeId="}
          updateEndpoint={"/employees/basic"}
          additionalRequestParams={[{ label: "employeeId", value: props.id }]}
          additionalBodyParams={["id"]}
          patch={false}
          id={props.id}
          input={BasicData}
          formFields={BasicDataFields}
          callbackFunc={() => dispatch(getCurrentEmployeeBasicData(props.id))}
        />
        <DisplayCard
          title={"Steuerliche Angaben"}
          getEndpoint={"/employees/tax?employeeId="}
          updateEndpoint={"/employees/tax"}
          additionalRequestParams={[{ label: "employeeId", value: props.id }]}
          additionalBodyParams={["id"]}
          patch={false}
          id={props.id}
          input={Tax}
          formFields={TaxDataFields}
          callbackFunc={() => dispatch(getCurrentEmployeeTaxData(props.id))}
        />
        <DisplayCard
          title={"Sozialversicherung"}
          getEndpoint={"/employees/social?employeeId="}
          updateEndpoint={"/employees/social"}
          additionalRequestParams={[{ label: "employeeId", value: props.id }]}
          additionalBodyParams={["id"]}
          patch={false}
          id={props.id}
          input={Social}
          formFields={SocialDataFields}
          callbackFunc={() => dispatch(getCurrentEmployeeSocialData(props.id))}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <br />
        <Switch>
          <Route path={"/app/employees/:id/contract/:workContractId"} exact>
            <EmployeeWorkContractsDetailView employeeId={props.id} />
          </Route>
          <Route path={"/app/employees/:id"}>
            <div>
              <SmartTable
                input={WorkContracts}
                deleteConfirmMessage={
                  "Wollen Sie den Arbeitsvertrag wirklich löschen?"
                }
                deleteEndpoint={"/employees/contract/workContractId="}
                updateInputCallback={() =>
                  dispatch(getCurrentEmployeeWorkContracts(props.id))
                }
                title={"Arbeitsverträge"}
                columns={WorkContractColumns}
                createButton={{
                  label: "Arbeitsvertrag hinzufügen",
                  callbackFunc: () => console.log("Create"),
                }}
              />
            </div>
          </Route>
        </Switch>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <br />
        <EmployeeDetailAbsences id={props.id} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <br />
        <SmartTable
          input={TimetrackingData}
          deleteConfirmMessage={"Wollen Sie den Antrag wirklich löschen?"}
          deleteEndpoint={"/employees/contract/workContractId="}
          updateInputCallback={() =>
            dispatch(getCurrentEmployeeWorkContracts(props.id))
          }
          title={"Anträge"}
          columns={TimetrackingColumns}
          createButton={{
            label: "Antrag hinzufügen",
            callbackFunc: () => console.log("Create"),
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <br />
        <SmartTable
          input={DocumentData}
          deleteConfirmMessage={"Wollen Sie das Dokument wirklich löschen?"}
          deleteEndpoint={"/employees/contract/workContractId="}
          updateInputCallback={() =>
            dispatch(getCurrentEmployeeWorkContracts(props.id))
          }
          title={"Dokumente"}
          columns={DocumentsColumns}
          createButton={{
            label: "Dokument hinzufügen",
            callbackFunc: () => console.log("Create"),
          }}
        />
      </TabPanel>
    </div>
  );
}
