import React from "react";
import { Grid, Typography } from "@mui/material";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

interface EmployeeTimelineProps {
  workingDays: WorkingDay[];
}

interface WorkingDay {
  calendarDay: string;
  contractHours: number;
  regularHours: number;
  sun: boolean;
  nightHours: number;
  holiday: boolean;
  absent: boolean;
}

const Bar = styled.div`
  width: 8px;
  height: 70px;
  margin: auto;
  margin-top: 5px;
  background-color: lightgrey;
  border-radius: 2px;
`;

const EmployeeTimeline = (props: EmployeeTimelineProps): JSX.Element => {
  return (
    <Grid
      container
      columns={props.workingDays.length + 1}
      sx={{ border: "1px solid lightgrey", marginBottom: "40px" }}
    >
      <Grid item xs={1} key={uuidv4()} height={80}>
        <Typography variant={"caption"} />
      </Grid>
      {props.workingDays.map(() => {
        return (
          <Grid item xs={1} key={uuidv4()} height={80}>
            <Bar />
          </Grid>
        );
      })}
      <Grid item xs={1} key={uuidv4()}>
        <Typography variant={"caption"} />
      </Grid>
      {props.workingDays.map((item) => {
        return (
          <Grid item xs={1} key={item.calendarDay} textAlign={"center"}>
            <Typography variant={"caption"}>{item.calendarDay}</Typography>
          </Grid>
        );
      })}
      <Grid item xs={1} key={uuidv4()}>
        <Typography variant={"caption"}>Vrtgl.</Typography>
      </Grid>
      {props.workingDays.map((item) => {
        return (
          <Grid item xs={1} key={item.calendarDay} textAlign={"center"}>
            <Typography variant={"subtitle2"}>{item.contractHours}</Typography>
          </Grid>
        );
      })}
      <Grid item xs={1} key={uuidv4()}>
        <Typography variant={"caption"}>Erbr.</Typography>
      </Grid>
      {props.workingDays.map((item) => {
        return (
          <Grid item xs={1} key={item.calendarDay} textAlign={"center"}>
            <Typography variant={"subtitle2"}>{item.regularHours}</Typography>
          </Grid>
        );
      })}
      <Grid item xs={1} key={uuidv4()}>
        <Typography variant={"caption"}>SFN</Typography>
      </Grid>
      {props.workingDays.map((item) => {
        return (
          <Grid item xs={1} key={item.calendarDay} textAlign={"center"}>
            <Typography variant={"subtitle2"}>{item.nightHours}</Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default EmployeeTimeline;
