import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import EmployeeAvatars from "../../../../../globalComponents/avatars/EmployeeAvatars";
import styled from "styled-components";
import { baseUrl } from "../../../../../config/globalVariables";
import { EmployeeAvatarInput } from "../../../../../globalComponents/avatars/EmployeeAvatar";
import FileUploadButton from "./FileUploadButton";
import { CoreRequest } from "../../../../../config/backend/serviceInstances";
import CenteredLoadingIndicator from "../../../../../globalComponents/loading/CenteredLoadingIndicator";
import { faCameraAlt } from "@fortawesome/pro-solid-svg-icons";

export const DetailHeaderWrapper = styled.div`
  border-radius: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #ededed;
  overflow: hidden;
`;

type CompanyDetailHeaderUpper = {
  imgUrl: string | null;
};

const CompanyDetailHeaderUpper = styled.div<CompanyDetailHeaderUpper>`
  ${(props) => {
    if (props.imgUrl) {
      return `background-image: url(${props.imgUrl});`;
    }
  }}
  background-repeat: no-repeat;
  background-size: 100% auto;
  min-height: 150px;
  position: relative;
`;

const CompanyDetailHeaderLower = styled.div`
  background-color: #f5f8fa;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 60px;
`;

const CompanyNameLabel = styled.div`
  position: absolute;
  background-color: white;
  opacity: 0.95;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px lightgrey;
  padding: 15px 35px 15px 35px;
  width: fit-content;
  right: 15%;
  top: 40%;
`;

const Pair = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const PictureButtonContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;

interface initialState {
  data: {
    name: string;
    imgLink: string;
    city: string;
    numberEmployees: number;
    managingDirectors: EmployeeAvatarInput[];
  };
  loading: boolean;
  error: boolean;
  errorMessage: string;
}

interface CompanyDetailHeader {
  id: number;
}

const CompanyDetailHeader = (
  props: CompanyDetailHeader
): React.ReactElement => {
  const [dataState, setDataState] = useState<initialState>({
    data: {
      name: "",
      imgLink: "",
      city: "",
      numberEmployees: 0,
      managingDirectors: [],
    },
    loading: false,
    error: false,
    errorMessage: "",
  });

  useEffect(() => {
    setDataState({ ...dataState, loading: true });
    CoreRequest.get("/companies/header", { params: { companyId: props.id } })
      .then((response) => {
        setDataState({
          data: response.data,
          loading: false,
          error: false,
          errorMessage: "",
        });
      })
      .catch(() => {
        setDataState({
          ...dataState,
          error: true,
          errorMessage: "Firmen Header konnte nicht geladen werden.",
        });
      });
  }, [props.id]);

  return (
    <DetailHeaderWrapper>
      {dataState.loading ? (
        <CenteredLoadingIndicator />
      ) : dataState.error ? (
        <Typography variant={"body2"} color={"primary"} align={"center"}>
          {dataState.errorMessage}
        </Typography>
      ) : (
        <>
          <CompanyDetailHeaderUpper
            imgUrl={
              dataState.data.imgLink
                ? `${baseUrl}/companies/pic/${dataState.data.imgLink}`
                : null
            }
          >
            <PictureButtonContainer>
              <FileUploadButton
                icon={faCameraAlt}
                successFunction={(r: string) =>
                  setDataState({
                    ...dataState,
                    data: { ...dataState.data, imgLink: r },
                  })
                }
                endpoint={"/companies/pic?companyId=" + props.id}
                errorMessage={"Upload fehlgeschlagen"}
                id={props.id}
              />
            </PictureButtonContainer>
            <CompanyNameLabel>
              <Typography variant={"h4"}>{dataState.data?.name}</Typography>
            </CompanyNameLabel>
          </CompanyDetailHeaderUpper>
          <CompanyDetailHeaderLower>
            <Pair>
              <Typography variant={"subtitle2"}>
                Geschäftsführer:&nbsp;&nbsp;
              </Typography>
              <EmployeeAvatars
                employeeList={dataState.data?.managingDirectors}
              />
            </Pair>
            <Pair>
              <Typography variant={"subtitle2"}>Mitarbeiteranzahl: </Typography>
              <Typography variant={"h6"}>
                &nbsp; {dataState.data.numberEmployees}
              </Typography>
            </Pair>
            <Pair>
              <Typography variant={"subtitle2"}>Firmensitz: </Typography>
              <Typography variant={"h6"}>
                &nbsp; {dataState.data.city}
              </Typography>
            </Pair>
          </CompanyDetailHeaderLower>
        </>
      )}
    </DetailHeaderWrapper>
  );
};

export default CompanyDetailHeader;
