import React, { useEffect, useMemo, useState } from "react";
import { format } from "date-fns/fp";
import { random } from "lodash";
import { Fade } from "@material-ui/core";
import StatusWheel, { Status } from "./StatusWheel";
import {
  Activities,
  ActivitiesContainer,
  DashboardHeader,
  DateText,
  LogoContainer,
  NewFeatureContainer,
  StatusCard,
  StatusDescription,
  StatusWheelContainer,
  TextContainer,
} from "./styles";
import { sampleData } from "./sampleData";
import EmployeeAvatarNew from "../../../globalComponents/avatars/EmployeeAvatarNew";
import { Grid, Typography } from "@mui/material";
import { PageTitle } from "../../../globalComponents/PageHeader/PageTitle";
import ChipC2 from "../../../globalComponents/chips/ChipC2";
import NewFeatureBanner from "./NewFeatureBanner";
import StatusNotification, {
  statusTypeValues,
} from "../../../globalComponents/notification/StatusNotification";
import {
  AbsenceRequest,
  EmployeeOverview,
} from "../../../state/employees/types";
import LinkStyled from "../../../globalComponents/container/LinkStyled";
import { baseUrl } from "../../../config/globalVariables";
import { useSelf } from "../../../state/general/selectors";
import { useDispatch } from "react-redux";
import { fetchSelf } from "../../../state/general/auth";
import { getFullNameFromEmployee } from "../../../utils";

// types
export type Type = "informal" | "todo";

export type Category = "DPF" | "Absences" | "Timetracking" | "Payroll";

export type Activity = {
  id: number;
  type: statusTypeValues;
  category: Category;
  date: string;
  employee: EmployeeOverview;
  absenceRequest?: AbsenceRequest;
};

export type FilterItem = { value: Type; label: string };

export type GetFilterList = () => FilterItem[];

// utils
const getDateText = () => {
  const now = new Date();
  return format("dd MMM yyyy")(now);
};

const filterByCategory = (categories: string[]) => (activity: Activity) =>
  !categories.length || categories.includes(activity.category);

const filterActivitiesBy = (categories: string[]) => (activity: Activity) =>
  filterByCategory(categories)(activity);

const getRandomStatus = (): Status => {
  const statusList: Status[] = ["green", "red", "yellow"];

  return statusList[random(2)];
};

const getFadeKey = (categories: string[]) => [...categories].toString();

const renderActivity = ({
  id,
  employee,
  date = "",
  type,
  absenceRequest,
}: Activity) => {
  return (
    <StatusNotification
      key={id}
      date={date}
      employee={employee}
      statusType={type}
      absenceRequest={absenceRequest}
    />
  );
};

// components
const Overview: React.FC = () => {
  const [categories, setCategories] = useState<string[]>([]);
  const { activities, modules } = useMemo(() => sampleData, []);
  const status = useMemo(getRandomStatus, []);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSelf());
  }, []);
  const self = useSelf();

  return (
    <>
      <PageTitle>
        <Typography variant={"h1"}>Dashboard</Typography>{" "}
      </PageTitle>
      <DashboardHeader>
        <LogoContainer>
          <EmployeeAvatarNew
            imgUrl={
              self.data && self.data.imgLink
                ? `${baseUrl}/employees/pic/${self.data.imgLink}`
                : null
            }
            size={"LARGE"}
          />
        </LogoContainer>
        <TextContainer>
          <Typography variant="h2">
            Welcome {getFullNameFromEmployee(self.data)} &#128075;
          </Typography>
          <Typography variant="subtitle2" marginTop={1}>
            Today is the <DateText>{getDateText()}</DateText>.
          </Typography>
          <Typography variant={"subtitle2"} marginTop={1}>
            <LinkStyled to={`/app/employees/${self?.data?.id}`}>
              View my profile
            </LinkStyled>
          </Typography>
        </TextContainer>
        <NewFeatureContainer>
          <NewFeatureBanner />
        </NewFeatureContainer>
      </DashboardHeader>
      <StatusCard>
        <StatusDescription>
          <Typography variant={"h3"}>Module Status</Typography>

          <Grid container marginTop={5} spacing={1}>
            <Grid item xs={8}>
              <Typography variant={"subtitle2"}>
                Digital Personal File
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant={"subtitle2"}>
                {" "}
                <ChipC2 color={"#00A76D"} label={"Ok"} />
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant={"subtitle2"}>Absences</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant={"subtitle2"}>
                {" "}
                <ChipC2 color={"#F8B44C"} label={"Attention"} />
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant={"subtitle2"}>Timetracking</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant={"subtitle2"}>
                {" "}
                <ChipC2 color={"#C80400"} label={"Critical"} />
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant={"subtitle2"}>Payroll</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant={"subtitle2"}>
                {" "}
                <ChipC2 color={"#00A76D"} label={"OK"} />
              </Typography>
            </Grid>
          </Grid>
        </StatusDescription>
        <StatusWheelContainer>
          <StatusWheel
            sectores={modules}
            status={status}
            onChange={setCategories}
          />
        </StatusWheelContainer>
        <ActivitiesContainer>
          <Typography variant={"h3"}>Messages</Typography>
          <Fade in={true} timeout={1000} key={getFadeKey(categories)}>
            <Activities>
              {activities
                .filter(filterActivitiesBy(categories))
                .map(renderActivity)}
            </Activities>
          </Fade>
        </ActivitiesContainer>
      </StatusCard>
    </>
  );
};

export default Overview;
