import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AbsenceRequest,
  Budget,
  Compensation,
  EmployeePersonalData,
  EmployeeSocialData,
  EmployeeTaxData,
  FullEmployee,
  TimeTrackingRequest,
  WorkContract,
  WorkContractBasic,
  WorkContractCompensations,
  WorkContractJob,
  WorkContractSimple,
  WorkContractStats,
  WorkSchedule,
} from "../types";
import { CoreRequest } from "../../../config/backend/serviceInstances";
import { GenericError } from "../absences/absence";
import {
  DataState,
  DataStateInitialArray,
  DataStateInitialObject,
} from "../../organization/companies/detail/companyDetail";
import { WorkingTime } from "../../../product/pages/employees/digitalPersonalFile/detail/workSchedules/WorkScheduleAdd";
import { Weekday } from "../../../product/pages/employees/digitalPersonalFile/detail/workSchedules/WorkScheduleRow";
import { convertDaylineToDay } from "../../../product/pages/employees/digitalPersonalFile/detail/workSchedules/helper/workScheduleGridHelper";
import { Page } from "../../organization/companies/detail/salarytype";

// action names
export const EMPLOYEE_GET = "employees/current/get";
export const EMPLOYEE_WORK_CONTRACTS_GET =
  "employees/current/workcontracts/get";
export const EMPLOYEE_WORK_CONTRACT_GET = "employees/current/workcontract/get";
export const EMPLOYEE_CURRENT_WORK_CONTRACT_GET =
  "employees/current/workcontract/current/get";
export const EMPLOYEE_CURRENT_WORK_CONTRACT_BUDGETS_GET =
  "employees/current/workcontract/current/budgets/get";
export const EMPLOYEE_CURRENT_WORK_CONTRACT_COMPENSATION_GET =
  "employees/current/workcontract/current/compensation/get";
export const EMPLOYEE_WORK_CONTRACT_STATS_GET =
  "employees/current/workcontract/stats/get";
export const EMPLOYEE_ABSENCES_GET = "employees/current/absences/get";

// types

export interface EmployeeDetailBasicState extends DataState {
  data: EmployeePersonalData | Record<string, never>;
}

export interface EmployeeDetailTaxState extends DataState {
  data: EmployeeTaxData | Record<string, never>;
}

export interface EmployeeDetailSocialState extends DataState {
  data: EmployeeSocialData | Record<string, never>;
}

export interface EmployeeDetailHeaderState extends DataState {
  data: { id: number; imgLink: string; status: string } | Record<string, never>;
}

export interface EmployeeDetailWorkContractsState extends DataState {
  data: WorkContractSimple[];
}

export interface WorkContractBasicState extends DataState {
  data: WorkContractBasic | Record<string, never>;
}

export interface WorkContractJobState extends DataState {
  data: WorkContractJob | Record<string, never>;
}

export interface WorkContractStatisticState extends DataState {
  data: WorkContractStats | Record<string, never>;
}

export interface WorkContractSchedulesState extends DataState {
  data: WorkSchedule[];
}

export interface WorkContractSchedulesNewState {
  data: WorkingTime[];
  dayLine: Dayline;
}

export enum NewScheduleRowState {
  INITIAL = "INITIAL",
  DRAFT = "DRAFT",
  CONFIRM = "CONFIRM",
  CONFIRMED = "CONFIRMED",
}

export interface Bar {
  id?: number;
  colStart: number;
  colEnd: number;
}

export interface Dayline {
  weekday: Weekday;
  row: string;
  columnStart: number;
  columnEnd: number;
  breaks: Bar[];
  state: NewScheduleRowState;
}

export interface StartEnd {
  startHour: number;
  startMin: number;
  endHour: number;
  endMin: number;
}

export interface WorkingDay {
  hours: number;
  minutes: number;
  workingTimes?: StartEnd;
  breaks?: StartEnd[];
}

export interface AddWorkSchedule {
  data: {
    Monday: WorkingDay;
    Tuesday: WorkingDay;
    Wednesday: WorkingDay;
    Thursday: WorkingDay;
    Friday: WorkingDay;
    Saturday: WorkingDay;
    Sunday: WorkingDay;
  };
  dayLine: Dayline;
}

export const AddWorkScheduleI: AddWorkSchedule = {
  data: {
    Monday: { hours: 0, minutes: 0 },
    Tuesday: { hours: 0, minutes: 0 },
    Wednesday: { hours: 0, minutes: 0 },
    Thursday: { hours: 0, minutes: 0 },
    Friday: { hours: 0, minutes: 0 },
    Saturday: { hours: 0, minutes: 0 },
    Sunday: { hours: 0, minutes: 0 },
  },
  dayLine: {
    weekday: Weekday.Mon,
    columnStart: 0,
    columnEnd: 0,
    row: "0/0",
    breaks: [],
    state: NewScheduleRowState.INITIAL,
  },
};

export interface WorkContractDetailBudgetState extends DataState {
  data: Budget[];
}

export interface WorkContractDetailCompensationState extends DataState {
  data: Compensation[];
}

export interface WorkContractCompensationsState extends DataState {
  data: WorkContractCompensations;
}

export interface EmployeeDetailWorkContractDetailState {
  workContractBasic: WorkContractBasicState;
  workContractStatistics: WorkContractStatisticState;
  workContractJob: WorkContractJobState;
  workSchedules: WorkContractSchedulesState;
  newWorkSchedule: AddWorkSchedule;
  budgets: WorkContractDetailBudgetState;
  compensation: WorkContractCompensationsState;
}

export interface EmployeeDetailAbsenceState extends DataState {
  data: AbsenceRequest[];
}

export interface EmployeeDetailTimetrackingRequestsState extends DataState {
  data: TimeTrackingRequest[];
}

export interface EmployeeDetailState {
  Header: EmployeeDetailHeaderState;
  Basic: EmployeeDetailBasicState;
  Tax: EmployeeDetailTaxState;
  Social: EmployeeDetailSocialState;
  WorkContracts: EmployeeDetailWorkContractsState;
  WorkContractDetail: EmployeeDetailWorkContractDetailState;
  AbsenceRequests: EmployeeDetailAbsenceState;
  TimetrackingRequests: EmployeeDetailTimetrackingRequestsState;
}

// actions
export const getCurrentEmployee = createAsyncThunk<
  FullEmployee,
  number,
  { rejectValue: GenericError }
>(EMPLOYEE_GET, async (employeeId, thunkApi) => {
  const response = await CoreRequest.get(`/employees/${employeeId}`);

  if (response.status > 299) {
    // Return the known error for future handling
    return thunkApi.rejectWithValue(response.data as GenericError);
  }

  return response.data;
});

//get current employee BasicData
export const getCurrentEmployeeBasicData = createAsyncThunk<
  EmployeePersonalData,
  number,
  { rejectValue: GenericError }
>("/Employee/Basic", async (employeeId, thunkApi) => {
  const response = await CoreRequest.get(`/employees/basic`, {
    params: { employeeId: employeeId },
  });

  if (response.status > 299) {
    // Return the known error for future handling
    return thunkApi.rejectWithValue(response.data as GenericError);
  }

  return response.data as EmployeePersonalData;
});

//get current employee TaxData
export const getCurrentEmployeeTaxData = createAsyncThunk<
  EmployeeTaxData,
  number,
  { rejectValue: GenericError }
>("/Employee/Tax", async (employeeId, thunkApi) => {
  const response = await CoreRequest.get(`/employees/tax`, {
    params: { employeeId: employeeId },
  });

  if (response.status > 299) {
    // Return the known error for future handling
    return thunkApi.rejectWithValue(response.data as GenericError);
  }

  return response.data as EmployeeTaxData;
});

//get current employee SocialData
export const getCurrentEmployeeSocialData = createAsyncThunk<
  EmployeeSocialData,
  number,
  { rejectValue: GenericError }
>("/Employee/Social", async (employeeId, thunkApi) => {
  const response = await CoreRequest.get(`/employees/social`, {
    params: { employeeId: employeeId },
  });

  if (response.status > 299) {
    // Return the known error for future handling
    return thunkApi.rejectWithValue(response.data as GenericError);
  }

  return response.data as EmployeeSocialData;
});

export const getCurrentEmployeeWorkContracts = createAsyncThunk<
  WorkContractSimple[],
  number,
  { rejectValue: GenericError }
>(EMPLOYEE_WORK_CONTRACTS_GET, async (employeeId, thunkApi) => {
  const response = await CoreRequest.get(`/employees/${employeeId}/contracts`);

  if (response.status > 299) {
    // Return the known error for future handling
    return thunkApi.rejectWithValue(response.data as GenericError);
  }

  return response.data as WorkContractSimple[];
});

export const getCurrentWorkContract = createAsyncThunk<
  WorkContract,
  number,
  { rejectValue: GenericError }
>(EMPLOYEE_CURRENT_WORK_CONTRACT_GET, async (employeeId, thunkApi) => {
  const response = await CoreRequest.get(
    `/employees/${employeeId}/contracts/current`
  );

  if (response.status > 299) {
    // Return the known error for future handling
    return thunkApi.rejectWithValue(response.data as GenericError);
  }

  return response.data;
});

export const getCurrentEmployeeWorkContractStats = createAsyncThunk<
  WorkContractStats,
  number,
  { rejectValue: GenericError }
>(EMPLOYEE_WORK_CONTRACT_STATS_GET, async (workContractId, thunkApi) => {
  const response = await CoreRequest.get(
    `/employees/contract/${workContractId}/statistics`
  );

  if (response.status > 299) {
    // Return the known error for future handling
    return thunkApi.rejectWithValue(response.data as GenericError);
  }

  return response.data as WorkContractStats;
});

export const getCurrentEmployeeWorkContractBasic = createAsyncThunk<
  WorkContractBasic,
  number,
  { rejectValue: GenericError }
>("/employee/workcontract/basic", async (workContractId, thunkApi) => {
  const response = await CoreRequest.get(`/employees/contract/basic`, {
    params: { workContractId: workContractId },
  });

  if (response.status > 299) {
    // Return the known error for future handling
    return thunkApi.rejectWithValue(response.data as GenericError);
  }

  return response.data as WorkContractBasic;
});

export const getCurrentEmployeeWorkContractJob = createAsyncThunk<
  WorkContractJob,
  number,
  { rejectValue: GenericError }
>("/employee/workcontract/Job", async (workContractId, thunkApi) => {
  const response = await CoreRequest.get(`/employees/contract/job`, {
    params: { workContractId: workContractId },
  });

  if (response.status > 299) {
    // Return the known error for future handling
    return thunkApi.rejectWithValue(response.data as GenericError);
  }

  return response.data as WorkContractJob;
});

export const getCurrentEmployeeSingleWorkContract = createAsyncThunk<
  WorkContract,
  number,
  { rejectValue: GenericError }
>(EMPLOYEE_WORK_CONTRACT_GET, async (workContractId, thunkApi) => {
  const response = await CoreRequest.get(
    `/employees/contract/${workContractId}`
  );

  if (response.status > 299) {
    // Return the known error for future handling
    return thunkApi.rejectWithValue(response.data as GenericError);
  }

  return response.data as WorkContract;
});

export const listWorkContractBudgets = createAsyncThunk<
  Budget[],
  number,
  { rejectValue: GenericError }
>(
  EMPLOYEE_CURRENT_WORK_CONTRACT_BUDGETS_GET,
  async (workContractId, thunkApi) => {
    const response = await CoreRequest.get(
      `/employees/absence/budget/${workContractId}`
    );

    if (response.status > 299) {
      // Return the known error for future handling
      return thunkApi.rejectWithValue(response.data as GenericError);
    }

    return response.data as Budget[];
  }
);

export const listWorkContractSchedules = createAsyncThunk<
  WorkSchedule[],
  number,
  { rejectValue: GenericError }
>("/employees/contract/workSchedules", async (workContractId, thunkApi) => {
  const response = await CoreRequest.get(`/employees/contract/workSchedules`, {
    params: { workContractId: workContractId },
  });

  if (response.status > 299) {
    // Return the known error for future handling
    return thunkApi.rejectWithValue(response.data as GenericError);
  }

  return response.data as WorkSchedule[];
});

export const listWorkContractCompensation = createAsyncThunk<
  WorkContractCompensations,
  number,
  { rejectValue: GenericError }
>(
  EMPLOYEE_CURRENT_WORK_CONTRACT_COMPENSATION_GET,
  async (workContractId, thunkApi) => {
    const response = await CoreRequest.get(
      `/employees/contract/${workContractId}/compensations`
    );

    if (response.status > 299) {
      // Return the known error for future handling
      return thunkApi.rejectWithValue(response.data as GenericError);
    }

    return response.data as WorkContractCompensations;
  }
);

export const listEmployeeAbsences = createAsyncThunk<
  Page<AbsenceRequest>,
  number,
  { rejectValue: GenericError }
>(EMPLOYEE_ABSENCES_GET, async (employeeId, thunkApi) => {
  const response = await CoreRequest.get(`/employees/absence/${employeeId}`);

  if (response.status > 299) {
    // Return the known error for future handling
    return thunkApi.rejectWithValue(response.data as GenericError);
  }

  return response.data as Page<AbsenceRequest>;
});

const initialState: EmployeeDetailState = {
  Header: DataStateInitialObject,
  Basic: { error: false, loading: false, data: {}, errorMessage: "" },
  Tax: DataStateInitialObject,
  Social: DataStateInitialObject,
  WorkContracts: DataStateInitialArray,
  WorkContractDetail: {
    workContractBasic: DataStateInitialObject,
    workContractStatistics: DataStateInitialObject,
    workContractJob: DataStateInitialObject,
    workSchedules: DataStateInitialArray,
    newWorkSchedule: AddWorkScheduleI,
    budgets: DataStateInitialArray,
    compensation: {
      ...DataStateInitialObject,
      data: {
        workContract: null,
        compensations: [],
        zuschlage: [],
      },
    },
  },
  AbsenceRequests: DataStateInitialArray,
  TimetrackingRequests: DataStateInitialArray,
};

export const changeWorkScheduleNewState = createAction<{
  weekday: Weekday;
  column: number;
  row: string;
}>("workschedule/new");

export const newScheduleAddEditBreak = createAction<{
  breakId?: number;
  colStart: number;
  colEnd: number;
}>("workschedule/new/break");

export const updateWorkingTimesDraft = createAction<{
  colStart: number;
  colEnd: number;
}>("workschedule/update");

export const newWorkScheduleDeleteBreak = createAction<{
  breakId: number;
}>("workschedule/delete");

export const newWorkScheduleEmpty = createAction("workschedule/empty");

export const WorkScheduleSlideRows = createAction<number>("workschedule/slide");

export const newWorkSchedulePutToData = createAction<{
  weekday: Weekday;
}>("workschedule/put");

// reducer
const employeeDetail = createSlice({
  name: "employees/current",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // to be substituted with header action
    builder.addCase(getCurrentEmployee.pending, (state) => {
      state.Header.loading = true;
    });
    builder.addCase(getCurrentEmployee.fulfilled, (state, action) => {
      state.Header.loading = false;
      state.Header.data.imgLink = action.payload.imgLink;
      state.Header.data.id = action.payload.id;
      state.Header.data.status = action.payload.status;
      state.Header.error = false;
    });
    builder.addCase(getCurrentEmployee.rejected, (state) => {
      state.Header.loading = false;
      state.Header.error = true;
    });

    builder.addCase(getCurrentEmployeeBasicData.pending, (state) => {
      state.Basic.loading = true;
    });
    builder.addCase(getCurrentEmployeeBasicData.fulfilled, (state, action) => {
      state.Basic.error = false;
      state.Basic.loading = false;
      state.Basic.data = action.payload;
    });
    builder.addCase(getCurrentEmployeeBasicData.rejected, (state) => {
      state.Basic.loading = false;
      state.Basic.error = true;
    });

    builder.addCase(getCurrentEmployeeTaxData.pending, (state) => {
      state.Tax.loading = true;
    });
    builder.addCase(getCurrentEmployeeTaxData.fulfilled, (state, action) => {
      state.Tax.error = false;
      state.Tax.loading = false;
      state.Tax.data = action.payload;
    });
    builder.addCase(getCurrentEmployeeTaxData.rejected, (state) => {
      state.Tax.loading = false;
      state.Tax.error = true;
    });

    builder.addCase(getCurrentEmployeeSocialData.pending, (state) => {
      state.Social.loading = true;
    });
    builder.addCase(getCurrentEmployeeSocialData.fulfilled, (state, action) => {
      state.Social.error = false;
      state.Social.loading = false;
      state.Social.data = action.payload;
    });
    builder.addCase(getCurrentEmployeeSocialData.rejected, (state) => {
      state.Social.loading = false;
      state.Social.error = true;
    });

    builder.addCase(getCurrentEmployeeWorkContracts.pending, (state) => {
      state.WorkContracts.loading = true;
    });
    builder.addCase(
      getCurrentEmployeeWorkContracts.fulfilled,
      (state, action) => {
        state.WorkContracts.loading = false;
        state.WorkContracts.data = action.payload;
        state.WorkContracts.error = false;
      }
    );
    builder.addCase(getCurrentEmployeeWorkContracts.rejected, (state) => {
      state.WorkContracts.loading = false;
      state.WorkContracts.error = true;
    });

    builder.addCase(getCurrentEmployeeWorkContractBasic.pending, (state) => {
      state.WorkContractDetail.workContractBasic.loading = true;
    });
    builder.addCase(
      getCurrentEmployeeWorkContractBasic.fulfilled,
      (state, action) => {
        state.WorkContractDetail.workContractBasic.loading = false;
        state.WorkContractDetail.workContractBasic.error = false;
        state.WorkContractDetail.workContractBasic.data = action.payload;
      }
    );
    builder.addCase(getCurrentEmployeeWorkContractBasic.rejected, (state) => {
      state.WorkContractDetail.workContractBasic.loading = false;
      state.WorkContractDetail.workContractBasic.error = true;
    });

    builder.addCase(getCurrentEmployeeWorkContractJob.pending, (state) => {
      state.WorkContractDetail.workContractJob.loading = true;
    });
    builder.addCase(
      getCurrentEmployeeWorkContractJob.fulfilled,
      (state, action) => {
        state.WorkContractDetail.workContractJob.loading = false;
        state.WorkContractDetail.workContractJob.error = false;
        state.WorkContractDetail.workContractJob.data = action.payload;
      }
    );
    builder.addCase(getCurrentEmployeeWorkContractJob.rejected, (state) => {
      state.WorkContractDetail.workContractJob.loading = false;
      state.WorkContractDetail.workContractJob.error = true;
    });

    builder.addCase(getCurrentEmployeeWorkContractStats.pending, (state) => {
      state.WorkContractDetail.workContractStatistics.loading = true;
    });
    builder.addCase(
      getCurrentEmployeeWorkContractStats.fulfilled,
      (state, action) => {
        state.WorkContractDetail.workContractStatistics.loading = false;
        state.WorkContractDetail.workContractStatistics.error = false;
        state.WorkContractDetail.workContractStatistics.data = action.payload;
      }
    );
    builder.addCase(getCurrentEmployeeWorkContractStats.rejected, (state) => {
      state.WorkContractDetail.workContractStatistics.loading = false;
      state.WorkContractDetail.workContractStatistics.error = true;
    });

    builder.addCase(listWorkContractBudgets.pending, (state) => {
      state.WorkContractDetail.budgets.loading = true;
    });
    builder.addCase(listWorkContractBudgets.fulfilled, (state, action) => {
      state.WorkContractDetail.budgets.loading = false;
      state.WorkContractDetail.budgets.error = false;
      state.WorkContractDetail.budgets.data = action.payload;
    });
    builder.addCase(listWorkContractBudgets.rejected, (state) => {
      state.WorkContractDetail.budgets.loading = false;
      state.WorkContractDetail.budgets.error = true;
    });

    builder.addCase(listWorkContractSchedules.pending, (state) => {
      state.WorkContractDetail.workSchedules.loading = true;
    });
    builder.addCase(listWorkContractSchedules.fulfilled, (state, action) => {
      state.WorkContractDetail.workSchedules.loading = false;
      state.WorkContractDetail.workSchedules.error = false;
      state.WorkContractDetail.workSchedules.data = action.payload;
    });
    builder.addCase(listWorkContractSchedules.rejected, (state) => {
      state.WorkContractDetail.workSchedules.loading = false;
      state.WorkContractDetail.workSchedules.error = true;
    });

    builder.addCase(listWorkContractCompensation.pending, (state) => {
      state.WorkContractDetail.compensation.loading = true;
    });
    builder.addCase(listWorkContractCompensation.fulfilled, (state, action) => {
      state.WorkContractDetail.compensation.loading = false;
      state.WorkContractDetail.compensation.error = false;
      state.WorkContractDetail.compensation.data = action.payload;
    });
    builder.addCase(listWorkContractCompensation.rejected, (state) => {
      state.WorkContractDetail.compensation.loading = false;
      state.WorkContractDetail.compensation.error = true;
    });
    builder.addCase(listEmployeeAbsences.pending, (state) => {
      state.AbsenceRequests.loading = true;
    });
    builder.addCase(listEmployeeAbsences.fulfilled, (state, action) => {
      state.AbsenceRequests.loading = false;
      state.AbsenceRequests.error = false;
      state.AbsenceRequests.data = action.payload.data;
    });
    builder.addCase(listEmployeeAbsences.rejected, (state) => {
      state.AbsenceRequests.loading = false;
      state.AbsenceRequests.error = true;
    });
    builder.addCase(changeWorkScheduleNewState, (state, action) => {
      if (
        state.WorkContractDetail.newWorkSchedule.dayLine.state ==
        NewScheduleRowState.INITIAL
      ) {
        state.WorkContractDetail.newWorkSchedule.dayLine = {
          weekday: action.payload.weekday,
          columnStart: action.payload.column,
          columnEnd: action.payload.column,
          row: action.payload.row,
          breaks: [],
          state: NewScheduleRowState.DRAFT,
        };
      } else if (
        state.WorkContractDetail.newWorkSchedule.dayLine.state ==
        NewScheduleRowState.DRAFT
      ) {
        state.WorkContractDetail.newWorkSchedule.dayLine = {
          ...state.WorkContractDetail.newWorkSchedule.dayLine,
          state: NewScheduleRowState.CONFIRM,
        };
      }
    });
    builder.addCase(updateWorkingTimesDraft, (state, action) => {
      state.WorkContractDetail.newWorkSchedule.dayLine.columnStart =
        action.payload.colStart;
      state.WorkContractDetail.newWorkSchedule.dayLine.columnEnd =
        action.payload.colEnd;
    });
    builder.addCase(WorkScheduleSlideRows, (state, action) => {
      state.WorkContractDetail.newWorkSchedule.dayLine!.columnEnd =
        action.payload;
    });
    builder.addCase(newWorkScheduleEmpty, (state) => {
      state.WorkContractDetail.newWorkSchedule.dayLine = {
        weekday: Weekday.Mon,
        row: "0/0",
        columnStart: 20,
        columnEnd: 20,
        breaks: [],
        state: NewScheduleRowState.INITIAL,
      };
    });
    builder.addCase(newScheduleAddEditBreak, (state, action) => {
      if (action.payload.breakId !== undefined) {
        const breaks = state.WorkContractDetail.newWorkSchedule.dayLine.breaks.filter(
          (r) => {
            return r.id == action.payload.breakId;
          }
        );
        breaks[0].colStart = action.payload.colStart;
        breaks[0].colEnd = action.payload.colEnd;
      } else {
        const id = Math.floor(Math.random() * 100);

        state.WorkContractDetail.newWorkSchedule.dayLine.breaks = [
          ...state.WorkContractDetail.newWorkSchedule.dayLine.breaks,
          {
            id: id,
            colStart: action.payload.colStart,
            colEnd: action.payload.colEnd,
          },
        ];
      }
    });
    builder.addCase(newWorkScheduleDeleteBreak, (state, action) => {
      state.WorkContractDetail.newWorkSchedule.dayLine.breaks = state.WorkContractDetail.newWorkSchedule.dayLine.breaks.filter(
        (r) => {
          return r.id !== action.payload.breakId;
        }
      );
    });
    builder.addCase(newWorkSchedulePutToData, (state, action) => {
      switch (action.payload.weekday) {
        case Weekday.Mon:
          state.WorkContractDetail.newWorkSchedule.data.Monday = convertDaylineToDay(
            state.WorkContractDetail.newWorkSchedule.dayLine
          );
          break;
        case Weekday.Tue:
          state.WorkContractDetail.newWorkSchedule.data.Tuesday = convertDaylineToDay(
            state.WorkContractDetail.newWorkSchedule.dayLine
          );
          break;
        case Weekday.Wed:
          state.WorkContractDetail.newWorkSchedule.data.Wednesday = convertDaylineToDay(
            state.WorkContractDetail.newWorkSchedule.dayLine
          );
          break;
        case Weekday.Thu:
          state.WorkContractDetail.newWorkSchedule.data.Thursday = convertDaylineToDay(
            state.WorkContractDetail.newWorkSchedule.dayLine
          );
          break;
        case Weekday.Fri:
          state.WorkContractDetail.newWorkSchedule.data.Friday = convertDaylineToDay(
            state.WorkContractDetail.newWorkSchedule.dayLine
          );
          break;
        case Weekday.Sat:
          state.WorkContractDetail.newWorkSchedule.data.Saturday = convertDaylineToDay(
            state.WorkContractDetail.newWorkSchedule.dayLine
          );
          break;
        case Weekday.Sun:
          state.WorkContractDetail.newWorkSchedule.data.Sunday = convertDaylineToDay(
            state.WorkContractDetail.newWorkSchedule.dayLine
          );
          break;
      }
      state.WorkContractDetail.newWorkSchedule.dayLine.state =
        NewScheduleRowState.INITIAL;
    });
  },
});

export default employeeDetail;
