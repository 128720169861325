import React, { useState } from "react";
import { Container, NoData } from "./styles";
import Header from "./Header";
import { getColumns, isLast } from "./utils";
import Department from "./Department";
import { OnHighlightClick } from "./Cell";
import { AbsenceDepartmentGroup } from "../../../../../../state/employees/absences/absence";
import { AbsenceType } from "../../../../../../state/employees/types";

export type CalendarProps = {
  className?: string;
  date: Date;
  departments: AbsenceDepartmentGroup[];
  absenceTypes: AbsenceType[];
  width: number;
  onHighlightClick: OnHighlightClick;
};

const Calendar: React.FC<CalendarProps> = ({
  date,
  departments,
  absenceTypes,
  width,
  onHighlightClick,
  className,
}) => {
  const [activeDepartment, setActiveDepartment] = useState("");

  const columns = getColumns(date);

  const onClickHandler = (name: string) => () => {
    setActiveDepartment(activeDepartment === name ? "" : name);
  };

  return (
    <Container className={className}>
      <Header date={date} columns={columns} width={width} />
      {departments.length === 0 && <NoData>Noch keine angelegt.</NoData>}
      {departments.map(
        ({ id, departmentName, employees, absences }, depIndex) => (
          <Department
            date={date}
            name={departmentName}
            size={employees.length}
            absences={absences}
            absenceTypes={absenceTypes}
            key={id}
            isActive={activeDepartment === departmentName}
            employees={employees}
            columns={columns}
            isLastDepartment={isLast(depIndex)(departments)}
            onClick={onClickHandler(departmentName)}
            width={width}
            onHighlightClick={onHighlightClick}
          />
        )
      )}
    </Container>
  );
};

export default Calendar;
