import React from "react";
import SmartTable from "../../../globalComponents/smartOperations/smartTable/SmartTable";
import { Typography } from "@mui/material";
import { PageTitle } from "../../../globalComponents/PageHeader/PageTitle";
import { useHistory } from "react-router-dom";

const tableCells = [
  { field: "companyName", headerName: "Firma", width: 180 },
  {
    field: "period",
    headerName: "Periode",
    width: 150,
  },
  { field: "grossSalaries", headerName: "Brutto Löhne", width: 200 },
  { field: "taxEmployer", headerName: "Steuer AN", width: 150 },
  { field: "svEmployer", headerName: "SV AG + AN", width: 150 },
  { field: "totalEmployer", headerName: "Arbeitgeberkosten", width: 220 },
];

const inputData = {
  data: [
    {
      id: 1,
      companyName: "Testfirma GmbH",
      period: "12/2019",
      grossSalaries: "11,953.44 €",
      taxEmployer: "2,454.12 €",
      svEmployer: "1,120,78 €",
      totalEmployer: "13,074.22 €",
    },
    {
      id: 2,
      companyName: "Testfirma GmbH",
      period: "11/2019",
      grossSalaries: "0 €",
      taxEmployer: "0 €",
      svEmployer: "0 €",
      totalEmployer: "0 €",
    },
    {
      id: 3,
      companyName: "Tochterfirma GmbH",
      period: "11/2019",
      grossSalaries: "4,500.00 €",
      taxEmployer: "1,240.00 €",
      svEmployer: "565.12 €",
      totalEmployer: "1,805.12 €",
    },
  ],
  loading: false,
  error: false,
  errorMessage: "",
};
const PayrollNew = (): JSX.Element => {
  const history = useHistory();
  return (
    <>
      <PageTitle>
        <Typography variant={"h1"}>Lohnabrechnung</Typography>
      </PageTitle>
      <SmartTable
        deleteEndpoint={"/employees?employeeId="}
        columns={tableCells}
        createButton={{
          label: "Lohnabrechnung starten",
          callbackFunc: () => {
            history.push("/app/payroll/add");
          },
        }}
        title={"Abgeschlossene Abrechnungen"}
        deleteConfirmMessage={"Wollen Sie die Periode wirklich löschen?"}
        input={inputData}
        updateInputCallback={() => console.log("reload")}
      />
    </>
  );
};

export default PayrollNew;
