import React from "react";
import { Button } from "@mui/material";
import { StyledLabel } from "../../../../../../globalComponents/formFields/TextFieldController";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled as styledM } from "@mui/styles";
import styled from "styled-components";

const TimeIntervalContainer = styled.div`
  width: 350px;
  display: inline-flex;
  align-items: center;
`;

const ButtonSwitch = styledM(Button)({
  height: "40px!important",
  width: "40px!important",
  padding: "0px!important",
  minWidth: "0px!important",
  color: "#4C4C4C!important",
  borderRadius: "4px!important",
  fontSize: "16px!important",
});

export interface TimeIntervalSwitchProps {
  forward: () => void;
  backward: () => void;
  children: JSX.Element;
}

const TimeIntervalSwitch = (props: TimeIntervalSwitchProps): JSX.Element => {
  return (
    <div>
      <StyledLabel>Timeframe</StyledLabel>
      <TimeIntervalContainer>
        <ButtonSwitch onClick={() => props.backward()} size={"small"}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </ButtonSwitch>
        {props.children}
        <ButtonSwitch onClick={() => props.forward()} size={"small"}>
          <FontAwesomeIcon icon={faAngleRight} />
        </ButtonSwitch>
      </TimeIntervalContainer>
    </div>
  );
};

export default TimeIntervalSwitch;
