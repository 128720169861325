import styled from "styled-components";

interface IconContainerProps {
  mobile: boolean;
  width?: string;
}

export const IconContainer = styled.div<IconContainerProps>`
  width: 150px;
  display: flex;
  opacity: ${(props) => (props.mobile ? "1" : "0")};
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  transition-duration: 1s;
`;

export const DataCardContainer = styled.div<IconContainerProps>`
  position: relative;
  width: ${(props) => (props.mobile ? "95%" : "49%")};
  min-width: ${(props) =>
    props.mobile ? "280px" : props.width ? props.width : "514px"};
  height: 433px;
  max-width: 520px;
  display: inline-block;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  margin: 20px 40px 10px 0px;
  overflow: scroll;
  &:hover ${IconContainer} {
    opacity: 1;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const TitleContainer = styled.div<IconContainerProps>`
  position: sticky;
  top: 0;
  display: flex;
  min-height: 40px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  z-index: 1;
  padding: ${(props) =>
    props.mobile ? "10px 10px 5px 10px" : "40px 40px 20px 40px"};
`;

export const DataCardBody = styled.div`
  padding: 0px 40px 0px 40px;
`;

export const ButtonContainer = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  background-color: white;
  box-shadow: 0px 0px 5px 0px lightgrey;
  justify-content: flex-end;
`;
