import { createSlice } from "@reduxjs/toolkit";
import { listDPFToDos, DPFState, DPFStateI } from "./DPF";
import { getCurrentPeriod, PeriodState, PeriodI } from "./periodState";
import { TrialState, TrialStateI } from "./trial";
import {
  EmploymentState,
  EmploymentStateI,
  listEmployments,
} from "./employments";
import {
  listPayrollAbsences,
  PayrollAbsenceState,
  PayrollAbsenceStateI,
} from "./absences";
import {
  listPayrollTimetrackingRequests,
  PayrollTimetrackingState,
  PayrollTimetrackingStateI,
} from "./timetracking";

export interface PayrollState {
  period: PeriodState;
  dpf: DPFState;
  absenceRequests: PayrollAbsenceState;
  timetrackingRequests: PayrollTimetrackingState;
  employments: EmploymentState;
  trial: TrialState;
  reports: boolean;
}

const PayrollStateI: PayrollState = {
  period: PeriodI,
  dpf: DPFStateI,
  absenceRequests: PayrollAbsenceStateI,
  timetrackingRequests: PayrollTimetrackingStateI,
  employments: EmploymentStateI,
  trial: TrialStateI,
  reports: false,
};

const payroll = createSlice({
  name: "/payroll/prepare",
  initialState: PayrollStateI,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listDPFToDos.pending, (state) => {
      state.dpf.loading = true;
      state.dpf.error = false;
    });
    builder.addCase(listDPFToDos.fulfilled, (state, action) => {
      state.dpf.loading = false;
      state.dpf.error = false;
      state.dpf.data = action.payload;
    });
    builder.addCase(listDPFToDos.rejected, (state) => {
      state.dpf.loading = false;
      state.dpf.error = true;
    });
    builder.addCase(getCurrentPeriod.pending, (state) => {
      state.period.loading = true;
      state.period.error = false;
    });
    builder.addCase(getCurrentPeriod.fulfilled, (state, action) => {
      state.period.loading = false;
      state.period.error = false;
      state.period.data = action.payload;
    });
    builder.addCase(getCurrentPeriod.rejected, (state) => {
      state.period.loading = false;
      state.period.error = true;
    });
    builder.addCase(listPayrollAbsences.pending, (state) => {
      state.absenceRequests.loading = true;
      state.absenceRequests.error = false;
    });
    builder.addCase(listPayrollAbsences.fulfilled, (state, action) => {
      state.absenceRequests.loading = false;
      state.absenceRequests.error = false;
      state.absenceRequests.data = action.payload;
    });
    builder.addCase(listPayrollAbsences.rejected, (state) => {
      state.absenceRequests.loading = false;
      state.absenceRequests.error = true;
    });
    builder.addCase(listPayrollTimetrackingRequests.pending, (state) => {
      state.timetrackingRequests.loading = true;
      state.timetrackingRequests.error = false;
    });
    builder.addCase(
      listPayrollTimetrackingRequests.fulfilled,
      (state, action) => {
        state.timetrackingRequests.loading = false;
        state.timetrackingRequests.error = false;
        state.timetrackingRequests.data = action.payload;
      }
    );
    builder.addCase(listPayrollTimetrackingRequests.rejected, (state) => {
      state.timetrackingRequests.loading = false;
      state.timetrackingRequests.error = true;
    });
    builder.addCase(listEmployments.pending, (state) => {
      state.employments.loading = true;
      state.employments.error = false;
    });
    builder.addCase(listEmployments.fulfilled, (state, action) => {
      state.employments.loading = false;
      state.employments.error = false;
      state.employments.data = action.payload;
    });
    builder.addCase(listEmployments.rejected, (state) => {
      state.employments.loading = false;
      state.employments.error = true;
    });
  },
});

export default payroll;
