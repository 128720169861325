import React from "react";
import { Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";

const CustomHeaderContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const tableCells = [
  { field: "id", headerName: "Lohnart", width: 90, sortable: false },
  {
    field: "name",
    headerName: "Bezeichnung",
    width: 200,
    sortable: false,
  },
  { field: "unit", headerName: "Einheit", width: 80, sortable: false },
  {
    field: "number",
    headerName: "Menge",
    width: 80,
    sortable: false,
  },
  { field: "factor", headerName: "Faktor", width: 80, sortable: false },
  {
    field: "percentage",
    headerName: "Prozentsatz",
    width: 110,
    sortable: false,
  },
  { field: "st", headerName: "ST", width: 50, sortable: false },
  { field: "sv", headerName: "SV", width: 50, sortable: false },
  { field: "gb", headerName: "GB", width: 60, sortable: false },
  {
    field: "amount",
    headerName: "Betrag",
    width: 200,
    sortable: false,
    editable: true,
  },
];

const dummyData = [
  {
    id: 1040,
    name: "Gehalt (laufend)",
    unit: "",
    number: "",
    factor: "",
    percentage: "",
    st: "Ja",
    sv: "Ja",
    gb: "Ja",
    amount: "5.666,67",
  },
];

function CustomHeader() {
  return (
    <CustomHeaderContainer>
      <Typography variant={"subtitle2"}>Brutto Bezüge</Typography>
      <Button variant={"contained"} color={"primary"}>
        Add Salarytype
      </Button>
    </CustomHeaderContainer>
  );
}

function CustomFooter() {
  return (
    <div
      style={{ display: "flex", justifyContent: "flex-end", padding: "5px" }}
    >
      <Typography variant={"body1"} marginRight={1}>
        Summe:{" "}
      </Typography>
      <Typography variant={"subtitle2"}>5,666.67 </Typography>
    </div>
  );
}

const SalarytypesGrid = (): JSX.Element => {
  return (
    <DataGrid
      columns={tableCells}
      rows={dummyData}
      components={{ Toolbar: CustomHeader, Footer: CustomFooter }}
      autoHeight
      disableColumnSelector
      disableDensitySelector
      disableColumnMenu
      style={{ marginBottom: 20 }}
    />
  );
};

export default SalarytypesGrid;
