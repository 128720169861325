import React from "react";
import { compose } from "lodash/fp";
import ColorPicker from "../../../../../../../../globalComponents/ColorPicker";
import useSelectedStyle, { UseSelectedStyleProps } from "./useSelected";
import { getColorKey, getColorPickerName } from "../utils";
import { getColorValue } from "../../../../../../../../globalComponents/ColorPicker/utils";

// components
const ColorPickerControl: React.FC<UseSelectedStyleProps> = (props) => {
  const [selectedStyle, setSelectedStyle] = useSelectedStyle(props);

  const onChangeHandler = compose(setSelectedStyle, getColorKey, getColorValue);
  const colorPickerName = getColorPickerName(selectedStyle);

  return <ColorPicker value={colorPickerName} onChange={onChangeHandler} />;
};

export default ColorPickerControl;
