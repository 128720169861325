import React from "react";
import { Grid, Typography } from "@mui/material";
import { GridStyled } from "./Verdienstbescheinigung";

const Nettobezuege = (): JSX.Element => {
  return (
    <div>
      <GridStyled
        container
        rowSpacing={1}
        height={"200px"}
        style={{ minWidth: "450px", marginLeft: "auto" }}
      >
        <Grid item xs={6}>
          <Typography variant={"h4"} marginBottom={2}>
            Nettobezüge
          </Typography>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={2}>
          <Typography variant={"subtitle2"}>Nr.</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={"subtitle2"}>Bezeichnung</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant={"subtitle2"}>Betrag</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={"subtitle2"}>8691</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={"subtitle2"}>Freiw. KV Anteil</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant={"subtitle2"}>358.60</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={"subtitle2"}>8969</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={"subtitle2"}>Freiw. PV Anteil</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant={"subtitle2"}>83.21</Typography>
        </Grid>
      </GridStyled>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: "50px",
        }}
      >
        <Typography variant={"body1"} marginRight={5}>
          Auszahlungsbetrag:{" "}
        </Typography>
        <Typography variant={"subtitle2"}>3,321.66 EUR </Typography>
      </div>
      <Grid
        container
        style={{
          maxWidth: "450px",
          marginLeft: "auto",
          marginTop: "20px",
          border: "1px solid lightgrey",
          padding: "5px",
          borderRadius: "5px",
        }}
      >
        <Grid item xs={8}>
          <Typography variant={"caption"}>Kontoinhaber</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant={"caption"}>Bank</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant={"subtitle2"}>John Watson</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant={"subtitle2"}>Deutsche Bank</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant={"caption"}>IBAN</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant={"caption"}>BIC</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant={"subtitle2"}>
            DE 5599 1009 2203 8812 23
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant={"subtitle2"}>HYVEDEMMXXX</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Nettobezuege;
