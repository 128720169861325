import React from "react";
import { Backdrop } from "@material-ui/core";
import { Slide, Typography } from "@mui/material";
import {
  SlideInBody,
  SlideInHeader,
  SlideInWrapper,
} from "../../smartOperations/smartCrud/styles";
import IconButtonNew from "../../buttons/IconButtonNew";
import { faTimes, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { faUserPlus } from "@fortawesome/pro-light-svg-icons";

export const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0px;
  right: 34px;
  height: 80px;
  padding-bottom: 20px;
  background-color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 310px;
`;

const SlideInFrame = (props: {
  title: string;
  confirmCallBack: () => void;
  closeCallBack: () => void;
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <>
      <Backdrop
        style={{ zIndex: 6 }}
        onClick={() => props.closeCallBack()}
        open
      />
      <Slide in direction={"left"} timeout={1000}>
        <SlideInWrapper>
          <SlideInHeader>
            <Typography variant={"h5"}>{props.title}</Typography>
            <IconButtonNew icon={faTimes} onClick={props.closeCallBack} />
          </SlideInHeader>
          <SlideInBody>{props.children}</SlideInBody>
        </SlideInWrapper>
      </Slide>
    </>
  );
};

export default SlideInFrame;
