import { ListItem, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

export interface FlapOutItemProps {
  icon: JSX.Element;
  title: string;
  link: string;
  closeFlap: React.Dispatch<React.SetStateAction<boolean>>;
}

const FlapOutItem = (props: FlapOutItemProps): React.ReactElement => {
  const history = useHistory();

  return (
    <>
      <ListItem
        button
        onClick={() => {
          history.push(props.link);
          props.closeFlap(false);
        }}
      >
        {props.icon}&nbsp;
        <Typography variant={"subtitle2"}>{props.title}</Typography>
      </ListItem>
    </>
  );
};

export default FlapOutItem;
