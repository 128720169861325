import React from "react";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import SelectMenuInput from "../../../../../../globalComponents/formFields/staticSelect/SelectMenuInput";
import berufsgenossenschaft from "../../../../../../config/selectLabels/berufsgenossenschaft";
import branche from "../../../../../../config/selectLabels/branche";
import { ContentContainer } from "./components/styledComponents";
import TextFieldController from "../../../../../../globalComponents/formFields/TextFieldController";

const Sozial2 = (): React.ReactElement => {
  const {
    control,
    formState: {},
    getValues,
  } = useFormContext();
  const social2 = getValues("social2");

  return (
    <ContentContainer>
      <Grid container rowSpacing={"28px"}>
        <Grid item xs={6}>
          <SelectMenuInput
            control={control}
            fieldName={"social2.berufsGenossenschaft"}
            menuItems={berufsgenossenschaft}
            errorMessage={"Bitte wählen Sie die Berufsgenossenschaft aus"}
            label={"Berufsgenossenschaft"}
            defaultValue={social2?.berufsgenossenschaft}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldController
            control={control}
            defaultValue={social2?.mitgliedsNummer}
            label={"Mitgliedsnummer"}
            fieldName={"social2.mitgliedsNummer"}
            rules={{ minLength: 3, required: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldController
            control={control}
            defaultValue={social2?.pin}
            label={"PIN"}
            fieldName={"social2.pin"}
            rules={{ minLength: 3, required: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectMenuInput
            label={"Branche"}
            control={control}
            fieldName={"social2.industry"}
            menuItems={branche}
            errorMessage={"Bitte wählen Sie die Branche aus"}
            defaultValue={social2?.industry}
          />
        </Grid>
      </Grid>
    </ContentContainer>
  );
};

export default Sozial2;
