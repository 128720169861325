import styled from "styled-components";
import { Link } from "react-router-dom";

const LinkStyled = styled(Link)`
  color: #054d80;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export default LinkStyled;
