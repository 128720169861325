import React from "react";
import styled from "styled-components";
import { Backdrop, Button, Typography } from "@mui/material";
import IconButtonNew from "../../buttons/IconButtonNew";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

const DeleteConfirmPopUpC = styled.div`
  position: absolute;
  width: 300px;
  background-color: #fbfbfb;
  padding: 0px 0px 10px 0px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 3px 0px lightgrey;
  border: 1px solid lightgrey;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px 10px 40px;
`;

const DeleteConfirmPopup = (props: {
  confirmCallBack: () => void;
  closeCallBack: () => void;
  text: string;
}): JSX.Element => {
  return (
    <Backdrop open style={{ zIndex: 3 }}>
      <DeleteConfirmPopUpC>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButtonNew onClick={props.closeCallBack} icon={faTimes} />
        </div>
        <ContentContainer>
          <Typography variant={"subtitle2"} align={"center"}>
            {props.text}
          </Typography>
        </ContentContainer>
        <ContentContainer>
          <Button
            variant={"contained"}
            color={"secondary"}
            onClick={props.closeCallBack}
          >
            Abbrechen
          </Button>
          &nbsp;&nbsp;
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={props.confirmCallBack}
          >
            Bestätigen
          </Button>
        </ContentContainer>
      </DeleteConfirmPopUpC>
    </Backdrop>
  );
};

export default DeleteConfirmPopup;
