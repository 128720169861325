import React, { useEffect, useState } from "react";
import { FormFieldType } from "../../dataCard/utils";
import SmartSlideInUpdate from "./SmartSlideInUpdate";
import styled from "styled-components";
import SmartSlideInCreate from "./SmartSlideInCreate";

import { LabelValuePair } from "../../formFields/staticSelect/SelectMenuInput";
import SmartTable, { ResponseType } from "../smartTable/SmartTable";
import { GridColDef } from "@mui/x-data-grid";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px 40px 20px 40px;
`;

export enum RenderOnlyOn {
  EDIT = "EDIT",
  ADD = "ADD",
}

interface GridColDefFormFields extends GridColDef {
  formType: FormFieldType | boolean;
  defaultValue?: any;
  validation?: { required: boolean };
  minutesFieldName?: string;
  hoursFieldName?: string;
  asRequestParameter?: boolean;
  conditionalRender?: boolean;
  conditionalFields?: string[];
  menuItems?: LabelValuePair[];
  requestFieldName?: string;
  renderOnlyOn?: RenderOnlyOn;
}

interface SmartCrudProps {
  title: string;
  input: ResponseType;
  updateInputCallback: () => void;
  createButtonLabel: string;
  endpoints: {
    getEndpointDetail: string;
    postEndpoint: {
      url: string;
      requestParameter?: LabelValuePair[];
      bodyParameter?: LabelValuePair[];
      type: "POST";
    };
    putPatchEndpoint: {
      url: string;
      type: "PUT" | "PATCH";
      idAsRequestParamLabel?: string;
      idAsBodyParamLabel?: string;
      idAsPathParameter?: boolean;
      requestParameter?: LabelValuePair[];
      bodyParameter?: LabelValuePair[];
    };
    deleteEndpoint: string;
  };
  deleteConfirmMessage: string;

  parameter: GridColDefFormFields[];
}

export type slideInType = "UPDATE" | "CREATE" | "DELETE";

const SmartCrud = (props: SmartCrudProps): JSX.Element => {
  const [slideIn, setslideIn] = useState<{
    type: slideInType | null;
    id: number;
    reload: boolean;
  }>({
    id: 0,
    type: null,
    reload: false,
  });

  useEffect(() => {
    if (slideIn.reload) {
      props.updateInputCallback();
      setslideIn({ ...slideIn, reload: false });
    }
  }, [slideIn.reload]);

  const requestParameter =
    props.endpoints.putPatchEndpoint.requestParameter || [];

  if (props.endpoints.putPatchEndpoint.idAsRequestParamLabel) {
    requestParameter?.push({
      label: props.endpoints.putPatchEndpoint.idAsRequestParamLabel,
      value: slideIn.id,
    });
  }

  const bodyParameter = props.endpoints.putPatchEndpoint.bodyParameter || [];

  if (props.endpoints.putPatchEndpoint.idAsBodyParamLabel) {
    bodyParameter?.push({
      label: props.endpoints.putPatchEndpoint.idAsBodyParamLabel,
      value: slideIn.id,
    });
  }

  const pathParameter = props.endpoints.putPatchEndpoint.idAsPathParameter;
  const pathParameters: string[] = [];
  if (pathParameter) {
    pathParameters.push(String(slideIn.id));
    console.log(pathParameters);
  }
  return (
    <>
      <SmartTable
        title={props.title}
        editIconAction={{
          callbackFunc: (r) =>
            setslideIn({ type: "UPDATE", id: r, reload: false }),
        }}
        createButton={{
          label: props.createButtonLabel,
          callbackFunc: () =>
            setslideIn({ type: "CREATE", id: 0, reload: false }),
        }}
        columns={props.parameter
          .filter(
            (item1) => item1.formType !== FormFieldType.BOOLEAN_WATCH_FIELD
          )
          .map((item) => {
            return item as GridColDef;
          })}
        deleteEndpoint={props.endpoints.deleteEndpoint}
        deleteConfirmMessage={props.deleteConfirmMessage}
        input={props.input}
        updateInputCallback={() => props.updateInputCallback()}
      />

      {slideIn.type == "UPDATE" ? (
        <SmartSlideInUpdate
          backdropCallback={() => setslideIn({ ...slideIn, type: null, id: 0 })}
          type={slideIn.type}
          updateId={slideIn.id}
          formFields={props.parameter
            .filter((item) => item.renderOnlyOn !== "ADD")
            .map((item) => {
              return {
                type: item.formType,
                fieldName: item.field,
                minutesFieldName: item.minutesFieldName,
                hoursFieldName: item.hoursFieldName,
                label: item.headerName as string,
                asRequestParameter: item.asRequestParameter,
                defaultValue:
                  item.defaultValue !== undefined ? item.defaultValue : "",
                validation: item.validation,
                menuItems: item.menuItems,
                conditionalRender: item.conditionalRender,
                conditionalFields: item.conditionalFields,
                requestFieldName: item.requestFieldName,
                renderOnlyOn: item.renderOnlyOn,
              };
            })}
          close={(r: React.ComponentState) => setslideIn(r)}
          getEndpointDetail={props.endpoints.getEndpointDetail}
          putPatchEndpoint={{
            ...props.endpoints.putPatchEndpoint,
            requestParameter: requestParameter,
            bodyParameter: bodyParameter,
            patchParameter: pathParameters,
          }}
          title={"Bearbeiten"}
        />
      ) : (
        <></>
      )}
      {slideIn.type == "CREATE" ? (
        <SmartSlideInCreate
          backdropCallback={() => setslideIn({ ...slideIn, type: null, id: 0 })}
          type={slideIn.type}
          formFields={props.parameter
            .filter((item) => item.renderOnlyOn !== "EDIT")
            .map((item) => {
              return {
                type: item.formType,
                fieldName: item.field,
                label: item.headerName as string,
                asRequestParameter: item.asRequestParameter,
                minutesFieldName: item.minutesFieldName,
                hoursFieldName: item.hoursFieldName,
                defaultValue:
                  item.defaultValue !== undefined ? item.defaultValue : "",
                validation: item.validation,
                menuItems: item.menuItems,
                conditionalRender: item.conditionalRender,
                conditionalFields: item.conditionalFields,
              };
            })}
          close={() => setslideIn({ type: null, id: 0, reload: false })}
          confirmAndClose={() =>
            setslideIn({ type: null, id: 0, reload: true })
          }
          title={"Anlegen"}
          postEndpoint={props.endpoints.postEndpoint}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default SmartCrud;
