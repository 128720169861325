import React from "react";
import { Route, Switch } from "react-router-dom";
import Teaser from "./home/Home";
import Email from "./register/Email";
import VerificationCode from "./register/VerificationCode";
import Password from "./register/Password";

const Homepage = (): React.ReactElement => {
  return (
    <>
      <Switch>
        <Route path="/register/email">
          <Email />
        </Route>
        <Route path="/register/code">
          <VerificationCode />
        </Route>
        <Route path="/register/password">
          <Password />
        </Route>
        <Route path="/reset/email">
          <Email reset />
        </Route>
        <Route path="/community">
          <Teaser />
        </Route>
        <Route path="/*">
          <Teaser />
        </Route>
      </Switch>
    </>
  );
};

export default Homepage;
