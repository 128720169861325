import React from "react";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { ContentContainer } from "./components/styledComponents";
import SelectBoolean from "../../../../../../globalComponents/formFields/SelectBoolean";
import TextFieldController from "../../../../../../globalComponents/formFields/TextFieldController";

const Sozial = (): React.ReactElement => {
  const {
    control,
    formState: {},
    getValues,
  } = useFormContext();

  const social = getValues("social");

  return (
    <ContentContainer>
      <Grid container rowSpacing={"28px"}>
        <Grid item xs={6}>
          <TextFieldController
            control={control}
            defaultValue={social?.betriebsNummer}
            label={"Betriebsnummer"}
            fieldName={"social.betriebsNummer"}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectBoolean
            control={control}
            fieldName={"social.sofortmeldepflicht"}
            label={"Sofortmeldepflicht"}
            errorText={
              "Bitte wählen Sie aus ob ein Sepa Lastschriftmandat erteilt wurde."
            }
            defaultValue={social?.sofortmeldepflicht}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectBoolean
            control={control}
            fieldName={"social.umlage"}
            label={"Umlageverfahren"}
            errorText={
              "Bitte wählen Sie aus ob die Firma am Umlageverfahren teilnimmt."
            }
            defaultValue={social?.umlage}
          />
        </Grid>
      </Grid>
    </ContentContainer>
  );
};

export default Sozial;
