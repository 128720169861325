import React, { useState } from "react";
import styled from "styled-components";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClickAwayListener, Typography } from "@mui/material";

const TooltipContainer = styled.div<{ active: boolean }>`
  position: relative;
  width: 20px;
  height: 20px;
  background-color: ${(props) => (props.active ? "#dae0e6" : "#e6edf2")};
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #dae0e6;
    cursor: pointer;
  }
`;

const Tip = styled.div`
  position: absolute;
  min-width: 200px;
  min-height: 40px;
  padding: 10px;
  border: 1px solid #cccccc;
  box-shadow: 0px 8px 16px #4c4c4c24;
  border-radius: 4px;
  left: 30px;
  top: -20px;
  background-color: white;
  z-index: 2;
`;

export interface TooltipNewProps {
  message: string;
}

const TooltipNew = (props: TooltipNewProps): JSX.Element => {
  const [show, setShow] = useState(false);

  return (
    <TooltipContainer onClick={() => setShow(!show)} active={show}>
      <FontAwesomeIcon icon={faQuestion} size={"xs"} color={"#054D80"} />
      {show ? (
        <ClickAwayListener onClickAway={() => setShow(false)}>
          <Tip>
            <Typography variant={"body1"}>{props.message}</Typography>
          </Tip>
        </ClickAwayListener>
      ) : (
        <></>
      )}
    </TooltipContainer>
  );
};

export default TooltipNew;
