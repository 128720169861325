import React, { useEffect } from "react";
import { faColumns, faSitemap } from "@fortawesome/free-solid-svg-icons";
import CompanyCalendar from "./CompanyCalendar";
import { AbsenceTypesProvider } from "./AbsenceTypesContext";
import CompanyAbsencesTable from "./TableView/CompanyAbsencesTable";
import { Typography } from "@mui/material";
import { PageTitle } from "../../../../globalComponents/PageHeader/PageTitle";
import ViewContainer from "../../../../globalComponents/container/ViewContainer";
import { Link } from "react-router-dom";
import Icon from "../../../../globalComponents/container/Icon";

type Props = {
  view: "table" | "calendar";
};

const Absences = (props: Props): React.ReactElement => {
  return (
    <>
      <PageTitle>
        <Typography variant={"h1"}>Urlaub und Abwesenheiten</Typography>
        <ViewContainer>
          <Typography variant={"subtitle2"} marginRight={"10px"}>
            Ansicht:{" "}
          </Typography>
          <br />
          <Link to={"/app/absences"}>
            <Icon icon={faSitemap} />
          </Link>
          <br />
          <Link to={"/app/absences/table"}>
            <Icon icon={faColumns} />
          </Link>
        </ViewContainer>
      </PageTitle>
      <AbsenceTypesProvider>
        {props.view === "calendar" ? (
          <CompanyCalendar />
        ) : (
          <CompanyAbsencesTable />
        )}
      </AbsenceTypesProvider>
    </>
  );
};

export default Absences;
