const geoUrl = {
  type: "Topology",
  transform: {
    scale: [0.0005727051063921633, 0.00035576127130473607],
    translate: [5.8720525325849255, 45.82165900000001],
  },
  arcs: [
    [
      [6447, 4823],
      [-104, 73],
    ],
    [
      [6343, 4896],
      [-416, 278],
      [-65, -7],
      [-199, 35],
      [-67, 32],
      [-127, 10],
      [-95, -70],
      [-107, -16],
      [-48, 82],
      [29, 36],
      [-63, 59],
      [-49, 9],
      [-12, 53],
      [-65, -62],
      [50, -39],
      [-5, -60],
      [-115, 40],
    ],
    [
      [4989, 5276],
      [15, 56],
      [-55, 46],
      [46, 65],
      [-20, 30],
      [-119, 21],
      [-35, -17],
      [-30, 36],
      [14, 44],
      [-28, 23],
      [-56, -3],
      [-48, -54],
      [-109, -31],
      [-63, -94],
      [7, -44],
      [-70, -52],
      [13, -66],
      [-27, -17],
      [98, -87],
      [143, 36],
      [34, 35],
      [27, -22],
      [49, 25],
    ],
    [
      [4775, 5206],
      [-21, -73],
      [14, -88],
      [-36, -51],
      [-44, 4],
      [8, 66],
      [-49, 42],
      [-43, -48],
      [-52, -9],
      [-35, -75],
      [39, -36],
      [-102, -25],
    ],
    [
      [4454, 4913],
      [-41, 20],
      [-124, -15],
      [-59, 52],
      [-21, 75],
      [-47, 7],
      [-67, -22],
      [-35, 37],
      [-60, -78],
      [-100, -32],
      [-21, -65],
      [-142, -27],
      [-10, 21],
      [-95, -7],
      [-67, -20],
      [-12, 75],
      [-30, 48],
      [-80, -22],
      [-44, 22],
      [-18, -54],
      [-36, -44],
      [-65, -30],
      [-66, -11],
    ],
    [
      [3214, 4843],
      [-31, -23],
      [-44, 9],
      [-44, 36],
      [-10, 37],
    ],
    [
      [3085, 4902],
      [57, 9],
      [25, 30],
      [-18, 51],
      [-52, 7],
      [-42, -55],
      [-33, 26],
    ],
    [
      [3022, 4970],
      [-22, 60],
      [-43, 40],
      [-1, 31],
      [-72, 66],
      [-23, 82],
      [55, 97],
      [7, 80],
      [-22, 51],
      [15, 124],
      [37, 82],
      [-14, 96],
      [32, 65],
      [22, 96],
      [56, 73],
      [10, 62],
      [-10, 55],
      [-67, 78],
      [-13, 148],
      [15, 106],
      [32, 47],
      [0, 46],
      [37, 53],
      [53, 118],
      [38, 33],
      [48, 232],
      [83, 72],
      [-25, 190],
      [61, 161],
      [5, 105],
      [33, 9],
      [30, 56],
      [-2, 184],
      [77, 185],
      [77, 44],
      [130, 172],
      [7, 94],
      [79, 3],
      [35, 75],
      [52, 2],
      [58, 65],
      [17, 81],
      [55, 162],
      [58, 76],
      [37, 85],
      [68, 34],
    ],
    [
      [4127, 8846],
      [104, 100],
      [34, 148],
      [83, 120],
      [23, 159],
      [-11, 41],
      [63, 139],
      [-30, 40],
      [27, 43],
      [58, 35],
      [28, 54],
      [68, 56],
      [-72, 88],
      [77, 78],
      [18, 73],
      [-21, 50],
      [28, 84],
      [-21, 40],
      [-79, -4],
      [-11, 40],
      [39, 34],
      [-42, 80],
      [-43, 142],
      [18, 94],
    ],
    [
      [4465, 10580],
      [70, 14],
      [102, -108],
      [11, -41],
      [41, -39],
      [59, 4],
      [49, 54],
      [-11, 102],
      [-31, 50],
      [9, 42],
      [150, 48],
      [13, -195],
      [31, -60],
      [101, -57],
      [50, 2],
      [71, -38],
      [111, 13],
      [0, -61],
      [-39, -42],
      [-31, 40],
      [-60, -33],
      [12, -133],
      [-60, -32],
      [30, -40],
      [40, 32],
      [30, -30],
      [70, 73],
      [20, 42],
      [80, 42],
      [-31, 61],
      [40, 53],
      [101, 22],
      [51, -10],
      [19, 62],
      [81, 11],
      [10, -52],
      [40, 21],
      [-20, 52],
      [-51, 20],
      [30, 52],
      [-1, 52],
    ],
    [
      [5652, 10573],
      [30, -21],
      [51, 21],
      [80, -21],
      [61, 10],
      [59, 41],
      [40, 62],
      [-21, 52],
      [29, 31],
      [101, 0],
      [29, 31],
      [41, -42],
      [59, 42],
      [-41, 42],
      [49, 62],
      [-41, 94],
      [-30, -11],
      [1, -52],
      [-50, 0],
      [19, 73],
      [-60, 11],
      [-31, 93],
      [69, 0],
      [30, 32],
      [40, -32],
      [49, 53],
      [40, -42],
      [39, 0],
      [19, 42],
      [60, -20],
      [40, -42],
      [38, 32],
      [59, 1],
      [97, 33],
      [-27, -106],
      [2, -73],
      [-18, -74],
      [51, -41],
      [39, 21],
      [-2, 63],
      [70, 11],
      [1, -42],
      [32, -51],
      [28, 63],
      [79, 42],
      [72, -93],
      [-19, -63],
      [12, -73],
      [30, 1],
      [42, -104],
      [-39, -32],
      [-8, -62],
      [-49, -53],
      [71, -30],
      [8, 62],
      [39, 42],
      [49, 12],
      [-28, -74],
      [50, 1],
      [1, -62],
      [-17, -125],
      [31, -31],
      [119, 2],
      [88, 74],
      [17, 104],
      [51, -41],
      [-29, -42],
      [80, -20],
      [-39, -42],
      [42, -82],
      [-49, -52],
      [70, -20],
      [52, -124],
      [-99, -42],
      [11, -42],
      [-19, -72],
      [81, -61],
      [-29, -63],
      [38, -35],
      [-46, -89],
      [12, -134],
      [100, -81],
      [11, -41],
      [60, 12],
      [51, -92],
      [-80, -43],
      [11, -41],
      [71, -81],
      [-20, -52],
      [61, -40],
      [111, -50],
      [50, -40],
      [11, -41],
      [40, -10],
      [49, -104],
      [39, -32],
      [-10, -72],
      [10, -52],
      [-20, -156],
      [9, -41],
      [-49, -72],
      [0, -135],
      [58, -43],
      [39, -52],
      [10, -73],
      [-59, -61],
      [-49, 11],
      [-10, 83],
      [-49, -30],
      [0, -52],
      [-69, 1],
      [-19, 62],
      [-40, -20],
      [-60, 52],
      [-50, -63],
      [10, -61],
      [109, -136],
      [-49, -9],
      [29, -73],
      [-30, -176],
      [-79, 12],
      [-59, -84],
      [-58, -44],
      [-19, -42],
      [-69, -13],
      [-49, 61],
      [-29, -43],
      [-98, -13],
      [10, -73],
      [-38, -74],
      [-88, -76],
      [20, -51],
      [149, -226],
      [-9, -63],
      [10, -94],
      [30, -42],
      [1, -84],
      [39, -135],
      [49, -52],
      [10, -42],
      [1, -147],
      [-29, -42],
      [20, -42],
      [-29, -32],
      [-9, -84],
      [-39, -11],
      [-19, -53],
      [48, -52],
      [-19, -84],
      [10, -42],
      [1, -147],
      [48, -52],
      [-9, -21],
      [-96, -65],
      [19, -41],
      [48, -42],
      [0, -116],
      [39, -63],
      [-10, -63],
      [-97, -44],
      [-28, 63],
      [-48, -21],
      [-9, 42],
      [-48, -21],
      [-56, -84],
      [-124, 30],
      [-58, 41],
      [-48, -11],
      [-9, -42],
      [-47, -21],
      [-9, -42],
      [-95, -85],
      [-57, -12],
      [-67, 29],
      [-48, -54],
      [-66, -24],
      [-71, -136],
    ],
    [
      [10574, 12945],
      [98, -94],
      [8, -42],
      [-22, -63],
      [156, -51],
      [104, -70],
    ],
    [
      [10918, 12625],
      [2, -109],
      [-50, -22],
      [8, -65],
      [78, -42],
      [67, -55],
      [44, -101],
      [-29, -76],
      [10, -96],
      [108, -55],
      [20, -40],
      [-29, -44],
      [104, -7],
      [18, -49],
      [87, -73],
      [162, -58],
      [50, -55],
      [-37, -102],
      [64, -30],
      [61, -6],
      [-3, -90],
      [-46, -30],
      [-5, -65],
      [-57, -52],
      [-27, -82],
      [-4, -80],
      [-100, -53],
      [-1, -60],
      [40, -34],
      [35, -84],
      [84, -42],
      [50, -1],
      [6, -125],
      [-11, -31],
      [78, -63],
      [-1, -32],
      [33, -153],
      [14, -22],
      [87, -14],
      [-6, -67],
      [9, -100],
      [23, -111],
      [39, -21],
      [47, 5],
      [29, -48],
      [58, -26],
      [-2, -52],
      [77, -95],
      [79, -1],
      [50, 19],
      [133, -17],
      [100, -109],
      [41, -3],
      [7, -115],
      [41, -2],
      [20, -53],
      [40, -12],
      [8, -60],
      [25, -10],
      [57, -109],
      [68, -37],
      [-10, -63],
      [69, -66],
      [42, -19],
      [91, 17],
      [76, -65],
      [74, -106],
      [38, -22],
      [8, -52],
      [1, -150],
      [100, -53],
      [65, -57],
      [12, 78],
      [137, 1],
      [17, -59],
      [46, -23],
      [49, -123],
      [32, -36],
      [107, -4],
      [33, -20],
      [20, -100],
      [82, -105],
      [4, -73],
      [42, -10],
    ],
    [
      [13906, 8293],
      [-45, -103],
      [-41, -39],
      [48, -53],
      [3, -119],
      [17, -54],
      [-41, -180],
      [-38, -15],
      [-67, -64],
      [7, -38],
      [-44, -52],
      [-104, 59],
      [-15, 37],
      [-102, 71],
      [-48, -30],
      [-83, 86],
      [-30, -51],
      [-59, -50],
      [-37, 32],
      [-21, -51],
      [27, -32],
      [7, -62],
      [-54, -175],
      [23, -80],
      [-46, -145],
      [-73, -62],
      [-36, -50],
      [-88, -79],
      [-88, -39],
      [-109, 7],
      [-76, -36],
      [-68, -4],
      [-142, -67],
      [-101, -122],
      [-30, 1],
      [-136, -39],
      [-21, -75],
      [-31, -42],
      [-61, -29],
      [-29, -65],
      [-59, -31],
      [8, -84],
      [28, -42],
      [150, -146],
      [8, -63],
    ],
    [
      [12209, 6118],
      [30, -105],
      [53, -5],
      [42, -38],
      [17, -58],
      [97, -204],
      [-33, -86],
      [-61, -98],
      [4, -33],
      [-67, -123],
      [34, -35],
      [95, -19],
      [53, 49],
      [49, -24],
      [34, -61],
      [33, -22],
      [-6, -56],
      [47, -91],
      [-59, -69],
      [9, -77],
      [-50, -7],
      [19, -76],
      [-39, -60],
      [29, -55],
      [1, -62],
      [-86, -73],
      [-35, 45],
      [-26, -22],
      [-99, 55],
      [-49, 78],
      [-58, 22],
      [0, 46],
      [-67, 13],
      [-56, 80],
      [31, 71],
      [48, 26],
      [-97, 81],
      [-17, 58],
      [-43, 47],
      [-109, 7],
      [-37, -30],
      [-83, 14],
      [-55, -124],
    ],
    [
      [11702, 5097],
      [-72, 11],
      [-46, -28],
      [-69, 71],
      [-82, 125],
      [-95, -35],
      [-62, 26],
      [-86, -33],
      [-57, 11],
      [10, 63],
      [29, 53],
      [-28, 31],
      [-39, -73],
      [-39, -32],
      [-61, -10],
      [8, -50],
      [45, -76],
      [28, -124],
      [-21, -25],
      [-66, 22],
      [-58, -20],
      [-250, 46],
      [-96, -30],
      [-29, 11],
      [-87, -40],
      [-29, -51],
      [-106, 12],
      [-33, 32],
      [-191, -24],
      [-55, 34],
      [-76, -128],
      [8, -84],
      [-29, -31],
      [-125, -19],
      [-96, 2],
      [-29, 21],
      [-87, -116],
      [46, -18],
      [17, -56],
      [-70, 17],
      [-74, 0],
      [-30, -44],
      [-53, -17],
      [5, -54],
      [-20, -45],
      [-78, -10],
      [-9, 33],
      [38, 65],
      [-19, 24],
      [-79, -5],
      [-87, -26],
      [-94, -79],
      [-94, -2],
      [-50, 17],
      [-81, -4],
      [17, 64],
      [-50, 56],
      [-68, 101],
      [-83, 0],
      [13, 67],
      [47, 51],
      [-25, 51],
      [-66, -7],
      [-128, -55],
      [-45, 65],
      [-96, 18],
      [-9, 41],
      [-104, 4],
      [-47, 23],
      [-35, -39],
      [-8, -41],
      [-90, -3],
      [-57, 13],
      [-28, 33],
      [-29, 81],
      [-43, -38],
      [32, -41],
      [-37, -142],
      [13, -53],
      [35, -11],
      [23, -144],
      [-62, -49],
      [-32, -92],
      [-48, -21],
      [-7, -44],
      [-61, -74],
      [-49, -86],
      [-28, 0],
      [-56, -51],
      [-21, 10],
      [-55, -51],
      [-59, 21],
      [-44, -21],
    ],
    [
      [7509, 4071],
      [-3, 63],
      [38, 12],
      [29, 42],
      [-19, 54],
      [58, 117],
      [-92, 59],
      [-32, -74],
      [-75, 19],
      [-37, -43],
      [-41, 157],
      [49, 48],
      [-22, 54],
      [-67, 45],
      [-11, 63],
      [-81, -8],
      [-9, 58],
      [-54, 45],
      [-11, 39],
      [-60, -13],
      [-25, 38],
      [-44, 8],
      [-13, -50],
      [-43, 35],
      [-63, 21],
      [19, 35],
      [-2, 62],
      [-37, 34],
      [-47, -1],
      [-48, -64],
      [-22, -108],
      [-206, 0],
      [-90, 5],
    ],
    [
      [6448, 4823],
      [-1, 0],
    ],
    [
      [5652, 10573],
      [-62, 113],
      [70, 63],
      [-31, 72],
      [-1, 62],
      [70, 42],
      [29, 83],
      [-20, 52],
      [-2, 114],
      [-70, 9],
      [-21, 61],
      [-51, 82],
      [-12, 124],
      [-21, 41],
      [-3, 238],
      [20, 31],
      [-1, 104],
      [-72, -2],
      [-21, 30],
      [40, 53],
      [-1, 62],
      [29, 53],
      [132, 14],
      [50, 23],
      [62, -50],
      [57, 43],
      [-11, 41],
      [66, 33],
      [49, -30],
      [58, 12],
      [30, -31],
      [38, 21],
      [69, -20],
      [73, -146],
      [96, 34],
      [79, 1],
      [8, 52],
      [-22, 63],
      [37, 95],
      [-40, 0],
      [-12, 73],
      [8, 53],
      [-21, 42],
      [68, 22],
      [70, -10],
      [41, -31],
      [118, 54],
      [-51, 31],
      [29, 53],
      [70, 22],
      [79, 63],
      [8, 127],
      [-22, 31],
      [40, 85],
      [8, 107],
      [72, 1],
      [43, -53],
      [103, -20],
      [61, 32],
      [122, 96],
      [61, 75],
      [31, 63],
      [-1, 64],
    ],
    [
      [7277, 13195],
      [103, 95],
      [20, 43],
      [52, -63],
      [20, 21],
      [93, 10],
      [0, -31],
      [51, -32],
      [-10, -42],
      [51, -10],
      [62, -52],
      [62, -11],
      [21, -73],
      [72, -105],
      [41, -32],
      [-30, -42],
      [51, -20],
      [72, 21],
      [10, -63],
      [52, -21],
      [0, -53],
      [41, -10],
      [31, 42],
      [41, -21],
      [-10, -42],
      [51, -31],
      [52, -115],
      [-21, -53],
      [32, -137],
      [122, -10],
      [21, -31],
      [60, 1],
      [0, 115],
      [41, -31],
      [50, 32],
      [70, -42],
      [40, 22],
      [0, 83],
      [-70, 10],
      [-20, 42],
      [-40, 0],
      [-91, 94],
      [20, 41],
      [-21, 63],
      [61, -10],
      [129, 83],
      [141, -9],
      [40, 21],
      [101, -104],
      [50, 11],
      [30, -42],
      [80, 21],
      [10, 42],
      [41, 0],
      [60, -72],
      [20, -52],
      [-50, -32],
      [31, -73],
      [70, -10],
      [20, 32],
      [80, -73],
      [11, 73],
      [31, 42],
      [-20, 42],
      [10, 83],
      [31, 11],
      [-10, 73],
      [-30, 62],
      [21, 42],
      [-40, 167],
      [92, 32],
      [41, 0],
      [11, 53],
      [72, 42],
      [20, -21],
      [82, 1],
      [20, -42],
      [-31, -42],
      [-11, -94],
      [61, -63],
      [51, 11],
      [0, -73],
      [61, -31],
      [30, -42],
      [62, 21],
      [41, 42],
      [51, -42],
      [133, 22],
      [155, 74],
      [70, -84],
      [103, 64],
      [73, 32],
    ],
    [
      [13288, 19264],
      [9, -52],
      [63, -33],
      [-35, -114],
      [94, -54],
      [52, -54],
      [9, -52],
      [42, -11],
      [51, -75],
      [-46, -113],
      [53, 9],
      [73, -43],
      [53, -2],
      [50, -64],
      [-1, -41],
      [-44, -72],
      [-44, -41],
      [10, -31],
      [-43, -30],
      [-33, -62],
      [-31, 42],
      [-9, 63],
      [-43, -20],
      [-19, 63],
      [-106, -18],
      [-31, 32],
      [-64, 2],
      [-19, 52],
      [-86, -29],
      [19, -73],
      [-74, -19],
      [-83, 85],
      [-54, -30],
      [-20, 42],
      [-43, -30],
      [-42, 11],
      [-10, 42],
      [-42, 1],
      [-129, -70],
      [-32, 1],
      [-73, 64],
      [65, 51],
      [-19, 83],
      [98, 113],
      [-94, 44],
      [67, 144],
      [2, 62],
      [63, -22],
      [56, 113],
      [74, -12],
      [56, 82],
      [19, -73],
      [96, -13],
      [54, 61],
      [64, -22],
      [34, 72],
      [43, 41],
    ],
    [
      [14918, 21104],
      [13, -145],
      [37, -4],
      [5, -56],
      [-81, -134],
      [-38, -14],
      [-15, -130],
      [28, -24],
      [-7, -97],
      [-39, -134],
      [-35, -53],
      [-99, -72],
      [-22, -40],
      [-117, -54],
      [-107, -79],
      [23, -229],
      [-58, -69],
      [-2, -41],
      [31, -42],
      [79, -19],
      [52, 5],
      [73, -96],
      [109, -54],
      [93, -81],
      [88, -124],
      [59, -42],
      [18, -62],
      [176, -88],
      [69, -57],
      [3, -48],
      [42, -25],
      [2, -38],
      [-47, -54],
      [8, -101],
      [26, -11],
      [-8, -64],
      [-83, -112],
      [-53, -20],
      [-3, -74],
      [-22, -41],
      [30, -44],
      [7, -87],
      [62, -134],
      [-26, -36],
      [57, -45],
      [67, -28],
      [75, -11],
      [26, -52],
      [-14, -84],
      [-29, -21],
      [34, -84],
      [-40, -63],
      [-4, -84],
      [35, -62],
      [46, -11],
      [44, -73],
      [-12, -63],
      [-74, -179],
      [23, -115],
      [-43, -105],
      [-130, -163],
      [-25, -15],
      [8, -101],
      [64, -7],
      [27, -24],
      [14, -176],
      [39, -22],
      [3, -38],
      [67, -51],
      [35, -83],
      [-4, -84],
      [17, -63],
      [-62, -74],
    ],
    [
      [15433, 16199],
      [-37, 0],
      [-22, -74],
      [-83, -14],
      [-103, 54],
      [-33, -50],
      [-102, -14],
      [-61, 5],
      [-11, -31],
      [-63, -27],
      [-41, 14],
      [-33, -40],
      [-63, -26],
      [-89, 49],
      [-51, -6],
      [-80, 28],
      [-111, 11],
      [7, -53],
      [-50, 5],
      [-37, -123],
      [-71, -14],
      [34, -79],
      [-32, -18],
      [-3, -54],
      [-79, -134],
      [-49, 14],
      [-27, 55],
      [-75, -50],
      [-83, -8],
      [-41, 12],
      [-33, -30],
      [-72, 2],
      [-63, -29],
      [-103, 24],
      [-155, 5],
      [-9, 42],
      [-115, -17],
      [-8, 63],
      [-42, 1],
      [-122, 76],
      [-50, 64],
      [-33, -51],
      [-42, 1],
      [-74, -29],
      [-53, -41],
      [9, -31],
      [-125, -7],
      [-39, 115],
      [12, 62],
      [-31, 32],
      [45, 113],
      [-40, 74],
      [2, 52],
      [-53, 1],
      [-30, 42],
      [33, 51],
      [-61, 64],
      [-74, -19],
      [-61, 116],
    ],
    [
      [12557, 16377],
      [203, 120],
      [-20, 42],
      [54, 51],
      [-51, 53],
      [-9, 73],
      [23, 93],
      [-70, 126],
      [44, 72],
      [-31, 22],
      [-86, -40],
      [-84, 12],
      [13, 83],
      [-127, 3],
      [12, 52],
      [-31, 42],
      [-73, 12],
      [-1, -31],
      [-116, 13],
      [-8, 94],
      [-54, -20],
      [-73, 12],
      [1, 41],
      [-74, 23],
      [-52, 42],
      [-95, 13],
      [-11, -42],
      [-43, -30],
      [-138, 3],
      [-9, 52],
      [-74, 22],
      [-20, 63],
      [-54, -40],
      [-42, 0],
      [-62, 74],
      [-53, 1],
      [-73, 95],
      [1, 32],
      [-84, 43],
      [-10, 62],
      [-83, 106],
      [12, 62],
      [32, 20],
      [33, 62],
      [53, 20],
      [-84, 84],
      [46, 156],
      [32, 41],
      [23, 72],
      [1, 63],
      [-21, 42],
      [22, 51],
      [-20, 53],
      [56, 145],
      [-31, 42],
      [-43, 1],
      [-42, 32],
      [0, 41],
      [-97, -61],
      [-63, 33],
      [33, 72],
      [-64, 22],
      [45, 104],
      [1, 83],
      [54, 51],
      [32, 0],
      [23, 62],
      [-9, 115],
      [-53, 63],
      [1, 52],
      [33, 31],
      [-31, 53],
      [33, 83],
      [54, 20],
      [-40, 146],
      [-74, 85],
      [-22, -41],
      [-76, -20],
      [-20, 53],
      [-75, 32],
      [-129, 2],
      [-54, -30],
      [-75, 43],
      [-54, 0],
      [-64, 54],
      [-43, -10],
      [-31, 52],
      [43, 31],
      [-31, 63],
      [-76, 1],
      [-21, 53],
      [-64, 32],
      [-54, -30],
      [-43, 21],
      [0, 63],
      [-75, -10],
      [-22, 43],
      [33, 41],
      [-43, 21],
      [-43, -20],
    ],
    [
      [10005, 20266],
      [-76, 53],
      [-43, -20],
      [-43, 11],
      [-86, 85],
      [-98, -30],
      [-87, -41],
      [-141, 138],
      [0, 52],
    ],
    [
      [9431, 20514],
      [76, -22],
      [142, 19],
      [0, 53],
      [130, -2],
      [11, -32],
      [108, -11],
      [33, 41],
      [-10, 32],
      [33, 62],
      [-22, 21],
      [12, 94],
      [43, 0],
      [77, 82],
      [119, -1],
      [54, -74],
      [76, 30],
      [98, -1],
      [11, 20],
      [-65, 54],
      [120, 8],
      [76, 52],
      [87, 9],
      [23, 52],
      [87, 19],
      [1, 95],
      [55, 30],
      [-10, 63],
      [54, -1],
      [-1, -63],
      [97, -1],
      [54, -22],
      [44, 41],
      [65, 9],
      [11, -42],
      [42, -32],
      [87, -12],
      [85, -54],
      [65, -12],
      [-1, -63],
      [64, -22],
      [42, -53],
      [76, -1],
      [55, 30],
      [139, -55],
      [33, 31],
      [64, -2],
      [-1, -63],
      [32, -21],
      [129, -3],
      [-23, -73],
      [64, -32],
      [54, 30],
      [44, -1],
      [74, -54],
      [55, 51],
      [108, 8],
      [-23, -41],
      [31, -64],
      [76, 51],
      [34, 51],
      [54, 9],
      [55, 41],
      [2, 62],
      [76, 30],
      [64, -2],
      [31, -74],
      [43, -1],
      [35, 104],
      [88, 60],
      [108, -3],
      [40, -95],
      [33, 10],
      [58, 144],
      [75, -23],
      [33, 31],
      [48, 145],
      [46, 71],
      [2, 63],
      [55, 30],
      [65, 8],
      [23, 31],
      [2, 63],
      [124, 90],
      [43, 9],
      [120, -3],
      [2, 52],
      [-41, 43],
      [-9, 41],
      [25, 83],
      [61, -96],
      [96, -54],
      [-4, -84],
      [74, -64],
      [-25, -72],
      [132, 27],
      [110, -15],
      [19, -52],
      [45, 8],
      [71, 81],
      [33, -1],
      [75, -55],
      [44, -1],
      [35, 40],
      [31, -42],
      [-5, -104],
      [-62, -123],
      [-162, -150],
      [-26, -72],
      [78, 18],
      [143, -25],
      [78, 8],
      [-9, 42],
      [57, 29],
      [37, 72],
      [55, -1],
      [57, 40],
      [55, 9],
    ],
    [
      [4598, 20832],
      [88, -59],
      [74, 14],
      [-30, -64],
      [33, -20],
      [53, 44],
      [56, -61],
      [96, 15],
      [45, -51],
      [151, -5],
      [57, -82],
      [86, 2],
      [62, 55],
      [34, -52],
      [-4, -220],
      [12, -52],
      [-53, -33],
      [-9, -43],
      [-75, 8],
      [-196, 67],
      [-85, -13],
      [-34, 30],
      [-2, 84],
      [-66, 29],
      [-36, 104],
      [-33, 30],
      [-14, 83],
      [-152, 67],
      [-44, 29],
      [-46, 72],
      [32, 22],
    ],
    [
      [7765, 21421],
      [-67, -32],
      [-57, -74],
      [-124, 9],
      [-57, 73],
      [-45, -1],
      [-45, 73],
      [-68, 41],
      [-34, -84],
      [-67, -42],
      [-124, 51],
      [-46, 62],
      [-66, -64],
      [-113, 93],
      [-12, 74],
      [-45, 30],
      [9, 137],
      [-79, 10],
    ],
    [
      [6725, 21777],
      [10, 72],
      [44, 53],
      [56, 21],
      [11, -41],
      [46, -31],
      [55, 31],
      [99, 126],
      [21, 41],
      [124, -20],
      [20, 94],
      [124, -10],
      [-2, 73],
      [21, 42],
      [79, 0],
      [88, 52],
      [34, -51],
      [-44, -32],
      [-42, -93],
      [56, -21],
      [81, -124],
      [-44, -21],
      [14, -93],
      [-89, -11],
      [12, -72],
      [-21, -21],
      [23, -62],
      [46, -31],
      [44, 21],
      [46, -62],
      [2, -41],
      [113, -93],
      [13, -52],
    ],
    [
      [7098, 15622],
      [-21, -21],
      [42, -105],
      [0, -83],
      [137, -75],
      [52, 0],
      [-10, -63],
      [42, -21],
      [0, -63],
      [209, -64],
      [73, -63],
      [-41, -52],
      [-10, -73],
      [-73, 42],
      [-51, -51],
      [73, -74],
      [-41, -62],
      [-10, -83],
      [94, -32],
      [31, -53],
      [-40, -72],
      [-104, 0],
      [-105, 43],
      [-62, 1],
      [-41, -94],
      [42, -32],
      [1, -62],
      [-52, -31],
      [-94, 21],
      [-10, -41],
      [125, -43],
      [63, -63],
      [-10, -31],
      [-62, -41],
      [1, -42],
      [-42, -62],
      [-42, 21],
      [-41, -42],
      [11, -93],
      [-52, -52],
      [21, -31],
      [0, -62],
      [-52, -63],
      [11, -51],
      [-73, -63],
      [20, -102],
      [42, 10],
      [65, -33],
      [31, 41],
      [-32, 43],
      [10, 31],
      [64, 19],
      [106, -1],
      [22, -22],
      [43, -105],
      [-42, -9],
      [-42, -42],
      [22, -33],
      [-9, -85],
      [31, -42],
      [-41, -116],
    ],
    [
      [4465, 10580],
      [-32, 71],
      [-30, 19],
      [-54, 163],
      [-1, 41],
      [39, 43],
      [109, 47],
      [68, 128],
      [-100, -5],
      [-33, 102],
      [-41, 50],
      [18, 73],
      [-21, 41],
      [-40, 8],
      [-22, 62],
      [29, 63],
      [-22, 114],
      [-32, 71],
      [-62, 60],
      [-72, 90],
      [-122, 15],
      [-71, -24],
      [-79, -56],
      [-91, -15],
      [-110, -67],
      [-51, -11],
      [-122, -1],
      [-32, 53],
      [-2, 53],
      [-83, 84],
      [-63, 42],
      [-1, 42],
      [50, 43],
      [62, 0],
      [17, 116],
      [31, 11],
      [42, -42],
      [61, -10],
      [-2, 106],
      [-63, 62],
      [-1, 32],
      [49, 64],
      [162, 76],
      [21, -42],
      [41, 12],
      [19, 53],
      [-22, 83],
      [50, 12],
      [83, -40],
      [19, 43],
      [-1, 63],
      [-31, 31],
      [9, 53],
      [-52, 9],
      [-1, 63],
      [-22, 63],
      [-74, 29],
      [-1, 42],
      [-53, -1],
      [-5, 105],
      [39, 64],
      [-56, 71],
      [25, 117],
      [90, 87],
      [92, -60],
      [71, 54],
      [31, 127],
      [-31, 96],
      [-62, 74],
      [24, 76],
    ],
    [
      [3950, 13678],
      [32, 30],
      [47, 116],
      [-59, 84],
      [-24, 63],
      [82, 42],
      [19, 52],
      [130, 125],
      [62, 32],
      [57, -63],
      [63, 10],
      [60, 43],
      [-1, 31],
      [79, 74],
      [42, -10],
      [-14, 83],
      [8, 62],
      [30, 21],
      [90, 108],
      [31, 12],
      [-33, 72],
      [8, 53],
      [-44, 51],
      [40, 54],
      [126, 14],
      [94, -28],
      [71, 44],
      [19, 95],
      [91, 87],
      [-2, 74],
      [-45, 145],
      [-2, 63],
      [-53, 9],
      [-51, -33],
      [-43, -1],
      [-51, -55],
      [-43, 10],
      [-12, 41],
      [-45, 41],
      [52, 54],
      [59, 117],
      [115, 67],
      [31, 43],
      [75, -8],
      [52, 33],
      [63, 12],
      [86, -39],
      [52, 44],
      [94, -8],
      [19, 116],
      [-52, -1],
      [-2, 83],
      [-43, 31],
      [20, 53],
      [205, 89],
      [31, -51],
      [61, 2],
      [30, -20],
      [-28, -84],
      [32, -62],
      [100, 3],
      [49, 64],
      [51, -19],
      [18, 84],
      [60, 23],
      [19, 42],
      [60, 2],
      [18, 115],
      [29, 11],
      [58, 96],
      [-61, 50],
      [59, 43],
      [50, 2],
      [79, 54],
    ],
    [
      [6250, 16370],
      [81, 33],
      [-9, -52],
      [62, -29],
      [30, 32],
      [42, -41],
      [103, 13],
      [11, -41],
      [95, -90],
      [-10, -42],
      [-102, -54],
      [-19, -73],
      [-41, -1],
      [32, -82],
      [63, -70],
      [-30, -42],
      [12, -124],
      [-81, -14],
      [-38, -74],
      [-8, -84],
      [40, -9],
      [123, -78],
      [132, -47],
      [60, 34],
      [-41, 30],
      [-52, 102],
      [113, 95],
      [43, -10],
      [-1, 61],
      [76, -20],
      [21, 31],
      [96, 12],
      [0, -32],
      [45, -82],
    ],
    [
      [9431, 20514],
      [-65, 64],
      [-87, -20],
      [0, 63],
      [22, 63],
      [-141, 65],
      [-43, 32],
      [-108, 180],
      [-33, 11],
      [0, 52],
      [-33, 94],
      [-141, 16],
      [-119, -78],
      [-86, 25],
      [-87, 77],
      [-66, 86],
      [-172, 4],
      [-21, -20],
    ],
    [
      [8251, 21228],
      [42, 51],
      [0, 93],
      [31, 113],
      [97, -2],
      [21, 62],
      [33, -12],
      [97, 49],
      [10, 52],
      [54, 19],
      [32, 61],
      [-22, 42],
      [153, 36],
      [21, 31],
      [10, 124],
      [43, 61],
      [-12, 74],
      [-45, 64],
      [-55, 23],
      [-65, -8],
      [-78, 54],
      [-1, 52],
      [-65, 33],
      [-22, 42],
      [21, 31],
      [-12, 135],
      [-32, 32],
      [21, 73],
      [31, 41],
      [42, -1],
      [94, 92],
      [63, 19],
      [23, -53],
      [77, 18],
      [-23, 43],
      [-67, 12],
      [2, 81],
    ],
    [
      [8770, 22865],
      [84, 35],
      [69, 54],
      [107, 54],
      [97, 20],
      [83, -13],
      [55, 17],
      [64, -86],
      [53, -38],
      [-2, -78],
      [71, -39],
      [55, 36],
      [19, 41],
      [68, -63],
      [70, 1],
      [-7, -35],
      [61, -37],
      [39, 21],
      [38, 71],
      [-25, 80],
      [-41, -29],
      [-115, 42],
      [11, 70],
      [129, 66],
      [68, -11],
      [18, -28],
      [104, 55],
      [35, 48],
      [-8, 39],
      [74, 47],
      [-21, 73],
      [-50, -35],
      [-59, -71],
      [-32, 31],
      [54, 60],
      [95, 36],
      [111, 118],
      [141, 6],
      [88, -27],
      [60, -1],
      [207, 61],
      [80, 33],
      [56, -5],
      [81, 18],
      [24, -76],
      [59, 5],
      [8, 46],
      [-55, 5],
      [66, 65],
      [63, 103],
      [76, 57],
      [129, 66],
      [67, 53],
      [123, 227],
      [54, 44],
      [53, 88],
      [51, 135],
      [19, 26],
      [67, -77],
      [201, -39],
      [335, -4],
      [93, 12],
      [71, -5],
      [-48, -55],
      [-87, -25],
      [-72, 23],
      [-39, -21],
      [-192, 10],
      [-54, -25],
      [-35, 23],
      [-126, -42],
      [-94, -72],
      [-46, 13],
      [-106, -10],
      [-26, -20],
      [2, -89],
      [-63, -81],
      [-47, -29],
      [43, -60],
      [-32, -33],
      [63, -48],
      [30, 21],
      [-16, 62],
      [45, 48],
      [69, 17],
      [-2, 75],
      [110, 27],
      [-3, 66],
      [40, 34],
      [66, -45],
      [126, 86],
      [-17, 53],
      [92, 7],
      [21, -108],
      [143, -3],
      [11, -60],
      [62, 4],
      [66, 50],
      [22, 87],
      [107, 64],
      [51, 6],
      [22, 32],
      [58, -4],
      [-14, -107],
      [44, -49],
      [43, 9],
      [34, -45],
      [-31, -51],
      [21, -80],
      [37, -32],
      [-6, -76],
      [80, -38],
      [24, 27],
      [65, -57],
      [26, -47],
      [72, 1],
      [48, -17],
      [34, -85],
      [67, -112],
      [95, 45],
      [43, -40],
      [-55, -28],
      [85, -98],
      [25, -84],
      [60, -24],
      [-20, 99],
      [89, 33],
      [97, 6],
      [77, 21],
      [119, 89],
      [0, -64],
      [91, -43],
      [51, -70],
      [36, -5],
      [-23, -162],
      [-42, -41],
      [3, -57],
      [59, -54],
      [69, -39],
      [34, -57],
      [67, -49],
      [32, -51],
      [-102, -93],
      [-14, -42],
      [88, 3],
      [45, 26],
      [28, 71],
      [-26, 105],
      [-44, 33],
      [14, 78],
      [73, 13],
      [30, -57],
      [55, -33],
      [59, -58],
      [28, 90],
      [-10, 99],
      [-34, 25],
      [-76, 105],
      [-92, 22],
      [-24, -39],
      [23, -39],
      [-46, -67],
      [-79, 1],
      [22, 58],
      [-13, 45],
      [-85, -51],
      [-13, -33],
      [-55, -1],
      [-12, 35],
      [47, 50],
      [39, 142],
      [-96, 100],
      [-15, 68],
      [28, 32],
      [83, 4],
      [18, -76],
      [41, -74],
      [74, -73],
      [75, -48],
      [131, -50],
      [80, -86],
      [144, -131],
      [84, -96],
      [64, -47],
      [-24, -61],
      [17, -107],
      [-53, 8],
      [-125, -11],
      [-54, 14],
      [-107, -14],
      [-47, -53],
      [-102, -18],
      [-25, 8],
      [-94, -29],
      [12, -47],
      [170, -132],
      [158, -50],
      [67, -40],
      [115, 7],
      [42, -11],
      [67, 64],
      [60, -27],
      [15, -35],
      [-27, -46],
      [-69, -65],
      [48, -18],
      [49, 16],
      [32, -112],
      [-4, -72],
      [52, -64],
      [-14, -52],
      [8, -88],
      [-22, -52],
      [27, -56],
      [72, -95],
      [28, -83],
      [-4, -94],
      [48, -135],
      [-2, -52],
      [28, -84],
    ],
    [
      [12768, 24378],
      [71, -22],
      [-38, -50],
      [-77, -58],
      [-67, 2],
      [48, 91],
      [63, 37],
    ],
    [
      [13195, 24913],
      [4, -61],
      [-79, -40],
      [-18, -38],
      [9, -61],
      [44, -77],
      [99, -47],
      [154, 31],
      [144, 12],
      [81, -65],
      [-3, -88],
      [-16, -27],
      [-109, -66],
      [-63, -76],
      [4, -57],
      [28, -73],
      [61, -72],
      [87, 7],
      [54, -58],
      [64, -95],
      [-18, -81],
      [-50, -62],
      [-79, -21],
      [-18, 26],
      [88, 50],
      [-28, 32],
      [-13, 68],
      [-42, -6],
      [-62, -50],
      [-19, 40],
      [-48, 22],
      [-44, -38],
      [-73, 13],
      [-85, -79],
      [-56, -25],
      [-52, -93],
      [-79, -17],
      [16, -51],
      [73, 43],
      [35, -75],
      [-58, -45],
      [-51, 32],
      [-68, 11],
      [-12, 74],
      [-77, -28],
      [-52, 35],
      [-72, 10],
      [-7, 43],
      [-144, 133],
      [28, 111],
      [183, -8],
      [5, 108],
      [-44, 51],
      [-70, -2],
      [-5, 78],
      [141, 53],
      [-41, 42],
      [6, 69],
      [-103, -11],
      [-14, 107],
      [135, 25],
      [80, -31],
      [3, -61],
      [41, 8],
      [-19, 87],
      [94, 72],
      [15, -70],
      [54, -45],
      [36, -60],
      [-3, -70],
      [48, -16],
      [18, -42],
      [88, 42],
      [24, 78],
      [-21, 90],
      [-99, 11],
      [-39, 50],
      [-70, 33],
      [-21, 92],
      [-76, -57],
      [-20, -39],
      [-74, -46],
      [-43, 22],
      [11, 47],
      [46, 75],
      [1, 89],
      [-34, -7],
      [-65, -56],
      [21, -71],
      [-97, -97],
      [-24, 28],
      [63, 37],
      [33, 49],
      [-9, 86],
      [54, 69],
      [70, 38],
      [81, 4],
      [70, 24],
      [92, 4],
    ],
    [
      [1554, 21917],
      [73, -60],
      [-93, -6],
      [-54, -91],
      [-37, 6],
      [-71, 46],
      [-6, 72],
      [78, -2],
      [110, 35],
    ],
    [
      [1797, 22015],
      [-5, -76],
      [-41, 7],
      [-18, 43],
      [64, 26],
    ],
    [
      [2522, 22211],
      [31, -24],
      [-116, -32],
      [-117, 1],
      [-87, -18],
      [-22, 30],
      [102, 38],
      [101, -13],
      [108, 18],
    ],
    [
      [2971, 22306],
      [-6, -26],
      [-95, -12],
      [-42, -60],
      [-42, 13],
      [4, 52],
      [36, 29],
      [145, 4],
    ],
    [
      [3203, 22369],
      [53, -18],
      [7, -34],
      [-81, 2],
      [21, 50],
    ],
    [
      [5630, 22662],
      [82, -7],
      [98, 13],
      [55, -9],
      [100, -38],
      [123, -130],
      [81, -184],
      [67, -70],
      [111, -90],
      [34, -81],
      [2, -52],
      [34, -82],
      [67, -49],
      [154, -89],
      [87, -17],
    ],
    [
      [7765, 21421],
      [42, -20],
      [85, 0],
      [32, -41],
      [169, -103],
      [95, -9],
      [63, -20],
    ],
    [
      [10005, 20266],
      [-55, -62],
      [-11, -42],
      [-87, 12],
      [-33, -125],
      [-33, -52],
      [-87, -62],
      [-21, -41],
      [-44, 0],
      [-119, -72],
      [-140, 12],
      [-33, 53],
      [-108, 22],
      [-65, -30],
      [-87, 43],
      [-131, 2],
      [-11, -62],
      [-86, -102],
      [-118, 19],
      [-64, -15],
      [-116, 4],
      [-21, -58],
      [1, -115],
      [52, -80],
      [-1, -67],
      [31, -46],
      [43, -2],
      [-1, -55],
      [125, -217],
      [42, -57],
      [33, 32],
      [56, 11],
      [-1, -52],
      [-66, -74],
      [1, -73],
      [67, -104],
      [-11, -41],
      [-54, -31],
      [-9, -82],
      [43, -73],
      [55, -42],
      [75, -94],
      [65, -32],
      [-21, -72],
      [-107, -20],
      [75, -115],
      [-42, -31],
      [22, -52],
      [32, 0],
      [53, -83],
      [0, -52],
      [-73, -30],
      [-31, -41],
      [0, -52],
      [84, -32],
      [-31, -93],
      [-62, -41],
      [-115, -39],
      [1, -31],
      [53, -22],
      [-9, -83],
      [-32, 11],
      [-119, 2],
      [-53, -30],
      [-290, 5],
      [-75, -20],
      [21, -31],
      [-42, -51],
      [-118, 2],
      [-10, -21],
      [43, -84],
      [85, -43],
      [-42, -63],
      [0, -42],
      [53, -21],
      [11, -85],
      [-116, -97],
      [-10, -128],
      [11, -64],
      [32, -52],
      [53, -30],
      [0, -43],
      [74, -124],
      [-10, -75],
      [63, -78],
    ],
    [
      [8439, 16365],
      [-72, -54],
      [-42, -15],
      [1, -109],
      [53, -18],
      [-41, -59],
      [-53, 66],
      [-62, -53],
      [-104, -20],
      [-31, 43],
      [-105, 64],
      [-115, -9],
      [12, -74],
      [-82, -125],
      [-62, -21],
      [-30, -41],
      [-83, -52],
      [-52, 32],
      [-61, -114],
      [-31, 11],
      [-61, -52],
      [-42, 21],
      [-41, -31],
      [-83, -20],
      [-30, -41],
      [-72, -31],
      [-52, -41],
    ],
    [
      [6250, 16370],
      [-121, 6],
      [9, 52],
      [29, 32],
      [-21, 41],
      [30, 32],
      [-43, 92],
      [8, 62],
      [108, 97],
      [-31, 40],
      [19, 42],
      [-12, 51],
      [50, 43],
      [-103, -2],
      [-63, 20],
      [-52, -23],
      [18, 116],
      [-34, 62],
      [-62, -1],
      [-32, 30],
      [-15, 136],
      [-63, -1],
      [-40, -43],
      [-44, 41],
      [-2, 73],
      [31, 22],
      [-12, 52],
      [-32, 20],
      [-2, 73],
      [31, 22],
      [-23, 52],
      [-42, 9],
      [-15, 126],
      [-63, -12],
      [-96, 50],
      [-62, -44],
      [-15, 136],
      [62, 33],
      [-2, 63],
      [52, 54],
      [-119, 70],
      [-64, 9],
      [30, 74],
      [-2, 63],
      [71, 96],
      [53, 2],
      [52, 32],
      [19, 85],
      [51, 75],
      [-44, 40],
      [-2, 84],
      [42, 33],
      [9, 42],
      [-140, 49],
      [-41, -54],
      [-29, -74],
      [-42, -22],
      [1, -42],
      [-114, -87],
      [-43, 9],
      [-73, -44],
      [-118, 29],
      [-73, -34],
      [-64, 19],
      [-6, 178],
      [9, 53],
      [-22, 31],
      [-2, 63],
      [-77, 60],
      [-167, -88],
      [-87, 39],
      [-83, -66],
      [-8, -73],
      [-41, -43],
      [-127, -14],
      [-22, 30],
      [-53, -1],
      [-21, -32],
      [34, -41],
      [-19, -63],
      [227, -119],
      [14, -94],
      [23, -41],
      [-9, -42],
      [15, -136],
      [-29, -74],
      [3, -63],
      [32, -30],
      [86, -19],
      [-82, -86],
      [-42, -1],
      [-62, -44],
      [-18, -84],
      [-53, 9],
      [-86, 40],
      [-63, 8],
      [-73, -23],
      [-60, -64],
      [-28, -74],
      [-63, 9],
      [-110, -23],
      [-101, -11],
      [-73, -36],
      [-41, -44],
      [-96, 37],
      [-58, 103],
      [103, 36],
      [40, 43],
      [73, 2],
      [-16, 117],
      [19, 55],
      [-138, 3],
      [-32, 20],
      [-25, 73],
      [50, 45],
      [-4, 106],
      [51, 35],
      [-44, 40],
      [-2, 42],
      [83, 26],
      [-43, 59],
      [-24, 82],
      [-75, 47],
      [-93, -36],
      [-53, 7],
      [-160, 85],
      [-46, 143],
      [-97, 47],
      [-42, 8],
      [-42, -53],
      [-20, -62],
      [55, -50],
      [-43, -22],
      [-9, -63],
      [-41, -43],
      [-63, -13],
      [-134, -79],
      [-29, -54],
      [-62, -13],
      [-70, -76],
      [-52, -13],
      [-40, -44],
      [-243, 11],
      [-63, -13],
      [-29, -53],
      [-62, -3],
    ],
    [
      [2094, 18051],
      [-46, 39],
      [-27, 104],
      [89, 177],
      [-2, 71],
      [-26, 51],
      [-37, 17],
      [-39, 72],
      [-36, 115],
      [-22, -2],
      [-43, -77],
      [-55, -15],
      [-78, 26],
      [-57, 38],
      [-75, -5],
      [-81, 11],
      [-90, 55],
      [-5, 123],
      [-47, 79],
      [60, -11],
      [55, 59],
      [-44, 49],
      [15, 52],
      [-24, 57],
      [43, 49],
      [75, 26],
      [127, -10],
      [80, 8],
      [76, -36],
      [68, 20],
      [58, -31],
      [54, -8],
      [20, 32],
      [21, 197],
      [9, 271],
      [35, 62],
      [-11, 30],
      [74, 81],
      [132, 162],
      [74, 192],
      [-9, 182],
      [13, 91],
      [-52, 53],
      [25, 71],
      [76, 130],
      [-23, 112],
      [-116, 29],
      [40, 54],
      [10, 106],
      [25, 96],
      [-145, 14],
      [-245, 4],
      [-45, 64],
      [21, 71],
      [0, 130],
      [25, 148],
      [69, 117],
      [121, 23],
      [-75, 131],
      [92, 82],
      [24, 53],
      [152, 115],
      [91, 34],
      [96, 15],
      [42, -9],
      [173, 2],
      [67, -31],
      [88, 6],
      [83, 35],
      [143, 39],
      [113, 1],
      [62, 24],
      [205, 22],
      [157, -14],
      [53, -21],
      [-19, -45],
      [15, -77],
      [34, -59],
      [41, 13],
      [61, -66],
      [21, -58],
      [89, -163],
      [-36, -87],
      [-152, -41],
      [4, -55],
      [61, -92],
      [66, 21],
      [68, -61],
      [57, -82],
      [88, -4],
      [66, 79],
      [-1, 39],
      [34, 60],
      [-6, 159],
      [-140, -2],
      [15, 161],
      [54, 91],
      [58, 23],
      [72, -22],
      [42, -71],
      [42, -41],
      [123, -48],
      [70, -4],
      [78, -37],
      [48, 2],
      [-91, 139],
      [-19, 82],
      [-53, 106],
      [0, 109],
      [102, 274],
      [21, 102],
      [93, 141],
      [131, 38],
      [78, -97],
      [105, -66],
      [185, -15],
      [141, 34],
      [74, -12],
      [64, 48],
      [82, 27],
      [-6, 59],
    ],
    [
      [3950, 13678],
      [-23, 41],
      [-59, 16],
      [-60, -25],
      [-6, 84],
      [-131, 128],
      [-4, 73],
      [20, 12],
      [-4, 73],
      [-24, 50],
      [-62, -3],
      [-42, 18],
      [-34, 61],
      [-94, 16],
      [6, 73],
      [29, 54],
      [-65, 38],
      [-51, -3],
      [-9, -32],
      [-51, -24],
      [-8, -42],
      [25, -72],
      [-106, -152],
      [-40, -23],
      [5, -84],
      [-39, -54],
      [-103, -6],
      [-6, -74],
      [-73, 17],
      [-100, -69],
      [-92, -16],
      [-40, -24],
      [-63, 17],
      [-39, -34],
      [15, -93],
      [-17, -64],
      [-61, -14],
      [-49, -35],
      [-71, -25],
      [-113, 3],
      [-60, -25],
      [-18, -43],
      [-63, 28],
      [-7, -53],
      [-30, -13],
      [-65, 49],
      [-86, -111],
      [-49, -24],
      [-52, 19],
      [-40, -24],
      [14, -62],
      [-40, -23],
      [-3, -127],
      [-87, -68],
      [-53, 29],
      [-45, 72],
      [-49, -24],
      [-28, -44],
      [23, -52],
      [-38, -24],
      [70, -106],
      [-18, -34],
      [48, -74],
      [-92, -43],
      [-73, -22],
      [-68, 83],
      [-59, -75],
      [-14, 73],
      [-76, 40],
      [-50, -43],
      [-53, 8],
      [-38, -53],
      [-61, -33],
      [-32, 19],
      [7, 53],
      [-74, 18],
      [-3, 52],
      [-51, -23],
      [26, -92],
      [4, -62],
    ],
    [
      [928, 12650],
      [8, 31],
      [-54, 50],
      [-65, 82],
      [39, 69],
      [22, 116],
      [-57, 52],
      [-21, 82],
      [-186, 25],
      [-75, 86],
      [-9, 75],
      [38, 20],
      [40, 55],
      [55, 16],
      [46, 68],
      [-33, 26],
      [-53, -4],
      [-106, 36],
      [10, 60],
      [-96, 142],
      [-32, 28],
      [-70, -3],
      [-56, 21],
      [-39, 119],
      [-75, 78],
      [21, 53],
      [43, -32],
      [55, 53],
      [-20, 67],
      [76, 17],
      [43, 77],
      [-22, 62],
      [13, 59],
      [-118, 32],
      [-23, 71],
      [53, 60],
      [-144, 35],
      [-88, -30],
      [15, 54],
      [-48, 70],
      [-15, 50],
      [95, 34],
      [37, -52],
      [38, -7],
      [52, 132],
      [69, 39],
      [119, 129],
      [41, 25],
      [72, 8],
      [12, 25],
      [-59, 33],
      [74, 50],
      [-22, 18],
      [-132, -68],
      [-36, 39],
      [-8, 102],
      [8, 59],
      [69, 70],
      [18, 46],
      [65, 82],
      [16, 63],
      [42, 14],
      [58, 65],
      [-30, 123],
      [28, 188],
      [-18, 92],
      [-44, 72],
      [-28, 17],
      [-39, 80],
      [-115, 101],
      [26, 49],
      [23, 106],
      [-118, 34],
      [-28, 29],
      [2, 126],
      [-118, 60],
      [54, 43],
      [-13, 86],
      [-48, 83],
      [64, 12],
      [104, 64],
      [13, 38],
      [73, -51],
      [102, -5],
      [-3, 38],
      [-83, 89],
      [69, 43],
      [46, -29],
      [58, -78],
      [123, 26],
      [28, -17],
      [10, -55],
      [74, 10],
      [29, -43],
      [71, -10],
      [-23, 100],
      [40, 11],
      [65, -18],
      [40, -40],
      [38, 12],
      [90, 67],
      [68, 34],
      [114, 37],
      [60, 34],
      [35, -41],
      [53, -18],
      [110, 100],
      [6, 74],
      [51, 13],
      [-5, 84],
      [-32, 9],
      [-101, 90],
      [-64, 28],
      [-42, -2],
      [4, 95],
      [73, 4],
      [30, 33],
      [15, 95],
      [148, -3],
      [50, 34],
      [8, 70],
      [52, 60],
      [92, 35],
      [34, 98],
      [72, 5],
      [73, 46],
    ],
    [
      [4127, 8846],
      [-10, 20],
      [-124, 12],
      [-101, 40],
      [-57, 49],
      [-80, 20],
      [-61, 29],
      [-19, 36],
      [-73, 69],
      [-14, -47],
      [-84, -12],
      [-19, -21],
      [-123, 90],
      [-57, -64],
      [-51, 10],
      [-68, 43],
      [-31, -37],
      [-73, 24],
      [-9, 47],
      [-50, 30],
      [-72, 2],
      [-34, 19],
      [-67, 100],
      [-22, 52],
      [8, 72],
      [-60, -11],
      [-62, 44],
      [-107, -52],
    ],
    [
      [2607, 9410],
      [-42, 64],
      [-71, 58],
      [7, 52],
      [-21, 41],
      [28, 53],
      [39, 2],
      [28, 43],
      [69, 34],
      [-2, 62],
      [46, 105],
      [-2, 31],
      [-51, 29],
      [-32, 41],
      [-60, -3],
      [-61, 28],
      [18, 43],
      [-83, 78],
      [8, 43],
      [49, 23],
      [38, 43],
      [-42, 40],
      [-2, 52],
      [36, 74],
      [-41, 19],
      [-3, 63],
      [-21, 30],
      [-90, -15],
      [-64, 59],
      [-2, 41],
      [-110, -17],
      [-75, 79],
      [-73, 47],
      [-58, -46],
      [-31, 19],
      [-40, -24],
      [-159, -42],
      [-47, -66],
      [-42, 18],
      [-66, -67],
      [-59, -36],
      [-61, -4],
      [-17, -43],
      [-42, 17],
      [-100, -28],
      [-17, -54],
      [-30, -2],
      [-46, 69],
      [-119, -42],
      [-54, 47],
      [-153, 6],
    ],
    [
      [884, 10474],
      [-3, 124],
      [80, 93],
      [25, 76],
      [-22, 40],
      [65, 80],
      [73, 59],
      [2, 107],
      [25, 28],
      [-20, 74],
      [19, 18],
      [-80, 72],
      [-39, -25],
      [-92, 29],
      [-37, 72],
      [-103, -6],
      [-14, 51],
      [-70, 26],
      [-56, 50],
      [-31, 167],
      [-76, 7],
      [-34, 20],
      [-3, 62],
      [-48, 92],
      [-28, 124],
      [-5, 93],
      [18, 83],
      [55, 41],
      [-25, 49],
      [19, 54],
      [76, 43],
      [-34, 132],
      [57, 52],
      [127, 30],
      [6, 73],
      [38, 76],
      [70, 12],
      [27, -28],
      [82, 26],
    ],
    [
      [2607, 9410],
      [2, -48],
      [-60, 12],
      [-64, -94],
      [-71, 38],
      [-77, -22],
      [-31, 25],
      [-78, -16],
      [-44, 46],
      [-82, -37],
      [-19, 39],
      [-12, 86],
      [-97, 78],
      [-87, 26],
      [-17, 31],
      [-77, -36],
      [-51, 38],
      [-44, -30],
      [1, -117],
      [-17, -20],
      [-166, 14],
      [-38, 63],
      [14, 63],
      [-54, 4],
      [-31, 26],
      [-9, 70],
      [-26, 31],
      [0, 58],
      [-46, 56],
      [-41, 17],
      [-55, 78],
      [-12, 52],
      [54, 8],
      [-23, 79],
      [-41, 6],
      [-25, 35],
      [-24, 86],
      [-82, 68],
      [-121, 66],
      [-30, -37],
      [-62, -13],
      [10, 126],
      [-21, 73],
      [31, 36],
    ],
    [
      [15433, 16199],
      [-22, -73],
      [54, -23],
      [4, -63],
      [97, -17],
      [73, -58],
      [90, -24],
      [53, 1],
      [41, -31],
      [52, -80],
      [-8, -64],
      [13, -190],
      [17, -59],
      [91, -92],
      [17, -195],
      [-36, -28],
      [-21, -52],
      [4, -63],
      [-28, -79],
      [9, -73],
      [-40, -69],
      [6, -88],
      [-47, -63],
      [-30, -83],
      [-6, -101],
      [-46, -55],
      [-8, -64],
      [-36, -12],
      [-12, -53],
      [-89, -96],
      [5, -78],
      [-79, -111],
      [-45, 13],
      [-73, -7],
      [3, 39],
      [-122, 19],
      [-63, 41],
      [33, 62],
      [2, 41],
      [48, 45],
      [-4, 50],
      [-38, 10],
      [-47, -42],
      [-61, -5],
      [-4, 48],
      [55, 96],
      [-4, 50],
      [-107, 73],
      [-148, 46],
      [-68, -62],
      [-74, 18],
      [-28, 42],
      [-56, -13],
      [-44, 81],
      [-19, -78],
      [-35, -28],
      [-17, -62],
      [8, -61],
      [40, -22],
      [52, 20],
      [5, -46],
      [77, -31],
      [75, -6],
      [-17, -103],
      [-33, -49],
      [-62, 1],
      [-73, -39],
      [-70, 32],
      [-52, -28],
      [-12, -71],
      [-42, -29],
      [-92, -17],
      [-31, -29],
      [-82, -27],
      [-32, -40],
      [-61, -18],
      [-69, 45],
      [-62, -28],
      [-22, -51],
      [-70, 4],
      [-23, -61],
      [28, -53],
      [-59, -5],
      [-14, -55],
      [-61, -17],
      [-50, 33],
      [-121, -4],
      [-58, -24],
      [-40, 34],
      [-83, -46],
      [-124, 1],
      [-14, -129],
      [-47, -30],
      [20, -60],
      [-41, -19],
      [-64, -70],
      [-91, 26],
      [-8, 53],
      [-56, -13],
      [-93, -70],
      [6, -58],
      [-74, -7],
      [-2, 33],
      [-61, 4],
      [-22, -41],
      [-16, -108],
      [-52, -67],
      [-2, -42],
      [-92, 15],
      [-127, -24],
      [-28, 35],
      [-37, -4],
      [-16, -91],
      [7, -75],
      [-57, -44],
      [-32, -55],
      [-72, -20],
      [-63, 45],
      [-98, 90],
      [-74, -41],
      [-69, 0],
      [-48, -31],
      [-49, -58],
      [-69, 25],
      [-41, -19],
      [-49, 23],
      [-21, -30],
      [-152, -24],
      [-42, -50],
      [2, -93],
      [-61, -7],
      [-19, -53],
      [-72, -21],
      [7, -63],
      [-58, -32],
      [-32, -55],
      [-4, -77],
      [-37, -4],
      [8, -75],
      [-31, -78],
      [-68, -3],
      [-16, 147],
      [-69, 52],
      [33, 63],
      [-102, 9],
      [-1, 123],
      [-110, -13],
      [-11, -14],
    ],
    [
      [10574, 12945],
      [-9, 83],
      [43, 74],
      [42, 21],
      [-81, 83],
      [-61, 0],
      [-20, 63],
      [21, 41],
      [73, 11],
      [12, 63],
      [31, 53],
      [74, 42],
      [61, -42],
      [10, -52],
      [-22, -42],
      [20, -31],
      [83, 21],
      [2, 52],
      [42, 11],
      [41, -21],
      [32, 31],
      [13, 126],
      [41, -42],
      [65, 73],
      [62, -31],
      [42, 11],
      [1, 42],
      [42, 10],
      [43, 42],
      [12, 42],
      [-84, 21],
      [-40, 83],
      [21, 61],
      [-62, 1],
      [21, 82],
      [42, -21],
      [42, 40],
      [-62, 32],
      [-20, 62],
      [72, -1],
      [42, 30],
      [72, 9],
      [1, 41],
      [52, 30],
      [102, -24],
      [12, 62],
      [73, 80],
      [93, 18],
      [103, 7],
      [94, 49],
      [-40, 53],
      [-18, 125],
      [-72, 12],
      [-41, 32],
      [-31, -9],
      [1, 82],
      [-61, 23],
      [1, 82],
      [-72, 13],
      [-21, 31],
      [-103, 13],
      [-32, -20],
      [-30, 52],
      [-42, 22],
      [-83, -30],
    ],
    [
      [11219, 14822],
      [-62, 12],
      [-52, 42],
      [22, 72],
      [-63, 11],
      [1, 62],
      [22, 51],
      [-52, 22],
      [32, 72],
      [-9, 93],
      [-41, 32],
      [1, 72],
      [-32, 21],
      [-9, 73],
      [74, 30],
      [11, 72],
      [-41, 52],
      [13, 155],
      [-42, 11],
      [0, 52],
      [86, 123],
      [-30, 73],
      [75, 102],
      [105, -2],
      [43, 51],
      [53, 31],
      [136, -14],
      [1, 42],
      [42, 20],
      [148, -3],
      [116, 59],
      [84, -12],
      [66, 113],
      [63, -1],
      [94, -44],
      [22, 51],
      [106, 50],
      [30, -63],
      [32, -1],
      [51, -42],
      [63, -12],
      [33, 72],
      [64, 9],
      [82, -54],
    ],
    [
      [11219, 14822],
      [-43, -61],
      [0, -52],
      [51, -31],
      [10, -52],
      [-64, -102],
      [-31, -20],
      [-1, -72],
      [-42, -31],
      [-61, 104],
      [-74, -40],
      [-62, 32],
      [-125, -9],
      [-62, 62],
      [-42, -10],
      [1, 73],
      [-177, 115],
      [-21, -21],
      [-189, -9],
      [-20, 42],
      [-83, 21],
      [-10, 73],
      [-94, 41],
      [-31, -20],
      [-42, 31],
      [-52, 10],
      [-42, -52],
      [-104, 0],
      [-41, 31],
      [-20, 94],
      [52, 32],
      [-20, 93],
      [-42, 11],
      [-31, 52],
      [-62, -32],
      [-21, 53],
      [1, 62],
      [41, -10],
      [85, 135],
      [42, 32],
      [-94, 73],
      [-61, 104],
      [10, 63],
      [-114, 21],
      [-10, 52],
      [-84, -31],
      [-42, -1],
      [-62, 42],
      [-21, -21],
      [-104, -11],
      [-125, 72],
      [-41, 0],
      [-42, -32],
      [-63, 0],
      [-41, 41],
      [10, 32],
      [-20, 73],
      [0, 63],
      [-52, 73],
      [11, 42],
      [-21, 42],
      [-52, 21],
      [10, 105],
      [53, 0],
      [10, 42],
      [-83, 10],
      [-52, 64],
      [-74, -22],
      [0, 43],
      [-200, 8],
    ],
    [
      [4547, 24328],
      [-2, -30],
      [80, -102],
      [-60, -48],
      [-63, 102],
      [45, 78],
    ],
    [
      [9076, 24491],
      [165, -34],
      [137, -82],
      [123, -256],
      [-119, 28],
      [-122, -32],
      [-99, 5],
      [-42, 20],
      [4, 96],
      [-55, 25],
      [-102, -28],
      [6, 111],
      [37, 85],
      [67, 62],
    ],
    [
      [4549, 24524],
      [44, -32],
      [-46, -110],
      [-20, 82],
      [22, 60],
    ],
    [
      [4924, 24554],
      [24, -53],
      [-59, -123],
      [-87, -14],
      [-57, 60],
      [5, 64],
      [57, 4],
      [28, 28],
      [89, 34],
    ],
    [
      [4559, 24631],
      [31, -75],
      [-79, 3],
      [48, 72],
    ],
    [
      [4339, 24988],
      [-31, -50],
      [37, -118],
      [36, -30],
      [25, -81],
      [-58, -5],
      [-92, 107],
      [-31, 62],
      [114, 115],
    ],
    [
      [4658, 25112],
      [65, -22],
      [32, -84],
      [-35, -43],
      [-14, -64],
      [-152, 3],
      [-49, 32],
      [-92, 28],
      [-5, 43],
      [43, 72],
      [91, 27],
      [42, -10],
      [74, 18],
    ],
    [
      [5630, 22662],
      [-79, 42],
      [-122, -19],
      [-31, 7],
      [-92, 98],
      [-82, 174],
      [-61, 87],
      [21, 40],
      [61, 27],
      [158, -29],
      [28, 30],
      [-1, 59],
      [-51, 64],
      [-42, 114],
      [-43, 11],
      [-60, -32],
      [-76, 60],
      [-32, 86],
      [13, 77],
      [30, 33],
      [-3, 63],
      [20, 57],
      [104, 6],
      [65, 69],
      [-23, 42],
      [-86, -71],
      [-53, -24],
      [-46, 75],
      [-47, -27],
      [-86, 23],
      [-57, -7],
      [-57, -55],
      [-58, 15],
      [-44, 66],
      [-35, -28],
      [-35, 60],
      [13, 83],
      [31, 57],
      [47, -46],
      [78, 32],
      [-2, 38],
      [-127, 18],
      [8, 35],
      [111, 43],
      [257, 32],
      [65, -25],
      [76, 30],
      [108, 123],
      [39, 2],
      [52, 90],
      [-55, 86],
      [-31, -22],
      [-63, -118],
      [-60, -35],
      [-116, 11],
      [-35, 22],
      [21, 46],
      [-17, 37],
      [72, 38],
      [48, 68],
      [-10, 84],
      [21, 50],
      [-12, 41],
      [-73, 1],
      [10, 52],
      [-33, 77],
      [-118, 97],
      [-25, 82],
      [-82, 43],
      [24, 56],
      [-54, 123],
      [-123, 147],
      [-4, 104],
      [-23, 19],
      [-120, -5],
      [-70, -30],
      [-51, -44],
      [-85, -25],
      [-46, 32],
      [-20, 45],
      [-85, 4],
      [-40, -138],
      [11, -68],
      [-11, -135],
      [-25, -10],
      [19, 388],
      [69, 203],
      [99, 219],
      [62, -16],
      [28, -54],
      [-101, -52],
      [-47, -79],
      [15, -38],
      [1, -130],
      [21, -41],
      [106, -48],
      [133, 17],
      [133, 8],
      [56, 21],
      [32, 54],
      [97, 4],
      [65, -53],
      [54, 8],
      [7, 39],
      [81, 13],
      [67, -66],
      [90, 34],
      [127, -13],
      [126, -89],
      [168, 5],
      [93, -42],
      [80, -20],
      [53, -69],
      [17, -62],
      [101, 15],
      [82, 87],
      [45, -19],
      [47, -76],
      [52, 54],
      [45, -4],
      [29, 39],
      [105, 76],
      [54, -89],
      [135, -36],
      [54, -28],
      [141, -32],
      [68, -103],
      [35, -18],
      [89, 56],
      [45, 46],
      [53, -42],
      [33, -53],
      [21, -114],
      [82, -74],
      [-72, -4],
      [-89, -35],
      [-24, -137],
      [-67, -12],
      [-33, -41],
      [-117, -55],
      [-24, -43],
      [-90, -80],
      [-11, -37],
      [-166, -29],
      [-22, 26],
      [-69, -59],
      [-67, 6],
      [45, -78],
      [22, 54],
      [65, 22],
      [71, -15],
      [45, -43],
      [44, 3],
      [-1, 71],
      [46, 35],
      [12, 48],
      [63, 32],
      [64, 63],
      [32, 8],
      [162, 114],
      [27, 140],
      [49, 0],
      [99, -38],
      [2, -96],
      [-12, -51],
      [1, -162],
      [-52, -82],
      [-54, -50],
      [-181, -94],
      [5, -63],
      [80, 12],
      [36, 28],
      [111, 18],
      [48, 21],
      [199, 22],
      [108, -77],
      [-46, -59],
      [27, -64],
      [0, -57],
      [-48, -18],
      [-33, -96],
      [58, -36],
      [7, 64],
      [27, 11],
      [37, 110],
      [29, 30],
      [84, 12],
      [66, 46],
      [126, -28],
      [100, -69],
      [86, -41],
      [74, -18],
      [109, -48],
      [64, -44],
      [78, -104],
      [51, -16],
      [133, 19],
      [58, 41],
      [85, 90],
      [141, 74],
      [73, -14],
      [42, -33],
      [103, 29],
      [25, 38],
      [47, 8],
      [18, -53],
      [-97, -84],
      [21, -181],
      [-5, -95],
      [21, -134],
      [-76, -63],
      [-166, -102],
      [-112, -132],
      [-40, -26],
      [-110, 29],
      [-22, -57],
      [-65, -54],
      [-3, -65],
      [56, -99],
      [135, -13],
      [40, -40],
      [16, -63],
    ],
    [
      [4454, 4913],
      [-14, -51],
      [-63, -103],
      [-32, -23],
      [12, -108],
      [28, -45],
      [8, -105],
      [32, -186],
      [19, -60],
      [32, -30],
      [-11, -40],
      [-57, -31],
      [10, -94],
      [15, -21],
    ],
    [
      [4433, 4016],
      [-27, -66],
      [36, -170],
      [-5, -60],
    ],
    [
      [4437, 3720],
      [-18, -12],
      [-72, 27],
      [-40, 96],
      [-44, 183],
      [-25, 69],
      [-35, 31],
      [-49, 12],
      [-37, -39],
      [-33, -68],
      [-10, -64],
      [-69, 9],
      [21, 66],
      [-120, -20],
      [-36, 54],
      [-44, -51],
      [-81, -5],
      [4, 40],
      [-39, 63],
      [-79, -15],
      [17, -62],
      [-50, -49],
      [-33, 23],
      [-129, -28],
    ],
    [
      [3436, 3980],
      [-23, 88],
    ],
    [
      [3413, 4068],
      [16, 8],
      [42, 102],
      [48, 17],
      [41, 89],
      [70, -29],
      [-2, -42],
      [100, 59],
      [9, 57],
      [33, 66],
      [-37, 70],
      [-50, 22],
      [4, 92],
      [-45, 22],
    ],
    [
      [3642, 4601],
      [-26, 22],
      [6, 61],
      [-67, 2],
      [-23, 57],
      [-49, 41],
      [-17, 46],
      [-35, -10],
      [-5, -48],
      [-60, -50],
      [-16, 59],
      [-87, 17],
      [-49, 45],
    ],
    [
      [6555, 4550],
      [-92, -12],
      [19, -72],
    ],
    [
      [6482, 4466],
      [-46, -31],
    ],
    [
      [6436, 4435],
      [-45, 31],
      [-35, -36],
    ],
    [
      [6356, 4430],
      [-20, -76],
      [9, -61],
    ],
    [
      [6345, 4293],
      [-69, 1],
      [-151, 99],
      [-33, -13],
      [12, -56],
      [-36, -13],
      [-48, -115],
      [-7, -60],
      [34, -59],
      [14, -60],
    ],
    [
      [6061, 4017],
      [-38, -4],
      [-63, 52],
      [-87, -1],
      [-56, 35],
      [40, 40],
      [-25, 86],
      [-3, 114],
      [19, 65],
      [57, 43],
      [96, -17],
      [40, 20],
      [169, 12],
      [16, 46],
      [61, 47],
      [53, 15],
      [79, 54],
      [90, -19],
      [46, -55],
    ],
    [
      [6345, 4293],
      [-27, -166],
      [-26, -43],
      [-72, -64],
      [-88, -42],
      [-71, 39],
    ],
    [
      [6436, 4435],
      [-80, -5],
    ],
    [
      [6555, 4550],
      [-61, -49],
      [-12, -35],
    ],
    [
      [3642, 4601],
      [-18, -32],
      [12, -82],
      [-80, -44],
      [-41, 20],
      [-11, -60],
      [-137, -70],
      [-11, -56],
      [-73, 13],
      [-40, 69],
      [-42, 7],
      [-103, -11],
      [-15, 36],
      [-3, 82],
      [74, 18],
      [13, 89],
      [42, 63],
      [-67, 44],
      [-52, -8],
      [-57, 19],
      [-4, -57],
      [28, -11],
      [-11, -91],
      [-62, -9],
      [-87, -71],
      [-19, -47],
      [-52, -9],
      [-22, 43],
      [-46, 10],
      [-23, -67],
    ],
    [
      [2735, 4389],
      [-62, 20],
      [-79, 58],
      [-39, 45],
      [-11, 47],
    ],
    [
      [2544, 4559],
      [95, -22],
    ],
    [
      [2639, 4537],
      [-9, -52],
      [75, -10],
      [48, 8],
      [11, 46],
      [-25, 45],
      [-34, 0],
    ],
    [
      [2705, 4574],
      [16, 37],
    ],
    [
      [2721, 4611],
      [44, -26],
      [132, 33],
      [2, 99],
      [-40, 14],
    ],
    [
      [2859, 4731],
      [41, 79],
      [-57, 28],
      [100, 74],
    ],
    [
      [2943, 4912],
      [1, -58],
      [53, -8],
      [26, -58],
      [36, 84],
      [26, 30],
    ],
    [
      [2639, 4537],
      [66, 37],
    ],
    [
      [2721, 4611],
      [28, 36],
      [-44, 26],
      [26, 48],
      [64, -49],
      [32, 6],
      [32, 53],
    ],
    [
      [2943, 4912],
      [20, 35],
      [59, 23],
    ],
    [
      [2927, 4181],
      [-23, -32],
      [-93, -34],
      [-24, -47],
      [-88, -39],
      [5, -24],
      [-100, -67],
      [-34, -9],
      [21, -71],
      [44, -13],
      [21, -55],
      [41, -11],
      [59, 73],
      [43, -12],
      [41, -43],
      [-46, -51],
      [-32, 15],
      [-80, -107],
      [-62, 11],
      [18, -74],
      [63, -5],
      [42, -57],
      [59, 37],
      [-21, 60],
      [79, 40],
      [36, 74],
      [49, 53],
      [57, -47],
      [85, 10],
      [59, 38],
      [5, 66],
      [-42, 45],
      [-11, 50],
      [-46, 10],
      [-37, 48],
      [-29, 85],
      [119, 17],
      [66, 28],
      [65, -61],
      [13, -31],
      [95, -8],
      [34, 34],
      [35, -9],
    ],
    [
      [3436, 3980],
      [39, -120],
      [29, -26],
      [5, -94],
      [14, -44],
      [-37, -42],
      [25, -94],
      [-22, -98],
      [43, -58],
      [12, -63],
      [43, 10],
      [43, -43],
      [8, -39],
      [-38, -57],
      [-31, -78],
      [-71, -26],
      [17, -50],
      [-44, -55],
      [33, -136],
      [88, -56],
      [47, -74],
      [54, -45],
      [72, 42],
      [36, -8],
    ],
    [
      [3801, 2726],
      [74, 1],
      [101, -98],
      [35, 32],
      [121, 13],
      [80, -45],
      [83, 72],
      [60, 25],
    ],
    [
      [4355, 2726],
      [49, -45],
    ],
    [
      [4404, 2681],
      [95, -22],
    ],
    [
      [4499, 2659],
      [-10, -71],
      [17, -21],
      [1, -120],
      [-52, -7],
      [-39, -30],
      [28, -64],
    ],
    [
      [4444, 2346],
      [-31, 1],
      [-53, -81],
      [-7, -117],
      [-26, -47],
      [-61, -18],
      [-27, -44],
      [-76, -44],
      [-108, -17],
      [-118, 44],
      [-20, 25],
      [-67, 24],
      [-28, -10],
      [-84, 30],
      [-85, -71],
      [9, -72],
      [-151, -96],
      [-61, 2],
      [-84, -57],
      [-38, -43],
      [-125, -80],
      [-18, 28],
      [-118, 57],
      [-76, -80],
      [-76, -16],
      [22, -51],
      [-27, -46],
      [-49, -1],
      [-117, 19],
      [-74, -18],
      [-79, -73],
      [-66, -17],
      [-43, 64],
      [-49, -24],
      [-6, -68],
      [-63, -17],
    ],
    [
      [2364, 1432],
      [-9, 80],
      [-45, 64],
      [27, 100],
      [-29, 55],
      [56, 20],
      [11, 73],
      [-13, 54],
      [30, 15],
      [16, 54],
      [-24, 117],
    ],
    [
      [2384, 2064],
      [35, 21],
      [45, 65],
      [49, -11],
      [16, 107],
      [-11, 54],
      [16, 48],
      [85, 4],
      [14, 104],
      [-55, 20],
      [4, 36],
      [-74, 14],
      [-20, 32],
      [15, 85],
      [-22, 36],
      [90, 221],
      [27, 27],
      [-8, 74],
      [-57, 18],
      [-72, -11],
      [-126, 56],
      [4, 152],
      [46, 59],
      [-34, 66],
      [-108, -60],
      [-106, -29],
      [-71, 1],
    ],
    [
      [2066, 3253],
      [-29, 66],
    ],
    [
      [2037, 3319],
      [-6, 91],
      [94, 77],
      [-25, 106],
      [-134, 60],
      [-116, -24],
      [-109, -71],
      [39, 128],
      [-45, 97],
      [-21, 2],
    ],
    [
      [1714, 3785],
      [0, 4],
    ],
    [
      [1714, 3789],
      [141, 10],
      [92, 52],
      [102, 42],
      [24, 67],
      [195, 87],
      [46, 64],
      [87, 10],
      [36, -28],
      [71, 2],
      [108, 101],
      [68, -23],
      [243, 8],
    ],
    [
      [1599, 2611],
      [-1, -36],
      [-37, -49],
      [-23, 38],
      [39, 57],
      [22, -10],
    ],
    [
      [1727, 2661],
      [39, -44],
      [-35, -72],
      [-79, 37],
      [58, 91],
      [17, -12],
    ],
    [
      [1565, 2890],
      [183, 151],
    ],
    [
      [1748, 3041],
      [63, -66],
      [42, -67],
      [-32, -41],
      [-1, -78],
      [30, -19],
      [-35, -59],
      [-55, -21],
      [-36, 33],
      [-58, -37],
      [-68, 46],
      [-31, 51],
      [-2, 107],
    ],
    [
      [2384, 2064],
      [-50, -58],
      [-24, 38],
      [-57, -54],
      [-25, 3],
      [-19, -58],
      [-55, -58],
      [-75, 6],
      [-70, -80],
      [-51, -33],
      [-20, 30],
      [-13, 84],
      [-70, 34],
      [-60, 53],
      [-100, -72],
      [-52, 84],
      [28, 36],
      [66, 0],
      [16, 78],
      [-41, 53],
      [-50, 0],
      [-28, 68],
      [-7, 103],
      [59, 49],
      [33, -17],
      [67, 136],
      [55, 55],
      [31, 56],
      [-18, 30],
      [14, 78],
      [45, 52],
      [-17, 37],
      [42, 88],
      [14, 82],
      [-55, -6],
      [-88, 138],
    ],
    [
      [1809, 3099],
      [68, 58],
    ],
    [
      [1877, 3157],
      [67, -93],
      [90, -97],
      [9, -81],
      [53, 74],
      [-2, 43],
      [43, 47],
      [-63, 95],
      [5, 95],
      [-13, 13],
    ],
    [
      [648, 1337],
      [0, -74],
      [49, -41],
      [34, 14],
      [31, -47],
      [-42, -63],
      [-71, -37],
      [-92, -102],
      [-24, -39],
      [-80, -49],
      [-66, 33],
      [-89, -36],
      [-135, 17],
      [55, 111],
      [-32, 51],
      [74, 77],
      [47, 2],
      [134, 57],
      [-18, 95],
      [38, 79],
    ],
    [
      [461, 1385],
      [62, -50],
    ],
    [
      [523, 1335],
      [2, -61],
      [-33, -67],
      [4, -103],
      [31, 3],
      [39, 69],
      [7, 70],
      [21, 6],
      [54, 85],
    ],
    [
      [5472, 3806],
      [109, -122],
      [54, 8],
      [160, -45],
      [3, -128],
      [-17, -39],
      [-73, -49],
      [25, -27],
      [53, -3],
      [36, 19],
      [64, -55],
      [15, -53],
      [-24, -118],
      [27, -59],
      [-6, -45],
    ],
    [
      [5898, 3090],
      [-109, -117],
      [-56, 9],
      [-26, -53],
      [-50, -27],
      [-33, 58],
      [-54, 6],
      [-75, -161],
      [-29, -20],
      [-81, -12],
      [-49, -23],
      [-51, 48],
      [-35, 0],
    ],
    [
      [5250, 2798],
      [-10, 86],
      [61, 47],
      [86, 43],
      [1, 37],
      [-36, 85],
    ],
    [
      [5352, 3096],
      [48, 19],
      [-6, 80],
      [-71, 125],
      [-34, 107],
      [38, 1],
      [94, 133],
      [-17, 103],
      [15, 52],
      [53, 90],
    ],
    [
      [5800, 1010],
      [-75, -23],
      [-64, 93],
      [-50, 30],
      [-16, 73],
      [2, 55],
      [-40, 103],
      [34, 47],
      [-6, 90],
      [28, 13],
      [15, 65],
      [-13, 47],
      [26, 64],
      [-30, 93],
      [15, 53],
      [-20, 37],
      [-64, 11],
      [-53, 150],
      [47, 156],
      [-64, 60],
      [-77, -21],
      [0, 41],
      [-69, 12],
      [-28, -34],
      [6, -60],
      [-115, -69],
      [-27, 19],
      [-52, -21],
      [-50, 29],
      [-110, 2],
      [-48, 15],
    ],
    [
      [4902, 2140],
      [-3, 110],
      [-45, 45],
      [38, 108],
      [4, 57],
      [59, 16],
      [62, 53],
      [-2, 30],
      [39, 47],
      [29, -46],
      [47, 43],
      [32, 86],
      [-4, 45],
      [92, 64],
    ],
    [
      [5898, 3090],
      [47, -31],
      [180, -25],
      [95, -38],
      [23, -30],
      [71, 175],
      [54, 51],
      [6, 50],
      [40, 10],
      [-59, 96],
      [-58, 115],
    ],
    [
      [6297, 3463],
      [112, 37],
      [44, -40],
      [70, 28],
    ],
    [
      [6523, 3488],
      [23, -22],
      [107, 23],
      [64, -48],
      [61, -22],
      [43, 8],
      [108, -76],
      [39, 30],
      [52, -86],
      [-34, -84],
      [6, -54],
      [83, -73],
      [66, -5],
      [94, -40],
      [63, -64],
      [8, -42],
      [59, -16],
      [27, -45],
      [74, 19],
    ],
    [
      [7466, 2891],
      [64, 17],
      [17, 29],
      [65, 11],
      [-6, 68],
      [27, 108],
      [97, -28],
      [30, 13],
      [-10, 64],
      [30, 20],
      [45, 96],
      [70, 31],
      [62, -78],
      [-7, -40],
      [73, -32],
      [38, -36],
      [-6, -59],
      [-32, -77],
      [7, -85],
    ],
    [
      [8030, 2913],
      [-28, -139],
      [-41, -54],
      [23, -101],
      [-62, -42],
      [19, -76],
      [-57, -82],
      [25, -120],
      [79, 5],
      [80, -77],
      [-36, -199],
      [-43, -10],
      [-45, 37],
      [-47, -25],
      [-34, 28],
      [-52, -27],
      [-74, 24],
      [-59, 76],
      [-48, 36],
      [29, 58],
      [-28, 57],
      [-36, -45],
      [-50, 12],
      [-80, -52],
      [-68, 0],
      [-9, -74],
      [-78, -115],
      [3, -58],
      [-21, -76],
      [5, -126],
      [21, -41],
      [60, -17],
      [17, 22],
      [61, -3],
      [43, -49],
      [-3, -63],
      [-48, -28],
      [-40, -97],
      [-1, -57],
      [73, -86],
      [35, -86],
      [-49, -101],
      [-29, -11],
      [-74, 16],
      [-40, -30],
      [-24, 27],
      [14, 81],
      [-51, 60],
      [-63, 20],
      [-16, 103],
      [14, 84],
      [-44, 24],
      [-25, 58],
      [-71, -24],
      [-14, 27],
      [-66, -49],
      [-64, -5],
      [-23, -30],
      [-85, -41],
      [-60, 14],
      [-15, -90],
      [-25, -58],
      [-51, 11],
      [-80, -28],
      [-106, 29],
      [-47, 29],
      [-56, 73],
      [-18, 55],
      [-67, 56],
      [2, 58],
      [-27, 93],
      [7, 117],
      [17, 38],
      [-11, 55],
      [-54, -16],
      [-31, -65],
      [-32, 5],
      [-51, 92],
      [-85, -1],
      [-62, -55],
      [-11, -99],
      [-37, -55],
      [48, -44],
      [-3, -141],
      [31, -15],
      [-3, -104],
      [-32, -89],
      [-40, -57],
      [-19, -97],
      [-72, -101],
      [-15, -52],
    ],
    [
      [2735, 4389],
      [39, -25],
      [74, 1],
      [67, -34],
      [51, -99],
      [-39, -51],
    ],
    [
      [1714, 3789],
      [50, 92],
      [133, 122],
      [-15, 28],
      [2, 110],
      [81, 9],
      [21, 83],
      [81, 25],
      [9, 33],
      [-31, 71],
      [-54, 10],
      [-128, -45],
      [-93, -4],
      [3, 53],
      [92, 88],
      [2, 63],
      [49, 47],
      [51, 12],
      [-23, 119],
      [80, 25],
      [80, -26],
      [90, 31],
      [107, -75],
      [-37, -83],
      [51, -35],
      [50, 18],
      [39, -49],
      [60, 38],
      [80, 10],
    ],
    [
      [1727, 1617],
      [-59, -21],
      [-25, 11],
    ],
    [
      [1643, 1607],
      [25, 68],
      [-9, 32],
      [-145, 54],
      [-121, 25],
      [-268, -2],
      [-136, -105],
      [-179, -43],
      [-140, -148],
      [-58, -107],
      [36, -44],
    ],
    [
      [523, 1335],
      [60, 89],
      [22, 92],
      [61, 82],
      [57, 22],
      [14, 77],
      [94, 105],
      [63, 13],
      [35, -21],
      [72, 39],
      [107, 119],
      [96, -21],
      [49, 30],
      [64, -31],
      [120, -14],
      [43, -39],
      [49, 6],
      [44, -38],
      [107, -21],
      [99, -82],
      [50, -31],
      [19, -55],
      [-16, -38],
      [-105, -1],
    ],
    [
      [4437, 3720],
      [9, -54],
      [57, 13],
      [50, -58],
    ],
    [
      [4553, 3621],
      [-52, 18],
      [-67, -57],
      [-12, -46],
      [36, -63],
      [-50, -59],
      [65, -5],
      [80, -48],
      [-7, -45],
    ],
    [
      [4546, 3316],
      [-51, 11],
      [-139, 6],
      [-56, -66],
      [-8, -42],
      [-38, -23],
    ],
    [
      [4254, 3202],
      [-18, 56],
      [-79, 4],
      [-81, -34],
      [-27, 12],
      [-67, -39],
      [23, -57],
      [-100, -82],
      [-64, -33],
      [-10, -107],
      [-38, -60],
      [37, -83],
      [-29, -53],
    ],
    [
      [2037, 3319],
      [-48, -68],
      [-112, -94],
    ],
    [
      [1809, 3099],
      [-61, -58],
    ],
    [
      [1565, 2890],
      [-9, 46],
      [-33, 21],
      [-3, 64],
      [-42, 41],
      [5, 74],
      [-128, -68],
      [-28, -61],
      [-77, -15],
      [-65, -36],
      [-48, -47],
      [-98, -18],
    ],
    [
      [1039, 2891],
      [18, 95],
      [-7, 39],
      [-48, 51],
      [14, 48],
      [85, 111],
      [27, -3],
      [96, 37],
      [45, 30],
      [64, 4],
      [42, 88],
      [75, 38],
      [30, 36],
      [-25, 56],
      [22, 49],
      [49, 12],
      [-9, 44],
      [115, 60],
      [82, 99],
    ],
    [
      [4546, 3316],
      [41, -22],
      [85, -4],
      [52, 27],
    ],
    [
      [4724, 3317],
      [-11, -59],
      [-26, -44],
      [-1, -61],
      [-33, -44],
      [-126, -57],
      [23, -63],
      [-10, -72],
    ],
    [
      [4540, 2917],
      [-88, -8],
      [-14, 44],
      [-90, -19],
      [43, -161],
      [62, -48],
      [-49, -44],
    ],
    [
      [4355, 2726],
      [-77, 89],
      [23, 126],
      [-13, 33],
      [29, 100],
      [-6, 46],
      [-57, -13],
      [-34, 28],
      [34, 67],
    ],
    [
      [4540, 2917],
      [47, -13],
      [-19, -47],
      [-16, -131],
      [21, -45],
      [-74, -22],
    ],
    [
      [5472, 3806],
      [-44, 30],
      [-32, -14],
      [14, 108],
      [-49, 0],
      [-69, -29],
      [-57, 1],
      [-48, -43],
      [-115, 43],
    ],
    [
      [5072, 3902],
      [46, 85],
      [43, 38],
      [77, -17],
      [7, 22],
      [108, 23],
      [25, 57],
      [9, 67],
      [49, 39],
      [-67, 166],
    ],
    [
      [5369, 4382],
      [46, 39],
      [46, 121],
      [55, 6],
      [58, 26],
      [-60, 33],
      [-33, 39],
      [5, 51],
      [49, 18],
      [33, -23],
      [115, -10],
      [103, 29],
      [37, -51],
      [23, -120],
      [87, 55],
      [88, 104],
      [-5, 50],
      [-57, 36],
      [84, 19],
      [107, -148],
      [20, 49],
      [31, 9],
      [73, 83],
      [69, 99],
    ],
    [
      [6448, 4823],
      [-11, -96],
      [50, -59],
      [22, -47],
      [99, -23],
      [-17, -49],
      [13, -82],
      [35, -44],
      [0, -49],
      [-27, -23],
      [-60, -9],
      [-40, -52],
      [-21, -87],
      [-42, -35],
      [-24, -56],
      [-35, -31],
    ],
    [
      [6390, 4081],
      [-15, -72],
      [-35, -70],
      [-28, -106],
      [38, -112],
      [18, -138],
      [-77, -82],
      [6, -38],
    ],
    [
      [4989, 5276],
      [-63, -1],
      [-38, -25],
    ],
    [
      [4888, 5250],
      [-79, 16],
      [4, -35],
      [-38, -25],
    ],
    [
      [5352, 3096],
      [-153, -86],
      [-66, 141],
      [-37, -27],
      [-30, 29],
      [-80, -36],
      [-32, -29],
      [-30, 82],
      [-37, -7],
      [-96, 34],
      [-15, 99],
      [-52, 21],
    ],
    [
      [4553, 3621],
      [41, -55],
      [103, 18],
      [5, -35],
      [79, 36],
      [53, 1],
      [57, 61],
      [50, 97],
      [-17, 31],
    ],
    [
      [4924, 3775],
      [-21, 55],
      [40, 56],
      [64, 24],
      [65, -8],
    ],
    [
      [5369, 4382],
      [-3, 25],
      [-69, 44],
      [18, 36],
      [-22, 112],
      [-36, 57],
      [20, 41],
      [8, 101],
      [-99, 19],
      [-18, 64],
      [13, 43],
      [-134, 64],
      [-37, 52],
      [38, 19],
      [50, -26],
      [42, 32],
      [20, 76],
      [-71, 21],
      [-73, -36],
      [-66, 2],
      [-31, 23],
      [-36, 76],
      [5, 23],
    ],
    [
      [5800, 1010],
      [-123, -123],
      [-73, -58],
      [16, -73],
      [-22, -78],
      [-66, -26],
      [-46, -46],
      [20, -109],
      [-46, -58],
      [32, -112],
      [125, -90],
      [-65, -91],
      [-34, -121],
      [-31, -25],
      [-38, 13],
      [-71, 57],
      [-73, -37],
      [11, 70],
      [42, 42],
      [-36, 77],
      [4, 31],
      [-48, 88],
      [-2, 57],
      [-47, -1],
      [-64, 74],
      [-63, 15],
      [-3, 48],
      [87, 114],
      [17, 62],
      [-70, 72],
      [-46, -22],
      [-66, 38],
      [-88, -18],
      [-86, 56],
      [-46, -1],
      [-50, 70],
      [-51, 122],
      [-40, 40],
      [-17, 59],
      [-107, 37],
      [-28, 40],
      [-1, 53],
      [-45, 100],
      [59, 80],
      [14, 92],
      [-11, 61],
      [13, 35],
      [-23, 78],
      [14, 57],
      [-49, 58],
      [-91, -35],
    ],
    [
      [4388, 1782],
      [13, 122],
      [90, -3],
      [60, 93],
    ],
    [
      [4551, 1994],
      [72, 29],
      [-12, 58],
      [27, 75],
      [107, -33],
      [57, 16],
      [36, -40],
      [64, 41],
    ],
    [
      [4551, 1994],
      [-92, 47],
      [-27, 89],
      [-3, 69],
      [23, 50],
      [-8, 97],
    ],
    [
      [4388, 1782],
      [-39, -4],
      [-65, -72],
      [-33, -3],
      [24, -92],
      [-12, -57],
      [-79, -44],
      [-21, -44],
      [-36, -11],
      [-25, -65],
      [-31, -40],
      [-52, -17],
      [-64, 11],
      [-85, -113],
      [76, -78],
      [58, -140],
      [-21, -113],
      [-47, -31],
      [-31, -60],
      [-69, -12],
      [-53, -43],
      [-19, -51],
      [11, -82],
      [-82, -130],
      [-141, -6],
      [-25, -37],
      [-54, -146],
      [-76, 6],
      [-25, -28],
      [-76, 71],
      [-42, -56],
      [-34, 13],
      [-6, 57],
      [-53, 31],
      [-36, 51],
      [-101, -22],
      [-44, 50],
      [-53, -2],
      [2, -68],
      [-54, 1],
      [-69, -23],
      [-11, -51],
      [-46, -19],
      [-42, -46],
      [-61, -37],
      [-53, 35],
      [-43, -5],
      [-63, 31],
      [-62, -87],
      [-72, -4],
      [-47, -84],
      [-59, 52],
      [-66, -52],
      [-39, 9],
      [-30, 91],
      [-68, 96],
      [3, 68],
      [-43, 21],
      [3, 53],
      [-25, 60],
      [-35, 10],
      [-42, 68],
      [-21, 60],
      [-36, 45],
      [-45, -51],
      [-29, 56],
      [29, 54],
      [-2, 101],
      [-42, 20],
      [-99, -3],
      [-41, 30],
      [-6, 52],
      [42, 54],
      [-5, 89],
      [39, 103],
      [30, 17],
      [13, 110],
      [-98, 92],
      [-51, 78],
      [56, 49],
      [4, 59],
    ],
    [
      [1727, 1617],
      [46, -55],
      [-8, -57],
      [30, -42],
      [60, -34],
      [8, -85],
      [41, -39],
      [8, -42],
      [42, -47],
      [42, -133],
      [33, -42],
      [100, 34],
      [58, 58],
      [100, 128],
      [17, 4],
      [0, 100],
      [60, 67],
    ],
    [
      [461, 1385],
      [-17, 14],
      [79, 139],
      [-139, 124],
      [-44, 12],
      [39, 72],
      [-24, 69],
      [41, 45],
      [26, 78],
      [70, 75],
      [-52, 122],
      [248, 254],
      [47, 68],
      [85, 46],
      [52, 59],
      [27, 1],
      [31, 53],
      [72, 31],
      [34, 63],
      [-40, 58],
      [15, 72],
      [28, 51],
    ],
    [
      [4433, 4016],
      [21, -67],
      [102, -44],
      [129, 41],
      [91, -47],
      [59, -108],
      [38, -22],
      [51, 6],
    ],
    [
      [17680, 2847],
      [32, 140],
      [38, 51],
      [45, 28],
      [21, 65],
      [35, -3],
      [3, 52],
      [60, 121],
      [26, 5],
      [-79, 170],
      [-20, 82],
      [9, 52],
      [42, 75],
      [-12, 122],
      [-40, 98],
      [4, 87],
      [-20, 93],
      [-27, 57],
      [-16, 103],
      [-59, 96],
      [26, 35],
      [134, 46],
      [-10, 56],
      [46, -5],
      [78, 28],
    ],
    [
      [17996, 4501],
      [89, 15],
      [56, 64],
      [85, 51],
      [65, 138],
      [-49, 78],
      [3, 60],
      [30, 46],
      [-2, 39],
      [73, 80],
      [1, 59],
      [-34, 70],
      [-73, 41],
      [-28, 89],
      [22, 185],
      [-62, 33],
      [14, 42],
      [55, -8],
      [27, -54],
      [36, 42],
      [4, 37],
      [66, 76],
      [-65, 68],
      [87, 31],
      [34, 56],
      [10, 67],
      [41, 44],
      [66, 20],
      [59, -25],
      [55, -104],
      [47, -29],
      [66, 105],
      [15, 56],
      [52, 40],
      [62, 6],
      [9, 137],
      [98, -5],
      [26, 22],
      [104, 17],
      [74, 107],
      [60, 11],
      [44, -24],
      [28, -59],
      [80, -31],
      [30, 24],
      [20, 77],
      [30, 41],
      [1, 50],
      [62, 68],
    ],
    [
      [19569, 6454],
      [23, -64],
      [-38, -59],
      [0, -48],
      [33, -27],
      [-35, -38],
      [45, -32],
      [26, 28],
      [48, -15],
      [43, -51],
      [-119, -103],
      [41, -26],
      [-46, -74],
      [35, -28],
      [-29, -60],
      [-19, -76],
      [-73, -24],
      [-23, -45],
      [78, -118],
      [-20, -27],
      [13, -208],
      [29, -47],
      [-89, -11],
      [-162, -44],
      [-126, -1],
      [0, 82],
      [-51, -44],
      [-20, -60],
      [-142, 1],
      [-45, 112],
      [0, 38],
      [-119, 14],
      [-36, 52],
      [-41, 6],
      [-29, -29],
      [-78, 0],
      [-21, -37],
      [25, -41],
      [-228, -169],
      [166, -51],
      [117, -80],
      [84, 33],
      [40, -16],
      [92, -282],
      [-22, -30],
      [-76, -30],
      [31, -59],
      [-11, -71],
      [-140, -103],
      [-11, -33],
      [-95, 12],
      [-41, -60],
      [-22, 52],
      [-63, 4],
      [16, -110],
      [-30, -45],
      [37, -47],
      [50, -154],
      [-34, -85],
      [-46, -5],
      [-8, -89],
      [-37, -64],
      [20, -35],
      [37, 10],
      [25, -61],
      [-9, -67],
      [96, 23],
      [27, -66],
      [-52, -10],
      [-1, -37],
      [-65, -33],
      [18, -65],
      [82, -35],
      [-24, -32],
      [-102, -30],
      [-5, -39],
      [57, 1],
      [49, -56],
      [-50, -34],
      [-48, 22],
      [-48, -22],
      [-16, 29],
      [-69, -14],
      [-164, -12],
      [-10, -87],
      [-37, -3],
      [-46, -73],
      [-50, 7],
      [-41, -94],
      [-26, -5],
      [-47, -79],
      [-125, -78],
      [-10, -28],
      [-54, 4],
      [-48, -22],
    ],
    [
      [13775, 3140],
      [75, -83],
      [98, 20],
      [162, 122],
      [43, 12],
      [28, 44],
      [-4, 44],
      [63, -1],
      [109, 101],
      [38, 57],
      [50, -27],
      [73, 67],
      [51, -133],
      [103, -8],
      [70, 9],
      [59, -11],
      [34, -31],
      [-10, -62],
      [16, -31],
      [78, 46],
      [47, -1],
      [4, 44],
      [64, 34],
      [37, -41],
      [122, 6],
      [54, -39],
      [16, 40],
      [70, 14],
      [106, 7],
      [78, 53],
      [51, -4],
      [50, 30],
      [55, -60],
      [31, -11],
      [60, -61],
      [104, -45],
      [47, -124],
      [51, -54],
      [7, -42],
      [-23, -89],
      [5, -108],
      [-39, -29],
      [-24, -104],
      [90, -35],
      [19, -121],
      [-5, -139],
      [9, -35],
      [50, -19],
    ],
    [
      [16047, 2342],
      [-48, -8],
      [-65, -77],
      [-74, 23],
      [-71, -75],
      [-42, 22],
      [-34, -15],
      [-65, -121],
      [-26, -152],
      [-56, -30],
      [-32, 12],
      [-88, -10],
      [-36, -103],
      [-65, -60],
      [-113, -21],
      [-22, -166],
      [-42, -6],
      [-31, 107],
      [-47, 42],
      [-39, -39],
      [-83, 23],
      [-33, 71],
      [-75, -22],
      [-131, -19],
      [-48, 33],
      [-75, -30],
      [-26, 22],
      [-92, -18],
      [-51, 37],
      [-36, 85],
      [-140, 45],
      [-47, -40],
      [-98, 77],
      [-69, 36],
      [-56, 7],
      [-35, -17],
      [-53, 7],
      [-35, -34],
      [-62, 35],
      [-68, -3],
      [-28, 15],
      [-82, -2],
      [-72, 48],
      [-126, 35],
      [-88, 4],
      [-12, 37],
      [-59, -21],
      [-84, 0],
      [-79, 52],
      [-119, -66],
      [-65, 25],
      [-59, -21],
      [-35, 45],
      [-29, 0],
      [-63, 49],
      [-40, -2],
      [-90, 32],
      [-80, -13],
      [-45, 23],
      [-64, -8],
      [-94, 24],
      [-138, -15],
      [-28, 14],
      [-12, 57],
      [-62, 40],
      [-67, 11],
      [-29, -36],
      [-44, 44],
      [-47, 20],
    ],
    [
      [11898, 2351],
      [5, 143],
      [28, 34],
      [5, 59],
      [22, 21],
      [133, 39],
      [71, -20],
      [64, 39],
      [36, 39],
      [41, -33],
      [37, 10],
      [38, 54],
      [-20, 35],
      [-102, 69],
      [-19, 52],
      [-81, 52],
      [-4, 143],
      [-95, 58],
      [-42, 67],
      [-24, 9],
      [-31, 90],
      [16, 61],
      [36, 33],
      [-20, 47],
      [-45, 3],
      [-29, 83],
      [-75, 73],
    ],
    [
      [11843, 3611],
      [25, 59],
      [61, 4],
      [47, 27],
      [13, -87],
      [114, -2],
      [54, -42],
      [56, 9],
      [115, -31],
      [32, -93],
      [48, -23],
      [31, 23],
      [28, -41],
      [88, -47],
      [11, 36],
      [81, -1],
      [75, 42],
      [132, 20],
      [-17, 57],
      [18, 41],
      [47, 22],
      [56, -27],
      [92, 29],
      [31, -50],
      [44, -33],
      [34, 31],
      [55, -6],
      [107, -36],
      [98, -48],
      [68, 17],
      [44, -47],
      [86, -8],
      [0, -93],
      [134, -81],
      [24, -92],
    ],
    [
      [17996, 4501],
      [-52, 48],
      [-6, 60],
      [-39, 35],
      [12, 102],
      [-98, -29],
      [-16, -47],
      [-52, 76],
      [-32, 15],
      [-99, -1],
      [-65, 31],
      [-18, 92],
      [-27, 24],
      [-72, -12],
      [2, 78],
      [24, 41],
      [-48, 30],
      [-20, 44],
      [-75, 11],
      [-60, -31],
      [-36, 39],
      [4, 60],
      [-23, 81],
      [7, 41],
      [-87, -1],
      [-64, 20],
      [-52, 102],
      [-94, -19],
      [-66, -1],
      [-4, 49],
      [-58, 23],
      [-46, 0],
      [-35, 57],
      [-53, -17],
      [-12, 84],
      [-56, 35],
      [-73, 5],
      [-61, -21],
      [-24, -89],
      [-87, 22],
      [-51, -33],
      [-11, -96],
      [-112, 15],
      [-11, -36],
      [-132, -19],
      [-39, 34],
      [-86, -36],
      [-25, 11],
      [-75, -90],
      [-30, -11],
      [-64, 19],
      [-30, 77],
      [-35, -25],
      [-40, 32],
      [-82, -48],
      [-62, 60],
    ],
    [
      [15450, 5392],
      [-48, 123],
      [34, 27],
      [-37, 71],
      [26, 53],
      [-20, 36],
      [73, 37],
      [28, 48],
      [-77, 154],
      [-45, -29],
      [-99, 54],
      [-38, 1],
      [-72, 70],
      [-90, 64],
      [-47, 11],
      [-84, 162],
      [14, 136],
      [54, 52],
      [-42, 38],
      [-1, 65],
      [30, 82],
      [-5, 63],
      [22, 72],
      [52, 39],
      [105, -38],
      [48, -82],
      [101, -96],
      [36, -19],
      [43, 62],
      [42, -19],
      [184, 64],
      [20, 82],
      [53, 29],
      [124, -21],
      [24, 59],
      [39, 30],
      [-16, 190],
      [-65, 0],
      [37, 182],
      [-48, 19],
      [12, 69],
      [-45, 24],
      [-32, 62],
      [-31, 9],
      [-25, 75],
      [45, 50],
      [48, 5],
      [-31, 59],
      [-87, 23],
      [-23, 38],
      [-77, 42],
      [-54, 14],
      [-53, -10],
      [-83, 45],
    ],
    [
      [15369, 7768],
      [51, 4],
      [28, 50],
      [2, 107],
      [-14, 37],
      [42, 129],
      [73, 38],
      [32, 53],
      [32, 96],
      [-8, 33],
      [122, 10],
      [38, -35],
      [35, 3],
      [69, -33],
      [33, 38],
      [-31, 44],
      [-8, 73],
      [40, 103],
      [-10, 62],
      [23, 15],
      [12, 66],
      [-21, 78],
      [-6, 80],
      [34, 146],
      [96, 5],
      [26, -47],
      [122, 11],
      [33, -12],
      [22, -75],
      [-2, -50],
      [41, -11],
      [52, 42],
      [70, -15],
      [53, 103],
      [23, -34],
      [101, 4],
      [51, -60],
      [50, -35],
      [47, 4],
      [86, -32],
      [16, -76],
      [195, -40],
      [68, -43],
      [51, -64],
      [115, -10],
      [41, 50],
      [48, -2],
      [124, -46],
      [90, -62],
      [34, -87],
      [36, -10],
      [112, -95],
      [25, -36],
      [109, -44],
      [154, 9],
      [48, -23],
      [130, 8],
      [120, -31],
      [69, 42],
      [56, 79],
      [21, 65],
      [130, 7],
      [46, 28],
      [38, -49],
      [61, -35],
      [82, 18],
      [37, -37],
      [14, -103],
      [62, 25],
      [46, -14],
      [21, -44],
      [71, -25],
      [113, 0],
      [30, 44],
      [65, -64],
      [9, -229],
      [30, -8],
      [-13, -106],
      [35, -91],
      [-15, -65],
      [-55, -30],
      [-33, -73],
      [-76, -104],
      [11, -132],
      [-28, -80],
      [11, -69],
      [105, -70],
      [-18, -82],
      [44, -61],
      [56, -31],
      [-10, -67],
      [46, -124],
      [2, -67],
      [86, -77],
      [59, -1],
      [37, -77],
    ],
    [
      [18419, 7056],
      [-62, -17],
      [-5, -79],
      [-59, -8],
      [-134, -85],
      [-21, -39],
      [-76, 60],
      [-31, -32],
      [13, -169],
      [-36, -56],
      [69, -95],
      [136, -23],
      [46, 25],
      [154, -53],
      [43, -3],
      [73, 103],
      [65, 3],
      [35, -41],
      [73, -30],
      [3, 65],
      [-61, 27],
      [-11, 28],
      [3, 91],
      [15, 95],
      [-10, 58],
      [-61, 32],
      [13, 33],
      [-62, 26],
      [-10, -52],
      [-60, 49],
      [-4, 70],
      [-38, 17],
    ],
    [
      [15450, 5392],
      [-78, 50],
      [-42, -71],
      [-40, -34],
      [-84, -5],
      [-15, -22],
      [-70, -1],
      [-49, -35],
      [-14, -78],
      [-46, 5],
      [-91, -87],
      [-69, 2],
      [-5, -45],
      [-61, -25],
      [-89, -64],
      [-49, 22],
      [-32, -12],
      [-109, 123],
      [-42, -40],
      [-203, -63],
      [-34, 32],
      [16, 122],
      [-97, 104],
      [-33, -17],
      [-52, 37],
      [-56, -14],
      [-76, 60],
      [-132, -27],
      [-42, 7],
      [-41, -50],
      [-31, -74],
      [-54, -27],
      [-21, -119],
      [3, -122],
      [70, -15],
      [38, -74],
      [-18, -31],
      [28, -62],
      [-20, -53],
      [-54, -80],
      [14, -33],
      [-92, 1],
      [-151, 41],
    ],
    [
      [13427, 4618],
      [-96, 66],
      [-19, -3],
      [-78, 109],
      [13, 64],
      [-11, 46],
      [45, 37],
      [1, 53],
      [30, 50],
      [-7, 30],
      [-52, 8],
      [-11, 31],
      [25, 75],
      [62, 45],
      [30, 86],
      [-131, 33],
      [-69, 43],
      [16, 95],
      [33, 41],
      [91, -42],
      [20, 59],
      [-69, -8],
      [-18, 33],
      [-49, 8],
      [-101, -25],
      [-77, 57],
      [-62, -2],
      [-45, 49],
      [15, 75],
      [-43, 98],
      [19, 12],
      [8, 95],
      [-8, 74],
      [49, 27],
      [85, -66],
      [29, 74],
      [-86, 73],
      [-39, 8],
      [-22, 40],
      [-56, -54],
      [-25, 2],
      [-107, -60],
      [-183, 78],
      [-55, 102],
      [-23, -25],
      [-93, -23],
      [-55, 38],
      [-44, -36],
      [-34, -88],
      [-21, 18],
    ],
    [
      [13906, 8293],
      [54, 2],
      [76, -69],
      [42, -72],
      [112, -38],
      [38, -62],
      [64, -31],
      [-10, -53],
      [-47, -5],
      [-17, -52],
      [45, -9],
      [5, -58],
      [99, -53],
      [93, 15],
      [72, -6],
      [74, -44],
      [59, 5],
      [27, -48],
      [49, -21],
      [27, -49],
      [53, 101],
      [48, -15],
      [3, 62],
      [64, -7],
      [29, 42],
      [15, 71],
      [32, 50],
      [56, -82],
      [76, -10],
      [32, -23],
      [94, 46],
      [46, -39],
      [53, -73],
    ],
    [
      [13427, 4618],
      [11, -40],
      [-48, -114],
      [19, -45],
      [23, -126],
      [30, -86],
      [-8, -101],
      [57, -57],
      [79, 0],
      [61, -44],
      [20, 52],
      [108, -10],
      [35, 47],
      [32, 4],
      [1, -76],
      [71, -41],
      [-21, -53],
      [55, -81],
      [34, -24],
      [25, -63],
      [36, -9],
      [50, -51],
      [41, -21],
      [-11, -49],
      [-48, -3],
      [-27, 36],
      [-103, -74],
      [-9, -30],
      [43, -71],
      [-4, -34],
      [-64, -105],
      [0, -68],
      [-33, -21],
      [-24, -62],
      [-83, -58],
    ],
    [
      [11843, 3611],
      [-56, 49],
      [-65, 37],
      [-62, -16],
      [-47, 28],
      [-10, 34],
      [-43, 17],
      [-61, -35],
      [-53, 16],
      [-42, -21],
      [-22, 27],
      [-64, -33],
      [-11, -66],
      [-24, -21],
      [-110, -42],
      [-55, -53],
    ],
    [
      [11118, 3532],
      [-40, 36],
      [-143, -24],
    ],
    [
      [10935, 3544],
      [-6, 63],
      [-32, 86],
      [-23, 12],
      [-29, 142],
      [8, 59],
      [40, 79],
      [-15, 44],
      [-38, 29],
      [11, 48],
      [48, 63],
      [49, -48],
      [38, 30],
      [60, 3],
      [45, 40],
      [18, -32],
      [46, 5],
      [56, 89],
      [135, -55],
      [67, 14],
      [55, 39],
      [48, -11],
      [52, 70],
      [-34, 66],
      [52, 70],
      [30, -10],
      [58, 28],
      [39, -19],
      [50, 67],
      [30, 8],
      [27, 55],
      [-3, 83],
      [56, 2],
      [60, 39],
      [-3, 26],
      [-62, 57],
      [-16, 53],
      [-44, 53],
      [65, 80],
      [-27, 27],
      [-127, 38],
      [-17, 61],
    ],
    [
      [17680, 2847],
      [-22, -18],
      [23, -53],
      [-32, -163],
      [31, -63],
      [50, -51],
      [25, -62],
      [-3, -72],
      [-52, 18],
      [-24, 39],
      [-89, 22],
      [-59, 55],
      [-62, 29],
      [-109, 6],
      [-74, -60],
      [-85, -16],
      [-76, 34],
      [-68, -40],
      [-17, -42],
      [-62, 31],
      [-42, -23],
      [-37, -53],
      [7, -66],
      [-83, -72],
      [-58, -6],
      [-26, 78],
      [-27, 1],
      [-42, 48],
      [-81, -17],
      [-62, 15],
      [-69, -3],
      [-36, -31],
      [-111, -3],
      [-67, 32],
      [-118, 13],
      [-40, -38],
      [-36, 26],
    ],
    [
      [11898, 2351],
      [-75, -10],
      [-147, 10],
      [-70, 52],
      [-57, 6],
      [-78, 27],
      [-103, 84],
      [-34, 120],
      [-26, 50],
      [-113, 23],
      [6, 84],
      [31, 50],
      [-60, 152],
      [-49, 7],
      [-46, -39],
      [-44, 83],
      [-51, 9],
      [5, 75],
      [-50, 74],
      [0, 64],
      [-20, 61],
      [46, 51],
      [41, -4],
      [64, 27],
      [15, 66],
      [35, 59],
    ],
    [
      [10935, 3544],
      [-150, -80],
      [-85, -6],
      [-118, -45],
      [-127, -99],
      [-80, -34],
      [-67, -6],
      [-47, -37],
      [-33, 6],
      [-24, 51],
      [-85, 5],
      [-88, 56],
      [-138, -79],
      [-61, 68],
      [-44, 3],
      [-27, -54],
      [-94, -67],
      [-42, 16],
      [-47, 51],
      [-64, 1],
      [-196, -74],
      [-77, 3],
      [0, -68],
      [-19, -26],
      [-77, -21],
      [-21, -59],
      [3, -58],
      [-41, -77],
      [0, -106],
      [-60, -63],
      [-28, -79],
      [-28, -14],
      [-135, 25],
      [-100, -21],
      [-40, 26],
      [-63, 7],
      [-43, 49],
      [-59, -15],
      [-42, 28],
      [46, 52],
      [-9, 45],
      [-108, 50],
      [8, 28],
      [-53, 38],
      [-21, -21],
      [-79, -18],
      [-79, -57],
      [-26, 25],
      [-83, -6],
      [-54, 26],
    ],
    [
      [7466, 2891],
      [-8, 64],
      [-58, 62],
      [-18, 93],
      [44, 37],
      [17, 85],
      [30, 46],
      [-4, 42],
      [-56, 49],
      [54, 70],
      [-40, 43],
      [-2, 49],
      [22, 42],
      [103, 126],
      [14, 80],
      [-20, 67],
      [31, 35],
      [-10, 73],
      [23, 65],
      [-78, 25],
      [-1, 27],
    ],
    [
      [6523, 3488],
      [9, 52],
      [43, 44],
      [-27, 48],
      [23, 40],
      [-16, 53],
      [-37, 16],
      [-45, 53],
      [-9, 51],
      [23, 42],
      [-55, 61],
      [26, 43],
      [-68, 90],
    ],
  ],
  objects: {
    layer: {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]],
          type: "Polygon",
          properties: {
            ISO: "DEU",
            NAME_0: "Germany",
            ID_1: 753,
            NAME_1: "Baden-Württemberg",
            VARNAME_1: "",
            HASC_1: "DE.BW",
            TYPE_1: "Land",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [[11, 12, 13, 14, 15, 16, 17, -11, 18, 19]],
          type: "Polygon",
          properties: {
            ISO: "DEU",
            NAME_0: "Germany",
            ID_1: 754,
            NAME_1: "Bayern",
            VARNAME_1: "Bavaria",
            HASC_1: "DE.BY",
            TYPE_1: "Land",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [[20]],
          type: "Polygon",
          properties: {
            ISO: "DEU",
            NAME_0: "Germany",
            ID_1: 755,
            NAME_1: "Berlin",
            VARNAME_1: "",
            HASC_1: "DE.BE",
            TYPE_1: "Land",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [[21, 22, 23, 24, 25], [-21]],
          type: "Polygon",
          properties: {
            ISO: "DEU",
            NAME_0: "Germany",
            ID_1: 756,
            NAME_1: "Brandenburg",
            VARNAME_1: "",
            HASC_1: "DE.BR",
            TYPE_1: "Land",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [[26]],
          type: "Polygon",
          properties: {
            ISO: "DEU",
            NAME_0: "Germany",
            ID_1: 757,
            NAME_1: "Bremen",
            VARNAME_1: "",
            HASC_1: "DE.HB",
            TYPE_1: "Land",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [[27, 28]],
          type: "Polygon",
          properties: {
            ISO: "DEU",
            NAME_0: "Germany",
            ID_1: 758,
            NAME_1: "Hamburg",
            VARNAME_1: "",
            HASC_1: "DE.HH",
            TYPE_1: "Land",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [[29, -19, -10, 30, 31, 32]],
          type: "Polygon",
          properties: {
            ISO: "DEU",
            NAME_0: "Germany",
            ID_1: 759,
            NAME_1: "Hessen",
            VARNAME_1: "Hesse",
            HASC_1: "DE.HE",
            TYPE_1: "Land",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [[[-26, 33, 34, 35]], [[36]], [[37]]],
          type: "MultiPolygon",
          properties: {
            ISO: "DEU",
            NAME_0: "Germany",
            ID_1: 760,
            NAME_1: "Mecklenburg-Vorpommern",
            VARNAME_1: "Mecklenburg-West Pomerania",
            HASC_1: "DE.MV",
            TYPE_1: "Land",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [
            [[38]],
            [[39]],
            [[40]],
            [[41]],
            [[42]],
            [[43, -28, 44, -34, -25, 45, 46, -33, 47, 48], [-27]],
          ],
          type: "MultiPolygon",
          properties: {
            ISO: "DEU",
            NAME_0: "Germany",
            ID_1: 761,
            NAME_1: "Niedersachsen",
            VARNAME_1: "Lower Saxony",
            HASC_1: "DE.NI",
            TYPE_1: "Land",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [[-32, 49, 50, -48]],
          type: "Polygon",
          properties: {
            ISO: "DEU",
            NAME_0: "Germany",
            ID_1: 762,
            NAME_1: "Nordrhein-Westfalen",
            VARNAME_1: "North Rhine-Westphalia",
            HASC_1: "DE.NW",
            TYPE_1: "Land",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [[-31, -9, 51, 52, 53, -50]],
          type: "Polygon",
          properties: {
            ISO: "DEU",
            NAME_0: "Germany",
            ID_1: 763,
            NAME_1: "Rheinland-Pfalz",
            VARNAME_1: "Rhineland-Palatinate",
            HASC_1: "DE.RP",
            TYPE_1: "Land",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [[54, -53]],
          type: "Polygon",
          properties: {
            ISO: "DEU",
            NAME_0: "Germany",
            ID_1: 764,
            NAME_1: "Saarland",
            VARNAME_1: "",
            HASC_1: "DE.SL",
            TYPE_1: "Land",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [[-23, 55, -12, 56, 57]],
          type: "Polygon",
          properties: {
            ISO: "DEU",
            NAME_0: "Germany",
            ID_1: 765,
            NAME_1: "Sachsen",
            VARNAME_1: "Saxony",
            HASC_1: "DE.SN",
            TYPE_1: "Land",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [[-58, 58, -46, -24]],
          type: "Polygon",
          properties: {
            ISO: "DEU",
            NAME_0: "Germany",
            ID_1: 766,
            NAME_1: "Sachsen-Anhalt",
            VARNAME_1: "Saxony-Anhalt",
            HASC_1: "DE.ST",
            TYPE_1: "Land",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [
            [[59]],
            [[60]],
            [[61]],
            [[62]],
            [[63]],
            [[64]],
            [[65]],
            [[-35, -45, -29, -44, 66]],
          ],
          type: "MultiPolygon",
          properties: {
            ISO: "DEU",
            NAME_0: "Germany",
            ID_1: 767,
            NAME_1: "Schleswig-Holstein",
            VARNAME_1: "",
            HASC_1: "DE.SH",
            TYPE_1: "Land",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [[-57, -20, -30, -47, -59]],
          type: "Polygon",
          properties: {
            ISO: "DEU",
            NAME_0: "Germany",
            ID_1: 768,
            NAME_1: "Thüringen",
            VARNAME_1: "Thuringia",
            HASC_1: "DE.TH",
            TYPE_1: "Land",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [[67, 68, 69, 70, 71, 72, -5]],
          type: "Polygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 531,
            NAME_1: "Aargau",
            VARNAME_1: "Argovia|Arg¢via|Argovie",
            HASC_1: "CH.AG",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[73, 74, 75, 76, 77, 78]],
          type: "Polygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 532,
            NAME_1: "Appenzell Ausserrhoden",
            VARNAME_1:
              "Appenzell Ausser-Rhoden|Appenzell Outer Rhodes|Appenzell dadens|Appenzell Rhodes Extérieures|Appenzello Esterno",
            HASC_1: "CH.AR",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[[79, -78]], [[80, -76]], [[81, -74]]],
          type: "MultiPolygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 533,
            NAME_1: "Appenzell Innerrhoden",
            VARNAME_1:
              "Appenzell Inner-Rhoden|Appenzell Inner Rhodes|Appenzell dador|Appenzell Rhodes Intérieures|Appenzello Interno",
            HASC_1: "CH.AI",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[-6, -73, 82, 83, 84, 85, 86, 87, 88, 89]],
          type: "Polygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 534,
            NAME_1: "Basel-Landschaft",
            VARNAME_1:
              "Bâle-Campagne|Basel-Country|Baselland|Basel-Land|Basilea campagna|Basilea Campaña|Basilea-Champagna",
            HASC_1: "CH.BL",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[[-86, 90]], [[91, -88]], [[-90, 92, -7]]],
          type: "MultiPolygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 535,
            NAME_1: "Basel-Stadt",
            VARNAME_1:
              "Bâle-Ville|Basel-City|Basel-Town|Basilea-Citad|Basilea Ciudad|Basilea città|Basiléia cidade",
            HASC_1: "CH.BS",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [
            [93, -71, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105],
          ],
          type: "Polygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 536,
            NAME_1: "Bern",
            VARNAME_1: "Berna|Berne",
            HASC_1: "CH.BE",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[[106]], [[107]], [[108, 109]], [[110, 111, 112, -102]]],
          type: "MultiPolygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 537,
            NAME_1: "Fribourg",
            VARNAME_1: "Freiburg|Friburg|Friburgo",
            HASC_1: "CH.FR",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[113, 114, 115]],
          type: "Polygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 538,
            NAME_1: "Genève",
            VARNAME_1:
              "Cenevre|Genebra|Geneve|Geneva|Genevra|Genf|Ginebra|Ginevra",
            HASC_1: "CH.GE",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[116, 117, 118, 119]],
          type: "Polygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 539,
            NAME_1: "Glarus",
            VARNAME_1: "Glaris|Glarona|Glaruna",
            HASC_1: "CH.GL",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[120, 121, -118, 122, 123, 124, 125, 126]],
          type: "Polygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 540,
            NAME_1: "Graubünden",
            VARNAME_1: "Graubünden|Grigioni|Grischun|Grisons",
            HASC_1: "CH.GR",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[-84, 127, -106, 128]],
          type: "Polygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 541,
            NAME_1: "Jura",
            VARNAME_1: "Giura",
            HASC_1: "CH.JU",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[129, 130, -116, 131]],
          type: "Polygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 542,
            NAME_1: "Lac Léman",
            VARNAME_1: "Lake of Geneva",
            HASC_1: "",
            TYPE_1: "Water body",
            ENGTYPE_1: "Water body",
          },
        },
        {
          arcs: [[132, 133, 134, 135, -95, -70]],
          type: "Polygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 543,
            NAME_1: "Lucerne",
            VARNAME_1: "Lucerna|Luzern",
            HASC_1: "CH.LU",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[136, -112, 137, -109, 138, 139, -104]],
          type: "Polygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 544,
            NAME_1: "Neuchâtel",
            VARNAME_1: "Neuenburg",
            HASC_1: "CH.NE",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[140, 141, 142, -97, 143, -135]],
          type: "Polygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 545,
            NAME_1: "Nidwalden",
            VARNAME_1: "Nidvaldo|Nidwald|Unterwalden-le-Bas|Nidwaldo",
            HASC_1: "CH.NW",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[[144, -98, -143]], [[-144, -96, -136]]],
          type: "MultiPolygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 546,
            NAME_1: "Obwalden",
            VARNAME_1: "Obvaldo|Obwald|Unterwalden-le-Haut|Obwaldo|Sursilvania",
            HASC_1: "CH.OW",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [
            [-123, -117, 145, 146, 147, -1, -18, 148, 149],
            [-80, -77, -81, -75, -82, -79],
          ],
          type: "Polygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 547,
            NAME_1: "Sankt Gallen",
            VARNAME_1: "Saint-Gall|San Gallo|Son Gagl",
            HASC_1: "CH.SG",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[150, 151, -3]],
          type: "Polygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 548,
            NAME_1: "Schaffhausen",
            VARNAME_1: "Schaffhouse|Schaffusa|Sciaffusa",
            HASC_1: "CH.SH",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[-120, 152, -141, -134, 153, 154, -146]],
          type: "Polygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 549,
            NAME_1: "Schwyz",
            VARNAME_1: "",
            HASC_1: "CH.SZ",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[-72, -94, -128, -83]],
          type: "Polygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 550,
            NAME_1: "Solothurn",
            VARNAME_1: "Soletta|Soleure|Soleuro|Soloturn",
            HASC_1: "CH.SO",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[-148, 155, -151, -2]],
          type: "Polygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 551,
            NAME_1: "Thurgau",
            VARNAME_1: "Thurgovie|Turgovia|Turg¢via",
            HASC_1: "CH.TG",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[156, 157, 158, -121]],
          type: "Polygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 552,
            NAME_1: "Ticino",
            VARNAME_1: "Tesino|Tessin",
            HASC_1: "CH.TI",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[-119, -122, -159, 159, -99, -145, -142, -153]],
          type: "Polygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 553,
            NAME_1: "Uri",
            VARNAME_1: "",
            HASC_1: "CH.UR",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[-158, 160, -130, 161, -100, -160]],
          type: "Polygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 554,
            NAME_1: "Valais",
            VARNAME_1: "Vallais|Vallese|Wallis",
            HASC_1: "CH.VS",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [
            [
              [-110, -138, -111, -101, -162, -132, -115, 162, -139],
              [-108],
              [-107],
            ],
            [[-137, -103, -113]],
          ],
          type: "MultiPolygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 555,
            NAME_1: "Vaud",
            VARNAME_1: "Vad|Waadt|Waadtland",
            HASC_1: "CH.VD",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[-154, -133, -69, 163]],
          type: "Polygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 556,
            NAME_1: "Zug",
            VARNAME_1: "Zoug|Zugo",
            HASC_1: "CH.ZG",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[-156, -147, -155, -164, -68, -4, -152]],
          type: "Polygon",
          properties: {
            ISO: "CHE",
            NAME_0: "Switzerland",
            ID_1: 557,
            NAME_1: "Zürich",
            VARNAME_1: "Turitg|Zurigo|Zürih|Zurique",
            HASC_1: "CH.ZH",
            TYPE_1: "Canton|Kanton|Chantun",
            ENGTYPE_1: "Canton",
          },
        },
        {
          arcs: [[164, 165, 166]],
          type: "Polygon",
          properties: {
            ISO: "AUT",
            NAME_0: "Austria",
            ID_1: 162,
            NAME_1: "Burgenland",
            VARNAME_1: "Burgenlândia",
            HASC_1: "AT.BU",
            TYPE_1: "Bundesländ|Länd",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [[167, 168, 169, 170]],
          type: "Polygon",
          properties: {
            ISO: "AUT",
            NAME_0: "Austria",
            ID_1: 163,
            NAME_1: "Kärnten",
            VARNAME_1: "Carinthia|Caríntia|Carintia",
            HASC_1: "AT.KA",
            TYPE_1: "Bundesländ|Länd",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [[-166, 171, 172, 173], [174]],
          type: "Polygon",
          properties: {
            ISO: "AUT",
            NAME_0: "Austria",
            ID_1: 164,
            NAME_1: "Niederösterreich",
            VARNAME_1:
              "Lower Austria|Baixa-Áustria|Baja Austria|Niederdonau|Österreich unter der Enns",
            HASC_1: "AT.NO",
            TYPE_1: "Bundesländ|Länd",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [[-173, 175, 176, -14, 177]],
          type: "Polygon",
          properties: {
            ISO: "AUT",
            NAME_0: "Austria",
            ID_1: 165,
            NAME_1: "Oberösterreich",
            VARNAME_1:
              "Upper Austria|Alta-Áustria|Alta Austria|Österreich ober der Enns|Oberösterreich",
            HASC_1: "AT.OO",
            TYPE_1: "Bundesländ|Länd",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [[178, -171, 179, 180, 181, -15, -177]],
          type: "Polygon",
          properties: {
            ISO: "AUT",
            NAME_0: "Austria",
            ID_1: 166,
            NAME_1: "Salzburg",
            VARNAME_1: "Salzburgo",
            HASC_1: "AT.SZ",
            TYPE_1: "Bundesländ|Länd",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [[-165, 182, -168, -179, -176, -172]],
          type: "Polygon",
          properties: {
            ISO: "AUT",
            NAME_0: "Austria",
            ID_1: 167,
            NAME_1: "Steiermark",
            VARNAME_1: "Styria|Est¡ria|Estiria",
            HASC_1: "AT.ST",
            TYPE_1: "Bundesländ|Länd",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [[[-170, 183, -180]], [[-182, 184, -126, 185, -16]]],
          type: "MultiPolygon",
          properties: {
            ISO: "AUT",
            NAME_0: "Austria",
            ID_1: 168,
            NAME_1: "Tirol",
            VARNAME_1: "Tyrol",
            HASC_1: "AT.TR",
            TYPE_1: "Bundesländ|Länd",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [[-186, -125, 186, -149, -17]],
          type: "Polygon",
          properties: {
            ISO: "AUT",
            NAME_0: "Austria",
            ID_1: 169,
            NAME_1: "Vorarlberg",
            VARNAME_1: "",
            HASC_1: "AT.VO",
            TYPE_1: "Bundesländ|Länd",
            ENGTYPE_1: "State",
          },
        },
        {
          arcs: [[-175]],
          type: "Polygon",
          properties: {
            ISO: "AUT",
            NAME_0: "Austria",
            ID_1: 170,
            NAME_1: "Wien",
            VARNAME_1: "Vienna|Viena",
            HASC_1: "AT.WI",
            TYPE_1: "Bundesländ|Länd",
            ENGTYPE_1: "State",
          },
        },
      ],
    },
  },
};

export default geoUrl;
