import React, { useState } from "react";
import {
  Validation,
  ValidationI,
  ValidationTypes,
} from "../../globalComponents/formFields/legacyFields/Validation/validation";
import Wrapper from "../../homepage/components/Wrapper";
import { Button, Link, TextField, Typography } from "@mui/material";

interface AlreadyCustomerProps {
  localState: (event: React.SetStateAction<number>) => void;
}

const AlreadyCustomer = (props: AlreadyCustomerProps): React.ReactElement => {
  const validation: ValidationI = { error: false, message: null };
  const [formFields, setFormFields] = useState({
    email: { value: "", validation: validation },
  });

  const sendForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationEmail = Validation(
      ValidationTypes.EMAIL,
      formFields.email.value
    );
    if (!validationEmail.error) {
      console.log("sendMail");
    } else {
      setFormFields({
        email: { ...formFields.email, validation: validationEmail },
      });
    }
  };

  return (
    <>
      <Typography variant={"h4"} align={"center"}>
        Alles klar! &#128076;
      </Typography>
      <br />
      <Typography variant={"subtitle2"} align={"center"}>
        Bitte geben Sie die E-Mail Adresse eines HR Managers oder Admins Ihrer
        Firma an.
      </Typography>
      <br />
      <Wrapper onSubmit={(e) => sendForm(e)}>
        <TextField
          variant={"standard"}
          sx={{ width: "100%" }}
          label={"Firmenname"}
          value={formFields.email.value}
          error={formFields.email.validation.error}
          helperText={formFields.email.validation.message}
          onChange={(e) => {
            setFormFields({
              ...formFields,
              email: { value: e.target.value, validation: validation },
            });
          }}
        />
        <br />
        <Button variant={"contained"} color={"primary"} type={"submit"}>
          Kontaktanfrage senden
        </Button>
      </Wrapper>
      <br />
      <Link onClick={() => props.localState(1)} align={"center"}>
        Zurück
      </Link>
    </>
  );
};

export default AlreadyCustomer;
