import React from "react";
import { Typography } from "@material-ui/core";
import Puppet from "./Puppet";

const ConstructionWork = (): React.ReactElement => {
  return (
    <div>
      <br />
      <Puppet />
      <Typography variant={"h6"} align={"center"}>
        We have to do some maintenance work on some pages. Currently affected
        pages: /Reporting /Settings
      </Typography>
      <br />
    </div>
  );
};

export default ConstructionWork;
