import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface SelectButtonProps {
  label: string;
  selected: boolean;
  onClick: () => void;
}

const SelectButtonProduct = (props: SelectButtonProps): React.ReactElement => {
  const useStyles = makeStyles({
    selected: {
      backgroundColor: "#043D66",
      color: "white",
      flex: 0.5,
      borderRadius: 0,
      "&:hover": {
        backgroundColor: "#043D66",
      },
    },
    unselected: {
      backgroundColor: "#C3C3C3",
      color: "#043D66",
      borderRadius: 0,
      flex: 0.5,
      "&:hover": {
        backgroundColor: "lightgrey",
      },
    },
  });
  const classes = useStyles();

  return (
    <Button
      className={props.selected ? classes.selected : classes.unselected}
      onClick={props.onClick}
    >
      {props.label}
    </Button>
  );
};

export default SelectButtonProduct;
