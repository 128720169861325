import styled from "styled-components";

export const GridContainer = styled.div<{ gridSize: number }>`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(${(props) => props.gridSize}, 1fr);
  border-top: 1px solid #e3e6e8;
`;

export const GridItem = styled.div<{
  size: number;
  height: string;
  backgroundColor: string;
}>`
  height: ${(props) => props.height};
  grid-column-start: span ${(props) => props.size};
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e3e6e8;
`;

export const GridItemDay = styled.div<{
  size: number;
  height: string;
  backgroundColor: string;
}>`
  height: ${(props) => props.height};
  grid-column-start: span ${(props) => props.size};
  background-color: ${(props) => props.backgroundColor};
  align-items: center;
  border-right: 1px solid #e3e6e8;
  border-bottom: 1px solid #e3e6e8;
  padding: 5px;
  justify-content: center;
`;

export const GridItemCalendarDay = styled.div<{
  variant: "SUN_HOLIDAY" | "REGULAR";
  size: number;
  height: string;
  background: string;
}>`
  height: ${(props) => props.height};
  grid-column-start: span ${(props) => props.size};
  background: ${(props) => props.background};
  align-items: center;
  border-right: 1px solid #e3e6e8;
  border-bottom: 1px solid #e3e6e8;
  padding: 5px;
  justify-content: center;
`;

export const GridItemButton = styled.div<{ size: number }>`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-start: span ${(props) => props.size};
  border-top: 1px solid #e3e6e8;
  border-bottom: 1px solid #e3e6e8;
  border-right: 1px solid #e3e6e8;
  padding: 5px;
  &:hover {
    background-color: #e3e6e8;
    cursor: pointer;
  }
`;

export const LegendContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0px 0px 0px;
`;

export const LegendPart = styled.div`
  flex: 0.33;
`;

export const LegendPartC = styled.div`
  display: flex;
  flex-direction: column;
`;
