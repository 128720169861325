import React from "react";
import { StyledLabel } from "../../../../../../globalComponents/formFields/TextFieldController";
import LegendChip from "../../../../../../globalComponents/chips/LegendChip";
import { LegendContainer, LegendPart, LegendPartC } from "../styles/styles";
import { Fade } from "@mui/material";

const TimetrackingLegend_Now = (): JSX.Element => {
  return (
    <Fade in timeout={800}>
      <LegendContainer>
        <LegendPart>
          <StyledLabel>Request Colors</StyledLabel>
          <LegendPartC>
            <LegendChip color={"#004c80"} label={"Working Time"} />
            <LegendChip color={"#EB8D25"} label={"Break Time"} />
            <LegendChip color={"red"} label={"Overtime"} />
          </LegendPartC>
        </LegendPart>
      </LegendContainer>
    </Fade>
  );
};

export default TimetrackingLegend_Now;
