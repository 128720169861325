import React from "react";
import AvatarGroup from "@material-ui/lab/AvatarGroup/AvatarGroup";
import EmployeeAvatar, { EmployeeAvatarInput } from "./EmployeeAvatar";
import { AvatarContainer } from "./styledComponents";
import { Typography } from "@material-ui/core";
import { baseUrl } from "../../config/globalVariables";

interface EmployeeAvatarsProps {
  noElementsFoundMessage?: string;
  employeeList: EmployeeAvatarInput[];
}

const EmployeeAvatars: React.FC<EmployeeAvatarsProps> = (
  props: EmployeeAvatarsProps
) => {
  let firstSupervisor = (
    <>
      &nbsp;
      <Typography variant={"body2"}>
        {props.noElementsFoundMessage
          ? props.noElementsFoundMessage
          : "Keine gefunden."}
      </Typography>
    </>
  );
  const employeeList = props.employeeList;

  if (employeeList?.length > 0) {
    firstSupervisor = (
      <EmployeeAvatar
        firstName={employeeList[0].firstName}
        id={employeeList[0].id}
        lastName={employeeList[0].lastName}
        imgLink={
          employeeList[0].imgLink
            ? baseUrl + "/employees/pic/" + employeeList[0].imgLink
            : null
        }
        position={employeeList[0].position}
      />
    );
  }
  if (employeeList?.length == 1 || employeeList?.length == 0) {
    return <>{firstSupervisor}</>;
  } else {
    return (
      <AvatarContainer>
        {firstSupervisor}
        <AvatarGroup max={2}>
          {employeeList?.slice(1, employeeList?.length).map((item) => {
            return (
              <AvatarGroup max={2} key={item.id}>
                <EmployeeAvatar
                  firstName={item.firstName}
                  lastName={item.lastName}
                  id={item.id}
                  collapsed
                />
              </AvatarGroup>
            );
          })}
        </AvatarGroup>
      </AvatarContainer>
    );
  }
};
export default EmployeeAvatars;
