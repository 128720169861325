import React from "react";
import styled from 'styled-components';
import { Typography } from "@mui/material";

import LinkStyled from "../container/LinkStyled";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const SubPageContainer = styled.div`
padding-top: 40px;
padding-bottom: 10px;
`;

const LinkContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
padding-top: 10px;
`;

const SubPage = (props) => {

    return  <>
        {props.linkUrl1!== undefined ?

        <LinkContainer>
            <LinkStyled to={props.linkUrl1}>
                <Typography variant={"subtitle2"}>{props.linkTitle1}</Typography>
            </LinkStyled>
                &nbsp;
                &nbsp;
            <FontAwesomeIcon icon={faChevronRight} size={"xs"}/>
                &nbsp;
                &nbsp;
            {props.linkUrl2!== undefined ?
                <>
                <LinkStyled to={props.linkUrl2}>
                    <Typography variant={"subtitle2"}>{props.linkTitle2}</Typography>
                </LinkStyled>
                &nbsp;
                &nbsp;
                <FontAwesomeIcon icon={faChevronRight} size={"xs"}/>
                &nbsp;
                &nbsp;
                </>
                : <></>}
            <Typography variant={"body2"}>{props.subtitle}</Typography>

        </LinkContainer>
            :
            <SubPageContainer>
                <Typography variant="h1">{props.title}</Typography>
            </SubPageContainer>
            }
        {props.children}
    </>;
};

export default SubPage;
