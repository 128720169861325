import React from "react";
import { AbsenceRequestStatusChipTable } from "./AbsenceRequestStatus";
import { DocumentStatus } from "../../../../../../state/employees/types";

interface AbsenceRStatusProps {
  documentStatus: DocumentStatus;
}

const AbsenceRequestDocumentStatus = (
  props: AbsenceRStatusProps
): JSX.Element => {
  let color = "#D7E9F7";
  let fontColor = "green";
  let content = "";

  switch (props.documentStatus) {
    case DocumentStatus.NOT_REQUIRED:
      color = "transparent";
      content = "Nicht Notwendig";
      fontColor = "#4c4c4c";
      break;
    case DocumentStatus.PROCESSING:
      color = "#eaf7f2b3";
      fontColor = "#008c5b";
      content = "Erbracht";
      break;
    case DocumentStatus.MISSING:
      fontColor = "#c80400";
      color = "#f9e8e8b3";
      content = "Fehlt";
      break;
    case DocumentStatus.MANUAL_CHECK:
      color = "#fcf4e7b3";
      fontColor = "#77541f";
      content = "Bitte überprüfen";
      break;
    case DocumentStatus.MANUALLY_APPROVED:
      color = "#eaf7f2b3";
      fontColor = "#008c5b";
      content = "Manuell erbracht";
      break;
  }
  return (
    <AbsenceRequestStatusChipTable
      backgroundColor={color}
      fontColor={fontColor}
    >
      {content}
    </AbsenceRequestStatusChipTable>
  );
};
export default AbsenceRequestDocumentStatus;
