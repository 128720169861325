import styled from "styled-components";
import Calendar from "./Calendar";
import {
  getBorderLeftStyle,
  getBorderRightStyle,
  getBorderTopStyle,
  StyleProps as BorderStyles,
} from "./Calendar/styles";
import { StyledParagraph } from "../Typogrophy";
import { colors as globalColors } from "../../../../../utils";
import MonthPicker from "./MonthPicker";

// types
export type HighlightTheme = {
  color: string;
  background: string;
};

export type Styles = BorderStyles & {
  highlightTheme?: HighlightTheme;
  paddingTop?: string;
  paddingBottom?: string;
  paddingRight?: string;
  display?: string;
  alignItems?: string;
  theme?: string;
  fontFamily?: string;
};

type Color = {
  background: string;
  color: string;
};

type Colors = Record<string, Color>;

// constants
export const colors: Colors = {
  APPROVED: {
    background: `${globalColors.successAlertBg2}`,
    color: `${globalColors.successAlertText}`,
  },
  REQUESTED: {
    background: `${globalColors.warnAlertBg2}`,
    color: `${globalColors.warnAlertText2}`,
  },
  REJECTED: {
    background: `${globalColors.dangerAlertBg2}`,
    color: `${globalColors.dangerAlertText}`,
  },
  PLANNED: {
    background: `${globalColors.infoAlertBg2}`,
    color: `${globalColors.infoAlertText}`,
  },
};

export const sizes = {
  padding: 40,
};

// helper methods
export const getHighlightTheme = (
  theme: string | undefined
): Color | undefined => {
  if (!theme) return undefined;

  return colors[theme];
};

export const getHighlightBackground = ({ highlightTheme }: Styles): string => {
  if (!highlightTheme) return "";

  return `
    background: ${highlightTheme.background};
  `;
};

export const getHighlightColor = ({ highlightTheme }: Styles): string => {
  if (!highlightTheme) return "";

  return `
    color: ${highlightTheme.color};
  `;
};

const getTheme = ({ theme }: Styles) => {
  const highlightTheme = getHighlightTheme(theme);

  return `
    ${getHighlightBackground({ highlightTheme })}
    ${getHighlightColor({ highlightTheme })}
  `;
};

const getFontFamily = ({ fontFamily }: Styles) => {
  if (!fontFamily) return "";

  return `font-family: ${fontFamily};`;
};

// styles
export const Container = styled.div<Styles>`
  width: 100%;
  ${getFontFamily}
`;

export const HeaderContainer = styled.div`
  ${getBorderRightStyle}
  ${getBorderLeftStyle}
  ${getBorderTopStyle}
  border-radius: 12px 12px 0 0;
  padding: 40px;
  display: grid;
  grid-template-columns: 1fr;
`;

export const StyledMonthPicker = styled(MonthPicker)`
  margin: 0;
  padding-bottom: 35px;
`;

export const HeaderButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Legend = styled.div`
  display: flex;
`;

export const StyledLabel = styled(StyledParagraph)`
  background: ${colors.gray16};
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: default;
  padding: 5px 15px;
  font-size: 15px;
  font-weight: 500;
  font-family: Roboto;
  margin-right: 10px;
  ${getTheme};
`;

export const StyledCell = styled.div`
  background: ${colors.gray16};
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin: 4px;
  cursor: default;
  padding: 5px 15px;
  ${getTheme};
`;

export const CalendarContainer = styled.div`
  border-radius: 12px;
`;

export const StyledCalendar = styled(Calendar)`
  border-radius: 0 0 12px 12px;
`;

export const ContentItem = styled.div`
  flex: 1;
  padding-right: ${({ paddingRight = "10px" }: Styles) => paddingRight};
  padding-bottom: ${({ paddingBottom = "0" }: Styles) => paddingBottom};
  display: ${({ display = "block" }: Styles) => display};
  align-items: ${({ alignItems = "center" }: Styles) => alignItems};
`;

export const ContentItemLabel = styled.p`
  padding-top: ${({ paddingTop = "20px" }: Styles) => paddingTop};
  padding-bottom: ${({ paddingBottom = "0" }: Styles) => paddingBottom};
`;

export const Content = styled.div`
  padding: ${sizes.padding}px;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const FormContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

export const FormHeader = styled.div`
  padding: ${sizes.padding}px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${globalColors.gray11};
`;

export const FormFooter = styled.div`
  height: 100%;
  flex: 1;
  padding: ${sizes.padding}px;
  display: flex;
  align-items: flex-end;
`;

export const Header = styled(FormHeader)`
  border: none;
  flex-direction: column;
  padding-bottom: 0;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
