import React from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";
import EmployeeAvatarNew from "../../../../../../globalComponents/avatars/EmployeeAvatarNew";
import TimetrackingDetailBasicSettings from "./TimetrackingDetailBasicSettings";
import TimetrackingDetailRounding from "./TimetrackingDetailRounding";

export const GrContainer = styled.div<{ size: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.size}, 1fr);
`;

export const GridItemTTC = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0px 5px 0px;
`;

const EmpContainer = styled.div`
  display: flex;
  padding: 40px 40px 20px 40px;
  flex: 1;
  box-shadow: 0px 2px 4px #4c4c4c24;
`;

const ParentContainer = styled.div`
  padding: 20px 40px 40px 40px;
`;

const TimetrackingDetail = (): JSX.Element => {
  return (
    <>
      <EmpContainer>
        <EmployeeAvatarNew size={"SMALL"} />
        <div>
          <Typography variant={"subtitle2"} marginLeft={"20px"}>
            John Watson
          </Typography>
          <Typography variant={"caption"} marginLeft={"20px"}>
            CEO
          </Typography>
        </div>
      </EmpContainer>
      <ParentContainer>
        <TimetrackingDetailBasicSettings />
        <TimetrackingDetailRounding />
      </ParentContainer>
    </>
  );
};

export default TimetrackingDetail;
