import React, { useEffect, useState } from "react";
import SmartTable from "../../../../../../globalComponents/smartOperations/smartTable/SmartTable";
import TemplateGenerator from "./TemplateGenerator";

// types
export type Styles = Partial<{ visible: boolean; paddingBottom: number }>;

// utils
const columns = [
  { field: "name", headerName: "Bezeichnung", flex: 4 },
  { field: "category", headerName: "Kategorie", flex: 1 },
  { field: "creationDate", headerName: "Erstellt am", flex: 1 },
];
const fakeInput = {
  errorMessage: "",
  error: false,
  loading: false,
  data: [
    {
      id: 1,
      name: "Arbeitsvertrag",
      category: "Verträge",
      creationDate: "20 / 05 / 2020 ",
    },
    {
      id: 2,
      name: "Zeugnis",
      category: "Bewertungen",
      creationDate: "20 / 05 / 2020 ",
    },
  ],
};
// components
const CompanyDocuments = (props: { id: number }): JSX.Element => {
  const [page, setPage] = useState("VIEW");
  useEffect(() => {
    //load document templates
    console.log("loading the templates");
  }, []);

  if (page == "VIEW")
    return (
      <SmartTable
        title={"Dokumentenvorlagen"}
        createButton={{
          label: "Vorlage hinzufügen",
          callbackFunc: () => setPage("CREATE"),
        }}
        columns={columns}
        input={fakeInput}
        deleteEndpoint={"/companies/" + props.id + "/delete"}
        deleteConfirmMessage={"Wollen Sie das Template wirklich löschen?"}
        updateInputCallback={() => console.log("update me")}
      />
    );
  return <TemplateGenerator />;
};

export default CompanyDocuments;
