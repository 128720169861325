import React from "react";
import {connect} from "react-redux";

const Feedback = () => {

    return <></>;
};

const mapStateToProps = state => {
    return {
        feedback: state.general,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        feedbackChange: (value)=>dispatch({type: 'FEEDBACK', payload: value}),
    }
};

export default connect(mapStateToProps, mapDispatchToProps) (Feedback);
