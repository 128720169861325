import React, { useState } from "react";
import styled from "styled-components";
import {
  StyledLabel,
  TextFieldWrapper,
} from "../../formFields/TextFieldController";
import { Button } from "@mui/material";

interface SelectBooleanUncontrolled {
  question: string;
  onClickYes: () => void;
  onClickNo: () => void;
}

const BooleanSelectContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
`;

const StyledButton = styled(Button)`
  width: 50%;
`;

const SelectBooleanUncontrolled = (
  props: SelectBooleanUncontrolled
): JSX.Element => {
  const [selectedOne, setSelectedOne] = useState("INITIAL");

  return (
    <TextFieldWrapper>
      <br />
      <StyledLabel>
        <label>{props.question}</label>
      </StyledLabel>
      <BooleanSelectContainer>
        <StyledButton
          onClick={() => {
            setSelectedOne("YES");
            props.onClickYes();
          }}
          variant={"contained"}
          color={selectedOne == "YES" ? "primary" : "secondary"}
        >
          Ja
        </StyledButton>
        <StyledButton
          onClick={() => {
            setSelectedOne("NO");
            props.onClickNo();
          }}
          variant={"contained"}
          color={selectedOne == "NO" ? "primary" : "secondary"}
        >
          Nein
        </StyledButton>
      </BooleanSelectContainer>
    </TextFieldWrapper>
  );
};

export default SelectBooleanUncontrolled;
