import React from "react";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import {
  StyledLabel,
  TextFieldWrapper,
  WarnLabel,
} from "../TextFieldController";
import styled from "styled-components";
import { faFileAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FileLabelContainer = styled.label`
  position: relative;
  width: 295px;
  height: 38px;
  background-color: white;
  border: ${(props: { error: boolean }) =>
    props.error ? "1px solid #C80410" : "1px solid #cccccc"};
  border-radius: 4px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: #4c4c4c;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  &:hover {
    cursor: pointer;
    background: #f5f8fa;
  }
`;

interface FileInputProps {
  control: Control<any>;
  rules?: { required?: boolean; minLength?: number };
  defaultValue: string | number | null | undefined;
  label: string;
  fieldName: string;
  errorMessage?: string;
  small?: boolean;
}

const FileInput: React.FC<FileInputProps> = (props: FileInputProps) => {
  return (
    <Controller
      name={props.fieldName}
      control={props.control}
      defaultValue={props.defaultValue}
      rules={props.rules}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
        let renderedValues = "";
        if (value[0] !== undefined) {
          renderedValues = value[0].name;
        }
        return (
          <TextFieldWrapper>
            <StyledLabel>
              <label id={`${props.fieldName}-Select`}>{props.label}</label>
            </StyledLabel>
            <FileLabelContainer
              htmlFor={"file-upload"}
              error={error !== undefined}
            >
              <input
                type={"file"}
                id={"file-upload"}
                name={props.fieldName}
                onChange={(event) => onChange(event.target.files)}
                ref={ref}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  display: "none",
                }}
              />
              {renderedValues}
              <FontAwesomeIcon icon={faFileAlt} />
            </FileLabelContainer>
            {error ? <WarnLabel>{props.errorMessage}</WarnLabel> : null}
          </TextFieldWrapper>
        );
      }}
    />
  );
};

export default FileInput;
