import React from "react";
import {
  FormControlLabel,
  FormHelperText,
  RadioGroup,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import Radio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/styles";
import { StyledLabel, TextFieldWrapper } from "./TextFieldController";

type SelectProps = {
  control: Control<any>;
  fieldName: `${string}`;
  label: string;
  errorText: string;
  defaultValue?: number | null | string;
  height?: string | null;
};

// @ts-ignore
const useStyles = makeStyles({
  // style rule
  formControl: {
    width: "100%",
    // @ts-ignore
    height: (props) => `${props.height || "100%"}`,
  },
  selected: {
    flex: "0.5",
    padding: "6px 16px",
    margin: "0px",
    color: "white",
    backgroundColor: "#054D80",
  },
  unselected: {
    flex: "0.5",
    padding: "6px 16px",
    margin: "0px",
    color: "#054D80",
    backgroundColor: "lightgrey",
  },
  radioGroup: {
    marginTop: "5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    // @ts-ignore
    height: (props) => `${props.height || "100%"}`,
    alignItems: "center",
    borderRadius: "4px",
    overflow: "hidden",
  },
  radio: {
    display: "none",
  },
});

const SelectBoolean = (props: SelectProps): JSX.Element => {
  return (
    <Controller
      name={props.fieldName}
      control={props.control}
      defaultValue={props.defaultValue ? props.defaultValue : "false"}
      rules={{ required: "Company is required" }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const classes = useStyles({
          selected: value,
          height: props.height,
        });
        return (
          <TextFieldWrapper>
            <StyledLabel>
              <label>{props.label}</label>
            </StyledLabel>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={value}
              onChange={onChange}
              className={classes.radioGroup}
            >
              <FormControlLabel
                value={"true"}
                control={<Radio className={classes.radio} />}
                label={"Ja"}
                labelPlacement={"bottom"}
                className={
                  value === "true" ? classes.selected : classes.unselected
                }
              />
              <FormControlLabel
                value={"false"}
                control={<Radio className={classes.radio} />}
                label="Nein"
                labelPlacement={"bottom"}
                className={
                  value === "false" ? classes.selected : classes.unselected
                }
              />
            </RadioGroup>
            <>
              {error !== undefined ? (
                <FormHelperText error>{props.errorText}</FormHelperText>
              ) : null}
            </>
          </TextFieldWrapper>
        );
      }}
    />
  );
};

export default SelectBoolean;
