import React, { useState } from "react";
import { Typography } from "@mui/material";
import IconButtonNew from "../../../../../../globalComponents/buttons/IconButtonNew";
import {
  faCheckCircle,
  faPen,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import TextFieldNumber from "./TextFieldNumber";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import {
  newScheduleAddEditBreak,
  newWorkScheduleDeleteBreak,
  updateWorkingTimesDraft,
} from "../../../../../../state/employees/employeeDetail/employeeDetail";
import {
  AddLeadingZero,
  convertTimeToGrid,
} from "./helper/workScheduleGridHelper";

const StyledForm = styled.form`
  display: flex;
  grid-column-start: span 3;
`;

export interface TimeRowProps {
  startHour: number;
  endHour: number;
  startMin: number;
  endMin: number;
  variant: "WORKINGTIME" | "BREAK";
  breakId?: number;
  editOption?: boolean;
  deleteOption?: boolean;
}

const TimeRow = (props: TimeRowProps): JSX.Element => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const { control, getValues } = useForm();

  const handleSubmit = () => {
    const d = getValues();
    const colStart = convertTimeToGrid(
      Number.parseFloat(d.startHour),
      Number.parseFloat(d.startMin)
    );
    const colEnd = convertTimeToGrid(
      Number.parseFloat(d.endHour),
      Number.parseFloat(d.endMin)
    );
    if (props.variant == "WORKINGTIME") {
      dispatch(
        updateWorkingTimesDraft({
          colStart: colStart,
          colEnd: colEnd,
        })
      );
    } else {
      dispatch(
        newScheduleAddEditBreak({
          breakId: props.breakId ? props.breakId : 1,
          colStart: colStart,
          colEnd: colEnd,
        })
      );
    }

    setEdit(false);
  };

  if (edit)
    return (
      <StyledForm>
        <TextFieldNumber
          control={control}
          fieldNameMin={"startMin"}
          fieldNameHours={"startHour"}
        />
        <TextFieldNumber
          control={control}
          fieldNameMin={"endMin"}
          fieldNameHours={"endHour"}
        />
        <div>
          <IconButtonNew icon={faCheckCircle} onClick={() => handleSubmit()} />
          <IconButtonNew icon={faTimes} onClick={() => setEdit(false)} />
        </div>
      </StyledForm>
    );

  return (
    <>
      <Typography variant={"body1"}>
        {AddLeadingZero(props.startHour)} : {AddLeadingZero(props.startMin)}
      </Typography>
      <Typography variant={"body1"}>
        {AddLeadingZero(props.endHour)} : {AddLeadingZero(props.endMin)}
      </Typography>
      <div>
        <IconButtonNew icon={faPen} onClick={() => setEdit(true)} />
        {props.variant == "BREAK" ? (
          <IconButtonNew
            icon={faTrashAlt}
            onClick={() =>
              dispatch(newWorkScheduleDeleteBreak({ breakId: props.breakId! }))
            }
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default TimeRow;
