import React, { useState } from "react";
import { RequestDummy } from "./TimetrackingHours";
import styled, { keyframes } from "styled-components";
import { v4 as uuidv4 } from "uuid";
import RequestFlap from "./RequestFlap";
import { GridItem } from "../styles/styles";

interface TTRequestProps {
  request: {
    originalRequest: RequestDummy;
    duration: number;
    ongoing?: boolean;
  };
}

const TTRequestContainer = styled.div<{ size: number }>`
  grid-column-start: span ${(props) => props.size};
  height: 50px;
  border-bottom: 1px solid #e3e6e8;
  padding-top: 20px;
`;

const GridContainer = styled.div<{ size: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.size}, 1fr);
  border: 1px solid transparent;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  &:hover {
    border: 1px solid lightgrey;
    cursor: pointer;
  }
`;

const ongoing = keyframes`
  from {
    margin-left: 0;
  }

  to {
    margin-left: 100%;
  }
`;

const GridAnimation = styled.div`
  height: 100%;
  background-color: white;
  position: absolute;
  width: 20px;
  opacity: 0.3;
  animation: ${ongoing} 4s linear infinite;
`;

const TTRequest = (props: TTRequestProps): JSX.Element => {
  const [req, setReq] = useState(false);
  const returnJSX: JSX.Element[] = [];
  const requestMinutes = Array(props.request.duration).fill("WORKING");
  const startDate = new Date(props.request.originalRequest.startDate);

  const makeRequest = () => {
    props.request.originalRequest.breaks.forEach((item) => {
      //calculate duration of break
      const breakStart = new Date(item.start);
      const breakEnd = new Date(item.end);

      const durationBetweenWorkStartAndBreak = Math.round(
        (breakStart.getTime() - startDate.getTime()) / 60000
      );
      const durationOfBreak = Math.round(
        (breakEnd.getTime() - breakStart.getTime()) / 60000
      );

      for (
        let i = durationBetweenWorkStartAndBreak;
        i < durationOfBreak + durationBetweenWorkStartAndBreak;
        i++
      ) {
        requestMinutes[i] = "BREAK";
      }
    });
    let workingSize = 0;
    let breakSize = 0;
    for (let i = 0; i < requestMinutes.length; i++) {
      if (requestMinutes[i] == "WORKING") {
        if (breakSize !== 0) {
          returnJSX.push(
            <GridItem
              backgroundColor={"#EAF5F0"}
              height={"30px"}
              size={breakSize}
              key={uuidv4()}
              style={{ border: "none" }}
            />
          );
          breakSize = 0;
          workingSize = workingSize + 1;
        } else {
          workingSize = workingSize + 1;
        }
      } else if (requestMinutes[i] == "BREAK") {
        if (workingSize !== 0) {
          returnJSX.push(
            <GridItem
              height={"30px"}
              size={workingSize}
              key={uuidv4()}
              backgroundColor={"#004c80"}
              style={{ border: "none" }}
            />
          );
          workingSize = 0;
          breakSize = breakSize + 1;
        } else {
          breakSize = breakSize + 1;
        }
      }
    }
    if (workingSize !== 0) {
      returnJSX.push(
        <GridItem
          height={"30px"}
          size={workingSize}
          key={uuidv4()}
          backgroundColor={"#004c80"}
          style={{ border: "none" }}
        />
      );
    }
    if (breakSize !== 0) {
      returnJSX.push(
        <GridItem
          height={"30px"}
          size={breakSize}
          key={uuidv4()}
          backgroundColor={"#004c80"}
          style={{ border: "none" }}
        />
      );
    }

    return returnJSX;
  };

  return (
    <TTRequestContainer size={props.request.duration}>
      <GridContainer size={props.request.duration} onClick={() => setReq(true)}>
        {makeRequest()}
        {props.request.ongoing ? <GridAnimation /> : <></>}
      </GridContainer>
      {req ? (
        <RequestFlap
          request={props.request.originalRequest}
          clickAway={() => setReq(false)}
        />
      ) : (
        <></>
      )}
    </TTRequestContainer>
  );
};

export default TTRequest;
