import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import SalarytypesGrid from "./SalarytypesGrid";
import EmployeeHeader from "./EmployeeHeader";
import DeductionsTax from "./DeductionsTax";
import DeductionsSV from "./DeductionsSV";
import Nettobezuege from "./Nettobezuege";
import Verdienstbescheinigung from "./Verdienstbescheinigung";

const Payslip = styled.div`
  background-color: white;
  width: 51.1vw;
  height: 72vw;
  min-width: 804px;
  min-height: 1133px;
  box-shadow: 0px 0px 3px 1px #e6e6e6;
  margin: auto;
  border-radius: 8px;
  padding: 40px;
`;

const NetSalaryContainer = styled.div`
  padding: 10px 0px 20px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const DeductionsContainer = styled(NetSalaryContainer)`
  justify-content: space-between;
`;

const Paycheck = (): JSX.Element => {
  return (
    <Payslip>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant={"h3"} marginBottom={5}>
          Abrechnung der Brutto/ Nettobezüge für Januar 2020
        </Typography>
        <img
          src={
            "https://www.netzsieger.de/cdn/raw/mdx/product/686/5ec231empe351197.zc300x150.png"
          }
          alt={"HeavenLogo"}
          style={{ width: "18%", marginBottom: "40px" }}
        />
      </div>
      <EmployeeHeader />
      <SalarytypesGrid />
      <DeductionsContainer>
        <DeductionsTax />
        <DeductionsSV />
      </DeductionsContainer>
      <NetSalaryContainer>
        <Typography variant={"body1"} marginRight={1}>
          Abzüge:{" "}
        </Typography>
        <Typography variant={"subtitle2"}>1,903.20 </Typography>
      </NetSalaryContainer>
      <NetSalaryContainer>
        <Typography variant={"body1"} marginRight={1}>
          Netto-Verdienst:{" "}
        </Typography>
        <Typography variant={"subtitle2"}>3.763.47 </Typography>
      </NetSalaryContainer>
      <DeductionsContainer>
        <Verdienstbescheinigung />
        <Nettobezuege />
      </DeductionsContainer>
    </Payslip>
  );
};
export default Paycheck;
