import { defaultTo, round } from "lodash/fp";

// types
export type Styles = Partial<{
  fontFamily: string;
  basesize: number;
}>;

// utils
export const getFontFamily = ({ fontFamily }: Styles): string => {
  if (!fontFamily) return "";

  return `font-family: ${fontFamily};`;
};

export const calculateSize = <S extends Styles>(ratio: number) => ({
  basesize,
}: S): number => round(defaultTo(0)(basesize) * ratio);
