import React, { useState } from "react";
import styled from "styled-components";
import "./OrgChart.css";
import IconButton from "@material-ui/core/IconButton";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import CompanyOrgChartElement from "../../product/pages/organisation/components/CompanyOrgChartElement";
import { EmployeeAvatarInput } from "../avatars/EmployeeAvatar";
import { LabelValuePair } from "../formFields/staticSelect/SelectMenuInput";
import { CircularProgress, Fade } from "@material-ui/core";
import { OrgChartProps } from "./OrganizationalChart";
import ConnectLine from "./ConnectLine";
import DepartmentOrgChartElement from "../../product/pages/organisation/components/DepartmentOrgChartElement";

export const ChildLine = styled.div`
  height: 20px;
  width: 0px;
  border-right: 1px solid lightgrey;
`;

const Container = styled.li`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  list-style-type: none;
`;

export interface OrgChartElementInterface {
  id: number;
  children: OrgChartElementInterface[];
  company?: LabelValuePair;
  managingDirectors?: EmployeeAvatarInput[];
  name?: string;
  departmentName?: string;
  supervisorList?: EmployeeAvatarInput[];
  activeEmployees: number;
}

export enum ElementType {
  "COMPANY" = "COMPANY",
  "DEPARTMENT" = "DEPARTMENT",
}

interface OrgChartGeneratorProps extends OrgChartProps {
  zoomLevel: number;
}

const OrgChartGenerator = (props: OrgChartGeneratorProps): JSX.Element => {
  const [state, setState] = useState([0]);

  if (props.loading) {
    return <CircularProgress />;
  }

  if (
    props.mother?.[0] === undefined ||
    props.mother?.[0].children === undefined ||
    props.error
  ) {
    return <>No data to display</>;
  }

  const returnElement = (
    elementType: ElementType,
    Element: OrgChartElementInterface
  ) => {
    switch (elementType) {
      case ElementType.COMPANY:
        return (
          <CompanyOrgChartElement
            zoomLevel={props.zoomLevel}
            id={Element.id}
            activeEmployees={Element.activeEmployees}
            companyName={
              Element.company?.label !== undefined ? Element.company.label : ""
            }
            deleteAndClose={
              props.reloadCallback
                ? props.reloadCallback
                : () => {
                    console.log("deleted");
                  }
            }
            managingDirectors={
              Element.managingDirectors !== undefined
                ? Element.managingDirectors
                : []
            }
          />
        );
      case ElementType.DEPARTMENT:
        return (
          <DepartmentOrgChartElement
            zoomLevel={props.zoomLevel}
            id={Element.id}
            deleteAndClose={
              props.reloadCallback
                ? props.reloadCallback
                : () => {
                    console.log("deleted");
                  }
            }
            supervisorList={
              Element.supervisorList !== undefined ? Element.supervisorList : []
            }
            departmentName={
              Element.departmentName !== undefined ? Element.departmentName : ""
            }
            activeEmployees={Element.activeEmployees}
          />
        );
    }
  };

  //Org Chart for every parent element
  const OrgCharts: JSX.Element[] = [];
  for (let i = 0; i < props.mother.length; i++) {
    OrgCharts.push(
      <ol className={"org-tree"} key={props.mother[i].id}>
        <Fade in timeout={800}>
          <Container>
            <ConnectLine
              elements={props.mother}
              currentElement={props.mother[i]}
            />
            {returnElement(props.elementType, props.mother[i])}
            {props.mother[i].children.length > 0 ? (
              <>
                <ChildLine />
                {state.includes(props.mother[i].id) ? (
                  <>
                    <IconButton
                      onClick={() =>
                        setState([
                          ...state.filter((obj) => {
                            return obj !== props.mother[i].id;
                          }),
                        ])
                      }
                    >
                      <ArrowDropUp />
                    </IconButton>
                    <OrgChartGenerator
                      mother={props.mother[i].children}
                      elementType={props.elementType}
                      error={props.error}
                      loading={props.loading}
                      reloadCallback={() => props.reloadCallback()}
                      zoomLevel={props.zoomLevel}
                    />
                  </>
                ) : (
                  <IconButton
                    onClick={() => setState([...state, props.mother[i].id])}
                    color={"primary"}
                  >
                    <ArrowDropDown />
                  </IconButton>
                )}
              </>
            ) : (
              <></>
            )}
          </Container>
        </Fade>
      </ol>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {OrgCharts}
    </div>
  );
};

export default OrgChartGenerator;
