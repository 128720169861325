import styled from "styled-components";

const Wrapper = styled.form`
  flex: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default Wrapper;
