// types
export enum Animate {
  In = "in",
  Out = "out",
  Empty = "",
}

type Color = {
  colorName: string;
  bgColor: string;
  borderColor: string | undefined;
};

type BaseStyles = {
  size: number;
  animate: Animate;
};

export type Styles = Partial<Color & BaseStyles>;

// utils

// color utils
export const colorMap: Record<string, string> = {
  nightRider: "#333333",
  mortar: "#5A5A5A",
  suvaGrey: "#8C8C8C",
  silver: "#BFBFBF",
  white: "#FFFFFF",
  purpleHeart: "#5724C2",
  darkOrchid: "#B629D4",
  torchRed: "#FC1233",
  outrageousOrange: "#FB5F2C",
  goldTips: "#E59E25",
  salem: "#18A841",
  lightSeaGreen: "#1AA9B2",
  Denim: "#1885E2",
  egyptianBlue: "#0D3A99",
};

const borderColorMap: Record<string, string> = {
  white: colorMap.silver,
};

const getColorTuples = (): string[][] =>
  Object.entries(colorMap).map(([k, v]) => [k, v, borderColorMap[k]]);

export const getColors = (): Color[] =>
  getColorTuples().map(([colorName, bgColor, borderColor]) => ({
    colorName,
    bgColor,
    borderColor: borderColor ?? bgColor,
  }));

export const getColorValues = (): string[] => Object.values(colorMap);

export const getColorNames = (): string[] => Object.keys(colorMap);

export const getDefaultColor = (): string => colorMap.nightRider;

export const getColorName = (colorValue: string): string =>
  getColorTuples()
    .find(([, value]) => value === colorValue)
    ?.shift() ?? "";

export const getColorValue = (colorName: string): string =>
  getColorTuples()
    .find(([name]) => name === colorName)
    ?.slice(-2)
    ?.shift() ?? "";

export const isValidColor = (colorName = ""): boolean =>
  getColorNames().includes(colorName);

// style utils
export const sizes = {
  widht: 220,
  colorsInRow: 7,
};

export const getHeight = ({ size = 0 }: Styles): string => {
  const rowHeight = sizes.widht / sizes.colorsInRow;
  const numOfRows = Math.ceil(size / sizes.colorsInRow);
  const height = rowHeight * numOfRows;

  return `height: ${height}px;`;
};

export const getBorderColor = ({ borderColor = "" }: Styles): string => {
  return `border: 1px solid ${borderColor};`;
};

export const getBackgroundColor = ({ bgColor = "initial" }: Styles): string => {
  return `background-color: ${bgColor};`;
};

export const getGridTemplateColumns = (): string => {
  return `grid-template-columns: repeat(${sizes.colorsInRow}, 1fr);`;
};

export const addAnimation = ({ animate }: Styles): string => {
  if (animate === Animate.Empty) return "";

  return `animation-name: zoom${animate};`;
};

export const getHtmlColor = (colorName: string): string => {
  const colorValue = colorMap[colorName];

  if (colorValue === colorMap.white) return "";

  return colorValue;
};
