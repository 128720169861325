import { ClickAwayListener, Grid, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { RequestDummy } from "./TimetrackingHours";
import { v4 as uuidv4 } from "uuid";

const RRC = styled.div`
  position: sticky;
  top: -150px;
  min-width: 200px;
  height: auto;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 0px lightgrey;
  background-color: white;
  margin-top: 5px;
  align-items: center;
  z-index: 5;
`;

const RequestContainer = styled(Grid)`
  align-items: center;
`;

interface RequestFlapProps {
  request: RequestDummy;
  clickAway: () => void;
}
const RequestFlap = (props: RequestFlapProps): JSX.Element => {
  const start = new Date(props.request.startDate);
  const end = props.request.endDate
    ? new Date(props.request.endDate)
    : new Date();
  const returnDuration = (start: Date, end: Date) => {
    const duration = end.getTime() - start.getTime();
    const hours = Math.floor(duration / 3600000);
    const minutes = Math.round(((duration / 3600000) % 1) * 60);
    return `${hours} h : ${minutes} m`;
  };

  let renderBreaks: JSX.Element[] = [];

  props.request.breaks.forEach((breakItem) => {
    const breakStart = new Date(breakItem.start);
    const breakEnd = new Date(breakItem.end);

    renderBreaks = renderBreaks.concat([
      <Grid item xs={4} key={uuidv4()}>
        <Typography variant={"body2"}>
          {breakStart.getHours()} : {breakStart.getMinutes()} Uhr
        </Typography>
      </Grid>,
      <Grid item xs={4} key={uuidv4()}>
        <Typography variant={"body2"}>
          {breakEnd.getHours()} : {breakEnd.getMinutes()} Uhr
        </Typography>
      </Grid>,
      <Grid item xs={4} key={uuidv4()}>
        <Typography variant={"body2"}>
          {returnDuration(breakStart, breakEnd)}
        </Typography>
      </Grid>,
    ]);
  });

  return (
    <ClickAwayListener onClickAway={() => props.clickAway()}>
      <RRC>
        <RequestContainer container spacing={1}>
          <Grid
            item
            xs={12}
            style={{
              borderBottom: "1px solid lightgrey",
              paddingBottom: "10px",
            }}
          >
            <Typography variant={"subtitle2"} align={"center"}>
              Antrag
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant={"caption"}>Start</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant={"caption"} align={"center"}>
              Ende{" "}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant={"caption"} align={"center"}>
              Dauer{" "}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant={"body2"}>
              {start.getHours()} : {start.getMinutes()} Uhr
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant={"body2"}>
              {end.getHours()} : {end.getMinutes()} Uhr
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant={"body2"}>
              {returnDuration(start, end)}
            </Typography>
          </Grid>
          {props.request.breaks.length > 0 ? (
            <>
              <Grid
                item
                xs={12}
                style={{ borderTop: "1px solid lightgrey", marginTop: "10px" }}
              >
                <Typography variant={"subtitle2"} align={"center"}>
                  Pausen{" "}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant={"caption"}>Start</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant={"caption"} align={"center"}>
                  Ende{" "}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant={"caption"} align={"center"}>
                  Dauer{" "}
                </Typography>
              </Grid>
              {renderBreaks}
            </>
          ) : (
            <></>
          )}
        </RequestContainer>
      </RRC>
    </ClickAwayListener>
  );
};

export default RequestFlap;
