import { get } from "lodash";

const ERROR_MAP: Record<string, string> = {
  unknown: "An unknown error has occurred",
  "error.absence.negative.balance":
    "Not enough budget available for the requested length",
  "error.location.coordinates.not-found": "The postal code is invalid",
  "error.absence.workContract.vacationDays.zero":
    "The employee's work contract has specified 0 vacation days.",
  "error.absence.create.overlappingRequest":
    "An existing absence request already exists on this date.",
};

export const getFirstError = (e: unknown): string | null => {
  const errors = get(e, "response.data.errors");
  if (errors && errors.length) {
    return errors[0].message;
  }

  return null;
};

const getErrorMessage = (errorCode: string): string => {
  if (!errorCode) {
    return ERROR_MAP["unknown"];
  }

  if (ERROR_MAP[errorCode]) {
    return ERROR_MAP[errorCode];
  }

  return ERROR_MAP["unknown"];
};

export default getErrorMessage;
