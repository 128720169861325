import { createSlice } from "@reduxjs/toolkit";
import {
  CompanyDetailBasicState,
  CompanyDetailBasicStateI,
  getBasicData,
} from "./companyDetailBasic";
import {
  CompanyDetailTaxStateI,
  CompanyDetailTaxState,
  getTaxData,
} from "./companyDetailTax";
import {
  CompanyDetailSocialState,
  CompanyDetailSocialStateI,
  getSocialData,
} from "./companyDetailSocial";
import {
  CompanyDetailSocial2State,
  CompanyDetailSocial2StateI,
  getSocialData2,
} from "./companyDetailSocial2";
import {
  listSalarytypes,
  SalarytypeState,
  SalarytypeStateI,
  SalaryTypeVariant,
} from "./salarytype";
import {
  AbsencetypeState,
  AbsencetypeStateI,
  listAbsencetypesByCompanyId,
} from "./absencetypes";

export type DataState = {
  loading: boolean;
  errorMessage?: string;
  error: boolean;
};

export const DataStateInitial: DataState = {
  loading: false,
  errorMessage: "",
  error: false,
};

export const DataStateInitialArray = {
  loading: false,
  errorMessage: "",
  error: false,
  data: [],
};

export const DataStateInitialObject = {
  loading: false,
  errorMessage: "",
  error: false,
  data: {},
};

export interface CompanyDetailState {
  CompanyBasic: CompanyDetailBasicState;
  CompanyTax: CompanyDetailTaxState;
  CompanySocial: CompanyDetailSocialState;
  CompanySocial2: CompanyDetailSocial2State;
  Salarytypes: SalarytypeState;
  Absencetypes: AbsencetypeState;
}

const CompanyDetailStateI: CompanyDetailState = {
  CompanyBasic: CompanyDetailBasicStateI,
  CompanyTax: CompanyDetailTaxStateI,
  CompanySocial: CompanyDetailSocialStateI,
  CompanySocial2: CompanyDetailSocial2StateI,
  Salarytypes: SalarytypeStateI,
  Absencetypes: AbsencetypeStateI,
};

// reducer
const companyDetail = createSlice({
  name: "/companies/detail",
  initialState: CompanyDetailStateI,
  reducers: {},
  extraReducers: (builder) => {
    //get Basic Data
    builder.addCase(getBasicData.pending, (state) => {
      state.CompanyBasic.loading = true;
    });
    builder.addCase(getBasicData.fulfilled, (state, action) => {
      state.CompanyBasic.loading = false;
      state.CompanyBasic.data = action.payload;
      state.CompanyBasic.error = false;
    });
    builder.addCase(getBasicData.rejected, (state, action) => {
      state.CompanyBasic.loading = false;
      state.CompanyBasic.errorMessage = action.error.message as string;
      state.CompanyBasic.error = true;
    });
    //get Tax Data
    builder.addCase(getTaxData.pending, (state) => {
      state.CompanyTax.loading = true;
    });
    builder.addCase(getTaxData.fulfilled, (state, action) => {
      state.CompanyTax.loading = false;
      state.CompanyTax.data = action.payload;
      state.CompanyTax.error = false;
    });
    builder.addCase(getTaxData.rejected, (state, action) => {
      state.CompanyTax.loading = false;
      state.CompanyTax.errorMessage = action.error.message as string;
      state.CompanyTax.error = true;
    });
    //get Basic Data
    builder.addCase(getSocialData.pending, (state) => {
      state.CompanySocial.loading = true;
    });
    builder.addCase(getSocialData.fulfilled, (state, action) => {
      state.CompanySocial.loading = false;
      state.CompanySocial.data = action.payload;
      state.CompanySocial.error = false;
    });
    builder.addCase(getSocialData.rejected, (state, action) => {
      state.CompanySocial.loading = false;
      state.CompanySocial.errorMessage = action.error.message as string;
      state.CompanySocial.error = true;
    });
    //get Social Data2
    builder.addCase(getSocialData2.pending, (state) => {
      state.CompanySocial2.loading = true;
    });
    builder.addCase(getSocialData2.fulfilled, (state, action) => {
      state.CompanySocial2.loading = false;
      state.CompanySocial2.data = action.payload;
      state.CompanySocial2.error = false;
    });
    builder.addCase(getSocialData2.rejected, (state, action) => {
      state.CompanySocial2.loading = false;
      state.CompanySocial2.errorMessage = action.error.message as string;
      state.CompanySocial2.error = true;
    });

    builder.addCase(listAbsencetypesByCompanyId.pending, (state) => {
      state.Absencetypes.loading = true;
    });
    builder.addCase(listAbsencetypesByCompanyId.fulfilled, (state, action) => {
      state.Absencetypes.loading = false;
      state.Absencetypes.data = action.payload;
      state.Absencetypes.error = false;
    });
    builder.addCase(listAbsencetypesByCompanyId.rejected, (state, action) => {
      state.Absencetypes.loading = false;
      state.Absencetypes.errorMessage = action.error.message as string;
      state.Absencetypes.error = true;
    });

    //get Salarytypes
    builder.addCase(listSalarytypes.pending, (state) => {
      state.Salarytypes.loading = true;
    });
    builder.addCase(listSalarytypes.fulfilled, (state, action) => {
      state.Salarytypes.loading = false;
      state.Salarytypes.page = action.payload;
      state.Salarytypes.error = false;
      state.Salarytypes.All.selected = false;
      state.Salarytypes.Custom.selected = false;
      state.Salarytypes.Predefined.selected = false;
      if (action.meta.arg.type == SalaryTypeVariant.All)
        state.Salarytypes.All.selected = true;
      if (action.meta.arg.type == SalaryTypeVariant.Custom)
        state.Salarytypes.Custom.selected = true;
      if (action.meta.arg.type == SalaryTypeVariant.Predefined)
        state.Salarytypes.Predefined.selected = true;
    });
    builder.addCase(listSalarytypes.rejected, (state, action) => {
      state.Salarytypes.loading = false;
      state.Salarytypes.errorMessage = action.error.message as string;
      state.Salarytypes.error = true;
    });
  },
});

export default companyDetail;
