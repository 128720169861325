import React from "react";
import { LabelValuePair } from "../formFields/staticSelect/SelectMenuInput";

export enum Mode {
  read = "READ",
  Write = "WRITE",
}

export enum FormFieldType {
  TEXT = "TEXT",
  DATE = "DATE",
  DATE_LEFT = "DATE_LEFT",
  SELECT = "SELECT",
  BOOLEAN = "BOOLEAN",
  SELECT_MOTHER_COMPANY = "SELECT_MOTHER_COMPANY",
  SELECT_LOCATION = "SELECT_LOCATION",
  SELECT_DEPARTMENT = "SELECT_DEPARTMENT",
  SELECT_EMPLOYEES = "SELECT_EMPLOYEES",
  FINANCIAL_AUTHORITY = "SELECT_FINANCIAL_AUTHORITY",
  BOOLEAN_WATCH_FIELD = "BOOLEAN_WATCH_FIELD",
  TIME_FIELD = "TIME_FIELD",
}

//as Request Parameter: {requestFieldName or fieldName: value} is added as request parameter and excluded from body
//renderCondition: Field is rendered conditionally on the value of another watch field
//watch fields: Field is not part of the request, serves just ui purposes
//menuitems: in case of select field, you can pass in options as label value pairs
//requestFieldName: to use, if fieldName in the response is differing fom fieldName in the request

export interface DisplayCardField {
  type: FormFieldType | boolean;
  validation?: { required?: boolean; minLength?: number };
  asRequestParameter?: boolean;
  conditionalRender?: boolean;
  conditionalFields?: string[];
  minutesFieldName?: string;
  hoursFieldName?: string;
  defaultValueHours?: string | number | null | undefined;
  defaultValueMin?: string | number | null | undefined;
  defaultValue: string | number | Date | null | undefined | any;
  fieldName: string;
  requestFieldName?: string;
  label: string;
  menuItems?: LabelValuePair[];
}

export interface DisplayCardProps {
  title: string;
  getEndpoint: string;
  updateEndpoint: string;
  patch: boolean;
  id: number;
  input: {
    data: any;
    loading: boolean;
    error: boolean;
    errorMessage?: string;
  };
  formFields: DisplayCardField[];
  callbackFunc: () => void;
  additionalBodyParams?: string[];
  additionalRequestParams?: LabelValuePair[];
  width?: string;
}

export interface DisplayCardModeWriteProps {
  response: React.ComponentState;
  formFields: DisplayCardField[];
  updateEndpoint: string;
  patch: boolean;
  id: number;
  callbackFunction: (r: boolean) => void;
  additionalBodyParams?: string[];
  additionalRequestParams?: LabelValuePair[];
}

export interface DisplayCardModeProps {
  response: React.ComponentState;
  id: number;
  formFields: DisplayCardField[];
}

export interface CompanyBasicData {
  id: number;
  name: string;
  street: string;
  city: string;
  postalCode: string;
}
