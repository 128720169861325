import React from "react";
import styled from "styled-components";
import { List } from "immutable";
import { MenuItem, Select } from "@material-ui/core";
import useSelectedStyle, { UseSelectedStyleProps } from "./useSelected";

// types
type Option = { key: string; value: string | number };

export type SelectControlProps = UseSelectedStyleProps & {
  options: List<Option>;
};

// styles
export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .MuiInput-root {
    &:before {
      border-bottom: none !important;
    }
  }
`;

// components
const SelectControl: React.FC<SelectControlProps> = ({ options, ...props }) => {
  const [selectedStyle, setSelectedStyle] = useSelectedStyle(props);

  return (
    <Container>
      <Select
        key={selectedStyle}
        value={selectedStyle}
        onChange={(e) => setSelectedStyle(String(e.target.value))}
      >
        {options.map(
          (option) =>
            option && (
              <MenuItem key={option.key} value={option.key}>
                {option.value}
              </MenuItem>
            )
        )}
      </Select>
    </Container>
  );
};

export default SelectControl;
