import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  DisplayCardField,
  DisplayCardModeWriteProps,
  FormFieldType,
} from "./utils";
import SelectBoolean from "../formFields/SelectBoolean";
import SelectMenuInput, {
  LabelValuePair,
} from "../formFields/staticSelect/SelectMenuInput";
import CompaniesSelect from "../formFields/dynamic/CompaniesSelect";
import { ButtonContainer, DataCardBody } from "./styles";
import TextFieldController from "../formFields/TextFieldController";
import { CoreRequest } from "../../config/backend/serviceInstances";
import EmployeesSelect from "../formFields/dynamic/EmployeesSelect";
import FinancialAuthoritySelect, {
  CompanyLabelFinance,
} from "../formFields/dynamic/FinancialAuthoritySelect";
import DateFieldRHF from "../formFields/dateField/DateFieldRHF";
import { Button, CircularProgress, Typography } from "@mui/material";

const DisplayCardWriteMode = (
  props: DisplayCardModeWriteProps
): React.ReactElement => {
  const [submitState, setSubmitState] = useState({
    loading: false,
    error: false,
  });
  const { control, handleSubmit } = useForm();

  const onSubmit = (d: any) => {
    const allDataFields = d;
    if (props.additionalBodyParams) {
      props.additionalBodyParams.forEach((field) => {
        // Augment the POST body with any additional fields not in the form itself
        allDataFields[field] = props.response[field];
      });
    }

    //configure config
    const config: { params: any } = { params: {} };
    props.additionalRequestParams?.forEach((item) => {
      config.params[item.label] = item.value;
    });
    setSubmitState({ loading: true, error: false });
    if (props.patch) {
      CoreRequest.patch(props.updateEndpoint, allDataFields, config)
        .then(() => {
          setSubmitState({ loading: false, error: false });
          props.callbackFunction(true);
        })
        .catch(() => setSubmitState({ loading: false, error: true }));
    } else {
      CoreRequest.put(props.updateEndpoint, allDataFields, config)
        .then(() => {
          setSubmitState({ loading: false, error: false });
          props.callbackFunction(true);
        })
        .catch(() => setSubmitState({ loading: false, error: true }));
    }
  };

  const returnFormfield = (item: DisplayCardField) => {
    switch (item.type) {
      case FormFieldType.TEXT:
        return (
          <TextFieldController
            control={control}
            defaultValue={props.response[item.fieldName]}
            label={item.label}
            fieldName={
              item.requestFieldName ? item.requestFieldName : item.fieldName
            }
            rules={item.validation}
          />
        );
      case FormFieldType.DATE:
        return (
          <DateFieldRHF
            control={control}
            defaultValue={
              props.response[item.fieldName]
                ? props.response[item.fieldName]
                : ""
            }
            label={item.label}
            fieldName={
              item.requestFieldName ? item.requestFieldName : item.fieldName
            }
            validation={item.validation}
          />
        );
      case FormFieldType.BOOLEAN:
        return (
          <SelectBoolean
            control={control}
            fieldName={
              item.requestFieldName ? item.requestFieldName : item.fieldName
            }
            label={item.label}
            errorText={""}
            defaultValue={props.response[item.fieldName]}
          />
        );
      case FormFieldType.SELECT:
        return (
          <SelectMenuInput
            control={control}
            errorMessage={"Bitte auswählen"}
            fieldName={
              item.requestFieldName ? item.requestFieldName : item.fieldName
            }
            label={item.label}
            defaultValue={props.response[item.fieldName]}
            menuItems={item.menuItems !== undefined ? item.menuItems : []}
          />
        );
      case FormFieldType.SELECT_MOTHER_COMPANY:
        return (
          <CompaniesSelect
            control={control}
            fieldName={
              item.requestFieldName ? item.requestFieldName : item.fieldName
            }
            defaultValue={props.response[item.fieldName]?.value}
          />
        );
      case FormFieldType.FINANCIAL_AUTHORITY:
        return (
          <FinancialAuthoritySelect
            label={"Fin"}
            control={control}
            fieldName={
              item.requestFieldName ? item.requestFieldName : item.fieldName
            }
            defaultValue={props.response[item.fieldName]}
            transform={{
              output: (data: CompanyLabelFinance) => {
                return data?.id;
              },
            }}
          />
        );
      case FormFieldType.SELECT_EMPLOYEES:
        return (
          <EmployeesSelect
            control={control}
            fieldName={
              item.requestFieldName ? item.requestFieldName : item.fieldName
            }
            label={item.label}
            defaultValue={props.response[item.fieldName]?.map(
              (item: LabelValuePair) => {
                return item.value;
              }
            )}
          />
        );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DataCardBody>
        {props.formFields.map((item) => {
          return (
            <div
              key={item.label}
              style={{ marginBottom: "30px", marginTop: "30px" }}
            >
              {returnFormfield(item)}
            </div>
          );
        })}
      </DataCardBody>
      <ButtonContainer>
        {submitState.loading ? (
          <CircularProgress />
        ) : (
          <>
            {" "}
            <Button
              color={"secondary"}
              variant={"contained"}
              onClick={() => props.callbackFunction(false)}
            >
              Abbrechen
            </Button>
            &nbsp;
            <Button
              type={"submit"}
              color={"primary"}
              variant={"contained"}
              disabled={submitState.loading}
            >
              Bestätigen
            </Button>
          </>
        )}
        {submitState.error ? (
          <Typography variant={"subtitle2"}>
            Daten konnten nicht aktualisiert werden
          </Typography>
        ) : (
          <></>
        )}
      </ButtonContainer>
    </form>
  );
};
export default DisplayCardWriteMode;
