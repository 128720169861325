import React, { useEffect } from "react";
import FilterList, {
  FilterItem,
  useFilterList,
} from "../../../globalComponents/filterList/FilterList";

// types
export type ToolbarProps = {
  filterList: FilterItem[];
  onChange: (filter: string) => void;
  className?: string;
};

// components
const Toolbar: React.FC<ToolbarProps> = ({
  filterList,
  onChange,
  className,
}) => {
  const { filter, onSetFilter } = useFilterList();

  useEffect(() => {
    if (onChange) onChange(filter);
  }, [filter]);

  return (
    <FilterList
      list={filterList}
      filter={filter}
      onSetFilter={onSetFilter}
      className={className}
    />
  );
};

export default Toolbar;
