import React from "react";
import { Typography } from "@mui/material";
import { ContentContainer } from "./components/styledComponents";
import { GridValueGetterParams } from "@mui/x-data-grid";
import EmployeeAvatar from "../../../../../globalComponents/avatars/EmployeeAvatar";
import LinkStyled from "../../../../../globalComponents/container/LinkStyled";
import SmartTable from "../../../../../globalComponents/smartOperations/smartTable/SmartTable";
import { Warning } from "@mui/icons-material";
import { getDateText } from "../../../../../utils/dates";

const dpf_dummyData = [
  {
    id: 1,
    employee: {
      id: 2,
      firstName: "Angelina",
      lastName: "Jolie",
      imgLink: "",
      startDate: "2020-01-01",
      position: "Part Time Student",
      department: "Geschäftsführung",
      location: "Berlin",
      companyId: 2202,
      companyName: "Testfirma GmbH",
      status: "Angestellt",
    },
    message: { text: "Steuernummer fehlt", link: "" },
  },
  {
    id: 2,
    employee: {
      id: 3,
      firstName: "Emma",
      lastName: "Watson",
      imgLink: "",
      startDate: "2020-01-01",
      position: "CPO",
      department: "Geschäftsführung",
      location: "Berlin",
      companyId: 2302,
      companyName: "Testfirma GmbH",
      status: "Angestellt",
    },
    message: { text: "Anschrift fehlt", link: "" },
  },
  {
    id: 3,
    employee: {
      id: 4,
      firstName: "Tom",
      lastName: "Cruise",
      imgLink: "",
      startDate: "2020-01-01",
      position: "CTO",
      department: "Geschäftsführung",
      location: "Berlin",
      companyId: 202,
      companyName: "Testfirma GmbH",
      status: "Angestellt",
    },
    message: { text: "Sozialversicherungsnummer fehlt", link: "" },
  },
];

const columns = [
  {
    field: "employee",
    headerName: "Mitarbeiter",
    width: 300,
    renderCell: (params: GridValueGetterParams) => (
      <EmployeeAvatar
        id={params.row.employee.id}
        lastName={params.row.employee.lastName}
        firstName={params.row.employee.firstName}
        position={params.row.employee.position}
        imgLink={params.row.employee.imgLink}
      />
    ),
  },
  {
    field: "department",
    headerName: "Abteilung",
    width: 200,
    renderCell: (params: GridValueGetterParams) => (
      <Typography variant={"body2"}>
        {params.row.employee.department}
      </Typography>
    ),
  },
  {
    field: "employee1",
    headerName: "Startdatum",
    width: 200,
    renderCell: (params: GridValueGetterParams) => (
      <Typography variant={"body2"}>
        {getDateText(params.row.employee.startDate)}
      </Typography>
    ),
  },
  {
    field: "message",
    headerName: "Hinweis",
    width: 400,
    renderCell: (params: GridValueGetterParams) => (
      <>
        <Warning color={"warning"} />
        <LinkStyled to={params.row.message.link}>
          <Typography variant={"body2"} marginLeft={"10px"}>
            {params.row.message.text}
          </Typography>
        </LinkStyled>
      </>
    ),
  },
];

const DigitalPersonalFile = (): React.ReactElement => {
  // const dpf = useDPFtoDos();
  return (
    <ContentContainer>
      <SmartTable
        columns={columns}
        input={{
          data: dpf_dummyData,
          loading: false,
          error: false,
          errorMessage: "",
        }}
        height={"500px"}
        deleteConfirmMessage={""}
        deleteEndpoint={""}
        hideActions
        updateInputCallback={() => console.log("s")}
      />
    </ContentContainer>
  );
};

export default DigitalPersonalFile;
