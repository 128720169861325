import styled from "styled-components";

const OrgChartElementContainer = styled.div<{ zoomLevel: number }>`
  width: ${(props) => props.zoomLevel * 30}px;
  height: ${(props) => props.zoomLevel * 11}px;
  box-shadow: 0px 8px 16px #4c4c4c00;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 0 10px 0px 10px;
  &:hover {
    border: 1px solid #054D80};
    cursor: pointer; 
  }
`;

export default OrgChartElementContainer;
