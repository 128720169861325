import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  AuthRequest,
  CoreRequest,
} from "../../config/backend/serviceInstances";
import { AuthEndpoints } from "../../config/backend/endpointsAuth";
import qs from "querystring";
import {
  DataState,
  DataStateInitial,
} from "../organization/companies/detail/companyDetail";
import { Employee } from "../employees/employees/employee";
import { CoreEndpoints } from "../../config/backend/endpointsCore";

export interface Roles {
  HR_MANAGER: string[];
  EMPLOYEE: string[];
  ALL_ROLES: string[];
}

export const RolesI: Roles = {
  HR_MANAGER: [],
  EMPLOYEE: [],
  ALL_ROLES: [],
};

export interface authorizationState {
  authentication: boolean;
  session: boolean;
  roles: Roles;
  refreshToken: string;
  refreshInstance: number;
  loginByCredentials: DataState;
  refreshTokenRequest: DataState;
  loginBySession: DataState;
  logOut: DataState;
}

export const AuthorizationStateI: authorizationState = {
  authentication: false,
  session: false,
  roles: RolesI,
  refreshToken: "",
  refreshInstance: 0,
  loginByCredentials: DataStateInitial,
  refreshTokenRequest: DataStateInitial,
  loginBySession: DataStateInitial,
  logOut: DataStateInitial,
};

interface AuthorizationResponse {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
  jti: string;
}

interface AuthorizationBySessionResponse {
  accessToken: string;
  detail: string;
  refreshToken: string;
  session: boolean;
  timeStamp: Date;
}

export type LoginCredentials = { username: string; password: string };

const authConfig = {
  withCredentials: true,

  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  params: {
    grant_type: "password",
    scope: "any",
  },
  auth: {
    username: "alex",
    password: "alex",
  },
};

export const refreshConfig = {
  ...authConfig,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  params: { grant_type: "refresh_token", scope: "any" },
};

export const config = {
  auth: {
    username: "alex",
    password: "alex",
  },
  withCredentials: true,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const authenticateByCredentials = createAsyncThunk<
  AuthorizationResponse,
  LoginCredentials
>("/authentication/login", async (credentials: LoginCredentials) => {
  const response = await AuthRequest.post(
    AuthEndpoints.GET_TOKEN,
    qs.stringify(credentials),
    authConfig
  );

  if (response.status > 299) {
    // Return the known error for future handling
    return response.data.error;
  }

  return response.data as AuthorizationResponse;
});

export const authenticateBySession = createAsyncThunk<AuthorizationBySessionResponse>(
  "/authentication/session",
  async () => {
    const response = await AuthRequest.get(AuthEndpoints.CHECK_SESSION, config);

    if (response.status > 299) {
      // Return the known error for future handling
      return response.data.error;
    }

    return response.data as AuthorizationBySessionResponse;
  }
);

export const refreshToken = createAsyncThunk<AuthorizationResponse, string>(
  "/authentication/refresh",
  async (rToken) => {
    const response = await AuthRequest.post(
      AuthEndpoints.GET_TOKEN,
      qs.stringify({
        refresh_token: rToken,
      }),
      refreshConfig
    );

    if (response.status > 299) {
      // Return the known error for future handling
      return response.data.error;
    }
    return response.data as AuthorizationResponse;
  }
);

export const fetchSelf = createAsyncThunk<Employee>(
  "/authentication/self",
  async () => {
    const response = await CoreRequest.get(CoreEndpoints.EMPLOYEE_SELF);

    if (response.status > 299) {
      // Return the known error for future handling
      return response.data.error;
    }
    return response.data as Employee;
  }
);

export const enterDemoAccount = createAction("/demo");

export const logOut = createAsyncThunk("/authentication/logout", async () => {
  const response = await AuthRequest.post(AuthEndpoints.LOGOUT, config, {
    withCredentials: true,
  });

  window.location.href = "/app/login";

  return response.data as string;
});
