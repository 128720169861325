import React, { useState } from "react";
import { Backdrop, Typography } from "@mui/material";

import OrgChartElementHeaderLeft from "../../../../globalComponents/orgChart/styles/OrgChartElementHeaderLeft";
import OrgChartElementHeaderRight from "../../../../globalComponents/orgChart/styles/OrgChartElementHeaderRight";
import EmployeeAvatars from "../../../../globalComponents/avatars/EmployeeAvatars";
import OrgChartElement from "../../../../globalComponents/orgChart/OrgChartElement";
import { EmployeeAvatarInput } from "../../../../globalComponents/avatars/EmployeeAvatar";
import SmartTableDelete from "../../../../globalComponents/smartOperations/smartCrud/SmartTableDelete";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

interface CompanyOrgChartElementProps {
  id: number;
  companyName: string;
  managingDirectors: EmployeeAvatarInput[];
  deleteAndClose: () => void;
  zoomLevel: number;
  activeEmployees: number;
}

function HeaderLeft(props: {
  id: number;
  companyName: string;
  zoomLevel: number;
}) {
  return (
    <OrgChartElementHeaderLeft>{props.companyName}</OrgChartElementHeaderLeft>
  );
}

function Body(props: { managingDirectors: EmployeeAvatarInput[] }) {
  return (
    <EmployeeAvatars
      employeeList={props.managingDirectors}
      noElementsFoundMessage={"Keine Geschäftsführer angegeben."}
    />
  );
}

const CompanyOrgChartElement = (
  props: CompanyOrgChartElementProps
): JSX.Element => {
  const [deletePopup, setdeletePopup] = useState(false);
  const history = useHistory();
  function HeaderRight() {
    return (
      <OrgChartElementHeaderRight>
        <FontAwesomeIcon icon={faUsers} color={"#999999"} />
        <Typography variant={"subtitle2"} marginLeft={"5px"}>
          {props.activeEmployees}
        </Typography>
      </OrgChartElementHeaderRight>
    );
  }
  return (
    <>
      <OrgChartElement
        callBack={() => history.push(`/app/companies/${props.id}/basic`)}
        zoomLevel={props.zoomLevel}
        headerLeft={
          <HeaderLeft
            companyName={props.companyName}
            id={props.id}
            zoomLevel={props.zoomLevel}
          />
        }
        headerRight={<HeaderRight />}
        body={<Body managingDirectors={props.managingDirectors} />}
      />
      {deletePopup ? (
        <Backdrop
          open={deletePopup}
          style={{ zIndex: 1 }}
          onClick={() => setdeletePopup(false)}
        >
          <SmartTableDelete
            id={props.id}
            deleteEndpoint={"/companies?companyId="}
            close={() => setdeletePopup(false)}
            deleteAndClose={() => props.deleteAndClose()}
            deleteConfirmMessage={"Möchten Sie die Firma wirklich löschen?"}
          />
        </Backdrop>
      ) : (
        <></>
      )}
    </>
  );
};

export default CompanyOrgChartElement;
