import {
  Grid,
  IconButton,
  List,
  ListItem,
  Slide,
  Typography,
} from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";

export interface MenuMobileFlapOutProps {
  closeFlapOut: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  children: JSX.Element[];
}

const MenuMobileFlapOutContainer = (
  props: MenuMobileFlapOutProps
): React.ReactElement => {
  return (
    <Slide in direction={"up"} timeout={600}>
      <List>
        <ListItem>
          <Grid container justify={"space-between"} alignItems={"center"}>
            <Typography variant={"body1"}>{props.title}</Typography>
            <IconButton onClick={() => props.closeFlapOut(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </ListItem>
        {props.children}
      </List>
    </Slide>
  );
};

export default MenuMobileFlapOutContainer;
