import { DataState } from "./companyDetail";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CoreRequest } from "../../../../config/backend/serviceInstances";

export type CompanyDetailSocial = {
  id: number;
};

export interface CompanyDetailSocialState extends DataState {
  data: CompanyDetailSocial | Record<string, never>;
}

export const CompanyDetailSocialStateI: CompanyDetailSocialState = {
  loading: false,
  error: false,
  data: {},
};

export const getSocialData = createAsyncThunk<CompanyDetailSocial, number>(
  "/companies/detail/social",
  async (companyId) => {
    const response = await CoreRequest.get(`/companies/social`, {
      params: { companyId: companyId },
    });

    if (response.status > 299) {
      // Return the known error for future handling
      return response.data.error;
    }

    return response.data as CompanyDetailSocial;
  }
);
