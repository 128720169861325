import React from "react";
import { Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const GridContainer = styled(Grid)`
  max-width: 35%;
  padding: 5px;
  border: 1px solid lightgrey;
  border-radius: 4px;
`;

const DeductionsTax = (): JSX.Element => {
  return (
    <>
      <GridContainer container>
        <Grid item xs={1}>
          <Typography variant={"caption"}>St</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={"caption"}>Brutto</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant={"caption"}>Lohnsteuer</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={"caption"}>Ki.St.</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={"caption"}>Solz.</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={"caption"}>Betrag</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant={"caption"}>L</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={"caption"}>5,666.67</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant={"caption"}>1,240.00</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={"caption"}>0.00</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={"caption"}>68.20</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={"caption"}>1,308.20</Typography>
        </Grid>
      </GridContainer>
    </>
  );
};

export default DeductionsTax;
