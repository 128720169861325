import { useSelector } from "react-redux";
import { Store } from "../../store";
import { EmployeeProfilesState } from "./employee";
import {
  EmployeeDetailAbsenceState,
  EmployeeDetailBasicState,
  EmployeeDetailSocialState,
  EmployeeDetailState,
  EmployeeDetailTaxState,
  EmployeeDetailWorkContractDetailState,
  EmployeeDetailWorkContractsState,
  WorkContractBasicState,
  WorkContractCompensationsState,
  WorkContractDetailBudgetState,
  WorkContractJobState,
  WorkContractSchedulesState,
  WorkContractStatisticState,
} from "../employeeDetail/employeeDetail";

export const useEmployees = (): EmployeeProfilesState => {
  return useSelector<Store, EmployeeProfilesState>((state) => state.employees);
};

export const useEmployeeDetailBasic = (): EmployeeDetailBasicState => {
  return useSelector<Store, EmployeeDetailBasicState>(
    (state) => state.employeeDetail.Basic
  );
};

export const useEmployeeDetail = (): EmployeeDetailState => {
  return useSelector<Store, EmployeeDetailState>(
    (state) => state.employeeDetail
  );
};

export const useEmployeeDetailTax = (): EmployeeDetailTaxState => {
  return useSelector<Store, EmployeeDetailTaxState>(
    (state) => state.employeeDetail.Tax
  );
};

export const useEmployeeDetailSocial = (): EmployeeDetailSocialState => {
  return useSelector<Store, EmployeeDetailSocialState>(
    (state) => state.employeeDetail.Social
  );
};

export const useEmployeeWorkContracts = (): EmployeeDetailWorkContractsState => {
  return useSelector<Store, EmployeeDetailWorkContractsState>(
    (state) => state.employeeDetail.WorkContracts
  );
};

export const useEmployeeWorkContractDetail = (): EmployeeDetailWorkContractDetailState => {
  return useSelector<Store, EmployeeDetailWorkContractDetailState>(
    (state) => state.employeeDetail.WorkContractDetail
  );
};

export const useEmployeeWorkContractBasic = (): WorkContractBasicState => {
  return useSelector<Store, WorkContractBasicState>(
    (state) => state.employeeDetail.WorkContractDetail.workContractBasic
  );
};

export const useEmployeeWorkContractJob = (): WorkContractJobState => {
  return useSelector<Store, WorkContractJobState>(
    (state) => state.employeeDetail.WorkContractDetail.workContractJob
  );
};

export const useEmployeeWorkContractStatistics = (): WorkContractStatisticState => {
  return useSelector<Store, WorkContractStatisticState>(
    (state) => state.employeeDetail.WorkContractDetail.workContractStatistics
  );
};

export const useEmployeeWorkContractBudgets = (): WorkContractDetailBudgetState => {
  return useSelector<Store, WorkContractDetailBudgetState>(
    (state) => state.employeeDetail.WorkContractDetail.budgets
  );
};

export const useEmployeeWorkContractWorkSchedule = (): WorkContractSchedulesState => {
  return useSelector<Store, WorkContractSchedulesState>(
    (state) => state.employeeDetail.WorkContractDetail.workSchedules
  );
};

export const useEmployeeWorkContractCompensations = (): WorkContractCompensationsState => {
  return useSelector<Store, WorkContractCompensationsState>(
    (state) => state.employeeDetail.WorkContractDetail.compensation
  );
};
export const useEmployeeDetailAbsences = (): EmployeeDetailAbsenceState => {
  return useSelector<Store, EmployeeDetailAbsenceState>(
    (state) => state.employeeDetail.AbsenceRequests
  );
};
