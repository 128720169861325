import React, { useCallback, useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import EmployeeAvatar from "../../../../../../globalComponents/avatars/EmployeeAvatar";

const EmployeeCardContainer = styled.div`
  position: relative;
`;

const EmployeeCardContainerExpanded = styled.div`
  position: fixed;
  background-color: white;
  bottom: 90;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0px 0px 3px 0px lightgrey;
  padding: 10px;
`;

const useStyles = makeStyles({
  circular: {
    border: "2px solid transparent",

    "&:hover": {
      border: "2px solid darkgrey",
    },
  },
});

const EmployeeCardCollapsed = (props) => {
  const classes = useStyles();

  const [state, setState] = useState(false);

  const handleKeyUp = useCallback(
    (event) => {
      setState(false);
    },
    [setState]
  );

  useEffect(() => {
    if (state) {
      window.addEventListener("click", handleKeyUp);
    } else {
      window.removeEventListener("click", handleKeyUp);
    }

    return () => window.removeEventListener("click", handleKeyUp);
  }, [state]);

  return (
    <EmployeeCardContainer>
      <Avatar
        classes={classes}
        src={
          !!props.imgLink
            ? window.server_url + "/employees/pic/" + props.imgLink
            : null
        }
        alt={props.firstName + props.lastName}
        position={props.position}
        id={props.id}
        onClick={() => {
          setState(!state);
        }}
      />
      {state ? (
        <EmployeeCardContainerExpanded>
          <EmployeeAvatar
            imgLink={props.imgLink}
            firstName={props.firstName}
            lastName={props.lastName}
            position={props.position}
            id={props.id}
          />
        </EmployeeCardContainerExpanded>
      ) : (
        <></>
      )}
    </EmployeeCardContainer>
  );
};

export default EmployeeCardCollapsed;
