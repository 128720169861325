import axios, { AxiosResponse } from "axios";
import { defaultTo, random } from "lodash/fp";
import { useEffect, useState } from "react";
import { Employee } from "../store/actions";
import { baseUrl } from "../config/globalVariables";
import { WorkContractSimple } from "../state/employees/types";
import { format } from "date-fns/fp";

// types
export type EmployeeResponse = Employee | "Loading" | "Error";

export type UseRandomEmployee = () => EmployeeResponse;

// utils
const getAxiosResponseData = ({ data }: AxiosResponse) => data;

const getRandomEmployee = (employees: Employee[]): Promise<Employee> =>
  Promise.resolve(employees[random(0)(employees.length - 1)]);

export const setError = (): string => "Error";

const getEmployee = (): Promise<Employee> =>
  axios
    .get(baseUrl + `/employees/all`)
    .then(getAxiosResponseData)
    .then(getRandomEmployee);

const getEmployeeField = (key: string) => (employee?: EmployeeResponse) => {
  if (!employee || employee === "Error" || employee === "Loading") return "";

  return employee[key];
};

export const getId = getEmployeeField("id");

export const getFirstName = getEmployeeField("firstName");

export const getLastName = getEmployeeField("lastName");

export const getPosition = getEmployeeField("position");

export const getImgLink = getEmployeeField("imgLink");

export const defaultDateTo = (
  defaultValue: string,
  date?: string | null
): string => {
  if (!date) {
    return defaultValue;
  }

  return format("dd.MM.yy", new Date(date));
};

export const formatWorkContract = (
  workContract: WorkContractSimple
): string => {
  if (!workContract) {
    return "-";
  }

  const position = defaultTo("Position fehlt", workContract.position);
  const startDate = defaultDateTo("-", workContract.startDate);
  const endDate = defaultDateTo("∞", workContract.endDate);

  return `${position} (${startDate} - ${endDate})`;
};

export const getFullName = (e: EmployeeResponse): string =>
  `${getEmployeeField("firstName")(e)} ${getEmployeeField("lastName")(
    e
  )}`.trim();

export const getFullNameFromEmployee = (e: Employee | null): string => {
  const firstName = !!e && e.firstName ? e.firstName : "";
  const lastName = !!e && e.lastName ? e.lastName : "";
  return `${firstName} ${lastName}`.trim();
};

// hooks
// @todo remove when server data is available
export const useRandomEmployee: UseRandomEmployee = () => {
  const [employee, setEmployee] = useState<EmployeeResponse>("Loading");

  useEffect(() => {
    getEmployee().then(setEmployee).catch(setError);
  }, []);

  return employee;
};
