import React from "react";
import IconButtonNew from "../buttons/IconButtonNew";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { Backdrop } from "@mui/material";
import styled from "styled-components";

const DocumentContainer = styled.div`
  position: absolute;
  top: 80px;
  bottom: 0;
  overflow: scroll;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 0px 20px 0px;
`;

const PageTopContainer = styled.div`
  position: absolute;
  font-family: Roboto;
  color: white;
  font-weight: 500;
  font-size: 18px;
  width: 100%;
  top: 0;
  height: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #054d80;
`;

const TopBlock = styled.div<{ align: string }>`
  padding: 0px 40px 0px 40px;
  display: flex;
  justify-content: ${(props) => props.align};
  align-items: center;
`;

interface FileReaderContainerProps {
  currentPages: number;
  totalPages: number;
  closeCallBack: () => void;
  fileName: string;
  children: JSX.Element;
}

const FileReaderContainer = (props: FileReaderContainerProps): JSX.Element => {
  return (
    <Backdrop open style={{ zIndex: 4 }} onClick={() => props.closeCallBack()}>
      <PageTopContainer>
        <TopBlock align={"flex-start"}>{props.fileName}</TopBlock>
        <TopBlock align={"center"}>
          {props.currentPages} / {props.totalPages}
        </TopBlock>
        <TopBlock align={"flex-end"}>
          <IconButtonNew
            onClick={() => props.closeCallBack()}
            icon={faTimesCircle}
            primary
          />
        </TopBlock>
      </PageTopContainer>
      <DocumentContainer>{props.children}</DocumentContainer>
    </Backdrop>
  );
};

export default FileReaderContainer;
