const branche = [
  { label: "Dienstleistungen", value: "Dienstleistungen" },
  { label: "Produktion ohne Bau", value: "Produktion ohne Bau" },
  { label: "Bau", value: "Bau" },
  {
    label: "Land und Forstwirtschaft, Fischerei",
    value: "Land und Forstwirtschaft, Fischerei",
  },
];

export default branche;
