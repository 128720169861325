import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import { CoreRequest } from "../../../config/backend/serviceInstances";
import { useUserSelection } from "../../../state/general/selectors";
import { MenuProps } from "./EmployeesSelect";
import { StyledLabel, TextFieldWrapper } from "../TextFieldController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      width: "100%",
      marginTop: "1em",
    },
    selectMenu: {
      maxHeight: 200,
    },
  })
);

export interface CompanyLabel {
  label: string;
  value: number;
}

type Props = {
  control: Control<any>;
  fieldName: `${string}`;
  defaultValue?: number | null;
  required?: boolean;
  showLabel?: boolean;
};

const CompaniesSelect = (props: Props): React.ReactElement => {
  const { t } = useTranslation("common");
  const classes = useStyles();
  const userSelection = useUserSelection();

  const [companyLabels, setCompanyLabels] = useState<CompanyLabel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    CoreRequest.get("/companies/labels")
      .then((response) => {
        setLoading(false);
        const responses = response.data.concat([{ label: "Keine", value: 0 }]);
        setCompanyLabels(responses);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [userSelection]);

  if ((!companyLabels || loading) && props.showLabel !== false) {
    return (
      <FormControl className={classes.formControl}>
        <InputLabel id={`${props.fieldName}-companySelect`}>
          {t("company.select")}
        </InputLabel>
        <CircularProgress />
      </FormControl>
    );
  }

  return (
    <Controller
      name={props.fieldName}
      control={props.control}
      defaultValue={props.defaultValue || ""}
      rules={props.required ? { required: "Company is required" } : {}}
      render={({
        field: { onChange, value },
        fieldState: { error, invalid, isDirty },
      }) => {
        return (
          <TextFieldWrapper>
            {props.showLabel !== false ? (
              <StyledLabel>
                <label>{t("company.select")}</label>
                {!invalid && isDirty ? (
                  <>
                    &nbsp;
                    <FontAwesomeIcon icon={faCheckCircle} color={"#00A76D"} />
                  </>
                ) : (
                  <></>
                )}
                {(invalid && isDirty) || error ? (
                  <>
                    &nbsp;
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      color={"#C80410"}
                    />
                  </>
                ) : (
                  <></>
                )}
              </StyledLabel>
            ) : (
              <></>
            )}
            <Select
              disabled={loading}
              className={classes.selectMenu}
              labelId={`${props.fieldName}-companySelect`}
              id={`${props.fieldName}-companySelect`}
              onChange={onChange}
              value={value}
              MenuProps={MenuProps}
              error={error !== undefined}
              renderValue={(value: any) => {
                const vals = companyLabels.filter(
                  (item) => item.value == value
                );
                if (vals && vals.length > 0) {
                  return vals[0].label;
                }
              }}
            >
              {companyLabels.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </Select>
            <>
              {error !== undefined ? (
                <FormHelperText error>
                  Bitte geben Sie die übergeordnete Firma an.
                </FormHelperText>
              ) : null}
            </>
          </TextFieldWrapper>
        );
      }}
    />
  );
};

export default CompaniesSelect;
