import {
  AccountType,
  updateUserSelection,
  UserSelectionState,
  UserSelectionStateI,
} from "./userSelection";
import {
  authenticateByCredentials,
  authenticateBySession,
  authorizationState,
  AuthorizationStateI,
  enterDemoAccount,
  logOut,
  refreshToken,
  fetchSelf,
  Roles,
} from "./auth";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { decodeToken } from "../../homepage/decodeToken";
import { CoreRequest } from "../../config/backend/serviceInstances";
import { setUpResponseInterceptor } from "../../product/authorization/setAuthInterceptor";
import { setHeaders } from "./setHeaders";
import { Employee } from "../employees/employees/employee";

export interface GeneralState2 {
  auth: authorizationState;
  userSelection: UserSelectionState;
  mobileVersion: boolean;
  self: {
    data: Employee | null;
    loading: boolean;
  };
}

const GeneralStateI: GeneralState2 = {
  auth: AuthorizationStateI,
  userSelection: UserSelectionStateI,
  mobileVersion: false,
  self: {
    data: null,
    loading: false,
  },
};

type AdjustRoles = {
  session: boolean;
  refreshToken: string;
  roles: Roles;
};

const general = createSlice({
  name: "/companies/detail",
  initialState: GeneralStateI,
  reducers: {
    adjustRoles(state, action: PayloadAction<AdjustRoles>) {
      if (action.payload.roles.EMPLOYEE.length > 0) {
        state.userSelection.type = AccountType.EMPLOYEE;
        state.userSelection.companies = action.payload.roles.EMPLOYEE;
        setHeaders(
          action.payload.roles.EMPLOYEE,
          action.payload.roles.ALL_ROLES
        );
      } else if (action.payload.roles.HR_MANAGER.length > 0) {
        setHeaders(
          action.payload.roles.HR_MANAGER,
          action.payload.roles.ALL_ROLES
        );
        state.userSelection.type = AccountType.HR_MANAGER;
        state.userSelection.companies = action.payload.roles.HR_MANAGER;
      }

      if (action.payload.roles.ALL_ROLES.length === 0) {
        state.auth.authentication = true;
      }

      state.auth.roles = action.payload.roles;
      state.auth.refreshToken = action.payload.refreshToken;
      state.auth.session = action.payload.session;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSelf.pending, (state) => {
      state.self.data = null;
      state.self.loading = true;
    });
    builder.addCase(fetchSelf.fulfilled, (state, action) => {
      state.self.data = action.payload;
      state.self.loading = false;
    });
    builder.addCase(enterDemoAccount, (state) => {
      state.auth.roles = {
        HR_MANAGER: ["2608", "2403"],
        EMPLOYEE: ["2101"],
        ALL_ROLES: ["2608", "2403", "2101"],
      };
      state.userSelection = {
        type: AccountType.HR_MANAGER,
        companies: ["2608", "2403"],
      };
      setHeaders(["2608", "2403"], ["2608", "2403", "2101"]);
    });
    builder.addCase(updateUserSelection, (state, action) => {
      setHeaders(action.payload.companies, state.auth.roles.ALL_ROLES);
      state.userSelection = action.payload;
    });

    builder.addCase(authenticateByCredentials.pending, (state) => {
      state.auth.loginByCredentials.loading = true;
    });
    builder.addCase(authenticateByCredentials.fulfilled, (state, action) => {
      state.auth.loginByCredentials.loading = false;
      state.auth.loginByCredentials.error = false;
      state.auth.loginByCredentials.errorMessage = "";
      state.auth.refreshToken = action.payload.refresh_token;
      state.auth.roles = decodeToken(action.payload.access_token);
      state.auth.refreshInstance = setUpResponseInterceptor(
        action.payload.access_token,
        action.payload.refresh_token
      );
      const roles = state.auth.roles;
      if (roles.EMPLOYEE.length > 0) {
        state.userSelection.type = AccountType.EMPLOYEE;
        state.userSelection.companies = roles.EMPLOYEE;
        setHeaders(roles.EMPLOYEE, state.auth.roles.ALL_ROLES);
      } else if (state.auth.roles.HR_MANAGER.length > 0) {
        setHeaders(roles.HR_MANAGER, state.auth.roles.ALL_ROLES);
        state.userSelection.type = AccountType.HR_MANAGER;
        state.userSelection.companies = roles.HR_MANAGER;
      }
      state.auth.authentication = true;
      state.auth.session = true;
    });
    builder.addCase(authenticateByCredentials.rejected, (state, action) => {
      state.auth.loginByCredentials.loading = false;
      state.auth.loginByCredentials.errorMessage = action.error
        .message as string;
      state.auth.loginByCredentials.error = true;
    });

    builder.addCase(authenticateBySession.pending, (state) => {
      state.auth.loginBySession.loading = true;
    });
    builder.addCase(authenticateBySession.rejected, (state) => {
      state.auth.loginBySession.loading = false;
      state.auth.loginBySession.error = true;
    });
    builder.addCase(authenticateBySession.fulfilled, (state, action) => {
      state.auth.loginBySession.loading = false;
      state.auth.loginBySession.error = false;
      state.auth.loginBySession.errorMessage = "";
      if (action.payload.session) {
        state.auth.roles = decodeToken(action.payload.accessToken);
        state.auth.refreshToken = action.payload.refreshToken;
        const roles = state.auth.roles;
        if (roles.EMPLOYEE.length > 0) {
          setHeaders(roles.EMPLOYEE, state.auth.roles.ALL_ROLES);
          state.userSelection.type = AccountType.EMPLOYEE;
          state.userSelection.companies = roles.EMPLOYEE;
        } else if (state.auth.roles.HR_MANAGER.length > 0) {
          setHeaders(roles.HR_MANAGER, state.auth.roles.ALL_ROLES);
          state.userSelection.type = AccountType.HR_MANAGER;
          state.userSelection.companies = roles.HR_MANAGER;
        }
        state.auth.refreshInstance = setUpResponseInterceptor(
          action.payload.accessToken,
          action.payload.refreshToken
        );
        state.auth.authentication = true;
        state.auth.session = true;
      }
    });

    builder.addCase(logOut.rejected, (state, action) => {
      state.auth.logOut.loading = false;
      state.auth.logOut.errorMessage = action.error.message as string;
      state.auth.logOut.error = true;
    });
    builder.addCase(logOut.pending, (state) => {
      state.auth.logOut.loading = true;
    });
    builder.addCase(logOut.fulfilled, (state) => {
      delete CoreRequest.defaults.headers.common["companyId"];
      delete CoreRequest.defaults.headers.common["X-Heaven-Token-Role-Index"];
      state.auth.logOut.loading = false;
      state.auth.logOut.error = false;
      state.auth.logOut.errorMessage = "";
      state.auth.authentication = false;
    });

    builder.addCase(refreshToken.rejected, (state, action) => {
      state.auth.refreshTokenRequest.loading = false;
      state.auth.refreshTokenRequest.errorMessage = action.error
        .message as string;
      state.auth.refreshTokenRequest.error = true;
    });
    builder.addCase(refreshToken.pending, (state) => {
      state.auth.refreshTokenRequest.loading = true;
    });
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      state.auth.refreshTokenRequest.loading = false;
      state.auth.refreshTokenRequest.error = false;
      state.auth.refreshTokenRequest.errorMessage = "";
      state.auth.refreshToken = action.payload.refresh_token;
      const roles = decodeToken(action.payload.access_token);
      if (roles.EMPLOYEE.length > 0) {
        setHeaders(roles.EMPLOYEE, roles.ALL_ROLES);
        state.userSelection.type = AccountType.EMPLOYEE;
        state.userSelection.companies = roles.EMPLOYEE;
      } else if (roles.HR_MANAGER.length > 0) {
        setHeaders(roles.HR_MANAGER, roles.ALL_ROLES);
        state.userSelection.type = AccountType.HR_MANAGER;
        state.userSelection.companies = roles.HR_MANAGER;
      }
      //delete old filter, set up new one
      state.auth.refreshInstance = setUpResponseInterceptor(
        action.payload.access_token,
        action.payload.refresh_token,
        state.auth.refreshInstance
      );
      state.auth.roles = roles;
      state.auth.session = true;
    });
  },
});

export default general;
