import { DataState } from "./companyDetail";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CoreRequest } from "../../../../config/backend/serviceInstances";

export type CompanyDetailTax = {
  id: number;
};

export interface CompanyDetailTaxState extends DataState {
  data: CompanyDetailTax | Record<string, never>;
}

export const CompanyDetailTaxStateI: CompanyDetailTaxState = {
  loading: false,
  error: false,
  data: {},
};

export const getTaxData = createAsyncThunk<CompanyDetailTax, number>(
  "/companies/detail/tax",
  async (id) => {
    const response = await CoreRequest.get(`/companies/tax`, {
      params: { companyId: id },
    });

    if (response.status > 299) {
      // Return the known error for future handling
      return response.data.error;
    }

    return response.data as CompanyDetailTax;
  }
);
