export const timetrackingMonthResponse = {
  departments: [
    {
      id: 91,
      name: "Sales",
      hourPerDepartment: [
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
      ],
    },
    {
      id: 912,
      name: "Geschäftsführung",
      hourPerDepartment: [
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
      ],
    },
    {
      id: 911,
      name: "Marketing",
      hourPerDepartment: [
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
        {
          contractHours: 16,
          workedHours: 16,
        },
      ],
    },
  ],
};
export const timetrackingHoursResponse = {
  departments: [
    {
      id: 91,
      name: "Sales",
      hourPerDepartment: [
        {
          contractHours: 16,
          regularHours: 16,
          nightHours: 0,
          holiday: false,
          absent: false,
        },
        {
          contractHours: 16,
          regularHours: 16,
          nightHours: 0,
          sunday: false,
          holiday: false,
          absent: false,
        },
        {
          contractHours: 16,
          regularHours: 16,
          nightHours: 0,
          sunday: false,
          holiday: false,
          absent: false,
        },
        {
          contractHours: 16,
          regularHours: 16,
          nightHours: 0,
          sunday: false,
          holiday: false,
          absent: false,
        },
        {
          contractHours: 16,
          regularHours: 16,
          nightHours: 0,
          sunday: false,
          holiday: false,
          absent: false,
        },
        {
          contractHours: 16,
          regularHours: 16,
          nightHours: 0,
          sunday: false,
          holiday: false,
          absent: false,
        },
        {
          contractHours: 16,
          regularHours: 16,
          nightHours: 0,
          sunday: false,
          holiday: false,
          absent: false,
        },
        {
          contractHours: 16,
          regularHours: 16,
          nightHours: 0,
          sunday: false,
          holiday: false,
          absent: false,
        },
        {
          contractHours: 16,
          regularHours: 16,
          nightHours: 0,
          sunday: false,
          holiday: false,
          absent: false,
        },
        {
          contractHours: 16,
          regularHours: 16,
          nightHours: 0,
          sunday: false,
          holiday: false,
          absent: false,
        },
        {
          contractHours: 16,
          regularHours: 16,
          nightHours: 0,
          sunday: false,
          holiday: false,
          absent: false,
        },
        {
          contractHours: 16,
          regularHours: 16,
          nightHours: 0,
          sunday: false,
          holiday: false,
          absent: false,
        },
      ],
    },
  ],
};
export const timetrackingLiveResponse = {
  departments: [
    {
      id: 9,
      name: "Sales",
      numberPeopleWorking: [2, 5, 3, 2, 8, 0, 0, 3, 4, 5, 9, 7],
      employees: [
        {
          id: 10,
          firstName: "Alex",
          lastName: "Brandson",
          imgLink: "",
          position: "CMO",
          requests: [
            {
              id: 1,
              startDate: "2021-11-29T09:31",
              breaks: [],
            },
            {
              id: 2,
              startDate: "2021-11-28T22:00",
              endDate: "2021-11-29T06:15",
              breaks: [],
            },
          ],
        },
        {
          id: 42,
          firstName: "Johnny",
          lastName: "Mansion",
          imgLink: "",
          position: "CFO",
          requests: [
            {
              id: 2,
              startDate: "2021-11-29T08:31",
              endDate: "2021-11-29T10:25",
              breaks: [{ start: "2021-11-29T08:55", end: "2021-11-29T09:15" }],
            },
          ],
        },
      ],
    },
    {
      id: 239,
      name: "Engineering",
      numberPeopleWorking: [2, 5, 3, 2, 8, 0, 0, 3, 4, 5, 9, 7],
      employees: [
        {
          id: 12233,
          firstName: "Manfred",
          lastName: "Huber",
          imgLink: "",
          position: "No Position",
          requests: [
            {
              id: 3233,
              startDate: "2021-11-22T07:00",
              endDate: "2021-11-22T09:25",
              breaks: [
                { start: "2021-11-22T08:55", end: "2021-11-22T08:59" },
                { start: "2021-11-22T09:10", end: "2021-11-22T09:15" },
              ],
            },
            {
              id: 1230,
              startDate: "2021-11-22T04:00",
              endDate: "2021-11-22T08:00",
              breaks: [],
            },
          ],
        },
        {
          id: 3322,
          firstName: "Angelina",
          lastName: "Jolie",
          imgLink: "",
          position: "Part Time Student",
          requests: [
            {
              id: 3321,
              startDate: "2021-11-22T04:31",
              endDate: "2021-11-22T07:25",
              breaks: [
                { start: "2021-11-21T14:00", end: "2021-11-21T15:00" },
                { start: "2021-11-21T16:00", end: "2021-11-21T16:05" },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 91,
      name: "Marketing",
      numberPeopleWorking: [3, 5, 2, 2, 4, 0, 4, 3, 4, 2, 9, 7],
      employees: [
        {
          id: 323,
          firstName: "Alex",
          lastName: "Müller",
          imgLink: "",
          position: "CEO",
          requests: [
            {
              id: 114,
              startDate: "2021-11-21T14:31",
              endDate: "2021-11-21T16:25",
              breaks: [
                { start: "2021-11-21T14:55", end: "2021-11-21T15:00" },
                { start: "2021-11-21T15:10", end: "2021-11-21T15:15" },
              ],
            },
            {
              id: 232,
              startDate: "2021-11-21T17:00",
              endDate: "2021-11-21T18:00",
              breaks: [],
            },
          ],
        },
        {
          id: 33,
          firstName: "Magdalena",
          lastName: "Gerber",
          imgLink: "",
          position: "CMO",
          requests: [
            {
              id: 141,
              startDate: "2021-11-21T13:45",
              endDate: "2021-11-21T18:25",
              breaks: [
                { start: "2021-11-21T14:00", end: "2021-11-21T15:00" },
                { start: "2021-11-21T16:00", end: "2021-11-21T16:05" },
              ],
            },
          ],
        },
      ],
    },
  ],
};
