import React from "react";
import { Control } from "react-hook-form/dist/types/form";
import { InputLabel, TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import { Typography } from "@mui/material";

interface NumberSelectProps {
  control: Control<any>;
  rulesMin?: { required?: boolean; minLength?: number };
  rulesHours?: { required?: boolean; minLength?: number };
  hideDescription?: boolean;
  label: string;
  fieldNameMin: string;
  fieldNameHours: string;
  defaultValueHours: number;
  defaultValueMin: number;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NumberSelect = (props: NumberSelectProps): JSX.Element => {
  return (
    <>
      <InputLabel shrink>{props.label}</InputLabel>
      <Container>
        <Controller
          name={props.fieldNameHours}
          control={props.control}
          rules={{
            required: true,
            min: 0,
            max: 12,
          }}
          defaultValue={props.defaultValueHours}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <TextField
                  type="number"
                  key={props.fieldNameHours}
                  id={props.fieldNameHours}
                  onChange={onChange}
                  value={value}
                  helperText={
                    error?.type == "min"
                      ? "Arbeitsstunden sollten positiv sein"
                      : error?.type == "max"
                      ? "Mehr als 12 Stunden pro Tag darf nicht gearbeitet werden"
                      : error?.type == "required"
                      ? "Bitte ausfüllen"
                      : null
                  }
                  error={error !== undefined}
                  placeholder={"Std"}
                />
                {props.hideDescription ? (
                  <></>
                ) : (
                  <Typography variant={"subtitle2"}>Std</Typography>
                )}
              </>
            );
          }}
        />

        <Controller
          name={props.fieldNameMin}
          control={props.control}
          rules={{
            required: true,
            min: 0,
            max: 59,
          }}
          defaultValue={props.defaultValueMin}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <TextField
                  type="number"
                  key={props.fieldNameMin}
                  id={props.fieldNameMin}
                  onChange={onChange}
                  value={value}
                  helperText={
                    error?.type == "min"
                      ? "Arbeitsminuten sollten positiv sein"
                      : error?.type == "max"
                      ? "Mehr als 59 Minuten sind schon eine Stunde"
                      : error?.type == "required"
                      ? "Bitte ausfüllen"
                      : null
                  }
                  error={error !== undefined}
                  placeholder={"Min"}
                />
                {props.hideDescription ? (
                  <></>
                ) : (
                  <Typography variant={"subtitle2"}>Min</Typography>
                )}
              </>
            );
          }}
        />
      </Container>
    </>
  );
};

export default NumberSelect;
