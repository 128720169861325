import styled from "styled-components";

const SubpageHeader = styled.div`
width: 100%;
flex-direction: row;
display: flex; 
margin-top: 20px;
justify-content: space-between;
align-items: center;
`;

export default SubpageHeader;
