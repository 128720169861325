let baseUrl = "http://localhost:8085";
let authUrl = "http://localhost:8086";
let payrollUrl = "http://localhost:8087";

if (process.env.NODE_ENV === "production") {
  baseUrl = "https://core.heavenhr2.com";
  authUrl = "https://auth.heavenhr2.com";
  payrollUrl = "https://payroll.heavenhr2.com";
}

if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_ENV === "staging"
) {
  baseUrl = "/api-core";
  authUrl = "/api-auth";
  payrollUrl = "/api-payroll";
}

export { baseUrl, authUrl, payrollUrl };
