import { Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => {
  return {
    grid: {
      minHeight: 72,
      padding: "10px 10px 10px 10px",
    },
  };
});

interface MessageProps {
  icon: IconDefinition;
  component1: JSX.Element;
  component2?: JSX.Element;
}

const Message = (props: MessageProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div style={{ padding: "10px" }}>
      <Grid container className={classes.grid}>
        <Grid xs={1} style={{ fontSize: "18px" }}>
          <FontAwesomeIcon icon={props.icon} color={"#999999"} />
        </Grid>
        <Grid xs={props.component2 ? 8 : 11}>{props.component1}</Grid>
        {props.component2 ? <Grid xs={3}>{props.component2}</Grid> : <></>}
      </Grid>
    </div>
  );
};

export default Message;
