import React from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";

const ChipContainer = styled.div`
  padding: 5px 20px;
  color: white;
  background-color: ${(props) => props.color};
  border-radius: 0px;
`;

type Props = {
  color: string;
  label: string;
};

const ChipC2 = (props: Props): JSX.Element => {
  return (
    <ChipContainer color={props.color}>
      <Typography variant={"body1"} align={"center"}>
        {props.label}
      </Typography>
    </ChipContainer>
  );
};

export default ChipC2;
