import React from "react";
import { DisplayCardField } from "../../dataCard/utils";
import FormFieldReturn from "./FormFieldReturn";
import { SlideInBody, SlideInHeader, SlideInWrapper } from "./styles";
import { LabelValuePair } from "../../formFields/staticSelect/SelectMenuInput";
import { Slide, Typography } from "@mui/material";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Backdrop } from "@material-ui/core";
import IconButtonNew from "../../buttons/IconButtonNew";

interface SmartSlideInProps {
  postEndpoint: {
    url: string;
    requestParameter?: LabelValuePair[];
    bodyParameter?: LabelValuePair[];
    type: "POST";
  };
  title: string;
  formFields: DisplayCardField[];
  close: () => void;
  confirmAndClose: () => void;
  children?: JSX.Element;
  type: "UPDATE" | "CREATE" | "DELETE";
  backdropCallback: () => void;
}

const SmartSlideInCreate = (props: SmartSlideInProps): JSX.Element => {
  return (
    <>
      <Backdrop
        open={
          props.type == "UPDATE" ||
          props.type == "CREATE" ||
          props.type == "DELETE"
        }
        style={{ zIndex: 6 }}
        onClick={() => props.backdropCallback()}
      />
      <Slide in direction={"left"} timeout={1000}>
        <SlideInWrapper>
          <SlideInHeader>
            <Typography variant={"h5"}>{props.title}</Typography>
            <IconButtonNew icon={faTimes} onClick={props.close} />
          </SlideInHeader>
          <SlideInBody>
            {props.children ? (
              props.children
            ) : (
              <FormFieldReturn
                endpoint={props.postEndpoint}
                formFields={props.formFields}
                confirm={() => props.confirmAndClose()}
              />
            )}
          </SlideInBody>
        </SlideInWrapper>
      </Slide>
    </>
  );
};

export default SmartSlideInCreate;
