import React from "react";
import { StyledLabel } from "../../../../../../globalComponents/formFields/TextFieldController";
import LegendChip from "../../../../../../globalComponents/chips/LegendChip";
import {
  faDotCircle,
  faThermometerQuarter,
  faUmbrellaBeach,
} from "@fortawesome/free-solid-svg-icons";
import { LegendContainer, LegendPart, LegendPartC } from "../styles/styles";
import { Fade } from "@mui/material";

const TimetrackingLegend_30Days = (): JSX.Element => {
  return (
    <Fade in timeout={800}>
      <LegendContainer>
        <LegendPart>
          <StyledLabel>Hours</StyledLabel>
          <LegendPartC>
            <LegendChip color={"#EB8D25"} label={"Overtime"} />
            <LegendChip color={"#00A76D"} label={"Regular"} />
            <LegendChip color={"#999999"} label={"Contractual Hours"} />
          </LegendPartC>
        </LegendPart>
        <LegendPart>
          <StyledLabel>Days</StyledLabel>
          <LegendPartC>
            <LegendChip color={"green"} label={"Sunday/ Holiday"} riffled />
            <LegendChip color={"#EEF9F6"} label={"Absent"} />
            <LegendChip color={"#033354"} label={"Night Work"} />
          </LegendPartC>
        </LegendPart>
        <LegendPart>
          <StyledLabel>Absence Reasons</StyledLabel>
          <LegendPartC>
            <LegendChip
              color={"#EEF9F6"}
              icon={faUmbrellaBeach}
              label={"Holiday"}
            />
            <LegendChip
              color={"#FEF7ED"}
              icon={faThermometerQuarter}
              label={"Sickness"}
            />
            <LegendChip
              color={"yellow"}
              icon={faDotCircle}
              label={"Other Absence"}
            />
          </LegendPartC>
        </LegendPart>
      </LegendContainer>
    </Fade>
  );
};

export default TimetrackingLegend_30Days;
