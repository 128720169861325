import React, { DragEventHandler, useEffect, useRef, useState } from "react";
import {
  ContentBlock,
  convertToRaw,
  Editor,
  EditorState,
  getDefaultKeyBinding,
  Modifier,
} from "draft-js";
import SelectInput from "./SelectInput";
import EditorControls from "./EditorControls";
import {
  blockStyleFn,
  createEditorState,
  getPlaceHolder,
  getPlaceHolderDataList,
  getPlaceHolderItems,
  getStyleMap,
  handleKeyCommand,
  isArrowKey,
  isPlaceHolderChange,
  PLACEHOLDER_ID,
  ROW_DATA_KEY,
} from "./utils";
import EditorDropZone from "../../../../../../../globalComponents/DropZone";
import {
  ChipList,
  Container,
  Details,
  DocumentDetails,
  EditorContainer,
  Placeholder,
  PlaceholderContainer,
  PlaceholderContent,
  StyledChip,
} from "./styles";
import DocumentLoad from "./DocumentLoad";
import "draft-js/dist/Draft.css";
import { stateToHTML } from "draft-js-export-html";
import { Button } from "@mui/material";

// types
export enum Focus {
  In = "in",
  Out = "out",
}

export type OnDragStartHandler = (
  id: string
) => DragEventHandler<HTMLDivElement>;

// components
const TemplateGenerator: React.FC = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorFocus, setEditorFocus] = useState<Focus>(Focus.Out);
  const editor = useRef<any>(null);
  const placeHolderId = useRef("");

  useEffect(() => {
    const newEditorState = createEditorState();
    setEditorState(newEditorState);
  }, []);

  useEffect(() => {
    if (!editor.current) return;

    if (editorFocus === Focus.In) editor.current.focus();
  }, [editorFocus]);

  useEffect(() => {
    if (editorFocus === Focus.Out || !placeHolderId?.current) return;

    const placeHolderData = getPlaceHolder(placeHolderId.current);
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const text = ` {id: ${placeHolderData?.id}, label: ${placeHolderData?.label}} `;
    const newContent = Modifier.insertText(content, selection, text);
    const intermediateState = EditorState.push(
      editorState,
      newContent,
      "insert-characters"
    );
    const newSelection = selection.merge({
      anchorOffset: selection.getAnchorOffset() + text.length,
      focusOffset: selection.getFocusOffset() + text.length,
    });
    const newState = EditorState.forceSelection(
      intermediateState,
      newSelection
    );

    placeHolderId.current = "";
    onEditorStateUpdate(newState);
  }, [placeHolderId, editorFocus]);

  // @todo remove when server endpoint available
  const onSaveHandler = () => {
    const content = editorState.getCurrentContent();
    const rawData = convertToRaw(content);
    const rawDataStr = JSON.stringify(rawData);

    localStorage.setItem(ROW_DATA_KEY, rawDataStr);
    const options = {
      blockStyleFn: (block: ContentBlock) => {
        if (block.getData().get("text-align")) {
          return {
            style: {
              textAlign: block.getData().get("text-align"),
            },
          };
        }
      },
      inlineStyles: {
        // Override default element (`strong`).
        SPACE: { element: "<br/>" },
      },
      inlineStyleFn: (styles: any) => {
        for (const item of styles) console.log(item);
        const fontStyle = {
          style: { fontSize: "", fontFamily: "", color: "" },
        };
        for (const item of styles) {
          //getting the font
          switch (item) {
            case "ARIAL":
              fontStyle.style.fontFamily = "Arial";
              break;
            case "GEORGIA":
              fontStyle.style.fontFamily = "Georgia";
              break;
            case "IMPACT":
              fontStyle.style.fontFamily = "Impact";
              break;
            case "TIMES NEW ROMAN":
              fontStyle.style.fontFamily = "Times";
              break;
            case "VERDANA":
              fontStyle.style.fontFamily = "Verdana";
              break;
            case "TAHOMA":
              fontStyle.style.fontFamily = "Tahoma";
          }
          //getting the color
          switch (item) {
            case "colornightRider":
              fontStyle.style.color = "#333333";
              break;
            case "colormortar":
              fontStyle.style.color = "#5A5A5A";
              break;
            case "colorsuvaGrey":
              fontStyle.style.color = "#8C8C8C";
              break;
            case "colorsilver":
              fontStyle.style.color = "#BFBFBF";
              break;
            case "colorwhite":
              fontStyle.style.color = "#FFFFFF";
              break;
            case "colorpurpleHeart":
              fontStyle.style.color = "#5724C2";
              break;
            case "colordarkOrchid":
              fontStyle.style.color = "#B629D4";
              break;
            case "colortorchRed":
              fontStyle.style.color = "#FC1233";
              break;
            case "coloroutrageousOrange":
              fontStyle.style.color = "#FB5F2C";
              break;
            case "colorgoldTips":
              fontStyle.style.color = "#E59E25";
              break;
            case "colorsalem":
              fontStyle.style.color = "#18A841";
              break;
            case "colorlightSeaGreen":
              fontStyle.style.color = "#1AA9B2";
              break;
            case "colorDenim":
              fontStyle.style.color = "#1885E2";
              break;
            case "coloregyptianBlue":
              fontStyle.style.color = "#0D3A99";
              break;
          }
          //getting the fontsize
          if (item.substring(0, 8) == "fontSize") {
            fontStyle.style.fontSize = `${item.substring(8, 10)}px`;
          }
        }
        return fontStyle;
      },
      defaultBlockTag: "div",
    };
    const html = stateToHTML(content, options);

    console.log(html.replace(/<br>/g, "<br />"));
  };

  const onEditorStateUpdate = (editorState: EditorState) => {
    setEditorState(editorState);
    setEditorFocus(Focus.In);
  };

  const onDragStartHandler: OnDragStartHandler = (id) => (e) => {
    e.dataTransfer.setData(PLACEHOLDER_ID, id);
  };

  const handleDrop: DragEventHandler<HTMLDivElement> = (e) => {
    if (!placeHolderId) return;

    placeHolderId.current = e.dataTransfer.getData(PLACEHOLDER_ID);
    setEditorFocus(Focus.In);
  };

  const keyBindingFn = (e: React.KeyboardEvent): string | null => {
    const selection = editorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const content = editorState.getCurrentContent();
    const currentBlock = content.getBlockForKey(anchorKey);
    const placeHolderDataList = getPlaceHolderDataList(currentBlock);

    if (!placeHolderDataList.length) return getDefaultKeyBinding(e);

    const isDelete = isPlaceHolderChange(placeHolderDataList, editorState, 1);
    const isEdit = isPlaceHolderChange(placeHolderDataList, editorState);

    if (isDelete && e.code === "Backspace") {
      return "placeholder-delete";
    }

    if (isEdit && !isArrowKey(e.code)) {
      return "placeholder-edit";
    }

    return getDefaultKeyBinding(e);
  };
  return (
    <Container>
      <DocumentDetails variant="outlined">
        <DocumentLoad onEditorStateUpdate={onEditorStateUpdate} />
        <Details>
          <SelectInput
            label="Bezeichnung des Templates"
            value="Arbeitsvertrag (unbefristet)"
            menueItems={[{ label: "Arbeitsvertrag", value: "Arbeitsvertrag" }]}
            selectHeight="36px"
          />
          <SelectInput
            label="Kategorie"
            value="vertraege"
            menueItems={[{ label: "Verträge", value: "vertraege" }]}
            selectHeight="36px"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={onSaveHandler}
            sx={{ height: 40, marginTop: "auto", width: 100 }}
          >
            Speichern
          </Button>
        </Details>
      </DocumentDetails>
      <PlaceholderContainer>
        <Placeholder variant="outlined">
          <PlaceholderContent>
            <SelectInput
              label="Wählen Sie einen Platzhalter"
              value="privateAddress"
              menueItems={[
                {
                  label: "Basis Daten (Arbeitnehmer)",
                  value: "privateAddress",
                },
              ]}
              selectHeight="36px"
            />
            <ChipList>
              {getPlaceHolderItems().map(({ id, label }) => (
                <StyledChip
                  key={id}
                  label={label}
                  onDragStart={onDragStartHandler(id)}
                  draggable="true"
                />
              ))}
            </ChipList>
          </PlaceholderContent>
        </Placeholder>
      </PlaceholderContainer>
      <EditorControls
        editorState={editorState}
        onEditorStateUpdate={onEditorStateUpdate}
        onEditorFocus={() => setEditorFocus(Focus.In)}
      />
      <EditorDropZone onDrop={handleDrop}>
        <EditorContainer
          variant="outlined"
          onBlur={() => setEditorFocus(Focus.Out)}
          onFocus={() => setEditorFocus(Focus.In)}
        >
          <Editor
            ref={editor}
            editorState={editorState}
            onChange={(editorState) => setEditorState(editorState)}
            blockStyleFn={blockStyleFn}
            customStyleMap={getStyleMap()}
            handleKeyCommand={handleKeyCommand(onEditorStateUpdate)}
            keyBindingFn={keyBindingFn}
          />
        </EditorContainer>
      </EditorDropZone>
    </Container>
  );
};

export default TemplateGenerator;
