import { Action, Dispatch } from "redux";

// types
export type Employee = Record<string, unknown>;

export type AbsenceFormData = {
  employeeId: number;
  companyId: number;
  startDate: string;
  endDate: string;
  absenceTypeId: number;
  startWithHalf: boolean;
  endWithHalf: boolean;
};

export type OnSubmit = (data: AbsenceFormData) => void;

export type AbsencePayload = {
  name?: string;
  title?: string;
  requestId?: number;
  employee?: Employee;
  onSubmit?: OnSubmit;
};

export type AbsenceAction = Action<string> & AbsencePayload;

// actions names
export const SHOW_CREATE_REQUEST_FORM = "SHOW_CREATE_REQUEST_FORM";
export const SHOW_EDIT_ORG_REQUEST_FORM = "SHOW_EDIT_ORG_REQUEST_FORM";
export const SHOW_EDIT_TABLE_REQUEST_FORM = "SHOW_EDIT_TABLE_REQUEST_FORM";

// action creators
export const showCreateRequestForm = (
  dispatch: Dispatch,
  name: string,
  title: string,
  onSubmit: OnSubmit
): AbsenceAction =>
  dispatch<AbsenceAction>({
    type: SHOW_CREATE_REQUEST_FORM,
    name,
    title,
    onSubmit,
  });

export const showEditOrgRequestForm = (
  dispatch: Dispatch,
  name: string,
  title: string,
  requestId: number
) => (employee: Employee): AbsenceAction =>
  dispatch<AbsenceAction>({
    type: SHOW_EDIT_ORG_REQUEST_FORM,
    name,
    title,
    requestId,
    employee,
  });

export const showEditTableRequestForm = (
  dispatch: Dispatch,
  name: string,
  title: string,
  onSubmit: OnSubmit,
  requestId: number
) => (employee: Employee): AbsenceAction =>
  dispatch<AbsenceAction>({
    type: SHOW_EDIT_TABLE_REQUEST_FORM,
    name,
    title,
    onSubmit,
    requestId,
    employee,
  });
