import styled, { css } from "styled-components";
import { HighlightShape } from "../utils";
import { getBaseCellStyle, getCellBackground, Text as P } from "../styles";
import { HighlightTheme } from "../../styles";
import { colors } from "../../../../../../../utils";

// types
type Styles = {
  highlightShape?: HighlightShape;
  highlightTheme?: HighlightTheme;
  highlightLabel?: string;
  width?: number;
  isTextOverflow?: boolean;
  activeTooltip?: boolean;
  isHovered?: boolean;
};

// constants
const shapeBorder = `
  border-style: solid;
  border-color: ${colors.gray4};
`;

const middleShape = css`
  left: 0;
  right: 0;
  border-radius: none;
`;

const midleShapeBorder = `
  ${shapeBorder}
  border-width: 1px 0 1px 0;
`;

const startShape = css`
  left: 5px;
  right: 0;
  border-radius: 4px 0 0 4px;
`;

const startShapeBorder = `
  ${shapeBorder}
  border-width: 1px 0 1px 1px;
`;

const endShape = css`
  left: 0;
  right: 5px;
  border-radius: 0 4px 4px 0;
`;

const endShapeBorder = `
  ${shapeBorder}
  border-width: 1px 1px 1px 0;
`;

const singleShape = css`
  left: 5px;
  right: 5px;
  border-radius: 4px;
`;

const singleShapeBorder = `
  ${shapeBorder}
  border-width: 1px;
`;

const highlightShapes = {
  middle: { style: middleShape, border: midleShapeBorder },
  start: { style: startShape, border: startShapeBorder },
  end: { style: endShape, border: endShapeBorder },
  single: { style: singleShape, border: singleShapeBorder },
};

// helper methods
const getHighlightShape = ({ highlightShape }: Styles) => {
  if (!highlightShape || !highlightShapes[highlightShape]) return "";

  return highlightShapes[highlightShape].style;
};

const getHighlightBackground = ({ highlightTheme }: Styles) => {
  if (!highlightTheme) return "";

  return `
    background: ${highlightTheme.background};
  `;
};

const getHighlightBorder = ({ highlightShape, isHovered }: Styles) => {
  if (!isHovered || !highlightShape || !highlightShapes[highlightShape])
    return "";

  return highlightShapes[highlightShape].border;
};

const getHighlightColor = ({ highlightTheme }: Styles) => {
  if (!highlightTheme) return "";

  return `
    color: ${highlightTheme.color};
  `;
};

const getWidth = ({ width, isTextOverflow = false }: Styles) =>
  isTextOverflow ? `${width}px` : "inherit";

const getOverflow = ({ isTextOverflow = false }: Styles) =>
  isTextOverflow ? "hidden" : "inherit";

// styles
export const Container = styled.div`
  position: relative;
  z-index: ${({ activeTooltip }: Styles) => (activeTooltip ? "10" : "auto")};
`;

export const StyledCell = styled.div`
  ${getCellBackground};
  ${getBaseCellStyle()}
`;

export const HighlightContainer = styled.div`
  cursor: pointer;
`;

export const StyledHighlightedCell = styled.div`
  position: absolute;
  top: 5px;
  bottom: 5px;
  ${getHighlightBackground};
  ${getHighlightShape}
  ${getHighlightBorder}
`;

export const TextContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 0px;
  bottom: 0px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const HighlightText = styled.div`
  width: ${getWidth};
  margin-left: 50%;
`;

export const Text = styled(P)`
  text-transform: capitalize;
  white-space: nowrap;
  cursor: pointer;
  ${getHighlightColor}
  overflow: ${getOverflow};
  text-overflow: ellipsis;

  &:before {
    content: "";
    display: block;
  }
`;
