import styled from "styled-components";

const HeaderRightSideContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 14px 20px 14px 0px;
`;

export default HeaderRightSideContainer;
