import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EmployeeAvatarInput } from "../../../globalComponents/avatars/EmployeeAvatar";
import { CoreRequest } from "../../../config/backend/serviceInstances";
import { DataState } from "../companies/detail/companyDetail";
import { Page } from "../companies/detail/salarytype";

// types

interface DepartmentsTableOrgState extends DataState {
  data: Department[];
  page: number;
  pageSize: number;
  totalPages: number;
  totalCount: number;
}

export interface DepartmentsState {
  departmentsTable: DepartmentsTableOrgState;
  departmentsOrg: DepartmentsTableOrgState;
}

export const DepartmentsTableOrgState: DepartmentsTableOrgState = {
  loading: false,
  error: false,
  errorMessage: "",
  data: [],
  page: 0,
  pageSize: 25,
  totalCount: 0,
  totalPages: 0,
};

export const DepartmentsStateI: DepartmentsState = {
  departmentsTable: DepartmentsTableOrgState,
  departmentsOrg: DepartmentsTableOrgState,
};

export type Department = {
  id: number;
  departmentName?: string;
  motherCompanyId: number;
  name: string;
  city: string;
  entityType: string;
  supervisorList: EmployeeAvatarInput[];
  children: Department[];
  activeEmployees: number;
};

export type DepartmentsResponse = Department[];
export type DepartmentsPageResponse = Page<Department>;

// actions
export const listDepartmentsOrg = createAsyncThunk<DepartmentsResponse>(
  "/departments/org",
  async () => {
    const response = await CoreRequest.get(`/companies/departments/org`);

    if (response.status > 299) {
      // Return the known error for future handling
      return response.data.error;
    }

    return response.data as Department[];
  }
);

export const listDepartments = createAsyncThunk<DepartmentsPageResponse>(
  "/departments",
  async () => {
    const response = await CoreRequest.get(`/companies/departments`);

    if (response.status > 299) {
      // Return the known error for future handling
      return response.data.error;
    }

    return response.data as Page<Department>;
  }
);

// reducer
const departments = createSlice({
  name: "organization/departments",
  initialState: DepartmentsStateI,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listDepartmentsOrg.pending, (state) => {
      state.departmentsOrg.loading = true;
      state.departmentsOrg.data = [];
    });
    builder.addCase(listDepartmentsOrg.fulfilled, (state, action) => {
      state.departmentsOrg.loading = false;
      state.departmentsOrg.data = action.payload;
      state.departmentsOrg.error = false;
    });
    builder.addCase(listDepartmentsOrg.rejected, (state) => {
      state.departmentsOrg.loading = false;
      state.departmentsOrg.error = true;
    });
    builder.addCase(listDepartments.pending, (state) => {
      state.departmentsTable.loading = true;
      state.departmentsTable.data = [];
    });
    builder.addCase(listDepartments.fulfilled, (state, action) => {
      state.departmentsTable.loading = false;
      state.departmentsTable.data = action.payload.data;
      state.departmentsTable.page = action.payload.page;
      state.departmentsTable.pageSize = action.payload.pageSize;
      state.departmentsTable.totalPages = action.payload.totalPages;
      state.departmentsTable.totalCount = action.payload.totalCount;
      state.departmentsOrg.error = false;
    });
    builder.addCase(listDepartments.rejected, (state) => {
      state.departmentsTable.loading = false;
      state.departmentsTable.error = true;
    });
  },
});

export default departments;
