import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import CompensationRow from "./CompensationRow";

const TableStyled = styled(Table)``;

const HeaderCell = styled(TableCell)`
  background-color: #f5f8fa;
  color: #4c4c4c;
  text-transform: uppercase;
  height: 40px;
  padding-top: 0;
  padding-bottom: 0;
`;

const CompensationTable = (): React.ReactElement => {
  return (
    <TableStyled>
      <TableHead>
        <TableRow>
          <HeaderCell />
          <HeaderCell>Employee</HeaderCell>
          <HeaderCell>Gross</HeaderCell>
          <HeaderCell>Lohnsteuer</HeaderCell>
          <HeaderCell>Sol. Zuschlag</HeaderCell>
          <HeaderCell>Kirch St.</HeaderCell>
          <HeaderCell>SV</HeaderCell>
          <HeaderCell>Netto</HeaderCell>
          <HeaderCell>Nettobezüge</HeaderCell>
          <HeaderCell>Auszahlung</HeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <CompensationRow />
      </TableBody>
    </TableStyled>
  );
};

export default CompensationTable;
