import styled from "styled-components";
import { CircleProps } from ".";
import {
  addAnimation,
  getAnimationDuration,
  getDefaultValue,
  getKeyFramesAnimatein,
  Styles as AnimationStyles,
} from "../Animation/styles";

// types
export type Styles = AnimationStyles & {
  circleProps: CircleProps;
};

// utils
const getCircleProps = ({ circleProps }: Styles): string => {
  if (circleProps === "None") return "";

  const { width, height, left, top } = circleProps;

  return `
  width: ${width}px;
  height: ${height}px;
  left: ${left}px;
  top: ${top}px;
  `;
};

// styles
export const Container = styled.div`
  position: relative;
  overflow: hidden;
  transition: background 400ms;
`;

export const StyledAnimation = styled.div`
  animation-fill-mode: none;
  animation-timing-function: ease-in;
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  ${getAnimationDuration}
  ${getDefaultValue}
  ${getKeyFramesAnimatein}
  ${addAnimation}
  ${getCircleProps}
`;
