import React from "react";
import styled from "styled-components";
import { ApprovalStatus } from "../../../../../../state/employees/types";

export const AbsenceRequestStatusChipTable = styled.div<{
  backgroundColor: string;
  fontColor: string;
}>`
  height: 28px;
  border-radius: 4px;
  padding: 5px 15px 5px 15px;
  color: #131313;
  width: fit-content;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.fontColor};
`;

interface AbsenceRStatusProps {
  absenceTypeId: number;
  approvalStatus: ApprovalStatus;
}

const AbsenceRequestStatus = (props: AbsenceRStatusProps): JSX.Element => {
  let color = "#D7E9F7";
  let fontColor = "green";
  let content = "";

  if (props.absenceTypeId == 1) {
    color = "transparent";
    content = "Nicht Notwendig";
    fontColor = "#4c4c4c";
  } else {
    switch (props.approvalStatus) {
      case ApprovalStatus.APPROVED:
        color = "#eaf7f2b3";
        fontColor = "#008c5b";
        content = "Genehmigt";
        break;
      case ApprovalStatus.PLANNED:
        color = "#D7E9F7";
        fontColor = "#004C80";
        content = "Geplant";
        break;
      case ApprovalStatus.REJECTED:
        fontColor = "#c80400";
        color = "#f9e8e8b3";
        content = "Abgelehnt";
        break;
      case ApprovalStatus.REQUESTED:
        color = "#fcf4e7b3";
        fontColor = "#77541f";
        content = "Beantragt";
        break;
      case ApprovalStatus.WITHDRAWN:
        color = "#D7E9F7";
        content = "Zurückgezogen";
        break;
    }
  }
  return (
    <AbsenceRequestStatusChipTable
      backgroundColor={color}
      fontColor={fontColor}
    >
      {content}
    </AbsenceRequestStatusChipTable>
  );
};
export default AbsenceRequestStatus;
