import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../../config/globalVariables";
import { AbsenceRequest, AbsenceType } from "../types";
import { CoreRequest } from "../../../config/backend/serviceInstances";
import { Page } from "../../organization/companies/detail/salarytype";

export type GenericError = string;

export type Pagination = {
  page?: number;
  pageSize?: number;
  sort?: string | null;
};

// action names
export const ABSENCE_LIST = "absence/list";
export const ABSENCE_CALENDAR_LIST = "absence/calendar/list";

// types
export interface AbsenceState {
  loading: boolean;
  error: boolean;
  data: Page<AbsenceRequest>;
  calendarData: AbsenceCalendarResponse;
}

export type AbsenceCalendarResponse = {
  page: number;
  pageSize: number;
  absenceTypes: AbsenceType[];
  departmentGrouping: AbsenceDepartmentGroup[];
};

export type AbsenceDepartmentGroup = {
  id: number;
  uid: string;
  departmentName: string;
  employees: AbsenceEmployee[];
  absences: number[];
};

export type AbsenceEmployee = {
  id: number;
  firstName: string;
  lastName: string;
  position: string | null;
  imgLink: string | null;
  absenceRequests: AbsenceRequest[];
};

export type QueryParams = {
  startDate?: string | null;
  endDate?: string | null;
};

// actions
export const listAbsences = createAsyncThunk<Page<AbsenceRequest>>(
  ABSENCE_LIST,
  async () => {
    const response = await CoreRequest.get(`${baseUrl}/employees/absence`);

    if (response.status > 299) {
      // Return the known error for future handling
      return response.data.error;
    }

    return response.data as Page<AbsenceRequest>;
  }
);

export const listCalendarAbsences = createAsyncThunk<
  AbsenceCalendarResponse,
  Pagination & { startDate: string },
  { rejectValue: GenericError }
>(ABSENCE_CALENDAR_LIST, async (params, thunkApi) => {
  const response = await CoreRequest.get(
    `${baseUrl}/employees/absence/calendar/${params.startDate}`,
    {
      params: {
        page: params.page,
        pageSize: params.pageSize,
      },
    }
  );

  if (response.status > 299) {
    // Return the known error for future handling
    return thunkApi.rejectWithValue(response.data as GenericError);
  }

  return response.data;
});

const initialState: AbsenceState = {
  loading: false,
  error: false,
  data: {
    page: 0,
    data: [],
    pageSize: 25,
    totalCount: 0,
    totalPages: 0,
    sorting: "-startDate",
  },
  calendarData: {
    page: 0,
    pageSize: 25,
    absenceTypes: [],
    departmentGrouping: [],
  },
};

// reducer
const absence = createSlice({
  name: "absence",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listAbsences.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listAbsences.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload as Page<AbsenceRequest>;
      state.error = false;
    });
    builder.addCase(listAbsences.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });

    builder.addCase(listCalendarAbsences.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listCalendarAbsences.fulfilled, (state, action) => {
      state.loading = false;
      state.calendarData = action.payload;
    });
    builder.addCase(listCalendarAbsences.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default absence;
