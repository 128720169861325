import React, { useEffect, useState } from "react";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import { CoreRequest } from "../../../config/backend/serviceInstances";
import { useUserSelection } from "../../../state/general/selectors";
import { StyledLabel, TextFieldWrapper } from "../TextFieldController";

const ITEM_HEIGHT = 40;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
      width: 295,
    },
  },
  getContentAnchorEl: null,
};

export interface EmployeeLabel {
  label: string;
  value: number;
}

type Props = {
  control: Control<any>;
  fieldName: `${string}`;
  defaultValue?: number[] | null;
  label: string;
};

const EmployeesSelect = (props: Props): React.ReactElement => {
  const userSelection = useUserSelection();

  const [employeeLabels, setEmployeeLabels] = useState<EmployeeLabel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    CoreRequest.get("/employees/labels")
      .then((response) => {
        setLoading(false);
        setEmployeeLabels(response.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [userSelection]);

  if (!employeeLabels || loading) {
    return (
      <FormControl>
        <InputLabel id={`${props.fieldName}-employeeSelect`}>
          {props.label}
        </InputLabel>
        <CircularProgress />
      </FormControl>
    );
  }

  return (
    <Controller
      name={props.fieldName}
      control={props.control}
      defaultValue={props.defaultValue || []}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <TextFieldWrapper>
            <StyledLabel>
              <label id={`${props.fieldName}-employeeSelect`}>
                {props.label}
              </label>
            </StyledLabel>
            <Select
              disabled={loading}
              labelId={`${props.fieldName}-employeeSelect`}
              id={`${props.fieldName}-employeeSelect`}
              onChange={onChange}
              value={value}
              multiple
              MenuProps={MenuProps}
              renderValue={(value) => {
                if (value) {
                  const labels = [];
                  for (let i = 0; i < employeeLabels.length; i++) {
                    // @ts-ignore
                    for (let j = 0; j < value.length; j++) {
                      // @ts-ignore
                      if (employeeLabels[i].value === value[j]) {
                        labels.push(`${employeeLabels[i].label}`);
                      }
                    }
                  }
                  return labels.join(", ");
                }
              }}
            >
              {employeeLabels.map((item) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    <Checkbox
                      checked={value && value.includes(item.value)}
                      size={"small"}
                    />
                    <ListItemText primary={item.label} />
                  </MenuItem>
                );
              })}
            </Select>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </TextFieldWrapper>
        );
      }}
    />
  );
};

export default EmployeesSelect;
