import { useSelector } from "react-redux";
import { Store } from "../../store";
import { EmployeeDetailState, AddWorkSchedule } from "./employeeDetail";

export const useCurrentEmployee = (): EmployeeDetailState => {
  return useSelector<Store, EmployeeDetailState>(
    (state) => state.employeeDetail
  );
};

export const useNewWorkSchedule = (): AddWorkSchedule => {
  return useSelector<Store, AddWorkSchedule>(
    (state) => state.employeeDetail.WorkContractDetail.newWorkSchedule
  );
};
