import {
  Collapse,
  IconButton,
  styled,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const HeaderCellSmall = styled(TableCell)`
  color: #4c4c4c;
  height: 30px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 0;
  background-color: white;
`;

const CellNoBorder = styled(TableCell)`
  border-bottom: 0;
  background-color: white;
`;

const TableRowStyled = styled(TableRow)`
  background-color: white;
  border: 0px;
`;

const CompensationRow = (): JSX.Element => {
  const [flap, setFlap] = useState(false);

  return (
    <>
      <TableRowStyled>
        <CellNoBorder>
          <IconButton onClick={() => setFlap(!flap)}>
            {flap ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CellNoBorder>
        <CellNoBorder>John Watson</CellNoBorder>
        <CellNoBorder>5.666,67</CellNoBorder>
        <CellNoBorder>1.240,00</CellNoBorder>
        <CellNoBorder>68,20</CellNoBorder>
        <CellNoBorder>0</CellNoBorder>
        <CellNoBorder>595,00</CellNoBorder>
        <CellNoBorder>3.298,87</CellNoBorder>
        <CellNoBorder>-441,81</CellNoBorder>
        <CellNoBorder>3.321,66</CellNoBorder>
      </TableRowStyled>
      <TableRow>
        <TableCell
          colSpan={10}
          style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: "90px" }}
        >
          <Collapse in={flap} timeout="auto" unmountOnExit>
            <Table>
              <TableHead>
                <HeaderCellSmall>Compensation Type</HeaderCellSmall>
                <HeaderCellSmall />
                <HeaderCellSmall>Art</HeaderCellSmall>
                <HeaderCellSmall>Bezeichnung</HeaderCellSmall>
                <HeaderCellSmall>Faktor</HeaderCellSmall>
                <HeaderCellSmall>Menge</HeaderCellSmall>
                <HeaderCellSmall>Wert</HeaderCellSmall>
              </TableHead>
              <TableRow>
                <CellNoBorder>Salary (regular)</CellNoBorder>
                <CellNoBorder>
                  <FontAwesomeIcon icon={faArrowRight} color={"#054D80"} />
                </CellNoBorder>
                <CellNoBorder>Salarytype</CellNoBorder>
                <CellNoBorder>Gehalt (laufend)</CellNoBorder>
                <CellNoBorder contentEditable>-</CellNoBorder>
                <CellNoBorder contentEditable>-</CellNoBorder>
                <CellNoBorder contentEditable>5.666,67</CellNoBorder>
              </TableRow>
              <TableRow>
                <CellNoBorder>State Insurance (Not Mandatory)</CellNoBorder>
                <CellNoBorder>
                  <FontAwesomeIcon icon={faArrowRight} color={"#054D80"} />
                </CellNoBorder>
                <CellNoBorder>Nettoabzug</CellNoBorder>
                <CellNoBorder>
                  Frewillig gesetzlich krankenversichert
                </CellNoBorder>
                <CellNoBorder>-</CellNoBorder>
                <CellNoBorder>-</CellNoBorder>
                <CellNoBorder>-358,60</CellNoBorder>
              </TableRow>
              <TableRow>
                <CellNoBorder>State Insurance (Not Mandatory)</CellNoBorder>
                <CellNoBorder>
                  <FontAwesomeIcon icon={faArrowRight} color={"#054D80"} />
                </CellNoBorder>
                <CellNoBorder>Nettoabzug</CellNoBorder>
                <CellNoBorder>
                  Frewillig gesetzlich pflegeversichert
                </CellNoBorder>
                <CellNoBorder>-</CellNoBorder>
                <CellNoBorder>-</CellNoBorder>
                <CellNoBorder>-83,21</CellNoBorder>
              </TableRow>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CompensationRow;
