import { Location } from "../organization/locations/locations";
import { LabelValuePair } from "../../globalComponents/formFields/staticSelect/SelectMenuInput";

export type Errors = {
  errors: Error[];
};

type Error = {
  message: string;
  field: string | null;
  value: string | null;
};

export type EmployeeOverview = {
  id: number;
  firstName: string;
  lastName: string;
  imgLink: string | null;
  startDate: string | null;
  position: string | null;
  department: string | null;
  location: string | null;
  companyId: number;
  companyName: string;
  status: string | null;
};

export type SimpleWorkContract = {
  id: number;
  employeeId: number;
  position: string | null;
  startDate: string;
  endDate: string | null;
};

export type WorkContractBasic = {
  id: number;
  companyId: number;
  departmentId: number;
  supervisors: EmployeeOverview[];
  locationId: number;
  position: string | null;
  arbeitnehmerTyp: string | null;
  workingHoursPerWeek: number | null;
  abrechnungsGruppe: string | null;
  sicknessCertificateDays: string | null;
  vacationDaysPerYear: string | null;
};

export type WorkContractJob = {
  id: number;
  startDate: string;
  endDate: string | null;
  einstellungsArt: string | null;
  contractType: string | null;
  probationPeriod: string | null;
  probationTerminationPeriod: string | null;
  probationTerminationEffectiveness: string | null;
  terminationPeriod: string | null;
  terminationEffectiveness: string | null;
};

export type SalaryType = {
  id: number;
  companyId: number;
  name: string | null;
  type: string | null;
  taxRate: number | null;
};

export type WorkContractCompensations = {
  workContract: WorkContract | null;
  compensations: Compensation[];
  zuschlage: Zuchlag[];
};

export type Compensation = {
  id: number;
  workContractId: number;
  name: string;
  timeInterval: string;
  payoutDate: string;
  amount: number | null;
  type: string;
  salarytype: SalaryType | null;
};

export type Zuchlag = {
  id: number;
  workContractId: number;
  name: string;
  percentage: number;
  type: string;
  salarytype: SalaryType | null;
};

export type AbsenceDocument = {
  id: number;
  creationDate: string;
  fileId: string;
  name: string;
  status: string;
};

export type AbsenceRequest = {
  id: number;
  startDate: string;
  endDate: string;
  startWithHalf: boolean;
  endWithHalf: boolean;
  duration?: number;
  creationDate?: string;
  comment: string | null;
  documentStatus: DocumentStatus;
  absenceType: AbsenceType;
  workContract: WorkContractSimple;
  approvalStatus: ApprovalStatus;
  documents: AbsenceDocument[];
};

export enum DocumentStatus {
  NOT_REQUIRED = "NOT_REQUIRED",
  MISSING = "MISSING",
  PROCESSING = "PROCESSING",
  CORRECT = "CORRECT",
  INCORRECT = "INCORRECT",
  MANUAL_CHECK = "MANUAL_CHECK",
  MANUALLY_APPROVED = "MANUALLY_APPROVED",
}

export type AbsenceType = {
  id: number;
  description: string | undefined;
  companyId: number;
  approvalRequired: boolean;
  halfDayPossible: boolean;
  budgetRequired: boolean;
  documentRequired: boolean;
};

export enum ApprovalStatus {
  PLANNED = "PLANNED",
  REQUESTED = "REQUESTED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  WITHDRAWN = "WITHDRAWN",
}

export type WorkSchedule = {
  id: number;
};

export type TimeTrackingRequest = {
  id: number;
};

export type WorkContract = {
  id: number;
  workContractBasic: WorkContractBasic;
  workContractStatistics: WorkContractStats;
  workContractJob: WorkContractJob;
  location: Location;
};

export type WorkContractSimple = {
  id: number;
  startDate: string;
  endDate: string | null;
  position: string | null;
  status: string;
  personalNr: number;
  locationName: string | null;
  lastName: string | null;
  firstName: string | null;
  imgLink: string | null;
  employeeId: number;
  departmentName: string | null;
  departmentId: number | null;
  creationDate: string;
  companyId: number;
  company: CompanySimple;
};

export type CompanySimple = {
  id: number;
  imgLink: string | null;
  managingDirectors: [];
  motherCompany: CompanySimple | null;
  city: string | null;
  children: [];
  activeEmployees: null;
  entityType: string | null;
  company: LabelValuePair;
};

export type WorkContractStats = {
  vertragsForm: string | null;
  arbeitnehmerUeberlassung: string | null;
  ausbildungsabschluss: string | null;
  schulabschluss: string | null;
  ausgeuebteTaetigkeit: string | null;
};

export type EmployeePersonalData = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  personalNr: string | null;
  street: string | null;
  postalCode: string | null;
  city: string | null;
  sex: string | null;
};

export type EmployeeSocialData = {
  id: number;
  privateHealth: boolean | null;
  employerContribution: boolean | null;
  privateHealthRate: number | null;
  privateNurseRate: number | null;
  healthInsurer: string | null;
  svNumber: string | null;
  privatePension: boolean | null;
};

export type EmployeeTaxData = {
  id: number;
  taxId: string | null;
  taxClass: number | null;
  taxFactor: number | null;
  jfreib: number;
  jhinzu: number;
  lzzfreib: number;
  lzzhinzu: number;
  religion: number | null;
  children: boolean | null;
  childrenAllowance: number | null;
  birthDate: string | null;
};

export type FullEmployee = {
  id: number;
  imgLink: string;
  status: string;
  employeePersonalData: EmployeePersonalData;
  employeeSocialData: EmployeeSocialData;
  employeeTaxData: EmployeeTaxData;
};

export type Budget = {
  id: number;
  absenceType: AbsenceType;
  workContractId: number;
  amount: number;
  startDate: string;
  endDate: string;
  expiryDate: string;
  status: string;
  remainingAmount: number;
};
