import {
  Dayline,
  StartEnd,
  WorkingDay,
} from "../../../../../../../state/employees/employeeDetail/employeeDetail";

export const convertGridToTime = (
  column: number
): { hour: number; minutes: number } => {
  const totalMinutes = (column - 20) * 15;
  const minuteRemainder = totalMinutes % 60;
  const hour = Math.floor(totalMinutes / 60);

  return { hour: hour, minutes: minuteRemainder };
};

export const convertTimeToGrid = (hours?: number, minutes?: number): number => {
  const totalMinutes = hours! * 60 + minutes!;
  return Math.floor(totalMinutes / 15) + 20;
};

const returnMinutes = (column: number) => {
  const wStartMinTotal = (column - 20) * 15;
  return wStartMinTotal % 60;
};

const returnHours = (column: number) => {
  const wStartMinTotal = (column - 20) * 15;
  return Math.floor(wStartMinTotal / 60);
};

export const AddLeadingZero = (n: number | string): string => {
  if (n.toString().length == 1) return `0${n}`;
  else return n.toString();
};

export const convertDaylineToDay = (dayline: Dayline): WorkingDay => {
  const workingTimeMinutes = (dayline.columnEnd - dayline.columnStart) * 15;
  let breakMinutes = 0;
  const wStartHour = returnHours(dayline.columnStart);
  const wEndHour = returnHours(dayline.columnEnd);
  const wStartMin = returnMinutes(dayline.columnStart);
  const wEndMin = returnMinutes(dayline.columnEnd);

  const breaks: StartEnd[] = dayline.breaks.map((r) => {
    breakMinutes += (r.colEnd - r.colStart) * 15;
    return {
      startMin: returnMinutes(r.colStart),
      startHour: returnHours(r.colStart),
      endMin: returnMinutes(r.colEnd),
      endHour: returnHours(r.colEnd),
    };
  });
  const effTime = workingTimeMinutes - breakMinutes;
  return {
    hours: Math.floor(effTime / 60),
    minutes: effTime % 60,
    workingTimes: {
      startHour: wStartHour,
      startMin: wStartMin,
      endHour: wEndHour,
      endMin: wEndMin,
    },
    breaks: breaks,
  };
};
