import React from "react";
import IconButtonNew from "../../../../../../globalComponents/buttons/IconButtonNew";
import {
  faFileCheck,
  faPaperPlane,
  faPen,
  faThumbsDown,
  faThumbsUp,
  faTimesSquare,
  faTrashAlt,
} from "@fortawesome/pro-solid-svg-icons";
import { CoreRequest } from "../../../../../../config/backend/serviceInstances";
import { listAbsences } from "../../../../../../state/employees/absences/absence";
import { useDispatch } from "react-redux";
import {
  AbsenceDocument,
  AbsenceType,
  ApprovalStatus,
  DocumentStatus,
} from "../../../../../../state/employees/types";
import ExpandOptionsButton from "../../../../../../globalComponents/buttons/ExpandOptionsButton";
import ShowFilesFlap from "./ShowFilesFlap";
import { Operations } from "../CompanyAbsencesTable";
import QuickUploadButton from "../../../../../../globalComponents/buttons/QuickUploadButton";
import { v4 as uuidv4 } from "uuid";

interface RowActionsProps {
  id: number;
  absenceType: AbsenceType;
  approvalStatus: ApprovalStatus;
  documentStatus: DocumentStatus;
  documents: AbsenceDocument[];
  fileViewCallBack: (r: AbsenceDocument) => void;
  editDeleteCallBack: (r: { operation: Operations; requestId: number }) => void;
}

interface Item {
  JSX: JSX.Element;
  expandItem: { label: string; onClick: () => void };
}

const RowActions = (props: RowActionsProps): JSX.Element => {
  const dispatch = useDispatch();

  const changeStatus = (id: number, status: string) => {
    const obj = {} as Record<string, string>;
    obj[`${id}`] = status;
    CoreRequest.post(`/employees/absence/status`, {
      transitions: obj,
    }).then(() => {
      dispatch(listAbsences());
    });
  };

  const items: Item[] = [];

  const ApproveButton: Item = {
    JSX: (
      <IconButtonNew
        icon={faThumbsUp}
        key={uuidv4()}
        onClick={() => changeStatus(props.id, ApprovalStatus.APPROVED)}
        primary
      />
    ),
    expandItem: {
      label: "Genehmigen",
      onClick: () => changeStatus(props.id, ApprovalStatus.APPROVED),
    },
  };

  const RejectButton: Item = {
    JSX: (
      <IconButtonNew
        icon={faThumbsDown}
        key={uuidv4()}
        onClick={() => changeStatus(props.id, ApprovalStatus.REJECTED)}
      />
    ),
    expandItem: {
      label: "Ablehnen",
      onClick: () => changeStatus(props.id, ApprovalStatus.REJECTED),
    },
  };

  const EditButton: Item = {
    JSX: (
      <IconButtonNew
        icon={faPen}
        key={uuidv4()}
        onClick={() =>
          props.editDeleteCallBack({
            operation: Operations.EDIT,
            requestId: props.id,
          })
        }
      />
    ),
    expandItem: {
      label: "Bearbeiten",
      onClick: () =>
        props.editDeleteCallBack({
          operation: Operations.EDIT,
          requestId: props.id,
        }),
    },
  };

  const DeleteButton: Item = {
    JSX: (
      <IconButtonNew
        icon={faTrashAlt}
        key={uuidv4()}
        onClick={() =>
          props.editDeleteCallBack({
            operation: Operations.DELETE,
            requestId: props.id,
          })
        }
      />
    ),
    expandItem: {
      label: "Löschen",
      onClick: () =>
        props.editDeleteCallBack({
          operation: Operations.DELETE,
          requestId: props.id,
        }),
    },
  };

  const UploadButton: Item = {
    JSX: (
      <QuickUploadButton
        id={props.id}
        endpoint={`/absence/documents/upload`}
        params={{ absenceRequestId: props.id }}
        successCallBack={() => dispatch(listAbsences())}
        key={uuidv4()}
      />
    ),
    expandItem: { label: "Dokument hochladen", onClick: () => console.log("") },
  };

  const FileButton: Item = {
    JSX: (
      <ShowFilesFlap
        documents={props.documents}
        fileViewCallBack={props.fileViewCallBack}
        key={uuidv4()}
      />
    ),
    expandItem: {
      label: "Dateien ansehen",
      onClick: () =>
        console.log(
          "I am not working in expand mode, because i can have multiple children."
        ),
    },
  };

  const SendButton: Item = {
    JSX: (
      <IconButtonNew
        icon={faPaperPlane}
        onClick={() => changeStatus(props.id, ApprovalStatus.REQUESTED)}
        primary
        key={uuidv4()}
      />
    ),
    expandItem: {
      label: "Senden",
      onClick: () => changeStatus(props.id, ApprovalStatus.REQUESTED),
    },
  };

  const ManuallyApprove: Item = {
    JSX: (
      <IconButtonNew
        icon={faFileCheck}
        key={uuidv4()}
        onClick={() => changeStatus(props.id, ApprovalStatus.APPROVED)}
      />
    ),
    expandItem: {
      label: "Nachweis akzeptieren",
      onClick: () => changeStatus(props.id, ApprovalStatus.APPROVED),
    },
  };

  const ManuallyReject: Item = {
    JSX: (
      <IconButtonNew
        icon={faTimesSquare}
        key={uuidv4()}
        onClick={() => changeStatus(props.id, ApprovalStatus.REJECTED)}
      />
    ),
    expandItem: {
      label: "Nachweis ablehnen",
      onClick: () => changeStatus(props.id, ApprovalStatus.REJECTED),
    },
  };

  const approvalRequired = props.absenceType.approvalRequired;
  const documentRequired = props.absenceType.documentRequired;
  const approvalStatus = props.approvalStatus;
  const documentStatus = props.documentStatus;

  if (
    approvalRequired &&
    (approvalStatus == ApprovalStatus.REQUESTED ||
      approvalStatus == ApprovalStatus.REJECTED)
  ) {
    items.push(ApproveButton);
  }

  if (
    approvalRequired &&
    (approvalStatus == ApprovalStatus.REQUESTED ||
      approvalStatus == ApprovalStatus.APPROVED)
  ) {
    items.push(RejectButton);
  }

  if (approvalStatus == ApprovalStatus.PLANNED && approvalRequired) {
    items.push(SendButton);
  }

  if (props.documents.length > 0) {
    items.push(FileButton);
  }

  if (documentRequired) {
    items.push(UploadButton);
  }

  if (documentStatus == DocumentStatus.MANUALLY_APPROVED) {
    items.push(ManuallyReject);
  }

  if (documentStatus == DocumentStatus.MISSING) {
    items.push(ManuallyApprove);
  }

  if (documentStatus == DocumentStatus.MANUAL_CHECK) {
    items.push(ManuallyReject);
    items.push(ManuallyApprove);
  }

  items.push(EditButton);
  items.push(DeleteButton);

  return (
    <>
      {items.slice(0, 2).map((item) => {
        return item.JSX;
      })}
      {items.length == 3 ? items[2].JSX : <></>}
      {items.length > 3 ? (
        <ExpandOptionsButton
          items={items.slice(2, items.length).map((renderItem) => {
            return renderItem.expandItem;
          })}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default RowActions;
