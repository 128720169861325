import React, { useState } from "react";
import OrgChartGenerator, {
  ElementType,
  OrgChartElementInterface,
} from "./OrgChartGenerator";
import styled from "styled-components";
import { IconButton } from "@mui/material";
import { ZoomIn, ZoomOut } from "@mui/icons-material";

export interface OrgChartProps {
  mother: OrgChartElementInterface[];
  elementType: ElementType;
  loading: boolean;
  error: boolean;
  reloadCallback: () => void;
}

const ParentContainer = styled.div`
  width: 1200px;
  height: 600px;
  display: flex;
  justify-content: center;
`;

const OrganizationalContainer = styled.div`
  cursor: grab;
  overflow: scroll;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const OrganizationalChart = (props: OrgChartProps): JSX.Element => {
  const [zoomLevel, setZoomLevel] = useState(10);

  const boundaries = (a: number, inc: boolean) => {
    if (inc && a + 2 < 14) {
      return a + 2;
    }
    if (!inc && a - 2 > 4) {
      return a - 2;
    } else return a;
  };

  return (
    <>
      <ButtonContainer>
        <IconButton
          onClick={() => setZoomLevel(boundaries(zoomLevel, false))}
          size={"small"}
        >
          <ZoomOut color={"primary"} />
        </IconButton>
        <IconButton
          onClick={() => setZoomLevel(boundaries(zoomLevel, true))}
          size={"small"}
        >
          <ZoomIn color={"primary"} />
        </IconButton>
      </ButtonContainer>
      <ParentContainer>
        <OrganizationalContainer>
          <OrgChartGenerator
            mother={props.mother}
            elementType={props.elementType}
            loading={props.loading}
            error={props.error}
            reloadCallback={props.reloadCallback}
            zoomLevel={zoomLevel}
          />
        </OrganizationalContainer>
      </ParentContainer>
    </>
  );
};

export default OrganizationalChart;
